import React, { Component } from "react";
import styled from "styled-components";
import { Screens } from "src/scenes/Customise";

import ImgBackground from "../../../img/customise/ImgBackground.png";
import ImgBodyAndMindBackground from "../../../img/customise/ImgBodyAndMindBackground.png";

import ImgPhoneFrame from "../../../img/customise/ImgPhoneFrame.png";
import ImgYourLogoHere from "../../../img/customise/ImgYourLogoHere.png";
import ImgYourIconHere from "../../../img/customise/ImgYourIconHere.png";

import ImgQuotesOverlay from "../../../img/customise/ImgQuotesOverlay.png";
import ImgQuotesPrimary from "../../../img/customise/ImgQuotesPrimary.png";
import ImgQuotesSecondary from "../../../img/customise/ImgQuotesSecondary.png";

import ImgArticlesPrimary from "../../../img/customise/ImgArticlesPrimary.png";
import ImgArticlesOverlay from "../../../img/customise/ImgArticlesOverlay.png";

import ImgBodyAndMindPrimary from "../../../img/customise/ImgBodyAndMindPrimary.png";
import ImgBodyAndMindOverlay from "../../../img/customise/ImgBodyAndMindOverlay.png";

import ImgBackgroundNoticeboard from "../../../img/customise/ImgBackgroundNoticeboard.png";
import ImgNoticeboardBackgroundOverlay from "../../../img/customise/ImgNoticeboardBackgroundOverlay.png";
import ImgNoticeboardPrimary from "../../../img/customise/ImgNoticeboardPrimary.png";

import ImgBackgroundPlaylists from "../../../img/customise/ImgBackgroundPlaylists.png";
import ImgPlaylistsBackgroundOverlay from "../../../img/customise/ImgPlaylistsBackgroundOverlay.png";
import ImgPlaylistsOverlay from "../../../img/customise/ImgPlaylistsOverlay.png";
import ImgPlaylistsPrimary from "../../../img/customise/ImgPlaylistsPrimary.png";

import ImgGoalArticle from "../../../img/customise/ImgGoalArticle.png";
import ImgGoalTrackerPrimary from "../../../img/customise/ImgGoalTrackerPrimary.png";

import ImgSplashPrimary from "../../../img/customise/ImgSplashPrimary.png";
import ImgSplashSecondary from "../../../img/customise/ImgSplashSecondary.png";

import ImgSurveyPrimary from "../../../img/customise/ImgSurveyPrimary.png";
import ImgSurveyOverlay from "../../../img/customise/ImgSurveyOverlay.png";

import ImgPhoneScreen from "../../../img/customise/ImgPhoneScreen.png";

const LayerSizing = {
    FRAME_WIDTH: 252,
    FRAME_HEIGHT: 512,
    SCREEN_WIDTH: 232,
    SCREEN_HEIGHT: 492
};

const WidthContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
`;

const ZeroSizeSvg = styled.svg`
    width: 0px;
    height: 0px;
    position: absolute;
`;

const PlainImageLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top}px;
    background-image: url(${ p => p.src });
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${p => p.layout.borderRadius}px;
`;

const PlainImageTextLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top}px;
    background-image: url(${ p => p.src });
    background-size: contain;
    background-position: ${p => p.position || "center"};
    background-repeat: no-repeat;
    &:after {
        position: absolute; 
        top: 0; 
        left: 0; 
        bottom: 0; 
        right: 0;
        margin-top: 40px; 
        z-index: 99999; 
        font-size: 16px; 
        color: #FFFFFF;
        text-align: center;
        text-shadow: 0px 0px 5px black;
        padding: 5px;
    }
`;

const BackgroundLayer = styled(PlainImageLayer)`
    filter: url(#filter-background);
`;

const PrimaryLayer = styled(PlainImageLayer)`
    filter: url(#filter-primary);
`;

const SecondaryLayer = styled(PlainImageLayer)`
    filter: url(#filter-secondary);
`;

class SvgFilter extends Component {
    render() {
        const { filterId, rgbValues } = this.props;
        const matrix = `0 0 0 0 ${rgbValues[0]} 0 0 0 0 ${rgbValues[1]} 0 0 0 0 ${rgbValues[2]} 0 0 0 1 0`;
        return (
            <filter id={filterId} colorInterpolationFilters="sRGB">
                <feColorMatrix type="matrix" values={matrix} />
            </filter>
        );
    }
}

class FilteredPngStack extends Component {
    renderLayers(currentScreen, logoUrl, iconUrl) {
        const logoAspect = 3.43;
        const logoSize = {
            width: LayerSizing.FRAME_WIDTH / 2,
            height: Math.floor(LayerSizing.FRAME_WIDTH / 2 / logoAspect)
        };

        const phoneLayout = {
            width: LayerSizing.FRAME_WIDTH,
            height: LayerSizing.FRAME_HEIGHT,
            left: 0,
            top: 0
        };
        const screenLayout = {
            width: LayerSizing.SCREEN_WIDTH,
            height: LayerSizing.SCREEN_HEIGHT,
            left: (LayerSizing.FRAME_WIDTH - LayerSizing.SCREEN_WIDTH) / 2,
            top: (LayerSizing.FRAME_HEIGHT - LayerSizing.SCREEN_HEIGHT) / 2
        };
        const logoLayout = {
            width: logoSize.width,
            height: logoSize.height - (logoSize.height / 5),
            left: currentScreen !== Screens.SPLASH ? LayerSizing.FRAME_WIDTH / 12 : (LayerSizing.FRAME_WIDTH - logoSize.width) / 2,
            top: (currentScreen === Screens.MAIN ? LayerSizing.FRAME_HEIGHT / 8.5 : LayerSizing.FRAME_HEIGHT / 2.2) - logoSize.height / 2
        };  

        const bodyAndMindLogoLayout = {
            width: logoSize.width,
            height: logoSize.height - (logoSize.height / 5),
            left: LayerSizing.FRAME_WIDTH / 10,
            top: LayerSizing.FRAME_HEIGHT / 3.50
        }; 

        const surveyLogoLayout = {
            width: logoSize.width,
            height: logoSize.height - (logoSize.height / 5),
            left: (LayerSizing.FRAME_WIDTH - logoSize.width) / 2,
            top: LayerSizing.FRAME_HEIGHT / 6.6
        }; 
        
        const bodyAndMindOverlayLayout = {
            width: LayerSizing.SCREEN_WIDTH + 16,
            height: LayerSizing.SCREEN_HEIGHT,
            left: 2,
            top: ((LayerSizing.FRAME_HEIGHT - LayerSizing.SCREEN_HEIGHT) / 2) - 4
        };
        
        const iconLayout = {
            width: 36,
            height: 36,
            left: (LayerSizing.FRAME_WIDTH - logoSize.width) / 1.55,
            top: LayerSizing.FRAME_HEIGHT / 2.25,
            borderRadius: 8
        }; 
        
        const hasCompanyLogo = logoUrl.charAt(0) !== "?";
        const companyLogo = hasCompanyLogo ? logoUrl : ImgYourLogoHere;

        const hasCompanyIcon = iconUrl !== "undefined?" && (iconUrl !== undefined && iconUrl.charAt(0) !== "?");
        const companyIcon = hasCompanyIcon ? iconUrl : ImgYourIconHere;
        
        const imageLayers = [];
        switch (currentScreen) {
        case Screens.MAIN:
            imageLayers.push(<BackgroundLayer key="MAIN_BackgroundLayer" layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PrimaryLayer key="MAIN_PrimaryLayer" layout={screenLayout} src={ImgQuotesPrimary} />);
            imageLayers.push(<SecondaryLayer key="MAIN_SecondaryLayer" layout={screenLayout} src={ImgQuotesSecondary} />);
            imageLayers.push(<PlainImageLayer key="MAIN_PlainImageLayer_screen" layout={screenLayout} src={ImgQuotesOverlay} />);
            imageLayers.push(<PlainImageTextLayer position="left" key="MAIN_PlainImageTextLayer" layout={logoLayout} src={companyLogo} />);
            imageLayers.push(<PlainImageLayer key="MAIN_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.ARTICLES:
            imageLayers.push(<BackgroundLayer key="ARTICLES_BackgroundLayer" layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PrimaryLayer key="ARTICLES_PrimaryLayer" layout={screenLayout} src={ImgArticlesPrimary} />);
            imageLayers.push(<PlainImageLayer key="ARTICLES_PlainImageLayer_screen" layout={screenLayout} src={ImgArticlesOverlay} />);
            imageLayers.push(<PlainImageLayer key="ARTICLES_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.BODY_AND_MIND:
            imageLayers.push(<PlainImageLayer key="BODY_AND_MIND_PlainImageLayer_screen" layout={bodyAndMindOverlayLayout} src={ImgBodyAndMindOverlay} />);
            imageLayers.push(<BackgroundLayer key="BODY_AND_MIND_BackgroundLayer" layout={screenLayout} src={ImgBodyAndMindBackground} />);
            imageLayers.push(<PrimaryLayer key="BODY_AND_MIND_PrimaryLayer" layout={screenLayout} src={ImgBodyAndMindPrimary} />);
            imageLayers.push(<PlainImageLayer key="BODY_AND_MIND_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            imageLayers.push(<PlainImageTextLayer position="left" key="BODY_AND_MIND_PlainImageTextLayer" layout={bodyAndMindLogoLayout} src={companyLogo} />);
            break;
        case Screens.NOTICEBOARD:
            imageLayers.push(<PlainImageLayer key="NOTICEBOARD_PlainImageLayer_screen" layout={screenLayout} src={ImgBackgroundNoticeboard} />);
            imageLayers.push(<BackgroundLayer key="NOTICEBOARD_BackgroundLayer" layout={screenLayout} src={ImgNoticeboardBackgroundOverlay} />);
            imageLayers.push(<PrimaryLayer key="NOTICEBOARD_PrimaryLayer" layout={screenLayout} src={ImgNoticeboardPrimary} />);
            imageLayers.push(<PlainImageLayer key="NOTICEBOARD_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.PLAYLISTS:
            imageLayers.push(<PlainImageLayer key="PLAYLISTS_PlainImageLayer_background" layout={screenLayout} src={ImgBackgroundPlaylists} />);
            imageLayers.push(<BackgroundLayer key="PLAYLISTS_BackgroundLayer" layout={screenLayout} src={ImgPlaylistsBackgroundOverlay} />);
            imageLayers.push(<PrimaryLayer key="PLAYLISTS_PrimaryLayer" layout={screenLayout} src={ImgPlaylistsPrimary} />);
            imageLayers.push(<PlainImageLayer key="PLAYLISTS_PlainImageLayer_playlist" layout={screenLayout} src={ImgPlaylistsOverlay} />);
            imageLayers.push(<PlainImageLayer key="PLAYLISTS_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.GOALS:
            imageLayers.push(<BackgroundLayer key="GOALS_BackgroundLayer" layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PrimaryLayer key="GOALS_PrimaryLayer" layout={screenLayout} src={ImgGoalTrackerPrimary} />);
            imageLayers.push(<PlainImageLayer key="GOALS_PlainImageLayer_screen" layout={screenLayout} src={ImgGoalArticle} />);
            imageLayers.push(<PlainImageLayer key="GOALS_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.SPLASH:
            imageLayers.push(<BackgroundLayer key="SPLASH_BackgroundLayer" layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PrimaryLayer key="SPLASH_Primary" layout={screenLayout} src={ImgSplashPrimary} />);
            imageLayers.push(<SecondaryLayer key="SPLASH_SecondaryLayer" layout={screenLayout} src={ImgSplashSecondary} />);
            imageLayers.push(<PlainImageLayer key="SPLASH_PlainImageLayer_logo" layout={logoLayout} src={companyLogo} />);
            imageLayers.push(<PlainImageLayer key="SPLASH_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.SURVEY:
            imageLayers.push(<BackgroundLayer key="SURVEY_BackgroundLayer" layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PlainImageTextLayer key="Survey_PlainImageTextLayer" layout={surveyLogoLayout} src={companyLogo} />);
            imageLayers.push(<PrimaryLayer key="SURVEY_PrimaryLayer" layout={screenLayout} src={ImgSurveyPrimary} />);
            imageLayers.push(<PlainImageLayer key="SURVEY_PlainImageLayer_screen" layout={bodyAndMindOverlayLayout} src={ImgSurveyOverlay} />);
            imageLayers.push(<PlainImageLayer key="SURVEY_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.PHONE:
            imageLayers.push(<PlainImageLayer key="PHONE_BackgroundLayer" layout={screenLayout} src={ImgPhoneScreen} />);
            imageLayers.push(<PlainImageLayer key="PHONE_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            imageLayers.push(<PlainImageLayer key="PHONE_PlainImageLayer_icon" layout={iconLayout} src={companyIcon} />);
            
            break;

        default:
            break;
        }

        return imageLayers;
    }

    render() {
        const { currentScreen, colourPalette, logoUrl, iconUrl } = this.props;

        const imageLayers = this.renderLayers(currentScreen, logoUrl, iconUrl);
        const { primaryColor, secondaryColor, backgroundColor } = colourPalette;

        return (
            <WidthContainer>
                <ZeroSizeSvg>
                    <SvgFilter filterId="filter-primary" rgbValues={primaryColor} />
                    <SvgFilter filterId="filter-secondary" rgbValues={secondaryColor} />
                    <SvgFilter filterId="filter-background" rgbValues={backgroundColor} />
                </ZeroSizeSvg>
                {imageLayers}
            </WidthContainer>
        );
    }
}

export default FilteredPngStack;
