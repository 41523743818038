import axios from "axios";
import { useState } from "react";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";

/**
 * Custom hook for saving user's permissions to db
 * @returns {{
 *      isSavingPermissions: boolean
 *      savePermissions: Function
 * }}
 */
export const useSavePermissions = () => {
    const [isSavingPermissions, setIsSavingPermissions] = useState(false);
    /**
     * @param {string} employeeUuid 
     * @param {array} permissionIds 
     */
    const savePermissions = async (employeeUuid, permissionIds, headers) => {
        setIsSavingPermissions(true);
        try {
            const formData = {
                employeeUuid: employeeUuid,
                permissionIds: JSON.stringify(permissionIds)
            };
            await axios.post(`${API_URL}/permissions`, formData, headers);
            Toast.success("User role and permissions have been updated.");
        } catch (error) {
            Toast.error(error);
        } finally {
            setIsSavingPermissions(false);
        }
    };

    return {
        isSavingPermissions,
        savePermissions
    };
};