import React, { useContext, useEffect, useState } from "react";
import { ROOM_ENCRYPTED, ROOM_MESSAGE, ROOM_MEMBER, BAD_ENCRYPTED, DECRYPTION_FAILURE_MESSAGE } from "src/constants/matrixEventTypes";
import styled from "styled-components";
import { isWithinTimeInterval } from "src/utils/dates";
import ChatBubble from "../components/ChatBubble";
import { ChatContext } from "../ChatContext";
import { doesObjectPropertyExist, getObjectByPropertyValue } from "src/utils/helpers";
import { UserKeys } from "src/constants/userDetails";
import { MatrixEvent } from "matrix-js-sdk";

const RoomNotification = styled.div`
    font-size: 12px;
    color: gray;
    width: 100%;
    font-weight: 400;
    text-align: center;
    padding: 3px 0;
`;

/**
 * Custom hook for managing room timeline events. this can still be broken down further for SRP
 * @param {array} roomTimeline 
 * @returns {{
 *      renderTimelineEvent: Function
 *      isAMembershipEvent: Function
 *      isAValidMessageEvent: Function
 *      displayMembershipTimelineEvent: Function
 * }}
 */
export const useRoomTimeline = (roomTimeline) => {
    const { currentChat, currentUserEmployeeUuid } = useContext(ChatContext);
    const [chatUsers, setChatUsers] = useState([]);
    useEffect(() => {
        if (currentChat !== null) {
            setChatUsers(currentChat.users);
        }
    }, [currentChat]);

    const renderTimelineEvent = (event, eventIndex) => {
        if (isAMembershipEvent(event)) {
            return (
                <RoomNotification key={event.event_id}>{ displayMembershipTimelineEvent(event) }</RoomNotification>
            );
        } else if (isAValidMessageEvent(event)) {
            return processMessageEvent(event, eventIndex);
        }

        return "";
    };

    const fetchUserName = (event) => {
        const userMatrixID = event.state_key;
        const user = currentChat?.users.find((user) => user.matrixUserId === userMatrixID);
        return user !== undefined ? `${user.employeeDetails.firstName} ${user.employeeDetails.lastName}` : false;
    };

    const displayMembershipTimelineEvent = (event) => {
        const content = event.content;
        const checkIfSenderIsChatOwner = `@${currentChat.ownerUuid}:memotivationapp.com` === event.sender && content?.membership === "join";
        const amITheChatOwner = `@${currentUserEmployeeUuid}:memotivationapp.com` === event.sender && checkIfSenderIsChatOwner;
        const action = checkIfSenderIsChatOwner ? "created" : content?.membership === "join" ? "joined" : "left";
        const userName = fetchUserName(event);
        if (currentChat.isGroupChat && !amITheChatOwner) {
            return userName !== false ? `${userName} has ${action} the chat.` : "";
        } else {
            return "";
        }
    };

    const processMessageEvent = (event, eventIndex) => {
        const senderDetails = getUserByMatrixUserId(chatUsers, event.sender);
        if (!senderDetails) {
            return "";
        }

        /**
         * see https://spec.matrix.org/v1.8/client-server-api/#local-echo for definition
         * used to determine whether we display the timestamp or a sending status */
        const isLocalEcho = ["sending", "encrypting"].includes(event.status);

        const message = event?.content?.msgtype === BAD_ENCRYPTED ? DECRYPTION_FAILURE_MESSAGE : event?.content?.body;

        const isChatByCurrentUser = senderDetails.employeeUuid === currentUserEmployeeUuid;

        const previousSenderUserId = findPreviousSenderUserId(eventIndex);
        const nextSenderUserId = findNextSenderUserId(eventIndex);

        /**
         * Show timestamp only if previous message of the same user is more than 5 minutes or if it is the last message
         * in the thread sent by the same user (treated as the first of consecutive in the code)
         */
        const previousMessageTimestamp = eventIndex > 0 ? roomTimeline[eventIndex - 1].origin_server_ts : null;
        const isWithin = isWithinTimeInterval(previousMessageTimestamp, event.origin_server_ts, 300);
        
        const isFirstOfConsecutive = event.sender !== previousSenderUserId;
        const isLastOfConsecutive = event.sender !== nextSenderUserId;

        const showTimeStamp = !isWithin;
        if (currentChat.matrixRoomId === event.room_id) {
            return (
                <ChatBubble
                    key={event.event_id}
                    message={message}
                    timestamp={event.origin_server_ts}
                    isChatByCurrentUser={isChatByCurrentUser}
                    photo={senderDetails.employeeDetails[UserKeys.PROFILE_PICTURE_URL]}
                    firstName={senderDetails.employeeDetails[UserKeys.FIRST_NAME]}
                    lastName={senderDetails.employeeDetails[UserKeys.LAST_NAME]}
                    isFirstOfConsecutive={isFirstOfConsecutive}
                    isLastOfConsecutive={isLastOfConsecutive}
                    isSending={isLocalEcho}
                    showTimeStamp={showTimeStamp}
                />
            );
        }
    };

    /**
     * also excludes message events with signs of redaction
     * event messages when redacted, does not change event type. a `redacted_because` property gets added instead
     * @param {object} event 
     * @returns boolean */
    const isAValidMessageEvent = (event) => {        
        const matrixEvent = new MatrixEvent(event);

        return [ROOM_ENCRYPTED, ROOM_MESSAGE].includes(matrixEvent.getType()) && !doesObjectPropertyExist(event, "redacted_because");
    };
    
    const isAMembershipEvent = (event) => {
        const matrixEvent = new MatrixEvent(event);
        return matrixEvent.getType() === ROOM_MEMBER;
    };

    /**
     * @param {array} users     // array of chat users object
     * @param {string} matrixUserId 
     * @returns {object|undefined}
     */
    const getUserByMatrixUserId = (users, matrixUserId) => {
        const user = getObjectByPropertyValue(users, "matrixUserId", matrixUserId);
        return user;
    };

    /**
     * Go back to the previous, or to the next event 
     * until we can get a valid message (only non-redacted messaging events)
     * and get the user_id
     */
    const findPreviousSenderUserId = (startingIndex) => {
        if (startingIndex !== 0) {
            for (let i = startingIndex - 1; i >= 0;i--) {
                if (roomTimeline[i]?.user_id
                    && !doesObjectPropertyExist(roomTimeline[i], "redacted_because")
                    && [ROOM_ENCRYPTED, ROOM_MESSAGE].includes(roomTimeline[i].type)
                ) {
                    return roomTimeline[i].user_id;
                }
            }
        }
        return null;
    };

    const findNextSenderUserId = (startingIndex) => {
        if (startingIndex !== roomTimeline.length) {
            for (let i = startingIndex + 1; i < roomTimeline.length; i++) {
                if (roomTimeline[i]?.user_id
                    && !doesObjectPropertyExist(roomTimeline[i], "redacted_because")
                    && [ROOM_ENCRYPTED, ROOM_MESSAGE].includes(roomTimeline[i].type)
                ) {
                    return roomTimeline[i].user_id;
                }
            }
        }
        return null;
    };

    return {
        renderTimelineEvent,
        isAMembershipEvent,
        isAValidMessageEvent,
        displayMembershipTimelineEvent
    };
};