import React, { useContext } from "react";
import styled from "styled-components";
import { Dialog } from "@material-ui/core/";
import { Header } from "./CreateChatModal";
import { Text, FlexContainer, Button } from "src/components";
import { Card, StyledInput } from "./create/Group";
import { ChatContext } from "../ChatContext";
import { capitalizeString } from "src/utils/helpers";
import UserAvatar from "./UserAvatar";

const Container = styled.div`
    position: relative;
    padding: 1.625rem 3.125rem 2.5rem 3.125rem;
    box-sizing: border-box;
`;
const ListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 1.4375rem;
    box-sizing: border-box;
    border-bottom: ${p => p.borderBottom};
`;

const CloseButton = styled(Button)`
    height: 3.4375rem;
    max-width: 11.4375rem;
    margin-top: 2.375rem;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #DBE5ED;
    border-radius: 0.625rem !important;
    color: #AFBBC6;
    font-size: 1rem;
    box-sizing: border-box;
    float: right;
`;

const ViewGroupChatModal = (props) => {
    const { showDialog, handleClose } = props;
    const { currentChat } = useContext(ChatContext);

    const renderUsers = () => {
        if (currentChat !== null) {
            const usersLength = currentChat.users.length;
            return currentChat.users.map((user, index) => (
                !user.currentMember ? "" :
                    <ListItem key={user.index} 
                        borderBottom={index + 1 === usersLength ? "none" : "1px solid #DBE5ED"}
                    >
                        <FlexContainer direction="row" tdirection="row" alignItems="center" justifyContent="space-between" width="100%">
                            
                            <FlexContainer direction="row" alignItems="center">
                                <UserAvatar width="3.125rem" height="3.125rem" hideInfo={true}
                                    photo={user.employeeDetails.profilePictureUrl}
                                    background="#D6D6D6"
                                    firstName={user.employeeDetails.firstName}
                                    lastName={user.employeeDetails.lastName}
                                    statusBorder="1px solid #fff"
                                    hideStatus={true}
                                    withShadow={true}
                                    pixelBorder="1px"
                                />
                                <Text margin="0" color="#7B8793" size="1rem" weight="500">
                                    { capitalizeString(user.employeeDetails.firstName) + " " + capitalizeString(user.employeeDetails.lastName) }
                                </Text>
                            </FlexContainer>
                            <FlexContainer>
                                { currentChat.ownerUuid === user.employeeUuid &&
                                    <Text color="#000000" weight="500" size="1rem" align="right">
                                        Group Admin
                                    </Text>
                                }
                            </FlexContainer>
                        </FlexContainer>
                    </ListItem>
            ));
        }
    };

    return (
        <Dialog open={showDialog} onClose={handleClose}
            maxWidth="md"
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    width: "95%",
                    maxWidth: "44.125rem",
                    height: "95%",
                    maxHeight: "49.625rem",
                    borderRadius: "1.25rem",
                    overflow: "auto",
                    background: "#F9FAFC",
                },
            }}
        >
            <Header>
                <Text color="#4B5155" size="1.125rem" weight="700" margin="0">
                    Group Details
                </Text>
            </Header>
            { currentChat !== null &&
                <Container>
                    <Text weight="700" align="left" color="#4B5155" size="1rem" margin="0 0 0.875rem 0">
                        Group Name
                    </Text>
                    <StyledInput style={{ margin: "0 0 0.875rem 0" }} value={currentChat.name} />
                    <Text weight="700" align="left" color="#4B5155" size="1rem" margin="0 0 0.875rem 0">
                        Group Members
                    </Text>
                    <Card style={{ height: "26.8125rem !important" }}>
                        { renderUsers() }
                    </Card>
                    <CloseButton onClick={() => handleClose()}>
                        Close
                    </CloseButton>
                </Container>
            }
            
        </Dialog>

    );
};

export default ViewGroupChatModal;