import React from "react";
import styled from "styled-components";
import PlusIcon from "src/img/PlusIcon.svg";
import PrimaryMethod from "src/img/airwallex/PrimaryMethod.svg";
import { Text, FlexContainer } from "src/components";

// The following were "borrowed" from the Airwallex form
import Amex from "src/img/airwallex/american express logo.svg";
import Mastercard from "src/img/airwallex/master card logo.svg";
import Visa from "src/img/airwallex/visa logo.svg";
import GenericCard from "src/img/airwallex/GenericCard.svg";
import PaymentMethodCardActions from "./PaymentMethodCardActions";

const Grid = styled.div`
    display: grid;
    grid-auto-rows: 12rem;
    gap: 1rem;
    grid-template-columns: 25rem 25rem;
    @media (max-width: 92rem) {
        grid-template-columns: 25rem;
    }
`;

const PaymentMethodContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-color: ${ p => p.borderColor || "black" };
    border-width: 1px;
    border-style: ${ p => p.borderStyle || "solid" };
    border-radius: 20px;
    cursor: ${ p => p.cursor || "auto" };
    opacity: 1;
    padding: 1.5rem;
    box-shadow: 0px 3px 10px #0000001A;
`;

const TextDiv = styled.div`
    position: absolute;
    font-size: 16px;
`;

const Icon = styled.img`
    width: ${ p => p.size || "1.4rem" };
    height: ${ p => p.size || "1.4rem" };
    margin: ${ p => p.margin };
`;

function CardLogo(props) {
    const { brand } = props;
    let source;
    switch (brand.toLowerCase()) {
    case "visa":
        source = Visa;
        break;
    case "mastercard":
        source = Mastercard;
        break;
    case "american express":
        source = Amex;
        break;
    default: 
        source = GenericCard;
        break;
    }
    return <img src={ source } alt={ brand } />;
}

const CardText = styled(Text)`
    color: ${ p => p.color || "black" };
    text-align: start;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 16px;
`;

function CardItem(props) {
    /** @type GenericPaymentMethod */
    const item = props.item;

    const setDefault = item => {
        props.onSetDefaultClicked(item);
    };

    const deleteItem = item => {
        props.onDeleteClicked(item);
    };

    return (
        <PaymentMethodContainer borderColor="#E9EBEF">
            <div style={{ flexGrow: "1" }}>
                <PaymentMethodCardActions item={item} setDefault={setDefault} deleteItem={deleteItem} />

                <TextDiv width="100%">
                    <CardText margin="0"><CardLogo brand={ item.cardBrand } /></CardText>
                    <CardText weight={700} margin="1rem 0 0">•••• •••• •••• { item.cardLast4 } | Expires { item.expiration }</CardText>
                    <CardText weight={500} margin="0.5rem 0 0">{ item.cardName } &nbsp;</CardText>
                </TextDiv>
            </div>
            <div style={{ marginTop: "20px 0", display: "flex", justifyContent: "end", alignItems: "center" }}>
                { item.isDefault &&
                    <>
                        <img style={{ width: "1.6rem", height: "1.6rem" }} src={PrimaryMethod} alt="Default Method" />
                        <span style={{ fontFamily: "Roboto, Helvetica, sans-serif", fontSize: "14px", fontWeight: "700", color: "#76C00D", margin: "auto 0 auto 0.5rem" }}>Primary Method</span>
                    </>
                }
            </div>
        </PaymentMethodContainer>
    );
}

function AddPaymentMethodButton(props) {
    return (
        <PaymentMethodContainer borderStyle="dashed" cursor="pointer" onClick={props.onAddPaymentMethodClicked}>
            <FlexContainer direction="column" justifyContent="center" alignItems="center" height="100%">
                <Icon src={PlusIcon} />
                <CardText >
                    Add a payment method
                </CardText>
            </FlexContainer>
        </PaymentMethodContainer>
    );
}

function CardGrid(props) {
    /** @type {GenericPaymentMethod[]} */
    const items = props.paymentMethods;

    const onSetDefaultClicked = props.onSetDefaultClicked;
    const onDeleteClicked = props.onDeleteClicked;
    return (
        <Grid>
            { items.map((item, key) =>
                <CardItem item={item} key={key} onSetDefaultClicked={onSetDefaultClicked} onDeleteClicked={onDeleteClicked} />
            ) }
            <AddPaymentMethodButton onAddPaymentMethodClicked={props.onAddPaymentMethodClicked} />
        </Grid>
    );
}

export default CardGrid;
