import React, { Component } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import { Button } from "src/components";
import addColourImg from "src/img/addColour.png";

const ColorPop = styled.div`
    position: absolute;
    z-index: 2;
`;

const ColorCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const ColorCircle = styled.span`
    width: 105px;
    height: 105px;
    display: inline-block;
    border-radius: 50%;
    background-color: ${ p => p.color};
    background-image: ${p => !p.isPreview ? `url(${addColourImg})` : "none"};
    background-size: 21px;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid black;
`;

class ColorPicker extends Component {
    state = {
        isColorClicked: false,
        untouched: true,
    };

    handleClick = () => {
        this.setState({ isColorClicked: !this.state.isColorClicked, untouched: false });
        this.props.setSelectedColour(true);
    };

    handleClose = () => {
        this.setState({ isColorClicked: false });
    };

    handleColorChange = (color) => {
        this.props.handleColorChange(color);
    }; 

    render() {
        const { color, isPreview } = this.props;
        const { isColorClicked, untouched } = this.state;

        return (
            <>
                {
                    isPreview ? /* to remove the plus sign(addColour.png), used by Finish.js */
                        <ColorCircle color={ color } isPreview={isPreview} />
                        :
                        <Button>
                            <div>
                                <ColorCircle color={ color } onClick={this.handleClick} isPreview={!untouched}/>
                            </div>
                            {isColorClicked &&
                        <ColorPop>
                            <ColorCover onClick={this.handleClose}/>
                            <SketchPicker color={color} onChange={this.handleColorChange} />
                        </ColorPop>
                            }
                        </Button>
                }
            </>
        );
    } 
}

export default ColorPicker;