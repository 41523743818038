import React, { useState } from "react";
import Permission from "./PermissionType";
import { 
    Button, Text, FormField, Toast,
    mobile, tablet, desktop, AuthenticatedLink
} from "src/components";
import Checkbox from "./Checkbox";
import SearchImg from "src/img/employeeList/SearchMagnifyingGlass.svg";
import UpArrow from "src/img/employeeList/SortUpArrow.svg";
import DownArrow from "src/img/employeeList/SortDownArrow.svg";

import { NO_ROLE } from "src/constants/premissionRoles";
import { getCompanySubscriptionEnd, getCompanySubscriptionExpiryString } from "src/utils/dates";
import styled from "styled-components";
import { capitalizeString } from "src/utils/helpers";
import { UserContext } from "src/scenes/App/UserContext";
import { API_URL } from "src/scenes/App";
import { SortableColumns, SortableColumnState } from "../EmployeeList";
const UpperControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 1512px;
    background: #FFF;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
    
    ${tablet`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${mobile`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${desktop`
        padding: 0;
        flex-direction: row;
    `};
`;

const DownloadCsv = styled(AuthenticatedLink)`
    background-color: #000000; 
    color: #FFF; 
    font-size: 0.8750em; 
    font-weight: 500; 
    padding: 10px 21px; 
    border-radius: 10px; 
    cursor: pointer;
    text-decoration: none;
    width: 190px;
    display: block;
    margin-right: 1.3em;
`;

const HeaderTh = styled.th`
    cursor: pointer;
    background-color: #EAEDF4 !important;
    :hover {
        background-color: #EAEDF4;
        opacity: 0.8;
    }
`;

const UserListsTable = (props) => {
    const userContext = React.useContext(UserContext);
    const { sortColumn, sortableColumnStates, openPermissionForm, openAddNewUserOptions, openUserDetailsForm, removeUsers, openSendInviteForm, isShowingClients } = props;
    const [ selectedUsers, setSelectedUsers ] = useState([]);

    const [ users, setUsers ] = useState(props.users);

    const tbWidths = ["5%", "9%", "9%", "18%", "18%", "18%", "7%", "8%", "11%", "8%", "8%", "7%", "15%"];

    const renderUserStatus = (status, hasReceivedInvitation) => {
        return status ? <Text color="#36BE24" align="left">Active</Text>
            :
            hasReceivedInvitation ? <Text color="#006CFF" align="left">Pending</Text>
                :
                <Text color="#FF3B3B" align="left">Inactive</Text>;
    };

    const renderUserInviteStatus = (user) => {
        const InviteSent = selectedUsers.length === 1 && checkIfUuidIsSelected(user.uuid) 
            ? <Button onClick={() => handleSendInvite(user)} className="employee-list-table-btn" backgroundColor="#006CFF" color="#fff">Resend Invite</Button>
            : <span style={{ marginLeft: "10px" }}>Invite Sent</span>;
        return user.status ? <div style={{ fontWeight: "500", textAlign: "center" }}>-</div>
            : user.hasReceivedInvitation ? InviteSent
                : <Button onClick={() => handleSendInvite(user)} className="employee-list-table-btn" backgroundColor="#006CFF" color="#fff">Send Invite</Button>;
    };

    const emitOpenPermissionForm = (user) => {
        const dataToEmit = {
            uuid: user.uuid,
            name: capitalizeString(user.firstName) + " " + capitalizeString(user.lastName),
            email: user.email,
            isClient: user.isClient,
            leadershipRoles: user.leadershipRoles || [],
            // because users with no role and permission record in the db will have `roleAndPermissions: null`
            role: user.roleAndPermissions ? user.roleAndPermissions.roleId : NO_ROLE,
            permissions: user.roleAndPermissions ? JSON.parse(user.roleAndPermissions.permissionIds) : []
        };
        return openPermissionForm(dataToEmit);
    };

    const emitOpenUserDetailsForm = user => {
        // returns null when "Add New User" button is clicked
        return openUserDetailsForm(user);
    };

    /** @param {string} heading */
    const emitSortColumn = (heading) => {
        
        heading = heading.replace(/ /g, "_").toUpperCase();
        return sortColumn(heading);
    };

    // this is for the checkboxes
    const checkIfUuidIsSelected = (uuid) => selectedUsers.some(obj => obj["uuid"] === uuid);
    
    const addOrRemoveUserFromSelected = (event, user) => {
        const isAlreadyInList = checkIfUuidIsSelected(user.uuid);

        if (!isAlreadyInList) {
            const data = {
                uuid: user.uuid,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                role: filterUserRole(user)
            };
            setSelectedUsers([...selectedUsers, data]);
        } else {
            setSelectedUsers(selectedUsers.filter((obj) => obj["uuid"] !== user.uuid));
        }
    };

    const handleSearchUser = (event) => {
        const value = event.target.value;
        if (value.length) {
            const filteredUsers = props.users.filter(item => 
                item.firstName.toLowerCase().includes(value.toLowerCase()) || item.lastName.toLowerCase().includes(value.toLowerCase()) 
            );

            setUsers(filteredUsers);
        }
        else {
            setUsers(props.users);
        }
    };

    const handleSelectAllCheckbox = (e) => {
        const isChecked = !e.target.checked;
        
        if (!isChecked) {
            setSelectedUsers([]);
        }
        else {
            const arr = users.map(user => ({
                uuid: user.uuid,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                role: user.roleAndPermissions == null ? NO_ROLE : user.roleAndPermissions.roleId
            }));
            setSelectedUsers(arr);
        }
    };

    const handleUserRemoval = () => {
        // account owner roles cannot be deleted.
        const result = checkIfSelectedUsersContainsAccountOwner();
        if (result.length > 0) {
            return Toast.error("Cannot remove users with Account Owner permission. Please de-select such users first.");
        }
        else {
            let userUuidsForDeletion = [];
            selectedUsers.forEach(user => userUuidsForDeletion.push(user.uuid));
            return removeUsers(userUuidsForDeletion);
        }
    };

    const handleSendInvite = (user = null) => {
        const dataToSend = user !== null ? [user] : selectedUsers;
        return openSendInviteForm(dataToSend);
    };

    const checkIfSelectedUsersContainsAccountOwner = () => selectedUsers.filter(obj => obj["role"] === 1);

    const filterUserRole = (user) => user.roleAndPermissions == null ? NO_ROLE : user.roleAndPermissions.roleId;

    /**
     * @param {Element|string} heading
     * @param {number} index
     * @param {MouseEvent} event
     */
    const onHeadingClicked = (heading, index, event) => {
        if (typeof heading === "string") {
            emitSortColumn(heading);
        } else if (index === 0) {
            handleSelectAllCheckbox(event);
        }
    };

    const tableHeading = [ 
        // callback is in render - headings. onChange is only for warning prevention
        <Checkbox readOnly checked={selectedUsers.length === users.length} onChange={() => ""} key="0" />,
        "First Name",
        "Last Name",
        "Email",
        "User Type",
        isShowingClients ? "Client Type" : "Department",
        "Job Title",
        "View/Edit User",
        <span style={{ marginLeft: "15px" }}>Permissions</span>,
        "User Invites",
        "User Access Code",
        "User Status",
        "Subscription Status"
    ];

    const sortableHeading = {
        [SortableColumns.FIRST_NAME]: tableHeading[1],
        [SortableColumns.LAST_NAME]: tableHeading[2],
        [SortableColumns.EMAIL]: tableHeading[3],
        [SortableColumns.DEPARTMENT]: tableHeading[5],
        [SortableColumns.JOB_TITLE]: tableHeading[6],
        [SortableColumns.USER_STATUS]: tableHeading[11]
    };

    const { startDate, subscriptionPeriod } = props;
    const endDate = getCompanySubscriptionEnd(startDate, subscriptionPeriod, true);
    const expiryString = getCompanySubscriptionExpiryString(endDate, true);

    const usersList = users.map(user => {
        const role = user.roleAndPermissions;
        const hasLeadershipRole = user.leadershipRoles.length > 0;
        return (
            <tr key={user.uuid} className={checkIfUuidIsSelected(user.uuid) ? "selected" : "" }>
                <td width={tbWidths[0]} style={{ textAlign: "center" }}>
                    <label>
                        <Checkbox readOnly
                            checked={checkIfUuidIsSelected(user.uuid)}
                            value={user.uuid}
                            onChange={(e) => addOrRemoveUserFromSelected(e, user)} />
                    </label>
                </td>
                <td width={tbWidths[1]} className="change-color-on-select">{user.firstName}</td>
                <td width={tbWidths[2]} className="change-color-on-select">{user.lastName}</td>
                <td width={tbWidths[3]} className="change-color-on-select">{ user.email }</td>
                <td width={tbWidths[4]}>{ user.isClient ? "Client" : "Employee" }</td>
                <td width={tbWidths[5]} className="change-color-on-select">{ user.department }</td>
                {!isShowingClients && <td width={tbWidths[6]} className="change-color-on-select">{ user.employmentPosition }</td>}
                
                <td width={tbWidths[7]}>
                    <Button className="employee-list-table-btn" backgroundColor="#FFFFFF" 
                        color="#000" style={{ border: "1px solid #000" }}
                        onClick={() => emitOpenUserDetailsForm(user)}
                    >
                        View/Edit
                    </Button>
                </td>
                <td width={tbWidths[8]} 
                    style={{
                        cursor: "pointer",
                        pointerEvents: "auto"
                    }}
                    onClick={() => emitOpenPermissionForm(user)}
                >
                    <div style={{ display: "inline-grid", gridGap: "0.2em" }}>
                        <Permission role={role ? role.roleId : NO_ROLE} isClient={user.isClient} hasLeadershipRole={hasLeadershipRole} fontSize={hasLeadershipRole ? "10px" : ""} />
                    </div>
                </td>
                <td width={tbWidths[9]}>
                    { renderUserInviteStatus(user) }
                </td>
                <td width={tbWidths[10]} style={{ textAlign: "center" }}>
                    <Button className="employee-list-table-btn" backgroundColor="#000000" color="#fff"
                        style={{ maxWidth: "8em", cursor: "auto" }}
                    >
                        { user.accessToken }
                    </Button>
                </td>
                <td width={tbWidths[11]}>
                    <div style={{ marginLeft: "1px", }}>{ renderUserStatus(user.status, user.hasReceivedInvitation) }</div>
                </td>
                <td width={tbWidths[12]}>{ expiryString }</td>
            </tr>
        );
    });

    const showEmptyRows = () => {
        const emptyRows = 10 - users.length;
        
        if (emptyRows < 1)
            return;

        return Array.from({ length: emptyRows }, (_, i) => (
            <tr key={"empty_" + i}>
                <td className="change-color-on-select">&nbsp;</td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                {!isShowingClients && <td className="change-color-on-select"></td>}
            </tr>
        ));
    };

    return (
        <>
            <UpperControls>
                <div style={{ position: "relative",
                    width: "100%",
                    maxWidth: "35rem",
                    marginRight: "1.3em"
                }}>
                    <FormField type="text" placeholder="Search" onChange={(e) => handleSearchUser(e)}
                        margin="1.8em 1.4em"
                        border="1px solid #000000"
                        placeholderAlignment="left"
                        style={{
                            backgroundImage: `url(${SearchImg})`,
                            backgroundRepeat: "no-repeat",
                            textIndent: "1.5625rem",
                            backgroundPosition: "16px center",
                            lineHeight: "100%",
                            fontSize: "0.875rem",
                            width: "90%",
                            borderRadius: 10,
                            height: "2.4375rem",
                            textAlign: "left"
                        }}
                    />

                </div>
                
                <div>
                    {!isShowingClients && <DownloadCsv url={`${API_URL}/employees/export`} filename={"EmployeeList.csv"} user={userContext}>Download CSV Employee List</DownloadCsv>}
                    {isShowingClients && <DownloadCsv url={`${API_URL}/clients/export`} filename={"ClientList.csv"} user={userContext}>Download CSV Client List</DownloadCsv>}
                </div>
                { selectedUsers.length ?
                    <Button marginLeft="auto" backgroundColor="#ff6464" marginRight="1.3em" color="#fff"
                        borderRadius="10px !important"
                        onClick={() => handleUserRemoval()}
                    >
                            Remove Selected Users
                    </Button>
                    : ""
                }
                { selectedUsers.length > 1 ?
                    <Button marginLeft="auto" backgroundColor="#EAF3FF" border="1px solid #006CFF"
                        color="#006CFF" weight="700" marginRight="1.3em"
                        borderRadius="10px !important"
                        onClick={() => handleSendInvite()}
                    >Resend Invite/Code</Button>
                    : ""
                }

                <Button marginLeft="auto" backgroundColor="#006CFF " border="1px solid #006CFF"
                    color="#fff" weight="700" marginRight="39px"
                    borderRadius="10px !important"
                    onClick={() => openAddNewUserOptions()} 
                >Add New Users</Button>
            </UpperControls>
            <div className="scrollable-table">
                <table>
                    <thead>
                        <tr>
                            <HeaderTheads
                                isShowingClients={isShowingClients}
                                sortableColumnStates={sortableColumnStates}
                                sortableHeading={sortableHeading}
                                tableHeading={tableHeading}
                                onHeadingClicked={onHeadingClicked}
                                tbWidths={tbWidths}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        { usersList }
                    
                        { showEmptyRows() }
                    </tbody>
                </table>
            </div>
        </>
    );
};

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-left: auto; /* Push image to the right */
        padding-left: 5px;
    }
`;

const HeaderTheads = ({ isShowingClients, sortableColumnStates, sortableHeading, tableHeading, onHeadingClicked, tbWidths }) => {
    const nonInactiveIndex = sortableColumnStates.findIndex(item => item !== "INACTIVE");
    const activeSortableHeadingString = sortableHeading[nonInactiveIndex];
    return tableHeading.map((heading, index) => {
        
        if (isShowingClients && heading === "Job Title")
            return null;

        return (
            <HeaderTh onClick={(e) => onHeadingClicked(heading, index, e)}
                key={index}
                width={tbWidths[index]}
                style={{ textAlign: index > 0 ? "left" : "center" }}
            >
                <HeaderContainer>
                    <span key={index}>{heading}</span>
                    <SortingIcon sortingStatus={activeSortableHeadingString === heading ? sortableColumnStates[nonInactiveIndex] : ""} />
                </HeaderContainer>
            </HeaderTh>
        );
    });
};

const SortingIcon = ({ sortingStatus }) => {
    const imgStyle = {};

    if (sortingStatus === SortableColumnState.ASCENDING) {
        return <img src={UpArrow} alt={SortableColumnState.ASCENDING} style={imgStyle} />;
    }

    if (sortingStatus === SortableColumnState.DESCENDING) {
        return <img src={DownArrow} alt={SortableColumnState.ASCENDING} style={imgStyle} />;
    }

    return <></>;
};

export default UserListsTable;