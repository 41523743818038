import React from "react";
import styled from "styled-components";
import { renderChatListTimestamp } from "src/utils/dates";
import { Text } from "src/components";

const StyledText = styled(Text)`
    font-weight: ${ p => p.weight || 400 };
    color: ${ p => p.color || "#000000" };
    font-size: ${ p => p.size };
    margin: ${ p => p.margin || "1px" };
    text-align: ${ p => p.align || "left"};
`;

const ListDate = (props) => {
    const { timestamp } = props;

    return (
        <StyledText size="0.8125rem" weight="500" color="#7B8793" width="auto" flexGrow="1" flexShrink="0">
            { timestamp ? renderChatListTimestamp(timestamp) : "" }
        </StyledText>
    );
};

export default ListDate;