import React, { Component } from "react";
import { CustomiseContext } from "../CustomiseContext";
import DownloadApple from "src/img/Apple@2x.png";
import DownloadGoogle from "src/img/Google@2x.png";
import { Text, FlexContainer, Button } from "src/components";

class Email extends Component {
    static contextType = CustomiseContext;

    render() {
        const { email, nextClick } = this.context;
    
        return (
            <FlexContainer width="70%">
                <Text size="25px" color="#612684" margin="10px 0px 10px 0px" align="left" weight="700">
                    Get The App!
                </Text>

                <Text size="1em" align="left" weight="700">
                    Your Me Business App Is Now Ready
                </Text>

                <Text size="0.8750em" align="left">
                    We are pleased to inform you that your <b>Access Code</b> has been 
                    sent to your email address, <b>{email}.</b> 
                    This code is expected to arrive shortly. 
                    <br/><br/>
                    To commence using your custom Me Business app, 
                    kindly download the <b>&rsquo;Me App&rsquo;</b> from either the <b>App Store</b> or <b>Google Play, </b>
                    and select the <b>invite login</b> button to access your app. 
                    <br/><br/>
                    Should you encounter any difficulty locating the confirmation email, 
                    we recommend checking your spam or junk folder. 
                    <br/><br/>
                    Thank you for choosing our services. 
                    If you require any further assistance, please do not hesitate to reach out to our support team.
                </Text>

                <Text size="1em" align="left" weight="700">
                    Download The Me App
                </Text>

                <FlexContainer display="table-cell" mWidth="unset">
                    <a href="https://apps.apple.com/au/app/daily-fix-me-motivation/id1445382703" target="_blank" rel="noopener noreferrer">
                        <img src={DownloadApple} alt="Download Apple" height="40px" style={{ padding: "10px", marginLeft: "-9px" }}/>
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.onpoint_holdings.dailyfixme" target="_blank" rel="noopener noreferrer">
                        <img src={DownloadGoogle} alt="Download Google" height="60px" />
                    </a>
                </FlexContainer>

                <Text size="1em" align="left" weight="700">
                    Get Support
                </Text>
                
                <a href="https://www.memotivationapp.com/contact" target="_blank" rel="noopener noreferrer" style={{ width: "140px", borderRadius: "10px", color: "#000", fontSize: "0.9375em", fontWeight: "500", border: "1px solid #000", padding: "10px 0px", textDecoration: "none", textAlign: "center" }}>
                    Contact Us
                </a>

                <Button width="140px" onClick={nextClick} borderRadius="7px !important" backgroundColor="#000" color="#FFF" size="0.9375em" padding="10px 0px" marginTop="4em" marginBottom="1em">
                    Done 
                </Button>
            
            </FlexContainer>
        );
    }
}

export default Email;