import React, { useState, useEffect } from "react";
import { Box, Dialog, Divider } from "@material-ui/core/";
import { UserKeys } from "src/constants/userDetails";

import {
    LoadingIndicator, FlexContainer, Button,
    ExplainParagraphLabel, PageHeadingLabel
} from "src/components";

import {
    Card, Label, Number, BottomErrorMessage, TableTemplate
} from "./EditUserList";

import {
    // useEffect
    getRemainingLicense, countNewUsers,
    // CsvListContainer
    saveAllDirty,
    // ImportUsersInputField
    ClearUpload, InputField, firstNameErrors, lastNameErrors, emailErrors, phoneErrors, checkPropertyFieldIsEmptyAddError, propertyFieldIsNotEmptyRemoverError, repositionErrorMessageIndexes, Tr
} from "./EditUserList";

import {
    CountryPhoneInput
} from "src/components";

const EditUserListModal = (props) => {
    const { uploadUsers, showDialog, handleClose, isClient, openUploadUserListModal, uploadCsvPostEmployees, newUser, token, fetchingCsv, countryCode, existingUserEmailsResponse } = props;
    const [importUsers, setImportUsers] = useState(uploadUsers);
    const [errorList, setErrorList] = useState([]);
    const [totalLicense, setTotalLicense] = useState(0);
    const [isAmountOfUserExceeds, setIsAmountOfUserExceeds] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [existingEmails, setExistingEmails] = useState({ "existingEmployeeEmails": [], "existingClientEmails": [] });
    const [totalNewUsersInCsvList, setTotalNewUsersInCsvList] = useState(0);
    const [hasExistedUser, setHasExistedUser] = useState(false); 

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            
            const remainingLicense = await getRemainingLicense(token);
            setTotalLicense(remainingLicense);

            setExistingEmails(existingUserEmailsResponse);

            const countNewUsersTotal = countNewUsers(importUsers, existingUserEmailsResponse);
            setTotalNewUsersInCsvList(countNewUsersTotal);

            //Total New Users in CSV List
            setIsAmountOfUserExceeds(remainingLicense < countNewUsersTotal);

            setIsLoading(false);
        })();
    }, []);

    const handleModalClose = (_, reason) => {
        if (reason !== "backdropClick") {
            handleClose();
        }
    };

    return (
        <React.Fragment>
            <Dialog fullWidth 
                open={showDialog} onClose={() => handleModalClose}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
        		aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        height: "auto",
                        borderRadius: 15,
                        overflow: "visible",
                        maxWidth: "96em",
                        minHeight: "30em",
                    },
                }}
            >
                {
                    isLoading ? <LoadingContainer/> :
                        <CSVListContainer 
                            isClient={isClient}
                            importUsers={importUsers}
                            setImportUsers={setImportUsers}
                            totalLicense={totalLicense}
                            totalNewUsersInCsvList={totalNewUsersInCsvList}
                            setErrorList={setErrorList}

                            newUser={newUser}
                            saveAllDirty={() => { saveAllDirty(setErrorList, importUsers, newUser, isClient, isAmountOfUserExceeds, uploadCsvPostEmployees, handleClose); } }
                            handleClose={handleClose}
                            openUploadUserListModal={openUploadUserListModal}
                            fetchingCsv={fetchingCsv}
                            isAmountOfUserExceeds={isAmountOfUserExceeds}
                            //importUsersInputFields
                            existingEmails={existingEmails}
                            errorList={errorList}
                            setTotalNewUsersInCsvList={setTotalNewUsersInCsvList}
                            countNewUsers={countNewUsers}
                            setIsAmountOfUserExceeds={setIsAmountOfUserExceeds}
                            countryCode={countryCode}
                            hasExistedUser={hasExistedUser}
                            setHasExistedUser={setHasExistedUser}
                        />
                }
            </Dialog>
        </React.Fragment>
    );
};

function LoadingContainer() {
    return (
        <div style={{ padding: "0px 55px 0 55px" }}>
            <Box>
                <FlexContainer direction="row" justifyContent="space-between" marginTop="10em"
                    style={{ maxWidth: "98.625rem" }}
                >
                    <LoadingIndicator/>
                </FlexContainer>
            </Box>
        </div>
    );
}


function CSVListContainer(props) {
    const { isClient, importUsers, setImportUsers, totalLicense, totalNewUsersInCsvList, errorList, isAmountOfUserExceeds, handleClose, openUploadUserListModal, fetchingCsv, setErrorList, saveAllDirty, setTotalNewUsersInCsvList, setIsAmountOfUserExceeds, countNewUsers, countryCode, hasExistedUser, setHasExistedUser } = props;
    const { existingEmails } = props; //importUsersInputFields
    const totalUsersInCsvList = importUsers.length.toLocaleString("en-US");
    const userLicensesAvailable = totalLicense.toLocaleString();

    const [isShowButtonWhenWrongUserType, setIsShowButtonWhenWrongUserType] = useState(false);

    function handleConfirm() {  
        setErrorList([]);
    
        if (!(importUsers.length > 0)) {
            setErrorList((prevErrorList) => [...prevErrorList, "* There is no user list to upload."]);
            return;
        }
    
        saveAllDirty();
    }

    return (
        <div style={{ padding: "0px 55px 0 55px" }}>
            <Box>
                <FlexContainer direction="row" justifyContent="space-between" marginBottom="1.625rem"
                    style={{ maxWidth: "98.625rem" }}
                >
                    <div style={{ marginRight: "3.1875rem" }}>
                        <PageHeadingLabel>{isClient ? "Client" : "Employee"} CSV User List</PageHeadingLabel>
                        <ExplainParagraphLabel width="100%" size="0.875rem" maxWidth="44rem">
                            <span style={{ color: "#000000" }}>Please review your uploaded list of employees or clients to ensure accuracy. Double-check details 
                                before confirming your submission. A moment of review can save hours of corrections later!</span>
                        </ExplainParagraphLabel>
                    </div>

                    <FlexContainer tDirection="row" direction="row" margin="2.25rem 0 0.5rem"
                        width="60%"
                        justifyContent="end"
                    >
                        <Card style={{ marginRight: "1em" }}>
                            <Label>Total Users in CSV List</Label>
                            <Number>{ totalUsersInCsvList }</Number>
                        </Card>
                        <Card style={{ marginRight: "1em", border: isAmountOfUserExceeds ? "1px solid #FF5353" : "inherit", color: isAmountOfUserExceeds ? "#FF5353" : "inherit" }}>
                            <Label>Total New Users in CSV List</Label>
                            <Number>{ totalNewUsersInCsvList }</Number>
                        </Card>
                        <Card>
                            <Label>User Licenses Available</Label>
                            <Number>{ userLicensesAvailable }</Number>
                        </Card>
                    </FlexContainer>
                </FlexContainer>
            </Box>

            <Divider style={{ marginBottom: "1em" }} />

            <TableTemplate isClient={isClient} >
                <ImportUsersInputFields
                    isClient={isClient}
                    importUsers={importUsers} 
                    setImportUsers={setImportUsers}  
                    existingEmails={existingEmails} 
                    errorList={errorList} 
                    setTotalNewUsersInCsvList={setTotalNewUsersInCsvList} 
                    setIsAmountOfUserExceeds={setIsAmountOfUserExceeds} 
                    totalLicense={totalLicense}
                    countNewUsers={countNewUsers} 
                    countryCode={countryCode}
                    isShowButtonWhenWrongUserType={isShowButtonWhenWrongUserType}
                    setIsShowButtonWhenWrongUserType={setIsShowButtonWhenWrongUserType}
                    hasExistedUser={hasExistedUser}
                    setHasExistedUser={setHasExistedUser}
                />
            </TableTemplate>

            <Divider style={{ marginTop: "1em", marginBottom: "1em" }} />

            <ErrorMessage 
                errorList={errorList} 
                isAmountOfUserExceeds={isAmountOfUserExceeds}
                isClient={isClient}
                isShowButtonWhenWrongUserType={isShowButtonWhenWrongUserType}
                hasExistedUser={hasExistedUser}
            />

            <Box display="flex" justifyContent="right" margin="auto">
                <div style={{ textAlign: "right", margin: "30px" }}>
                    <Button 
                        border="1px solid #000"
                        color="#000"
                        marginRight="15px"
                        width="140px"
                        onClick={ () => [handleClose(), openUploadUserListModal()]}
                        style={{ borderRadius: "7px", boxShadow: "none" }}
                        className="form-btns disabled"
                    >
                        Back
                    </Button>

                    { (fetchingCsv > 0) && <LoadingIndicator style={{ display: "inline-block", width: "10px", position: "relative", top: "2px" }} width="10px" height="10px" containerHeight="10" />}
                    { (fetchingCsv === 0) &&
                        <Button backgroundColor="#006CFF" color="#fff"
                            border="1px solid #006CFF"
                            width="140px"
                            style={{ borderRadius: "7px", boxShadow: "none" }}
                            type="submit"
                            onClick={() => handleConfirm() }
                            className="form-btns disabled"
                        >
                        Confirm
                        </Button>
                    }
                </div>
            </Box>
        </div>
    );
}

const ImportUsersInputFields = ({ importUsers, setImportUsers, existingEmails, isClient, errorList, setTotalNewUsersInCsvList, countNewUsers, setIsAmountOfUserExceeds, totalLicense, countryCode, setIsShowButtonWhenWrongUserType, setHasExistedUser }) => {
    const firstNameErrorList = firstNameErrors(errorList);
    const lastNameErrorList = lastNameErrors(errorList);
    const emailErrorList = emailErrors(errorList);

    const phoneErrorList = phoneErrors(errorList);

    function clearUploadOnClickHandler(index) {
        repositionErrorMessageIndexes(index, errorList, isClient);

        //remove that row in importUsers
        const updatedImportUsers = importUsers.filter((_, objIndex) => objIndex !== index);
        setImportUsers(updatedImportUsers);
    
        //Total New Users in CSV List
        const countNewUsersTotal = countNewUsers(updatedImportUsers, existingEmails);
        setTotalNewUsersInCsvList(countNewUsersTotal);
        setIsAmountOfUserExceeds(totalLicense < countNewUsersTotal);
    }

    function onChangeHandler(e, index, propertyField) {
        const updatedImportUsers = [...importUsers];
    
        updatedImportUsers[index] = { 
            ...updatedImportUsers[index], 
            [propertyField]: e.target.value 
        };
        
        if (propertyField === "email" 
            || propertyField === "firstName" 
            || (!isClient && propertyField === "lastName")) {
            if (!checkPropertyFieldIsEmptyAddError(e, index, propertyField, errorList)) {
                propertyFieldIsNotEmptyRemoverError(e, index, propertyField, errorList);
            }
        }
    
        setImportUsers(updatedImportUsers);

        //Total New Users in CSV List
        const countNewUsersTotal = countNewUsers(updatedImportUsers, existingEmails);
        setTotalNewUsersInCsvList(countNewUsersTotal);
        setIsAmountOfUserExceeds(totalLicense < countNewUsersTotal);
    }

    function handlePhoneNumber(phone, index, propertyField) {
        const updatedUsers = [...importUsers];
        
        if (phone !== "" && !phone.startsWith("+")) {
            phone = "+" + phone;
        }
    
        updatedUsers[index] = { 
            ...updatedUsers[index], 
            [propertyField]: phone
        };
    
        setImportUsers(updatedUsers);
    }

    function mapImportUsers() {
        let hasShownButtonWhenWrongUserType = false;
        let existedUserBool = false;

        const importUsersMapped = importUsers.map((user, index) => {
            const rowId = user.newUser ? "newUser_" + index : user.uuid;
                
            const isFirstNameInvalid = firstNameErrorList.includes(index);
            const isLastNameInvalid = lastNameErrorList.includes(index);
            const isEmailInvalid = emailErrorList.includes(index);
            const isPhoneInvalid = phoneErrorList.includes(index);
            
            const isExistingEmployee = existingEmails["existingEmployeeEmails"].includes(user.email);
            const isExistingClient = existingEmails["existingClientEmails"].includes(user.email);
            const isNewUser = !isExistingEmployee && !isExistingClient;
            const showButtonWhenWrongUserType = (isClient && isExistingEmployee) || (!isClient && isExistingClient);
            
            //check if needs to display wrong user
            hasShownButtonWhenWrongUserType = hasShownButtonWhenWrongUserType || showButtonWhenWrongUserType;

            //check if there was an existing user from the given list
            existedUserBool = existedUserBool || !isNewUser;

            return (
                <Tr key={rowId} isExistingUser={!(isNewUser || showButtonWhenWrongUserType)} showButtonWhenWrongUserType={showButtonWhenWrongUserType} >
                    <td>{ (isNewUser || showButtonWhenWrongUserType) && <ClearUpload onClick={ () => clearUploadOnClickHandler(index) }></ClearUpload> } </td>
                    <td><InputField value={user.firstName} errors={isFirstNameInvalid} onChangeHandler={(e) => onChangeHandler(e, index, "firstName")} /></td>
                    <td><InputField value={user.lastName} errors={isLastNameInvalid} onChangeHandler={(e) => onChangeHandler(e, index, "lastName")} /></td>
                    <td><InputField value={user.email} errors={isEmailInvalid} onChangeHandler={(e) => onChangeHandler(e, index, "email")} width="250px" /></td>
                    <td>
                        <CountryPhoneInput
                            autoFormat={false}
                            prefix={"+"}
                            error={isPhoneInvalid} //boolean
                            country={countryCode.toLowerCase()}
                            placeholder=""
                            value={user.phoneNumber}
                            onChange={(phone) => handlePhoneNumber(phone, index, "phoneNumber")}
                        />
                    </td>
                    {!isClient && <td><InputField value={user.department} errors={false} onChangeHandler={(e) => onChangeHandler(e, index, "department")} /></td>}
                    <td><InputField value={user.employmentPosition} errors={false} onChangeHandler={(e) => onChangeHandler(e, index, "employmentPosition")} /></td>
                </Tr>
            );
        });

        setIsShowButtonWhenWrongUserType(hasShownButtonWhenWrongUserType);     
        setHasExistedUser(existedUserBool);

        return importUsersMapped;
    }
    
    const mappedUsers = mapImportUsers();
    return mappedUsers;    
};

function ErrorMessage({ errorList, isAmountOfUserExceeds, isShowButtonWhenWrongUserType, isClient, hasExistedUser }) {
    /**
     * @returns {React.ReactElement|false}
     */
    const errorMessageForFirstAndLastName = () => {
        if (Object.prototype.hasOwnProperty.call(errorList, UserKeys.FIRST_NAME)) {
            return <BottomErrorMessage>You must provide a first name for every user</BottomErrorMessage>;
        } else if (Object.prototype.hasOwnProperty.call(errorList, UserKeys.LAST_NAME)) {
            return <BottomErrorMessage>You must provide a first name and last name for every user</BottomErrorMessage>;
        }
        return false;
    };

    /**
     * @returns {React.ReactElement|false}
     */
    const errorMessageForEmail = () => {
        if (Object.prototype.hasOwnProperty.call(errorList, UserKeys.EMAIL)) {
            return <BottomErrorMessage>Some email addresses are invalid or already in use</BottomErrorMessage>;
        }
        return false;
    };

    /**
     * @returns {React.ReactElement|false}
     */
    const errorMessageForPhones = () => {
        if (Object.prototype.hasOwnProperty.call(errorList, "phone")) {
            const value = errorList["phone"] && errorList["phone"][0][1];
            return typeof value === "string" && value.includes("exist") ?
                <BottomErrorMessage>Some mobile numbers are already in use</BottomErrorMessage> :
                <BottomErrorMessage>Some mobile numbers are invalid</BottomErrorMessage>;
        }
        return false;
    };

    const showMessageWhenUserExist = <div style={{ color: "#006CFF" }}>Existing Users or Updated User Information: Blue-highlighted rows indicate existing users; no licenses will be deducted.</div>;
    const noMoreAvailableUsers = <BottomErrorMessage>* The amount of users in this list exceeds the amount of available licenses. Please purchases additional licenses to continue or remove the required amount of users to match your available license.</BottomErrorMessage>;
    
    //when isShowButtonWhenWrongUserType is true
    const showMessageWhenWrongUserTypeEmployee = <BottomErrorMessage>User Exists as Client: 1 or more of the Employees in this list already exists as a ‘Client’ using the same email or mobile number.</BottomErrorMessage>;
    const showMessageWhenWrongUserTypeClient = <BottomErrorMessage>User Exists as Employee: 1 or more of the Clients in this list already exists as an ‘Employee’ using the same email or mobile number.</BottomErrorMessage>;
    
    return (
        <div style={{ width: "60%" }}>
            {hasExistedUser && showMessageWhenUserExist}
            {errorMessageForFirstAndLastName()}
            {errorMessageForEmail()}
            {errorMessageForPhones()}
            {isShowButtonWhenWrongUserType && !isClient && showMessageWhenWrongUserTypeEmployee}
            {isShowButtonWhenWrongUserType && isClient && showMessageWhenWrongUserTypeClient}
            {isAmountOfUserExceeds && noMoreAvailableUsers}
            
        </div>
    );
}

export default EditUserListModal;