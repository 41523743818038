import React, { Component } from "react";
import styled from "styled-components";
import { FlexContainer, Text } from "src/components";
import { StyledButton } from "src/scenes/Subscription/forms/Initial";
import SubscriptionPhone from "src/img/CustomizeInitial.png";
import { CustomiseContext } from "../CustomiseContext";

const Image = styled.img`
    width: 700px;
    height: auto;
`;

class Initial extends Component {
    static contextType = CustomiseContext;

    render() {
        const { nextClick } = this.context;
        return (
            <>
                
                <FlexContainer minWidth="500px" alignItems="center" family="Roboto, Helvetica, sans-serif">
                    <Text weight="700" size="1.875em" color="#2A2B2A" style={{ marginBottom: "0" }}>Customise Your Me Business App</Text>

                    <Image src={SubscriptionPhone} alt=""/>
                    <StyledButton onClick={nextClick} fontWeight="500" marginTop="2em" style={{ fontSize: "0.8750em", width: "202px", height: "39px", borderRadius: "10px", background: "#006CFF 0% 0% no-repeat padding-box", border: "none" }}>
                        Get Started!
                    </StyledButton>
                    <br/>
                </FlexContainer>
            </>
        );
    }
}

export default Initial;