export const WEB_DASHBOARD_ACCESS = 1;
export const MY_ME_APP = 2;
export const USER_LISTS = 3;
export const NOTICEBOARD = 4;
export const SURVEYS = 5;
export const STATISTICS = 6;
export const SALES_AND_PROMOTIONS = 7;
export const BILLING = 8;
export const NOTICEBOARD_APP = 9;
export const CREATE_BUSINESS_PLAYLISTS = 10;
export const FULL_ACCESS_BUSINESS_CHAT = 11;
export const MESSAGE_EMPLOYEES = 12;
export const MESSAGE_CLIENTS = 13;
export const CREATE_GROUP_CHAT = 14;
export const ACCOUNT_OWNER_PERMISSION = 15;
// insert team goal permission here = 16
export const DIRECTORY = 17;

/**
 * @param {array} requiredPermissions
 * @param {array} userPermissions
 */
export const hasPermissions = (requiredPermissions, userPermissions) => {
    return userPermissions.length > 0 ? 
        requiredPermissions.every((val) => userPermissions.includes(val)) : false;
};

/**
 * @param {int} implicitPermission 
 * @param {array} userPermissions 
 * @returns 
 */
export const hasImplicitPermission = (implicitPermission, userPermissions) => {
    return userPermissions.length > 0 ? userPermissions.includes(implicitPermission) : false;
};

/**
 * checks whether the user has either the required permission
 * or an implicit FULL_ACCESS_BUSINESS_CHAT
 * @param {int} permissionId 
 * @param {array} userPermissions 
 * @returns 
 */
export const isChatActionAllowed = (permissionId, userPermissions) => {
    const withImplicit = hasImplicitPermission(FULL_ACCESS_BUSINESS_CHAT, userPermissions);
    return withImplicit || hasPermissions([permissionId], userPermissions);
};