import styled from "styled-components";

export const Hr = styled.hr`
    margin: 0.75rem 0 1.25rem;
    width:100%;
`;

export const SubscriptionContainer = styled.div`
    box-shadow: 0px 5px 5px #00000026;
    border-radius: 12px;
`;

export const SubscriptionHeaderContainer = styled.div`
    background-color: #000;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    padding: 10px 10px 10px 30px;
    height: 50px;
`;
export const SubscriptionTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    flex: 8;
    line-height: 50px;
`;

export const SubscriptionStatus = {
    ACTIVE: "Active",
    EXPIRED: "Expired",
    REACTIVATE: "Reactivate"
};

export const SubscriptionBadgeContainer = styled.div`
    flex: 1;
    margin: auto 0; //center vertically
`;

export const SubscriptionBadge = styled.div`
    text-align: center;
    color: #FFF;
    background-color: ${p => p.status === SubscriptionStatus.ACTIVE ? "#36BE24" : p.status === SubscriptionStatus.EXPIRED ? "#FF5353" : "#006CFF" };
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    width: 102px;
    height: 32px;
    line-height: 32px;
    text-transform: uppercase;
`;

export const SubscriptionAction = styled.div`
    flex: 1;
    color: #000;
    text-align: right;
    margin: auto 0; //center vertically
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
`;

export const SubscriptionContentContainer = styled.div`
    margin: 1em 1em 2em 1em;
`;

export const CardGray = styled.div`
    background-color: ${p => p.backgroundColor || "#EAEDF4" }; 
    border-radius: 12px;
    padding: 15px;
    flex: 1;
    margin: 0 5px;
    margin-bottom: 5px;
`;

export const CardTitle = styled.div`
    font-size: 14px;
    padding: 5px 0;
    ${p => p.weight || "600"};
`;
export const CardContent = styled.div`
    font-size: ${p => p.size || "14px" };
    font-weight: ${p => p.weight || "600" };
    color: ${p => p.color || "inherit" };
`;

export const RedBackgroundButton = styled.button`
    text-align: center;
    color: #FF5353;
    background-color: #FFF;
    font-size: 14px;
    font-weight: 700;
    width: 102px;
    height: 32px;
    line-height: 28px;
    border: 1px solid #FF5353;
    border-radius: 10px;
    cursor: pointer;
`;