import React, { useState } from "react";
import styled from "styled-components";

import DownArrow from "src/img/SettingsDownArrow.svg";
import RightArrow from "src/img/SettingsRightArrow.svg";
import MoreMenuContainer from "./MoreMenuContainer";

const rowBorderTop = {
    borderTop: "2px solid #EBEDF1"
};
const rowBorderBottom = {
    borderBottom: "2px solid #EBEDF1"
};

const layoutStyle = {
    paddingLeft: "40px"
};

const tdStyle = {
    paddingTop: "2em",
    paddingBottom: "2em",
    paddingRight: "2em",
};

const firstTdStyle = {
    ...layoutStyle,
    ...tdStyle
};

const statementRowStyle = {
    height: "71px", backgroundColor: "#E8E8E8", fontSize: "1.2500em", fontWeight: "700", borderBottom: "2px solid #C1C1C1", borderTop: "2px solid #C1C1C1"
};

const Td = styled.td`
    font-weight: 500;
    font-size: 14px;
    color: ${p => p.color || "#000000"} /* invoice - #5A2A7F,  pending - #006CFF, paid - #36BE24 */
`;
   
function RenewalCommissionTable(props) {
    const earnings = props.earnings;
    const token = props.token;
    
    const [selectedIndex, setSelectedIndex] = useState(0);

    const renderedLicenseMonthlyEarnings = (licenseEarnings) => {
        return (
            <>
                {licenseEarnings.map((monthlyEarning, index) => (
                    renderedLicenseEarningsRows(monthlyEarning)
                ))}
            </>
        );
    };

    const isSummaryIndex = (detailsIndex) => {
        return detailsIndex === 0;
    };
    
    const renderedLicenseEarningsRows = (monthlyEarning) => {
        return (
            <>
                {monthlyEarning.map((earning, detailsIndex) => (
                    <tr key={"row_" + earning.uuid} style={ (monthlyEarning.length === (detailsIndex - 1)) ? rowBorderTop : { ...rowBorderTop, ...rowBorderBottom } } >
                        <Td key={"date_" + earning.uuid} style={firstTdStyle}>{isSummaryIndex(detailsIndex) ? earning.name : ""}</Td>
                        <Td key={"invoice_" + earning.uuid} color="#5A2A7F">{isSummaryIndex(detailsIndex) ? earning.invoiceNo : ""}</Td>
                        <Td key={"description_" + earning.uuid} >{earning.description}</Td>
                        <Td key={"renewals_" + earning.uuid} >{earning.numberOfRenewal}</Td>
                        <Td key={"value_" + earning.uuid} >{earning.totalValue}</Td>
                        <Td key={"earnings_" + earning.uuid} >{Math.floor(earning.earningPercent * 100) + "%"}</Td>
                        <Td key={"total_" + earning.uuid} >{earning.totalEarnings}</Td>
                        <Td key={"status_" + earning.uuid} color={earning.isPaid ? "#36BE24" : "#006CFF"}>{isSummaryIndex(detailsIndex) ? (earning.isPaid ? "Paid" : "Pending") : "" }</Td>
                        <Td key={"buttons_" + earning.uuid} > {isSummaryIndex(detailsIndex) ? <MoreMenuContainer earning={earning} token={token} /> : ""}</Td>
                    </tr>
                ))}
            </>
        );
    };

    const renderedStatementTBody = earnings.map((value, index) => {
        const heading = value.year;
        const licenseEarnings = value.licenseEarnings;

        return (
            <tbody key={"statement_" + index}>
                <tr style={statementRowStyle} >
                    <td colSpan="8" style={layoutStyle}>{heading} Statements</td>
                    <td><img onClick={ () => setSelectedIndex(selectedIndex === index ? -1 : index) } src={selectedIndex === index ? DownArrow : RightArrow} alt="Collapsible Button" /> </td>
                </tr>
                {selectedIndex === index && renderedLicenseMonthlyEarnings(licenseEarnings)}
            </tbody>
        );
    });

    return (
        <div style={{ overflow: "auto", margin: "0px", paddingBottom: "2em" }}>
            <table style={{ marginTop: "1em", width: "100%", fontSize: "0.8750em", fontWeight: "500", borderCollapse: "collapse" }} >
                <thead>
                    <tr style={{ backgroundColor: "#612684", fontSize: "14px", color: "#FFF", height: "43px" }}>
                        <td style={layoutStyle}>Date</td>
                        <td>Invoice/Statement</td>
                        <td>Description</td>
                        <td>Renewals</td>
                        <td>Value</td>
                        <td>Earnings %</td>
                        <td>Total Earnings<br/><sup>*</sup>Ex GST</td>
                        <td>Status</td>
                        <td>&nbsp;</td>
                    </tr>
                </thead>
                {renderedStatementTBody}
            </table>
        </div>
    );
}

export default RenewalCommissionTable;