import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import ArrowCaret from "../img/survey/arrow-caret.png";
import styled, { css } from "styled-components";
import Text from "./Text";

const InputSelector = styled.select`
        font-size: ${p => p.size || "0.8rem"};
        color: ${ p => p.color || "#000"};
        font-weight: ${ p => p.fontWeight || "bolder" };
        font-family: Roboto, Helvetica, sans-serif;
        padding: ${p => p.padding || 0};
        width: ${p => p.width || "100%"};
        height: 50px;
        border: 1px solid #E9EBEF;
        margin: ${p => p.margin || "5px 0"};
        text-align: ${p => p.align || "center"};
        box-sizing: border-box;
        border-radius: 12px;
        max-width: ${p => p.maxWidth};
        background: url(${p => p.selectIcon || ArrowCaret}) no-repeat right;
        background-position: calc(100% - 1rem) calc(100% - 1rem) !important;
        -moz-appearance:none !important;
        -webkit-appearance: none !important; 
        appearance: none !important;
        display: ${ p => p.display || "flex"};

        ::placeholder {
            color: ${p => p.placeholderColor || "#000"};
            font-weight: ${p => p.placeholderWeight || "400" };
            text-align: ${p => p.placeholderAlignment || "center"};
            font-size: ${p => p.placeholderSize || "0.875rem"};
            padding: ${p => p.placeholderPadding || "0"};
        };


        [value=""][disabled] { 
            color: #808080;
        }

        ${ p => p.isHighlighted && css`
            border: 1px solid red;
        `};
    `;
    
const ErrorText = styled(Text)`
        color: red;
        text-align: left;
        font-size: 12px;
        margin: ${p => p.margin || "0 20px 5px"};
        white-space: pre-wrap;
    `;

function CountrySelector(props) {
    const { value, placeholder, errors, onChange, onFocus, showErrors, ...styleProps } = props;
    const options = useMemo(() => countryList().getData(), []);
    const errorsArray = errors ? errors : [];
        
    return (
        
        <div>
            <InputSelector fontWeight="500" size="14px" 
                color={value === null || value === "" ? "#808080" : null}
                onChange={onChange}
                value={value}
                isHighlighted={errorsArray.length > 0}
                onFocus={onFocus}
                {...styleProps}
            >
                <option value="">{placeholder ? placeholder : "Select a country"}</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value} >
                        {option.label}
                    </option>
                ))}
            </InputSelector>
            { showErrors && <ErrorText>{errorsArray}</ErrorText> }
        </div>
    );
}

export default CountrySelector;