import React from "react";
import ReactDOM from "react-dom";
import App from "./scenes/App";
import "./stylesheets/application.css";
import * as Sentry from "@sentry/browser";
import { Provider } from "react-redux";
import store from "./redux/store";

Sentry.init({
    dsn: "https://98e2779a151d4bcb994055e81f334c6d@sentry.io/1336441"
});

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById("root")
);