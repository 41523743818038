import React from "react";
import { Grid, Box } from "@material-ui/core/";

import styled from "styled-components";

const ScrollingContainer = styled.div`
    max-height: 345px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 25px;

    ::-webkit-scrollbar-track-piece:start {
        margin-top: 0 !important;
    }
`;

const StyledLabel = styled.div`
    color: #000000;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 13px;
`;

const StyledInput = styled.input`
    border: 1px solid #000;
    color: #000;
    text-align: left;
    border-radius: 12px;
    padding: 0 1.8125rem;
    margin: 0;
    height: 2.5rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 0.875rem;
    text-align: left;
    width: 250px;
    box-sizing: border-box;
    pointer-events: none;

    ::placeholder {
        text-align: left;
    }
`;

const MultipleInvitesForm = (props) => {
    const { users } = props;
    const renderRows = () => {
        return users.map((user, index) => (
            <Grid container spacing={2} key={index}>
                <Grid item xs={12} sm={3}>
                    <StyledInput value={user.firstName} onChange={() => ""} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledInput value={user.lastName} onChange={() => ""} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledInput value={user.email} onChange={() => ""} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledInput value={user.phoneNumber} onChange={() => ""} />
                </Grid>
            </Grid>
        ));
    };

    return (
        <React.Fragment>
            <Box sx={{ margin: "1.40625rem 1.5625rem 1.59375rem 3.5625rem" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}><StyledLabel>First Name</StyledLabel></Grid>
                    <Grid item xs={12} sm={3}><StyledLabel>Last Name</StyledLabel></Grid>
                    <Grid item xs={12} sm={3}><StyledLabel>Email</StyledLabel></Grid>
                    <Grid item xs={12} sm={3}><StyledLabel>Mobile</StyledLabel></Grid>
                </Grid>
                <ScrollingContainer className="custom-scroll">
                    { renderRows() }
                </ScrollingContainer>
            </Box>
        </React.Fragment>
    );
};

export default MultipleInvitesForm;