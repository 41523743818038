export const SortableColumns = {
    SURVEY_NAME: 0,
    SURVEY_TYPE: 1,
    USER_GROUP: 2,
    START_DATE: 3,
    FINISH_DATE: 4,
    STATUS: 5
};

export const SortableColumnState = {
    INACTIVE: 0,
    DESCENDING: 1,
    ASCENDING: 2,
};


export const PageType = {
    ACTIVE: 1,
    SCHEDULED: 2,
    COMPLETE: 3,
    RECENT: 4,
};

export const SurveyStatusType = {
    ACTIVE: "Active",
    SCHEDULED: "Scheduled",
    COMPLETE: "Complete",
};

export const SurveyType = {
    RATING: "Rating",
    SATISFACTION: "Satisfaction",
    YESNO: "Yes/No",
    FEEDBACK: "Feedback",
};