import React, { Component } from "react";
import styled from "styled-components";
import Text from "./Text";

export const TextArea = styled.textarea`
    font-family: Roboto, Helvetica, sans-serif;
    color: #000000;
    font-size: 14px;
    width: -webkit-fill-available;
    height: 111px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #2D3037;
    padding: 10px 20px;
    border-radius: 26px;
    ::placeholder {
        color: #000;
    }
    font-weight: 500;
`;

export const ErrorTextArea = styled(Text)`
    color: red;
    text-align: left;
    font-size: 12px;
    margin: ${p => p.margin || "0 20px 5px"};
    height: 12px;
    white-space: nowrap;
`;

class FormText extends Component {
    render() {
        const { placeholder, align, errors, onChange, type, onFocus, ...styleProps } = this.props;
        const errorsArray = errors ? errors : [];
    
        return (
            <div>
                <TextArea
                    placeholder={placeholder}
                    placeholderColor="#51315D"
                    align={align}
                    opacity="1.0"
                    type={type}
                    isHighlighted={errorsArray.length > 0}
                    onChange={onChange}
                    onFocus={onFocus}
                    {...styleProps}
                />
                <ErrorTextArea>
                    {errorsArray}
                </ErrorTextArea>
            </div>
        );
    }
}

export default FormText;