import React from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import styled from "styled-components";
import CloseButton from "src/img/employeeList/CloseButton.png";
import { UserKeys } from "src/constants/userDetails";
import {
    mobile, desktop, tablet,
    FormField
} from "src/components";

export const Card = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E9EBEF;
    border-radius: 12px;
    color: #000;
    
    border: ${p => p.border || 0 };
    width: 100%;
    max-width: 15rem;
    height: auto;
    min-height: ${p => p.minHeight || "6.875rem"};
    border-radius: 12px;
    padding: 1.4375rem 1.5625rem 1.4375rem 1.8125rem;
    box-sizing: border-box;
    position: relative;

    ${tablet`
        margin-top: 1rem;
    `};

    ${mobile`
        margin-top: 1rem;
    `};

    ${desktop`
        margin-top: 0;
    `};
`;

export const Label = styled.div`
    font-weight: 500;
    font-size: 0.875rem;
`;

export const Number = styled.div`
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    text-align: left;
`;

export const BottomErrorMessage = styled.div`
    color: #FF5353;
`;

export const ClearUpload = styled.span`
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url(${CloseButton}) no-repeat right transparent;
    background-size: 1.25rem;
    margin-left: 5px;
    cursor: pointer;
`;

const Required = styled.span`
    color: red;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
`;

export const Tr = styled.tr`
    td input, .PhoneInputCountry, .flag-dropdown{
        border-color: ${ p => p.isExistingUser && "#006CFF"};
        color: ${ p => p.isExistingUser && "#006CFF"};
        background: ${ p => p.isExistingUser && "#EDF5FF !important;"}; 
        
    }

    td input, .PhoneInputCountry, .flag-dropdown{
        border-color: ${ p => p.showButtonWhenWrongUserType && "#FF0000 !important"};
        color: ${ p => p.showButtonWhenWrongUserType && "#FF0000"};
    }
    
`;

export function InputField({ value, placeholder, onChangeHandler, width, errors }) {
    return (
        <FormField borderRadius="12px" size="14px" padding="10px 20px" height="40px"
            placeholderColor="#000000" backgroundColor="#F4F4F4"
            align="left"
            placeholderAlignment="left"
            width={width || "200px"} //temporary
            type="text"
            placeholder={placeholder}
            onChange={onChangeHandler}
            errors={errors ? "Invalid" : "" }
            maxLength="100"
            value={value}
            showErrors={false}
            border="1px solid #000"
            color={errors ? "#ff0000 !important" : undefined}
        >
        </FormField>
    );
}

export const TableTemplate = (props) => {
    const { children, isClient } = props;
    
    return (
        <div style={{ width: "inherit", overflowY: "auto", overflowX: "auto" }}>
            <table cellPadding="4" style={{ minHeight: "10rem" }}>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>First Name <Required>(required)</Required></th>
                        <th>Last Name { isClient ? "(Optional)" : <Required>(required)</Required>}</th>
                        <th>Email <Required>(required)</Required></th>
                        <th>Mobile (Optional)</th>
                        <th>{!isClient ? "Department (Optional)" : "Client Type (Optional)"}</th>
                        {!isClient && <th>Job Title (Optional)</th>}
                    </tr>
                </thead>
                <tbody style={{ verticalAlign: "baseline" }}>
                    {children}
                </tbody>
            </table>
        </div>
    );
};

export async function getRemainingLicense(token) {
    const res = await axios.get(`${API_URL}/company`, {
        headers: {
            Authorization: "Bearer " + token
        }
    });
    
    let totalNumber = res.data.totalNumber;
    let employees = res.data.employees;
    let clients = res.data.clients;

    return totalNumber - (employees + clients);
}

export async function getExistingEmployeeAndClientEmails(token) {
    //check existing emails to changed their UI
    return await axios.get(`${API_URL}/existingUserEmails`, {
        headers: {
            Authorization: "Bearer " + token
        }
    });
}

export function countNewUsers(usersList, existingUserEmailsResult) {
    let countNewUsers = 0;

    usersList.forEach((user, _) => {
        const isExistingEmployee = existingUserEmailsResult["existingEmployeeEmails"].includes(user.email);
        const isExistingClient = existingUserEmailsResult["existingClientEmails"].includes(user.email);

        if (!isExistingEmployee && !isExistingClient) {
            countNewUsers++;
        }
    });

    return countNewUsers;
}

export const handleModalClose = ({ reason, handleClose }) => {
    if (reason !== "backdropClick") {
        handleClose();
    }
};

export function checkPropertyFieldIsEmptyAddError(e, index, propertyField, errorList) {
    if (e.target.value !== "")
        return false;
    
    //create new the errorList to be iterable
    let newErrorListInIsEmpty = errorList;

    if (newErrorListInIsEmpty[propertyField] === undefined) {
        newErrorListInIsEmpty[propertyField] = [];
    }
    
    newErrorListInIsEmpty[propertyField].push([index, "Please provide your " + propertyField]);
    
    //setErrorList(newErrorListInIsEmpty); this should be added, but I'm not sure why the errorList is being updated, and its working correctly

    return true;
}

export function propertyFieldIsNotEmptyRemoverError(e, index, propertyField, errorList) {
    //create new the errorList to be iterable
    let newErrorList = errorList;

    if (newErrorList[propertyField] === undefined) {
        return; //it should exist because of checkPropertyFieldIsEmptyAddError()
    }

    for (let i = 0; i < errorList[propertyField].length; i++) {
        const [errorIndex] = errorList[propertyField][i];

        if (errorIndex === index)
            newErrorList[propertyField].splice(i, 1);
    }

    if (errorList[propertyField].length === 0) {
        delete newErrorList[propertyField];  
    }

    //setErrorList(newErrorList); this should be added, but I'm not sure why the errorList is being updated, and its working correctly
}

export function repositionErrorMessageIndexes(index, errorList, isClient) {
    let newErrorListInClearRow = errorList;

    const requiredFields = isClient ? ["email", "firstName"] : ["email", "firstName", "lastName"];

    requiredFields.forEach((propertyField, fieldIndex) => {
        if (Object.prototype.hasOwnProperty.call(errorList, propertyField)) {
            for (let i = 0; i < errorList[propertyField].length; i++) {
                const [errorIndex] = errorList[propertyField][i];
                
                if (errorIndex === index) {
                    newErrorListInClearRow[propertyField].splice(i, 1);
                }

                if (errorIndex > index) {
                    newErrorListInClearRow[propertyField][i][0] = errorIndex - 1;
                }
            }

            if (newErrorListInClearRow[propertyField].length === 0) {
                delete newErrorListInClearRow[propertyField];  
            }
        }
    });
}

export function saveAllDirty(setErrorList, importUsers, newUser, isClient, isAmountOfUserExceeds, uploadCsvPostEmployees, handleClose) {
    setErrorList([]);

    let errorHolder = [];
    let bulkUsers = [];
    
    importUsers.forEach((user, _) => {
        let { newEmployee, errorList } = newUser(user, user.isDirty);
        
        bulkUsers.push(newEmployee);

        if (errorList.length > 0) {
            errorHolder.push(errorList);
        }   
    });

    const suffix = isClient ? "clients" : "employees";

    if (! (bulkUsers.length > 0)) {
        console.log("No users");
    }

    setErrorList(errorHolder);

    if (errorHolder.length === 0 && !isAmountOfUserExceeds) {
        uploadCsvPostEmployees(bulkUsers, suffix, setErrorList, handleClose);
    }
}

/** start of importUsersInputFields functions/const */
export function firstNameErrors(errorList) {
    let firstNames = [];

    if (Object.prototype.hasOwnProperty.call(errorList, UserKeys.FIRST_NAME)) {
        for (let i = 0; i < errorList["firstName"].length; i++) {
            const [index] = errorList["firstName"][i];
            firstNames.push(index);
        } 
    }

    return firstNames;
}

export function lastNameErrors(errorList) {
    let lastNames = [];

    if (Object.prototype.hasOwnProperty.call(errorList, UserKeys.LAST_NAME)) {
        for (let i = 0; i < errorList["lastName"].length; i++) {
            const [index] = errorList["lastName"][i];
            lastNames.push(index);
        }
    }

    return lastNames;
}

export function emailErrors(errorList) {
    let emails = [];
    
    if (Object.prototype.hasOwnProperty.call(errorList, UserKeys.EMAIL)) {
        for (let i = 0; i < errorList["email"].length; i++) {
            const [index] = errorList["email"][i];

            emails.push(index);
        } 
    }

    return emails;
}

export function phoneErrors(errorList) {
    let numbers = [];

    if (Object.prototype.hasOwnProperty.call(errorList, "phone")) {
        for (let i = 0; i < errorList["phone"].length; i++) {
            const [index] = errorList["phone"][i];

            numbers.push(index);
        } 
    }

    return numbers;
}