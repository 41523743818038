import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { PaddingLeftForOverFlowIssue } from "../forms/BankDetailsForm";
const MODAL_CONTAINER_ID = "modal-container";

const Body = styled.div`
    min-height: 26rem;
    width: 50rem;
    max-width: 90vw;
    pointer-events: auto;       
    display: grid;
    grid-template-columns: 5% 85% 10%;
    grid-template-rows: auto;
    border-radius: 12px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #00000029;
    margin: auto;
    align-content: start;
    padding-top:1em;
`;

const ModalContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    z-index: 996;  
    width: 100%;
    height: 100%;  
    pointer-events: none; 
`;

const Container = styled.div`
    position: fixed;
    top: 0; 
    left: 0; 
    width: 100vw;
    height: 100vh;
    z-index: 994;
    
    animation-timing-function: ease-out;
    animation-name: fade-in;
    animation-duration: .1s;
    animation-fill-mode: forwards;
    
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0; 
    left: 0; 
    background-color: rgba(0,0,0,.5);
    z-index: 995;  
    width: 100%;
    height: 100%;  
    pointer-events: auto;
    cursor: ${ p => (p.dismissable && "pointer") || "normal" };
`;

const Header = styled.div`
    grid-column: 2;
    grid-row: 1;
    width:100%;
    margin: 1rem 0;   
    text-align: ${ p => p.textAlign || "left"};
    font: 700 25px Roboto;
    color: #23262D;
`;

const TextBody = styled.div`
    grid-column: 2;
    grid-row: 2;
    text-align: center; 
    font: 1rem Roboto;
    color: #000000;

    grid-column-start: 2;
    grid-column-end: 4;
    margin-right: 2rem;
    
`;

const createModal = (modalHeader, modalBody, easyDismiss, modalLoading) => {
    const modalContainer = document.createElement("div");

    BankDetailsModal.instance.appendChild(modalContainer);

    const dismissEvent = () => {
        modalContainer.parentNode.removeChild(modalContainer);
    };

    const modalContent = (loading) => {
        return (
            <Body>
                <div>{/*Close Button */}</div>
                <Header style={PaddingLeftForOverFlowIssue}>
                    {modalHeader}
                </Header>

                <TextBody style={PaddingLeftForOverFlowIssue}>
                    {modalBody}
                </TextBody>
            </Body>
        );
    };

    ReactDOM.render(
        <Container>
            <Overlay onClick={easyDismiss ? dismissEvent : null} dismissable={easyDismiss}/>
            <ModalContainer>
                { modalContent(modalLoading) }
            </ModalContainer>
        </Container>
        ,
        modalContainer
    );
};

class BankDetailsModal {  
    static instance;

    static init() {
        let exists = document.getElementById(MODAL_CONTAINER_ID);

        if (exists) {
            BankDetailsModal.instance = exists;
            BankDetailsModal.instance.id = MODAL_CONTAINER_ID;
            document.body.removeChild(BankDetailsModal.instance);
        }

        BankDetailsModal.instance = document.createElement("div");
        BankDetailsModal.instance.id = MODAL_CONTAINER_ID;
        document.body.appendChild(BankDetailsModal.instance);
    }

    /** There can only every be one modal active at a time. */
    static open(modalHeader = "", modalBody = "", easyDismiss = true) {
        if (!BankDetailsModal.instance || !document.getElementById(MODAL_CONTAINER_ID)) {
            BankDetailsModal.init();

            console.log("BankDetailsModal.init();", BankDetailsModal.instance, !document.getElementById(MODAL_CONTAINER_ID));
        }
        
        createModal(modalHeader, modalBody, easyDismiss);
    }

    /** Allows for programmatic closing of a modal. */
    static close() {
        if (!BankDetailsModal.instance || !document.getElementById(MODAL_CONTAINER_ID)) {
            console.warn("There is no modal to close.");
            return;
        }

        let modal = document.getElementById(MODAL_CONTAINER_ID);
        if (modal) {
            modal.parentNode.removeChild(modal);
        }
    }
}

export default BankDetailsModal;
