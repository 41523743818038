import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import Initial from "./forms/Initial";
import Company from "./forms/Company";
import Plan from "./forms/Plan";
import MaximumUsers from "./forms/MaximumUsers";
import Pending from "./forms/Pending";
import Review from "./forms/Review";
import Success from "./forms/Success";
import Active from "./forms/Active";
import {
    Toast, LoadingIndicator, PageContainer
} from "src/components";
import { SubscriptionContext } from "./SubscriptionContext";
import SubscriptionCostResponse from "src/scenes/Subscription/api/SubscriptionCostResponse";

import { UserContext } from "src/scenes/App/UserContext";
import * as PERMISSIONS from "src/constants/permissions";
import { getSubscriptionExpired, getCompanySubscriptionEnd } from "src/utils/dates";
import { UserKeys } from "src/constants/userDetails";
import { withRouter } from "react-router-dom";

export const Forms = {
    INITIAL: 0,
    COMPANY_NAME: 1,
    PLAN: 2,
    REVIEW: 3,
    SUCCESS: 4,
    ACTIVE: 5,
    PURCHASE: 6,
    CHECKOUT: 7,
    PAYMENT_SUCCESS: 8,
    MAX_USER: 9,
    SUBSCRIPTION_PENDING: 10
};

const StorageKeys = {
    TEMP_COMPANY_NAME: "tempCompanyName",
    TEMP_COMPANY_ABN: "tempAbn",
    SUBSCRIPTION_PERIOD: "subscriptionPeriod",
    TOTAL_NUMBER: "totalNumber",
    IS_SUBSCRIBED: "isSubscribed",
    MORE_USERS: "moreUsers",
    IS_AWAITING_ACTIVATION: "isAwaitingActivation"
};

class Subscription extends Component {
    static contextType = UserContext;
    
    state = {
        loadingCount: 0,
        currentForm: this.context.companyName !== null ? Forms.ACTIVE : Forms.INITIAL,
        isNextClickable: this.context.companyName !== null ? true : false,
        acceptToc: false,
        companyName: "",
        abn: "",
        website: "",
        country: "",
        // to maintain value through Initial component re-mounts, eg. when revisiting the Initial screen/form
        isValidCountry: false,
        promoCode: "",
        subscriptionPeriod: NaN,
        totalNumber: 5,
        timezone: "",
        quoteSendTime: "",
        method: "paypal",
        costing: SubscriptionCostResponse.default(),
        employees: 0,
        clients: 0,
        trialClientUsers: 0,
        isSalesAndPromotionsEnabled: false,
        moreUsers: 1,
        subscriptionType: "",
        subscriptionEndDate: "",
        // We create moment library objects from our start date and end date
        //    to enable easier date comparison (see usage of the .diff method)
        subscriptionStartDate: null,
        isCancelled: false,
        loadingStatus: false,
        hideResult: true,
        resultMessage: "",
        isPaymentFailing: true,
        awaitingActivation: false,
        hasBillingPermission: false,
        isPurchasingMoreUsers: false,
        isSubscriptionExpired: false,
    };

    async getCompanyRegistrationStatus() {
        this.setState((prevState) => {
            return { loadingCount: prevState.loadingCount + 1 };
        });
        try {
            let response = await axios.get(`${API_URL}/companyregistration`, {
                headers: {
                    Authorization: "Bearer " + this.context.token
                }
            });
            if (response && response.data.company_registration.awaitingActivation) {
                this.setState({
                    currentForm: Forms.SUBSCRIPTION_PENDING,
                    awaitingActivation: true,
                });
                localStorage.setItem(StorageKeys.IS_AWAITING_ACTIVATION, response.data.company_registration.awaitingActivation);
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setState((prevState) => {
                return { loadingCount: prevState.loadingCount - 1 };
            });
        }
    }
    
    //we just provide a return url from PayPal and this local storage will help us to determine which
    //page the front-end should display to the user correctly.
    componentDidMount() {
        const userContext = this.context;
        if (this.context) {
            if (userContext[UserKeys.IS_CUSTOMIZE_COMPLETE]) {
                // triggering before company setup is completed will return unexpected argument error in the api due to no employee record and company to retrieve
                this.getCompanySubscriptionStatus(userContext.token);
            }

            /** check if user has Billing permission
             * this will prevent 403s wh8en user doesnt have billing permission */
            const hasBillingPermission = PERMISSIONS.hasPermissions([PERMISSIONS.BILLING], userContext.permissions);
            this.setState({
                hasBillingPermission: hasBillingPermission
            }, () => {
                //check if user has existing subscription request (for subscribers with more than 1000 users)
                if (!userContext.isActive) {
                    if (Object.prototype.hasOwnProperty.call(localStorage, StorageKeys.IS_AWAITING_ACTIVATION)) {
                        this.setState({ currentForm: Forms.SUBSCRIPTION_PENDING });
                    } else {
                        userContext.companyName === null && this.getCompanyRegistrationStatus();
                    }
                }
                // If the company has been activated, or it comes from redirection of PayPal after add more users
                if (userContext.isActive) {
                    //get cards method includes loading attribute, so it doesn't need to set loading attribute before this method is called
                    this.getActiveCompanyDetails();
                }

                // Determine if current request comes from first time subscription; make sure to always load subscription cost
                // for any page that might need it
                if (JSON.parse(localStorage.getItem(StorageKeys.IS_SUBSCRIBED))) {
                    this.getCompanyIsActive();
                } else {     
                    if (hasBillingPermission)
                    {
                        this.loadSubscriptionCost();
                    }
                }

                if (!userContext.isActive) {
                    // isCustomComplete being false means current user has not yet finished setting up his company, thus not an expired one
                    if (userContext[UserKeys.IS_CUSTOMIZE_COMPLETE]) {
                        this.setState({ currentForm: Forms.ACTIVE });
                    }
                    else {
                        // The payment cannot fail if the user
                        // account is not even active yet
                        this.setState({
                            isPaymentFailing: false
                        });
                    }
                }
            });
        }
    }

    getCompanySubscriptionStatus = async () => {
        await axios.get(`${API_URL}/company`, {
            headers: {
                Authorization: "Bearer " + this.context.token
            }
        }).then(res => {
            const isSubscriptionExpired = getSubscriptionExpired(res.data.subscriptionStart, res.data.subscriptionPeriod, true);
            this.setState({ isSubscriptionExpired: isSubscriptionExpired });
        }).catch(err => {
            if (err.response) {
                if (err.response.data.error.messages) {
                    return Toast.error(err.response.data.error.messages.toString());
                }
                return Toast.error(err.response.data.error.toString());
            } else {
                return Toast.error(err.message);
            }
        });
    };

    getActiveCompanyDetails = async () => {
        this.setState((prevState) => {
            return { loadingCount: prevState.loadingCount + 1 };
        });

        try {
            // Get the pricing metrics from the API
            let costing = this.state.costing;
            if (this.state.hasBillingPermission) {
                costing = await SubscriptionCostResponse.get(this.context.token);
            }

            const res = await axios.get(`${API_URL}/company`, {
                headers: {
                    Authorization: "Bearer " + this.context.token
                }
            });

            let startDate = res.data.subscriptionStart;
            let subscriptionPeriod = res.data.subscriptionPeriod;
            let expiredDate = getCompanySubscriptionEnd(startDate, subscriptionPeriod, true);
            let totalNumber = res.data.totalNumber;
            let employees = res.data.employees;
            let clients = res.data.clients;
            let trialClientUsers = res.data.trialClientUsers;
            let isSalesAndPromotionsEnabled = res.data.isSalesAndPromotionsEnabled;
            let companyName = res.data.companyName;
            const abn = res.data.abn;
            const countryCode = res.data.countryCode;
            let isCancelled = res.data.isCancelled;
            let isPaymentFailing = res.data.isPaymentFailing;

            let formatExpireDay = new Intl.DateTimeFormat().format(expiredDate);

            //redirection comes from employee list `add more users`
            if (JSON.parse(localStorage.getItem("redirect"))) {
                localStorage.removeItem("redirect");
                this.setState({
                    currentForm: Forms.PURCHASE
                });
            } else {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        currentForm: Forms.ACTIVE,
                    };
                });

                this.setState((prevState) => {
                    return {
                        ...prevState,
                        companyName: companyName,
                        abn: abn,
                        country: countryCode,
                        totalNumber: totalNumber,
                        employees: employees,
                        clients: clients,
                        trialClientUsers: trialClientUsers,
                        isSalesAndPromotionsEnabled: isSalesAndPromotionsEnabled,
                        isNextClickable: true,
                        costing: costing,
                        subscriptionEndDate: formatExpireDay,
                        subscriptionPeriod: subscriptionPeriod,
                        isCancelled: isCancelled,
                        subscriptionStartDate: new Date(res.data.subscriptionStart),
                        isPaymentFailing: isPaymentFailing
                    };
                });
            }

            //release these values since they are stored in state
            localStorage.removeItem(StorageKeys.TOTAL_NUMBER);
            localStorage.removeItem(StorageKeys.MORE_USERS);
        } catch (error) {
            this.setState({
                isNextClickable: true,
            });
            return Toast.error(error.message);
        } finally {
            this.setState((prevState) => {
                return { loadingCount: prevState.loadingCount - 1 };
            });
        }
    };

    getCompanyIsActive = async () => {
        this.setState((prevState) => {
            return { loadingCount: prevState.loadingCount + 1 };
        });

        try {
            //determine if the company successfully created to see if payment and company created successfully
            const res = await axios.get(`${API_URL}/company/isActive`, {
                headers: {
                    Authorization: "Bearer " + this.context.token
                }
            });

            let costing = this.state.costing;
            if (this.state.hasBillingPermission) {
                // Get the price per user from the API
                costing = await SubscriptionCostResponse.get(this.context.token);
            }

            //get these information to display page accurately
            let totalNumber = localStorage.getItem(StorageKeys.TOTAL_NUMBER);
            let companyName = localStorage.getItem(StorageKeys.TEMP_COMPANY_NAME);
            let abn = localStorage.getItem(StorageKeys.TEMP_COMPANY_ABN);

            // get current time and set it as subscription start time instead of getting from
            // API side for this time only due to this request comes from
            // first time purchase
            const subscriptionPeriod = costing.chargePeriodNumber;
            let expiredDate = getCompanySubscriptionEnd(new Date(), subscriptionPeriod, true);
            let formatExpireDay = new Intl.DateTimeFormat().format(expiredDate);

            if (res.data.isActive) {
                this.setState({
                    ...this.state,
                    currentForm: Forms.SUCCESS,
                    totalNumber: totalNumber,
                    subscriptionPeriod: subscriptionPeriod,
                    companyName: companyName,
                    abn: abn,
                    isNextClickable: true,
                    subscriptionStartDate: new Date(),
                    subscriptionEndDate: formatExpireDay,
                });
                this.context.updateUserContextProperties({ [UserKeys.IS_ACTIVE]: true });
            } else {
                //if payment did not success or user doesn't agree with agreement
                this.setState({
                    ...this.state,
                    currentForm: Forms.REVIEW,
                    totalNumber: totalNumber,
                    subscriptionPeriod: subscriptionPeriod,
                    companyName: companyName,
                    abn: abn,
                    isNextClickable: true,
                    costing: costing
                });
            }

            //clear local storage
            localStorage.removeItem(StorageKeys.IS_SUBSCRIBED);
            localStorage.removeItem(StorageKeys.TOTAL_NUMBER);
            localStorage.removeItem(StorageKeys.TEMP_COMPANY_NAME);
            localStorage.removeItem(StorageKeys.TEMP_COMPANY_ABN);
        } catch (error) {
            localStorage.removeItem(StorageKeys.IS_SUBSCRIBED);
            return Toast.error(error.message);
        } finally {
            this.setState((prevState) => {
                return { loadingCount: prevState.loadingCount - 1 };
            });
        }
    };

    loadSubscriptionCost = async () => {
        this.setState((prevState) => {
            return { loadingCount: prevState.loadingCount + 1 };
        });
        try {
            const costing = await SubscriptionCostResponse.get(this.context.token);
            this.setState({
                costing: costing
            });
        } catch (error) {
            console.log("Failed loading subscription cost");
        } finally {
            this.setState((prevState) => {
                return { loadingCount: prevState.loadingCount - 1 };
            });
        }
    };

    reloadCosting = (costing) => {
        this.setState({ costing });
    };

    nextClick = () => {
        if (this.state.isNextClickable) {
            if (this.state.currentForm === Forms.PLAN && this.state.totalNumber > 1000) {
                this.setState({
                    currentForm: Forms.MAX_USER,
                    hideResult: true,
                });
            } else if (this.state.currentForm === Forms.REVIEW) {
                this.context.updateUserContextProperties({ [UserKeys.IS_ACTIVE]: true });
                this.setState({
                    currentForm: Forms.SUCCESS,
                    hideResult: true,
                });
            } else if (this.state.currentForm === Forms.PAYMENT_SUCCESS) {
                this.setState({
                    currentForm: Forms.ACTIVE,
                    hideResult: true
                });
            } else if (this.state.currentForm === Forms.SUCCESS) {
                /** @type {SubscriptionCostResponse} */
                const costing = this.state.costing;

                let expiredDate = getCompanySubscriptionEnd(new Date(), costing.chargePeriodNumber, true);
                let formatExpireDay = new Intl.DateTimeFormat().format(expiredDate);

                this.setState({
                    subscriptionEndDate: formatExpireDay,
                    hideResult: true,
                    subscriptionStartDate: new Date(),
                });

                window.location.href = "/customise";
            } else if (this.state.currentForm === Forms.CHECKOUT) {
                let totalNumber = parseInt(this.state.totalNumber) + parseInt(this.state.moreUsers);
                this.setState({
                    totalNumber: totalNumber,
                    hideResult: true,
                });
                this.setState({
                    currentForm: Forms.PAYMENT_SUCCESS,
                    hideResult: true,
                });
            } else if (this.state.currentForm === Forms.ACTIVE) {
                // isPurchasingMore users on true will let COMPANY_NAME and PLAN differentiate between setup and purchase more users
                this.setState({
                    isPurchasingMoreUsers: true,
                    currentForm: Forms.COMPANY_NAME
                });
            } else if (this.state.currentForm === Forms.COMPANY_NAME && this.state.isPurchasingMoreUsers) {
                this.setState({ currentForm: Forms.PURCHASE });
            }
            else {
                const newForm = this.state.currentForm + 1;
                this.setState({
                    currentForm: newForm,
                    hideResult: true,
                });
            }
        }
    };

    updateValue = (name, value) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                isNextClickable: true,
                [name]: value,
            };
        });
    };

    updateCompanyValueAndPossiblyGoNext = (name, value, goNext) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                isNextClickable: true,
                [name]: value,
            };
        }, () => goNext && this.nextClick());
    };

    updatePrice = (currentForm, value) => {
        if (currentForm === Forms.PLAN) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    totalNumber: value
                };
            });
        } else {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    moreUsers: value
                };
            });
        }
    };

    backToInitial = (form) => {
        const newForm = form === Forms.PLAN || form === Forms.REVIEW ? Forms.INITIAL : Forms.ACTIVE;
        this.setState({
            ...this.state,
            currentForm: newForm,
        });
    };

    backToPrevious = () => {
        // show "Active" tab when clicking back on Company when purchasing more users
        if (this.state.currentForm === Forms.COMPANY_NAME && this.state.isPurchasingMoreUsers) {
            return this.setState({ currentForm: Forms.ACTIVE, isPurchasingMoreUsers: false });
        }
        else {
            const newForm = this.state.currentForm === Forms.MAX_USER ? 2 : this.state.currentForm - 1;
            this.setState({
                ...this.state,
                currentForm: newForm,
            });
        }
    };

    onPaymentMethodSaved = () => {
        this.setState({
            currentForm: Forms.ACTIVE
        });
    };

    sendSubscriptionRequest = (fullName, emailAddress, mobileNumber, businessName, message) => {
        const { uuid, totalNumber } = this.state;
        axios.post(`${API_URL}/companyregistration`, {
            "totalNumber": totalNumber,
            "uuid": uuid,
        }, {
            headers: {
                Authorization: "Bearer " + this.context.token
            }
        }).then(_ => {
            // Send subscription request email
            axios.post(`${API_URL}/businesssubscription/sendmail`, {
                "fullName": fullName, 
                "emailAddress": emailAddress, 
                "mobileNumber": mobileNumber,
                "businessName": businessName, 
                "message": message
            }, {
                headers: {
                    Authorization: "Bearer " + this.context.token
                }
            }).then(_ => {
                this.setState({
                    currentForm: Forms.SUBSCRIPTION_PENDING,
                    awaitingActivation: true
                });
                localStorage.setItem(StorageKeys.IS_AWAITING_ACTIVATION, true);
            }).catch(error => {
                this.showError(error.message);
            });
        }).catch(error => {
            this.showError(error.message);
        });
    };

    showError = (error) => {
        this.setState({
            resultMessage: error
        });
        return Toast.error(error);
    };

    setUserPermissions = (permissionIds) => {
        // Update the UserContext property
        this.context.permissions = JSON.parse(permissionIds);
    };

    render() {
        const userContext = this.context;
        const { loadingCount, currentForm, acceptToc, companyName, abn, website, country, isValidCountry, promoCode, totalNumber,
            subscriptionType, subscriptionPeriod, isCancelled, hideResult,
            costing, method, employees, clients, trialClientUsers, isSalesAndPromotionsEnabled, loadingStatus, resultMessage,
            moreUsers, subscriptionStartDate, subscriptionEndDate, isSubscriptionExpired,
            isPaymentFailing, isPurchasingMoreUsers } = this.state;

        if (loadingCount > 0) {
            return <LoadingIndicator />;
        }

        return (
            <PageContainer>
                <SubscriptionContext.Provider value={{
                    updateValue: this.updateValue,
                    updateCompanyValueAndPossiblyGoNext: this.updateCompanyValueAndPossiblyGoNext,
                    sendSubscriptionRequest: this.sendSubscriptionRequest,
                    acceptToc: acceptToc,
                    nextClick: this.nextClick,
                    companyName: companyName,
                    abn: abn,
                    website: website,
                    country: country,
                    isValidCountry: isValidCountry,
                    promoCode: promoCode,
                    finishSubmission: this.finishSubmission,
                    updatePrice: this.updatePrice,
                    totalNumber: totalNumber,
                    backToInitial: this.backToInitial,
                    backToPrevious: this.backToPrevious,
                    costing: costing,
                    reloadCosting: this.reloadCosting,
                    currentForm: currentForm,
                    moreUsers: moreUsers,
                    setUserPermissions: this.setUserPermissions,
                    method: method,
                    subscriptionPeriod: subscriptionPeriod,
                    isActive: userContext.isActive,
                    employees: employees,
                    clients: clients,
                    trialClientUsers: trialClientUsers,
                    isSalesAndPromotionsEnabled: isSalesAndPromotionsEnabled,
                    email: userContext.email,
                    token: userContext.token,
                    isCancelled: isCancelled,
                    onPaymentMethodSaved: this.onPaymentMethodSaved,
                    subscriptionType: subscriptionType,
                    subscriptionEndDate: subscriptionEndDate,
                    subscriptionStartDate: subscriptionStartDate,
                    loadingStatus: loadingStatus,
                    resultMessage: resultMessage,
                    hideResult: hideResult,
                    isPaymentFailing: isPaymentFailing,
                    isPurchasingMoreUsers: isPurchasingMoreUsers,
                    isSubscriptionExpired: isSubscriptionExpired
                }}>
                    { currentForm === Forms.INITIAL &&
                        <Initial /> }
                    { currentForm === Forms.COMPANY_NAME &&
                        <Company /> }
                    { currentForm === Forms.PLAN &&
                        <Plan /> }
                    { currentForm === Forms.MAX_USER &&
                        <MaximumUsers /> }
                    { currentForm === Forms.SUBSCRIPTION_PENDING &&
                        <Pending /> }
                    { currentForm === Forms.REVIEW &&
                        <Review /> }
                    { currentForm === Forms.SUCCESS &&
                        <Success /> }
                    { currentForm === Forms.ACTIVE &&
                        <Active /> }
                    { currentForm === Forms.PURCHASE &&
                        <Plan /> }
                    { currentForm === Forms.CHECKOUT &&
                        <Review /> }
                    { currentForm === Forms.PAYMENT_SUCCESS &&
                        <Success /> }
                </SubscriptionContext.Provider>
            </PageContainer>
        );
    }
}

export default withRouter(Subscription);