import styled, { keyframes } from "styled-components";
import React from "react";

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: ${p => p.containerHeight || "8rem" };
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Animation = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

// Code from https://projects.lukehaas.me/css-loaders/
const LoadingIndicatorIcon = styled.div`
animation: ${Animation} 1.1s infinite linear;
border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
border-left: 1.1em solid rgb(0, 0, 0);
border-radius: 50%;
border-right: 1.1em solid rgba(0, 0, 0, 0.2);
border-top: 1.1em solid rgba(0, 0, 0, 0.2);
font-size: 5px;
height: ${p => p.height || "10em" };
margin: auto;
position: relative;
width: ${p => p.width || "10em" };

&:after {
    border-radius: 50%;
    height: 10em;
    width: 10em;
}
`;

export function LoadingIndicator(props) {
    return (
        <Container {...props} >
            <LoadingIndicatorIcon {...props} />
        </Container>
    );
}

export default LoadingIndicator;