import React, { Component } from "react";
import styled from "styled-components";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import {
    Toast, FlexContainer, Text, PageContainer,
    LottieLoadingIndicator
} from "src/components";
import { SurveyInfo } from "./SurveyInsightsComponents/SurveyInfo";
import { SurveyQuestions } from "./SurveyInsightsComponents/SurveyQuestions";
import { GridContainer, GridChild, StyledButton } from "./Utils";
import { SurveyOverview } from "./SurveyInsightsComponents/SurveyOverview";

const ContentContainer = styled.div`
  width: 100%;
  overflow: auto;
  padding: 2.5rem 1.5rem;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #8291b2;
  border-radius: 7px;
  opacity: 1;
`;

class SurveyInsightPage extends Component {
    state = {
        fetchingInsight: true,
        fetchingSurvey: true,
        fetchingResponseStats: true,
        survey: {
            name: "...",
            surveyType: "...",
            startDate: "",
            finishDate: "",
            userGroup: "..."
        },
        insights: {
            numberOfUsersInTargetGroup: "...",
            numberOfUsersPromptedForSurvey: "...",
            numberOfTotalSurveysSubmitted: "...",
            surveyQuestions: [{}],
        },
        responsesStats: {},
        message: "",
        inputErrors: [],
        setInputTypeAs: "text",
        loading: false,
    };

    componentDidMount() {
        this.safeToInit();
    }

    safeToInit = () => {
        this.populateInsights();
        this.populateSurvey();
        this.populateResponseStats();
    };

    populateSurvey = () => {
        const { surveyUuid } = this.props;
        let surveyResponse = {};
        let hasError = true;

        axios.get(`${API_URL}/survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            surveyResponse = res.data.survey;
            hasError = false;
        }).finally(() => {
            if (!hasError)
            {
                this.setState(prevState => ({
                    survey: surveyResponse,
                    fetchingSurvey: false,
                }));
            }
            else
            {
                this.setState(state => ({
                    fetchingSurvey: false,
                }));
            }
        }).catch(error => {
            if (!error.response) 
                return Toast.error(error.message);

            return Toast.error(error.response.data.error);
        });
    };

    populateInsights = () => {
        const { surveyUuid } = this.props;
        let surveyInsight = {};

        let hasError = true;

        axios.get(`${API_URL}/survey/company/insights/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            surveyInsight = res.data;
            hasError = false;
        }).finally(() => {
            if (!hasError)
            {
                this.setState(prevState => ({
                    insights: surveyInsight,
                    fetchingInsight: false,
                }));
            }
            else
            {
                this.setState(state => ({
                    fetchingInsight: false,
                }));
            }
        }).catch(error => {
            if (!error.response) 
                return Toast.error(error.message);

            return Toast.error(error.response.data.error);
        });
    };

    populateResponseStats = () => {
        const { surveyUuid } = this.props;
        let surveyResponsesStats = {};
        let hasError = true;

        axios.get(`${API_URL}/survey/company/responsesStats/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            surveyResponsesStats = res.data;
            hasError = false;
        }).finally(() => {
            if (!hasError)
            {
                this.setState(prevState => ({
                    responsesStats: surveyResponsesStats,
                    fetchingResponseStats: false,
                }));
            }
            else
            {
                this.setState(state => ({
                    fetchingResponseStats: false,
                }));
            }
        }).catch(error => {
            if (!error.response) 
                return Toast.error(error.message);

            return Toast.error(error.response.data.error);
        });
    };

    render() {
        const { survey, insights, responsesStats, fetchingInsight, fetchingResponseStats } = this.state;
        const { showInsights, user } = this.props;

        return (
            <PageContainer>
                <FlexContainer>
                    <FlexContainer
                        direction="row"
                        justifyContent="space-between"
                        marginBottom="1.625rem"
                        style={{ maxWidth: "95.188rem" }}
                    >
                        <div style={{ flexGrow: 1 }}>
                            <Text color="#23262D" size="40px" weight="500" align="left" margin="1em 0 0 0">
                                Survey Insights
                            </Text>
                            <Text color="#808080" size="14px" align="justify" width="690px">
                                Unlock a comprehensive overview of your survey results with our Survey Insights. Tailored for admin users, this feature provides a visual representation of survey data, helping you decipher trends, feedback, and patterns at a glance. Analyse, understand, and make informed decisions with precision, all within one intuitive interface.
                            </Text>
                        </div>
                        <FlexContainer
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <FlexContainer direction="row" justifyContent="center">
                                <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "150px", height: "40px", borderRadius: "7px", weight: "bold" }}
                                    onClick={() => { showInsights(false); }} >
                                    Close
                                </StyledButton>
                            </FlexContainer>
                        </FlexContainer>
                    </FlexContainer>

                    <FlexContainer
                        direction="row"
                        justifyContent="space-between"
                        marginBottom="1.625rem"
                        style={{ maxWidth: "95.188rem" }}
                    >
                        <ContentContainer>
                            { fetchingInsight && <LottieLoadingIndicator />}

                            { !fetchingInsight &&
                                <GridContainer style={{ gridTemplateColumns: "25% 75%" }}>
                                    <GridChild style={{ textAlign: "left" }}>
                                        <SurveyOverview showInsights={showInsights} survey={survey} insights={insights} />
                                    </GridChild>
                                    <GridChild style={{ textAlign: "left" }}>
                                        <SurveyInfo showInsights={showInsights} survey={survey} />
                                        <SurveyQuestions
                                            survey={survey}
                                            insights={insights}
                                            surveyType={survey.surveyType}
                                            responsesStats={responsesStats}
                                            fetchingResponseStats={fetchingResponseStats}
                                            user={user}
                                        />
                                    </GridChild>
                                </GridContainer>
                            }
                        </ContentContainer>
                    </FlexContainer>
                </FlexContainer>
            </PageContainer>
        );
    }
}

export default SurveyInsightPage;