import React, { Component } from "react";
import moment from "moment";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import Select, { components } from "react-select";
import {
    LoadingIndicator, FlexContainer, FormField, ErrorText,
    Text, Form, Button, ConfirmModal, Toast, PageContainer, PageHeadingLabel
} from "src/components";

import styled from "styled-components";

import { GridContainer, GridChild, CancelButton, SaveButton, EditQuestionContainer, FormBackground, FormSectionHeader, LoadingIndicatorButton, LoadingIndicatorIcon } from "../components/Utils";
import { ErrorStrings, ErrorList, surveyTypeOptions, surveyUserGroupOptions, surveyShowInOptions, surveyFrequencyOptions } from "../components/Enums";
import SurveyDurationFormField from "../components/SurveyDurationInput";

const { Option } = components;
const IconOption = props => (
    <Option { ...props }>
        { props.data.label }
        <div style={{ display: "inline-flex", justifyContent: "center", verticalAlign: "middle", marginRight: "10px" }}>
            { props.data.label === "User Yes/No Survey" ?
                <div style={{ fontFamily: "Roboto", fontSize: "18px", fontStyle: "bold" }}>
                    Yes/No
                </div> :
                <img 
                    src={ props.data.icon }
                    style={{ height: 25 }}
                    alt={ props.data.label }
                />
            }
            { (props.data.value === "Rating" || props.data.value === "Satisfaction") &&
                <>
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                </>
            }
        </div>
    </Option>
);

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        { props.data.label }
        <div style={{ display: "inline-flex", justifyContent: "center", verticalAlign: "middle", height: "100%", marginLeft: "10px" }}>
            { props.data.label === "User Yes/No Survey" ?
                <div style={{ fontFamily: "Roboto", fontSize: "18px", fontStyle: "bold" }}>
                    Yes/No
                </div> :
                <img 
                    src={ props.data.icon }
                    style={{ height: 25 }}
                    alt={ props.data.label }
                />
            }
            { (props.data.value === "Rating" || props.data.value === "Satisfaction") &&
                <>
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                </>
            }
        </div>
    </components.SingleValue>
);

const customStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "12px",
        border: "1px solid #E9EBEF",
        textDecoration: "none",
        color: "white",
        height: "50px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        fontSize: "16px",
        fontFamily: "Roboto, Helvetica, sans-serif",
        appearance: "none",
        placeholderAlignment: "middle",
        "&:hover": {
            borderColor: "#E9EBEF",
            outline: "#E9EBEF",
            boxShadow: "0 0 1px #E9EBEF"
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "16px",
        fontWeight: "Regular",
        justifyContent: "center",
        verticalAlign: "middle"
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (provided) => ({
        marginTop: "1rem",
        borderRadius: "12px",
        border: ".5px solid black",
        fontSize: "16px",
        backgroundColor: "white",
        position: "absolute",
        zIndex: "10",
        width: "100%",
        "&hover": {
            borderRadius: "12px"
        }
    }),
    option: (base) => ({
        ...base,
        borderRadius: "12px",
    })
};

const customDisabledStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "12px",
        border: "1px solid #E9EBEF",
        textDecoration: "none",
        color: "white",
        height: "50px",
        background: "#F0F0F0 0% 0% no-repeat padding-box",
        fontSize: "16px",
        fontFamily: "Roboto, Helvetica, sans-serif",
        appearance: "none",
        placeholderAlignment: "middle",
        "&:hover": {
            borderColor: "#E9EBEF",
            outline: "#E9EBEF",
            boxShadow: "0 0 1px #E9EBEF"
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "16px",
        fontWeight: "Regular",
        justifyContent: "center",
        verticalAlign: "middle"
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (provided) => ({
        marginTop: "1rem",
        borderRadius: "12px",
        border: ".5px solid black",
        fontSize: "16px",
        backgroundColor: "white",
        position: "absolute",
        zIndex: "10",
        width: "100%",
        "&hover": {
            borderRadius: "12px"
        }
    }),
    option: (base) => ({
        ...base,
        borderRadius: "12px",
    })
};

const RemoveButton = styled(Button)`
    height: 2.4rem;
    color: #FFFFFF;
    margin: 5px 5px;
`;

const Hr = styled.hr`
    width:100%;
`;

class EditForm extends Component {
    state = {
        fetching: 0,
        survey: {
            name: "",
            surveyType: "",
            startDate: "",
            finishDate: "",
            userGroup: "",
            surveyQuestions: {
                0: { uuid: "", question: "" },
                1: {},
                2: {}
            },
            showIn: "",
            showFrequency: "",
            anonymousSurvey: false
        },
        currentSavedStartDate: "",
        message: "",
        inputErrors: [],
        setInputTypeAs: "text",
        loading: false
    };
    
    componentDidMount() {
        //this.safeToInit();
        //this.populateSurvey();

        this.setState(state => ({
            fetching: state.fetching + 1
        }), () => this.safeToInit());
    }

    safeToInit = () => {
        this.populateSurvey();

        this.setState(state => ({
            fetching: state.fetching - 1
        }));
    };

    populateSurvey = () => {
        const { surveyUuid } = this.props;

        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.get(`${API_URL}survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            const surveyResponse = res.data.survey;
            this.setState(prevState => ({
                survey: surveyResponse,
                currentSavedStartDate: surveyResponse.startDate,
            }));
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
            }));
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    formSubmission = () => {
        this.setState({
            loading: true,
            inputErrors: [],
        });

        const { surveyUuid } = this.props;
        const surveyData = this.state.survey;
        const surveyQuestions = Object.keys(surveyData.surveyQuestions);

        let surveyQuestionItems = {};
        let questionOrder = 1;
        surveyQuestions.forEach(questionKey => {
            if (surveyData.surveyQuestions[questionKey].question !== "" && typeof surveyData.surveyQuestions[questionKey].question !== "undefined")
            {
                surveyData.surveyQuestions[questionKey].questionOrder = questionOrder;
                surveyQuestionItems[questionKey] = surveyData.surveyQuestions[questionKey];
                questionOrder++;
            }
        });

        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = surveyData.startDate ? formatter.format(new Date(surveyData.startDate)) : "";
        const formattedFinishDate = surveyData.finishDate ? formatter.format(new Date(surveyData.finishDate)) : "";

        let errors = this.getErrors();
        
        if (errors.length > 0) {
            this.setState({
                loading: false,
                inputErrors: errors,
            });

            return Toast.error("Please make sure your entries are correct");
        }

        axios.put(`${API_URL}/survey/company/${surveyUuid}`, {
            name: surveyData.name,
            surveyType: surveyData.surveyType,
            startDate: surveyData.startDate ? formattedStartDate + " 00:00:00" : null,
            finishDate: surveyData.finishDate ? formattedFinishDate + " 00:00:00" : null,
            userGroup: surveyData.userGroup,
            surveyQuestions: surveyQuestionItems,
            showIn: surveyData.showIn,
            showFrequency: surveyData.showFrequency
        },
        {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            const surveyResponse = res.data.survey;
            this.setState(prevState => ({
                survey: surveyResponse,
            }));

            return Toast.info("Survey updated successfully");
        }).catch(error => {
            if (!error.response)
                return Toast.error(error.message);

            let errors = [];
            let dataErrorFields = error.response.data.error.fields;
            
            dataErrorFields.forEach(function(fieldKey) {
                switch (fieldKey) {
                case "userGroupOverLap":
                    errors.push(ErrorList.DATE_AND_USERGROUP_OVERLAP);
                    return;

                case "startDateInvalid":
                    errors.push(ErrorList.DATE_START_DATE_INVALID);
                    return;
   
                case "finishDateInvalid":
                    errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                    return;

                case "startDateGreaterThanFinishDate":
                    errors.push(ErrorList.DATE_RANGE_INVALID);
                    return;

                case "surveyQuestionEmpty":
                    errors.push(ErrorList.EMPTY_QUESTION_ONE);
                    return;
                        
                default:
                    //NO ERROR TO CATCH
                    return;
                }
            });

            this.setState({
                inputErrors: errors,
            });

            return Toast.error("Please make sure your entries are correct");
        }).finally(error => {
            this.setState({
                loading: false,
            });
        });
    };

    confirmDelete = (surveyUuid) => {
        return ConfirmModal.open(
            "Permanently Remove Survey",
            <div>
                <p>Are you sure you want to remove this survey? All data from survey 
                    insights for this survey will be lost</p><br></br>
                <hr/>
            </div>,
            () => {this.deleteSurvey(surveyUuid); },
            "Remove",
            false
        );
    };

    deleteSurvey = (surveyUuid) => {
        const { hideEditForm, getSurveys } = this.props;

        ConfirmModal.close();

        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.delete(`${API_URL}/survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            hideEditForm();
            Toast.info("Delete successful");
            getSurveys();
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    getErrors() {
        let survey = this.state.survey;
        let errors = [];

        if (survey.name === "") {
            errors.push(ErrorList.EMPTY_SURVEY_NAME);
        }
    
        if (survey.startDate === "") {
            errors.push(ErrorList.EMPTY_START_DATE);
        }

        if (survey.finishDate === "") {
            errors.push(ErrorList.EMPTY_FINISH_DATE);
        }

        if (survey.surveyQuestions[0] === undefined || (survey.surveyQuestions[0] !== undefined && survey.surveyQuestions[0].question === "")) {
            errors.push(ErrorList.EMPTY_QUESTION_ONE);
        }

        return errors;
    }

    inputSurveyName = (event) => {
        const surveyName = event.target.value;
        
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                name: surveyName // update the value of specific key
            }
        }));
    };

    inputStartDate = (event) => {
        const startDate = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                startDate: startDate // update the value of specific key
            }
        }));
    };

    inputFinishDate = (event) => {
        const finishDate = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                finishDate: finishDate // update the value of specific key
            }
        }));
    };

    inputQuestion = (questionNumber, event) => {
        const questionString = event.target.value;

        this.setState(prevState => ({
            survey: {                  
                ...prevState.survey,   
                surveyQuestions: { 
                    ...prevState.survey.surveyQuestions,
                    [questionNumber]: {
                        ...prevState.survey.surveyQuestions[questionNumber],
                        question: questionString
                    }
                }        
            }
        }));
    }; 

    selectShowIn = (event) => {
        const showIn = event.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showIn: showIn // update the value of specific key
            }
        }));
    };

    selectShowFrequency = (event) => {
        const showFrequency = event.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showFrequency: showFrequency // update the value of specific key
            }
        }));
    };

    render() {
        const { hideEditForm, surveyUuid } = this.props;
        const { fetching, inputErrors, survey, loading, setInputTypeAs } = this.state;

        if (fetching > 0) {
            return (
                <PageContainer>
                    <PageHeadingLabel color="#23262D" size="40px" weight="500">
                        Edit Survey
                    </PageHeadingLabel>
                    <div className="row" style={{ maxWidth: "43.125rem" }}>
                        <div className="description" style={{ marginBottom: "1em" }}>
                            Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability
                            to create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards<br/>
                            employees, clients, or both, ensuring you reach the right audience every time.
                        </div>
                    </div>
                    <LoadingIndicator />
                </PageContainer>
            );
        }
        
        return (
            <PageContainer>
                <PageHeadingLabel color="#23262D" size="40px" weight="500">
                    Edit Survey
                </PageHeadingLabel>
                <div className="row" style={{ maxWidth: "43.125rem" }}>
                    <div className="description" style={{ marginBottom: "1em" }}>
                        Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability
                        to create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards<br/>
                        employees, clients, or both, ensuring you reach the right audience every time.
                    </div>
                </div>

                
                <FormBackground>
                    <FlexContainer style={{ padding: "2.5rem", width: "100%" }}>
                        <Form width="100%" paddingBottom="0" onSubmit={(e) => {e.preventDefault();}} >
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "50%", marginRight: "2rem" }}>
                                    <FormSectionHeader style={{ width: "100%", fontWeight: "bold" }}> Survey Information </FormSectionHeader>
                                    <SurveyNameField survey={survey} inputSurveyName={this.inputSurveyName} inputErrors={inputErrors} />
                                    <TypeOfSurveyField survey={survey} inputErrors={inputErrors} />
                                    <PageSurveyDuration survey={survey} inputStartDate={this.inputStartDate}
                                        inputFinishDate={this.inputFinishDate}
                                        inputErrors={inputErrors}
                                        setInputTypeAs={setInputTypeAs}
                                        currentSavedStartDate={this.state.currentSavedStartDate}
                                        changeScheduleStartDateSetInputTypeAs={this.changeScheduleStartDateSetInputTypeAs}
                                        changeScheduleFinishDateSetInputTypeAs={this.changeScheduleFinishDateSetInputTypeAs} /> 
                                    <UserGroupField survey={survey} inputErrors={inputErrors} />
                                    <PageSurveyFrequency survey={survey} inputErrors={inputErrors} selectShowIn={this.selectShowIn} selectShowFrequency={this.selectShowFrequency} /> 
                                </div>
                                <div style={{ flex: "50%", marginRight: "5rem", display: "flex", flexWrap: "wrap" }}>
                                    <FormSectionHeader style={{ width: "100%" }}>
                                        Survey Questions
                                        <PageSurveyQuestions survey={survey} inputQuestion={this.inputQuestion} inputErrors={inputErrors} />
                                    </FormSectionHeader>
                                    <div style={{ placeSelf: "flex-end", margin: "0rem 0rem 2.5rem 0rem" }}>
                                        <SurveySubmissionAnonymousField isSurveyAnonymous={this.state.survey.isAnonymous} />
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <Hr/>
                        <FlexContainer direction="row" className="tableHeaderContainer" width="100%">
                            {
                                loading 
                                    ?
                                    ""
                                    :
                                    <RemoveButton backgroundColor="#ff6464" onClick={(e) => { this.confirmDelete(surveyUuid); } }>
                                        <span>Permanently Remove Survey </span>
                                    </RemoveButton>
                            }

                            {
                                loading ? 
                                    <LoadingIndicatorButton height="38px" style={{ marginLeft: "auto" }}>
                                        <LoadingIndicatorIcon height="20px" width="20px" margin="5px"/>
                                    </LoadingIndicatorButton>
                                    :
                                    <>
                                        <CancelButton style={{ marginLeft: "auto" }} borderRadius="7px" onClick={(e) => {hideEditForm();}}>Cancel</CancelButton>
                                        <SaveButton border="unset !important" color="white" backgroundColor="#006CFF" borderRadius="7px" onClick={(e) => {this.formSubmission();}}>Save</SaveButton>
                                    </>
                            }
                        </FlexContainer>
                    </FlexContainer>
                </FormBackground>
                
            </PageContainer>
        );
    }
}

class SurveyNameField extends Component {
    render() {
        const { survey, inputSurveyName, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyNameError = getErrorStrings(ErrorList.EMPTY_SURVEY_NAME);

        return (
            <>
                <Text align="left" size="16px" weight="bold">
                    Change Survey Name
                </Text>
                <FormField borderRadius="12px" size="16px" height="50px"
                    placeholderColor="#000000" border="1px solid #E9EBEF"
                    align="left"
                    width="100%"
                    type="text"
                    placeholder="Enter your survey name here"
                    onChange={(event) => {inputSurveyName(event);} }
                    errors={surveyNameError}
                    maxLength="100"
                    value={survey.name}
                    showErrors={surveyNameError.length > 0}
                >
                </FormField>
            </>
        );
    }
}

class PageSurveyDuration extends Component {
    state = {
        startDateSetInputTypeAs: "text",
        finishDateSetInputTypeAs: "text",
        tooltipActive: false,
        startDate: moment(this.props.survey.startDate).format("YYYY-MM-DD"),
        finishDate: moment(this.props.survey.finishDate).format("YYYY-MM-DD"),
        formattedStartDate: moment(this.props.survey.startDate).format("Do MMMM, YYYY"),
        formattedFinishDate: moment(this.props.survey.finishDate).format("Do MMMM, YYYY"),
    };

    /**
     * onFocus action to switch from text to date input
     */
    startDateChangeInputType = (event) => {
        this.setState({
            startDateSetInputTypeAs: event,
        });
    };

    /**
     * onBlur action to switch fron date to text input
     */
    finishDateChangeInputType = (event) => {
        this.setState({
            finishDateSetInputTypeAs: event,
        });
    };

    setStartDate = (event, setInputValue) => {
        setInputValue(event);
        this.setState({
            formattedStartDate: moment(event.target.value).format("Do MMMM, YYYY"),
            startDate: moment(event.target.value).format("YYYY-MM-DD"),
            startDateSetInputTypeAs: "text"
        });
    };

    setFinishDate = (event, setInputValue) => {
        setInputValue(event);
        this.setState({
            formattedFinishDate: moment(event.target.value).format("Do MMMM, YYYY"),
            finishDate: moment(event.target.value).format("YYYY-MM-DD"),
            finishDateSetInputTypeAs: "text"
        });
    };

    checkDateIsFuture = (date) => {
        const compareDate = new Date(date);
        const dateToday = new Date;
        if (compareDate > dateToday) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const { inputStartDate, inputFinishDate, inputErrors, currentSavedStartDate } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
        const surveyFinishDateError = getErrorStrings(ErrorList.EMPTY_FINISH_DATE);
        
        const currentStartDate = currentSavedStartDate;

        const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
        const finishDateInvalid = getErrorStrings(ErrorList.DATE_FINISH_DATE_INVALID);

        const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
        const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_FINISH_FORMAT_INVALID);

        const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);
        const userGroupOverLap = getErrorStrings(ErrorList.DATE_AND_USERGROUP_OVERLAP);

        const hasAnySurveyDurationErrors = (surveyStartDateError.length > 0 || surveyFinishDateError.length > 0 || startDateInvalid.length > 0 || finishDateInvalid.length > 0 || dateStartFormatInvalid.length > 0 || dateFinishFormatInvalid.length > 0 || dateRangeInvalid.length > 0);

        return (
            <>
                <GridContainer align="center" style={{ gridTemplateColumns: "50% 50%" }} >                    
                    <GridChild style={{ textAlign: "center", marginLeft: "0px" }}>
                        <Text color="#000" align="left" size="16px" weight="bold" margin="16px 0 16px 0">
                            { this.checkDateIsFuture(currentStartDate) ? "Change " : "" }Start Date
                        </Text>
                        <SurveyDurationFormField 
                            size="16px"
                            height="50px"
                            placeholderColor="#000000"
                            border={this.checkDateIsFuture(currentStartDate) ? "1px solid #2D3037" : ""}
                            borderRadius={ this.state.finishDateSetInputTypeAs === "text" ? "12px 0px 0px 12px !important" : "12px"}
                            color="#000000"
                            align="left"
                            type={this.state.startDateSetInputTypeAs}
                            inputName="surveyDurationInput"
                            placeholder="Choose date..."
                            onChange={(event) => {this.setStartDate(event, inputStartDate);} }
                            onFocus={() => {
                                this.startDateChangeInputType("date"); 
                            }}
                            onBlur={() => {
                                this.startDateChangeInputType("text");
                            }}
                            errors={surveyStartDateError}
                            value={this.state.startDateSetInputTypeAs === "text" ? this.state.formattedStartDate : this.state.startDate}
                            max="9999-12-31"
                            backgroundColor={ this.checkDateIsFuture(currentStartDate) ? "" : "#F0F0F0"}
                            fontWeight="500"
                            showErrors={surveyStartDateError.length > 0}
                            style={{
                                pointerEvents: this.checkDateIsFuture(currentStartDate) ? "auto" : " none",
                                userSelect: this.checkDateIsFuture(currentStartDate) ? "auto" : " none",
                                width: this.state.startDateSetInputTypeAs === "text" ? "90%" : "100%",
                                borderRight: this.state.startDateSetInputTypeAs === "text" ? "none !important" : ""
                            }}
                        >
                        </SurveyDurationFormField>
                    </GridChild>

                    <GridChild style={{ textAlign: "center", marginRight: "0px" }}>
                        <Text color="#000" align="left" size="16px" weight="bold" margin="16px 0 16px 0">
                        Change Finish Date
                        </Text>
                        <SurveyDurationFormField 
                            size="16px"
                            height="50px"
                            placeholderColor="#000000"
                            border="1px solid #2D3037"
                            borderRadius={ this.state.finishDateSetInputTypeAs === "text" ? "12px 0px 0px 12px !important" : "12px"}
                            color="#000000"
                            align="left"
                            type={this.state.finishDateSetInputTypeAs}
                            inputName="surveyDurationInput"
                            placeholder="Choose date..."
                            onChange={(event) => {this.setFinishDate(event, inputFinishDate);} }
                            onFocus={() => {
                                this.finishDateChangeInputType("date");
                            }}
                            onBlur={() => {
                                this.finishDateChangeInputType("text");
                            }}
                            errors={surveyFinishDateError}
                            value={this.state.finishDateSetInputTypeAs === "text" ? this.state.formattedFinishDate : this.state.finishDate}
                            max="9999-12-31"
                            fontWeight="500"
                            showErrors={surveyFinishDateError.length > 0}
                            style={{ 
                                width: this.state.finishDateSetInputTypeAs === "text" ? "90%" : "100%"
                            }}
                        >
                        </SurveyDurationFormField>
                    </GridChild>
                </GridContainer>

                { hasAnySurveyDurationErrors &&
                    <ErrorText>
                        { startDateInvalid.length > 0 ? startDateInvalid : null }
                        { finishDateInvalid.length > 0 ? finishDateInvalid : null }
                        { dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null }
                        { dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null }
                        { dateRangeInvalid.length > 0 ? dateRangeInvalid : null }
                        { userGroupOverLap.length > 0 ? userGroupOverLap : null }
                    </ErrorText>
                }
            </>
        );
    }
}

class PageSurveyQuestions extends Component {
    render() {
        const { inputQuestion, inputErrors, survey } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);   

        let surveyQuestionOneError = getErrorStrings(ErrorList.EMPTY_QUESTION_ONE);
        let surveyQuestionTwoError = getErrorStrings(ErrorList.EMPTY_QUESTION_TWO);
        let surveyQuestionThreeError = getErrorStrings(ErrorList.EMPTY_QUESTION_THREE);

        let surveyQuestionOne = (survey.surveyQuestions[0]) ? survey.surveyQuestions[0].question : "";
        let surveyQuestionTwo = (survey.surveyQuestions[1]) ? survey.surveyQuestions[1].question : "";
        let surveyQuestionThree = (survey.surveyQuestions[2]) ? survey.surveyQuestions[2].question : "";
        return (
            <>
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer questionNumber={1} questionValue={surveyQuestionOne} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} />
                        </div>
                    </div>
                }
           
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer questionNumber={2} questionValue={surveyQuestionTwo} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} />
                        </div>
                    </div>
                }
          
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer questionNumber={3} questionValue={surveyQuestionThree} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} />
                        </div>
                    </div>
                }
            </>
        );
    }
}

class PageSurveyFrequency extends Component {
    render() {
        const { survey, inputErrors, selectShowIn } = this.props;
        
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const showInError = getErrorStrings(ErrorList.EMPTY_SHOW_IN);
        const showFrequencyError = getErrorStrings(ErrorList.EMPTY_SHOW_FREQUENCY);

        return (

            <>
                <FlexContainer>
                    <Text color="#000" align="left" margin="16px 0 16px 0" size="16px" weight="500" >
                    Show Survey In:
                    </Text>

                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        options={ surveyShowInOptions }
                        onChange={(choice) => selectShowIn(choice)}
                        styles={customStyles}
                        value={ surveyShowInOptions.find(obj => obj.value == survey.showIn) }
                    />

                    <ErrorText>
                        {showInError}
                    </ErrorText>

                    <Text color="#000" align="left" margin="16px 0 16px 0" size="16px" weight="500">
                    Select Survey Frequency?
                    </Text>
                
                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        options={ surveyFrequencyOptions }
                        onChange={(choice) => selectShowIn(choice)}
                        styles={customStyles}
                        value={ surveyFrequencyOptions.find(obj => obj.value == survey.showFrequency) }
                    />
                
                    <ErrorText>
                        {showFrequencyError}
                    </ErrorText>

                    <br/>
               
                </FlexContainer>
            </>
        );
    }
}

class TypeOfSurveyField extends Component {
    render() {
        const { inputErrors, survey } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyTypeError = getErrorStrings(ErrorList.EMPTY_SURVEY_TYPE);

        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Survey Type
                </Text>
                <Select
                    isDisabled={true}
                    value={ surveyTypeOptions.find(obj => obj.value === survey.surveyType) }
                    isSearchable={false}
                    className="surveyTypeDropdown"
                    placeholder="Please select..."
                    options={ surveyTypeOptions }
                    styles={customDisabledStyles}
                    components={{ Option: IconOption, SingleValue: SingleValue }}
                />
                { surveyTypeError.length > 0 &&
                    <ErrorText errorMargin="15px 20px 15px">
                        {surveyTypeError}
                    </ErrorText>
                }
            </>
        );
    }
}

class UserGroupField extends Component {
    render() {
        const { survey, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const userGroupError = getErrorStrings(ErrorList.EMPTY_USER_GROUP);
        const userGroupAndDateError = getErrorStrings(ErrorList.DATE_AND_USERGROUP_OVERLAP);

        const hasAnyUserGroupErrors = userGroupError.length > 0 || userGroupAndDateError.length > 0;
        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Select User Group
                </Text>

                <Select
                    isDisabled={true}
                    value={ surveyUserGroupOptions.find(obj => obj.value == survey.userGroup) }
                    isSearchable={false}
                    className="surveyTypeDropdown"
                    placeholder="Select a user group"
                    options={ surveyUserGroupOptions }
                    styles={customDisabledStyles}
                />

                { hasAnyUserGroupErrors &&
                    <ErrorText errorMargin="15px 20px 15px">
                        {userGroupError.length > 0 ? userGroupError : null}
                        {userGroupAndDateError.length > 0 ? userGroupAndDateError : null}
                    </ErrorText>
                }
            </>
        );
    }
}

class SurveySubmissionAnonymousField extends Component {
    render() {
        const { isSurveyAnonymous } = this.props;
        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Make Survey Submission Anonymous (Optional)
                </Text>
                <Text align="left" size="14px" color="#8291B2">
                    By checking this box, you ensure that all survey responses will be collected anonymously. This<br/>
                    means users can share their thoughts freely and without any personal identifiers, promoting<br/>candid and unbiased feedback.
                </Text>
                <FlexContainer>
                    {parseInt(isSurveyAnonymous) ? 
                        <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", borderRadius: "12px", border: "2px solid #000000", width: "39px", height: "39px", display: "flex", cursor: "not-allowed" }} >
                            <div style={{ background: "#36BE24 0% 0% no-repeat padding-box", borderRadius: "6px", width: "23px", height: "23px", margin: "auto" }} />
                        </div>
                        :
                        <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", borderRadius: "12px", border: "2px solid #000000", width: "39px", height: "39px", cursor: "not-allowed" }}/>
                    }
                </FlexContainer>
            </>
        );
    }
}

export default EditForm;