import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "src/scenes/App";

import DirectoryTable from "./components/DirectoryTable";
import ContactModal from "./components/ContactModal";
import DeleteDialog from "./components/DeleteDialog";
import { Toast, PageContainer, PageHeadingLabel } from "src/components";
import AccessInformation from "./components/AccessInformation";

const InputSelector = styled.select`
    height: 3.188rem !important;
    width: 19.7rem !important;
`;

const FILTER_DIRECTORY = {
    EMPLOYEE: "employee",
    CLIENT: "client",
    EMPLOYEE_AND_CLIENT: "employee_and_client"
};

const DEFAULT_FORM_STATE = {
    visibleToEmployees: false,
    visibleToClients: false,
    title: null,
    position: null,
    department: null,
    email: null,
    phoneNumber: null,
    website: null,
    description: null,
    imageUrl: null,
    expirationDate: null,
    customFields: []
};

const Directory = ({ user }) => {
    const [filterOption, setFilterOption] = useState(FILTER_DIRECTORY.EMPLOYEE);
    const [isFetching, setIsFetching] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [contactsArray, setContactsArray] = useState([]);
    const [showContactModal, setShowContactModal] = useState(false);
    const [formData, setFormData] = useState(DEFAULT_FORM_STATE);
    const [updatingUuid, setUpdatingUuid] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [checkAllContacts, setCheckAllContacts] = useState(false);
    const [checkedContacts, setCheckedContacts] = useState([]);

    useEffect(() => {
        if (user.enabledBusinessDirectory) fetchData();
    }, [user.token]);

    const fetchData = async () => {
        setIsFetching(true);
        try {
            const res = await axios.get(`${API_URL}/businessContacts`, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            setContactsArray(res.data.contacts);
        } catch (error) {
            Toast.error("Unable to fetch contacts. Please try again later or contact support if the issue persists.");
        } finally {
            setIsFetching(false);
        }
    };

    const handleFilterChange = event => {
        setFilterOption(event.target.value);
        // reset selected contacts (checkboxes)
        setCheckAllContacts(false);
        setCheckedContacts([]);
    };

    const handleEditContact = contact => {
        setUpdatingUuid(contact.uuid);
        setFormData({
            uuid: contact.uuid,
            visibleToEmployees: contact.visibleToEmployees,
            visibleToClients: contact.visibleToClients,
            title: contact.title,
            position: contact.position,
            department: contact.department,
            email: contact.email,
            phoneNumber: contact.phoneNumber,
            website: contact.website,
            description: contact.description,
            imageUrl: contact.imageUrl,
            expirationDate: contact.expirationDate,
            customFields: contact.customFields
        });
        setShowContactModal(true);
    };

    const handleShowContactModal = () => {
        setShowContactModal(true);
    };

    const handleCloseContactModal = () => {
        resetDefaultState();
    };

    const handleCreateOrUpdate = async (formData, image) => {
        try {
            setIsFormLoading(true);
            // upload image
            if (image) {
                let fileExtension = image.name.split(".").pop();
                // get s3 link for image
                const s3Response = await axios.get(`${API_URL}/businessContacts/generateImageUrl/${fileExtension}`, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                });

                const fullUrl = s3Response.data.url;
                const storedUrl = fullUrl.substring(0, fullUrl.indexOf("?"));
                formData.imageUrl = storedUrl;

                // upload image
                await axios.put(fullUrl, image, {
                    headers: { "Content-Type": image.type }
                });
            }
            // create/update contact
            if (updatingUuid) {
                const response = await axios.put(`${API_URL}/businessContacts/${updatingUuid}`, formData, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                });
                const updatedContact = response.data.contact;
                setContactsArray(prevContacts => {
                    const updatedContacts = prevContacts.map(contact => {
                        if (contact.uuid === updatingUuid) {
                            return updatedContact;
                        } else {
                            return contact;
                        }
                    });
                    return updatedContacts;
                });
            } else {
                const response = await axios.post(`${API_URL}/businessContacts`, formData, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                });
                const newContact = response.data.contact;
                setContactsArray(prevContacts => [...prevContacts, newContact]);
            }
        } catch (error) {
            Toast.error("Unable to create or update the contact. Please try again later or contact support if the issue persists.");
        } finally {
            resetDefaultState();
        }
    };

    const handleSingleDelete = async () => {
        try {
            await axios.delete(`${API_URL}/businessContacts/${updatingUuid}`, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            const indexToDelete = contactsArray.findIndex(contact => contact.uuid === updatingUuid);
            if (indexToDelete !== -1) {
                const updatedContacts = [...contactsArray];
                updatedContacts.splice(indexToDelete, 1);
                setContactsArray(updatedContacts);
            }
        } catch (error) {
            Toast.error("Unable to delete the contact. Please try again later or contact support if the issue persists.");
        } finally {
            resetDefaultState();
            handleCloseDeleteDialog();
        }
    };

    const handleMultipleDelete = async (contactUuids) => {
        try {
            await axios.delete(`${API_URL}/businessContacts`, {
                data: { contactUuids },
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
        } catch (error) {
            Toast.error("Unable to delete the selected contacts. Please try again later or contact support if the issue persists.");
        } finally {
            fetchData();
            handleCloseDeleteDialog();
        }
    };

    const handleCheckAllContacts = (contacts) => {
        const toggleState = !checkAllContacts;
        setCheckAllContacts(toggleState);
        const checked = toggleState ? contacts.map(contact => contact.uuid) : [];
        setCheckedContacts(checked);
    };

    const handleCheckboxChange = (event, contactUuid) => {
        const { checked } = event.target;
        setCheckedContacts(prevChecked => {
            if (checked) {
                return [...prevChecked, contactUuid];
            } else {
                return prevChecked.filter(uuid => uuid !== contactUuid);
            }
        });
    };

    const handleSingleOrMultipleDelete = () => {
        updatingUuid ? handleSingleDelete() : handleMultipleDelete(checkedContacts);
    };

    const handleRemoveCustomField = async (field, contact, setContactData, setRemovingUuid) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this custom field?");
        if (!isConfirmed) {
            return;
        }

        setRemovingUuid(field.uuid || field.name); // Use uuid if present, otherwise use name

        if (field.uuid) {
            try {
                await axios.delete(`${API_URL}/businessContacts/customField/${field.uuid}`, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                });
            } catch (error) {
                Toast.error("Unable to remove the custom field. Please try again later or contact support if the issue persists.");
                setRemovingUuid(null);
                return;
            }
        }

        const updatedCustomFields = contact.customFields.filter(cf => cf.name !== field.name);
        setContactData(prevContactData => ({
            ...prevContactData,
            customFields: updatedCustomFields
        }));
        setRemovingUuid(null);
    };

    const handleCreateCustomField = async (
        name,
        contact,
        setContactData,
        setIsAddingField,
        setNewCustomFieldName,
        setIsSavingNewField
    ) => {
        if (!name.trim()) {
            // Do nothing if the name parameter is empty or only contains whitespace
            return;
        }

        if (!updatingUuid) { // Check if not editing
            const newCustomField = { name: name, value: "" };
            const customFields = [...contact.customFields, newCustomField];
            setContactData(prevContactData => ({
                ...prevContactData,
                customFields: customFields
            }));
            setNewCustomFieldName("");
            setIsAddingField(false);
            return;
        }

        setIsSavingNewField(true);

        try {
            const response = await axios.post(`${API_URL}/businessContacts/customField`, {
                "contactUuid": contact.uuid,
                "name": name
            }, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            const newCustomField = response.data.customField;
            const updatedCustomFields = [...contact.customFields, newCustomField];
            setContactData(prevContactData => ({
                ...prevContactData,
                customFields: updatedCustomFields
            }));
            setIsAddingField(false);
            setNewCustomFieldName("");
            setIsSavingNewField(false);
        } catch (error) {
            Toast.error("Unable to create the custom field. Please try again later or contact support if the issue persists.");
        }
    };

    const handleShowDeleteDialog = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const resetDefaultState = () => {
        setUpdatingUuid(null);
        setIsFormLoading(false);
        setFormData(DEFAULT_FORM_STATE);
        setShowContactModal(false);
    };

    const filteredContacts = contactsArray.filter(contact => {
        if (filterOption === FILTER_DIRECTORY.EMPLOYEE) {
            return contact.visibleToEmployees === true && contact.visibleToClients === false;
        } else if (filterOption === FILTER_DIRECTORY.CLIENT) {
            return contact.visibleToClients === true && contact.visibleToEmployees === false;
        } else if (filterOption === FILTER_DIRECTORY.EMPLOYEE_AND_CLIENT) {
            return contact.visibleToEmployees === true && contact.visibleToClients === true;
        }
        return false;
    });

    return (
        <PageContainer>
            { !user.enabledBusinessDirectory &&
                <AccessInformation />
            }

            { user.enabledBusinessDirectory &&
                <>
                    <PageHeadingLabel>
                        Directory
                    </PageHeadingLabel>

                    <div className="row" style={{ maxWidth: "94.5rem", margin: "5px 0 20px 0px" }}>
                        <div className="description">
                            Create a custom business directory seamlessly within your Me Business app. Decide whether it’s accessible to <br />
                            your employees or clients. Take your business communication to the next level with the integrated Directory <br />
                            feature.
                        </div>

                        <div className="field">
                            <label>Filter:</label>
                            <InputSelector style={{ height: 51 }} onChange={handleFilterChange}>
                                <option value={FILTER_DIRECTORY.EMPLOYEE}>Employee Directory</option>
                                <option value={FILTER_DIRECTORY.CLIENT}>Client Directory</option>
                                <option value={FILTER_DIRECTORY.EMPLOYEE_AND_CLIENT}>Employee & Client Directory</option>
                            </InputSelector>
                        </div>
                    </div>

                    <DirectoryTable
                        user={user}
                        contactsArray={filteredContacts}
                        handleOpenModal={handleShowContactModal}
                        handleEditContact={handleEditContact}
                        handleShowDeleteDialog={handleShowDeleteDialog}
                        checkAllContacts={checkAllContacts}
                        checkedContacts={checkedContacts}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckAllContacts={handleCheckAllContacts}
                        isFetching={isFetching}
                    />
                </>
            }

            {showContactModal && (
                <ContactModal
                    showContactModal={showContactModal}
                    handleCloseModal={handleCloseContactModal}
                    formData={formData}
                    updatingUuid={updatingUuid}
                    handleCreateOrUpdate={handleCreateOrUpdate}
                    handleShowDeleteDialog={handleShowDeleteDialog}
                    isFormLoading={isFormLoading}
                    handleRemoveCustomField={handleRemoveCustomField}
                    handleCreateCustomField={handleCreateCustomField}
                />
            )}
            {showDeleteDialog && (
                <DeleteDialog
                    showDeleteDialog={showDeleteDialog}
                    handleCloseDeleteDialog={handleCloseDeleteDialog}
                    handleSingleOrMultipleDelete={handleSingleOrMultipleDelete}
                />
            )}
        </PageContainer>
    );
};

export default withRouter(Directory);