import { ENVIRONMENT } from "src/scenes/App";
import { DAYSTRINGS } from "src/constants/chat";
import moment from "moment";

/**
 * Given a start date (or date-time) and a "subscription period" as reported by the API, calculate when the
 * subscription's expiry is and return as a Date object.
 * The meaning of the subscription period varies by environment: a number of hours on localhost, days on staging, and
 * months on production.
 * @param {number|string|Date} startDate
 * @param {number} period
 * @param {boolean} withGrace
 * @returns {Date}
 */
export const getCompanySubscriptionEnd = (startDate, period, withGrace) => {
    const date = new Date(startDate);
    switch (ENVIRONMENT.get()) {
    case ENVIRONMENT.DEV:
        date.setHours(date.getHours() + period);
        if (withGrace) {
            // Move to start of next hour
            date.setHours(date.getHours() + 1);
            date.setMinutes(0, 0);
        }
        break;
    case ENVIRONMENT.STAGING:
        date.setDate(date.getDate() + period);
        if (withGrace) {
            // Move to start of next day
            date.setDate(date.getDate() + 1);
            date.setHours(0, 0, 0);
        }
        break;
    case ENVIRONMENT.PRODUCTION:
        date.setMonth(date.getMonth() + period);
        if (withGrace) {
            // Move to start of next day
            date.setDate(date.getDate() + 1);
            date.setHours(0, 0, 0);
        }
        break;
    default:
        throw new Error("Unknown environment");
    }
    return date;
};

/**
 * Given an expiry date (or date-time), return a user-facing string for how long is remaining (e.g. "Expires in 7
 * days"), or "Expired" if it has already passed.
 * Scale is environment-specific: hours on localhost or days on staging and production.
 * @param {number|string|Date} endDate
 * @param {boolean} remainingStyle - whether to return like "7 Days Remaining" or "Expires in 7 Days"
 * @returns {string}
 */
export const getCompanySubscriptionExpiryString = (endDate, remainingStyle) => {
    const current = new Date();
    const end = new Date(endDate);
    const diffInMillis = end.getTime() - current.getTime();
    if (diffInMillis < 0) {
        return "Expired";
    }

    let count;
    switch (ENVIRONMENT.get()) {
    case ENVIRONMENT.DEV:
        count = Math.floor(diffInMillis / (1000 * 60 * 60));
        return remainingStyle ? `${count} Hours Remaining` : `Expires in ${count} Hours`;
    case ENVIRONMENT.STAGING:
    case ENVIRONMENT.PRODUCTION:
        count = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
        return remainingStyle ? `${count} Days Remaining` : `Expires in ${count} Days`;
    default:
        return "Unknown environment";
    }
};

export const findDiffInUnitsRoundingUp = (from, to, unit) => {
    const diffInHours = (to.getTime() - from.getTime()) / (1000 * 60 * 60);
    switch (unit) {
    case "day":
        return Math.ceil(diffInHours / 24);
    case "hour":
        return Math.ceil(diffInHours);
    default:
        return 0;
    }
};

/**
 * @param {number} timestamp 
 * @returns {string}
 */
export const parseTimestamp = (timestamp) => {
    if (!isValidTimestamp(timestamp)) {
        console.error("Invalid timestamp.");
        return;
    }

    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Create a readable date string
    const readableDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return readableDate;
};

const isValidTimestamp = (timestamp) => {
    if (typeof timestamp !== "number") {
        return false;
    }

    const dateInstance = new Date(timestamp);
    return !isNaN(dateInstance.getTime());
};

export const renderChatListTimestamp = (dateString) => {
    const date = moment(new Date(dateString));
    const today = moment();
    const yesterday = moment().subtract(1, "days");
    const daysDifference = today.diff(date, "days");
    const datesAreSameForToday = date.isSame(today, "year") && date.isSame(today, "month") && date.isSame(today, "day");
    if (daysDifference <= 7) {
        if (date.isBetween(yesterday.startOf("day"), moment().subtract(1, "days").endOf("day"))) {
            return DAYSTRINGS.YESTERDAY;
        }

        if (datesAreSameForToday) { // if message is sent today
            if (date.isAfter(today.subtract(1, "minutes"))) {
                return "Now";
            }
            const readableTime = date.format("hh:mm");
            return readableTime;
        }
        const dayOfWeekAbbreviation = date.format("ddd");
        return dayOfWeekAbbreviation; // Mon to Sun
    }

    return date.format("DD MMM YYYY");
};

/**
 * @param {string} dateString 
 * @returns {Object} - An object with 'day(01 Dec 2023/Today/Yesterday)' and 'time(09:30)' properties
 */
export const calculateTimelineMessageDate = (dateString) => {
    const response = { time: "", day: "" };
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const readableTime = date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hourCycle: "h12" });

    const isToday = date.toISOString().slice(0, 10) === today.toISOString().slice(0, 10);
    const isYesterday = date.toISOString().slice(0, 10) === yesterday.toISOString().slice(0, 10);
    
    if (isToday || isYesterday) {
        response.time = readableTime;
        response.day = isToday ? DAYSTRINGS.TODAY : DAYSTRINGS.YESTERDAY;

        return response;
    }

    const readableDate = date.toLocaleDateString("en-US", { day: "2-digit", month: "long", year: "numeric" });
    const parts = readableDate.replace(",", "").split(" ");

    response.day = `${parts[1]} ${parts[0]} ${parts[2]}`;
    response.time = readableTime;

    return response;
};

/**
 * Checks if both date strings are within the given interval
 * @param {number} timestamp1
 * @param {number} timestamp2
 * @param {int} allowedDifferenceInSeconds
 * @returns {boolean}
 */
export const isWithinTimeInterval = (timestamp1, timestamp2, allowedDifferenceInSeconds) => {
    if (!isValidTimestamp(timestamp1) || !isValidTimestamp(timestamp2)) {
        return false;
    }
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    // calculate the difference in milliseconds
    const timeDifference = Math.abs(date1 - date2) / 1000;
    return timeDifference <= allowedDifferenceInSeconds;
};

/**
 * 
 * @param {string} dateString use ISOString format
 * @returns {boolean|Date}
 */
export const getSubscriptionExpired = (subscriptionStart, subscriptionPeriod, withGrace) => {
    const expiredDate = getCompanySubscriptionEnd(subscriptionStart, subscriptionPeriod, withGrace);
    const calculationUnit = ENVIRONMENT.get() === ENVIRONMENT.PRODUCTION ? "day" : "hour";
    const remainingUnits = findDiffInUnitsRoundingUp(new Date(), expiredDate, calculationUnit);
    return remainingUnits < 1;
};