import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import AppleImg from "src/img/new/apple.svg";
import GoogleImg from "src/img/new/Google.svg";
import LogoImg2 from "../img/new/me_logo.svg";
import { mobile, tablet, desktop } from "./Media";
import { IsFeatureReady } from "src/constants/features";
// Styled components

const Container = styled.header`
    background: ${p => p.url ? `url(${p.url}) no-repeat center ${p.location || "top"}` : null};
    background-color: ${p => p.transparent ? "transparent" : p.backgroundColor };
    flex: 0;
    top: ${p => p.top};
    transition: background-color 0.3s;
    width: 100%;
`;

const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    height: 90px;
    width: 100%;
    text-align: center;
    background: #000000;
    ${desktop`
        flex-direction: row;
        align-items: center;
    `};
    ${tablet`
        height: auto;
    `};
    ${mobile`
        height: auto;
    `};
`;

const Logo = styled.div`
    width: 18em;
    height: 1.8em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Navigation = styled.nav`
    flex: 1;
    display: inline-flex;
    flex-grow: 2;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
    ${desktop`
        padding-top: 0;
    `};
    
`;

const LoggedOutNavigationLink = styled(Link)`
    font-size: 14px;
    font-weight: 500;
    margin-left: ${p => p.marginleft || "1vw"};
    text-decoration: none;
    padding: 10px 40px;
    color: ${p => p.color || "black"};
    align-items: flex-end;
    border: 0.2em solid white;
    border-radius: 20px;
    color: ${props => props.ishighlighted === "true" ? "#000000" : "white"};
    ${props => props.ishighlighted === "true" && css`
        background-color: white;
    `};
`;

const StoreButtonContainer = styled.div`
    justify-content: flex-end;
    align-items: center;
    z-index: 7;
    display: none;
    ${tablet`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        width: 100%;
    `};
    
    ${desktop`
        flex-direction: row;
        position: initial;
        width: 18em;
    `};
`;

const StoreButton = styled.img`
    height: 39px;
    margin: 0 0.6em 0;
`;

function Header(props) {
    const { location, top, url, transparent, user, ...styleProps } = props;
    const currentPageIs = path => location.pathname === path;
    const linkColor = currentPageIs("/") ? "white" : null;

    return (
        <Container transparent={transparent} top={top} url={url} backgroundColor="transparent">
            <Content { ...styleProps }>
                <Logo>
                    <a href="https://memotivationapp.com" style={{ textDecoration: "none" }}>
                        <img src={LogoImg2} alt="me_logo"></img>
                    </a>
                </Logo>
                {
                    !user ? 
                        <React.Fragment>
                            <Navigation>
                                { IsFeatureReady.signUpButton() && <>
                                    <LoggedOutNavigationLink to="/signUp" marginleft="0" ishighlighted={currentPageIs("/signUp").toString()} color={linkColor}>
                                    Sign Up
                                    </LoggedOutNavigationLink>
                                    <LoggedOutNavigationLink to="/login" ishighlighted={currentPageIs("/login").toString()} color={linkColor}>
                                    Log In
                                    </LoggedOutNavigationLink>
                                
                                </> }
                            </Navigation>

                            <StoreButtonContainer>
                                <a href="https://apps.apple.com/au/app/daily-fix-me-motivation/id1445382703" style={{ width: "150.82px" }}>
                                    <StoreButton src={AppleImg} alt="Download on the App Store" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.onpoint_holdings.dailyfixme" style={{ width: "150.82px" }}>
                                    <StoreButton src={GoogleImg} alt="Get it on Google Play"/>
                                </a>
                            </StoreButtonContainer>
                        </React.Fragment> 
                
                        : ""
                
                }
            </Content>
        </Container>
    );
}

export default withRouter(Header);