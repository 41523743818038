import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_URL } from "src/scenes/App";
import styled, { css } from "styled-components";
import {
    Text, FlexContainer, Button, LoadingIndicator, ErrorLabel
} from "src/components";

const UserTypes = {
    USER: "user",
    ACCOUNT_OWNER: "accountOwner"
};

const FormLabels = {
    user: "Enter Your Access Code",
    accountOwner: "Enter Your Email Address"
};

const ToLogin = styled(Link)`
    background: #000;
    color: #fff;
    font-size: 0.875rem;
    margin-top: 6.625rem;
    font-family: Roboto, Helvetica, sans-serif;
    width: 8.5rem;
    height: 2.5rem;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
`;

const StyledForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Roboto, Helvetica, sans-serif;
`;

const StyledInput = styled.input`
    font-family: ${p => p.fontFamily || "Roboto, Helvetica, sans-serif"};
    text-align: ${p => p.align || "center"};
    background-color: ${p => p.backgroundColor || "white"};
    opacity: ${p => p.opacity || "1"};
    width: ${p => p.width || "100%"};
    height: ${p => p.height || "2.4rem"};
    flex-basis: ${p => p.flexBasis};
    padding: ${p => p.padding || "10px 20px 10px 20px"};
    border-radius: ${p => p.borderRadius || "1.2rem"};
    border: ${p => p.border || "none"};
    border-bottom: ${p => p.borderBottom};
    margin: ${p => p.margin || "5px 0"};
    box-sizing: border-box;
    font-size: ${p => p.size || "0.8rem"};
    overflow: ${p => p.hiddenOverFlow && "hidden"};
    font-weight: ${p => p.fontWeight || "400"};
    max-width: ${p => p.maxWidth};
    ::placeholder {
        opacity: ${p => p.opacity || "initial"};
        color: ${p => p.placeholderColor};
        font-weight: ${p => p.placeholderWeight || "400"};
        text-align: ${p => (p.align || p.placeholderAlignment) || "center"};
        font-size: ${p => p.placeholderSize || "0.875rem"};
    };
    ${p => p.isHighlighted && css`
        border: 1px solid red;
    `};
    ::-webkit-inner-spin-button { display: none; }
    ::-webkit-datetime-edit-text { 
        color: #c1a999; 
        padding: 0 0.3rem; 
    }
    ::-webkit-datetime-edit-month-field { color: ${p => p.color || "#6A1E00"}; } 
    ::-webkit-datetime-edit-day-field { color: ${p => p.color || "#6A1E00"}; }
    ::-webkit-datetime-edit-year-field { color: ${p => p.color || "#6A1E00"}; }   
    ::-webkit-calendar-picker-indicator { background: #6A1E00; }
`;

class ForgotPassword extends Component {
    state = {
        accessCode: "",
        email: "",
        isComplete: false,
        errorMessage: "",
        loading: false,
        userType: this.props.match.params.userType || null
    };

    componentDidMount() {
        localStorage.clear(); //remove cookies, to make the logged-in user as Guest
    }

    continue = (e) => {
        const { accessCode, email, userType } = this.state;
        this.setState({ loading: true, errorMessage: "" });

        let url;
        let formData;

        // for sending password reset requests of user logins (same endpoint used by mobile apps forgot-password)
        if (userType === UserTypes.USER) {
            if (accessCode.trim() === "") {
                return this.setState({ errorMessage: "* Please enter a valid access code.", loading: false });
            }
            else {
                url = `${API_URL}/sendSetPasswordLink`;
                formData = { accessToken: accessCode };
            }
        }

        // for sending password reset requests of account owner logins
        else if (userType === UserTypes.ACCOUNT_OWNER) {
            if (email.trim() === "") {
                return this.setState({ errorMessage: "* Please enter a valid email address.", loading: false });
            }
            else {
                url = `${API_URL}/company/forgotPassword`;
                formData = { email: email };
            }
        }

        axios.post(url, formData)
            .then(res => {
                this.setState({
                    isComplete: true,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({
                    errorMessage: error,
                    loading: false
                });
            });
    };

    handleInputChange = (e) => {
        const { userType } = this.state;
        if (userType === UserTypes.ACCOUNT_OWNER) {
            this.setState({ email: e.target.value });
        }
        else if (userType === UserTypes.USER) {
            this.setState({ accessCode: e.target.value });
        }
    };

    render() {
        const { errorMessage, isComplete, loading, userType } = this.state;

        return (
            <FlexContainer alignItems="center" justifyContent="center" flexGrow="1">
                {!isComplete ?
                    <StyledForm>
                        <Text family="Roboto, Helvetica, sans-serif" size="2.5rem" weight="500" color="white">
                            Reset Your Password
                        </Text>
                        <Text color="white" family="Roboto, Helvetica, sans-serif" weight="500" size="1.25rem">
                            {FormLabels[userType]}
                        </Text>
                        <StyledInput
                            size="14px"
                            width="15.6875rem"
                            placeholder={FormLabels[userType]}
                            placeholderColor="#000"
                            placeholderWeight="500"
                            fontFamily="Roboto, Helvetica, sans-serif"
                            align="center"
                            showErrors={false}
                            onChange={(e) => this.handleInputChange(e)}
                            disabled={loading}
                        />
                        <ErrorLabel width="15.6875rem" align="center" margin="14px 0 0 0">{errorMessage}</ErrorLabel>
                        {loading ? <div style={{ marginTop: "4rem" }}><LoadingIndicator /> </div>
                            :
                            <Button backgroundColor="#000"
                                color="#fff"
                                size="0.875rem"
                                alt="Send"
                                marginTop="6.625rem"
                                family="Roboto, Helvetica, sans-serif"
                                onClick={() => this.continue()}
                                style={{ borderRadius: 20, padding: "10px 50px 10px" }}
                            >
                                Send
                            </Button>
                        }

                    </StyledForm>
                    :
                    <React.Fragment>
                        <Text color="#fff" size="2.5rem" family="Roboto, Helvetica, sans-serif" weight="500">
                            Password Reset Sent!
                        </Text>
                        <Text color="#fff" size="0.875rem" family="Roboto, Helvetica, sans-serif" weight="500">
                            We’ve emailed you instructions to reset your password. After<br /> completing the process, please log in.
                        </Text>
                        <ToLogin to="/login">Login To App</ToLogin>
                    </React.Fragment>
                }
            </FlexContainer>
        );
    }
}

export default ForgotPassword;