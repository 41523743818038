
export const SUBSCRIPTION_TYPE_MONTHLY = "month";
export const SUBSCRIPTION_TYPE_YEARLY = "year";
export const SUBSCRIPTION_MONTHLY_DESCRIPTION = "Monthly \nYearly plan, Paid Monthly";
export const SUBSCRIPTION_YEARLY_DESCRIPTION = "Yearly \nYearly plan, Prepaid";

export const AUSTRALIA = "AU";

/** additional 10% for australian customers */
export const GST = 0.1;
