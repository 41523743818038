import { useContext, useState } from "react";
import { UserContext } from "src/scenes/App/UserContext";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { Toast } from "src/components";

/**
 * Custom react hook for fetching leadership roles and leadership employees
 * @returns {{
 *      isFetchingLeadership: boolean               
 *      leadershipRoles: array
 *      leadershipToShow: object                    // selected leadership role showing the role and available leadership employees to chat
 *      retrieveLeadershipRoles: Function,
 *      retrieveLeadershipEmployees: Function           
 * }}
 */
export const useGetLeadershipRoles = () => {
    const userContext = useContext(UserContext);
    const [isFetchingLeadership, setIsFetchingLeadership] = useState(false);
    const [leadershipRoles, setLeadershipRoles] = useState([]);
    const [leadershipToShow, setLeadershipToShow] = useState(null);
    const headers = { headers: { Authorization: "Bearer " + userContext.token } };

    const retrieveLeadershipRoles = async () => {
        setIsFetchingLeadership(true);
        try {
            const response = await axios.get(`${API_URL}/chats/leadership/roles`, headers);
            setLeadershipRoles(response.data.leadershipRoles ?? []);
        } catch (error) {
            Toast.error("Unable to retrieve leadership roles.");
        } finally {
            setIsFetchingLeadership(false);
        }
    };

    const retrieveLeadershipEmployees = async (leadershipRoleUuid) => {
        setIsFetchingLeadership(true);
        try {
            const response = await axios.get(`${API_URL}/chats/leadership/roles/${leadershipRoleUuid}`, headers);
            setLeadershipToShow(response.data);
        } catch (error) {
            Toast.error("Unable to retrieve leadership employees.");
        } finally {
            setIsFetchingLeadership(false);
        }
    };

    return {
        leadershipRoles,
        isFetchingLeadership,
        leadershipToShow,
        retrieveLeadershipRoles,
        retrieveLeadershipEmployees
    };
};
