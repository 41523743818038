import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";
import axios from "axios";
import styled, { css } from "styled-components";
import { API_URL } from "src/scenes/App";
import { getSubscriptionExpired, getCompanySubscriptionEnd } from "src/utils/dates";
import {
    FlexContainer, LoadingIndicator, Toast, PageContainer, PageHeadingLabel
} from "src/components";
import MyBusiness from "./forms/General";
import Payments from "./forms/Payments";
import BillingHistory from "./forms/BillingHistory";
import Security from "./forms/Security";
import CancelSubscription from "./forms/Subscription";
import RenewalAndEarning from "./forms/RenewalAndEarning";
import { SettingContext } from "./SettingContext";
import { UserContext } from "src/scenes/App/UserContext";
import * as PERMISSIONS from "src/constants/permissions";
import { UserKeys } from "src/constants/userDetails";
import { SubscriptionStatus } from "src/components/SubscriptionElements";

export const Forms = {
    MY_BUSINESS: "/setting/business",
    PAYMENTS: "/setting/payment",
    SECURITY: "/setting/security",
    BILLING_HISTORY: "/setting/billingHistory",
    SUBSCRIPTION: "/setting/subscriptions",
    RENEWALS_AND_EARNINGS: "/setting/renewalsAndEarnings",
};

const ItemWrapper = styled.div`
    background: none;
    padding: 0 15px;
    & a {
        color: #000;
        text-decoration: none;
        width:100%;
        height:100%;
    }
    ${ p => p.isHighlighted && css`
        background: #612684 0 0 no-repeat padding-box;
        & a {
            color: #fff;
        }
    `};
`;

const TextDiv = styled.div`
  font-family: Roboto, Helvetica, sans-serif;
  font-size: ${ p => p.fontSize || "14px"};
  text-align: ${ p => p.textAlign || "left" };
  color: #11141A;
  flex-grow: ${ p => p.flexGrow };
  font-weight: ${ p => p.fontWeight || 400};
`;

const Item = styled.div`
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width:100%;
    height:100%;
`;

const ErrorAlert = styled.div`
    height: auto;
    padding: 0.8rem 2rem;
    width: 100%;
    max-width: 800px;
    background: #FFBABA;
    color: #D8000C;
    font-weight: 300;
    border-radius: 10px;
    border: 1px solid #D8000C;

`;

class Setting extends Component {
    static contextType = UserContext;
    
    state = {
        fetching: 0,
        countryCallingCode: "",
        //My Business
        website: null,
        //CancelSubscription
        subscriptionStart: null,
        subscriptionEnd: null,
        subscriptionNextPaymentDate: null,
        subscriptionPeriod: null,
        subscriptionStatus: null,
        employees: 0,
        clients: 0,
    };
    
    componentDidMount() {
        this.safeToInit();
    }

    safeToInit = () => {
        if (this.context[UserKeys.IS_CUSTOMIZE_COMPLETE] && this.hasBillingPermission()) {
            /**
             * running this function on accounts that are yet to finish their theme set up will show the RevokedPermissionsModal 
             * need to finish setup to get the SalesAndPromotionAuthorization permission required for the API
             */
            this.getCompanyClientLicense();
        }

        this.getCompanyDetails();
        this.getCountryCallingCode();
    };

    isAccountOwner = () => PERMISSIONS.hasPermissions([PERMISSIONS.ACCOUNT_OWNER_PERMISSION], this.context.permissions);

    hasBillingPermission = () => PERMISSIONS.hasPermissions([PERMISSIONS.BILLING], this.context.permissions);

    hasCompany = () => this.context.companyName !== null;

    subscriptionNextPaymentDateFormat = (subscriptionEndDate) => {
        const date = new Date(subscriptionEndDate);
        date.setDate(subscriptionEndDate.getDate() + 1);

        return new Intl.DateTimeFormat("default", {        
            year: "numeric",
            month: "long",
            day: "numeric",
        }).format(date);
    };

    getCompanyDetails = (isUpdate = false) => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.get(`${API_URL}/company`, {
            headers: {
                Authorization: "Bearer " + this.context.token
            }
        }).then(res => {
            const startDate = new Date(res.data.subscriptionStart);
            const subscriptionStart = new Intl.DateTimeFormat("default", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }).format(startDate);

            const subscriptionEndDate = getCompanySubscriptionEnd(res.data.subscriptionStart, res.data.subscriptionPeriod, false);
            const subscriptionEnd = new Intl.DateTimeFormat("default", {        
                year: "numeric",
                month: "long",
                day: "numeric",
            }).format(subscriptionEndDate);

            const subscriptionNextPaymentDate = this.subscriptionNextPaymentDateFormat(subscriptionEndDate);

            const isSubscriptionExpired = getSubscriptionExpired(res.data.subscriptionStart, res.data.subscriptionPeriod, true);

            this.context.updateUserContextProperties({
                [UserKeys.COUNTRY_CODE]: res.data.countryCode,
                [UserKeys.COMPANY_ABN]: res.data.abn,
            });

            this.setState(state => ({
                //Setting Template
                fetching: state.fetching - 1,
                companyNumber: res.data.companyNumber,

                //MyBusiness
                website: res.data.website,

                //CancelSubscription
                subscriptionStart: subscriptionStart,
                subscriptionEnd: subscriptionEnd,
                subscriptionNextPaymentDate: subscriptionNextPaymentDate,
                subscriptionPeriod: res.data.subscriptionPeriod,
                subscriptionStatus: isSubscriptionExpired ? SubscriptionStatus.EXPIRED : (res.data.isCancelled ? SubscriptionStatus.REACTIVATE : SubscriptionStatus.ACTIVE),
                totalNumber: res.data.totalNumber,
                employees: res.data.employees,
                clients: res.data.clients,
            }));
        }).catch(error => {
            //remove loading for newly signup company, we use `hasCompany()` for this scenario
            if (error.message === "No company registration has been found") {
                this.setState(state => ({
                    fetching: state.fetching - 1,
                }));
            }
            
            return Toast.error(error.message);
        });
    };

    getCompanyClientLicense = async () => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));
        
        return await axios.get(`${API_URL}/company/clientLicense`, {
            headers: {
                Authorization: "Bearer " + this.context.token
            }
        }).then(res => {
            this.props.setEnabledClientLicenseFlag(true);
        }).catch(error => { /** Check axios.interceptors in App.js */
            this.props.setEnabledClientLicenseFlag(false);
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
            }));
        });
    };

    getCountryCallingCode = () => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        // Getting the country code of the user.
        axios.get(`${API_URL}/callingCode`, {
            headers: {
                Authorization: "Bearer " + this.context.token
            }
        }).then(res => {
            let callingCode = res.data.callingCode;

            this.setState({
                countryCallingCode: callingCode,
            });
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
            }));
        }).catch(error => {
            return Toast.error(error.message);
        });
    };

    setForm = (form) => {
        this.setState({ currentForm: form });
    };

    setWebsite = (website) => {
        this.setState({ website: website });
    };

    setSubscriptionStatus = (subscriptionStatus) => {
        this.setState({ subscriptionStatus: subscriptionStatus });
    };
    
    render() {
        const {
            fetching, countryCallingCode, companyNumber, website,
            subscriptionStart, subscriptionEnd, subscriptionNextPaymentDate, subscriptionPeriod, subscriptionStatus,
            totalNumber, employees, clients
        } = this.state;

        const showRenewalAndEarnings = this.context.enabledClientLicense;
        
        const startingForm = this.isAccountOwner() ? Forms.MY_BUSINESS : Forms.SECURITY;

        let windowsPathname = window.location.pathname;
        if (windowsPathname.length > 0 && windowsPathname.charAt(windowsPathname.length - 1) === "/") {
            windowsPathname = windowsPathname.substring(0, windowsPathname.length - 1);
        }

        const pathname = windowsPathname === "/setting" ? startingForm : windowsPathname;
        const isNoSidePadding = pathname === Forms.RENEWALS_AND_EARNINGS || pathname === Forms.BILLING_HISTORY;
        
        if (fetching > 0) {
            return (
                <PageContainer>
                    <PageHeadingLabel>
                        Account Settings
                    </PageHeadingLabel>
                    <LoadingIndicator />
                </PageContainer>
            );
        }
        
        return (
            <PageContainer style={{ maxWidth: "100rem" }}>
                <FlexContainer direction="row" padding="0 0px 35px 0">
                    <PageHeadingLabel>
                        Account Settings
                    </PageHeadingLabel>
                    <FlexContainer display={ companyNumber ? "flex" : "none" } justifyContent="end" flexGrow="1">
                        <TextDiv fontSize="20px" fontWeight="700" textAlign="right">Customer Number #</TextDiv>
                        <TextDiv fontSize="20px" fontWeight="700" textAlign="right">{ companyNumber }</TextDiv>
                    </FlexContainer>
                </FlexContainer>

                { (pathname === null && fetching === 0) ?
                    <ErrorAlert>
                        <strong>The form failed to load successfully.</strong><br />
                        Please login again. If error failure persist please contact support.
                    </ErrorAlert>
                    :
                    <FlexContainer style={{ display: "flex", flexDirection: "row" }}>
                        <FlexContainer style={{ minWidth: "15.625rem", backgroundColor: "#FFF", marginRight: "10px", borderRadius: "12px", overflow: "hidden" }} >
                            { this.isAccountOwner() &&
                                <ItemWrapper isHighlighted={pathname === Forms.MY_BUSINESS}>
                                    <Item>
                                        <Link to={Forms.MY_BUSINESS}>My Business</Link>
                                    </Item>
                                </ItemWrapper>
                            }

                            <ItemWrapper isHighlighted={pathname === Forms.SECURITY}>
                                <Item>
                                    <Link to={Forms.SECURITY}>Security</Link>
                                </Item>
                            </ItemWrapper>

                            { (this.hasBillingPermission() && this.hasCompany()) &&
                                <React.Fragment>
                                    <ItemWrapper isHighlighted={pathname === Forms.BILLING_HISTORY} >
                                        <Item onClick={() => this.setForm(Forms.BILLING_HISTORY)}>
                                            <Link to={Forms.BILLING_HISTORY}>Billing History</Link>
                                        </Item>
                                    </ItemWrapper>

                                    <ItemWrapper isHighlighted={pathname === Forms.SUBSCRIPTION} >
                                        <Item>
                                            <Link to={Forms.SUBSCRIPTION}>Subscriptions</Link>
                                        </Item>
                                    </ItemWrapper>

                                    <ItemWrapper isHighlighted={pathname === Forms.PAYMENTS} >
                                        <Item>
                                            <Link to={Forms.PAYMENTS}>Payment Methods</Link>
                                        </Item>
                                    </ItemWrapper>
                                    { showRenewalAndEarnings && 
                                        <ItemWrapper isHighlighted={pathname === Forms.RENEWALS_AND_EARNINGS} >
                                            <Item>
                                                <Link to={Forms.RENEWALS_AND_EARNINGS}>Renewals & Earnings</Link>
                                            </Item>
                                        </ItemWrapper>
                                    }
                                </React.Fragment>
                            }
                        </FlexContainer>
                        {fetching === 0 && 
                            <SettingContext.Provider value={{

                                //MyBusiness
                                website: website,
                                setWebsite: this.setWebsite,
                                
                                //CancelSubscription
                                subscriptionStart: subscriptionStart,
                                subscriptionEnd: subscriptionEnd,
                                subscriptionNextPaymentDate: subscriptionNextPaymentDate,
                                subscriptionPeriod: subscriptionPeriod,
                                subscriptionStatus: subscriptionStatus,
                                setSubscriptionStatus: this.setSubscriptionStatus,
                                totalNumber: totalNumber,
                                employees: employees,
                                clients: clients,
                                getCompanyDetails: this.getCompanyDetails, 
                                token: this.context.token,
                                setForm: this.setForm,
                                isAccountOwner: this.isAccountOwner(),
                                hasCompany: this.hasCompany,
                                countryCallingCode: countryCallingCode
                            }}>
                                
                                <FlexContainer style={{ flexGrow: 1, backgroundColor: "#FFF", marginLeft: "10px", padding: `${ isNoSidePadding ? "40px 0px" : "40px"}`, borderRadius: "12px" }}>   
                                    <Switch>
                                        {/* isNoSidePadding */}
                                        {pathname === Forms.RENEWALS_AND_EARNINGS && showRenewalAndEarnings && <Route path={Forms.RENEWALS_AND_EARNINGS} component={RenewalAndEarning} />}
                                        {pathname === Forms.BILLING_HISTORY && <Route path={Forms.BILLING_HISTORY} component={BillingHistory} />}
                                        
                                        {pathname === Forms.PAYMENTS && <Route path={Forms.PAYMENTS} component={Payments} />}
                                        {pathname === Forms.SECURITY && <Route path={Forms.SECURITY} component={Security} />}
                                        {pathname === Forms.SUBSCRIPTION && <Route path={Forms.SUBSCRIPTION} component={CancelSubscription} />}
                                        {(this.isAccountOwner() && pathname === Forms.MY_BUSINESS) && <Route path={Forms.MY_BUSINESS} component={MyBusiness} />}
                                        {<Route path="/setting" component={startingForm === Forms.MY_BUSINESS ? MyBusiness : Security} />}
                                    </Switch>
                                </FlexContainer>
                            </SettingContext.Provider>
                        }
                    </FlexContainer>
                }
                
            </PageContainer>
        );
    }
}

export default Setting;