import React, { useState, useEffect, useRef } from "react";
import MoreMenu from "src/img/SubscriptionsMoreMenu.svg";
import { useHistory } from "react-router-dom";
import { SubscriptionStatus } from "src/components/SubscriptionElements";

function MoreMenuContainerSubscription({ cancelSubscriptionEvent, reactivationSubscriptionEvent, renewalSubscriptionEvent, status }) {
    const [hidden, setHidden] = useState(true);
    const updateRef = useRef(null);
    const cancelRef = useRef(null);

    const handleClick = () => {
        setHidden(!hidden);
    };

    const handleCancelSubscription = () => {
        setHidden(true);
        cancelSubscriptionEvent(true);
    };

    const handleRenewalSubscription = () => {
        setHidden(true);
        renewalSubscriptionEvent(true);
    };

    const handleReactivationSubscription = () => {
        setHidden(true);
        reactivationSubscriptionEvent(true);
    };

    const history = useHistory();
    
    const handleUpdatePaymentMethod = () => {
        history.push("/setting/payment");
    };
    
    const isActive = status === SubscriptionStatus.ACTIVE;
    const isCanceled = status === SubscriptionStatus.REACTIVATE;
    const isExpired = status === SubscriptionStatus.EXPIRED;

    useEffect(() => {
        const handleClickOutside = (event) => {
            const cancelRefElementExist = (cancelRef.current && !cancelRef.current.contains(event.target));

            if (updateRef.current && !updateRef.current.contains(event.target) && cancelRefElementExist) {
                setHidden(true);
            }
        };

        hidden === false && document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return (
        <>
            <img src={MoreMenu} alt={MoreMenu} onClick={handleClick} style={{ cursor: "pointer" }} />

            {!hidden &&
            <div style={{ position: "absolute", textAlign: "left" }}>
                <div style={{ width: "220px", border: "1px solid #E9EBEF", borderRadius: "8px", padding: "1em", fontSize: "14px", fontWeight: "500", backgroundColor: "#FFF", position: "absolute", top: "-2px", right: "20px" }}>
                    <div ref={updateRef} style={{ paddingBottom: "0.5em", cursor: "pointer" }} onClick={handleUpdatePaymentMethod}>Update Payment Method</div>
                    {isActive && <div ref={cancelRef} style={{ paddingTop: "0.5em", borderTop: "1px solid #E9EBEF", cursor: "pointer" }} onClick={handleCancelSubscription}>Cancel Subscription</div> }
                    {(isCanceled) && <div ref={cancelRef} style={{ paddingTop: "0.5em", borderTop: "1px solid #E9EBEF", cursor: "pointer" }} onClick={handleReactivationSubscription}>Reactivate Subscription</div> }
                    {(isExpired) && <div ref={cancelRef} style={{ paddingTop: "0.5em", borderTop: "1px solid #E9EBEF", cursor: "pointer" }} onClick={handleRenewalSubscription}>Renew Subscription</div> }
                </div>
            </div>
            }
        </>
    );
}

export default MoreMenuContainerSubscription;