import React from "react";
import styled from "styled-components";
import { mobile, tablet, desktop } from "./Media";

const StyledContainer = styled.div`
    display: ${ p => p.display || "flex" };
    height: ${p => p.height};
    align-items: ${p => p.alignItems };
    justify-content: ${p => p.justifyContent};
    margin: ${p => p.margin};
    margin-left: ${p => p.marginLeft};
    margin-right: ${p => p.marginRight};
    margin-top: ${p => p.marginTop};
    margin-bottom: ${p => p.marginBottom};
    padding: ${p => p.padding};
    box-sizing: border-box;
    position: ${p => p.position};
    background-color: ${p => p.color};
    top: ${p => p.top};
    bottom: ${p => p.bottom};
    right: ${p => p.right};
    left: ${p => p.left};
    background-image: ${p => p.image};
    overflow-x: ${p => p.overflowX};
    flex-grow: ${p => p.flexGrow};
    min-width: ${p => p.minWidth};
    min-height: ${p => p.minHeight};

    max-height: ${p => p.maxHeight};
    overflow-y: ${p => p.overflowY};
    border: ${p => p.border};
    font-family: ${p => p.family};
    flex-wrap: ${p => p.wrap};
    
    ${mobile`
        width: ${p => p.mWidth || "100%"};
        flex-direction: ${p => p.mDirection || "column"};
    `};

    ${tablet`
        width: ${p => p.tWidth};
        flex-direction: ${p => p.tDirection || "column"};
    `};

    ${desktop`
        width: ${p => p.width};
        flex-direction: ${p => p.direction || "column"};
        max-width: ${p => p.maxWidth};
    `};
`;

function FlexContainer(props) {
    const { children, ...styleProps } = props;
    return (
        <StyledContainer {...styleProps}>
            {children}
        </StyledContainer>
    );
}

export default FlexContainer;