import React from "react";
import styled from "styled-components";
import {
    Text, Button, FormField
} from "src/components";

import CorrectIcon from "src/img/survey/correct.png";
import WrongIcon from "src/img/survey/wrong.png";

import IconRating from "src/img/survey/type_star.png";
import IconSatisfactionBad from "src/img/survey/type_bad.png";
import IconSatisfactionPoor from "src/img/survey/type_poor.png";
import IconSatisfactionOk from "src/img/survey/type_ok.png";
import IconSatisfactionGreat from "src/img/survey/type_great.png";
import IconSatisfactionExcellent from "src/img/survey/type_excellent.png";
import IconYes from "src/img/survey/type_yes.png";
import IconNo from "src/img/survey/type_no.png";
import IconFeedBack from "src/img/survey/type_comment.png";

import IconRatingSelected from "src/img/survey/type_star_Selected.png";
import IconSatisfactionBadSelected from "src/img/survey/type_bad_Selected.png";
import IconSatisfactionPoorSelected from "src/img/survey/type_poor_Selected.png";
import IconSatisfactionOkSelected from "src/img/survey/type_ok_Selected.png";
import IconSatisfactionGreatSelected from "src/img/survey/type_great_Selected.png";
import IconSatisfactionExcellentSelected from "src/img/survey/type_excellent_Selected.png";
import IconYesSelected from "src/img/survey/type_yes_Selected.png";
import IconNoSelected from "src/img/survey/type_no_Selected.png";
import IconFeedBackSelected from "src/img/survey/type_comment_Selected.png";

/*Survey Insights */
import InsightIconRating from "src/img/survey/insight_star.png";
import InsightIconSatisfactionBad from "src/img/survey/insight_bad.png";
import InsightIconSatisfactionPoor from "src/img/survey/insight_poor.png";
import InsightIconSatisfactionOk from "src/img/survey/insight_ok.png";
import InsightIconSatisfactionGreat from "src/img/survey/insight_great.png";
import InsightIconSatisfactionExcellent from "src/img/survey/insight_excellent.png";
import InsightIconYes from "src/img/survey/insight_yes.png";
import InsightIconNo from "src/img/survey/insight_no.png";

import InsightIconRatingSelected from "src/img/survey/insight_star_selected.png";
import InsightIconSatisfactionBadSelected from "src/img/survey/insight_bad_selected.png";
import InsightIconSatisfactionPoorSelected from "src/img/survey/insight_poor_selected.png";
import InsightIconSatisfactionOkSelected from "src/img/survey/insight_ok_selected.png";
import InsightIconSatisfactionGreatSelected from "src/img/survey/insight_great_selected.png";
import InsightIconSatisfactionExcellentSelected from "src/img/survey/insight_excellent_selected.png";
import InsightIconYesSelected from "src/img/survey/insight_yes_selected.png";
import InsightIconNoSelected from "src/img/survey/insight_no_selected.png";

import AddIcon from "src/img/survey/add.png";
import RemoveIcon from "src/img/survey/close.png";

export const NextStepButton = styled(Button)`
    background: #51315D;
    color: #ffffff;
`;

export const CancelButton = styled(Button)`
    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;
`;

export const GridSurveyTypeContainer = styled.div``;
export const GridSurveyTypeChildStart = styled.div`
justify-content: start;
`;
export const GridSurveyTypeChildEnd = styled.div`
justify-content: end;
`;

export const GridUserGroupContainer = styled.div``;
export const GridUserGroupChild = styled.div`
justify-content: center;
`;

/*Back and Next Step Container */
export const GridContainer = styled.div`
`;

export const GridChild = styled.div`
margin: ${ p => p.margin || "0 10px 0 10px"};
`;

export const StyledButton = styled(Button)`
    color: ${ p => p.color || "white"};
    background-color: ${ p => p.backgroundColor || "#51315D"};
    border: ${ p => p.border || "none"};
`;

export const ImageGroupRating = (({ isSelected }) => {
    if (isSelected)
    {
        return (
            <>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected1"/>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected2"/>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected3"/>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected4"/>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected5"/>
            </>
        );
    }
    else
    {
        return (
            <>
                <img className="icon-surveType" src={IconRating} alt="IconRating1"/>
                <img className="icon-surveType" src={IconRating} alt="IconRating2"/>
                <img className="icon-surveType" src={IconRating} alt="IconRating3"/>
                <img className="icon-surveType" src={IconRating} alt="IconRating4"/>
                <img className="icon-surveType" src={IconRating} alt="IconRating5"/>
            </>
        );
    }
});

export const ImageGroupSatisfaction = (({ isSelected }) => {
    if (isSelected)
    {
        return (
            <>
                <img className="icon-surveType" src={IconSatisfactionBadSelected} alt="IconSatisfactionBadSelected"/>
                <img className="icon-surveType" src={IconSatisfactionPoorSelected} alt="IconSatisfactionPoorSelected"/>
                <img className="icon-surveType" src={IconSatisfactionOkSelected} alt="IconSatisfactionOkSelected"/>
                <img className="icon-surveType" src={IconSatisfactionGreatSelected} alt="IconSatisfactionGreatSelected"/>
                <img className="icon-surveType" src={IconSatisfactionExcellentSelected} alt="IconSatisfactionExcellentSelected"/>
            </>
        );
    }
    else
    {
        return (
            <>
                <img className="icon-surveType" src={IconSatisfactionBad} alt="IconSatisfactionBad"/>
                <img className="icon-surveType" src={IconSatisfactionPoor} alt="IconSatisfactionPoor"/>
                <img className="icon-surveType" src={IconSatisfactionOk} alt="IconSatisfactionOk"/>
                <img className="icon-surveType" src={IconSatisfactionGreat} alt="IconSatisfactionGreat"/>
                <img className="icon-surveType" src={IconSatisfactionExcellent} alt="IconSatisfactionExcellent"/>
            </>
        );
    }
});

export const ImageGroupYesNo = (({ isSelected }) => {
    if (isSelected)
    {
        return (
            <>
                <img className="icon-surveType" src={IconYesSelected} alt="IconYesSelected"/>
                <img className="icon-surveType" src={IconNoSelected} alt="IconNoSelected"/>
            </>
        );
    }
    else
    {
        return (
            <>
                <img className="icon-surveType" src={IconYes} alt="IconYes"/>
                <img className="icon-surveType" src={IconNo} alt="IconNo"/>
            </>
        );
    }
});

export const ImageGroupFeedback = (({ isSelected }) => {
    if (isSelected)
    {
        return (
            <>
                <img className="icon-surveType" src={IconFeedBackSelected} alt="IconFeedBackSelected"/>
            </>
        );
    }
    else
    {
        return (
            <>
                <img className="icon-surveType" src={IconFeedBack} alt="IconFeedBack"/>
            </>
        );
    }
});

const CorrectIconContainer = styled.div`
    position: relative;
    left: ${ p => p.left || "60%"};
    top: ${ p => p.top || "-35px"};
    display: ${ p => p.display || "none"};
`;

export const CorrectImage = (({ display }) => {
    return (
        <>
            <CorrectIconContainer display={display}><img className="icon-correct" src={CorrectIcon} alt="CorrectIcon"/></CorrectIconContainer>
        </>
    );
});

export const WrongImage = (({ display }) => {
    return (
        <>
            <CorrectIconContainer display={display}><img className="icon-correct" src={WrongIcon} alt="WrongIcon"/></CorrectIconContainer>
        </>
    );
});

export const CorrectImageSurveyType = (({ display }) => {
    return (
        <>
            <CorrectIconContainer display={display} left="170%" top="-37px"><img className="icon-correct" src={CorrectIcon} alt="CorrectIcon"/></CorrectIconContainer>
        </>
    );
});

/* Question Page */

export const RemoveQuestionIconContainer = (({ display, removeQuestion, questionElement }) => {
    return (
        <>
            <CorrectIconContainer display={display} left="100%" top="-74px"><img className="icon-remove" src={RemoveIcon} alt="RemoveIcon" onClick={(event) => {removeQuestion(questionElement);}} /></CorrectIconContainer>
        </>
    );
});

export const QuestionContainer = (({ questionNumber, inputQuestion, questionValue, surveyQuestionError, removeQuestion }) => {
    return (
        <>
            <Text height="100px">
                <Text color="#000" align="center" margin="10px 0px" size="16px" weight="bolder">
                    Question {questionNumber}
                </Text>
                <FormField borderRadius="26px" size="14px" padding="10px 20px" height="45px"
                    placeholderColor="#000000" border="1px solid #2D3037"
                    width="100%"
                    type="text"
                    placeholder="Enter your question here..."
                    onChange={(event) => {inputQuestion(questionNumber, event);} }
                    errors={surveyQuestionError}
                    value={questionValue}
                    fontWeight="500"
                    showErrors={true}
                />  
                
            </Text>
        </>
    );
});

export const AddAnotherQuestionContainer = ({ addQuestion }) => {
    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <Text color="#000" align="center" margin="10px 0px" size="16px" weight="bolder">
                    <img className="add-question" src={AddIcon} alt="AddIcon" height="46px" onClick={(event) => {addQuestion();} }/>
                </Text>
                <Text color="#000" align="center" margin="10px 0px" size="16px" weight="500">
                    Add Another Question
                </Text>
            </div>
        </>
    );
};

export const InputSelector = styled.select`
    font-size: ${p => p.size || "0.8rem"};
    color: ${ p => p.color || "#000"};
    font-weight: ${ p => p.fontWeight || "bolder" };
    border: ${ p => p.border || "none" };
    border-bottom: ${ p => p.borderBottom };
    margin: ${ p => p.margin || "5px 0" };
    box-sizing: border-box;
    
    overflow: ${p => p.hiddenOverFlow && "hidden"};
    ::placeholder {
        opacity: ${ p => p.opacity || "initial" };
        color: ${ p => p.placeholderColor || "#6A1E00" };
        font-weight: 400;
    };
    
`;

export const EditQuestionContainer = (({ questionNumber, inputQuestion, questionValue, surveyQuestionError, removeQuestion }) => {
    return (
        <>
            <Text height="63px" className="EditQuestionContainer" align="left">
                <span>{questionNumber}. </span> <FormField borderRadius="26px" size="14px" margin="5px 0px 5px 5px" padding="10px 20px" height="45px" style={{ display: "inline-block" }}
                    placeholderColor="#000000" border="1px solid #2D3037"
                    type="text"
                    placeholder="Enter your question here..."
                    onChange={(event) => {inputQuestion(questionNumber - 1, event);} }
                    errors={surveyQuestionError}
                    value={questionValue}
                />  
            </Text>
        </>
    );
});

/* Page Insight */

export const ImageGroupRatingInsight = (({ isSelected, score1 = 0, score2 = 0, score3 = 0, score4 = 0, score5 = 0 }) => {
    return (
        <>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score5" && score5 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating5"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score5}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">5 Stars</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score4" && score4 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating4"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score4}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">4 Stars</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score3" && score3 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating3"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score3}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">3 Stars</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score2" && score2 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating2"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score2}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">2 Stars</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score1" && score1 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating1"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score1}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">1 Stars</Text>
                </GridChild>
            </GridContainer>
        </>
    );
});

export const ImageGroupSatisfactionInsight = (({ isSelected, bad = 0, poor = 0, ok = 0, great = 0, excellent = 0 }) => {
    return (
        <>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "excellent" && excellent > 0 ? InsightIconSatisfactionExcellentSelected : InsightIconSatisfactionExcellent} alt="InsightIconSatisfactionExcellent"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{excellent}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Excellent</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "great" && great > 0 ? InsightIconSatisfactionGreatSelected : InsightIconSatisfactionGreat} alt="InsightIconSatisfactionGreat"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{great}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Great</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "ok" && ok > 0 ? InsightIconSatisfactionOkSelected : InsightIconSatisfactionOk} alt="InsightIconSatisfactionOk"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{ok}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Ok</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "poor" && poor > 0 ? InsightIconSatisfactionPoorSelected : InsightIconSatisfactionPoor} alt="InsightIconSatisfactionPoor"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{poor}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Poor</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "bad" && bad > 0 ? InsightIconSatisfactionBadSelected : InsightIconSatisfactionBad} alt="InsightIconSatisfactionBad"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{bad}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Bad</Text>
                </GridChild>
            </GridContainer>
        </>
    );
});

export const ImageGroupYesNoInsight = (({ isSelected, yes = 0, no = 0 }) => {
    return (
        <>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "yes" && yes > 0 ? InsightIconYesSelected : InsightIconYes} alt="InsightIconYes"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{yes}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Answered &rdquo;Yes&rdquo;</Text>
                </GridChild>
            </GridContainer>

            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "no" && no > 0 ? InsightIconNoSelected : InsightIconNo} alt="InsightIconNo"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{no}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Answered &rdquo;No&rdquo;</Text>
                </GridChild>
            </GridContainer>
        
        </>
    );
});
