import React, { useState, useEffect, useContext } from "react";
import { ChatContext } from "./ChatContext";
import { FlexContainer, LoadingIndicator, ErrorLabel, Toast } from "src/components";
import axios from "axios";
/**
 * fix for runtime error caused by crypto
 * see https://stackoverflow.com/questions/68707553/uncaught-referenceerror-buffer-is-not-defined
 */
import { Buffer } from "buffer";
window.Buffer = Buffer;

import { ONE_ON_ONE_CHAT, USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import EditGroup from "./components/EditGroupChatModal";
import ChatMessages from "./components/ChatMessages";
import CreateChatModal from "./components/CreateChatModal";
import DeleteConfirmation from "./components/DeleteConfirmation";
import ProfileSettingsModal from "./components/ProfileSettingsModal";
import LeadershipEmployees from "./components/LeadershipEmployees";
import ChatList from "./components/ChatList";
import { isListTypeEmployeeOrClient } from "src/utils/helpers";
import { MainContainer } from "./components/styled/shared";


import {
    useAbortController,
    useMatrixClient,
    useGetChats,
    useGetLeadershipRoles,
    useCreateChat,
    useUpdateChat,
    useDeleteChat,
    useTypingListener,
    usePresenceListener,
    useFetchUsersList
} from "./hooks";
import BackupKeyModal from "./components/children/backupkey/BackupKeyModal";
import { API_URL } from "../App";
import { BackupKeyContext } from "./BackupKeyContext";
import useTimelineListener from "./hooks/matrix/useTimelineListener";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import { MESSAGE_CLIENTS, MESSAGE_EMPLOYEES } from "src/constants/permissions";

export const BACKUP_KEY_FORMS = {
    CREATE: "CREATE",
    RESET: "RESET",
    RESTORE: "RESTORE"
};

export const BACKUP_STORAGE_KEYS = {
    CREATE_BACKUP_REFUSED: "createBackupPasswordRefused",
    USER_KEY_RESTORED: "userKeyRestored"
};

/**
 * @param {object} currentUser
 * @param {array} leadershipRoles
 * @return {string}
 */
const identifyStartingChatTypeAndLeadershipRoles = (currentUser, leadershipRoles) => {
    if (currentUser.permissions.includes(MESSAGE_EMPLOYEES)) {
        return USER_TYPE_EMPLOYEES;
    }
    if (currentUser.permissions.includes(MESSAGE_CLIENTS)) {
        return USER_TYPE_CLIENTS;
    }
    if (currentUser.leadershipRoles.length > 0) {
        return currentUser.leadershipRoles[0].uuid;
    } else {
        const lrRole = leadershipRoles.find((lr) => lr.visibleLeaderCount != 0);
        if (lrRole) {
            return lrRole.uuid;
        } else {
            return "";
        }
    }
};

const Chat = (props) => {

    const { abort } = useAbortController();
    const currentUser = useContext(UserContext);
    const { updateUserContextProperties } = useContext(UserContext);
    const headers = { headers: { Authorization: "Bearer " + currentUser.token } };
    const { 
        matrixClient, 
        matrixClientError, 
        isInitialSyncComplete, 
    } = useMatrixClient(headers);
    
    const {
        chatList,
        chatMasterList,
        didFetchChatsFail,
        currentUserUuid,
        businessBackgroundColor,
        isFetchingChats,
        isUpdatingChatlist,
        retrieveChats,
        updateChatsMasterList,
        retrieveLeadershipRoleChats,
        updateChatList,
        removeChatFromChatlist,
        setChatList,
        setIsUpdatingChatlist
    } = useGetChats(matrixClient);

    const { isTyping, typingMember, typingRoomId } = useTypingListener(matrixClient);

    const {
        leadershipRoles,
        leadershipToShow,
        isFetchingLeadership,
        retrieveLeadershipRoles,
    } = useGetLeadershipRoles();

    const [selectedChatListTypeOrLeadershipUuid, setSelectedChatListTypeOrLeadershipUuid] = useState(USER_TYPE_EMPLOYEES);
    const [selectedChatListTypeOrLeadershipUuidLabel, setSelectedChatListTypeOrLeadershipUuidLabel] = useState(USER_TYPE_EMPLOYEES);
    const [openedChat, setOpenedChat] = useState(null);
    const [chatToCreate, setChatToCreate] = useState({
        showModal: false,
        employeesToChat: [],
        chatType: "" // group or single/one-on-one
    });
    const [showEditGroupChatModal, setShowEditGroupChatModal] = useState(false);
    const [profileModalIsShown, setProfileModalIsShown] = useState(false);
    const [profileModalShowsCurrentUser, setProfileModalShowsCurrentUser] = useState(false);
    const [profileModalUser, setProfileModalUser] = useState(null);
    const [profileModalUserLeadershipRoles, setProfileModalUserLeadershipRoles] = useState(null);
    const [showBackupKeyForm, setShowBackupKeyForm] = useState(false);
    const [backupFormToShow, setBackupFormToShow] = useState("");
    const [userHasBackupKeys, setUserHasBackupKeys] = useState(false);
    const [toggleDropdown, setToggleDropdown] = useState(false);

    const { createChatHook } = useCreateChat();
    const { updateChatHook, isUpdatingChat } = useUpdateChat();
    const { deleteChat, isDeletingChat, showConfirmationModal, setShowConfirmationModal } = useDeleteChat();
    const { fetchUsersList } = useFetchUsersList();
    // to show page load only on first fetch. subsequent fetch and create should only show loading state on the ChatList component
    const [isInitialMount, setIsInitialMount] = useState(true);
    // to show LoadingIndicator on ChatList and ChatMessage when fetching, creating or updating chat
    const [showLoadingState, setShowLoadingState] = useState(false);
    const [isCurrentlyCreatingChat, setIsCurrentlyCreatingChat] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [showLeadershipEmployees, setShowLeadershipEmployees] = useState(false);

    const { 
        newEvent,
        newMatrixRoomId,
        matrixRoomIdToRemove,
        roomWithMemberChange,
        resetNewMatrixRoomId,
        resetMatrixRoomIdToRemove,
        resetRoomWithMemberChange
    } = useTimelineListener(matrixClient, isInitialSyncComplete);

    const retrieveUsersList = async (fetchUserType) => {
        const users = await fetchUsersList(fetchUserType, headers);
        if (users && users.length > 0) {
            setUsersList(users);
        }
    };

    /** this will retrieve all user's details and permissions */
    const getUserDetails = async () => {
        const currentUserData = await props.getUserDetails(currentUser.token);
        updateUserContextProperties({
            [UserKeys.FIRST_NAME]: currentUserData.firstName,
            [UserKeys.LAST_NAME]: currentUserData.lastName,
            [UserKeys.PROFILE_PICTURE_URL]: currentUserData.profilePictureUrl,
            [UserKeys.PERMISSIONS]: JSON.parse(currentUserData.roleAndPermissions.permissionIds),
            [UserKeys.LEADERSHIP_ROLES]: currentUserData.leadershipRoles,
            [UserKeys.CHAT_PRESENCE_OVERRIDE]: currentUserData.chatPresenceOverride,
            [UserKeys.PHONE_NUMBER]: currentUserData.phoneNumber,
            [UserKeys.COUNTRY_CODE]: currentUserData.countryCode,
        });
    };


    const { onlineUsersMatrixId } = usePresenceListener(matrixClient, isInitialSyncComplete, headers, currentUser, retrieveUsersList, selectedChatListTypeOrLeadershipUuid, didFetchChatsFail);

    useEffect(() => {
        /**
         * used to change the background color of <LoggedInRightContainer>
         * when rendering the Chat component. revert back to original background color upon unmount */
        props.isChatRendered(true);
        getUserDetails();
        retrieveLeadershipRoles();
        return () => {
            props.isChatRendered(false);
        };
    }, []);

    useEffect(() => {
        if (isInitialSyncComplete && matrixClient) {
            const fetchData = async () => {
                await fetchChats(selectedChatListTypeOrLeadershipUuid);
                const ifBackupKeysExist = await checkIfUserHasExistingBackupKeys();
                const status = checkIfUserAlreadyRefusedToCreateBackupPassword();
                const ifLocalStorageHasRestoredUserKey = localStorage.getItem(BACKUP_STORAGE_KEYS.USER_KEY_RESTORED);
                if (!status && !ifBackupKeysExist) {
                    setShowBackupKeyForm(true);
                    setBackupFormToShow(BACKUP_KEY_FORMS.CREATE);
                }
                if (!status && ifBackupKeysExist && !ifLocalStorageHasRestoredUserKey) {
                    setShowBackupKeyForm(true);
                    setBackupFormToShow(BACKUP_KEY_FORMS.RESTORE);
                }
                setIsInitialMount(false);
            };
            fetchData();
        }

        return abort();
    }, [isInitialSyncComplete, matrixClient]);

    useEffect(() => {
        if (!isInitialMount && isUpdatingChat && !showLoadingState) {
            setShowLoadingState(true);
        }
        else {
            setShowLoadingState(false);
        }
    }, [isUpdatingChat]);

    useEffect(() => {
        setOpenedChat(null);
    }, [selectedChatListTypeOrLeadershipUuid]);

    useEffect(() => {
        const handleChatMemberChange = async () => {
            try {
                const index = chatList.findIndex(chat => chat.matrixRoomId === roomWithMemberChange);
                if (index === -1) return;
                const response = await axios.get(`${API_URL}/chats/chatByMatrixRoomId/${roomWithMemberChange}`, headers);
                const newChatList = [...chatList];
                newChatList[index].users = response.data.chat.users;
                updateChatList(newChatList);
            } catch (error) {
                Toast.error("Unable to update chat members, will retrieve chats.");
                fetchChats(selectedChatListTypeOrLeadershipUuid);
            } finally {
                resetRoomWithMemberChange();
            }
        };

        if (roomWithMemberChange && isInitialSyncComplete) {
            handleChatMemberChange();
        }
    }, [roomWithMemberChange]);

    useEffect(() => {
        if (chatList.length === 0) {
            return;
        }

        const openedChatNotInList = (openedChat === null || (chatList.find((chat) => chat.uuid === openedChat.uuid) === undefined));
        if (openedChatNotInList && chatList.length === 1) {
            handleOpenChat(chatList[0]);
        }
    }, [chatList]);

    useEffect(() => {
        if (leadershipRoles.length > 0) {
            setSelectedChatListTypeOrLeadershipUuid(identifyStartingChatTypeAndLeadershipRoles(currentUser, leadershipRoles));
        }
    }, [leadershipRoles]);

    const handleChatListTypeOrLeadershipUuidChange = (chatListTypeToShow, chatListTypeToShowLabel) => {
        setSelectedChatListTypeOrLeadershipUuid(chatListTypeToShow);
        setSelectedChatListTypeOrLeadershipUuidLabel(chatListTypeToShowLabel);
        setIsUpdatingChatlist(true);
        fetchChats(chatListTypeToShow);
    };

    const fetchChats = async (chatListTypeToShow) => {
        resetNewMatrixRoomId();
        /** to prevent crashing once passed chatListTypeToShow variable incidentally becomes blank/undefined */
        if (chatListTypeToShow === undefined) {
            return Toast.error("No selected chat type to fetch.");
        }
        return isListTypeEmployeeOrClient(chatListTypeToShow) ?
            await retrieveChats(chatListTypeToShow) :
            await retrieveLeadershipRoleChats(chatListTypeToShow);
    };

    const createChatWithLeadership = async (formData) => {
        try {
            setChatToCreate({ ...chatToCreate, chatType: ONE_ON_ONE_CHAT });
            const created = await createChatHook(formData, chatToCreate, headers);
            handleCreateChatResponse(created, true);
        } catch (error) {
            Toast.error(error.message || "Unable to create a new chat with a leadership employee.");
        }
    };

    const handleOpenChat = (chat) => setOpenedChat(chat);

    const createChat = async (formData) => {
        closeCreateChatModal();
        const created = await createChatHook(formData, chatToCreate, headers);
        if (created) {
            handleCreateChatResponse(created, false);
        } else {
            setIsCurrentlyCreatingChat(false);
            Toast.error("Unable to create a new chat.");
        }
    };

    const updateGroupChat = async (formData) => {
        setShowEditGroupChatModal(false);
        try {
            const updated = await updateChatHook(openedChat.uuid, formData, headers);
            handleUpdateChatResponse(updated);
        } catch (error) {
            Toast.error(error.message || "Unable to update chat.");
        }
    };

    const handleUpdateChatResponse = (response) => {
        const index = chatList.findIndex(chat => chat.uuid === response.chat.uuid);
        if (index !== -1) {
            chatList[index].users = response.chat.users;
            chatList[index].name = response.chat.name;
            handleOpenChat(response.chat);
            return;
        }

        Toast.error("Failed to locate updated chat. Will retrieve list.");
        retrieveChats(selectedChatListTypeOrLeadershipUuid);
    };

    const insertNewlyCreatedChat = async (chat, chatList) => {
        let updatedChatList = [...chatList];
        if (updatedChatList.length === 0) {
            updatedChatList = [chat];
        } else {
            updatedChatList.unshift(chat);
        }

        const index = updatedChatList.findIndex((chatItem) => chatItem.uuid === chat.uuid);

        if (index !== -1) {
            chat.latestEvent = updatedChatList[index].latestEvent;
            updatedChatList[index] = chat;
            handleOpenChat(chat);
        } else {
            if (chatList.length > 0) {
                handleOpenChat(updatedChatList[0]);
            } else {
                handleOpenChat(null);
            }
        }
        setChatList(updatedChatList);
    };

    const handleCreateChatResponse = (response, isLeadershipChat) => {
        let newlyCreatedChat = response.chat;

        const chatBelongsInList = (chat, listType) => {
            if (chat.leadershipRoleUuid) {
                return chat.leadershipRoleUuid === listType;
            }
            const chatType = chat.isClient ? USER_TYPE_CLIENTS : USER_TYPE_EMPLOYEES;
            return chatType === listType;
        };

        if (response.isNew) {
            newlyCreatedChat = { ...newlyCreatedChat, message: "No messages yet" };
            /**
             * validate if the selectedChatListTypeOrLeadershipUuid is correct 
             * in order to prevent any unintended insertion for the active chat's list of chats
            */ 
            if (chatBelongsInList(newlyCreatedChat, selectedChatListTypeOrLeadershipUuid)) {
                insertNewlyCreatedChat(newlyCreatedChat, chatList);
                handleOpenChat(newlyCreatedChat);
            }
        }

        if (!response.isNew && chatBelongsInList(newlyCreatedChat, selectedChatListTypeOrLeadershipUuid)) {
            newlyCreatedChat = { ...newlyCreatedChat, message: "No messages yet" };
            insertNewlyCreatedChat(newlyCreatedChat, chatList);
        }

        setIsCurrentlyCreatingChat(false);
    };

    const handleChatDeletion = async () => {
        setToggleDropdown(false);
        const response = await deleteChat(openedChat.uuid, headers);
        if (response.status === 200) {
            removeChatFromChatlist(openedChat.uuid);
        }
    };

    const checkIfUserHasExistingBackupKeys = async () => {
        const url = `${API_URL}/_matrix/client/v3/room_keys/version`;
        try {
            const response = await axios.get(url, headers);
            if (response.data.auth_data) {
                setUserHasBackupKeys(true);
                return true;
            }
        } catch (error) {
            // these means that logged in user is most likely a new user that is yet to create back up keys
            setUserHasBackupKeys(false);
            setBackupFormToShow(BACKUP_KEY_FORMS.CREATE);
            return false;
        }
    };

    const checkIfUserAlreadyRefusedToCreateBackupPassword = () => {
        return localStorage.getItem(BACKUP_STORAGE_KEYS.CREATE_BACKUP_REFUSED);
    };

    const showCreateChatModalOrShowLeadershipEmployees = (chatType, selectedChatListTypeOrLeadershipUuid) => {
        const isSelectedChatTypeDefaultChatType = selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES || selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS;
        const isUserLeaderInSelectedLeadershipRole = currentUser.leadershipRoles.some((item) => item.uuid === selectedChatListTypeOrLeadershipUuid);
        if (!isSelectedChatTypeDefaultChatType && chatType === ONE_ON_ONE_CHAT && !isUserLeaderInSelectedLeadershipRole) {
            // shows the new leadership chat list instead of messages
            if (openedChat !== null) {
                setShowLeadershipEmployees(!showLeadershipEmployees);
                setOpenedChat(null);
            }
        } else {
            setChatToCreate({
                ...chatToCreate,
                chatType: chatType,
                showModal: true
            });
        }
    };

    const closeCreateChatModal = () => {
        setChatToCreate({
            showModal: false,
            employeesToChat: [],
            chatType: ""
        });
    };

    const displayOtherUserProfileModal = () => {
        if (openedChat.isGroupChat) {
            return Toast.error("This feature is not available on group chats.");
        }
        const otherChatUser = openedChat.users.filter(user => user.employeeUuid !== currentUserUuid);
        displayProfileModal(otherChatUser[0].employeeDetails, otherChatUser[0].leadershipRoles, false);
    };

    /**
     * @param {object} userToShow
     * @param {array} userLeadershipRoles
     * @param {boolean} isCurrentUser
     */
    const displayProfileModal = (userToShow, userLeadershipRoles, isCurrentUser) => {
        setProfileModalUser(userToShow);
        setProfileModalUserLeadershipRoles(userLeadershipRoles);
        setProfileModalIsShown(true);
        setProfileModalShowsCurrentUser(isCurrentUser);
    };

    const closeProfileModal = () => {
        setProfileModalIsShown(false);
    };

    const handleShowBackupKeyModal = () => {
        const form = userHasBackupKeys ? BACKUP_KEY_FORMS.RESTORE : BACKUP_KEY_FORMS.CREATE;
        setBackupFormToShow(form);
        setShowBackupKeyForm(true);
    };


    const handleShowEditGroupChatModal = () => setShowEditGroupChatModal(true);

    const handleCloseBackupKeyForm = () => {
        if (!userHasBackupKeys && backupFormToShow === BACKUP_KEY_FORMS.CREATE) {
            localStorage.setItem(BACKUP_STORAGE_KEYS.CREATE_BACKUP_REFUSED, 1);
        }
        setShowBackupKeyForm(false);
    };

    const handleSettingCurrentUserStatus = (status, currentUserState) => {
        const currUser = currentUserState;
        props.updateUserPresenceState(status);
        if (currUser.chatPresenceOverride) {
            currentUser.updateUserContextProperties({
                [UserKeys.CHAT_PRESENCE_OVERRIDE]: status
            });
        }
    };

    if (isFetchingChats && isInitialMount) {
        return <LoadingIndicator />;
    }
    return (
        <React.Fragment>
            <FlexContainer direction="row" height="100%">
                {didFetchChatsFail ?
                    <FlexContainer marginTop="3rem" justifyContent="center" alignItems="center" width="100%" height="100%">
                        <ErrorLabel>Something went wrong!</ErrorLabel>
                    </FlexContainer>
                    :
                    <React.Fragment>
                        <ChatContext.Provider value={{
                            showLoadingState: showLoadingState,
                            currentUserEmployeeUuid: currentUserUuid,
                            currentChat: openedChat,
                            handleOpenChat: handleOpenChat,
                            showCreateChatModalOrShowLeadershipEmployees: showCreateChatModalOrShowLeadershipEmployees,
                            chatToCreate: chatToCreate,
                            selectedChatListTypeOrLeadershipUuid: selectedChatListTypeOrLeadershipUuid,
                            setSelectedChatListTypeOrLeadershipUuid: setSelectedChatListTypeOrLeadershipUuid,
                            handleChatListTypeOrLeadershipUuidChange: handleChatListTypeOrLeadershipUuidChange,
                            refreshChatList: updateChatsMasterList,
                            displayProfileModal: displayProfileModal,
                            handleShowBackupKeyModal: handleShowBackupKeyModal,
                            leadershipRoles: leadershipRoles,
                            leadershipToShow: leadershipToShow,
                            matrixClient: matrixClient,
                            isTyping: isTyping,
                            typingMember: typingMember,
                            typingRoomId: typingRoomId,
                            // passing the new event here to trigger a re-render on the ChatMessages' roomTimeline.
                            // since this component does not use `useFetchRoomTimeline`.
                            newTimelineEvent: newEvent,
                            onlineUsersMatrixId: onlineUsersMatrixId,
                            handleSettingCurrentUserStatus: handleSettingCurrentUserStatus,
                            usersList: usersList,
                            showLeadershipEmployees: showLeadershipEmployees,
                            userChatsList: chatList,
                            userChatsMasterList: chatMasterList,
                            setChatList: setChatList,
                            isUpdatingChatlist: isUpdatingChatlist,
                            setIsUpdatingChatlist: setIsUpdatingChatlist,
                            fetchChats: fetchChats
                        }}>
                            {(isInitialSyncComplete && !matrixClientError) &&
                                <MainContainer>
                                    <ChatList businessBackgroundColor={businessBackgroundColor}
                                        matrixRoomIdToRemove={matrixRoomIdToRemove}
                                        newMatrixRoomId={newMatrixRoomId}
                                        resetMatrixRoomIdToRemove={resetMatrixRoomIdToRemove}
                                        isCurrentlyCreatingChat={isCurrentlyCreatingChat}
                                    />
                                    { isFetchingLeadership || isCurrentlyCreatingChat ? <LoadingIndicator /> :
                                        !isListTypeEmployeeOrClient(selectedChatListTypeOrLeadershipUuid) && openedChat === null ?
                                            <LeadershipEmployees
                                                createChatWithLeadership={createChatWithLeadership}
                                                selectedChatListTypeOrLeadershipUuidLabel={selectedChatListTypeOrLeadershipUuidLabel}
                                                isChatListEmpty={chatList.length === 0}
                                            /> 
                                            :
                                            <ChatMessages showEditGroupChatModal={handleShowEditGroupChatModal}
                                                deleteChat={() => setShowConfirmationModal(true)}
                                                hasChats={chatList.length > 0}
                                                toggleDropdown={toggleDropdown}
                                                setToggleDropdown={setToggleDropdown}
                                                showUserProfile={displayOtherUserProfileModal}
                                                handleChatDeletion={handleChatDeletion}
                                            />
                                    }
                                </MainContainer>
                            }

                            {chatToCreate.showModal &&
                                <CreateChatModal showDialog={chatToCreate.showModal}
                                    handleClose={() => closeCreateChatModal()}
                                    createChat={(data) => createChat(data)}
                                    setIsCurrentlyCreatingChat={setIsCurrentlyCreatingChat}
                                />
                            }
                            {showEditGroupChatModal &&
                                <EditGroup showDialog={showEditGroupChatModal}
                                    handleClose={() => setShowEditGroupChatModal(false)}
                                    updateGroupChat={updateGroupChat}
                                    deleteChat={() => setShowConfirmationModal(true)}
                                />
                            }
                            {profileModalIsShown &&
                                <ProfileSettingsModal
                                    handleClose={closeProfileModal}
                                    isCurrentUser={profileModalShowsCurrentUser}
                                    userToShow={profileModalUser}
                                    profileModalUserLeadershipRoles={profileModalUserLeadershipRoles}
                                />
                            }
                            {showConfirmationModal &&
                                <DeleteConfirmation
                                    showDialog={showConfirmationModal}
                                    handleClose={() => setShowConfirmationModal(false)}
                                    confirm={handleChatDeletion}
                                    isDeletingChat={isDeletingChat}
                                />
                            }
                            { showBackupKeyForm && backupFormToShow &&
                                <BackupKeyContext.Provider value={{
                                    backupFormToShow: backupFormToShow,
                                    updateBackupKeyFormToShow: (data) => setBackupFormToShow(data),
                                    handleClose: handleCloseBackupKeyForm
                                }}>
                                    <BackupKeyModal showDialog={showBackupKeyForm} />
                                </BackupKeyContext.Provider>
                            }
                        </ChatContext.Provider>
                    </React.Fragment>
                }
            </FlexContainer>
        </React.Fragment>
    );
};

export default Chat;