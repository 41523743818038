import { useState } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { Toast } from "src/components";

/**
 * custom hook for getting a list of active users to chat
 * @returns {{
 *      fetchUsersList: Function
 *      isFetchingUsersList: boolean
 *      fetchUpdatedUsersList: Function
 * }}
 */
export const useFetchUsersList = () => {
    const [isFetchingUsersList, setIsFetchingUsersList] = useState(false);

    const fetchUsersList = async (selectedChatListTypeOrLeadershipUuid, headers) => {
        setIsFetchingUsersList(true);
        try {
            const path = getPath(selectedChatListTypeOrLeadershipUuid);
            const response = await axios.get(`${API_URL}/chats/users/${path}`, headers);
            return response.data.users;
        } catch (error) {
            return Toast.error("Failed to retrieve list of users.");
        } finally {
            setIsFetchingUsersList(false);
        }
    };

    const fetchUpdatedUsersList = async (selectedChatListTypeOrLeadershipUuid, headers) => {
        try {
            const path = getPath(selectedChatListTypeOrLeadershipUuid);
            const response = await axios.get(`${API_URL}/chats/users/${path}`, headers);
            return response.data.users;
        } catch (error) {
            return Toast.error("Failed to retrieve list of users.");
        }
    };

    return {
        isFetchingUsersList,
        fetchUsersList,
        fetchUpdatedUsersList
    };
};

const getPath = (selectedChatListTypeOrLeadershipUuid) => {
    return selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS ? "clientsList"
        : selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES ? "employeesList" : selectedChatListTypeOrLeadershipUuid;
};