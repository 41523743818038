import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import styled from "styled-components";
import {
    LoadingIndicator, ErrorText, Text, Form, Button, FormField
} from "src/components";

import ClientLicenseModal from "../components/ClientLicenseModal";
import InviteSentModal from "../components/InviteSentModal";
import InviteSent from "../components/InviteSent";
import AppInviteMethod from "src/components/AppInviteMethod";

const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns: 47% 47%;
    grid-template-rows: auto;
    column-gap: 6%;
    height: 12em;
`;

const ActionButtonContainer = styled.div`
    margin: 2rem 0px;
`;

const ActionButton = styled(Button)`
    color: ${ p => (p.color) || "#000" };
    background-color: ${ p => (p.backgroundColor) || "#fff" };
    margin: 5px 5px;
    font-size:14px;
    border: ${ p => (p.border) || "1px solid #000" };
    padding: ${ p => (p.padding) || "10px 45px 10px !important" };
`;

const LicenseElementDiv = ({ label, value }) => {
    return (
        <React.Fragment>
            <Text color="#000" align="left" size="1rem" weight="bolder" margin="0.625rem 0">
                {label}
            </Text>
            <FormField 
                border="1px solid #2D3037"
                padding="0.625rem 1.25rem"
                textAlign="center"
                height="2.75rem"
                margin="0"
                value={value}
                onChange={() => {}}
            />
        </React.Fragment>
    );
};

class SendInviteForm extends Component {
    state = {
        licenseEmployee: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            department: "",
            licenseDuration: "",
            startDate: "",
            licenseStatus: "notStarted" //use to disable input fields and remove client button
        },
        isFormSubmitted: false,

        checkedEmailInvite: false,
        checkedSmsInvite: false,

        emailInviteInProgress: false,
        smsInviteInProgress: false,

        isEmailSentSuccessful: false,
        isSmsSentSuccessful: false,

        smsInviteCompleted: false,
        emailInviteCompleted: false,

        smsResponseMessage: "",
        emailResponseMessage: "",

        showSentModal: false,

        sendInvitationErrorString: "",
    };
    
    componentDidMount() {
        this.safeToInit();
    }

    safeToInit = () => {
        this.populateClientLicenseForm();
    };

    populateClientLicenseForm = () => {
        const { selectedLicenseEmployee } = this.props;

        this.setState(prevState => ({
            responseErrors: [],
            licenseEmployee: selectedLicenseEmployee,
            checkedEmailInvite: selectedLicenseEmployee.email ? true : false,
            checkedSmsInvite: selectedLicenseEmployee.phoneNumber ? true : false
        }));
    };

    componentDidUpdate(prevProps, prevState) {
    // This will be executed after the component is updated
    // You can perform any side effect here, like updating the DOM

        if (this.state.smsInviteCompleted !== prevState.smsInviteCompleted) {
            if (this.state.smsInviteCompleted && this.state.emailInviteCompleted)
            {
                ClientLicenseModal.close();
                this.openInviteSentModal();
            }  
        }

        if (this.state.emailInviteCompleted !== prevState.emailInviteCompleted) {
            if (this.state.smsInviteCompleted && this.state.emailInviteCompleted)
            {
                ClientLicenseModal.close();
                this.openInviteSentModal();
            }  
        }
    }

    sendSmsInvites = () => {
        if (this.state.smsInviteInProgress) {
            return;
        }

        this.setState(state => ({
            smsInviteInProgress: true,
            smsInviteCompleted: false,
        }));

        axios({
            method: "POST",
            data: {
                uuids: [this.props.selectedLicenseEmployee.employeeUuid]
            },
            url: `${API_URL}/company/clients/sendClientSmsInvitationByClient`,
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then((response) => {
            if (response.data.subject !== null && response.data.subject === "rate-limit") {
                this.setState(state => ({
                    isSmsSentSuccessful: false,
                    smsResponseMessage: "Sorry, you have already sent this user an SMS invitation today.You may only do that once per day.",
                }));
            } else {
                this.props.getCompanyClientLicense();
                this.setState(state => ({
                    isSmsSentSuccessful: true,
                    smsResponseMessage: "",
                }));
            }
        }).catch(error => {
            this.setState(state => ({
                isSmsSentSuccessful: false,
                smsResponseMessage: "* There was a problem sending the SMS invite",
            }));
        }).finally(() => {
            this.setState(state => ({
                smsInviteInProgress: false,
                smsInviteCompleted: true
            }));
        });
    };

    sendEmailInvites = () => {
        if (this.state.emailInviteInProgress) {
            return;
        }

        this.setState(state => ({
            emailInviteInProgress: true,
            emailInviteCompleted: false,
        }));

        axios({
            method: "POST",
            data: {
                uuids: [this.props.selectedLicenseEmployee.employeeUuid]
            },
            url: `${API_URL}/company/clients/addClientEmailInvitationByClient`,
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then((response) => {
            if (response.data.subject !== null && response.data.subject === "rate-limit") {
                this.setState(state => ({
                    isEmailSentSuccessful: false,
                    emailResponseMessage: "Sorry, you have already sent this user an email invitation today.You may only do that once per day.",
                }));
            } else {
                this.props.getCompanyClientLicense();
                this.setState(state => ({
                    isEmailSentSuccessful: true,
                    emailResponseMessage: "",
                }));
            }
        }).catch(error => {
            this.setState(state => ({
                isEmailSentSuccessful: false,
                emailResponseMessage: "* There was a problem sending the Email invite",
            }));
        }).finally(() => {
            this.setState(state => ({
                emailInviteInProgress: false,
                emailInviteCompleted: true
            }));
        });
    };

    submitInvitationLink = () => {
        const {
            checkedEmailInvite, checkedSmsInvite, 
        } = this.state;

        if (checkedEmailInvite === false && checkedSmsInvite === false) {
            this.setState(state => ({
                loading: false,
                sendInvitationErrorString: "* Please select at least one method for sending",
            }));

            return;
        }

        this.setState(state => ({
            isFormSubmitted: true,
            sendInvitationErrorString: "",
        }));
        
        if (checkedSmsInvite)
            this.sendSmsInvites();
        else {
            this.setState(state => ({
                smsInviteCompleted: true,
            }));
        }
        if (checkedEmailInvite)
            this.sendEmailInvites();
        else {
            this.setState(state => ({
                emailInviteCompleted: true,
            }));
        }

        /**
         * Check ComponentDidUpdate for the continuation of this method.
         * should open a successfully sent modal depends on the response.
         */
    };

    openInviteSentModal = () => {
        const { licenseEmployee, 
            checkedEmailInvite, checkedSmsInvite, 
            smsResponseMessage, emailResponseMessage, 
            isSmsSentSuccessful, isEmailSentSuccessful
        } = this.state;
        
        let headerTitle;

        if (isEmailSentSuccessful && isSmsSentSuccessful)
            headerTitle = "Invite Sent Successfully!";
        else if (isEmailSentSuccessful)
            headerTitle = "Email Invite Sent Successfully!";
        else if (isSmsSentSuccessful)
            headerTitle = "SMS Invite Sent Successfully!";
        else
            headerTitle = "Something went wrong";

        const modalHeader = <React.Fragment> {headerTitle} </React.Fragment>;
        const inviteSent = <React.Fragment> 
            <InviteSent 
                user={this.props.user} 
                licenseEmployee={licenseEmployee} 
                checkedEmailInvite={checkedEmailInvite} 
                checkedSmsInvite={checkedSmsInvite} 
                isEmailSentSuccessful={isEmailSentSuccessful} 
                isSmsSentSuccessful={isSmsSentSuccessful} 
                emailResponseMessage={emailResponseMessage}
                smsResponseMessage={smsResponseMessage}
                cancel={InviteSentModal.close} />
        </React.Fragment>;

        InviteSentModal.open(
            modalHeader,
            inviteSent,
            false
        );
    };

    cancel = () => {
        ClientLicenseModal.close();
    };

    checkEmailInvite = (isCheckEmail) => {
        this.setState({
            checkedEmailInvite: isCheckEmail
        });
    };

    checkSmsInvite = (isCheckSms) => {
        this.setState({
            checkedSmsInvite: isCheckSms
        });
    };

    handleSetAppInviteMethod = (value) => {

        const isSmsInviteFlag = value === "both" || value === "sms";
        const isEmailInviteFlag = value === "both" || value === "email";

        this.checkEmailInvite(isEmailInviteFlag);
        this.checkSmsInvite(isSmsInviteFlag);
    };

    render() {
        const { sendInvitationErrorString, responseErrors, licenseEmployee, isFormSubmitted } = this.state;

        return (
            <>
                <Form width="100%" onSubmit={(e) => {e.preventDefault(); }} >
                    <ErrorText margin="0">
                        { responseErrors ? responseErrors : null }  
                    </ErrorText>

                    <ContainerGrid>
                        <div><LicenseElementDiv label={"First Name"} value={licenseEmployee.firstName} /></div>
                        <div><LicenseElementDiv label={"Last Name"} value={licenseEmployee.lastName} /></div>
                        <div><LicenseElementDiv label={"Email"} value={licenseEmployee.email} /></div>
                        <div><LicenseElementDiv label={"Mobile"} value={licenseEmployee.phoneNumber} /></div>
                    </ContainerGrid>
                    <div>
                        <AppInviteMethod setAppInviteMethod={this.handleSetAppInviteMethod} />
                        
                        <ErrorText margin="0">
                            { sendInvitationErrorString.length > 0 ? sendInvitationErrorString : null }  
                        </ErrorText>
                    </div>    
                </Form>

                <ActionButtonContainer>
                    <hr/>
                    {
                        isFormSubmitted
                            ?
                            <div style={{ width: "100%" }}><LoadingIndicator containerHeight="3em" height="3em" width="3em" style={{ top: "6px", display: "inline-block" }} /></div>
                            :
                            <div style={{ textAlign: "right", marginTop: "1rem", marginBottom: "2rem" }}>
                                <ActionButton style={{ marginLeft: "auto" }} backgroundColor="#FFFFFF" color="#000" onClick={this.cancel}>
                                Cancel
                                </ActionButton>
                                <ActionButton color="#FFF" backgroundColor="#006CFF" border="none" onClick={this.submitInvitationLink}>
                                Submit
                                </ActionButton>
                            </div>
                    }
                </ActionButtonContainer>
            </>
        );
    }
}

export default SendInviteForm;
