import React, { Component } from "react";
import { Text, FlexContainer } from "src/components";
import { LicenseRenewalContainerContext } from "src/components/LicenseRenewalContainerContext";

import CorrectGreen from "src/img/CorrectGreen.svg";

class LicenseRenewalSuccess extends Component {
    static contextType = LicenseRenewalContainerContext;

    render() {
        const { accessToken } = this.context;

        return (
            <FlexContainer style={{ margin: "auto 0" }}>
                <FlexContainer alignItems="center" padding="2rem 1rem" height="100%">
               
                    <br/>
                    <img src={CorrectGreen} alt="success" height="100px"/>
                
                    <Text color="#23262D" size="3.75rem" mSize="2rem" weight="500" width="50rem" style={{ marginBlockEnd: "0px" }}>
                        Purchase Successful!
                    </Text>

                    <Text color="black" weight="500" size="14px" width="400px" lineHeight="25px" margin="30px 0px 50px 0px">
                        We sent a copy of your purchase receipt to your email inbox. 
                        Please check your email to access your receipt 
                        and keep it handy for your records. <br/><br/>

                        You can now log back into the app using your unique code.
                    </Text>

                    <Text size="16px" color="#000" weight="bolder">
                        Your App Login Code Is:
                    </Text>

                    <FlexContainer style={{ margin: "auto", width: "210px", }}>
                        <Text margin="0 0 80px 0">
                            <div style={{ fontSize: "20px", color: "#2A2B2A", fontWeight: "700", letterSpacing: "1px", padding: "18px 0px", borderRadius: "17px", border: "1px solid #2D3037" }}>
                                {accessToken}
                            </div>
                        </Text>
                    </FlexContainer>

                </FlexContainer>
            </FlexContainer>
        ); 
    }
}

export default LicenseRenewalSuccess;