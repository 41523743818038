import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { RadioGroup } from "@trendmicro/react-radio";
import FlexContainer from "./FlexContainer";
import LoadingIndicator from "./LoadingIndicator";
import CancelButton from "src/scenes/Subscription/components/CancelButton";
import ProceedButton from "src/scenes/Subscription/components/ProceedButton";
import { RadioButton } from "@trendmicro/react-radio";
import CompanyPaymentMethods, { GenericPaymentMethod } from "src/scenes/Subscription/api/CompanyPaymentMethods";
import Toast from "./Toast";
import Text from "./Text";
import LeftText from "src/scenes/Subscription/components/LeftText";
import ImgAirwallexDesktopLogo from "src/img/airwallex@2x.png";
import Amex from "src/img/airwallex/Amex.svg";
import GenericCard from "src/img/airwallex/GenericCard.svg";
import Mastercard from "src/img/airwallex/Mastercard.svg";
import Visa from "src/img/airwallex/Visa.svg";

export const ButtonContainer = styled.div`
    display: flex;
    margin: ${p => p.margin || "64px 0"};
    justify-content: ${p => p.justifyContent || "center"};
    width: ${p => p.width};
    height: auto;
`;

export const NewRadioButton = styled(RadioButton)`
    margin: 0.4rem 0;
    font-weight: 500;
  .radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover {
    border: 1px solid #2D3037;
  }

  .radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover, >
  .radio---input-radio---2yM_b:checked {
    border: 1px solid #2D3037;
    background-color: #2D3037;
  }

  .radio---input-radio---2yM_b:checked + .radio---control-indicator---dcwXW::before {
    border: 1px solid #2D3037;
    background-color: #2D3037;
  }
`;

const CardDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CardLogo = styled.img`
  height: 1.5rem;
  margin-left: .5rem;
`;

function CardList(props) {
    const [ isLoadingPaymentMethods, setIsLoadingPaymentMethods ] = useState(false);
    const [ isProcessingPayment, setIsProcessingPayment ] = useState(false);
    const [ paymentMethods, setPaymentMethods ] = useState(GenericPaymentMethod.default());
    const [ selectedMethod, setSelectedMethod ] = useState("");

    /** @type string|null */
    const loadingError = props.loadingError;

    const token = props.token;
    const onBackClicked = props.onBackClicked;
    const onPurchaseClicked = props.onPurchaseClicked;
    const onDeleteClicked = props.onDeleteClicked;

    const findMethodSource = methodId => {
        const foundMethod = paymentMethods.find(method => method.id === methodId);
        return foundMethod ? foundMethod.source : "";
    };

    const thisPurchaseClicked = async (selectedMethod) => {
        setIsProcessingPayment(true);
        await onPurchaseClicked(selectedMethod, findMethodSource(selectedMethod));
        setIsProcessingPayment(false);
    };

    const loadPaymentMethods = async () => {
        setIsLoadingPaymentMethods(true);
        try {
            const methods = await CompanyPaymentMethods.get(token);
            const defaultMethod = methods.findDefault();
            setPaymentMethods(methods.intoDataArray());
            if (defaultMethod) {
                setSelectedMethod(defaultMethod);
            }
        } catch (error) {
            Toast.error(error.message);
        } finally {
            setIsLoadingPaymentMethods(false);
        }
    };

    const renderCardLogo = (brand) => {
        const logoMap = {
            "american express": Amex,
            "mastercard": Mastercard,
            "visa": Visa,
        };
        
        const logo = logoMap[brand] || GenericCard;
        
        return <CardLogo src={logo} />;
    };

    useEffect(
        () => { loadPaymentMethods(); },
        [ /* No deps; only run once */ ]
    );

    return (
        <FlexContainer direction="column" justifyContent="space-between" 
            height="auto" minHeight="31rem"
        >

            {!isProcessingPayment && 
                <>
                    <FlexContainer width="100%" maxWidth="19.125rem" border="1px solid #707070" padding="10px 20px" align="left">
                        <RadioGroup name="card" value={selectedMethod}>
                            <FlexContainer direction="column">
                                { isLoadingPaymentMethods && "Getting payment method information..." }
                                { !isLoadingPaymentMethods && paymentMethods.length === 0 && "No existing payment methods. Add a new card." }
                                { loadingError && <span style={{ color: "red" }}>{loadingError}</span> }
                                { paymentMethods.map((method, index) => {
                                    return (
                                        <CardDetailsContainer key={index}>
                                            <NewRadioButton
                                                key={method.id}
                                                style={{ marginLeft: "10px" }}
                                                value={method.id}
                                                label={ "•••• •••• •••• " + method.cardLast4 }
                                                checked={ selectedMethod === method.id}
                                                onChange={() => setSelectedMethod(method.id)} 
                                            />
                                            { renderCardLogo(method.cardBrand) }
                                        </CardDetailsContainer>
                                    );
                                })}
                            </FlexContainer>
                        </RadioGroup>
                    </FlexContainer>
                </> 
            }

            <FlexContainer>
                <ButtonContainer justifyContent="space-between" alignItems="flex-end" width="400px" margin="0">
                    { onDeleteClicked && !isLoadingPaymentMethods &&
                        <ProceedButton color="#6A1E00" width="100px" left="0" onClick={ () => onDeleteClicked(selectedMethod, findMethodSource(selectedMethod)) }>
                            DELETE
                        </ProceedButton>
                    }
                
                    { isLoadingPaymentMethods || isProcessingPayment ?
                        <LoadingIndicator />
                        :
                        <ButtonContainer margin="0">
                            <CancelButton width="8.75rem" style={{ borderRadius: 7 }} onClick={() => onBackClicked()}>
                                    Back
                            </CancelButton>
                            <ProceedButton color="#5A32F5"
                                width="11.125rem"
                                style={{ boxShadow: "none", borderRadius: 7 }}
                                onClick={ () => thisPurchaseClicked(selectedMethod) }
                            >
                                    Pay
                            </ProceedButton>
                        </ButtonContainer>
                    }
        
                </ButtonContainer>
                <FlexContainer marginTop="2.89rem">
                    <Text align="left" margin="0" weight="500">
                        Hosted by Airwallex
                        <picture>
                            <img style={{ height: "0.85rem", marginLeft: "1em" }} src={ImgAirwallexDesktopLogo} alt="Airwallex Logo" />
                        </picture>
                    </Text>
                    <LeftText margin="0.625rem 0 0 0" maxWidth="24.5625rem" width="100%" color="#8E4EB4">
                        *Your payment method will be stored on your account for subscription, renewals and future payments.
                    </LeftText>
                </FlexContainer>
            </FlexContainer>
            
        </FlexContainer>
    );
}

export default CardList;
