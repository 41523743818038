import React, { Component } from "react";
import { Route } from "react-router-dom";
import { UserContext } from "src/scenes/App/UserContext";
import Toast from "./Toast";

class AuthorizedRoute extends Component {
    isUserAllowed = (allowed, permissions) => {
        if (!Array.isArray(permissions)) {
            return false;
        }
        return allowed.every((val) => permissions.includes(val));
    };

    render() {
        const { component: Component, allowed, ...rest } = this.props;
        return (
            <UserContext.Consumer>
                {user => (
                    <Route {...rest} render={props => {
                        if (user !== null && this.isUserAllowed(allowed, user.permissions || [])) {
                            return <Component {...rest} />;
                        } else {
                            Toast.error("Unauthorized access.");
                        }
                    }} />
                )}
            </UserContext.Consumer>
        );
    }
}

export default AuthorizedRoute;