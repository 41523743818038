import React, { useState, useContext, useEffect } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import {
    LoadingIndicator, FlexContainer, Toast, CardHeadingLabel, ExplainParagraphLabel, Button
} from "src/components";

import { SettingContext } from "../SettingContext";
import styled from "styled-components";
import BankDetailsForm from "./BankDetailsForm";
import BankDetailsModal from "../components/BankDetailsModal";
import RenewalCommissionTable from "../components/RenewalCommissionTable";

const EarningBankAccountButton = styled(Button)`
    padding: 10px 40px 10px !important;
    border-radius: 10px !important;
    background: #8551AE 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    cursor: pointer;
    font-weight:700;
    margin-top: 10px;
`;

const HeaderDescription = styled.div`
    font-size: 14px;
    color: #808080;
    font-weight: 400;
    margin: 1em 0;
`;

const FlexGridContainer = styled(FlexContainer)`
    grid-template-columns: 60% 40%;
    display: grid;
`;

function RenewalAndEarning() {
    const { token } = useContext(SettingContext);
    const [loading, setLoading] = useState(false);
    const [isTableLoading, setTableLoading] = useState(true); /* default is true because there is a mini second that I can see the commission table */
    const [expiredLicenses, setExpiredLicenses] = useState(false);
    const [licenseRenewals, setLicenseRenewals] = useState(false);
    const [earnings, setEarnings] = useState(null);

    useEffect(() => {
        safeToInit();
    }, []);

    const safeToInit = () => {
        getCompanyClientLicense();
        getCompanyEarnings();
    };
    
    const getCompanyClientLicense = () => {
        setLoading(true);

        axios.get(`${API_URL}/company/currentMonthLicenseRenewalStats`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            setExpiredLicenses(res.data.expiredLicenses);
            setLicenseRenewals(res.data.licenseRenewals);
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const getCompanyEarnings = () => {
        setTableLoading(true);

        axios.get(`${API_URL}/company/earningDetails`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            setEarnings(res.data.earnings);
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        }).finally(() => {
            setTableLoading(false);
        });
    };
    
    const addBankDetails = () => {
        const modalHeader = <React.Fragment>
                                Earnings Bank Account Details
            <HeaderDescription>Please kindly provide the bank account information and invoice statement details below, to enable us to deposit your earnings to the designated account and email you statements.</HeaderDescription>
        </React.Fragment>;
                            
        const addBankDetailsForm = <React.Fragment> 
            <BankDetailsForm token={token} />
        </React.Fragment>;

        return BankDetailsModal.open(
            modalHeader,
            addBankDetailsForm,
            false
        );
    };

    /** RENDER */

    const pageHeadline = () => {
        return (
            <div>
                <CardHeadingLabel>
                    Renewal & Earnings
                </CardHeadingLabel>
                <ExplainParagraphLabel>
                    Track your earnings from client trial license renewals and view statements. Manage your bank details
                    for earnings payments via the &rsquo;Earnings Bank Account&rsquo; button.
                </ExplainParagraphLabel>

                <EarningBankAccountButton backgroundColor="#006CFF" onClick={(e) => {addBankDetails();} }>
                    <span> <span style={{ backgroundColor: "#FFF", borderRadius: "8px", color: "rgba(0, 0, 0, 0.5)", padding: "0px 5px", marginRight: "4px" }}>$</span> Earning Bank Account Details</span>
                </EarningBankAccountButton>
            </div>
        );
    };

    return (
        <>
            <FlexGridContainer direction="row" margin="0 40px">
                
                {pageHeadline()}
                
                <div style={{ marginLeft: "20px" }}>
                    <div style={{ fontSize: "14px", marginBottom: "6px" }}>Previous Month: </div>

                    <FlexContainer display="flex" direction="row">
                        <div style={{ backgroundColor: "#EAEDF4", borderRadius: "12px", width: "45%", padding: "15px", marginRight: "10px" }}>
                            <div style={{ fontSize: "16px" }}>Expired Licenses</div>
                            <div style={{ fontSize: "32px" }}>{loading ? "..." : expiredLicenses}</div>
                        </div>

                        <div style={{ backgroundColor: "#EAEDF4", borderRadius: "12px", width: "45%", padding: "15px" }}>
                            <div style={{ fontSize: "16px" }}>License Renewals</div>
                            <div style={{ fontSize: "32px" }}>{loading ? "..." : licenseRenewals}</div>
                        </div>
                    </FlexContainer>
                </div>
            </FlexGridContainer>

            {isTableLoading && <div style={{ marginTop: "40px" }}><LoadingIndicator /></div>}

            {!isTableLoading && <RenewalCommissionTable earnings={earnings} token={token}/> }
        </>
    );
}

export default RenewalAndEarning;