import React, { Component } from "react";
import styled from "styled-components";
import Text from "./Text";
import CountrySelector from "./CountrySelector";
import FormField from "./FormField";

const Description = styled.div`
    font-size: 14px;
    color: #808080;
    font-weight: 400;
    text-align: left;
    margin-bottom: 10px;
`;

export const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns: 47% 47%;
    grid-template-rows: auto;
    column-gap: 6%;
`;

export const ErrorList = {
    UNKNOWN: 0,
    EMPTY_COUNTRY: 1,
    EMPTY_ACCOUNT_HOLDER_NAME: 2,
    EMPTY_BANK_NAME: 3,
    EMPTY_ACCOUNT_NUMBER: 4,
    EMPTY_SWIFT_CODE: 5,
    EMPTY_BSB_OR_IBAN: 6, 
    EMPTY_INVOICE_OR_STATEMENT_EMAIL: 7, 

    EMPTY_STREET_ADDRESS: 8, 
    EMPTY_CITY: 9, 
    EMPTY_STATE: 10, 
    EMPTY_ADDRESS_COUNTRY: 11, 
    EMPTY_POST_CODE_OR_ZIP: 12, 
    EMAIL_INVALID: 13,
    AUS_INVALID_ACCOUNT_NUMBER: 14,
    AUS_BSB_LENGTH: 15
};

export const returnErrorMessages = {
    [ErrorList.AUS_BSB_LENGTH]: "Please enter a 6-digit number for BSB.",
};

export const ErrorStrings = {
    
    [ErrorList.UNKNOWN]: "* Error unknown. Please contact support@memotivationapp.com",
    [ErrorList.EMPTY_COUNTRY]: "* Please enter a country",
    [ErrorList.EMPTY_ACCOUNT_HOLDER_NAME]: "* Please enter an account holder name",
    [ErrorList.EMPTY_BANK_NAME]: "* Please enter a bank name",
    [ErrorList.EMPTY_ACCOUNT_NUMBER]: "* Please enter an account number",
    [ErrorList.EMPTY_SWIFT_CODE]: "* Please enter a SWIFT code",
    [ErrorList.EMPTY_BSB_OR_IBAN]: "* Please enter a BSB or IBAN",
    [ErrorList.EMPTY_INVOICE_OR_STATEMENT_EMAIL]: "* Please enter an invoice or statement email",

    [ErrorList.EMPTY_STREET_ADDRESS]: "* Please enter a street",
    [ErrorList.EMPTY_CITY]: "* Please enter a city",
    [ErrorList.EMPTY_STATE]: "* Please enter a state",
    [ErrorList.EMPTY_ADDRESS_COUNTRY]: "* Please enter a country",
    [ErrorList.EMPTY_POST_CODE_OR_ZIP]: "* Please enter a postcode or zip",
    [ErrorList.EMAIL_INVALID]: "* Please enter a valid invoice or statement email",
    [ErrorList.AUS_INVALID_ACCOUNT_NUMBER]: "* Account number field accepts 7 to 12-digit number.",
    [ErrorList.AUS_BSB_LENGTH]: "* " + returnErrorMessages[ErrorList.AUS_BSB_LENGTH],
};

class BankCountry extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors } = this.props;
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_COUNTRY);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Country
                    </Text>
                
                    <CountrySelector 
                        value={bankAccountDetails.country}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "country");} }
                        errors={emptyFieldError}
                        showErrors={true}
                    />
                </div>
            </>
        );
    }
}

class AddressCountry extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors } = this.props;
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_ADDRESS_COUNTRY);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Country
                    </Text>
                
                    <CountrySelector 
                        value={bankAccountDetails.addressCountry}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "addressCountry");} }
                        errors={emptyFieldError}
                        showErrors={true}
                    />
                </div>
            </>
        );
    }
}

class AccountHolderName extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, placeholderDisabled } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_ACCOUNT_HOLDER_NAME);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Account Holder Name
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled && "Enter Account Holder Name"}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "accountHolderName");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.accountHolderName}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class BankName extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, placeholderDisabled } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_BANK_NAME);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Bank Name
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled && "Enter Bank Name"}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "bankName");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.bankName}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class AccountNumber extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, placeholderDisabled } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_ACCOUNT_NUMBER, ErrorList.AUS_INVALID_ACCOUNT_NUMBER);
        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Account Number
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled && "Enter Account Number"}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "accountNumber");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.accountNumber}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class SwiftCode extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, isSwiftCodeDisabled, placeholderDisabled } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_SWIFT_CODE);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    SWIFT Code
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={ isSwiftCodeDisabled ? "n/a" : !placeholderDisabled && "Enter SWIFT Code" }
                        onChange={(event) => {inputBankAccountDetailsValue(event, "swiftCode");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.swiftCode}
                        disabled={isSwiftCodeDisabled}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class BsbOrIban extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, placeholderDisabled } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_BSB_OR_IBAN, ErrorList.AUS_BSB_LENGTH);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    BSB/IBAN
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled && "Enter BSB/IBAN"}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "bsbOrIban");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.bsbOrIban}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class InvoiceOrStatementEmail extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, placeholderDisabled } = this.props;
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_INVOICE_OR_STATEMENT_EMAIL, ErrorList.EMAIL_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Invoice/Statement Email
                    </Text>

                    <Description>Enter the email address you would like to receive your earnings statements to.</Description>
                
                    <ContainerGrid>
                        <FormField borderRadius="12px" size="14px" height="50px"
                            placeholderColor="#808080" border="1px solid #E9EBEF"
                            align="left"
                            width="100%"
                            type="text"
                            placeholder={!placeholderDisabled && "Enter Invoice/Statement Email"}
                            onChange={(event) => {inputBankAccountDetailsValue(event, "invoiceOrStatementEmail");} }
                            errors={emptyFieldError}
                            showErrors={true}
                            maxLength="100"
                            value={bankAccountDetails.invoiceOrStatementEmail}
                        >
                        </FormField>
                    </ContainerGrid>
                </div>
            </>
        );
    }
}

class StreetAddress extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, placeholderDisabled } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_STREET_ADDRESS);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Street Address
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled && "Enter Street Address"}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "streetAddress");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.streetAddress}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class StreetAddress2nd extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, placeholderDisabled } = this.props;

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Street Address 2 (optional)
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled && "Enter Street Address 2 (Optional)"}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "streetAddress2");} }
                        errors={null}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.streetAddress2}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class City extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, placeholderDisabled } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_CITY);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    City
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled && "Enter City"}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "city");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.city}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class State extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, placeholderDisabled } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_STATE);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    State
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled && "Enter State"}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "state");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.state}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class PostcodeOrZip extends Component {
    render() {
        const { bankAccountDetails, inputBankAccountDetailsValue, inputErrors, placeholderDisabled } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_POST_CODE_OR_ZIP);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Postcode/Zip
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled && "Enter Postcode/Zip"}
                        onChange={(event) => {inputBankAccountDetailsValue(event, "postcodeOrZip");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={bankAccountDetails.postcodeOrZip}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

export {
    BankCountry, 
    AddressCountry, 
    AccountHolderName, 
    BankName, 
    AccountNumber, 
    SwiftCode, 
    BsbOrIban, 
    InvoiceOrStatementEmail,
    StreetAddress,
    StreetAddress2nd,
    City,
    State,
    PostcodeOrZip
};

