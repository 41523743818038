import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { LoadingIndicator, Toast } from "src/components";
import SurveyTable, { SortableColumns, SortableColumnState } from "./components/SurveyTable";
import HowItWorks from "./components/HowItWorks";
import SurveyInsightPage from "./components/SurveyInsightPage";
import SurveyForm from "./forms/SurveyForm";
import EditForm from "./forms/EditForm";

import "./css/SurveyStyling.css";

class Survey extends Component {
    state = {
        fetching: 0,
        surveyArray: [],
        sortableColumns: [],
        checkAllUsers: false,
        isShowSurveyForm: false,
        isShowHowItWorks: false,
        isShowInsights: false,
        isShowEditForm: false,
        surveyUuid: "",
    };

    componentDidMount() {
        this.setState(state => ({
            fetching: state.fetching + 1
        }), () => this.safeToInit());
    }

    safeToInit = () => {
        this.getSurveys();

        this.setState(state => ({
            fetching: state.fetching - 1
        }));
    };

    getSurveys = () => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.get(`${API_URL}/survey/company/allSurveys`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            let allSurveys = res.data;

            allSurveys && allSurveys.forEach(element => {
                if (element.startDate) {
                    const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "2-digit", day: "2-digit" });
                    const date = new Date(element.startDate);
                    element.startDate = formatter.format(date);
                }

                if (element.finishDate) {
                    const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "2-digit", day: "2-digit" });
                    const date = new Date(element.finishDate);
                    element.finishDate = formatter.format(date);
                }
            });
            this.setState(state => ({
                surveyArray: allSurveys
            }));

            // Initially sort list based on application signup status and field errors.
            let sortable = this.state.sortableColumns;
            sortable[SortableColumns.SURVEY_NAME] = SortableColumnState.ASCENDING;

            this.setState({
                sortableColumns: sortable,
            });
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
            }));
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    showSurveyForm = (isShow) => {
        this.setState({
            isShowSurveyForm: isShow
        });
    };

    hideEditForm = () => {
        this.setState({
            isShowEditForm: false
        });
    };

    editSurvey = (surveyUuid) => {
        this.setState({
            isShowEditForm: true,
            surveyUuid: surveyUuid
        });
    };

    showHowItWorks = (isShow) => {
        this.setState({
            isShowHowItWorks: isShow
        });
    };

    surveyInsights = (surveyUuid) => {
        this.setState({
            isShowInsights: true,
            surveyUuid: surveyUuid
        });
    };

    showInsights = (isShow) => {
        this.setState({
            isShowInsights: isShow
        });
    };

    render() {
        const {
            fetching,
            surveyArray,
            isShowSurveyForm,
            isShowEditForm,
            surveyUuid,
            isShowHowItWorks,
            isShowInsights
        } = this.state;

        const { user } = this.props;
        if (fetching > 0) {
            return <LoadingIndicator />;
        }

        if (isShowSurveyForm) {
            return <SurveyForm showSurveyForm={this.showSurveyForm} user={user} getSurveys={this.getSurveys} />;
        }

        if (isShowEditForm) {
            return <EditForm hideEditForm={this.hideEditForm} user={user} surveyUuid={surveyUuid} getSurveys={this.getSurveys} />;
        }

        if (isShowInsights) {
            return <SurveyInsightPage showInsights={this.showInsights} user={user} surveyUuid={surveyUuid} />;
        }

        if (isShowHowItWorks) {
            return <HowItWorks showHowItWorks={this.showHowItWorks} />;
        }

        return <SurveyTable surveyArray={surveyArray} showSurveyForm={this.showSurveyForm} editSurvey={this.editSurvey} showHowItWorks={this.showHowItWorks} surveyInsights={this.surveyInsights} />;
    }
}

export default withRouter(Survey);