import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { SubscriptionContext } from "../SubscriptionContext";

// global components
import { Divider } from "@material-ui/core/index";
import {
    FlexContainer, Button, ErrorLabel, CardHeadingLabel, ExplainParagraphLabel, PageHeadingLabel,
    SplitLayoutRightImage
} from "src/components";

import LeftText from "../components/LeftText";

// constants
import { SUBSCRIPTION_TYPE_MONTHLY, SUBSCRIPTION_TYPE_YEARLY } from "src/constants/subscriptions";

// assets
import GreenCheck from "src/img/new/green-check.svg";
import RightImage from "src/img/subscription/2.jpg";

const TypeSelection = styled(FlexContainer)`
    width: 18.6875rem;
    height: 21.0625rem;
    border: ${p => p.border || "none"};
    border-radius: 27px;
    margin-top: 1.875rem;
    margin-right: 1.9375rem;
    box-shadow: 0px 5px 5px #0000001A;
    box-sizing: border-box;
    transition: outline ease 6s;
    &:hover{
        outline: 1px solid #8E4EB4;
        cursor: pointer;
    };
    ${(props) =>
        props.active &&
        css`
          background-color: #8E4EB4;
          color: #fff !important;
          &:hover {
            outline: none !important;
        }
    `};
    ${(props) =>
        props.disabled &&
        css`
            cursor: not-allowed !important;
            color: #E9EBEF !important;
            &:hover {
                outline: none !important;
            };
    `};
`;

const CardHeadingWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 2.1875rem;
    box-sizing: border-box;
    align-items: flex-end;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1.375rem;
`;

const Price = styled.div`
    font-size: 3.75rem;
    font-weight: 700;
    margin-bottom: 8px;
`;

const Description = styled.div`
    font-size: 1rem;
    font-weight: 400;
`;

const CardAction = styled.div`
    background: ${p => p.active ? "transparent" : "#E9EBEF 0% 0% no-repeat padding-box"};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.3125rem;
    border-radius: 0px 0px 27px 27px;
`;

const Image = styled.img`
    position: relative;
    object-fit: contain;
`;

const SelectionButton = styled(Button)`
    background-color: #fff;
    color: #000000;
    font-size: 0.935rem;
    border: 1px solid #000000;
    border-radius: 7px !important;
    font-family: Roboto;
    font-weight: 500;
    height: 2.3125rem;
    width: 8.75rem;
    line-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ::before {
        content: "Select";
    }

    ${(props) =>
        props.active &&
        css`
          border: 0 !important;
          color: #8E4EB4 !important;
          cursor: none;
          pointer-events: none;

          ::before {
            content: "Selected";
          }
    `};

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
            ::before {
                content: "Not Available" !important;
            }
    `};
`;

const StyledButton = styled(Button)`
    font-family: Roboto;
    border-radius: 7px !important;
    width: 8.75rem;
    height: 2.3125rem;
    line-height: 100%;
    font-weight: 500;
    margin-right: 1.125rem;
    margin-top: 1.125rem;
    font-size: 15px;
`;

const Currency = styled.span`
    margin-left: 0.47rem;
    font-size: 1rem;
    font-weight: 700;
`;

const SubscriptionType = () => {
    const context = useContext(SubscriptionContext);
    const { costing, updateCompanyValueAndPossiblyGoNext, isPurchasingMoreUsers } = context;
    const [subscriptionType, setSubscriptionType] = useState(context.subscriptionType);
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 1510px)").matches);
    const [error, setError] = useState(null);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 1510px)");
        function handleMediaQueryChange(e) {
            setMatches(e.matches);
        }

        mediaQuery.addEventListener("change", handleMediaQueryChange);

        return () => mediaQuery.removeEventListener("change", handleMediaQueryChange);
    }, []);

    const handleTypeSelection = (value) => {
        setSubscriptionType(value);
    };

    const proceed = (e) => {
        e.preventDefault();

        if (!subscriptionType) {
            return setError("*You must select a subscription type.");
        }

        updateCompanyValueAndPossiblyGoNext("subscriptionType", subscriptionType, true);
    };

    const isYearlySelected = () => SUBSCRIPTION_TYPE_YEARLY === subscriptionType;
    const isMonthlySelected = () => SUBSCRIPTION_TYPE_MONTHLY === subscriptionType;

    return (
        <React.Fragment>
            <PageHeadingLabel>
                {!isPurchasingMoreUsers ? "Subscription Type: Step 2"
                    : "Purchase More User Licenses"
                }
            </PageHeadingLabel>
            <ExplainParagraphLabel maxWidth="43.125rem" width="100%" size="0.9375rem">
                {!isPurchasingMoreUsers ? "Select what type of subscription you would like for your business and app user licenses."
                    : "Select what type of subscription you would like your additional user licenses to use."
                }
            </ExplainParagraphLabel>
            <SplitLayoutRightImage image={RightImage}
                width="100%"
                imageVisibility={matches ? "hidden" : "visible"}
            >
                <CardHeadingLabel margin="0 0 0 0">Subscription Type</CardHeadingLabel>
                <FlexContainer direction="row" marginTop="0" padding="0">
                    <TypeSelection disabled={true} border="1px solid #E9EBEF" active={isMonthlySelected()}>
                        <FlexContainer padding="1.25rem" height="15.75rem">
                            <CardHeadingWrapper>
                                Monthly
                                {isMonthlySelected() && <Image src={GreenCheck} />}

                            </CardHeadingWrapper>
                            <Price>
                                $9.99<Currency>USD</Currency>
                            </Price>
                            <Description>
                                Per user, per month <br />
                                Yearly plan, paid monthly
                            </Description>
                        </FlexContainer>
                        <CardAction active={isMonthlySelected()}>
                            <SelectionButton disabled={true}
                                // active={isMonthlySelected()}
                                onClick={() => handleTypeSelection(SUBSCRIPTION_TYPE_MONTHLY)}
                            />
                        </CardAction>
                    </TypeSelection>

                    <TypeSelection active={isYearlySelected()} onClick={() => handleTypeSelection(SUBSCRIPTION_TYPE_YEARLY)}>
                        <FlexContainer padding="1.25rem" height="15.75rem">
                            <CardHeadingWrapper>
                                Yearly
                                {isYearlySelected() && <Image src={GreenCheck} />}
                            </CardHeadingWrapper>
                            <Price>
                                ${costing.costPerUserUsd}<Currency>USD</Currency>
                            </Price>
                            <Description active={isYearlySelected()}>
                                Per user, per month <br />
                                Yearly plan, prepaid
                            </Description>
                        </FlexContainer>
                        <CardAction active={isYearlySelected()}>
                            <SelectionButton
                                active={isYearlySelected()}
                            />
                        </CardAction>
                    </TypeSelection>
                </FlexContainer>

                <LeftText color="#8E4EB4" margin="3.75rem 0 17px 0">
                    *Subscriptions are 12 months fixed term - Excluding government charges<br />
                    *Subscription automatically renews unless canceled 24-hours before the end of the current term.
                </LeftText>
                {error !== null ? <ErrorLabel>{error}</ErrorLabel> : ""}
                <Divider style={{ maxWidth: "39.375rem" }} />

                <FlexContainer direction="row">
                    <StyledButton border="1px solid #000000" color="#000000" onClick={() => context.backToPrevious()}>
                        Back
                    </StyledButton>
                    <StyledButton color="#ffffff" backgroundColor="#000000" onClick={(e) => proceed(e)}>
                        Next
                    </StyledButton>
                </FlexContainer>
            </SplitLayoutRightImage>
        </React.Fragment >
    );
};

export default SubscriptionType;