import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import {
    Text, FlexContainer, LoadingIndicator, Button, ErrorLabel,
    tablet, desktop
} from "src/components";

import ChevronLeft from "src/img/new/chevron-left.svg";

const StyledInput = styled.input`
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 500;
    width: 19.8125rem;
    height: 2.875rem;
    border-radius: 1.4375rem;
    color: #000000;
    font-size: 0.9375rem;
    border: 0;
    margin-bottom: 0.55rem;
    text-align: left;
    padding: 0 1.8rem;
    box-sizing: border-box;

    ::placeholder {
        color: #000000;
        font-weight: 500;
        text-align: left;
    }

    ${props => props.disabled && css`
        pointer-events: none;
        background: white;
    `};
`;

const BackButton = styled.div`
    height: 2.625rem;
    width: 2.625rem;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #4956FC;
`;

const Wrapper = styled(FlexContainer)`
    ${desktop`
        margin-left: -2rem;
    `};

    ${tablet`
        flex-direction: row;
    `};
`;

const UserLogin = ({ loginSuccess, errorStrings, showLoginSelection }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState("");
    const [accessToken, setAccessToken] = useState("");

    const handleInputChange = (event, key) => {
        if (key === "password") {
            setPassword(event.target.value);
        }
        else if (key === "accessToken") {
            setAccessToken(event.target.value);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setErrors([]);
        let errors = [];
        if (accessToken.trim() === "") {
            errors.push(errorStrings.EMPTY_ACCESS_TOKEN);
        }

        if (password.trim() === "") {
            errors.push(errorStrings.EMPTY_PASSWORD);
        }
        
        if (errors.length) {
            setErrors(errors);
        }
        else {
            setLoading(true);
            axios.post(`${API_URL}/company/login`, {
                accessToken: accessToken,
                password: password,
            })
                .then(response => {
                    setLoading(false);
                    loginSuccess(response.data);
                })
                .catch(error => {
                    setErrors(prevArray => [...prevArray, error]);
                    setLoading(false);
                });
        }
    };

    const renderErrors = () => errors.map(val => <ErrorLabel align="center" key={val}>{ val }</ErrorLabel>);

    return (
        <React.Fragment>
            
            <Wrapper direction="row" width="auto" >
                <div>
                    <BackButton onClick={() => showLoginSelection()}><img alt="back" src={ChevronLeft} /></BackButton>
                </div>
                <FlexContainer justifyContent="center" alignItems="center">
                    <div style={{ height: 42, marginBottom: "2.9375rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Text size="30px" weight="700" color="white" family="Roboto, Helvetica, sans-serif" margin="0">
                            User Login
                        </Text>
                    </div>
                    <form onSubmit={ (event) => event.preventDefault()} style={{ display: "grid", width: "100%" }}>
                        <StyledInput
                            placeholder="Email Address or Access Code"
                            autoComplete="email"
                            onChange={(e) => handleInputChange(e, "accessToken")}
                            disabled={loading}
                        />
                        <StyledInput
                            placeholder="Password"
                            onChange={(e) => handleInputChange(e, "password")}
                            type="password"
                            autoComplete="current-password"
                            disabled={loading}
                        />
                        { errors.length ? renderErrors() : "" }
                        <FlexContainer width="100%" height="4em" justifyContent="center" alignItems="center" marginTop="1.75rem" marginBottom="0">
                            { loading ?
                                <LoadingIndicator height="2rem" width="2rem"/>
                                :
                                <Button backgroundColor="#fff"
                                    width="8.75rem"
                                    color="#4956FC"
                                    onClick={(e) => handleFormSubmit(e)}
                                    style={{ fontFamily: "Roboto, Helvetica, sans-serif", fontWeight: 700, borderRadius: 7, boxShadow: "0px 3px 6px #00000029" }}
                                >Log In</Button>
                            }
                        </FlexContainer>
                    </form>
                    <Text color="white" weight="700" family="Roboto, Helvetica, sans-serif" margin="2rem 0 1.3rem 0" size="15px">
                        <Link to="/forgotPassword/user" style={{ color: "white", textDecoration: "none" }}>
                            Forgot your password?
                        </Link>
                    </Text>
                    <Text color="white" weight="700" family="Roboto, Helvetica, sans-serif" size="15px" margin="0">
                        <Link to="/forgotAccessCode" style={{ color: "white", textDecoration: "none" }}>
                            Forgot Access Code?
                        </Link>
                    </Text>
                </FlexContainer>
            </Wrapper>
        </React.Fragment>
    );
};

export default UserLogin;