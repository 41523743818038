import axios from "axios";
import { useState } from "react";
import { API_URL } from "src/scenes/App";

/**
 * Custom hook for updating a group chat (one-on-one chat cannot be updated)
 * @returns {{
 *      isUpdatingChat: boolean
 *      updateChatHook: Function
 * }}
 */
export const useUpdateChat = () => {
    const [isUpdatingChat, setIsUpdatingChat] = useState(false);
    const updateChat = async (currentChatUuid, formData, headers) => {
        setIsUpdatingChat(true);
        try {
            const url = `${API_URL}/chats/${currentChatUuid}`;
            const response = await axios.put(url, formData, headers);
            return response.data;
        } catch (error) {
            throw error;
        } finally {
            setIsUpdatingChat(false);
        }
    };

    return {
        isUpdatingChat,
        updateChatHook: updateChat
    };
};