import React from "react";
import styled from "styled-components";
import { Dialog, Divider } from "@material-ui/core/";
import CancellationTermsModal from "./CancellationTermsModal";
import {
    Text, Button, FlexContainer, tablet, mobile
} from "src/components";

const StyledButton = styled(Button)`
    width: 100%;
    max-width: 8.75rem;
    height: 2.345625rem;
    border-radius: 7px !important;
    border: ${p => p.border};
    background-color: ${p => p.backgroundColor};
    color: ${p => p.color};
    font-size: 0.875rem;
    line-height: 100%;

    ${tablet` margin-top: 1rem; `}
    ${mobile` margin-top: 1rem; `}
`;

const StyledText = styled(Text)`
    text-align: left;
    margin: ${p => p.margin};
    font-size: ${p => p.size || "1rem"};
`;

function CancellationModal(props) {
    const { showDialog, handleClose, confirmCallback, companyDetails } = props;    
    const subscriptionEnd = new Date(companyDetails.subscriptionEnd);

    const formattedSubscriptionEnd = subscriptionEnd instanceof Date ?
        new Intl.DateTimeFormat("default", { dateStyle: "full" }).format(subscriptionEnd)
        : subscriptionEnd;
    
    return (
        <Dialog open={showDialog} onClose={handleClose}
            maxWidth={false}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    width: "95%",
                    maxWidth: "56.25rem",
                    height: "auto",
                    minHeight: "40.3125rem",
                    borderRadius: 15,
                    overflow: "auto"
                },
            }}
        >
            <Text size="40px" align="left" weight="700" margin="2.875rem 3.4375rem 0 3.4375rem">Cancel Subscription</Text>
            <Divider style={{ margin: "1.75rem" }} />

            <div style={{ margin: "0 3.4375rem", maxWidth: "44.375rem", width: "100%" }}>
                <StyledText weight="700" size="20px" margin="0 0 1.8125rem 0">{companyDetails.companyName}, are you sure you want to end your subscription?</StyledText>
                <StyledText margin="0 0 1.2rem 0">Please click the <strong>‘Confirm’</strong> button below to initiate the cancellation process for your subscription in your Me Business Account.</StyledText>

                <StyledText margin="0 0 1.2rem 0" weight="700">Here are some important details to note:</StyledText>
                <StyledText >The cancellation will take effect at the conclusion of your current billing period, precisely on <strong>{formattedSubscriptionEnd}</strong></StyledText>
                <StyledText>
                After cancellation, your subscription will not automatically renew. However, you can restart your subscription at any time, and your account details will remain accessible to you.
                </StyledText>

                <CancellationTermsModal />
            </div>

            <Divider style={{ margin: "2.5625rem 0 1.375rem 0" }} />
            <FlexContainer direction="row" justifyContent="space-between" margin="0 3.4375rem 2rem 3.4375rem">
                <StyledButton
                    border="1px solid #000000"
                    backgroundColor="#FFF"
                    color="000"
                    onClick={handleClose}
                >Close</StyledButton>

                <StyledButton
                    backgroundColor="#FF5353"
                    color="#FFF"
                    onClick={() => confirmCallback()}
                >
                    Confirm
                </StyledButton>
            </FlexContainer>
        </Dialog>
    );
}

export default CancellationModal;