import { useCallback, useContext, useState } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { UserContext } from "src/scenes/App/UserContext";
import { Toast } from "src/components";
import { useGetEventData } from "../matrix/useGetEventData";
import { hasRoomMessages, handleMessageLabel } from "src/utils/helpers";
import { useFetchRoomTimeline } from "../../hooks";

/**
 * Custom hook for getting chats (employee, clients or leadership role).
 * Also updates a Chat's `latestEvent` once received by timeline listener.
 *
 * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
 *
 * @param {MatrixClient} matrixClient
 * @returns {{
 *      chatList: Array
 *      chatMasterList: Array
 *      didFetchChatsFail: boolean
 *      setDidFetchChatsFail: React.SetStateAction
 *      currentUserUuid: string
 *      businessBackgroundColor: string?
 *      isFetchingChats: boolean
 *      isUpdatingChatlist: boolean
 *      retrieveChats: ((function(string): Promise<void>))
 *      updateChatsMasterList: ((function(string): Promise<void>))
 *      retrieveLeadershipRoleChats: ((function(string): Promise<void>))
 *      updateChatList: Function
 *      removeChatFromChatlist: ((function(string): void))
 *      setChatList: React.SetStateAction
 *      setIsUpdatingChatlist: React.SetStateAction
 * }}
 */
export const useGetChats = (matrixClient) => {

    const userContext = useContext(UserContext);

    const { getEventData } = useGetEventData(matrixClient);
    const { fetchRoomsTimelines } = useFetchRoomTimeline(matrixClient);

    const [chatList, setChatList] = useState([]);
    const [chatMasterList, setChatMasterList] = useState([]);
    const [currentUserUuid, setCurrentUserUuid] = useState("");
    const [businessBackgroundColor, setBusinessBackgroundColor] = useState(null);
    const [isFetchingChats, setIsFetchingChats] = useState(true);
    const [isUpdatingChatlist, setIsUpdatingChatlist] = useState(false);
    const [didFetchChatsFail, setDidFetchChatsFail] = useState(false);

    const headers = { headers: { Authorization: "Bearer " + userContext.token } };

    /**
     * Also retrieves company's background color to be used by ChatList
     * @param {string} selectedChatListTypeOrLeadershipUuid
     * @returns {Promise<void>}
     */
    const retrieveChats = async (selectedChatListTypeOrLeadershipUuid) => {
        if (!selectedChatListTypeOrLeadershipUuid) {
            Toast.error("No selected chat type to fetch.");
            return;
        }
        setIsFetchingChats(true);
        try {
            const response = await axios.get(`${API_URL}/chats/${selectedChatListTypeOrLeadershipUuid}`, headers);
            setBusinessBackgroundColor(response.data.businessBackgroundColor);
            setCurrentUserUuid(response.data.currentUserUuid);

            setChatMasterList(response.data.chats);
            const formatted = await getChatsLatestEvent(response.data.chats, response.data.currentUserUuid);
            const sorted = sortByLatestEventTimestamp(formatted);
            setChatList(sorted);
        } catch (error) {
            setDidFetchChatsFail(true);
            Toast.error("Failed to retrieve chats.");
        } finally {
            setIsFetchingChats(false);
            setIsUpdatingChatlist(false);
        }
    };

    /**
     * Also retrieves company's background color to be used by ChatList
     * @param {string} selectedChatListTypeOrLeadershipUuid
     * @returns {Promise<void>}
     */
    const updateChatsMasterList = async (selectedChatListTypeOrLeadershipUuid) => {
        if (!selectedChatListTypeOrLeadershipUuid) {
            Toast.error("No selected chat type to fetch.");
            return;
        }
        setIsFetchingChats(true);
        try {
            const response = await axios.get(`${API_URL}/chats/${selectedChatListTypeOrLeadershipUuid}`, headers);
            setBusinessBackgroundColor(response.data.businessBackgroundColor);
            setCurrentUserUuid(response.data.currentUserUuid);

            setChatMasterList(response.data.chats);
        } catch (error) {
            setDidFetchChatsFail(true);
            Toast.error("Failed to retrieve chats.");
        } finally {
            setIsFetchingChats(false);
        }
    };

    /**
     * @param {string} leadershipRoleUuid
     * @returns {Promise<void>}
     */
    const retrieveLeadershipRoleChats = async (leadershipRoleUuid) => {
        await retrieveChats(`getLeadershipChats/${leadershipRoleUuid}`);
    };

    /**
     * Get the last event of the matrix room, used to display contents for ChatsList
     * @param {array} chats
     * @param {string} userUuid
     * @returns array
     */
    const getChatsLatestEvent = async (chats, userUuid) => {
        const roomsEvents = await fetchRoomsTimelines(chats, headers);
        const formattedChats = await Promise.all(
            chats.map(async (chat) => {
                const room = await matrixClient.getRoom(chat.matrixRoomId);
                const unreadCount = room.getUnreadNotificationCount("total");
                const latestEvent = room.getLastLiveEvent();
                if (!latestEvent) {
                    return undefined;
                }
                const formatted = await getEventData(latestEvent);
                const roomHasMessages = hasRoomMessages(chat, roomsEvents);
                const message = handleMessageLabel(latestEvent, roomHasMessages, chat, roomsEvents);
                chat["latestEvent"] = formatted;
                chat["unreadCount"] = unreadCount;
                chat["roomHasMessages"] = roomHasMessages;
                chat["message"] = message;
                if (roomHasMessages || (chat.ownerUuid === userUuid && chat.isGroupChat) || (!chat.isGroupChat && userUuid === chat.ownerUuid)) {
                    return chat;
                }
            })
        );
        return formattedChats.filter((item) => item !== undefined);
    };

    const updateChatList = useCallback((newChatList) => {
        setChatList(newChatList);
    });

    /**
     * @param {string} chatRoomUuidToRemove
     */
    const removeChatFromChatlist = (chatRoomUuidToRemove) => {
        const removedChat = chatList.filter((chat) => chat.uuid !== chatRoomUuidToRemove);
        setChatList(removedChat);
    };

    return {
        chatList,
        chatMasterList,
        didFetchChatsFail,
        setDidFetchChatsFail,
        currentUserUuid, 
        businessBackgroundColor, 
        isFetchingChats,
        isUpdatingChatlist,
        retrieveChats,
        updateChatsMasterList,
        retrieveLeadershipRoleChats,
        updateChatList,
        removeChatFromChatlist,
        setChatList,
        setIsUpdatingChatlist
    };
};

/**
 *
 * @param {Array} chats
 * @returns {Array}
 */
const sortByLatestEventTimestamp = (chats) => {
    return chats.sort((a, b) => {
        const timestampA = new Date(a?.latestEvent?.origin_server_ts);
        const timestampB = new Date(b?.latestEvent?.origin_server_ts);
        return timestampB - timestampA;
    });
};