import React from "react";
import styled from "styled-components";
import { Dialog, Divider } from "@material-ui/core/";
import {
    Text, Button, FlexContainer, tablet, mobile
} from "src/components";

const StyledButton = styled(Button)`
    width: 100%;
    max-width: ${p => p.maxWidth || "8.75rem"};
    height: 2.345625rem;
    border-radius: 7px !important;
    border: ${p => p.border};
    background-color: ${p => p.backgroundColor};
    color: ${p => p.color};
    font-size: 0.875rem;
    line-height: 100%;

    ${tablet` margin-top: 1rem; `}
    ${mobile` margin-top: 1rem; `}
`;

const StyledText = styled(Text)`
    text-align: left;
    margin: ${p => p.margin};
    font-size: ${p => p.size || "1rem"};
`;

function RenewalModal(props) {
    const { showDialog, handleClose, confirmCallback, companyDetails } = props;  

    return (
        <Dialog open={showDialog} onClose={handleClose}
            maxWidth={false}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    width: "95%",
                    maxWidth: "56.25rem",
                    height: "auto",
                    borderRadius: 15,
                    overflow: "auto"
                },
            }}
        >
            <Text size="40px" align="left" weight="700" margin="2.875rem 3.4375rem 0 3.4375rem">Renew Subscription</Text>
            <Divider style={{ margin: "1.75rem" }} />

            <div style={{ margin: "0 3.4375rem", maxWidth: "44.375rem", width: "100%" }}>
                <StyledText weight="700" size="20px" margin="0 0 1.8125rem 0">{companyDetails.companyName} would you like to renew your Me Business subscription?</StyledText>
                <StyledText margin="0 0 1.2rem 0">Great news! Getting back to all the Me Business features and benefits of your subscription is just a click away. Simply hit the <strong>‘Renew Subscription’</strong> button below to enable auto-renew and seamlessly reactivate your subscription directly in your Me Business Account. </StyledText>

                <StyledText>
                    Remember, you’re always in control. If you need to, you can cancel your subscription at any time.
                </StyledText>
            </div>

            <Divider style={{ margin: "2.5625rem 0 1.375rem 0" }} />
            <FlexContainer direction="row" justifyContent="space-between" margin="0 3.4375rem 2rem 3.4375rem">
                <StyledButton
                    border="1px solid #000000"
                    backgroundColor="#FFF"
                    color="000"
                    onClick={handleClose}
                >Cancel</StyledButton>

                <StyledButton
                    maxWidth="200px"
                    backgroundColor="#000000"
                    color="#FFF"
                    onClick={() => confirmCallback()}
                >
                    Renew Subscription
                </StyledButton>
            </FlexContainer>
        </Dialog>
    );
}

export default RenewalModal;