import React, { Component } from "react";
import { FlexContainer, Text, ExplainParagraphLabel, PageHeadingLabel } from "src/components";

class AccessInformation extends Component {
    render() {
        return (
            <>
                <PageHeadingLabel>
                    Directory
                </PageHeadingLabel>

                <div style={{ marginBottom: "2rem" }}>
                    <ExplainParagraphLabel style={{ fontSize: "0.875rem", width: "42.625rem" }}>
                        Enhance your Me Business app by creating a custom business directory. Display relevant information to
                        employees, clients, or both, based on your preferences. Elevate your business communication with the
                        seamlessly integrated Directory feature.
                    </ExplainParagraphLabel>
                </div>

                <FlexContainer style={{ maxWidth: "42.625rem", backgroundColor: "#fff", borderRadius: "1.25rem", padding: "0.625rem 2.063rem 1.563rem 2.063rem" }}>
                    <Text size="1.25rem" align="left" weight="700" style={{ marginBottom: "0.75rem" }}>
                        How to Gain Access to the Directory Feature:
                    </Text>

                    <Text size="1rem" weight="400" align="left" margin="0 0 1rem 0">
                        This feature is currently disabled. If you would like to leverage the Directory feature
                        and start displaying relevant information to your employees and clients, please
                        contact our support team at ‘Me Business.’ Our support team will promptly review
                        your request and provide you with the necessary steps to enable the Directory feature
                        for your account.
                    </Text>

                    <a href="https://www.memotivationapp.com/contact"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ width: "8.125rem",
                            borderRadius: "0.625rem",
                            color: "#fff",
                            fontSize: "0.9375rem",
                            fontWeight: "500",
                            padding: "0.625rem 0",
                            textDecoration: "none",
                            textAlign: "center",
                            backgroundColor: "#4956fc",
                            margin: "1rem 0"
                        }}> Contact Us </a>
                </FlexContainer>
            </>
        );
    }
}

export default AccessInformation;