import React, { Component } from "react";
import { hot } from "react-hot-loader/root";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import styled, { createGlobalStyle, css } from "styled-components";
import { UserContext } from "./UserContext";
import LeftNav from "./components/LeftNav";
import QuoteCategorySelection from "../EmployeeList/QuoteCategorySelection";
import axios from "axios";
import { openModal } from "src/redux/actions";
import store from "src/redux/store";
import { 
    FlexContainer, Toast, Modal, 
    LicenseRenewalContainer, SetupPasswordContainer, SignUpContainer,
    Header, AuthorizedRoute, LoadingIndicator,
    RevokedPermissionsModal, PageContainer
} from "src/components";

import {
    LicenseRenewal, ResetPassword,
    Login, SignUp, SetupPassword, ForgotAccessCode, ForgotPassword,
    Subscription, SubscriptionRenewal, Customise, EmployeeList, ClientLicense, Noticeboard,
    Chat, Directory, Survey, LegacySurvey, Statistics, Setting
} from "src/scenes";

import { 
    FontFaceObserver, SofiaPro,
    RobotoRegular, RobotoMedium, RobotoBold, RobotoBlack,
} from "src/fonts";

import * as PERMISSIONS from "src/constants/permissions";
import { IsFeatureReady } from "src/constants/features";
import { UserKeys } from "src/constants/userDetails";

const getEnvironment = () => {
    switch (window.location.hostname) {
    case "localhost": return ENVIRONMENT.DEV;
    case "staging.memotivationapp.com": return ENVIRONMENT.STAGING;
    case "business.memotivationapp.com": return ENVIRONMENT.PRODUCTION;
    default:
        console.log("[ERROR] Running on unrecognised host: " + window.location.hostname);
        return null;
    }
};

export const ENVIRONMENT = {
    DEV: "dev",
    STAGING: "staging",
    PRODUCTION: "production",
    get: getEnvironment
};

export const API_URL = (() => {
    switch (ENVIRONMENT.get()) {
    case ENVIRONMENT.DEV: return "http://127.0.0.1:8880";
    case ENVIRONMENT.STAGING: return "https://api.staging.dailyfixme.com";
    case ENVIRONMENT.PRODUCTION: return "https://api.dailyfixme.com";
    default: return null;
    }
})();

const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Roboto';
        font-weight: 400;
        src: url('${RobotoRegular}');
    }
    @font-face {
        font-family: 'Roboto';
        font-weight: 500;
        src: url('${RobotoMedium}');
    }
    @font-face {
        font-family: 'Roboto';
        font-weight: 700;
        src: url('${RobotoBold}');
    }
    @font-face {
        font-family: 'Roboto';
        font-weight: 800;
        src: url('${RobotoBlack}');
    }
    @font-face {
        font-family: 'SofiaPro';
        font-weight: 600;
        src: url('${SofiaPro}');
    }

    html,
    body,
    #root {
        font-family: Roboto, Helvetica, sans-serif;
        height: 100%;
        margin: 0;
        padding: 0;
        width: 100%;
        font-weight: 500;
    }

    ${p => p.isLoadingFonts && css`
        html,body, #root {
                font-family: Roboto, Helvetica, sans-serif;
                line-height: 1.6;
                letter-spacing: -0.9px;
                height: auto;
                visibility: visible;
                font-weight: 500;
        }`}
`;

const LoggedInRightContainer = styled(FlexContainer)`
    background: ${p => p.backgroundColor};
    flex-grow: 1;
    height: auto;
    width: auto;
    overflow-x: hidden;
`;

export const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        width: "40%",
        backgroundColor: "#292C33",
        color: "#F4F4F4",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        transform: "translate(-50%, -50%)"
    }
};

export const StorageKey = {
    TOKEN: "token",
    COMPANY_NAME: "companyName",
    COMPANY_ABN: "abn",
    UUID: "uuid",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    IS_CUSTOMIZE_COMPLETE: "isCustomizeComplete",
    IS_ACTIVE: "isActive",
    EMAIL: "email",
    COUNTRY_CODE: "countryCode",
    ENABLED_CLIENT_LICENSE: "enabledClientLicense",
    PERMISSIONS: "permissions",
    ACCESS_CODE: "accessCode",
    COMPANY_LOGO_URL: "companyLogoUrl",
    PROFILE_PICTURE_URL: "profilePictureUrl",
    IS_MATRIX_REGISTRATION_COMPLETE: "isMatrixRegistrationComplete"
};

class App extends Component {
    state = {
        isLoadingFonts: true,
        user: null,
        contentLeftMargin: window.innerWidth <= 1280 ? 75 : 270,
        currentWidth: null,
        isUserAuthenticated: false,
        isUserDataLoaded: false,
        componentBackground: "#EAEDF4"
    };

    resizeHandler = (element) => {
        const newContentLeftMargin = window.innerWidth <= 1280 ? 75 : 270;
        element.style.padding = `0 0 2rem ${newContentLeftMargin}px`;
    };

    // will error when resizing on unauthenticated screens
    determineLeftMarginForContent = () => {
        let timeoutId;
        window.addEventListener("resize", () => {
            let container = document.getElementById("content-container");
            if (container) {
                // debouncing listener to reduce number of executions
                clearTimeout(timeoutId);
                timeoutId = setTimeout(this.resizeHandler(container), 100);
            }
        });
    };

    redirectToLoginIfNoToken = () => {
        const excludedPaths = ["login", "resetPassword", "setPassword", "licenseRenewal"];
        if (!excludedPaths.includes(window.location.pathname.split("/")[1])) {
            window.location.href = "/login";
        }
    };

    /**
     * This prevents access when the 'resetPassword' path clears local storage.
     * Opening 'resetPassword' in a new tab clears local storage for all 'memotivation' tabs,
     * but does not affect component state and 'userContext' in other tabs.
     * Thus, other tabs remain authenticated and authorized.
     * Boot out all 'memotivation' tabs when local storage is cleared
     */
    handleStorageChange = (e) => {
        if (!localStorage.length) {
            this.setState({ user: null });
            window.location.href = "/login";
        }
    };

    componentWillUnmount() {
        window.removeEventListener("storage", this.handleStorageChange);
    }

    async componentDidMount() {
        this.loadFonts();
        Toast.init();
        await this.initUser();
        this.determineLeftMarginForContent();
        window.addEventListener("storage", this.handleStorageChange);
        this.handleSmallDeviceWarning();
    }

    loadFonts = () => {
        let observer = new FontFaceObserver("Roboto");
        observer.load(null, 10000).then(() => {
            this.setState({
                isLoadingFonts: false
            });
        }, function() {
            console.warn("Font is not available after waiting 10 seconds");
        });
    };

    handleSmallDeviceWarning = () => {
        // A warning will be displayed whenever the screen is refreshed while a user is
        // logged in and using a device with a smaller than most laptops (1280px).
        const condition = window.innerWidth < 1280 && this.state.user !== null;
        if (condition) {
            Modal.open("SmallDevice",
                <div>
                    The Me administration page was not developed for a small screen, so
                    we recommend a laptop or desktop be used for the best user experience.
                </div>
            );
        }
    };

    initUser = async () => {
        const token = localStorage.getItem(UserKeys.TOKEN);
        if (!token) {
            return this.redirectToLoginIfNoToken();
        }
        // Prevent showing the non-authorised components resulting to <NoMatch /> being rendered when refreshing.
        this.setState({ isUserAuthenticated: true });
        try {
            const userDetails = await this.getUserDetails(token);
            this.updateUserStateFromResponse(userDetails);
        } catch (err) {
            Toast.error("Unable to initialize user.");
        }
    };

    /** this will retrieve all user's details and permissions */
    getUserDetails = async (token) => {
        try {
            const response = await axios.get(`${API_URL}/company/userDetails`, { headers: { Authorization: `Bearer ${token}` } });
            return response.data;
        }
        catch (error) {
            Toast.error("Unable to retrieve your information. Please log in again.");
            setTimeout(() => {
                window.location.href = "/login?status=invalidToken";
                localStorage.clear();
            }, 1500);
            throw error;
        }
    };

    updateUserStateFromResponse = (data) => {
        this.setState({
            user: {
                [UserKeys.UUID]: data.uuid,
                [UserKeys.EMAIL]: data.email,
                [UserKeys.ACCESS_CODE]: data.accessCode,
                [UserKeys.FIRST_NAME]: data.firstName,
                [UserKeys.LAST_NAME]: data.lastName,
                [UserKeys.PROFILE_PICTURE_URL]: data.profilePictureUrl,
                [UserKeys.PHONE_NUMBER]: data.phoneNumber,
                [UserKeys.BIRTH_DATE]: data.birthDate,
                [UserKeys.IS_ACTIVE]: data.isActive,
                [UserKeys.IS_CLIENT]: data.isClient,
                [UserKeys.PERMISSIONS]: JSON.parse(data.roleAndPermissions.permissionIds),
                [UserKeys.TOKEN]: data.token,
                [UserKeys.LEADERSHIP_ROLES]: data.leadershipRoles,
                // company details below
                [UserKeys.COMPANY_NAME]: data.companyName,
                [UserKeys.COMPANY_ABN]: data.abn,
                [UserKeys.IS_CUSTOMIZE_COMPLETE]: data.isCustomizeComplete,
                [UserKeys.COUNTRY_CODE]: data.countryCode,
                [UserKeys.ENABLED_CLIENT_LICENSE]: data.enabledClientLicense,
                [UserKeys.ENABLED_BUSINESS_DIRECTORY]: data.enabledBusinessDirectory,
                [UserKeys.COMPANY_LOGO_URL]: data.companyLogoUrl,
                [UserKeys.MATRIX_USER_ID]: data.matrixUserId,
                updateUserContextProperties: this.updateUserContextProperties,
                [UserKeys.CHAT_PRESENCE_OVERRIDE]: data.chatPresenceOverride
            },
            isUserAuthenticated: true,
            isUserDataLoaded: true
        });
    };

    /** @param {object} properties - key-value pairs */
    updateUserContextProperties = (properties) => {
        this.setState({
            user: { ...this.state.user, ...properties }
        });
    };

    loggedInSuccess = (data) => {
        localStorage.setItem(UserKeys.TOKEN, data.token);
        this.updateUserStateFromResponse(data);
    };

    loggedOut = () => {
        this.setState({ 
            user: null,
            isUserAuthenticated: false,
            isUserDataLoaded: false
        });
        localStorage.clear();
    };

    /**
     * TODO: might be possible to use the updateUserContextProperties instead */
    setEnabledClientLicenseFlag = (isEnabled) => {
        this.setState({
            user: {
                ...this.state.user,
                enabledClientLicense: isEnabled
            }
        });

        localStorage.setItem(StorageKey.ENABLED_CLIENT_LICENSE, isEnabled);
    };

    isChatRendered = (value) => {
        const background = value ? "#FFF" : "#EAEDF4";
        this.setState({
            componentBackground: background
        });
    };

    updateUserPresenceState = (onlineStatus) => {
        this.setState({
            user: {
                ...this.state.user,
                [UserKeys.CHAT_PRESENCE_OVERRIDE]: onlineStatus
            }
        });
    };

    render() {
        const { user, contentLeftMargin, isUserAuthenticated, isUserDataLoaded, componentBackground } = this.state;
        const token = localStorage.getItem(StorageKey.TOKEN);

        return (
            <UserContext.Provider value={user}>
                <GlobalStyle isLoadingFonts={this.state.isLoadingFonts} />
                <Router>
                    <Switch>
                        <Route path="/licenseRenewal/:referralCode/:key" render={(props) => (
                            <LicenseRenewalContainer {...props}>
                                <LicenseRenewal {...props} />
                            </LicenseRenewalContainer>
                        )} />

                        <Route path="/setPassword/:shortTermToken" render={(props) => (
                            <SetupPasswordContainer {...props}>
                                <SetupPassword {...props} />
                            </SetupPasswordContainer>
                        )} />

                        <Route path="/forgotPassword/:userType" render={(props) => (
                            <SignUpContainer>
                                <ForgotPassword {...props} logout={this.loggedOut} />
                            </SignUpContainer>
                        )} />
                        <Route path="/resetPassword/:portal/:token" render={(props) => (
                            <SignUpContainer>
                                <ResetPassword {...props} logout={this.loggedOut} />
                            </SignUpContainer>
                        )} />
                        <Route path="/forgotAccessCode" render={(props) => (
                            <SignUpContainer>
                                <ForgotAccessCode {...props} logout={this.loggedOut} />
                            </SignUpContainer>
                        )} />

                        <Route exact path="/">
                            <Redirect to={token === null ? "/login" : "/active"} />
                        </Route>

                        { (!isUserAuthenticated && !isUserDataLoaded) &&
                            <Switch>
                                <Route path="/signUp" render={(props) => (
                                    <SignUpContainer>
                                        <SignUp {...props} signUpComplete={this.loggedInSuccess} />
                                    </SignUpContainer>
                                )} />
                                <Route path="/login" render={(props) => (
                                    <SignUpContainer>
                                        <Login {...props} login={this.loggedInSuccess} />
                                    </SignUpContainer>
                                )} />
                                <Route path="*">
                                    <SignUpContainer>
                                        <NoMatch />
                                    </SignUpContainer>
                                </Route>
                            </Switch>
                        }
                        { isUserAuthenticated && isUserDataLoaded ?
                            <React.Fragment>
                                <Header style={{ position: "fixed", zIndex: 10 }} user={user} />
                                <FlexContainer direction="row" height="auto" minHeight="100vh" overflowY="auto" padding="76px 0 0 0">
                                    <LeftNav logout={this.loggedOut} />
                                    <LoggedInRightContainer backgroundColor={componentBackground}>
                                        <FlexContainer
                                            id="content-container"
                                            padding={`0 0 0 ${contentLeftMargin}px`}
                                            style={{ transition: "0.2s all ease-in-out", flexGrow: "1" }}
                                        >
                                            <Switch>
                                                <Route exact path="/">
                                                    <Redirect to="/active" />
                                                </Route>
                                                <AuthorizedRoute path="/active"
                                                    component={Subscription}
                                                    allowed={[PERMISSIONS.WEB_DASHBOARD_ACCESS]} 
                                                />
                                                <AuthorizedRoute path="/renewal"
                                                    component={SubscriptionRenewal}
                                                    allowed={[PERMISSIONS.WEB_DASHBOARD_ACCESS]} 
                                                />
                                                <AuthorizedRoute path="/customise" 
                                                    component={Customise} 
                                                    allowed={[PERMISSIONS.MY_ME_APP]} 
                                                />
                                                <AuthorizedRoute path="/employList" 
                                                    component={EmployeeList} user={user} isClient={false}
                                                    allowed={[PERMISSIONS.USER_LISTS]} 
                                                />
                                                {/* <AuthorizedRoute path="/invite" component={EmployeeList} user={user} isClient={true}
                                                    allowed={[PERMISSIONS.WEB_DASHBOARD_ACCESS]} 
                                                /> */}
                                                <AuthorizedRoute path="/clientLicense" 
                                                    component={ClientLicense} user={user}
                                                    setEnabledClientLicenseFlag={this.setEnabledClientLicenseFlag}
                                                    allowed={[PERMISSIONS.SALES_AND_PROMOTIONS]} 
                                                />
                                                <AuthorizedRoute path="/noticeboard" 
                                                    component={Noticeboard} user={user}
                                                    allowed={[PERMISSIONS.NOTICEBOARD]} 
                                                />
                                                
                                                { IsFeatureReady.chat() &&
                                                    <AuthorizedRoute path="/chats" 
                                                        component={Chat} user={user}
                                                        allowed={[PERMISSIONS.WEB_DASHBOARD_ACCESS]}
                                                        isChatRendered={this.isChatRendered}
                                                        updateUserPresenceState={this.updateUserPresenceState}
                                                        getUserDetails={this.getUserDetails}
                                                    />
                                                }
                                                <AuthorizedRoute path="/survey"
                                                    component={ IsFeatureReady.surveyRedesign() ? Survey : LegacySurvey } user={user}
                                                    allowed={[PERMISSIONS.SURVEYS]}
                                                />

                                                <AuthorizedRoute path="/directory"
                                                    component={Directory} user={user}
                                                    allowed={[PERMISSIONS.DIRECTORY]}
                                                />
                                                <AuthorizedRoute path="/statistics" 
                                                    component={Statistics} user={user}
                                                    allowed={[PERMISSIONS.STATISTICS]} 
                                                />
                                                <AuthorizedRoute path="/setting" 
                                                    component={Setting}
                                                    setEnabledClientLicenseFlag={this.setEnabledClientLicenseFlag}
                                                    allowed={[PERMISSIONS.WEB_DASHBOARD_ACCESS]} 
                                                />

                                                { IsFeatureReady.clientQuoteCategorySelection() &&
                                                    <AuthorizedRoute path="/quoteSelection" 
                                                        component={QuoteCategorySelection} isClient={true} user={user}
                                                        allowed={[PERMISSIONS.USER_LISTS]}
                                                    />
                                                }

                                                {/** because we added the "NoMatch" component, the disadvantage is having the T6647 error
                                                 * Just adding the routes can prevent this issue.  both this page displays blank, when user is already logged-in.
                                                 * */}

                                                <Route path="/signUp">
                                                    <Redirect to="/active" />
                                                </Route>
                                                <Route path="/login">
                                                    <Redirect to="/active" />
                                                </Route>

                                                <Route path="*">
                                                    <NoMatch />
                                                </Route>
                                            </Switch>
                                        </FlexContainer>
                                    </LoggedInRightContainer>
                                </FlexContainer>
                            </React.Fragment>
                            :
                            <LoadingIndicator />
                        }
                    </Switch>
                </Router>
                <RevokedPermissionsModal refreshPermissions={() => this.getUserDetails()} />
            </UserContext.Provider>
        );
    }
}

function NoMatch() {
    let location = useLocation();

    return (
        <div style={{ display: "flex", height: "80vh", alignItems: "center" }}>
            <PageContainer>
                <h3>
                    No match for <code>{location.pathname}</code>
                </h3>
            </PageContainer>
        </div>
    );
}

function handle401() {
    const pathsToNotGoToLoginOn401 = ["login", "licenseRenewal", "forgotPassword", "forgotAccessCode"];
    if (!pathsToNotGoToLoginOn401.includes(window.location.pathname.split("/")[1])) {
        setTimeout(function() {
            window.location.href = "/login?status=invalidToken";
            localStorage.clear();
        }, 1500);
    }
}
/**
 * handle a 403 response when a user tries to access unauthorized resource, or loses the required permission while they are logged in
 * @param {Error} error the 403 response containing the error message and updated permission
 * @returns {Promise<Error>} a Promise that rejects with the error
 * opens a modal - RevokedPermissionsModal - that redirects to /active thus refreshing the user's permission and screen
 * or redirect to login and clear storage when losing web dashboard permission */
function handle403(error) {
    // we dont want this to run during login
    // because a user logging in with valid credentials but has no web dashboard permission will get unfriendly errors
    if (window.location.pathname !== "/login" && window.location.pathname !== "/chat") {
        const latestUserPermissions = error.response.data.permissions;
        // the `permissions` key is only sent from the API UserAuthorization middleware. 
        // other 403s within the API will not send the `permissions` key
        if (latestUserPermissions !== undefined) {
            if (PERMISSIONS.hasPermissions([PERMISSIONS.WEB_DASHBOARD_ACCESS], JSON.parse(latestUserPermissions))) {
                store.dispatch(openModal());
            }
            else {  
                window.location.href = "/login";
                localStorage.clear();
            }
            return Promise.reject(error);
        }
    }

    if (window.location.pathname === "/chat") {
        const latestUserPermissions = error.response.data.permissions;
        if (latestUserPermissions !== undefined && PERMISSIONS.hasPermissions([PERMISSIONS.WEB_DASHBOARD_ACCESS], JSON.parse(latestUserPermissions))) {
            store.dispatch(openModal());
            return Promise.reject(error);
        }
    }

    if (window.location.pathname === "/clientLicense") {
        return Promise.reject(error);
    }

    if (window.location.pathname === "/noticeboard") {
        return Promise.reject(error);
    }

    const message = error.response.data["error"] || "Unauthorized access.";
    return Promise.reject(message);
}

function handleOtherErrorCodes(error) {
    // Extract error message sent from the API
    /** below paths are those whose error messages are handled here */
    const paths = ["login", "signUp", "forgotPassword", "forgotAccessCode", "resetPassword", "setPassword"];
    if (paths.includes(window.location.pathname.split("/")[1])) {
        let message;
        if (error.response.data.error) {
            if (error.response.data.messages) {
                message = error.response.data.error.messages;
            }
            else {
                message = error.response.data.error;
            }
        }
        else {
            message = "Something went wrong. Unknown error.";
        }
        
        return Promise.reject(message);
    }

    else {
        const message = error.response.data["error"];

        if (typeof message === "string")
            return Promise.reject(new Error(message));
        else
            return Promise.reject(error.response.data.error);
    }
}

// Add an axios response interceptor. This is similar to middleware.
// We 'intercept' the response object before passing it on to the app

axios.interceptors.response.use(function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (!error) {
        return console.log("Error object doesn't exist");
    }

    if (error && error.response) {
        if (error.response.status === 401) {
            handle401();
        }
        else if (error.response.status === 403) {
            return handle403(error);
        }
        return handleOtherErrorCodes(error);
    }
    else {
        return Promise.reject(error.message);
    }
});

export default hot(App);
