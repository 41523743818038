import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";

const TOAST_ID = "toast-container";
const DEFAULT_DURATION = 5000;
const MAX_TOASTS = 5;

const ToastType = {
    INFO: 0,
    ERROR: 1,
    SUCCESS: 2,
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    user-select: none;
`;

const Container = styled.div`
    display: inline-block;
    position: relative;
    z-index: 999;
    top: 20vh;

    margin: 0.5rem auto;
    padding: .5rem 1rem;
    min-width: 10rem;
    max-width: 45rem;
    height: 2rem;

    color: ${ p => p.color || "white" };
    background-color: ${ p => p.background || "black" };
    border-radius: 1.5rem;

    line-height: 2rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: auto;
    cursor: pointer;

    animation-timing-function: ease-out;
    animation-name: fade-in, fade-out;
    animation-duration: .25s;
    animation-delay: 0s, ${ p => (p.duration && ((p.duration - 250) / 1000) + "s") || "2.75s" };
    animation-fill-mode: forwards;

    @keyframes fade-in {
        0% {
            user-select: none;
            opacity: 0;
            transform: translateY(-5vh);
        }
        100% {
            user-select: auto;
            opacity: 1;
        }
    }

    @keyframes fade-out {
        100% {
            user-select: none;
            opacity: 0;
        }
        0% {
            user-select: auto;
            opacity: 1;
        }
    }
`;

const ToastObject = (({ toastType, duration, children }) => {
    const toastTypeColour = () => {
        if (toastType === ToastType.INFO) {
            return ["#81B6FF", "#002D6A"];
        } else if (toastType === ToastType.ERROR) {
            return ["#FF6E6E", "#670000"];
        } else if (toastType === ToastType.SUCCESS) {
            return ["#4DE68D", "#003E1A"];
        } else {
            return ["#81B6FF", "#002D6A"];
        }
    };

    return (
        <Container
            background={toastTypeColour()[0]}
            color={toastTypeColour()[1]}
            duration={duration}
        >
            {children}
        </Container>
    );
});

const createToast = (type, message, duration) => {
    const toastContainer = document.createElement("div");
    Toast.instance.appendChild(toastContainer);

    // Make sure we never exceed the maximum amount of Toasts.
    // This allows functions, or users, to spam create Toasts without running into any issues.
    if (MAX_TOASTS < Toast.instance.childNodes.length) {
        Toast.instance.removeChild(Toast.instance.childNodes[0]);
    }

    // Set a timer for the toast's eventual destruction.
    const timeoutEvent = setTimeout(() => {
        if (toastContainer !== null &&
            toastContainer.parentNode !== null &&
            toastContainer.parentNode.contains(toastContainer)
        ) {
            toastContainer.parentNode.removeChild(toastContainer);
        }
    }, duration);

    const dismissEvent = () => {
        clearTimeout(timeoutEvent);
        toastContainer.parentNode.removeChild(toastContainer);
    };

    const toastBody = () => {
        return (
            <ToastObject toastType={type} duration={duration}>
                {message}
            </ToastObject>
        );
    };

    ReactDOM.render(
        <Row onClick={dismissEvent}>
            { toastBody() }
        </Row>
        ,
        toastContainer
    );
};

class Toast {
    static instance;

    static init(width = 0, offsetX = 0) {
        let exists = document.getElementById(TOAST_ID);
        if (exists) {
            return;
        }

        Toast.instance = document.createElement("div");
        Toast.instance.id = TOAST_ID;
        /** setting z-index to 1500 due to MUI dialogs having 1300 */
        Toast.instance.style = "top: 0; left: 0; position: absolute; width: 100vw, height: 100vh; pointer-events: none; z-index: 1500 !important;";
        if (width !== 0 || offsetX !== 0) {
            Toast.instance.style = `top: 0; left: ${offsetX}px; position: absolute; max-width: ${width}px; z-index: 1500 !important; width: auto; height: 100vh; pointer-events: none;`;
        }
        document.body.appendChild(Toast.instance);
    }

    static info(message, duration = DEFAULT_DURATION) {
        // Checking to see if Toast hasn't been initialised first.
        Toast.isUnsafe(message);
        createToast(ToastType.INFO, message, duration);
    }

    static error(message, duration = DEFAULT_DURATION) {
        // Checking to see if Toast hasn't been initialised first.
        Toast.isUnsafe(message);
        createToast(ToastType.ERROR, message, duration);
    }

    static success(message, duration = DEFAULT_DURATION) {
        // Checking to see if Toast hasn't been initialised first.
        Toast.isUnsafe(message);
        createToast(ToastType.SUCCESS, message, duration);
    }

    static isUnsafe(message) {
        if (!Toast.instance || !document.getElementById(TOAST_ID)) {
            console.warn("Toast hasn't been initialised first.");
            Toast.init();
        }

        if (message) {
            if (message.length > 85) {
                console.warn("Your toast message is quite large. Consider using something other than a toast.");
            }

            if (message.length >= 95) {
                console.error("Your toast message is going to be truncated.");
                console.log("Full message: " + message);
            }
        }
    }
}

export default Toast;
