
export const NO_ROLE = 0;
export const ACCOUNT_OWNER = 1;
export const ADMIN = 2;
export const STANDARD = 3;
export const APP_MANAGER = 4;
export const WEB_MANAGER = 5;
export const MARKETING = 6;
export const FINANCE_AND_SALES = 7;
export const MULTIPLE_PERMISSIONS = 9;

export const ROLE_STYLES = {
    [NO_ROLE]: { color: "red", background: "#fff", name: "NO ROLE ASSIGNED" },
    [ACCOUNT_OWNER]: { color: "#006CFF", background: "#EAF3FF", name: "Account Owner" },
    [ADMIN]: { color: "#633FE4", background: "#EFE7FF", name: "Admin" },
    [STANDARD]: { color: "#CF5300", background: "#FFEFCA", name: "Standard" },
    [APP_MANAGER]: { color: "#fff", background: "#FF7F00", name: "App Manager" },
    [WEB_MANAGER]: { color: "#fff", background: "#633FE4", name: "Web Manager" },
    [MARKETING]: { color: "#fff", background: "#D81313", name: "Marketing" },
    [FINANCE_AND_SALES]: { color: "#626262", background: "#E8E8E8", name: "Finance And Sales" },
    [MULTIPLE_PERMISSIONS]: { color: "#892D7E", background: "#FFCEF9", name: "Multiple Permissions" }
};

export const LEADERSHIP_ROLE_STYLE = {
    color: "#26A26B", background: "#E6FFF8", name: "Leadership Role"
};