import React, { useContext, useEffect, useState } from "react";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { UserContext } from "src/scenes/App/UserContext";
import { Select, MenuItem } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { FlexContainer, Text } from "src/components";
import { ChatContext } from "../../ChatContext";
import { UserKeys } from "src/constants/userDetails";
import { MESSAGE_CLIENTS, MESSAGE_EMPLOYEES } from "src/constants/permissions";

const useStyles = makeStyles(() => ({
    outlined: {
        "&:focus": {
            outline: "none",
            background: "none"
        },
    }
}));

const selectStyle = {
    height: "2.5625rem",
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "0.875rem",
    background: "#FFFFFF",
    border: "1px solid #000000",
    color: "#4B5155",
    paddingLeft: "0.5rem",
    borderRadius: 7,
    "&:focus": {
        backgroundColor: "transparent"
    },
    "&:hover": {
        backgroundColor: "transparent",
    },
};

/**
 * @param {array} leadershipRoles
 * @param {string} chatListTypeUuid
 * @return boolean
 */
const validateLeadershipUuidPresence = (leadershipRoles, uuid) => leadershipRoles.some((item) => item.uuid === uuid); 

/**
 * @param {array} leadershipRoles
 * @param {string} selectedChatListTypeOrLeadershipUuid
 * @return string
 */
const retrieveLeadershipRoleName = (leadershipRoles, selectedChatListTypeOrLeadershipUuid) => {
    if (validateLeadershipUuidPresence(leadershipRoles, selectedChatListTypeOrLeadershipUuid)) {
        let name = leadershipRoles.find((item) => item.uuid === selectedChatListTypeOrLeadershipUuid).name;
        return name;
    }
    return "";
};

const resolveSlectedListTypeForStartChatAs = (selectedChatListTypeOrLeadershipUuid, userContext, hasAnyChatPermissions) => {
    if (hasAnyChatPermissions && !userContext.isClient) {
        switch (selectedChatListTypeOrLeadershipUuid) {
            case USER_TYPE_EMPLOYEES:
                return selectedChatListTypeOrLeadershipUuid;
            case USER_TYPE_CLIENTS:
                return USER_TYPE_EMPLOYEES;
            default:
                return selectedChatListTypeOrLeadershipUuid;
        }
    }

    if (hasAnyChatPermissions && userContext.isClient) {
        switch (selectedChatListTypeOrLeadershipUuid) {
            case USER_TYPE_EMPLOYEES:
                return USER_TYPE_CLIENTS;
            case USER_TYPE_CLIENTS:
                return USER_TYPE_CLIENTS;
            default:
                return selectedChatListTypeOrLeadershipUuid;
        }
    }
    return selectedChatListTypeOrLeadershipUuid;
};

const LeadershipRoleToUseSelection = (props) => {
    const { leadershipRoles } = useContext(UserContext);
    const userContext = useContext(UserContext);
    const { selectedChatListTypeOrLeadershipUuid } = useContext(ChatContext);
    const hasAnyChatPermission = userContext[UserKeys.PERMISSIONS].includes(MESSAGE_EMPLOYEES) || userContext[UserKeys.PERMISSIONS].includes(MESSAGE_CLIENTS);
    const chatAsEmployeesAllowed = !userContext.isClient && hasAnyChatPermission;
    const chatAsClientsAllowed = userContext.isClient && hasAnyChatPermission;

    const ifSelectedListTypeOrLeadershipUuidIsPresent = validateLeadershipUuidPresence(leadershipRoles, selectedChatListTypeOrLeadershipUuid);
    const doesUserHaveProperStartChatAsLeadershipRole = ifSelectedListTypeOrLeadershipUuidIsPresent && !selectedChatListTypeOrLeadershipUuid.includes([USER_TYPE_EMPLOYEES, USER_TYPE_CLIENTS]);

    const resolvedSelectedChatListTypeOrLeadershipUuid = doesUserHaveProperStartChatAsLeadershipRole ? retrieveLeadershipRoleName(leadershipRoles, leadershipRoles[0].uuid) : resolveSlectedListTypeForStartChatAs(selectedChatListTypeOrLeadershipUuid, userContext, hasAnyChatPermission);

    const [selectedLabel, setSelectedLabel] = useState(resolvedSelectedChatListTypeOrLeadershipUuid);
    const classes = useStyles();

    useEffect(() => {
        if (Array.isArray(leadershipRoles) && leadershipRoles.length > 0) {
            setSelectedLabel(leadershipRoles[0].name);
        }
    }, [leadershipRoles]);

    useEffect(() => {
        if (selectedChatListTypeOrLeadershipUuid && leadershipRoles) {
            selectLeadershipRole({ target: { value: resolvedSelectedChatListTypeOrLeadershipUuid } });
        }
    }, [selectedChatListTypeOrLeadershipUuid, leadershipRoles]);

    const selectLeadershipRole = (role) => {
        const sel = role.target.value;
        setSelectedLabel(sel);
        if (sel === USER_TYPE_EMPLOYEES) {
            props.changeSelectedRole();
        } else if (sel === USER_TYPE_CLIENTS) {
            props.changeSelectedRole();
        } else {
            const lrItem = leadershipRoles && leadershipRoles.find((item) => item.name === sel);
            if (lrItem) {
                props.changeSelectedRole(lrItem.uuid);
            }
        }
    };

    const renderSelectionItems = () => {
        if (leadershipRoles.length > 0) {
            return leadershipRoles.map(role =>
                <MenuItem key={role.uuid} value={role.name}
                >
                    Start chat as &quot;{ role.name }&quot;
                </MenuItem>
            );
        }
        return "";
    };

    return (
        <FlexContainer>
            <Text color="#4B5155" size="1rem" align="left" margin="0 0 0.8125rem 0" weight="700">
                Select how you will appear in this chat:
            </Text>
            <Select value={selectedLabel} variant="outlined" style={selectStyle} classes={{ outlined: classes.outlined }} placeholder={`Start chat as &quot;${selectedLabel}&quot;`} onChange={selectLeadershipRole}>
                { chatAsEmployeesAllowed && <MenuItem value={USER_TYPE_EMPLOYEES}>Start chat as &quot;Employee&quot;</MenuItem> }
                { chatAsClientsAllowed && <MenuItem value={USER_TYPE_CLIENTS}>Start chat as &quot;Client&quot;</MenuItem> }
                { renderSelectionItems() }
            </Select>
        </FlexContainer>
    );
};

export default LeadershipRoleToUseSelection;