import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../ChatContext";
import { Toast } from "src/components";


export const useSendMessage = () => {
    const { currentChat, matrixClient } = useContext(ChatContext);
    const [newMessage, setNewMessage] = useState("");
    const [queued, setQueued] = useState(null);

    useEffect(() => {
        sendMessage();
    }, [queued]);

    const prepareMessage = () => {
        const data = {
            message: newMessage.trim(),
            isSending: true,
            status: null,
        };

        setNewMessage("");
        setQueued(data);
    };

    const sendMessage = async () => {
        if (queued !== null && queued.status === null) {
            const message = queued.message;

            if (message !== null && message !== undefined && message.trim() !== "") {
                try {
                    /**
                     * this will only return the event id but;
                     * the timeline listener in `useMatrixClient` should receive the matrix event
                     * Note: the timeline listener receives a local echo which is only an acknowledgement that the message was sent,
                     * but does not confirm server-side delivery.
                     */
                    await matrixClient.sendMessage(
                        currentChat.matrixRoomId,
                        {
                            body: message,
                            msgtype: "m.room.encrypted",
                            formatted_body: "",
                            user_id: matrixClient.userId,
                            ts: Date.now()
                        }
                    );

                    setQueued(null);
                } catch (err) {
                    setQueued(prevQueued => ({
                        ...prevQueued,
                        status: "failed",
                        isSending: false
                    }));
                    setNewMessage(queued.message);

                    return Toast.error("Unable to send the message.");
                }
            }
        }
    };

    return {
        newMessage,
        queued,
        setNewMessage,
        prepareMessage,
    };
};