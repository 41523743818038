import React, { Component } from "react";
import styled from "styled-components";
import { SubscriptionContext } from "../SubscriptionContext";
import {
    Text, Button, FlexContainer,
    CardHeadingLabel, PageHeadingLabel, ExplainParagraphLabel
} from "src/components";
import {
    AUSTRALIA, SUBSCRIPTION_MONTHLY_DESCRIPTION, SUBSCRIPTION_TYPE_MONTHLY, SUBSCRIPTION_YEARLY_DESCRIPTION
} from "src/constants/subscriptions";

import { Forms } from "../Subscription";
import LeftText from "../components/LeftText";

import GreenCheck from "src/img/new/green-check.svg";
import MailIcon from "src/img/new/mail.svg";

const CheckIcon = styled.img`
    width: 2.5625rem;
    height: 2.5625rem;
`;

const StyledText = styled(Text)`
    font-weight: ${ p => p.weight || 400 };
    margin: ${ p => p.margin || 0 };
    font-size: 1rem;
    text-align: left;
`;

const MailImage = styled.img`
    width: 1.9375rem;
    height: 1.375rem;
    margin: 3.1875rem 0 0.625rem 0;
`;

class Success extends Component {
    static contextType = SubscriptionContext;

    render() {
        const { companyName, abn, country, totalNumber, currentForm, nextClick, subscriptionType } = this.context;

        return (
            <React.Fragment>
                <PageHeadingLabel>
                    { currentForm === Forms.SUCCESS ? "Account Setup Complete!" : "You Have Purchased More User Licenses!" }
                </PageHeadingLabel>
                <ExplainParagraphLabel maxWidth="42.125rem" width="100%" size="0.9375rem">
                    { currentForm === Forms.SUCCESS ? 
                        "Congratulations! You can now start to enjoy all what the Me Business App has to offer. You can now proceed to start customising your Me Business App via ‘My Me App’ or check out your Business Dashboard."
                        :
                        "Congratulations! You have additional user licenses to invite users to your Me Business app! These new licenses have been created as a new subscription which can be managed in your Account Settings."
                    }
                </ExplainParagraphLabel>
                <FlexContainer width="42.125rem" color="#ffffff" marginTop="1.125rem" minHeight="43.625rem" 
                    padding="2.5625rem 0 3.875rem 3.8125rem" style={{ borderRadius: 12 }}
                >
                    <CardHeadingLabel margin="0 0 1.9375rem 0">
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <CheckIcon src={GreenCheck} />
                            <div style={{ marginLeft: "1.0625rem" }}>Subscription Purchase Successful!</div>
                        </div>
                        
                    </CardHeadingLabel>

                    <StyledText weight="700">Business Name:</StyledText>
                    <StyledText>{ companyName }</StyledText>

                    { country === AUSTRALIA && 
                        <React.Fragment>
                            <StyledText weight="700" margin="0.9375rem 0 0 0">ABN:</StyledText>
                            <StyledText>{abn}</StyledText>
                        </React.Fragment>
                    }
                    
                    {
                        currentForm === Forms.SUCCESS &&
                            <React.Fragment>
                                <StyledText weight="700" margin="0.9375rem 0 0 0">Country:</StyledText>
                                <StyledText>{ country }</StyledText>
                            </React.Fragment>
                    }

                    <StyledText weight="700" margin="0.9375rem 0 0 0">User Licenses:</StyledText>
                    <StyledText>{ totalNumber }</StyledText>

                    <StyledText weight="700" margin="0.9375rem 0 0 0">Subscription Type:</StyledText>
                    <StyledText style={{ whiteSpace: "pre-line" }}>
                        {subscriptionType === SUBSCRIPTION_TYPE_MONTHLY 
                            ? SUBSCRIPTION_MONTHLY_DESCRIPTION
                            : SUBSCRIPTION_YEARLY_DESCRIPTION
                        }
                    </StyledText>

                    <MailImage src={MailIcon} />
                    <LeftText color="#612684" size="1rem" margin="0 0 3.75rem 0" fontWeight="500" maxWidth="26.9375rem" width="100%">
                        We sent a copy of your purchase receipt to your email inbox. Please check your email to access your receipt and keep it handy for your records.
                    </LeftText>
                    { currentForm === Forms.SUCCESS && <StyledText weight="700">Start Customising Your App!</StyledText> }
                    
                    <Button color="#ffffff"
                        backgroundColor="#8E4EB4"
                        width="11.5625rem"
                        height="2.3125rem"
                        size="0.9375rem"
                        style={{ borderRadius: 7, lineHeight: "100%" }}
                        marginTop="1.5rem"
                        onClick={nextClick}
                    >
                        { currentForm === Forms.SUCCESS ? "Go to My Me App" : "Go to Dashboard" }
                    </Button>
                </FlexContainer>
            </React.Fragment>
        );
    }
}

export default Success;