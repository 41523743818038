import React, { Component } from "react";
import styled, { css } from "styled-components";
import FilteredPngStack from "./FilteredPngStack";
import { Screens } from "src/scenes/Customise";
const FlexContainer = styled.div`
    display: flex;
    flex-direction: ${p => p.direction || "row"};
    align-items: center;
`;

const WidthContainer = styled.div`
    box-sizing: ${p => p.boxSizing};
    display: flex;
    height: ${p => p.height || "100%"};
    width: ${p => p.width};
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: ${p => p.direction};
    margin-top: ${p => p.marginTop};
    min-height: ${p => p.minHeight};
    padding: ${p => p.padding};
    position: ${p => p.position};
`;

const Dot = styled.span`
    cursor: pointer;
    height: 5px;
    width: 5px;
    margin: 0 2px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

    ${p => p.isHighlighted && css`
        height: 10px;
        width: 10px;
    `}
`;

class CustomAppIcon extends Component {
    /**
     * @param {int} currentScreen
     * @param {int[]} screenIds
     * @return {Dot[]}
     */
    renderDotsForSlides(currentScreen, screenIds) {
        return screenIds.map((id, index) =>
            <Dot key={index} isHighlighted={id === currentScreen} />
        );
    }

    render() {
        const { currentForm, imageWidth, colourPalette, assetLogoUrl, assetIconUrl } = this.props;

        return (
            <FlexContainer direction="column">

                <FlexContainer>
                    <div>
                        {/* Images container */}
                        <WidthContainer width={ imageWidth || "100%" } direction="column">
                            <WidthContainer width={"252px"} height={"512px"} direction="column" padding="40px"
                                boxSizing="border-box" position="relative" minHeight="500px">

                                <FilteredPngStack
                                    margin={"auto"}
                                    currentForm={currentForm}
                                    colourPalette={colourPalette}
                                    currentScreen={Screens.PHONE}
                                    logoUrl={assetLogoUrl}
                                    iconUrl={assetIconUrl}
                                />
                            </WidthContainer>
                        </WidthContainer>
                    </div>
                  
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default CustomAppIcon;