import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import Renewal from "./forms/Renewal";
import RenewalSuccess from "./forms/RenewalSuccess";
import {
    Toast, LoadingIndicator, PageContainer
} from "src/components";
import { SubscriptionRenewalContext } from "./SubscriptionRenewalContext";
import SubscriptionCostResponse from "src/scenes/Subscription/api/SubscriptionCostResponse";

import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import * as PERMISSIONS from "src/constants/permissions";
import { getSubscriptionExpired } from "src/utils/dates";
import { withRouter } from "react-router-dom";

export const RenewalForms = {
    RENEWAL: 1,
    RENEWAL_SUCCESS: 2,
};

class SubscriptionRenewal extends Component {
    static contextType = UserContext;
    
    state = {
        loadingCount: 0,
        currentForm: RenewalForms.RENEWAL,
        subscriptionType: "",
        totalNumber: 0,
        costing: SubscriptionCostResponse.default(),
        hasBillingPermission: false,
        isPurchasingMoreUsers: false,
        isSubscriptionExpired: false,
    };
    
    //we just provide a return url from PayPal and this local storage will help us to determine which
    //page the front-end should display to the user correctly.
    componentDidMount() {
        const userContext = this.context;
        if (this.context) {
            /** check if user has Billing permission
             * this will prevent 403s when user doesnt have billing permission */
            const hasBillingPermission = PERMISSIONS.hasPermissions([PERMISSIONS.BILLING], userContext.permissions);
            this.setState({
                hasBillingPermission: hasBillingPermission
            }, async () => {
                await this.getCompany();
                
                if (hasBillingPermission) {
                    this.loadSubscriptionCost();
                }
            });
        }
    }
    
    redirectToActive = () => {
        this.props.history.push("/active");
    };

    getCompany = async () => {
        this.setState((prevState) => {
            return { loadingCount: prevState.loadingCount + 1 };
        });

        return axios.get(`${API_URL}/company`, {
            headers: {
                Authorization: "Bearer " + this.context.token
            }
        }).then(res => {
            const isSubscriptionExpired = getSubscriptionExpired(res.data.subscriptionStart, res.data.subscriptionPeriod, true);
            
            if (!isSubscriptionExpired) {
                alert("Your account is still active, redirecting to dashboard");
                this.redirectToActive();
            }

            this.setState({ 
                totalNumber: res.data.totalNumber,
                isSubscriptionExpired: isSubscriptionExpired 
            });
        }).catch(err => {
            if (err.response) {
                if (err.response.data.error.messages) {
                    return Toast.error(err.response.data.error.messages.toString());
                }
                return Toast.error(err.response.data.error.toString());
            } else {
                return Toast.error(err.message);
            }
        }).finally(_ => {
            this.setState((prevState) => {
                return { loadingCount: prevState.loadingCount - 1 };
            });
        });
    };

    loadSubscriptionCost = async () => {
        this.setState((prevState) => {
            return { loadingCount: prevState.loadingCount + 1 };
        });
        try {
            const costing = await SubscriptionCostResponse.get(this.context.token);
            this.setState({
                costing: costing
            });
        } catch (error) {
            console.log("Failed loading subscription cost");
        } finally {
            this.setState((prevState) => {
                return { loadingCount: prevState.loadingCount - 1 };
            });
        }
    };

    reloadCosting = (costing) => {
        this.setState({ costing });
    };

    nextClick = () => {
        this.setState({
            currentForm: RenewalForms.RENEWAL_SUCCESS
        });
    };

    render() {
        const userContext = this.context;
        const { loadingCount, currentForm, totalNumber,
            subscriptionType, costing, moreUsers, isSubscriptionExpired, subscriptionEndDate } = this.state;

        if (loadingCount > 0) {
            return <LoadingIndicator />;
        }

        return (
            <PageContainer>
                <SubscriptionRenewalContext.Provider value={{
                    email: userContext.email,
                    token: userContext.token,
                    nextClick: this.nextClick,

                    //Renewal > Plan
                    totalNumber: totalNumber,
                    companyName: userContext[UserKeys.COMPANY_NAME], 
                    moreUsers: moreUsers, 
                    subscriptionEndDate: subscriptionEndDate,
                    currentForm: currentForm,
                    costing: costing,

                    //RenewalSuccess
                    abn: userContext[UserKeys.COMPANY_ABN],
                    country: userContext[UserKeys.COUNTRY_CODE],
                    subscriptionType: subscriptionType
                }}>
                    { isSubscriptionExpired && currentForm === RenewalForms.RENEWAL &&
                        <Renewal /> }
                    { currentForm === RenewalForms.RENEWAL_SUCCESS &&
                        <RenewalSuccess /> }
                </SubscriptionRenewalContext.Provider>
            </PageContainer>
        );
    }
}

export default withRouter(SubscriptionRenewal);