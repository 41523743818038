import React from "react";
import { useContext } from "react";
import { ChatContext } from "src/scenes/Chat/ChatContext";
import { doesObjectPropertyExist } from "src/utils/helpers";
import styled from "styled-components";

const IsTyping = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 1.75rem;
    background: #DBE5ED;
    border-radius: 0.625rem 0.625rem 0.625rem 0;
    font-size: 0.8125rem;
    font-weight: 400;
    color: #7B8793;
    padding: 0 1.25rem;
    box-sizing: border-box;
    margin-top: 1.25rem;
`;

export const IsTypingBox = () => {
    const { currentChat, isTyping, typingRoomId, typingMember, matrixClient } = useContext(ChatContext);

    const isCurrentUserTyping = (matrixClient, typingMember) => {
        return typingMember !== null && doesObjectPropertyExist(typingMember, "userId")
            ? matrixClient.getUserId() === typingMember.userId : false;
    };

    const showTypingUserName = (typingMember) => {
        if (typingMember === null || !currentChat || !currentChat.users)
            return "";
        
        const typingUser = currentChat.users.find(user => user.matrixUserId === typingMember.userId);
        
        if (!typingUser)
            return "";

        return typingUser.employeeDetails.firstName + " " + typingUser.employeeDetails.lastName;
    };

    return (
        <React.Fragment>
            { (isTyping && currentChat.matrixRoomId === typingRoomId && !isCurrentUserTyping(matrixClient, typingMember)) && 
                <IsTyping>
                    { showTypingUserName(typingMember) } is typing...
                </IsTyping>
            }
        </React.Fragment>
    );
};