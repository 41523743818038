import axios from "axios";
import { API_URL } from "src/scenes/App";
import { GROUP_CHAT } from "src/constants/chat";

/**
 * Custom hook for creating a chat
 * @returns {{
 *      isCreatingChat: boolean
 *      createChatHook: Function
 * }}
 */
export const useCreateChat = () => {
    const createChat = async (formData, chatToCreate, headers) => {
        const isGroupChat = chatToCreate.chatType === GROUP_CHAT;
        try {
            const url = `${API_URL}/chats/create/${isGroupChat ? "group" : "one"}`;
            const response = await axios.post(url, formData, headers);

            return response.data;
        } catch (error) {
            throw error;
        }
    };
    return {
        createChatHook: createChat
    };
};