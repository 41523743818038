import styled from "styled-components";
import React from "react";
import LottieAnimation from "./LottieAnimation";
import loading from "./assets/loading";

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: ${p => p.containerHeight || "8rem" };
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;


export function LottieLoadingIndicator(props) {
    return (
        <Container {...props} >
            <LottieAnimation animationJsonData={loading} {...props} />
        </Container>
    );
}

export default LottieLoadingIndicator;