import React, { useContext } from "react";
import { Text } from "src/components";
import { StartChatButton } from "../../styled/chatMessagesStyled";
import { CREATE_GROUP_CHAT, isChatActionAllowed } from "src/constants/permissions";
import GroupChatIcon from "src/img/new/new-group-chat.svg";
import SingleChatIcon from "src/img/new/new-1-on-1-chat.svg";
import { GROUP_CHAT, ONE_ON_ONE_CHAT } from "src/constants/chat";
import { ChatContext } from "../../../ChatContext";

const NoActiveChats = (props) => {
    const { showCreateChatButton, showCreateChatModalOrShowLeadershipEmployees, permissions } = props;
    const {
        selectedChatListTypeOrLeadershipUuid,
    } = useContext(ChatContext);

    return (
        <>
            <Text color="#4B5155" size="1.125rem" weight="700" margin="12rem 0 0 0" >No Active Chats</Text>
            <StartChatButton icon={SingleChatIcon} text="Start 1-on-1 Chat" 
                iconWidth="1.5rem" iconHeight="1.3rem !important"
                iconMargin="0 7px"
                margin="10rem 0 0.5rem 0"
                isAllowed={showCreateChatButton}
                onClick={() => showCreateChatModalOrShowLeadershipEmployees(ONE_ON_ONE_CHAT, selectedChatListTypeOrLeadershipUuid)}
            />
            <StartChatButton icon={GroupChatIcon} text="Start Group Chat" 
                iconWidth="1.5rem" iconHeight="1.3rem !important"
                iconMargin="0 7px"
                isAllowed={isChatActionAllowed(CREATE_GROUP_CHAT, permissions) && showCreateChatButton}
                onClick={() => showCreateChatModalOrShowLeadershipEmployees(GROUP_CHAT, selectedChatListTypeOrLeadershipUuid)}
            />
        </>
    );
};

export default NoActiveChats;