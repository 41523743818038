import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import styled from "styled-components";
import DropZone from "react-dropzone";
import {
    Text, Button, FlexContainer, BackgroundContainer, LoadingIndicator, TextLabel, Toast, BrowseFileButton
} from "src/components";

import UploadIconImg from "src/img/UploadIcon.png";
import { CustomiseContext } from "../CustomiseContext";
import { Forms, Screens } from "src/scenes/Customise";
import CustomAppIcon from "../components/CustomAppIcon";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    font-family: Roboto, Helvetica, sans-serif;
    width: 100%;
`;

const UploadContainer = styled.div`
`;

const PreviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const UploadSize = {
    width: 391,
    height: 102,
    marginBottom: "1.5em",
    marginTop: "1.5em"
};

export const PhoneContainer = styled.div`
    align-items: start;
    display: flex;
    flex-shrink: 0;
    margin-top: 4rem;
    width: 45%;
    justify-content: center;
    margin-left: 8%;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

class UploadIcon extends Component {
    state = {
        dropzoneActive: false,
        maxSize: 5242880, //5MB

        isUploading: false,
        timestamp: "",
        uploadPercentage: 0,

        errorMessage: "",
    };

    static contextType = CustomiseContext;

    /**
     * File dropped into logo drop zone, validate file type and upload to S3
     * @param droppedFiles {Array<File>}
     */
    onDrop = (droppedFiles) => {
        const { token } = this.context;

        // If the drop zone receives anything other than the accepted formats, or anything greater
        // than the max file size specified, the droppedFiles array will be empty.
        if (droppedFiles.length === 0) {
            this.setState({
                errorMessage: "Your logo must be of the PNG or JPEG format, and less than 5MB in size."
            });
            return;
        }

        // Take first file if multiple provided and use that
        let fileToUpload = droppedFiles[0];
        let fileExtension = fileToUpload.name.split(".").pop();
        this.setState({
            errorMessage: ""
        });

        axios.get(`${API_URL}/company/generateAppIconUrl/${fileExtension}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            const fullUrl = res.data.url;
            const storedUrl = fullUrl.substring(0, fullUrl.indexOf("?"));

            //set preview of the updated file to the props
            this.context.uploadFile("iconFile", droppedFiles.map(oneFile => ({
                ...oneFile,
                preview: URL.createObjectURL(oneFile)
            })));

            //set parameters applied in S3 updating
            const parameter = {
                headers: {
                    "Content-Type": fileToUpload.type
                },

                //set current updating percentage to S3 of the file and used to display on screen
                onUploadProgress: progressEvent => {
                    let uploadPercent = (progressEvent.loaded / progressEvent.total) * 100;
                    //get current time and add it to the end of logo url in order to guarantee the file can be
                    //auto refreshed when a new logo is updated due to the updated logo's url is the same
                    let currentTime = new Date().getTime();

                    this.setState({
                        isUploading: true,
                        timestamp: currentTime,
                        uploadPercentage: uploadPercent,
                    });
                }
            };

            //send updated file to the pre-signed url
            axios.put(fullUrl, fileToUpload, parameter)
                .then(result => {
                    this.context.nextIconAvailable(storedUrl);
                })
                .catch(err => {
                    return Toast.error(err.message);
                });
        }).catch(error => {
            return Toast.error(error.message);
        });
    };

    clickNext = () => {
        if (this.context.isNextClickable)
            this.context.nextClick();
    };

    render() {
        const { maxSize, isUploading, uploadPercentage, errorMessage, timestamp } = this.state;
        const { iconFile, assetLogoUrl, assetIconUrl, companyName, currentScreen, currentForm, screenIds, colourPalette,
            leftScreen, rightScreen } = this.context;

        return (
            <>
                <Container>
                    <div>
                        <TextLabel weight="700" size="1.875em" color="#2A2B2A" margin="2rem 0 0.5rem" flexGrow="1">
                            Step 3: Custom App Icon (Optional)
                        </TextLabel>

                        <FlexContainer style={{ flexShrink: 0 }}>
                            <Text size="1em" align="left">Empower your brand identity by uploading your custom app icon for your Me Business app.</Text>

                            <Text size="1.25em" align="left" weight="700">Upload Your App Icon:</Text>

                            <DropZone
                                accept="image/png"
                                maxSize={maxSize}
                                onDrop={this.onDrop.bind(this)}
                                style={UploadSize}
                            >
                                <UploadContainer>
                                    {isUploading ?
                                        <>
                                            <PreviewContainer>
                                                {uploadPercentage === 100 ?
                                                    <><FlexContainer alignItems="center" width="120px" height="120px" justifyContent="start">
                                                        <BackgroundContainer style={{ border: "1px solid #000" }}>
                                                            <img style={{ maxWidth: "120px", maxHeight: "120px" }} src={iconFile[0].preview} alt={"Uploaded Icon File"} />
                                                        </BackgroundContainer>
                                                    </FlexContainer><ImageFormat /></>
                                                    :
                                                    <FlexContainer width="405px" height="102px">
                                                        <Text color="black">
                                                        Uploading - {uploadPercentage}%
                                                        </Text>
                                                        <div style={{ marginLeft: "50px" }}>
                                                            <svg width="391" height="10">
                                                                <rect width="391" height="10" fill="#566cad" rx="0" ry="0"></rect>
                                                                <rect width={3 * uploadPercentage} height="10" fill="#f9db5e" rx="0" ry="0"></rect>
                                                            </svg>
                                                        </div>
                                                    </FlexContainer>
                                                }
                                            
                                            </PreviewContainer>
                                        </>
                                        :
                                        <>
                                            <PreviewContainer>
                                                <>
                                                    {iconFile.length === 0 && (assetIconUrl === "" || assetIconUrl === null || assetIconUrl === undefined) ?
                                                        <img src={UploadIconImg} alt="UploadBackground" width="120px" /> :
                                                        <FlexContainer width="120px" height="120px" alignItems="center" justifyContent="start">
                                                            <BackgroundContainer style={{ border: "1px solid #000" }}>
                                                                {iconFile.length === 0 ?
                                                                    <img style={{ maxWidth: "120px", maxHeight: "120px" }} src={assetIconUrl} alt={"Asset Icon Url"} />
                                                                    :
                                                                    <img style={{ maxWidth: "120px", maxHeight: "120px" }} src={iconFile[0].preview} alt={"Icon File"} />
                                                                }
                                                            </BackgroundContainer>
                                                        </FlexContainer>
                                                    }
                                                </>
                                                <ImageFormat />
                                            </PreviewContainer>
                                        </>
                                    }
                                    
                                </UploadContainer>
                            </DropZone>
                                
                            <Text align="left">
                                <BrowseFileButton onChange={(files) => this.onDrop(files)}>
                                    Browse File
                                </BrowseFileButton>
                            </Text>

                            <Text color="#8E4EB4" align="left" size="0.9375em">
                                *Your app icon will become available after we submit it to the app store. 
                                It will only be shown to users in your company. 
                                Ensure that you update your app or turn on automatic updates to display your custom app icon.
                            </Text>

                            <hr style={{ width: "100%", marginTop: "2em" }}/>

                            <Text align="left" size="1.0000em">
                                Click <b>Finish</b> to complete customisation. You can make additional changes as required by selecting <b>‘My Me App’</b> on the side menu.
                            </Text>
                            
                            <ButtonsContainer>
                                <Button color="#000" backgroundColor="transparent" borderRadius="10px !important" border="1px solid black"
                                    width="112px" onClick={() => this.context.previousClick(Forms.COLOR, Screens.MAIN) }>
                                    Back
                                </Button>
                                    
                                {this.context.isFinishing && <div style={{ marginLeft: "2.5rem" }}><LoadingIndicator containerHeight="0rem" height="4em" width="6em" style={{ width: "4em" }} /></div>}
                                {!this.context.isFinishing && <Button color="#FFFFFF" backgroundColor="#000" borderRadius="10px !important" border="1px solid black"
                                    marginLeft="20px" width="112px" onClick={ this.clickNext } >
                                    Finish
                                </Button>}
                            
                            </ButtonsContainer>

                            <Text color="red">
                                {errorMessage}
                            </Text>
                        </FlexContainer>
                    </div>
                    <PhoneContainer>
                        <CustomAppIcon
                            companyName={companyName}
                            logoTopOffset={45}
                            phoneFrameTopOffset={30}
                            currentScreen={currentScreen}
                            currentForm={currentForm}
                            screenIds={screenIds}
                            leftScreen={leftScreen}
                            rightScreen={rightScreen}
                            colourPalette={colourPalette}
                            assetLogoUrl={assetLogoUrl + "?" + timestamp} 
                            assetIconUrl={assetIconUrl + "?" + timestamp} />
                    </PhoneContainer>
                </Container>
            </>
        );
    }
}

class ImageFormat extends Component {
    render() {
        return (
            <Text align="left" color="#8E4EB4" size="0.9375em" margin="0 0 0 10px">
                <b>Image Format: </b><br/>
                <b>PNG File</b> - 1024 x 1024 px
            </Text>
        );
    }
}

export default UploadIcon;