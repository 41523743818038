import React, { Component } from "react";
import FlexContainer from "./FlexContainer";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import styled from "styled-components";
import Footer from "./Footer";
import { SetupPasswordContainerContext } from "./SetupPasswordContainerContext";
import LogoImg2 from "../img/new/me_logo.svg";
import Text from "./Text";

/**
 * Class: LicenseHeader Styled components
 *  */ 
const Container = styled.header`
background: ${p => p.url ? `url(${p.url}) no-repeat center ${p.location || "top"}` : null};
background-color: ${p => p.transparent ? "transparent" : p.backgroundColor };
flex: 0;
top: ${p => p.top};
transition: background-color 0.3s;
width: 100%;
`;

const Content = styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: center;
padding: 24px 0px;
width: 100%;
text-align: center;
`;

const Logo = styled.div`

`;

class SetupPasswordContainer extends Component {
    state = {
        fetching: 0,
        accessCode: "",
        businessName: "",
        isValidShortTermToken: false,
    };

    componentDidMount() {
        this.setState(state => ({
            fetching: state.fetching + 1
        }), () => this.safeToInit());
    }
    
    safeToInit = () => {
        const shortTermToken = this.props.match.params.shortTermToken;

        axios.get(`${API_URL}/validateShortTermKey/${shortTermToken}`)
            .then(res => {
                const validateShortTermKeyResponse = res.data;

                this.setState(({
                    isValidShortTermToken: true,
                    accessCode: validateShortTermKeyResponse.accessToken,
                    businessName: validateShortTermKeyResponse.companyName
                }));
            })
            .catch(error => console.log(error)) // Removes Sentry errors when short term key is invalid.
            .finally(() => {
                this.setState(state => ({
                    fetching: state.fetching - 1,
                }));
            });
    };

    imageContainer = (imageUrl) => {
        return (
            <FlexContainer direction="row" alignItems="center">
                <img src={imageUrl} alt={"Company Logo"} style={{ height: "29px", objectFit: "contain" }} />
            </FlexContainer>
        );
    };

    render() {
        const { children } = this.props;

        if (this.state.fetching > 0) {
            return (
                <FlexContainer style={{ margin: "auto", width: "40vw" }}>
                    <Text size="30px" color="#000" weight="500">
                        Setup Password
                    </Text>
    
                    <Text margin="10px" size="16px" color="#000" weight="500">
                        Please wait while we connect you to your account...
                    </Text>
                </FlexContainer>
            );
        }

        return (
            <FlexContainer alignItems="center" minHeight="100vh" style={{ backgroundColor: "#404df3" }}>
                
                <CenterHeader logoImg={this.imageContainer(LogoImg2)} backgroundColor={"#000"} />

                { !this.state.isValidShortTermToken && <TokenExpired /> }

                { this.state.isValidShortTermToken &&
                    <SetupPasswordContainerContext.Provider value={{
                        accessCode: this.state.accessCode,
                        businessName: this.state.businessName,
                        shortTermToken: this.props.match.params.shortTermToken,
                    }}>
                        {children}
                    </SetupPasswordContainerContext.Provider>
                }

                <Footer />

            </FlexContainer>
        );
    }
}

class CenterHeader extends Component {
    render() {
        const { top, url, transparent, logoImg, backgroundColor } = this.props;

        return (
            <Container transparent={transparent} top={top} url={url} backgroundColor={backgroundColor}>
                <Content>
                    <Logo>
                        {logoImg}
                    </Logo>
                </Content>
            </Container>
        );
    }
}

class TokenExpired extends Component {
    render() {
        return (
            <FlexContainer style={{ margin: "auto" }} family="Roboto, Helvetica, sans-serif">
                <Text size="30px" color="#FFF" weight="500">
                    Link Expired
                </Text>

                <Text margin="10px" size="16px" color="#FFF" weight="500">
                    The link is no longer valid.
                </Text>
            </FlexContainer>
        );
    }
}

export default SetupPasswordContainer;