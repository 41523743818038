import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1rem 0 3.5rem;
    flex-grow: 1;
`;

/**
 * A container to display content to the right of the nav bar while logged in
 * @return {JSX.Element}
 */
export function PageContainer(props) {
    const { children, ...styleProps } = props;
    return (
        <Container {...styleProps}>
            {children}
        </Container>
    );
}
