import React from "react";
import styled from "styled-components";
import { mobile } from "./Media";

const StyledDiv = styled.div`
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
`;

const StyledText = styled(StyledDiv)`
    font-size: ${p => p.size || "14px"};
    text-align: ${p => p.align || "center"};
    color: ${p => p.color || "black"};
    padding: ${p => p.padding};
    border-bottom: ${p => p.borderBottom || "none"};
    width: ${p => p.width};
    margin: ${p => p.margin};
    line-height: ${p => p.lineHeight};
    box-sizing: ${p => p.boxSizing};
    font-family: ${p => p.family || "Roboto, Helvetica, sans-serif"};
    font-weight: ${p => p.weight || "normal"};
    min-width: ${p => p.minWidth};
    display: ${p => p.display};
    flex-direction: ${p => p.flexDirection || ""};
    justify-content: ${p => p.justifyContent || ""};
    max-width: ${p => p.maxWidth};
    height: ${p => p.height};
    position: ${p => p.position};
    text-transform: ${p => p.transform};
    outline: ${p => p.outline};
    text-decoration: ${p => p.decoration};
    text-shadow: ${p => p.shadow};
    white-space: ${p => p.noWrap && "nowrap"};
    text-overflow: ${p => p.textOverflow || ""};
    overflow: ${p => p.overflow || ""};

    ${mobile`
        width: ${p => p.mWidth || "100%"};
        font-size: ${p => p.mSize};
    `};

`;

function Text(props) {
    const { children, ...styleProps } = props;
    return (
        <StyledText {...styleProps}>
            {children}
        </StyledText>
    );
}

export default Text;