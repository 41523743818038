import React, { useState } from "react";
import { Dialog, Box, Divider } from "@material-ui/core/";
import styled from "styled-components";
import { Button, Text, LoadingIndicator } from "src/components";
import MultipleInvitesForm from "./MultipleInvitesForm";
import SingleInviteForm from "./SingleInviteForm";
import AppInviteMethod from "src/components/AppInviteMethod";

const StyledText = styled(Text)`
    font-family: Roboto, Helvetica, sans-serif;
    text-align: left;
    padding: ${p => p.padding || 0};
    margin: ${p => p.margin || 0};
`;

const SendInviteFormModal = (props) => {
    const { showDialog, handleClose, users, sendInvites } = props;
    const [selectedMethod, setSelectedMethod] = useState("email");
    const [isLoading, setIsLoading] = useState(false);

    const handleSwitchMethod = (value) => {
        setSelectedMethod(value);
    };

    const handleFormSubmit = () => {
        setIsLoading(true);
        const data = {
            users: users,
            method: selectedMethod
        };
        sendInvites(data);
    };

    return (
        <Dialog open={showDialog} onClose={handleClose}
            maxWidth="lg"
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    width: "72rem",
                    height: "auto",
                    minHeight: "37.5rem",
                    borderRadius: 15,
                    overflow: "auto",
                },
            }}
        >
            { isLoading ? <div style={{ minHeight: "37.5rem", display: "flex", justifyContent: "center", alignItems: "center" }}><LoadingIndicator /></div>
                :
                <React.Fragment>
                    <Box sx={{ margin: "3.4375rem 2.3125rem 1.40625rem 3.5625rem" }}>
                        <StyledText color="#11141A" size="2.5rem" weight="700">Send App Invite</StyledText>
                        <StyledText color="#000000" size="14px" margin="9px 0 0 0">
                            Please choose your preferred method of sending the app invitation to the designated user(s).
                        </StyledText>
                    </Box>
                    <Divider />
                    { users.length > 1 ? <MultipleInvitesForm users={props.users}/>
                        : <SingleInviteForm user={props.users[0]}/>
                    }
                    <Divider />

                    <Box sx={{ margin: "1.4375rem 3.5rem" }}>
                        <AppInviteMethod setAppInviteMethod={handleSwitchMethod} />
                    </Box>

                    <Divider />
                    <Box sx={{ 
                        margin: "0.875rem 3.5625rem 2.75rem 3.5625rem", 
                        display: "flex", justifyContent: "space-between", alignItems: "center"
                    }}
                    >
                        <Text color="#808080" size="14px" align="left" family="Roboto, Helvetica, sans-serif">
                            Kindly be advised that email invitations may be directed to the <br />
                            spam folder. It’s recommended to notify users in the event they do <br />
                            not receive the invitation.
                        </Text>
                        <div>
                            
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button 
                                border="1px solid #000"
                                color="#000"
                                marginRight="15px"
                                width="8.75rem"
                                onClick={handleClose}
                                style={{ borderRadius: "7px", boxShadow: "none", fontFamily: "Roboto, Helvetica, sans-serif" }}
                                className="form-btns disabled"
                            >
                                Cancel
                            </Button>
                            <Button backgroundColor="#006CFF" color="#fff"
                                border="1px solid #006CFF"
                                width="8.75rem"
                                style={{ borderRadius: "7px", boxShadow: "none", fontFamily: "Roboto, Helvetica, sans-serif" }}
                                type="submit"
                                onClick={() => handleFormSubmit()}
                                className="form-btns disabled"
                            >
                                Send Invite
                            </Button>
                        </div>
                    </Box>
                </React.Fragment>

            }
            
        </Dialog>
    );
};

export default SendInviteFormModal;