import { Text } from "src/components";
import styled from "styled-components";

export const StyledText = styled(Text)`
    font-weight: ${ p => p.weight || 400 };
    color: ${ p => p.color || "#000000" };
    text-align: left;
    font-size: ${ p => p.size };
    margin: ${ p => p.margin || "1px" };
    text-align: ${ p => p.align || "left"};
`;

export const MainContainer = styled.div`
    background: #FFF;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    margin: 1.5rem 0 0 0;
    overflow-x: auto;

    ::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.5rem;
        border-radius: 0.375rem;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #DBE5ED;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track {
        background: #F9FAFC;
        margin-left: 1.875rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
    }
`;