import React, { useState, useEffect, useRef } from "react";
import DownArrow from "src/img/down_arrow.svg";
import styled from "styled-components";

const ActionsButton = styled.div`
    position: relative;
    background: #000000;
    color: #ffffff;
    border-radius: 0.6rem;
    width: 7rem;
    height: 2.5rem;
    text-align: center;
    line-height: 2.4rem;
    float: right;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
`;

const Icon = styled.img`
    width: 0.55rem;
    height: 0.5rem;
    margin: 0 0 0.1rem 0.5rem;
`;

function PaymentMethodCardActions({ item, setDefault, deleteItem }) {
    const [hidden, setHidden] = useState(true);
    const setDefaultRef = useRef(null);
    const deleteRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if ((setDefaultRef.current && !setDefaultRef.current.contains(event.target)) && (deleteRef.current && !deleteRef.current.contains(event.target))) {
                setHidden(true);
            }
        };

        hidden === false && document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    const handleClick = () => {
        setHidden(!hidden);
    };

    const handleSetDefault = () => {
        setDefault(item);
    };

    const handleDelete = () => {
        deleteItem(item);
    };

    return (
        <>
            <ActionsButton onClick={handleClick}>Actions<Icon src={DownArrow} /></ActionsButton>
            {!hidden &&
            <div style={{ position: "relative" }}>
                <div style={{ width: "9.375rem", border: "1px solid #E9EBEF", borderRadius: "8px", padding: "1em", fontSize: "14px", fontWeight: "500", backgroundColor: "#FFF", position: "absolute", top: "2.5rem", right: "0rem", zIndex: "1" }}>
                    <div ref={setDefaultRef} style={{ paddingBottom: "0.5em", cursor: "pointer" }} onClick={handleSetDefault}>Set As Default</div>
                    <div ref={deleteRef} style={{ paddingTop: "0.5em", borderTop: "1px solid #E9EBEF", cursor: "pointer" }} onClick={handleDelete}>Delete</div>
                </div>
            </div>
            }
        </>
    );
}

export default PaymentMethodCardActions;