import React, { Component } from "react";
import styled from "styled-components";
import {
    Text, FormField, Button
} from "src/components";
export const ErrorList = {
    UNKNOWN: 0,
    EMPTY_PASSWORD: 1,
    EMPTY_EMAIL: 2,
    EMPTY_CONFIRM_EMAIL: 3,
    CONFIRM_EMAIL_MISMATCHED: 4,
    EMAIL_INVALID_FORMAT: 5,
    PASSWORD_INVALID: 6,
    EMAIL_INVALID: 7,
};

export let ErrorStrings = {
    
    [ErrorList.UNKNOWN]: "* Error unknown. Please contact support@memotivationapp.com",
    [ErrorList.EMPTY_PASSWORD]: "* Please enter your password",
    [ErrorList.EMPTY_EMAIL]: "* Please enter new account email",
    [ErrorList.EMPTY_CONFIRM_EMAIL]: "* Please confirm your new account email",
    [ErrorList.CONFIRM_EMAIL_MISMATCHED]: "* Please make sure the email addresses match",
    [ErrorList.EMAIL_INVALID_FORMAT]: "* Please enter a valid email format",
    [ErrorList.PASSWORD_INVALID]: "",
    [ErrorList.EMAIL_INVALID]: "",
};

export const CancelButton = styled(Button)`
    background: #fff;
    color: #000;
    border: 2px solid #000;
    border-radius: 10px !important;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
    padding: 0 !important;
    margin-right: 1em;
`;

export const SaveButton = styled(Button)`
    background: #000000;
    color: #ffffff;
    border-radius: 10px !important;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
`;

class Password extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_PASSWORD, ErrorList.PASSWORD_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="14px" weight="bolder" margin="0.5em 0">
                        {isEditable ? "Enter Your Password" : "Password"}
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="password"
                        placeholder={!placeholderDisabled ? "Enter Your Password Here" : undefined }
                        onChange={(event) => {onChangeFormData(event, "password");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.password}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class Email extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_EMAIL, ErrorList.EMAIL_INVALID_FORMAT, ErrorList.EMAIL_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="14px" weight="bolder" margin="0.5em 0">
                        {isEditable ? "Enter New Account Email" : "Account Email"}
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled ? "Enter New Account Email Here" : undefined }
                        onChange={(event) => {onChangeFormData(event, "email");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.email}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class ConfirmEmail extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_CONFIRM_EMAIL, ErrorList.CONFIRM_EMAIL_MISMATCHED);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="14px" weight="bolder" margin="0.5em 0">
                    Confirm New Account Email
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled ? "Enter Confirm New Account Email Here" : undefined }
                        onChange={(event) => {onChangeFormData(event, "confirmEmail");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.confirmEmail}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class AccessCode extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings([]);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="14px" weight="bolder" margin="0.5em 0">
                    Access Code
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled ? "Enter Access Code" : undefined }
                        onChange={(event) => {onChangeFormData(event, "accessCode");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.accessCode}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

export {
    Password, 
    Email, 
    ConfirmEmail,
    AccessCode
};

