import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import { API_URL } from "src/scenes/App";
import {
    FlexContainer, CardList, LoadingIndicator,
    Toast, TwoColumnLayout, CardHeadingLabel, ExplainParagraphLabel, PageHeadingLabel
} from "src/components";

import Price from "../components/Price";
import SubscriptionOverview from "../components/SubscriptionOverview";
import "@trendmicro/react-radio/dist/react-radio.css";
import { SubscriptionContext } from "../SubscriptionContext";
import { Forms } from "../Subscription";
import AirwallexForm, { AirwallexProduct } from "src/scenes/Subscription/components/AirwallexForm";
import GreenCheck from "src/img/new/green-check.svg";
import CircleIcon from "src/img/new/circle-regular-24.png";

export const PaymentSelectionButton = styled.button`
    position: relative;
    width: ${p => p.width};
    margin: ${p => p.margin};
    background: ${p => p.background};
    border: 1px solid #000000;
    border-radius: 7px;
    height: 2.375rem;
    font-size: 0.875rem;
    max-width: ${p => p.maxWidth};
    color: ${p => p.color};
    font-weight: 500;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.75rem;
    cursor: pointer;
`;

export const PaymentSelectionIcon = styled.img`
    width: 19px;
    height: 19px;
    margin-right: 0.5625rem;
`;

export const Methods = {
    "EXISTING": "Existing Card",
    "NEW": "New Card"
};

const Screens = {
    OVERVIEW: "Overview",
    PAYMENT: "Payment"
};

class Review extends Component {
    state = {
        loading: false,
        method: Methods.EXISTING,
        screen: Screens.OVERVIEW
    };

    static contextType = SubscriptionContext;

    /**
     *  Returns a Promise that is being used to `await` in CardList.js thisPurchaseClicked()
    */
    purchaseMore = (selectedMethod, methodSource) => {
        if (methodSource === "square") {
            return this.purchaseWithOurApi(
                `${API_URL}/company/square/more`,
                {
                    numOfUsers: this.context.moreUsers,
                    cardId: selectedMethod
                });
        } else if (methodSource === "airwallex") {
            return this.purchaseWithOurApi(
                `${API_URL}/company/airwallex/purchaseNow/moreUsers`,
                {
                    numberOfUsers: this.context.moreUsers,
                    paymentMethodId: selectedMethod
                });
        } else {
            console.log(`Bad method source: ${methodSource}`);
        }
    };

    purchaseWithOurApi = async (url, requestBody) => {
        const { token, nextClick } = this.context;
        this.setState({
            loading: true
        });
        axios.post(url, requestBody, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            this.setState({
                loading: false
            });
            nextClick();
        }).catch(error => {
            this.setState({
                loading: false
            });
            if (error.response) {
                if (error.response.data.error.messages) {
                    return Toast.error(error.response.data.error.messages.toString());
                }
                return Toast.error(error.response.data.error.toString());
            } else {
                return Toast.error(error.message);
            }
        });
    };

    updateMethod = (value) => {
        this.setState({
            method: value
        });
    };

    changeScreen = (screen) => {
        this.setState({ screen: screen });
    };

    render() {
        //add method and updateValue when using radio buttons back
        const { totalNumber, companyName, abn, website,
            moreUsers, backToPrevious, subscriptionEndDate, email,
            currentForm, token, nextClick, setUserPermissions } = this.context;

        const { method, screen } = this.state;

        if (this.state.loading) {
            return <LoadingIndicator />;
        }

        const updatePaymentAndPurchase = () => {
            nextClick();
        };

        return (
            <React.Fragment>
                { screen === Screens.OVERVIEW ? 
                    <SubscriptionOverview 
                        totalNumber={currentForm === Forms.REVIEW ? totalNumber : moreUsers}
                        goToPayment={() => this.changeScreen(Screens.PAYMENT)}
                    />
                    :
                    <React.Fragment>
                        <PageHeadingLabel>
                            { currentForm === Forms.REVIEW ? "Subscription Payment: Step 5" : "Purchase More User Licenses" }
                        </PageHeadingLabel>
                        <ExplainParagraphLabel maxWidth="43.125rem" width="100%" size="0.9375rem">
                            { currentForm === Forms.REVIEW ?
                                "Enter a payment method for your business subscription and select Pay Now to complete setup. Your payment method will be used for subscription renewals. You can always add a new payment method via your Account Settings."
                                :
                                "Select an existing payment method or add a new payment method for this subscription."

                            }
                        </ExplainParagraphLabel>
                        <TwoColumnLayout marginTop="2rem" height="auto" minHeight="43.625rem"
                            leftMaxWidth="28.4375rem" rightMaxWidth="29.75rem"
                            leftContainerWidth="100%" rightContainerWidth="100%" 
                            leftContainerPadding="2.8125rem 1.06rem"
                            rightContainerPadding="2.8125rem 1.6875rem 1rem 2.125rem"
                        >
                            <Price
                                Forms={Forms}
                                currentForm={currentForm}
                                companyName={companyName}
                                totalNumber={currentForm === Forms.REVIEW ? totalNumber : moreUsers}
                                subscriptionEndDate={subscriptionEndDate}
                                goToOverview={() => this.changeScreen(Screens.OVERVIEW)}
                            />
                            <FlexContainer justifyContent="start" height="auto">
                                <CardHeadingLabel>
                                    { currentForm === Forms.REVIEW ? "Confirm Payment" : "Payment Method" }
                                </CardHeadingLabel>
                                { currentForm === Forms.REVIEW ?
                                    <FlexContainer direction="column" flexGrow="1">
                                        <AirwallexForm
                                            token={token} email={email}
                                            productType={AirwallexProduct.INITIAL}
                                            companyName={companyName} abn={abn} website={website} numberOfUsers={totalNumber}
                                            setUserPermissions={setUserPermissions}
                                            backToPrevious={backToPrevious}
                                            nextClick={nextClick} />
                                    </FlexContainer>
                                    :
                                    <FlexContainer direction="column" margin="1rem 0 0">
                                        <FlexContainer direction="row" position="relative">
                                            <PaymentSelectionButton 
                                                maxWidth="16rem" width="100%"
                                                background={method === Methods.EXISTING ? "#000" : "#FFF"}
                                                margin="0 0.375rem 1rem 0"
                                                color={method === Methods.EXISTING ? "#FFF" : "#000"}
                                                onClick={() => this.updateMethod(Methods.EXISTING)}
                                            >

                                                <PaymentSelectionIcon 
                                                    src={method === Methods.EXISTING ? GreenCheck : CircleIcon}
                                                />
                                                
                                                Use an existing credit/debit card
                                            </PaymentSelectionButton>
                                            <PaymentSelectionButton
                                                width="100%"
                                                maxWidth="9.6rem"
                                                margin="0 0 1.625rem 0"
                                                background={method === Methods.NEW ? "#000" : "#FFF"}
                                                color={method === Methods.NEW ? "#FFF" : "#000"}
                                                onClick={() => this.updateMethod(Methods.NEW)}
                                            >
                                                <PaymentSelectionIcon 
                                                    src={method === Methods.NEW ? GreenCheck : CircleIcon}
                                                />
                                                Add a new card
                                            </PaymentSelectionButton>
                                        </FlexContainer>
                                        { this.state.method === Methods.NEW ?
                                            <FlexContainer align="start" direction="column" flexGrow="1">
                                                <AirwallexForm
                                                    token={token} email={email}
                                                    productType={AirwallexProduct.ADD_USERS}
                                                    companyName={companyName} numberOfUsers={moreUsers}
                                                    backToPrevious={backToPrevious}
                                                    nextClick={updatePaymentAndPurchase} />
                                            </FlexContainer>
                                            :
                                            <CardList
                                                token={token}
                                                onBackClicked={() => backToPrevious()}
                                                onPurchaseClicked={this.purchaseMore}
                                                onDeleteClicked={null}
                                            />
                                        }
                                    </FlexContainer>
                                }
                            </FlexContainer>
                        </TwoColumnLayout>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default Review;