import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import styled from "styled-components";
import {
    FlexContainer, FormField, Button, Text, LoadingIndicator, ErrorLabel
} from "src/components";

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Roboto, Helvetica, sans-serif;
`;

const ToLogin = styled(Link)`
    background: #000;
    color: #fff;
    font-size: 0.875rem;
    margin-top: 6.625rem;
    font-family: Roboto, Helvetica, sans-serif;
    width: 8.5rem;
    height: 2.5rem;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
`;

const Errors = {
    INVALID_EMAIL: "*Please enter a valid email address.",
    EMPTY_EMAIL: "*Email address is required"
};

const ForgotAccessCode = (props) => {
    const [errors, setErrors] = useState([]);
    const [emailAddress, setEmailAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    useEffect(() => {
        localStorage.clear(); //remove cookies, to make the logged-in user as Guest
    });

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }; 

    const handleSubmit = () => {
        if (emailAddress.trim() === "") {
            setErrors(prevErrors => [...prevErrors, Errors.EMPTY_EMAIL]);
            return;
        }
        if (!isEmailValid(emailAddress)) {
            setErrors(prevErrors => [...prevErrors, Errors.INVALID_EMAIL]);
            return;
        }
        setIsLoading(true);
        axios.post(`${API_URL}/requestAccessCode`, { email: emailAddress })
            .then(() => {
                setRequestSent(true);
            })
            .catch(error => {
                setErrors(prevErrors => [...prevErrors, error]);
                setIsLoading(false);
            });
    };

    const renderErrors = errors.map(err => <ErrorLabel key={err}>{ err }</ErrorLabel>);

    return (
        <FlexContainer height="90%" alignItems="center" justifyContent="center" flexGrow="1">
            { requestSent ? <AccessCodeSent />
                :
                <Form>
                    <Text size="2.5rem" color="#fff" weight="500">
                        Request Access Code
                    </Text>
                    <Text size="1.25rem" color="#fff" weight="500">
                        Enter your Email Address
                    </Text>

                    <FormField 
                        placeholder="Enter Your Email Address"
                        placeholderColor="#000"
                        placeholderWeight="500"
                        fontFamily="Roboto, Helvetica, sans-serif"
                        size="0.875rem"
                        width="15.625rem"
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                        disabled={isLoading}
                    />
                    { errors.length ? renderErrors : "" }
                    { isLoading ? <div style={{ marginTop: "2rem" }}><LoadingIndicator /> </div> :
                        <Button backgroundColor="#000"
                            color="#fff"
                            size="0.875rem"
                            alt="Send"
                            marginTop="6.625rem"
                            family="Roboto, Helvetica, sans-serif"
                            onClick={() => handleSubmit()}
                            style={{ borderRadius: 20 }}
                        >
                            Send
                        </Button>
                    }
                    
                </Form>
            }
        </FlexContainer>
    );
};

const AccessCodeSent = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Text size="2.5rem" color="#fff" weight="500" family="Roboto, Helvetica, sans-serif">Access Code Sent!</Text>
            <Text size="1rem" color="#fff" weight="500" family="Roboto, Helvetica, sans-serif">We’ve emailed you a new Access Code to login to your business app.</Text>
            <ToLogin to="/login">
                Login To App
            </ToLogin>
        </div>
    );
};

export default ForgotAccessCode;