import React, { Component } from "react";
import {
    PageContainer, FlexContainer, Text
} from "src/components";
import { GridContainer, GridChild, CancelButton, NextStepButton, StyledButton } from "../components/Utils";
import { HowItWorksStep } from "../components/Enums.js";

//assets
import InAppSurvey from "src/img/survey/how_it_works_in_app.png";
import SurveyInsights from "src/img/survey/how_it_works_survey_insights.png";

class HowItWorks extends Component {
    state = {
        howItWorksStep: HowItWorksStep.PAGE_IN_APP,
    };

    nextStep = (stepNumber) => {
        this.setState({ 
            howItWorksStep: stepNumber
        });
    };

    render() {
        const { showHowItWorks } = this.props;
        const { howItWorksStep } = this.state;

        return (
            <>
                { howItWorksStep === HowItWorksStep.PAGE_IN_APP &&
                    <PageInApp showHowItWorks={showHowItWorks} nextStep={this.nextStep} /> }
                { howItWorksStep === HowItWorksStep.PAGE_SURVEY_INSIGHT &&
                    <PageInsight showHowItWorks={showHowItWorks} nextStep={this.nextStep} /> }
            </>
        );
    }
}

class PageInApp extends Component {
    render() {
        const { nextStep, showHowItWorks } = this.props;

        return (
            <PageContainer>
                <FlexContainer marginTop="10em">
                    <GridContainer align="center" style={{ gridTemplateColumns: "50% 50%" }} >                    
                        <GridChild style={{ textAlign: "center" }}>
                            <img src={InAppSurvey} alt="How it Works? In-App Surveys" className="how-it-works" />
                        
                            <Text color="#51315D" size="13px" align="center">
                                *Example of a survey pop up in the app
                            </Text>
                        </GridChild>
                        <GridChild style={{ textAlign: "left" }}>

                            <Text color="#23262D" size="40px" align="left" weight="bolder" margin="0">
                                How it Works?
                            </Text>
                            <Text color="#23262D" size="40px" align="left" weight="500" margin="0">
                                In-App Surveys
                            </Text>
                            <br/>
                            <Text color="#23262D" size="20px" align="left" lineHeight="26px" weight="500">
                                Surveys allow you to collect data and feedback from 
                                your users in a relatively short period. Surveys can be 
                                created quickly and administered easily through your 
                                Me app Business account.
                            </Text>

                            <Text color="#23262D" size="16px" align="left" lineHeight="26px" weight="500">
                                &bull; Set up surveys easily <br/>
                                &bull; Select from 4 survey types available <br/>
                                &bull; Ask anything (up to 3 questions) per survey <br/>
                                &bull; Branded with your business logo <br/>
                                &bull; Choose when users receive your survey in the app <br/>
                                &bull; Send out to all users or select a user group <br/>
                            </Text>

                            <NextStepButton onClick={(e) => {nextStep(HowItWorksStep.PAGE_SURVEY_INSIGHT);}}>Next</NextStepButton>
                        </GridChild>
                    </GridContainer>

                    <Text align="center">
                        <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "150px", height: "40px", marginTop: "65px" }}
                            onClick={() => { showHowItWorks(false); }} >
                            Close
                        </StyledButton>
                    </Text>
                </FlexContainer>
            </PageContainer>
        );
    }
}

class PageInsight extends Component {
    render() {
        const { nextStep, showHowItWorks } = this.props;

        return (
            <FlexContainer style={{ margin: "auto" }}>
                <GridContainer align="center" style={{ gridTemplateColumns: "50% 50%" }} >                    
                    <GridChild style={{ textAlign: "right" }}>
                        <img src={SurveyInsights} alt="How it Works? In-App Surveys" className="how-it-works" />

                        <Text color="#51315D" size="13px" align="center">
                            *Example of a survey insights in the business admin
                        </Text>
                        
                    </GridChild>
                    <GridChild style={{ textAlign: "left" }}>

                        <Text color="#23262D" size="40px" align="left" weight="bolder" margin="0">
                            How it Works?
                        </Text>
                        <Text color="#23262D" size="40px" align="left" weight="500" margin="0">
                            Survey Insights
                        </Text>
                        <br/>
                        <Text color="#23262D" size="20px" align="left" lineHeight="26px" weight="500">
                            Get instant results and track all user feedback via &rsquo;Survey Insights&rsquo;.
                        </Text>

                        <Text color="#23262D" size="16px" align="left" lineHeight="26px" weight="500">
                            &bull; Easy-to-understand visual data <br/>
                            &bull; Track total survey submitted <br/>
                            &bull; See user feedback for each individual question asked <br/>
                            &bull; Download user-written feedback <br/>
                            &bull; Get insight on how your users feel about a topic <br/>
                        </Text>
                        
                        <CancelButton onClick={(e) => {nextStep(HowItWorksStep.PAGE_IN_APP);}}>Back</CancelButton>
                    </GridChild>
                </GridContainer>

                <Text align="center">
                    <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "150px", height: "40px", marginTop: "65px" }}
                        onClick={() => { showHowItWorks(false); }} >
                        Close
                    </StyledButton>
                </Text>

            </FlexContainer>
        );
    }
}

export default HowItWorks;