import React, { Component } from "react";
import styled from "styled-components";
import {
    Text, FormField, Button
} from "src/components";

export const ErrorList = {
    UNKNOWN: 0,
    EMPTY_PASSWORD: 1,
    EMPTY_NEW_PASSWORD: 2,
    EMPTY_CONFIRM_NEW_PASSWORD: 3,
    CONFIRM_NEW_PASSWORD_MISMATCHED: 4,
    PASSWORD_INVALID: 5,
    NEW_PASSWORD_INVALID: 6,
};

export let ErrorStrings = {
    [ErrorList.UNKNOWN]: "* Error unknown. Please contact support@memotivationapp.com",
    [ErrorList.EMPTY_PASSWORD]: "* Please enter your current password",
    [ErrorList.EMPTY_NEW_PASSWORD]: "* Please enter new password",
    [ErrorList.EMPTY_CONFIRM_NEW_PASSWORD]: "* Please confirm your new password",
    [ErrorList.CONFIRM_NEW_PASSWORD_MISMATCHED]: "* Please make sure the new password match",

    [ErrorList.PASSWORD_INVALID]: "",
    [ErrorList.NEW_PASSWORD_INVALID]: "",
};

export const CancelButton = styled(Button)`
    background: #fff;
    color: #000;
    border: 2px solid #000;
    border-radius: 10px !important;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
    padding: 0 !important;
    margin-right: 1em;
`;

export const SaveButton = styled(Button)`
    background: #000000;
    color: #ffffff;
    border-radius: 10px !important;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
`;

class Password extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_PASSWORD, ErrorList.PASSWORD_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="14px" weight="bolder" margin="0.5em 0">
                    Current Password
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="password"
                        placeholder={!placeholderDisabled ? "Enter Your Password Here" : undefined }
                        onChange={(event) => {onChangeFormData(event, "oldPassword");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.oldPassword}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class NewPassword extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_NEW_PASSWORD, ErrorList.NEW_PASSWORD_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="14px" weight="bolder" margin="0.5em 0">
                    New Password
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="password"
                        placeholder={!placeholderDisabled ? "Enter Your New Password Here" : undefined }
                        onChange={(event) => {onChangeFormData(event, "newPassword");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.newPassword}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class ConfirmNewPassword extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_CONFIRM_NEW_PASSWORD, ErrorList.CONFIRM_NEW_PASSWORD_MISMATCHED);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="14px" weight="bolder" margin="0.5em 0">
                    Confirm New Password
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="password"
                        placeholder={!placeholderDisabled ? "Confirm Your New Password Here" : undefined }
                        onChange={(event) => {onChangeFormData(event, "confirmNewPassword");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.confirmNewPassword}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

export {
    Password, 
    NewPassword, 
    ConfirmNewPassword,
};

