import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Select, MenuItem } from "@material-ui/core/";
import { ChatContext } from "../ChatContext";
import { makeStyles } from "@material-ui/core/styles";
import { LoadingIndicator } from "src/components";
import IconButton from "./IconButton";
import UserAvatar from "./UserAvatar";

import UserSingleIcon from "src/img/new/new-1-on-1-chat.svg";
import UserGroupIcon from "src/img/new/new-group-chat.svg";
import SettingsIcon from "src/img/new/profile-settings.svg";
import { 
    StyledText, CompanyLogo,
    Container, StyledInput,
    CompanyLogoContainer,
    Icon, CreateChatButtons,
} from "./styled/chatListStyled";

import { GROUP_CHAT, ONE_ON_ONE_CHAT, USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { CREATE_GROUP_CHAT, FULL_ACCESS_BUSINESS_CHAT, MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, isChatActionAllowed } from "src/constants/permissions";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import ListOfChats from "./children/ListOfChats";
import { usePresenceListener } from "../hooks";
import { EventType } from "matrix-js-sdk";

const useStyles = makeStyles(() => ({
    outlined: {
        "&:focus": {
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    listItemSecondary: {
        color: "#000"
    }
}));

const selectStyle = {
    width: 227,
    height: 40,
    fontSize: "0.9rem",
    fontFamily: "Roboto",
    fontWeight: "600",
    color: "#4B5155",
    border: "1px solid #DBE5ED",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 7,
    "&:focus": {
        backgroundColor: "transparent"
    }
};


const ChatList = (props) => {
    const {
        businessBackgroundColor,
        newMatrixRoomId,
        matrixRoomIdToRemove,
        resetMatrixRoomIdToRemove,
        isCurrentlyCreatingChat
    } = props;
    const currentUser = useContext(UserContext);
    const {
        showLoadingState,
        showCreateChatModalOrShowLeadershipEmployees,
        selectedChatListTypeOrLeadershipUuid,
        handleChatListTypeOrLeadershipUuidChange,
        displayProfileModal,
        leadershipRoles,
        handleShowBackupKeyModal,
        newTimelineEvent,
        matrixClient,
        handleOpenChat,
        onlineUsersMatrixId,
        isInitialSyncComplete,
        handleSettingCurrentUserStatus,
        userChatsList,
        userChatsMasterList,
        setChatList,
        isUpdatingChatlist,
        fetchChats
    } = useContext(ChatContext);
    const classes = useStyles();
    const [searchedChatList, setSearchedChatList] = useState(userChatsList);
    const [isChatListSearched, setIsChatListSearched] = useState(false);
    const headers = { headers: { Authorization: "Bearer " + currentUser[UserKeys.TOKEN] } };
    const { setUserPresence } = usePresenceListener(matrixClient, isInitialSyncComplete, headers);
    const PRESENCE_STATUS = { ONLINE: "online", OFFLINE: "offline" };
    const amIOnline = currentUser[UserKeys.CHAT_PRESENCE_OVERRIDE] === PRESENCE_STATUS.ONLINE;
    const chatWithEmployeesAllowed = currentUser[UserKeys.PERMISSIONS].includes(MESSAGE_EMPLOYEES);
    const chatWithClientsAllowed = currentUser[UserKeys.PERMISSIONS].includes(MESSAGE_CLIENTS);


    useEffect(() => {
        const processReceivedEvent = () => {
            const roomId = newTimelineEvent?.room_id;
            const newEventIsFromChatListRoom = userChatsList.find((room) => room.uuid === roomId);

            if (!newEventIsFromChatListRoom) {
                fetchChats(selectedChatListTypeOrLeadershipUuid);
            }

            if (roomId && newEventIsFromChatListRoom) {
                const clientUserId = matrixClient.getUserId();
                let newChats = [...userChatsList];
                let index = newChats.findIndex(chat => chat.matrixRoomId === roomId);
                if (index === -1) {
                    /*
                    * instead of returning nothing, we will be comparing the list of chats inside the userChatsMasterList
                    * and update the chatsList found in useGetChats.js, code below handles the updating displayed chat rooms
                    */
                    newChats = [...userChatsMasterList];
                    index = newChats.findIndex(chat => chat.matrixRoomId === roomId);
                }
                const targetChat = newChats.splice(index, 1)[0];
                if (newTimelineEvent.type === EventType.RoomMessageEncrypted) {
                    targetChat.message = newTimelineEvent.content.body;
                }
                targetChat.latestEvent = newTimelineEvent;
                clientUserId !== newTimelineEvent?.sender ? targetChat.unreadCount++ : "";
                newChats.unshift(targetChat);

                setChatList(newChats);
            }
        };
        if (newTimelineEvent && Array.isArray(userChatsList)) {
            processReceivedEvent();
        }
    }, [newTimelineEvent]);

    useEffect(() => {
        if (matrixRoomIdToRemove !== null && matrixRoomIdToRemove !== undefined) {
            const index = userChatsList.findIndex(chat => chat.matrixRoomId === matrixRoomIdToRemove);
            if (index === -1) {
                console.error("Matrix room to remove does not exist in this user's chats list", matrixRoomIdToRemove);
                return;
            }
            
            const list = [...userChatsList];
            list.splice(index, 1);
            setChatList(list);
            handleOpenChat(null);
            resetMatrixRoomIdToRemove();
        }
    }, [matrixRoomIdToRemove]);


    useEffect(() => {
        if (newMatrixRoomId && selectedChatListTypeOrLeadershipUuid) {
            fetchChats(selectedChatListTypeOrLeadershipUuid);
        }
    }, [newMatrixRoomId]);

    /*  this is supposed to handle the current user's online status  on page load
    **  this would basically sets own user presence status based on saved value from database
    **  if the user does not have any presence status set, we'll default to sending "online" to matrix servers
    */
    useEffect(() => {
        const override = currentUser[UserKeys.CHAT_PRESENCE_OVERRIDE];
        if (override === "online" || override === "offline") {
            handleSetOwnUserPresence(override);
        } else if (override === "" || override === null) {
            handleSetOwnUserPresence("online");
        }
    }, []);

    const handleSelectChange = (event) => {
        let selectedLeadershipRoleName = event.target.value;
        if (Array.isArray(leadershipRoles)) {
            const selectedLeadershipRoleItem = leadershipRoles.find((item) => item.uuid === event.target.value);
            if (selectedLeadershipRoleItem) {
                selectedLeadershipRoleName = selectedLeadershipRoleItem.name;
            }
        }
        handleChatListTypeOrLeadershipUuidChange(event.target.value, selectedLeadershipRoleName);
    };

    const handleSearch = (e) => {
        const searchKey = e.target.value;
        if (searchKey.length) {
            setIsChatListSearched(true);
            const filteredChats = userChatsList.filter(chat =>
                // searches for chats whose users' firstname or lastname matches the given search key
                chat.users.some(user => 
                    user.employeeDetails.firstName.toLowerCase().includes(searchKey.toLowerCase())
                        || user.employeeDetails.lastName.toLowerCase().includes(searchKey.toLowerCase())
                )    
            );
            setSearchedChatList(filteredChats);
        } else {
            setIsChatListSearched(false);
            setSearchedChatList(userChatsList);
        }
    };

    const showCreateChatButton = () => {
        const userPermissions = currentUser[UserKeys.PERMISSIONS];
        const userLeadershipRoles = currentUser[UserKeys.LEADERSHIP_ROLES];

        if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES) {
            return isChatActionAllowed(MESSAGE_EMPLOYEES, userPermissions);
        } else if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS) {
            return isChatActionAllowed(MESSAGE_CLIENTS, userPermissions);
        } else {
            //leadershipRole

            // Check if selectedChatListTypeOrLeadershipUuid exists in userLeadershipRoles
            return userLeadershipRoles.some(userLeadershipRole =>
                selectedChatListTypeOrLeadershipUuid === userLeadershipRole.uuid
            );
        }
    };

    const hasAnyChatPermission = (permission) => {
        return permission.includes(FULL_ACCESS_BUSINESS_CHAT) || permission.includes(CREATE_GROUP_CHAT) || permission.includes(MESSAGE_EMPLOYEES) || permission.includes(MESSAGE_CLIENTS);
    };

    const showCreateOneToOneChatButton = () => {
        const userPermissions = currentUser[UserKeys.PERMISSIONS];
        const userLeadershipRoles = currentUser[UserKeys.LEADERSHIP_ROLES];
        return userLeadershipRoles.find((item) => (item.name === selectedChatListTypeOrLeadershipUuid || item.uuid === selectedChatListTypeOrLeadershipUuid)) || hasAnyChatPermission(userPermissions);
    };

    const renderLeadershipRolesList = () => {
        if (leadershipRoles && Array.isArray(leadershipRoles)) {
            return leadershipRoles.map(role => 
                role.visibleLeaderCount > 0 ? <MenuItem key={role.uuid} value={role.uuid} name={role.name}>{ role.name }</MenuItem> : []
            );
        }
    };

    const handleSetOwnUserPresence = (status) => setUserPresence(onlineUsersMatrixId, status, currentUser[UserKeys.MATRIX_USER_ID], handleSettingCurrentUserStatus, currentUser);

    return (
        <Container>
            <CompanyLogoContainer backgroundColor={businessBackgroundColor}>
                { currentUser[UserKeys.COMPANY_LOGO_URL] && <CompanyLogo src={currentUser[UserKeys.COMPANY_LOGO_URL]} /> }
            </CompanyLogoContainer>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "0.4375rem 0.9375rem 0 0.9375rem" }}>
                <UserAvatar firstName={currentUser[UserKeys.FIRST_NAME]} borderPixel="2px"
                    lastName={currentUser[UserKeys.LAST_NAME]}
                    photo={currentUser[UserKeys.PROFILE_PICTURE_URL]}
                    height="4rem" width="4rem" withShadow={true}
                    onClickUserAvatarFunction={() => displayProfileModal(currentUser, currentUser.leadershipRoles, true)}
                    isOnline={amIOnline}
                    showPresenceStatus={true}
                    handleSetOwnUserPresence={handleSetOwnUserPresence}
                />
                <Icon width="1rem" height="1rem" color="#000"
                    src={SettingsIcon}
                    onClick={() => handleShowBackupKeyModal(true)}
                />
            </div>
            
            <Box sx={{ margin: "0 0.9375rem" }}>
                <StyledText size="1rem" margin="1.4375rem 0 0.8125rem 0" weight="600">Active Chats</StyledText>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Select value={selectedChatListTypeOrLeadershipUuid} variant="outlined"
                        style={selectStyle}
                        onChange={handleSelectChange}
                        classes={{ outlined: classes.outlined }}
                        SelectDisplayProps={{
                            style: {
                                background: "transparent",
                            },
                        }}
                    >
                        { chatWithEmployeesAllowed && <MenuItem value={USER_TYPE_EMPLOYEES}>Employees</MenuItem> }
                        { chatWithClientsAllowed && <MenuItem value={USER_TYPE_CLIENTS}>Clients</MenuItem> }
                        { renderLeadershipRolesList() }
                    </Select>
                    <CreateChatButtons className="flex-centered-content"
                        onClick={() => showCreateChatModalOrShowLeadershipEmployees(GROUP_CHAT, selectedChatListTypeOrLeadershipUuid)}
                        isAllowed={isChatActionAllowed(CREATE_GROUP_CHAT, currentUser[UserKeys.PERMISSIONS]) && showCreateChatButton()}
                    >
                        <IconButton color="#fff" src={UserGroupIcon}></IconButton>
                    </CreateChatButtons>
                    
                    <CreateChatButtons className="flex-centered-content"
                        onClick={() => showCreateChatModalOrShowLeadershipEmployees(ONE_ON_ONE_CHAT, selectedChatListTypeOrLeadershipUuid)}
                        isAllowed={showCreateOneToOneChatButton()}
                    >
                        <IconButton color="#fff" src={UserSingleIcon}></IconButton>
                    </CreateChatButtons>
                </div>
                <StyledInput placeholder="Search people" onChange={(e) => handleSearch(e)} />
            </Box>
            
            <Divider />
            { showLoadingState || isUpdatingChatlist || isCurrentlyCreatingChat ? <LoadingIndicator /> :
                <ListOfChats
                    chatsList={isChatListSearched ? searchedChatList : userChatsList}
                />
            }
        </Container>
    );
};

export default ChatList; 