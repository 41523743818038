import React from "react";
import Button from "./Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
    border-radius: ${p => p.borderRadius};
    box-sizing: border-box;
    display: flex;
    align-items: center;
`;

const Icon = styled.img`
    height: ${p => p.height};
    width: ${p => p.width};
    color: ${p => p.color};
    margin: ${p => p.margin};
`;

const ButtonWithIcon = (props) => {
    const { icon, text, iconHeight, iconWidth, iconMargin, iconColor, ...styleProps } = props;
    return (
        <StyledButton { ...styleProps}>
            <Icon src={icon}
                height={iconHeight}
                width={iconWidth}
                margin={iconMargin}
                color={iconColor}
            /> { text }
        </StyledButton>
    );
};

export default ButtonWithIcon;