import styled, { css } from "styled-components";

const Tooltip = styled.div`
    ${ p => css`
            margin-left:10px;
            position: absolute;
            padding: 12px 24px;
            background: ${ p => p.color || "white"};
            color: #000000;
            font-size: 12px;
            border-radius: 16px;
            border: 1px solid #0000001A;
            box-shadow: 0px 0px 15px 3px #0000001a;
            pointer-events: none;
            display: ${ p => p.active ? "block" : "none" };
            transform: translate(calc(100% - ${ p => p.transform || "45%"}), calc(-100% - 30px));
            max-width:275px;
    `}
`;

export default Tooltip;
