import React, { Component } from "react";
import styled from "styled-components";
import OphLogo from "../img/new/onpoint_logo.svg";
import { tablet, desktop } from "./Media";

// Styled components
const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top: 40px;
    text-align: center;
    width: 100%;
    background: #fff;
    padding-top: 10px;
    ${desktop`
        flex-direction: row;
        height: 6em;
        margin-top: 0;
        padding-bottom: 0;
    `};
`;

const FooterLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    ${tablet`
        width: 100%;
        flex-direction: row;
        box-sizing: border-box;
    `};
`;

const FooterLink = styled.a`
    color: #000;
    font-weight: 500;
    padding: 16px 0;
    text-decoration: none;

    ${tablet`
        flex-grow: 1;
    `};

    ${desktop`
        flex-grow: 0;
        padding: 16px 16px;
        width: 12em;
    `};
`;

const OphPowerContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;

    ${desktop`
        width: 20em;
        padding-right: 10em;
    `};

`;

const OphImage = styled.img`
    width: auto;
    height: 1.2rem;
    margin: 1em;
    position:relative;
    top: 2px;
`;

const OphLink = styled.a`
    text-decoration: none;
`;

class Footer extends Component {
    render() {
        return (
            <FooterContainer>
                <OphPowerContainer />
                <FooterLinksContainer>
                    <FooterLink href="https://www.memotivationapp.com/terms-conditions" target="_blank" rel="noopener noreferrer">
                        Terms &amp; Conditions
                    </FooterLink>
                    <FooterLink href="https://www.memotivationapp.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </FooterLink>
                    <FooterLink href="mailto:support@memotivationapp.com">
                        Contact Us
                    </FooterLink>
                </FooterLinksContainer>
                <OphPowerContainer>
                    <OphLink href="https://www.onpoint-holdings.com" target="_blank" rel="noopener noreferrer">
                        <OphImage src={OphLogo} />
                    </OphLink>
                </OphPowerContainer>
            </FooterContainer>
        );
    }
}

export default Footer;
