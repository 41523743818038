import React, { useState } from "react";
import styled, { css } from "styled-components";
import {
    Text, Button
} from "src/components";

import SmsIcon from "../img/new/sms.svg";
import EmailIcon from "../img/new/email.svg";
import SmsIconGreen from "../img/new/sms-green.svg";
import EmailIconGreen from "../img/new/email-green.svg";

const StyledText = styled(Text)`
    font-family: Roboto, Helvetica, sans-serif;
    text-align: left;
    padding: ${p => p.padding || 0};
    margin: ${p => p.margin || 0};
`;

const RadioButton = styled(Button)`
    border: 1px solid #000000;
    color: #000000;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px !important;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 500;
    width: 14.25rem;

    ${props => props.active && 
        css`
            color: #36BE24;
            border: 1px solid #36BE24;
            background-color: #E6FFE3;
        `
}
    ${props => props.disabled && 
        css`
            color: #36BE24;
            border: 1px solid #36BE24;
            background-color: #E6FFE3;
        `
}
`;

const ButtonIcon = styled.img`
    width: 20px;
    height: auto;
    color: #fff;
    margin-right: 6px;
`;

const SelectedLabel = styled.div`
    text-align: center;
    font-size: 12px;
    color: #36BE25;
    margin-top: 10px;
`;

const AppInviteMethod = ({ setAppInviteMethod }) => {
    const [selectedMethod, setSelectedMethod] = useState("email");

    const handleSwitchMethod = (value) => {
        setSelectedMethod(value);
        setAppInviteMethod(value);
    };

    return (
        <div>
            <StyledText color="#000000" weight="700" size="1.25rem" margin="1em 0 0 0">Select App Invite Method</StyledText>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <input type="radio" name="sendMethod" value="email" style={{ visibility: "hidden" }} />
                    <RadioButton active={selectedMethod === "email"} onClick={() => handleSwitchMethod("email")}>
                        <ButtonIcon src={selectedMethod === "email" ? EmailIconGreen : EmailIcon} />
                        Send Invite By Email
                    </RadioButton>
                    {selectedMethod === "email" && <SelectedLabel>Selected</SelectedLabel>}
                </div>
                <div>
                    <input type="radio" name="sendMethod" value="sms" style={{ visibility: "hidden" }} />
                    <RadioButton active={selectedMethod === "sms"} onClick={() => handleSwitchMethod("sms")}>
                        <ButtonIcon src={selectedMethod === "sms" ? SmsIconGreen : SmsIcon} />
                        Send Invite By SMS
                    </RadioButton>
                    {selectedMethod === "sms" && <SelectedLabel>Selected</SelectedLabel>}
                </div>
                <div>
                    <input type="radio" name="sendMethod" value="both" style={{ visibility: "hidden" }} />
                    <RadioButton active={selectedMethod === "both"} onClick={() => handleSwitchMethod("both")}>
                        <ButtonIcon src={selectedMethod === "both" ? EmailIconGreen : EmailIcon} />
                        <ButtonIcon src={selectedMethod === "both" ? SmsIconGreen : SmsIcon} />
                        Send Both
                    </RadioButton>
                    {selectedMethod === "both" && <SelectedLabel>Selected</SelectedLabel>}
                </div>
            </div>
        </div>
    );
};

export default AppInviteMethod;