import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import styled from "styled-components";
import {
    Text, Button, FormField, Form, FlexContainer, CountryPhoneInput, LoadingIndicator, ErrorLabel
} from "src/components";

const ErrorList = {
    DIFF_PASS: 1,
    EMAIL_FORMAT: 2,
    EMPTY_EMAIL: 3,
    EMPTY_FIRST_NAME: 4,
    EMPTY_PASS: 5,
    EMPTY_REPASS: 6,
    MIN_LENGTH: 7,
    EMPTY_LAST_NAME: 9
};

const ErrorStrings = {
    [ErrorList.DIFF_PASS]: "* Please make sure passwords match",
    [ErrorList.EMAIL_FORMAT]: "* Please make sure Email format is correct",
    [ErrorList.EMPTY_EMAIL]: "* Please enter an email address",
    [ErrorList.EMPTY_FIRST_NAME]: "* Please enter a first name",
    [ErrorList.EMPTY_LAST_NAME]: "* Please enter a last name",
    [ErrorList.EMPTY_PASS]: "* Please enter a password",
    [ErrorList.EMPTY_REPASS]: "* Please re-enter the same password",
    [ErrorList.MIN_LENGTH]: "* Please enter a password at least eight characters long",
};

const StyledInput = styled(FormField)`
    text-align: left;
    width: 100%;
    max-width: 22.25rem;
    height: 2.9rem;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 0.875rem;
    padding-left: 2.125rem;
    border-radius: 23px;
    margin: 0 0 0.625rem 0;
`;

const StyledCountryPhoneInput = styled(CountryPhoneInput)`

    div.flag-dropdown, input.form-control {
        background-color: #FFF;
        border-radius: 1.4375rem !important;
        border: none !important;
        height: 2.9rem !important;
    }

    div.flag-dropdown {
        width: 4rem;
        padding-left: 10px;
    }

    input.form-control {
        width: 100% !important;
        max-width: 15.25rem !important;
        text-align: left;
        font-weight: 400;
        font-size: 0.875rem;
        color: #000;
        padding: 0 30px;
        margin-left: 75px !important
    }

    .form-control::placeholder {
        text-align: left;
        color: #737373;
        font-size: 0.875rem;
        font-family: Roboto, Helvetica, sans-serif;
    }
`;

class SignUp extends Component {
    state = {
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        password: "",
        rePassword: "",
        errorMessage: "",
        inputErrors: [],
        loading: false,
    };

    register = (e) => {
        const { firstName, lastName, mobile, email, password, rePassword } = this.state;

        e.preventDefault();
        let errorArray = [];

        if (firstName.trim() === "") {
            errorArray.push(ErrorList.EMPTY_FIRST_NAME);
        }

        if (lastName.trim() === "") {
            errorArray.push(ErrorList.EMPTY_LAST_NAME);
        }

        if (email.trim() === "") {
            errorArray.push(ErrorList.EMPTY_EMAIL);
        } else if (! /^(.+)@(.+)\.(.+)$/.test(email)) {
            errorArray.push(ErrorList.EMAIL_FORMAT);
        }

        if (password.trim() === "") {
            errorArray.push(ErrorList.EMPTY_PASS);
        } else if (password.length < 8) {
            errorArray.push(ErrorList.MIN_LENGTH);
        } else if (password !== rePassword) {
            errorArray.push(ErrorList.DIFF_PASS);
        }

        if (rePassword.trim() === "") {
            errorArray.push(ErrorList.EMPTY_REPASS);
        }

        if (errorArray.length === 0) {
            this.setState({
                inputErrors: errorArray,
                errorMessage: "",
                loading: true,
            });
            
            axios.post(`${API_URL}/company/register`, {
                firstName: firstName,
                lastName: lastName,
                phoneNumber: mobile,
                email: email,
                password: password,
            })
                .then(response => {
                    this.setState({
                        loading: false
                    });
                    this.props.signUpComplete(response.data.account);
                })
                .catch(error => {
                    this.setState({
                        errorMessage: error,
                        loading: false
                    });
                });
        } else {
            this.setState({
                inputErrors: errorArray,
            });
        }
    };

    renderErrors = (errors) => errors.map((err, index) => {
        return (
            <ErrorLabel key={index}>{ ErrorStrings[err] }</ErrorLabel>
        );
    });

    handlePhoneNumber = (phone) => {
        if (phone !== "" && !phone.startsWith("+")) {
            phone = "+" + phone;
        }

        this.setState({ mobile: phone });
    };

    render() {
        const { errorMessage, inputErrors, loading } = this.state;
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const firstNameErrors = getErrorStrings(ErrorList.EMPTY_FIRST_NAME);
        const lastNameErrors = getErrorStrings(ErrorList.EMPTY_LAST_NAME);
        const emailErrors = getErrorStrings(ErrorList.EMPTY_EMAIL, ErrorList.EMAIL_FORMAT);
        const passwordErrors = getErrorStrings(ErrorList.EMPTY_PASS, ErrorList.MIN_LENGTH, ErrorList.DIFF_PASS);
        const rePasswordErrors = getErrorStrings(ErrorList.EMPTY_REPASS, ErrorList.DIFF_PASS);

        return (
            <FlexContainer flexGrow="1" justifyContent="center" alignItems="center" maxWidth="25rem">
                <Form style={{
                    textAlign: "center"
                }}>
                    <Text color="red"><strong>{errorMessage}</strong></Text><br/>
                    <Text size="1.875rem" weight="700" color="white" margin="0 0 3rem 0">
                        Welcome
                    </Text>
                    <StyledInput
                        placeholder="First Name"
                        placeholderAlignment="left"
                        placeholderWeight="500"
                        onChange={(e) => this.setState({ firstName: e.target.value }) }
                        style={{ textTransform: "capitalize" }}
                        errors={firstNameErrors}
                    />
                    <StyledInput
                        placeholder="Last Name"
                        placeholderAlignment="left"
                        placeholderWeight="500"
                        onChange={(e) => this.setState({ lastName: e.target.value }) }
                        style={{ textTransform: "capitalize" }}
                        errors={lastNameErrors}
                    />
                    <StyledInput
                        autoComplete="email"
                        placeholder="Email"
                        placeholderAlignment="left"
                        placeholderWeight="500"
                        onChange={(e) => this.setState({ email: e.target.value }) }
                        errors={emailErrors}
                    />

                    <StyledCountryPhoneInput
                        autoFormat={false}
                        prefix={"+"}
                        country={"au"} //lowercase
                        value={this.state.mobile}
                        onChange={phone => this.handlePhoneNumber(phone)}
                        placeholder="Mobile Number"
                        errors={[]}
                    />

                    <StyledInput
                        type="password"
                        autoComplete="new-password"
                        placeholder="Create Password"
                        placeholderAlignment="left"
                        placeholderWeight="500"
                        onChange={(e) => this.setState({ password: e.target.value }) }
                        errors={passwordErrors}
                        style={{ marginTop: "0.625rem" }}
                    />
                    <StyledInput
                        type="password"
                        autoComplete="new-password"
                        placeholder="Re-Enter Password"
                        placeholderAlignment="left"
                        placeholderWeight="500"
                        onChange={(e) => this.setState({ rePassword: e.target.value }) }
                        errors={rePasswordErrors}
                    />
                    <div>
                        { this.renderErrors(inputErrors) }
                    </div>
                    { loading ?
                        <FlexContainer width="100%" height="30px" >
                            <LoadingIndicator />
                        </FlexContainer>
                        :
                        <Button
                            backgroundColor="#FFF"
                            color="#4956FC"
                            size="0.875rem"
                            width="10.125rem"
                            height="2.75rem"
                            alt="Create Account"
                            marginTop="1.875rem"
                            style={{ lineHeight: "inline", fontWeight: 700, borderRadius: 22 }}
                            onClick={this.register}
                        >
                            Create Account
                        </Button>
                    }
                </Form>
            </FlexContainer>
        );
    }
}

export default SignUp;