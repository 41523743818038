import React from "react";
import styled from "styled-components";
import FlexContainer from "./FlexContainer";
import { smallDesktop } from "./Media";

const Row = styled(FlexContainer)`
    background: ${p => p.backgroundColor || "#ffffff"};
    width: ${p => p.width || "43.125rem"};
    max-width: ${p => p.maxWidth || "92.875rem"};
    width: ${p => p.height || "auto"};
    min-height: ${p => p.minHeight || "43.75rem"};
    margin: ${p => p.rowMargin || "2.375rem 0 0 0"};
    border-radius: ${p => p.borderRadius || "12px"};
    display: flex;
    flex-direction: row;

    ${smallDesktop`
        flex-direction: column;
    `};
`;

const ContentContainer = styled.div`
    position: relative;
    font-family: Roboto;
    width: 50%;
    height: 100%;
    padding: ${p => p.contentPadding || "2.8125rem 3.8125rem"};
    box-sizing: border-box;
    ${smallDesktop`
        width: 100%;
    `};
`;
const ImageContainer = styled.div`
    position: relative;
    width: 50%;
    visibility: ${p => p.imageVisibility};
    ${smallDesktop`
        display: 0;
    `};
    background-image: url(${ p => p.src});
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0 12px 12px 0;
    background-position: center center;
`;

const SplitLayoutRightImage = (props) => {
    const { children, image, ...styleProps } = props;
    return (
        <Row {...styleProps}>
            <ContentContainer {...styleProps}>
                { children }
            </ContentContainer>
            <ImageContainer imageVisibility={props.imageVisibility || "visible"} src={image}>

            </ImageContainer>
        </Row>
    );
};

export default SplitLayoutRightImage;