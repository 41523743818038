const { MatrixEvent } = require("matrix-js-sdk");

export const useDecryptEvent = (matrixClient) => {
    const decryptEvent = async (event) => {
        const matrixEvent = new MatrixEvent(event);
        if (matrixEvent.isEncrypted()) {
            try {
                await matrixEvent.attemptDecryption(matrixClient.getCrypto());
                matrixEvent.verificationRequest.start();
                matrixEvent.event.content = matrixEvent.getContent();
                return matrixEvent.event;
            } catch (error) {
                console.error("Unable to decrypt event.", error);
                return event;
            }
        }
    };

    return {
        decryptEvent
    };
};
