import React, { Component } from "react";
import styled from "styled-components";
import { API_URL } from "src/scenes/App";
import { Text, AuthenticatedLink } from "src/components";
import TypeComment from "src/img/survey/updatedIcons/feedback@2x.png";

const OverviewCard = styled.div`
    width: 910px;
    height: 160px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000026;
    border: 1px solid #8291B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
`;


export class SurveyFeedbackCard extends Component {
    render() {
        const { surveyQuestion, user } = this.props;
        return (
            <OverviewCard>
                <div style={{ display: "flex", margin: "auto 0 auto 35px" }}>
                    <img src={TypeComment} alt={TypeComment} height="45px" width="45px" />
                    <Text width="290px" height="20px" align="left" margin="0 0 0 20px">
                        Download (.csv) all current written answers from users for your questions:
                    </Text>
                </div>
                <div style={{ justifyContent: "end", margin: "auto 35px auto 0" }}>
                    <AuthenticatedLink
                        style={{ width: "235px", borderRadius: "10px", weight: "bold", border: "1px solid #000", color: "#FFF", backgroundColor: "#006CFF", display: "block", textAlign: "center", padding: "8px 23px", cursor: "pointer" }}
                        url={`${API_URL}/survey/company/questionResponsesDownload/${surveyQuestion.uuid}`}
                        filename={surveyQuestion.question.toString() + ".csv"}
                        user={user}
                    >
                        Download Question {surveyQuestion.questionOrder} Answers
                    </AuthenticatedLink>
                </div>
            </OverviewCard>
        );
    }
}