import React from "react";
import styled, { keyframes, css } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: .15;
  }
  100% {
    opacity: 1;
  }
`;

const SkeletonWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : "100px")};
  height: ${(props) => (props.height ? props.height : "16px")};
  background: linear-gradient(90deg, #EAEDF4 25%, #EAEDF4 50%, #EAEDF4 75%);
  background-size: 200% 100%;
  background-repeat: no-repeat;
  border-radius: ${(props) => (props.variant === "Circle" ? "50%" : "2px")};
  animation: ${(props) =>
        props.animation === "wave"
            ? css`
          ${pulseAnimation} 1.5s infinite
        `
            : "none"};
`;

const Skeleton = ({ variant, width, height, animation }) => {
    return <SkeletonWrapper variant={variant} width={width} height={height} animation={animation} />;
};

export default Skeleton;