import React from "react";
import { Box, Grid, Dialog, Divider } from "@material-ui/core/";
import { useState, useEffect } from "react";
import { Button, FormField, Text, CountryPhoneInput } from "src/components";
import styled, { css } from "styled-components";

const Label = styled.label`
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    color: #000;
    margin: 0;
`;

const Required = styled.span`
    color: red;
    font-style: italic;
    font-weight: 400;
    
    ::before {
        content: "(required)";
        font-size: 0.8rem;
    }
`;

const RadioButton = styled.button`
    position: relative;
    width: 15.625rem;
    height: 2.5rem;
    border: 1px solid #000;
    border-radius: 12px;
    text-align: center;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    background-color: #fff;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
          background-color: #000000;
          color: #fff !important;
          font-weight: 700;
    `}
    ${(props) =>
        props.disabled &&
        css`
          pointer-events: none;
          color: #000;
    `}
`;

const StyledInput = styled(FormField)`
    width: 15.625rem;
    height: 2.5rem;
    border: 1px solid #000;
    border-radius: 12px;
    text-align: left;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
    padding: 0 30px;

    ::placeholder {
        text-align: left;
        color: #737373;
        font-family: Roboto, Helvetica, sans-serif;
    }
`;

const StyledCountryPhoneInput = styled(CountryPhoneInput)`
    margin: 5px 0;

    div.flag-dropdown, input.form-control {
        background-color: #FFF;
    }

    input.form-control {
        width: 200px !important;
        height: 2.5rem;
        text-align: left;
        font-weight: 400;
        font-size: 0.875rem;
        color: #000;
        padding: 0 1rem 0 3.6rem !important;
    }

    .form-control::placeholder {
        text-align: left;
        color: #737373;
        font-size: 0.875rem;
        font-family: Roboto, Helvetica, sans-serif;
    }
`;

const DialCodeContainer = styled.div`
    position: absolute;
    height: 100%;
    top: 0;
    left: 50px;
    display: flex;
    padding: 0.05rem 0 0;
`;

const PhoneInputWithDialCode = (props) => {
    const { countryCode, onMount, onChange, value, dialCode } = props;
    return (
        <div style={{ position: "relative" }}>
            <StyledCountryPhoneInput
                autoFormat={false}
                country={countryCode.toLowerCase()}
                disableCountryGuess={true}
                disableCountryCode={true}
                value={value}
                onMount={onMount}
                onChange={onChange}
                placeholder="Mobile"
                errors={[]}
            />
            <DialCodeContainer>
                <Text color="#737373" width="auto" margin="auto 0 auto 1rem">
                    +{dialCode}
                </Text>
            </DialCodeContainer>
        </div>
    );
};

const Circle = styled.div`
    position: absolute;
    right: 0;
    bottom: 9px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #76C00D;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;

    ${(props) =>
        props.active &&
        css`
            visibility: visible;
    `}
    ${(props) =>
        props.disabled &&
        css`
          pointer-events: none;
    `}
`;

const CheckMark = styled.span`
    color: white;
    font-weight: 700;
    font-size: 10px;
`;

const UserDetailsForm = (props) => {
    const { showDialog, handleClose, user, createNewUser, removeUsers, updateEmployee, countryCode } = props;
    const [formData, setFormData] = useState(props.user);
    const [errorList, setErrorList] = useState([]);
    const [dialCode, setDialCode] = useState("");

    const isFormCreate = user.formType === "add";

    const handleInputChange = (event, key) => {
        const updatedFormData = { ...formData, [key]: event.target.value };
        setFormData(updatedFormData);
    };

    const emptyPhoneFieldWhenEmptyString = (phone, country) => {
        if (formData.phoneNumber !== "" && !country)
            return;

        if (country.dialCode !== phone)
            return;
        
        const updatedFormData = { ...formData, phoneNumber: "" };
        setFormData(updatedFormData);
    };

    const changePhoneNumber = (phone, country) => {
        setDialCode(country.dialCode);

        const updatedFormData = { ...formData, phoneNumber: phone };
        setFormData(updatedFormData);
    };

    const handlePhoneNumber = (phone, country) => {
        (async () => {
            await Promise.resolve(changePhoneNumber(phone, country)); 
            emptyPhoneFieldWhenEmptyString(phone, country);
        })();
    };

    const onPhoneInputMounted = (value, country) => {
        country.dialCode && setDialCode(country.dialCode);

        const updatedFormData = { ...formData, phoneNumber: value.slice(country.dialCode.length) };
        setFormData(updatedFormData);
    };

    const handleFormSubmit = () => {
        setErrorList([]);
        let requiredFields = ["firstName", "lastName", "email"];
        if (formData.isClient)
            requiredFields = ["firstName", "email"];

        let status = true;
        requiredFields.forEach(field => {
            if (!formData[field].trim()) {
                status = false;
            }
        });

        if (!status) {
            return setErrorList((prevErrorList) => [...prevErrorList, "Please fill all required fields."]);
        }
        
        if (!validateEmail(formData.email)) {
            return setErrorList((prevErrorList) => [...prevErrorList, "Invalid email format."]);
        }

        let formRequest = formData;
        formRequest = {
            ...formRequest,
            phoneNumber: formRequest.phoneNumber.length > 0 ? "+" + dialCode + formRequest.phoneNumber : ""
        };
        isFormCreate ? createNewUser(formRequest) : updateEmployee(formRequest);
    };

    const handleDeleteUser = () => {
        removeUsers([user.uuid]);
    };

    const handleUserTypeSelection = (value) => {
        const updatedFormData = { ...formData, isClient: value };
        setFormData(updatedFormData);
    };

    const validateEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    };

    const renderErrors = errorList.map((error, index) => {
        return errorList.length ? <div key={index} style={{ color: "red", fontFamily: "Roboto, Helvetica, sans-serif" }}>{ error }</div>
            : "";
    });

    // prevents console warns when one of the input values are null
    const filterInputValue = (value) => [null].includes(value) ? "" : value;

    useEffect(() => {
        setFormData(user);
        setErrorList(props.errorList);
    }, [props]);

    return (
        <>
            <Dialog open={showDialog} onClose={handleClose}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
        		aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: 900,
                        height: "auto",
                        borderRadius: 15,
                        overflow: "visible"
                    },
                }}
            >
                <Box sx={{ margin: "60px 55px 0 55px" }}>
                    <Text color="#11141A" size="2.5rem" align="left" weight="700" margin="0">
                        { isFormCreate ? "Add New User" : "Edit User"}
                    </Text>
                    { isFormCreate ?
                        <Text color="#000000" align="left" size="0.875rem">
                            Please select whether this individual is an employee or a client of your business,<br />
                            and kindly provide the relevant details accordingly.
                        </Text>
                        : <div style={{ marginBottom: "1.5rem" }}></div>
                    }
                </Box>
                <Divider />
                <Box sx={{ margin: "20px 55px" }}>
                    <Grid container spacing={2}>
                        { (isFormCreate || !formData.isClient) &&
                            <Grid item sm={12} md={4}>
                                <Label>{ isFormCreate ? "Select User Type" : "User Type" }</Label>
                                <div className="radio" 
                                    style={{ margin: "-0.75rem 0 1.25rem 0" }}
                                >
                                    <input type="radio" name="userType" value="employee" style={{ visibility: "hidden" }} />
                                    <RadioButton active={!formData.isClient}
                                        onClick={() => handleUserTypeSelection(0)}
                                        disabled={!isFormCreate}
                                    >
                                        Employee { isFormCreate }
                                        <Circle active={!formData.isClient}><CheckMark>&#x2713;</CheckMark></Circle>
                                    </RadioButton>
                                    
                                </div>
                            </Grid>
                        }
                        
                        { (isFormCreate || formData.isClient) &&
                            <Grid item sm={12} md={4}>
                                <Label>{ isFormCreate ? "Select User Type" : "User Type" }</Label>
                                <div className="radio" style={{ margin: "-0.75rem  0 1.25rem 0" }}>
                                    <input type="radio" name="userType" value="client" style={{ visibility: "hidden" }} />
                                    <RadioButton active={formData.isClient}
                                        onClick={() => handleUserTypeSelection(1)}
                                        disabled={!isFormCreate}
                                    >
                                        Client
                                        <Circle active={formData.isClient}><CheckMark>&#x2713;</CheckMark></Circle>
                                    </RadioButton>
                                </div>
                                
                            </Grid>
                        }
                        
                        <Grid item sm={12} md={4}>
                            &nbsp;
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={4}>
                            <Label>First Name <Required /></Label>
                            <StyledInput placeholder="First Name"
                                value={filterInputValue(formData.firstName)}
                                onChange={e => handleInputChange(e, "firstName")}
                                style={{ textTransform: "capitalize" }}
                            />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <Label>Last Name {!formData.isClient && <Required />}</Label>
                            <StyledInput required placeholder="Last Name"
                                value={filterInputValue(formData.lastName)}
                                onChange={e => handleInputChange(e, "lastName")}
                                style={{ textTransform: "capitalize" }}
                            />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <Label>Email <Required /></Label>
                            <StyledInput required placeholder="Email"
                                value={filterInputValue(formData.email)}
                                onChange={e => handleInputChange(e, "email")}
                            />
                        </Grid> 
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={4}>
                            <Label>Mobile (Optional)</Label>
                            
                            <div>
                                <PhoneInputWithDialCode
                                    onChange={handlePhoneNumber}
                                    onMount={onPhoneInputMounted}
                                    countryCode={countryCode.toLowerCase()}
                                    dialCode={dialCode}
                                    value={filterInputValue(formData.phoneNumber)} />
                            </div>
                        </Grid>

                        {!formData.isClient &&
                        <Grid item sm={12} md={4}>
                            <Label>{formData.isClient ? "Client Type" : "Department"} (Optional)</Label>
                            <StyledInput placeholder={formData.isClient ? "Client Type" : "Department"}
                                value={filterInputValue(formData.department)}
                                onChange={e => handleInputChange(e, "department")}
                            />
                        </Grid>}

                        <Grid item sm={12} md={4}>
                            <Label>{formData.isClient ? "Client Type (Optional)" : "Job Title (Optional)"}</Label>
                            <StyledInput placeholder={formData.isClient ? "Client Type" : "Job Title"}
                                value={filterInputValue(formData.employmentPosition)}
                                onChange={e => handleInputChange(e, "employmentPosition")}
                            />
                        </Grid>
                    </Grid>

                    { renderErrors }
                </Box>
                <Divider />
                <Box sx={{ margin: "25px 55px 30px 55px" }}>
                    
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            { !isFormCreate &&
                                <Button backgroundColor="#FF5353" color="#fff"
                                    style={{ borderRadius: 10, fontFamily: "Roboto, Helvetica, sans-serif" }}
                                    width="9.375rem" height="2.3125rem"
                                    onClick={() => handleDeleteUser()}
                                >
                                    Delete User
                                </Button>
                            }
                            
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <Button 
                                border="1px solid #000"
                                color="#000"
                                marginRight="15px"
                                width="140px"
                                onClick={handleClose}
                                style={{ borderRadius: "7px", boxShadow: "none", fontFamily: "Roboto, Helvetica, sans-serif" }}
                                className="form-btns disabled"
                            >
                                Cancel
                            </Button>
                            <Button backgroundColor="#006CFF" color="#fff"
                                border="1px solid #006CFF"
                                width="140px"
                                style={{ borderRadius: "7px", boxShadow: "none", fontFamily: "Roboto, Helvetica, sans-serif" }}
                                type="submit"
                                onClick={(e) => handleFormSubmit(e)}
                                className="form-btns disabled"
                            >
                                { isFormCreate ? "Add User" : "Save" }
                            </Button>
                        </div>
                    </div>
                    
                </Box>
            </Dialog>
        </>
    );
}; 

export default UserDetailsForm;