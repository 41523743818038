
/**
 * Utilities for retrieving the event data from a Matrix event. Will use the supplied MatrixClient to decrypt
 * content where appropriate.
 *
 * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
 * @typedef {import('matrix-js-sdk').MatrixEvent} MatrixEvent
 * @typedef {import('matrix-js-sdk').IEvent} IEvent
 *
 * @param {MatrixClient} matrixClient
 * @returns {{
 *     getEventData: ((function(MatrixEvent): Promise<Partial<IEvent>>)|*)
 * }}
 */
export const useGetEventData = (matrixClient) => {

    /**
     * @param {MatrixEvent} matrixEvent 
     * @returns {Promise<Partial<IEvent>>}
     */
    const getEventData = async (matrixEvent) => {
        try {
            const shouldDecrypt = matrixEvent.shouldAttemptDecryption();
            if (shouldDecrypt) {
                await matrixEvent.attemptDecryption(matrixClient.getCrypto());
            }
            matrixEvent.event.content = !shouldDecrypt ? matrixEvent.getClearContent() : matrixEvent.getContent();
            return matrixEvent.event;
        } catch (error) {
            console.error(error);
            return matrixEvent.event;
        }
    };

    return {
        getEventData
    };
};
