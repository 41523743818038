import React, { useEffect, useState, useRef } from "react";
import { Button, FormField, Text, Checkbox, LoadingIndicator } from "src/components";
import { Dialog, Box, Grid, Divider } from "@material-ui/core";
import styled, { css } from "styled-components";
import AttachIcon from "src/img/new/attach-image.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dropzone from "react-dropzone";
import CheckImg from "src/img/check@2x.png";
import RemoveImg from "src/img/close.png";

const Label = styled.label`
    font-family: Roboto, Helvetica, sans-serif;
    font-size: ${p => p.size || "0.906rem"};
    font-weight: 700;
    color: #000;
    display: inline-block;
    margin-bottom: 0.313rem;
`;

const StyledTitleInput = styled(FormField)`
    height: 3.125rem;
    border: 1px solid #8291b2;
    border-radius: 12px;
    text-align: left;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #000;

    ::placeholder {
        text-align: left;
        color: #000;
        font-family: Roboto, Helvetica, sans-serif;
    }
`;

const TextArea = styled.textarea`
    font-family: Roboto, Helvetica, sans-serif;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    height: 15rem;
    width: 90%;
    border: 1px solid #8291b2;
    border-radius: 12px;
    padding: 10px 20px 10px 20px;
    margin: 0.313rem 0;
    resize: none;

    ::placeholder {
        text-align: left;
        color: #000;
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 0.875rem;
    }
`;

const RadioButton = styled.button`
    position: relative;
    width: 14rem;
    height: 3.125rem;
    border: 1px solid #8291b2;
    border-radius: 12px;
    text-align: center;
    font-size: 1rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    background-color: #fff;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            background-color: #edf4ff;
            color: #000;
            font-weight: 700;
        `}
`;

const Description = styled.div`
    font-weight: ${p => p.weight || "500"};
    font-size: ${p => p.size || "0.813rem"};
    color: ${p => p.color || "#000"}
`;

const StyledDropzone = styled(Dropzone)`
    height: 12.938rem;
    border: 1px solid #8291b2;
    border-radius: 12px;
    margin: 0.313rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden; /* Ensure image doesn't overflow the container */
`;

const ImagePreview = styled.img`
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Image will fit inside container without stretching */
`;

const FileInput = styled.input`
    display: none;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DatePickerContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DatePickerWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
`;

const DateContainer = styled.div`
    width: 8.75rem;
    text-align: center;
`;

const SelectedDate = styled.span`
  display: inline-block;
  font-size: 0.875rem;
  color: #006cff;
  margin-top: 0.5rem;
`;

const ErrorMessage = styled.span`
    font-size: 0.844rem;
    color: #ff7171;
`;

const RemoveButton = styled.img`
    height: 40px;
    width: 40px;
    cursor: pointer;
    margin-top: 30px;
    margin-left: 3px;
`;

const VISIBLE_TO = {
    EMPLOYEES: "employees",
    CLIENTS: "clients",
    EMPLOYEES_AND_CLIENTS: "employees_and_clients"
};

const ERROR_LIST = {
    VISIBLE_TO: { "input": "visibleTo", "message": "*You must select 1 option." },
    TITLE: { "input": "title", "message": "*Please enter a name here." },
    EXPIRATION_DATE: { "input": "expirationDate", "message": "*Please select an option." }
};

const ContactModal = ({
    showContactModal,
    handleCloseModal,
    formData,
    updatingUuid,
    handleCreateOrUpdate,
    handleShowDeleteDialog,
    isFormLoading,
    handleRemoveCustomField,
    handleCreateCustomField
}) => {
    const [contactData, setContactData] = useState({ ...formData });
    const [visibleTo, setVisibleTo] = useState("");
    const [noExpDate, setNoExpDate] = useState(false); // For handling toggle of expiration date checkbox
    const [selectedDate, setSelectedDate] = useState(null); // Selected date from drop-down date-picker
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false); // For handling toggle of drop-down date-picker
    const [errors, setErrors] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isAddingField, setIsAddingField] = useState(false);
    const [newCustomFieldName, setNewCustomFieldName] = useState("");
    const [isSavingNewField, setIsSavingNewField] = useState(false);
    const [removingUuid, setRemovingUuid] = useState(null); // Track which field is being removed
    const dropzoneRef = useRef();

    useEffect(() => {
        if (formData.visibleToEmployees && formData.visibleToClients) {
            setVisibleTo(VISIBLE_TO.EMPLOYEES_AND_CLIENTS);
        } else if (formData.visibleToEmployees) {
            setVisibleTo(VISIBLE_TO.EMPLOYEES);
        } else if (formData.visibleToClients) {
            setVisibleTo(VISIBLE_TO.CLIENTS);
        }
        // Set expiry option
        if (formData.expirationDate) {
            setSelectedDate(formData.expirationDate);
        } else {
            if (updatingUuid) {
                setNoExpDate(true);
            }
        }
        // Set selectedImage if there's an image URL in formData
        if (formData.imageUrl) {
            setSelectedImage(formData.imageUrl);
        }
    }, [formData]);

    const handleInputChange = (e, key) => {
        // Remove input's error message
        removeErrorMessage(key);

        const updatedContactData = { ...contactData, [key]: e.target.value };
        setContactData(updatedContactData);
    };

    const handleCustomFieldChange = (e, fieldName) => {
        const updatedCustomFields = contactData.customFields.map(field =>
            field.name === fieldName ? { ...field, value: e.target.value } : field
        );

        setContactData(prevContactData => ({
            ...prevContactData,
            customFields: updatedCustomFields
        }));
    };

    const handleNewCustomFieldChange = e => {
        setNewCustomFieldName(e.target.value);
    };

    const handleFormSubmit = () => {
        if (hasFormErrors()) {
            return;
        }
        // create/update contact
        if (selectedImage && formData.imageUrl !== selectedImage) {
            const imageFile = dataURLtoFile(selectedImage, "contact-image");
            handleCreateOrUpdate(contactData, imageFile);
        } else {
            handleCreateOrUpdate(contactData, null);
        }
    };

    const dataURLtoFile = (dataUrl, filename) => {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bStr = atob(arr[1]);
        let n = bStr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bStr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleVisibleTo = type => {
        // Remove input's error message
        removeErrorMessage(ERROR_LIST.VISIBLE_TO.input);

        if (type === VISIBLE_TO.EMPLOYEES) {
            setContactData({ ...contactData, visibleToEmployees: true, visibleToClients: false });
        } else if (type === VISIBLE_TO.CLIENTS) {
            setContactData({ ...contactData, visibleToEmployees: false, visibleToClients: true });
        } else if (type === VISIBLE_TO.EMPLOYEES_AND_CLIENTS) {
            setContactData({ ...contactData, visibleToEmployees: true, visibleToClients: true });
        }
        setVisibleTo(type);
    };

    const handleExpDateCheckbox = event => {
        // Remove input's error message
        removeErrorMessage(ERROR_LIST.EXPIRATION_DATE.input);

        const { checked } = event.target;
        setNoExpDate(checked);
        setSelectedDate(null);
        // Update contact data
        setContactData({ ...contactData, expirationDate: null });
    };

    const handleDateChange = date => {
        // Remove input's error message
        removeErrorMessage(ERROR_LIST.EXPIRATION_DATE.input);

        setNoExpDate(false); // Uncheck no-expiry date checkbox
        setSelectedDate(date);
        setIsDatePickerOpen(false);
        // Update contact data
        setContactData({ ...contactData, expirationDate: moment(date).format("yyyy-MM-DD") });
    };

    const formatSelectedDate = date => {
        return moment(date).format("Do MMMM, YYYY");
    };

    const hasFormErrors = () => {
        let newErrors = [];
        if (!contactData.visibleToEmployees && !contactData.visibleToClients) {
            newErrors = [...newErrors, ERROR_LIST.VISIBLE_TO];
        }
        if (!contactData.title) {
            newErrors = [...newErrors, ERROR_LIST.TITLE];
        }
        if (!contactData.expirationDate && (!noExpDate && !selectedDate)) {
            newErrors = [...newErrors, ERROR_LIST.EXPIRATION_DATE];
        }

        setErrors(newErrors);
        return newErrors.length > 0;
    };

    const removeErrorMessage = key => {
        setErrors(prevErrors => prevErrors.filter(error => error.input !== key));
    };

    const hasErrorType = type => {
        return errors.find(error => error.input === type.input);
    };

    const handleDrop = acceptedFiles => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBrowseClick = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const GreenCheck = ({ active }) => active && <img src={CheckImg} style={{ height: "30px", position: "absolute", top: "10px", right: "5px" }} />;

    const handleAddFieldClick = () => {
        setIsAddingField(true);
    };

    const handleCancelClick = () => {
        setIsAddingField(false);
    };

    return (
        <Dialog
            open={showContactModal}
            maxWidth="md"
            scroll="body"
            PaperProps={{
                style: {
                    width: 900,
                    height: "auto",
                    borderRadius: 15,
                    overflow: "auto"
                }
            }}
        >
            <Box sx={{ padding: "1.563rem 3.125rem 0.625rem" }}>
                <Text size="2.313rem" weight="700" align="left" margin="0">
                    { updatingUuid ? "Edit Contact" : "New Contact" }
                </Text>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem" }}>
                <Label size="1.125rem" style={{ marginBottom: "0.625rem" }}>Visible to</Label>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} style={{ marginBottom: "1.875rem" }}>
                    <Grid item>
                        <RadioButton active={visibleTo === VISIBLE_TO.EMPLOYEES} onClick={() => handleVisibleTo(VISIBLE_TO.EMPLOYEES)}>
                            Employees
                            <GreenCheck active={visibleTo === VISIBLE_TO.EMPLOYEES} />
                        </RadioButton>
                    </Grid>
                    <Grid item>
                        <RadioButton active={visibleTo === VISIBLE_TO.CLIENTS} onClick={() => handleVisibleTo(VISIBLE_TO.CLIENTS)}>
                            Clients
                            <GreenCheck active={visibleTo === VISIBLE_TO.CLIENTS} />
                        </RadioButton>
                    </Grid>
                    <Grid item>
                        <RadioButton active={visibleTo === VISIBLE_TO.EMPLOYEES_AND_CLIENTS} onClick={() => handleVisibleTo(VISIBLE_TO.EMPLOYEES_AND_CLIENTS)}>
                            Employees & Clients
                            <GreenCheck active={visibleTo === VISIBLE_TO.EMPLOYEES_AND_CLIENTS} />
                        </RadioButton>
                    </Grid>
                    {hasErrorType(ERROR_LIST.VISIBLE_TO) && <ErrorMessage style={{ marginLeft: "12px" }}>{ERROR_LIST.VISIBLE_TO.message}</ErrorMessage>}
                </Grid>

                <Grid container spacing={3}>
                    {/* Left Column: Inputs */}
                    <Grid item xs={12} md={7}>
                        <Label size="1.125rem" style={{ marginBottom: "0.625rem" }}>Contact Information</Label>
                        <Grid container direction="column" spacing={2}>
                            <Grid item md={11}>
                                <Label>{"Person's Name or Company Name"}</Label>
                                <StyledTitleInput
                                    value={contactData.title ?? ""}
                                    placeholder="Enter your name here..."
                                    onChange={e => handleInputChange(e, "title")}
                                />
                                {hasErrorType(ERROR_LIST.TITLE) && <ErrorMessage>{ERROR_LIST.TITLE.message}</ErrorMessage>}
                            </Grid>
                            <Grid item md={11}>
                                <Label>Title/Position (Optional)</Label>
                                <StyledTitleInput
                                    value={contactData.position ?? ""}
                                    placeholder="Enter your position here..."
                                    onChange={e => handleInputChange(e, "position")}
                                />
                            </Grid>
                            <Grid item md={11}>
                                <Label>Department (Optional)</Label>
                                <StyledTitleInput
                                    value={contactData.department ?? ""}
                                    placeholder="Enter your department here..."
                                    onChange={e => handleInputChange(e, "department")}
                                />
                            </Grid>
                            <Grid item md={11}>
                                <Label>Email Address (Optional)</Label>
                                <StyledTitleInput
                                    value={contactData.email ?? ""}
                                    placeholder="Enter your email here..."
                                    onChange={e => handleInputChange(e, "email")}
                                />
                            </Grid>
                            <Grid item md={11}>
                                <Label>Phone Number (Optional)</Label>
                                <StyledTitleInput
                                    value={contactData.phoneNumber ?? ""}
                                    placeholder="Enter your phone number here..."
                                    onChange={e => handleInputChange(e, "phoneNumber")}
                                />
                            </Grid>
                            <Grid item md={11}>
                                <Label>Website (Optional)</Label>
                                <StyledTitleInput
                                    value={contactData.website ?? ""}
                                    placeholder="Enter your phone number here..."
                                    onChange={e => handleInputChange(e, "website")}
                                />
                            </Grid>
                            {/* Show Custom Fields */}
                            {contactData.customFields.length > 0 && contactData.customFields.map((field, index) => (
                                <Grid item key={field.uuid || `field-${index}`}>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item md={11}>
                                            <Label>{field.name}</Label>
                                            <StyledTitleInput
                                                value={field.value ?? ""}
                                                placeholder={`Enter your ${field.name} here...`}
                                                onChange={e => handleCustomFieldChange(e, field.name)}
                                            />
                                        </Grid>
                                        {!isFormLoading && (
                                            <Grid item md={1}>
                                                {removingUuid !== (field.uuid || `field-${index}`) ? (
                                                    <RemoveButton
                                                        src={RemoveImg}
                                                        alt="close image"
                                                        onClick={() => handleRemoveCustomField(
                                                            field, // Pass the entire field object
                                                            contactData,
                                                            setContactData,
                                                            setRemovingUuid
                                                        )}
                                                    />
                                                ) : (
                                                    <LoadingIndicator height="0.938rem" width="0.938rem" containerHeight="0" style={{ marginTop: "12px", marginLeft: "5px" }} />
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item md={11}>
                                <Label>Create Custom Field</Label>
                                {!isAddingField && (
                                    <>
                                        <Description size="0.75rem" weight="400" color="#8291b2">
                                            Create custom fields and display specific details of employees, companies <br />
                                            or members of your business to your app users.
                                        </Description>
                                        <Button
                                            backgroundColor="#000"
                                            color="#fff"
                                            width="8rem"
                                            height="2.375rem"
                                            style={{ borderRadius: "7px", marginTop: "10px" }}
                                            onClick={handleAddFieldClick}
                                        >
                                            Add Field
                                        </Button>
                                    </>
                                )}
                                {isAddingField && (
                                    <>
                                        <StyledTitleInput
                                            placeholder="Enter new field name"
                                            onChange={e => handleNewCustomFieldChange(e)}
                                        />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            style={{ marginTop: "7px" }}
                                        >
                                            {isSavingNewField ? (
                                                <div style={{ textAlign: "right", marginRight: "0.625rem" }}>
                                                    <LoadingIndicator height="0.938rem" width="0.938rem" containerHeight="0" />
                                                </div>
                                            ) : (
                                                <>
                                                    <Button
                                                        backgroundColor="#fff"
                                                        color="#000"
                                                        width="6.063rem"
                                                        height="2.375rem"
                                                        border="1px solid #000"
                                                        style={{ borderRadius: "7px" }}
                                                        onClick={handleCancelClick}
                                                    >
                                                        Cancel
                                                    </Button>

                                                    <Button
                                                        backgroundColor="#000"
                                                        color="#fff"
                                                        width="6.063rem"
                                                        height="2.375rem"
                                                        style={{ borderRadius: "7px", marginLeft: "10px" }}
                                                        onClick={() => handleCreateCustomField(
                                                            newCustomFieldName,
                                                            contactData,
                                                            setContactData,
                                                            setIsAddingField,
                                                            setNewCustomFieldName,
                                                            setIsSavingNewField
                                                        )}
                                                    >
                                                        Save
                                                    </Button>
                                                </>
                                            )}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid item md={12}>
                                <Label>Description (Optional)</Label>
                                <TextArea
                                    value={contactData.description ?? ""}
                                    placeholder="Enter your description here..."
                                    onChange={e => handleInputChange(e, "description")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Right Column: Image Container */}
                    <Grid item xs={12} md={5}>
                        <Label size="1.125rem">{updatingUuid ? "Change" : "Upload"} Image (Optional)</Label>
                        <StyledDropzone ref={dropzoneRef} onDrop={handleDrop}>
                            {selectedImage ? (
                                <ImagePreview src={selectedImage} alt="Uploaded" />
                            ) : (
                                <ImagePreview src={AttachIcon} alt="Attach Icon" />
                            )}
                        </StyledDropzone>
                        <FileInput type="file" accept="image/*" onChange={handleDrop} />
                        <Button
                            backgroundColor="#000"
                            color="#fff"
                            width="8.75rem"
                            height="2.375rem"
                            style={{ borderRadius: "7px", marginTop: "10px" }}
                            onClick={handleBrowseClick}
                        >
                            Browse File
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={7}>
                        <Label>Contact Expiry</Label>
                        <Description>
                            Set expiry dates for contacts that have a limited timeframe in your Directory, <br />
                            and we’ll remind you when it’s time to remove them. For ongoing contacts, <br />
                            simply select ‘None’ and the contact will remain seamlessly in your Me <br />
                            Business directory.
                        </Description>
                    </Grid>
                    <Grid item sm={12} md={5}>
                        <Label>Select 1 Option:</Label>
                        <Grid container direction="row" justifyContent="flex-start" style={{ marginTop: "3px" }}>
                            <DatePickerContainer>
                                <Button
                                    size="0.875rem"
                                    width="8.75rem"
                                    height="2.375rem"
                                    color="#000"
                                    border="1px solid #000"
                                    style={{ borderRadius: "7px", display: "block", padding: "0" }}
                                    onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                                >
                                    {selectedDate ? "Change Expiry" : "Set Expiry Date"}
                                </Button>
                                {isDatePickerOpen && (
                                    <DatePickerWrapper>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            inline
                                        />
                                    </DatePickerWrapper>
                                )}
                            </DatePickerContainer>
                            <span style={{ width: "0px", height: "38px", border: "1px solid #000", margin: "0 23px" }} />
                            <CheckboxContainer>
                                <label>
                                    <Checkbox
                                        color="#36be24"
                                        height="38px"
                                        width="38px"
                                        padding="7px"
                                        radius="12px"
                                        checked={noExpDate}
                                        onChange={event => handleExpDateCheckbox(event)}
                                    />
                                </label>
                                <Text
                                    color="#000"
                                    size="0.875rem"
                                    weight="500"
                                    margin="0 0 0 10px"
                                >
                                    None
                                </Text>
                            </CheckboxContainer>
                            {hasErrorType(ERROR_LIST.EXPIRATION_DATE) && <ErrorMessage style={{ marginTop: "0.625rem" }}>{ERROR_LIST.EXPIRATION_DATE.message}</ErrorMessage>}
                        </Grid>
                        {selectedDate && (
                            <DateContainer>
                                <SelectedDate>
                                    {formatSelectedDate(selectedDate)}
                                </SelectedDate>
                            </DateContainer>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem 2.188rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {updatingUuid && !isFormLoading && (
                            <Button
                                style={{ borderRadius: "10px" }}
                                size="0.875rem"
                                backgroundColor="#ff5353"
                                color="#fff"
                                width="8.75rem"
                                onClick={() => handleShowDeleteDialog()}
                            >
                            Delete
                            </Button>
                        )}
                    </div>
                    <div style={{ textAlign: "right" }}>
                        {isFormLoading ? <LoadingIndicator height="1.563rem" width="1.563rem" containerHeight="0" /> : (
                            <>
                                <Button
                                    onClick={handleCloseModal}
                                    size="0.875rem"
                                    border="1px solid #000"
                                    color="#000"
                                    marginRight="15px"
                                    width="8.75rem"
                                    style={{ borderRadius: "7px", boxShadow: "none" }}
                                >
                                Cancel
                                </Button>
                                <Button
                                    size="0.875rem"
                                    backgroundColor="#006cff"
                                    color="#fff"
                                    border="1px solid #006cff"
                                    width="8.75rem"
                                    style={{ borderRadius: "7px", boxShadow: "none" }}
                                    onClick={() => handleFormSubmit()}
                                >
                                Post
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Box>
        </Dialog>
    );
};

export default ContactModal;