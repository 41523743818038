import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { FlexContainer, Text } from "src/components";
import { GridContainer, GridChild } from "../Utils";

const InfoBox = styled.div`
    width: ${p => p.width };
    height: 50px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E9EBEF;
    border-radius: 12px;
    opacity: 1;
`;

export class SurveyInfo extends Component {
    render() {
        const { survey } = this.props;

        const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = survey["startDate"] ? formatter.format(new Date(survey["startDate"])) : "...";
        const formattedFinishDate = survey["finishDate"] ? formatter.format(new Date(survey["finishDate"])) : "...";

        return (
            <GridContainer align="center" style={{ gridTemplateColumns: "85% 15%" }} >
                <GridContainer align="center" width="100%" style={{ gridTemplateColumns: "auto auto", gridGap: "16px" }} >
                    <GridChild style={{ textAlign: "left" }}>
                        <Text color="#51315D" size="16px" weight="bold" align="left">
                            Survey Name:
                        </Text>
                        <InfoBox width="430px">
                            <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal" }}>
                                {survey.name}
                            </div>
                        </InfoBox>

                        <Text color="#51315D" size="16px" weight="bold" align="left">
                            User Group Selected:
                        </Text>
                        <InfoBox width="430px">
                            <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal" }}>
                                {survey.userGroup}
                            </div>
                        </InfoBox>
                    </GridChild>

                    <GridChild style={{ textAlign: "right" }}>
                        <Text color="#51315D" size="16px" weight="bold" align="left">
                            Survey Type:
                        </Text>
                        <InfoBox width="430px">
                            <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal" }}>
                                {survey.surveyType}
                            </div>
                        </InfoBox>
                        
                        <Text color="#51315D" size="16px" weight="bold" align="left">
                            Survey Start/Finish Date:
                        </Text>
                        <FlexContainer direction="row" style={{ gap: "16px" }}>
                            <InfoBox width="207px">
                                <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal" }}>
                                    {moment(formattedStartDate).format("Do MMMM, YYYY")}
                                </div>
                            </InfoBox>
                            <InfoBox width="207px">
                                <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal" }}>
                                    {moment(formattedFinishDate).format("Do MMMM, YYYY")}
                                </div>
                            </InfoBox>
                        </FlexContainer>
                    
                    </GridChild>
                </GridContainer>
            </GridContainer>
        );
    }
}