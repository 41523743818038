import React from "react";
import styled, { keyframes } from "styled-components";
import {
    Text, Button, FormField, mobile, tablet, desktop
} from "src/components";

import CorrectIcon from "src/img/survey/correct.png";
import WrongIcon from "src/img/survey/wrong.png";

import IconRating from "src/img/survey/type_star.png";
import IconSatisfactionBad from "src/img/survey/type_bad.png";
import IconSatisfactionPoor from "src/img/survey/type_poor.png";
import IconSatisfactionOk from "src/img/survey/type_ok.png";
import IconSatisfactionGreat from "src/img/survey/type_great.png";
import IconSatisfactionExcellent from "src/img/survey/type_excellent.png";
import IconYes from "src/img/survey/type_yes.png";
import IconNo from "src/img/survey/type_no.png";
import IconFeedBack from "src/img/survey/type_comment.png";

import IconRatingSelected from "src/img/survey/type_star_Selected.png";
import IconSatisfactionBadSelected from "src/img/survey/type_bad_Selected.png";
import IconSatisfactionPoorSelected from "src/img/survey/type_poor_Selected.png";
import IconSatisfactionOkSelected from "src/img/survey/type_ok_Selected.png";
import IconSatisfactionGreatSelected from "src/img/survey/type_great_Selected.png";
import IconSatisfactionExcellentSelected from "src/img/survey/type_excellent_Selected.png";
import IconYesSelected from "src/img/survey/type_yes_Selected.png";
import IconNoSelected from "src/img/survey/type_no_Selected.png";
import IconFeedBackSelected from "src/img/survey/type_comment_Selected.png";

/*Survey Insights */
import InsightIconRating from "src/img/survey/insight_star.png";
import InsightIconSatisfactionBad from "src/img/survey/insight_bad.png";
import InsightIconSatisfactionPoor from "src/img/survey/insight_poor.png";
import InsightIconSatisfactionOk from "src/img/survey/insight_ok.png";
import InsightIconSatisfactionGreat from "src/img/survey/insight_great.png";
import InsightIconSatisfactionExcellent from "src/img/survey/insight_excellent.png";
import InsightIconYes from "src/img/survey/insight_yes.png";
import InsightIconNo from "src/img/survey/insight_no.png";

import InsightIconRatingSelected from "src/img/survey/insight_star_selected.png";
import InsightIconSatisfactionBadSelected from "src/img/survey/insight_bad_selected.png";
import InsightIconSatisfactionPoorSelected from "src/img/survey/insight_poor_selected.png";
import InsightIconSatisfactionOkSelected from "src/img/survey/insight_ok_selected.png";
import InsightIconSatisfactionGreatSelected from "src/img/survey/insight_great_selected.png";
import InsightIconSatisfactionExcellentSelected from "src/img/survey/insight_excellent_selected.png";
import InsightIconYesSelected from "src/img/survey/insight_yes_selected.png";
import InsightIconNoSelected from "src/img/survey/insight_no_selected.png";

import AddIcon from "src/img/survey/add.png";
import RemoveIcon from "src/img/survey/close.png";

export const NextStepButton = styled(Button)`
    background: #51315D;
    color: #ffffff;
`;

export const CancelButton = styled(Button)`
    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    height: ${ p => p.height || "38px"};
    border-radius: ${ p => p.borderRadius || ""};
`;

export const SaveButton = styled(Button)`
    background: ${ p => p.backgroundColor || "#ffffff"};
    color: ${ p => p.color || "#000000"};
    border: ${ p => p.border || "1px solid #000000"};
    height: ${ p => p.height || "38px"};
    border-radius: ${ p => p.borderRadius || ""};
`;

export const GridSurveyTypeContainer = styled.div``;
export const GridSurveyTypeChildStart = styled.div`
justify-content: start;
`;
export const GridSurveyTypeChildEnd = styled.div`
justify-content: end;
`;

export const GridUserGroupContainer = styled.div``;
export const GridUserGroupChild = styled.div`
justify-content: center;
`;

/*Back and Next Step Container */
export const GridContainer = styled.div`
`;

export const GridChild = styled.div`
margin: ${ p => p.margin || "0 10px 0 10px"};
`;

export const StyledButton = styled(Button)`
    color: ${ p => p.color || "white"};
    background-color: ${ p => p.backgroundColor || "#51315D"};
    border: ${ p => p.border || "none"};
`;

export const ImageGroupRating = (({ isSelected }) => {
    if (isSelected)
    {
        return (
            <>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected1"/>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected2"/>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected3"/>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected4"/>
                <img className="icon-surveType" src={IconRatingSelected} alt="IconRatingSelected5"/>
            </>
        );
    }
    else
    {
        return (
            <>
                <img className="icon-surveType" src={IconRating} alt="IconRating1"/>
                <img className="icon-surveType" src={IconRating} alt="IconRating2"/>
                <img className="icon-surveType" src={IconRating} alt="IconRating3"/>
                <img className="icon-surveType" src={IconRating} alt="IconRating4"/>
                <img className="icon-surveType" src={IconRating} alt="IconRating5"/>
            </>
        );
    }
});

export const ImageGroupSatisfaction = (({ isSelected }) => {
    if (isSelected)
    {
        return (
            <>
                <img className="icon-surveType" src={IconSatisfactionBadSelected} alt="IconSatisfactionBadSelected"/>
                <img className="icon-surveType" src={IconSatisfactionPoorSelected} alt="IconSatisfactionPoorSelected"/>
                <img className="icon-surveType" src={IconSatisfactionOkSelected} alt="IconSatisfactionOkSelected"/>
                <img className="icon-surveType" src={IconSatisfactionGreatSelected} alt="IconSatisfactionGreatSelected"/>
                <img className="icon-surveType" src={IconSatisfactionExcellentSelected} alt="IconSatisfactionExcellentSelected"/>
            </>
        );
    }
    else
    {
        return (
            <>
                <img className="icon-surveType" src={IconSatisfactionBad} alt="IconSatisfactionBad"/>
                <img className="icon-surveType" src={IconSatisfactionPoor} alt="IconSatisfactionPoor"/>
                <img className="icon-surveType" src={IconSatisfactionOk} alt="IconSatisfactionOk"/>
                <img className="icon-surveType" src={IconSatisfactionGreat} alt="IconSatisfactionGreat"/>
                <img className="icon-surveType" src={IconSatisfactionExcellent} alt="IconSatisfactionExcellent"/>
            </>
        );
    }
});

export const ImageGroupYesNo = (({ isSelected }) => {
    if (isSelected)
    {
        return (
            <>
                <img className="icon-surveType" src={IconYesSelected} alt="IconYesSelected"/>
                <img className="icon-surveType" src={IconNoSelected} alt="IconNoSelected"/>
            </>
        );
    }
    else
    {
        return (
            <>
                <img className="icon-surveType" src={IconYes} alt="IconYes"/>
                <img className="icon-surveType" src={IconNo} alt="IconNo"/>
            </>
        );
    }
});

export const ImageGroupFeedback = (({ isSelected }) => {
    if (isSelected)
    {
        return (
            <>
                <img className="icon-surveType" src={IconFeedBackSelected} alt="IconFeedBackSelected"/>
            </>
        );
    }
    else
    {
        return (
            <>
                <img className="icon-surveType" src={IconFeedBack} alt="IconFeedBack"/>
            </>
        );
    }
});

const CorrectIconContainer = styled.div`
    position: ${ p => p.position };
    left: ${ p => p.left || "60%"};
    top: ${ p => p.top || "-35px"};
    display: ${ p => p.display || "none"};
`;

export const CorrectImage = (({ display }) => {
    return (
        <>
            <CorrectIconContainer display={display}><img className="icon-correct" src={CorrectIcon} alt="CorrectIcon"/></CorrectIconContainer>
        </>
    );
});

export const WrongImage = (({ display }) => {
    return (
        <>
            <CorrectIconContainer display={display}><img className="icon-correct" src={WrongIcon} alt="WrongIcon"/></CorrectIconContainer>
        </>
    );
});

export const CorrectImageSurveyType = (({ display }) => {
    return (
        <>
            <CorrectIconContainer display={display} left="170%" top="-37px"><img className="icon-correct" src={CorrectIcon} alt="CorrectIcon"/></CorrectIconContainer>
        </>
    );
});

/* Question Page */

export const RemoveQuestionIconContainer = (({ display, position, removeQuestion, questionElement }) => {
    return (
        <>
            <CorrectIconContainer display={display} position={position} left="unset" style={{ right: "-10%", top: "50%" }}><img className="icon-remove" src={RemoveIcon} alt="RemoveIcon" onClick={(event) => {removeQuestion(questionElement);}} style={{ height: "51px" }}/></CorrectIconContainer>
        </>
    );
});

export const QuestionContainer = (({ questionNumber, inputQuestion, questionValue, surveyQuestionError, removeQuestion }) => {
    return (
        <>
            <Text color="#000" align="left" size="16px" weight="bolder">
                Question {questionNumber}
            </Text>
            <FormField borderRadius="12px" size="16px" padding="10px 20px" height="50px"
                placeholderColor="#000000" placeholderAlignment="left" border="1px solid #E9EBEF"
                align="left"
                width="100%"
                type="text"
                margin={questionNumber > 1 ? "0" : ""}
                placeholder="Enter your question here..."
                onChange={(event) => {inputQuestion(questionNumber, event);} }
                errors={surveyQuestionError}
                value={questionValue}
                fontWeight="500"
                showErrors={surveyQuestionError.length > 0}
            />
        </>
    );
});

export const AddAnotherQuestionContainer = ({ addQuestion }) => {
    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", border: "1px solid #E9EBEF", borderRadius: "12px", alignItems: "center", boxShadow: "0px 3px 6px #00000029", height: "50px", cursor: "pointer", margin: "3.25rem 0 16px 0" }} onClick={(event) => {addQuestion();} }>
                <Text margin="10px 0.5rem 10px 1.5rem" size="16px" weight="500">
                    <img className="add-question" src={AddIcon} alt="AddIcon" height="31px"/>
                </Text>
                <Text size="16px" weight="500">
                    Add Another Question <span style={{ fontWeight: "normal" }}>(Max 3 Questions)</span>
                </Text>
            </div>
        </>
    );
};

export const InputSelector = styled.select`
    font-size: ${p => p.size || "0.8rem"};
    color: ${ p => p.color || "#000"};
    font-weight: ${ p => p.fontWeight || "bolder" };
    border: ${ p => p.border || "none" };
    border-bottom: ${ p => p.borderBottom };
    margin: ${ p => p.margin || "5px 0" };
    box-sizing: border-box;
    
    overflow: ${p => p.hiddenOverFlow && "hidden"};
    ::placeholder {
        opacity: ${ p => p.opacity || "initial" };
        color: ${ p => p.placeholderColor || "#6A1E00" };
        font-weight: 400;
    };
    
`;

export const EditQuestionContainer = (({ questionNumber, inputQuestion, questionValue, surveyQuestionError, removeQuestion }) => {
    return (
        <>
            <Text color="#000" align="left" size="16px" weight="bolder">
                Question {questionNumber}
            </Text>
            <FormField borderRadius="12px" size="16px" padding="10px 20px" height="50px"
                placeholderColor="#000000" placeholderAlignment="left" border="1px solid #E9EBEF"
                margin="5px 0 0 0"
                align="left"
                width="100%"
                type="text"
                placeholder="Enter your question here..."
                onChange={(event) => {inputQuestion(questionNumber - 1, event);} }
                errors={surveyQuestionError}
                value={questionValue}
                fontWeight="500"
                showErrors={surveyQuestionError.length > 0}
            />  
        </>
    );
});

/* Page Insight */

export const ImageGroupRatingInsight = (({ isSelected, score1 = 0, score2 = 0, score3 = 0, score4 = 0, score5 = 0 }) => {
    return (
        <>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score5" && score5 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating5"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score5}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">5 Stars</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score4" && score4 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating4"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score4}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">4 Stars</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score3" && score3 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating3"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score3}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">3 Stars</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score2" && score2 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating2"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score2}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">2 Stars</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "score1" && score1 > 0 ? InsightIconRatingSelected : InsightIconRating} alt="InsightIconRating1"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{score1}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">1 Stars</Text>
                </GridChild>
            </GridContainer>
        </>
    );
});

export const ImageGroupSatisfactionInsight = (({ isSelected, bad = 0, poor = 0, ok = 0, great = 0, excellent = 0 }) => {
    return (
        <>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "excellent" && excellent > 0 ? InsightIconSatisfactionExcellentSelected : InsightIconSatisfactionExcellent} alt="InsightIconSatisfactionExcellent"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{excellent}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Excellent</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "great" && great > 0 ? InsightIconSatisfactionGreatSelected : InsightIconSatisfactionGreat} alt="InsightIconSatisfactionGreat"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{great}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Great</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "ok" && ok > 0 ? InsightIconSatisfactionOkSelected : InsightIconSatisfactionOk} alt="InsightIconSatisfactionOk"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{ok}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Ok</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "poor" && poor > 0 ? InsightIconSatisfactionPoorSelected : InsightIconSatisfactionPoor} alt="InsightIconSatisfactionPoor"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{poor}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Poor</Text>
                </GridChild>
            </GridContainer>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "bad" && bad > 0 ? InsightIconSatisfactionBadSelected : InsightIconSatisfactionBad} alt="InsightIconSatisfactionBad"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{bad}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Bad</Text>
                </GridChild>
            </GridContainer>
        </>
    );
});

export const ImageGroupYesNoInsight = (({ isSelected, yes = 0, no = 0 }) => {
    return (
        <>
            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "yes" && yes > 0 ? InsightIconYesSelected : InsightIconYes} alt="InsightIconYes"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{yes}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Answered &rdquo;Yes&rdquo;</Text>
                </GridChild>
            </GridContainer>

            <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                <GridChild margin="0">
                    <img className="icon-surveyInsight" src={isSelected === "no" && no > 0 ? InsightIconNoSelected : InsightIconNo} alt="InsightIconNo"/>
                </GridChild>
                <GridChild margin="0">
                    <Text color="#000" size="20px" weight="500" align="left" margin="0">{no}</Text>
                    <Text color="#000" size="13px" weight="500" align="left" margin="0">Answered &rdquo;No&rdquo;</Text>
                </GridChild>
            </GridContainer>
        
        </>
    );
});


export const FormBackground = styled.div`
    position: relative;
    width: 100%;
    max-width: 1512px;
    background: #FFF;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #8291B2;
    box-sizing: border-box;
    margin-bottom: 2.5rem;
    
    ${tablet`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${mobile`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${desktop`
        padding: 0;
        flex-direction: row;
    `};
`;

export const FormSectionHeader = styled.div`
    font-size: 25px;
    color: #612684;
    letter-spacing: 0px;
`;

export const DropdownSelector = styled.select`
    width: 100%;
    border-radius: 12px;
    text-decoration: none;
    color: white;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 0.3em 1.1em;
    color: #000000;
    font-size: 16px;
    font-family: Roboto, Helvetica, sans-serif;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='45' viewBox='0 0 24 24' width='35' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;
`;

export const LoadingIndicatorButton = styled.div`
    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    height: ${ p => p.height || "38px"};
    width: ${ p => p.width || "150px"};
    display: flex;
    border-radius: ${ p => p.borderRadius || "12px"};
`;

export const Animation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
// Code from https://projects.lukehaas.me/css-loaders/
export const LoadingIndicatorIcon = styled.div`
    animation: ${Animation} 1.1s infinite linear;
    border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid rgb(0, 0, 0);
    border-radius: 50%;
    border-right: 1.1em solid rgba(0, 0, 0, 0.2);
    border-top: 1.1em solid rgba(0, 0, 0, 0.2);
    font-size: 5px;
    height: ${p => p.height || "10em" };
    margin: auto;
    position: relative;
    width: ${p => p.width || "10em" };

    &:after {
        border-radius: 50%;
        height: 10em;
        width: 10em;
    }
`;

export const StyledCheckbox = styled.div`
background-clip: content-box;
background-color: ${props => (props.checked ? props.color || "#85b336" : "white")};
border: 1px solid #2D3037;
box-sizing: border-box;
height: ${p => p.height || "20px"};
padding: ${p => p.padding || "5px"};
border-radius: ${ p => p.radius || "0"};
transition: all 150ms;
width: ${p => p.width || "20px"};
`;

export const countSurveyResponseValue = (responsesStats, responseType, surveyQuestionUuid) => {
    let surveyQuestionResponses = responsesStats.filter((item) => 
    { 
        if (item.surveyQuestionUuid === surveyQuestionUuid) {
            return item;
        }
    });

    return surveyQuestionResponses.filter((item) => item.answerChosen === responseType).length;
};

export const evaluateAverageCategory = (categoriesCountArray) => {
    /**
     * Forumla taken from: https://www.omnicalculator.com/statistics/five-star-rating
     * FORMULA USED: average rating = (5r5 + 4r4 + 3r3 + 2r2 + r1) / (r5 + r4 + r3 + r2 + r1)
     * r5 = Excellent / 5 stars / categoriesCountArray[4]
     * r4 = Great / 4 stars / categoriesCountArray[3]
     * r3 = Ok / 3 stars / categoriesCountArray[2]
     * r2 = Poor / 2 stars / categoriesCountArray[1]
     * r1 = Bad / 1 star / categoriesCountArray[0]
     * categoriesCountArray is arranged by 
     * satisfaction => [badCount, poorCount, okCount, greatCount, excellentCount]
     * rating => [oneStarCount, twoStarsCount, threeStarsCount, fourStarsCount, fiveStarsCount]
     * 
     */
    const r5 = (5 * categoriesCountArray[4]);
    const r4 = (4 * categoriesCountArray[3]);
    const r3 = (3 * categoriesCountArray[2]);
    const r2 = (2 * categoriesCountArray[1]);
    const total = categoriesCountArray[4] + categoriesCountArray[3] + categoriesCountArray[2] + categoriesCountArray[1] + categoriesCountArray[0];
    if (total === 0) {
        return 0;
    }

    return ((r5 + r4 + r3 + r2 + categoriesCountArray[0]) / total);
};