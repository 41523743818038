import React from "react";
import styled from "styled-components";

const ButtonIcon = styled.img`
    width: ${p => p.width || "23px"};
    height: ${p => p.height || "20px"};
    color: ${p => p.color || "#fff"};
    margin: ${p => p.margin || 0};
`;

const IconButton = ({ children, ...styleProps }) => {
    return (
        <ButtonIcon {...styleProps}>
            { children }
        </ButtonIcon>
    );
};

export default IconButton;