import React from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux/actions";

import styled from "styled-components";
import { Dialog, Divider } from "@material-ui/core/";
import Text from "./Text";
import Button from "./Button";

const Heading = styled(Text)`
    font-size: 2.5rem;
    font-weight: 700;
    margin: 5.125rem 0 2rem 0;
`;

const StyledButton = styled(Button)`
    color: #000;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 7px !important;
    width: 8.75rem;
    height: 2.3125rem;
    line-height: 50%;
`;

const RevokedPermissionsModal = ({ modalOpen, closeModal, refreshPermissions }) => {
    const handleClose = (event, reason) => {
        if (reason !== "backdropClick") {
            const currentPath = window.location.pathname;
            if (currentPath === "/chats") {
                refreshPermissions();
                closeModal();
                window.location.href = "/active";
    
                return;
            }

            closeModal();
            window.location.href = "/active";
        }
    };

    return (
        <Dialog open={modalOpen}
            onClose={(event, reason) => handleClose(event, reason)}
            fullWidth={false}
            maxWidth={false}
            disableEscapeKeyDown
            scroll="body"
            PaperProps={{
                style: {
                    border: "1px solid #8291B2",
                    width: "56.25rem",
                    height: "25rem",
                    textAlign: "center"
                },
            }}
        >
            <Heading>Permissions Revoked for this Feature</Heading>
            <Text>
                We’re sorry, but your access to this feature has been revoked. For more information,<br />
                please contact your Account Owner or Administrator.
            </Text>
            <Divider style={{ margin: "3.5625rem 0 2rem 0" }} />
            <StyledButton onClick={handleClose}>Done</StyledButton>
        </Dialog>
    );
};

const mapStateToProps = state => {
    return {
	  modalOpen: state.modalOpen,
    };
};

export default connect(mapStateToProps, { closeModal })(RevokedPermissionsModal);
