import React from "react";
import styled from "styled-components";
import ProgressBar from "./ProgressBar";

const MoodListContainer = styled.div`
    width: 200px;
    padding: 10px;
  `;

const ProgressContainer = styled.div`
    width: 175px;
    display: flex;
    justify-content: space-between;
`;

const MoodItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
    font: normal normal bold 25px / 33px Roboto;
`;

const MoodLabel = styled.div`
    width: 100px;
    letter-spacing: 0px;
    font-family: Roboto;
    font-size: .75rem;
    line-height: normal; 
    font-weight: medium;
`;

const MoodPercentageLabel = styled.div`/* width: 100px; */
    letter-spacing: 0px;
    font-family: Roboto;
    font-size: .75rem;
    line-height: normal;
    text-align: left;
    color: #000000;
    font-weight: medium;
`;

const MoodDistributionList = ({ moodData }) => {
    const totalCheckins = moodData.reduce((acc, item) => acc + item.count, 0);
    const maxCount = Math.max(...moodData.map(mood => mood.count));
    const moodEmoji = {
        Happy: "😁",
        Content: "😊",
        Neutral: "😐",
        Sad: "😔",
        Unhappy: "😢",
        Tired: "😴",
        Stressed: "😬",
    };
    return (
        <MoodListContainer>
            {moodData && moodData.map(mood => {
                const progressBarMoodCount = mood.count === 0 ? 0 : (mood.count / maxCount);
                const numberMoodCount = mood.count === 0 ? 0 : (mood.count / totalCheckins);
                return (
                    <MoodItem key={mood.moodType}>
                        <div style={{ margin: "0px 15px", fontSize: "30px" }}>
                            {moodEmoji[mood.moodType]}
                        </div>
                        <div style={{ flexDirection: "row", margin: "auto" }}>
                            <MoodLabel>{mood.moodType}</MoodLabel>
                            <ProgressContainer>
                                <ProgressBar value={ progressBarMoodCount * 100} moodType={mood.moodType} />
                                <MoodPercentageLabel>
                                    {Number(numberMoodCount * 100).toFixed(0)}%
                                </MoodPercentageLabel>
                            </ProgressContainer>
                        </div>
                    </MoodItem>
                );
            })}
        </MoodListContainer>
    );
};

export default MoodDistributionList;