/**
 * Styled components shared by Create, Reset, Restore components
 */
import { Button, FormField } from "src/components";
import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 850px;
    padding: 0 0 55px 0;
`;

export const Content = styled.div`
    background: #FFFFFF;
    position: relative;
    height: 613px;
    box-sizing: border-box;
    border: 1px solid #DBE5ED;
    border-radius: 8px;
`;

const StyledButton = styled(Button)`
    width: 184px;
    height: 55px;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 10px !important;
`;

export const BackButton = styled(StyledButton)`
    border: 1px solid #DBE5ED;
    background: #FFFFFF;
    color: #AFBBC6;
    margin: 0 21px 0 0;
`;

export const ConfirmButton = styled(StyledButton)`
    background: #006CFF;
    color: #FFFFFF;
    opacity: ${p => p.disabled ? 0.5 : 1};
    pointer-events: ${p => p.disabled ? "none" : "auto"};
`;

export const TextLabel = styled.label`
    font-size: 1rem;
    font-weight: 700;
`;

export const StyledInput = styled(FormField)`
    position: relative;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 5px 5px #0000001A;
    box-sizing: border-box;
    width: 324px;
    height: 49px;
    margin: 8px 0 18px 0;
`;

export const SubText = styled.div`
    width: 100%;
    max-width: 324px;
    text-align: center;
    font-size: 13px;
    color: #7B7B7B;
`;