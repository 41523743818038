import { useState } from "react";
import { isANonEmptyString } from "src/utils/helpers";

/**
 * custom hook for validating matrix key backup password requirements when creating and resetting backup key password
 * see T7385 - Matrix Key Backup Password Requirements
 * @returns {{
 *      password: string
 *      confirmPassword: string
 *      setPassword: Function
 *      setConfirmPassword: Function
 *      validatePassword: Function
 * }}
 */
export const usePasswordValidator = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handlePasswordValidation = (firstName, lastName, email) => {
        try {
            validatePassword(firstName, lastName, email);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const validatePassword = (firstName, lastName, email) => {
        validators(
            password,
            confirmPassword,
            firstName,
            lastName,
            email
        );
    };

    return {
        password,
        confirmPassword,
        setPassword,
        setConfirmPassword,
        handlePasswordValidation
    };
};

const toLowerCaseAndTrim = (str) => str.toLowerCase().trim();

const validators = (password, confirmPassword, firstName, lastName, email) => {
    if (password === "" || confirmPassword === "") {
        throw new Error("Passwords must be a valid string and cannot be empty.");
    }
    
    if (password.length < 8 || confirmPassword < 8) {
        throw new Error("Passwords must be at least 8 characters long.");
    }

    if (password.trim() !== confirmPassword.trim()) {
        throw new Error("Passwords do not match.");
    }

    const uniqueChars = new Set(password.trim());
    if (uniqueChars.size < 4) {
        throw new Error("Password must contain at least 4 unique characters.");
    }

    const sanitizedPassword = toLowerCaseAndTrim(password);

    if (sanitizedPassword === toLowerCaseAndTrim(firstName)) {
        throw new Error("Password cannot be equal to your first name.");
    }

    if (sanitizedPassword === toLowerCaseAndTrim(lastName)) {
        throw new Error("Password cannot be equal to your last name.");
    }

    const fullName = firstName.toLowerCase() + lastName.toLowerCase();
    if (sanitizedPassword.replace(/\s/g, "") === toLowerCaseAndTrim(fullName).replace(/\s/g, "")) {
        throw new Error("Password cannot be equal to your full name.");
    }

    if (isANonEmptyString(email)) {
        if (sanitizedPassword === email.toLowerCase()) {
            throw new Error("Password cannot be equal to your email address.");
        }

        const username = email.split("@")[0];
        if (sanitizedPassword === username.toLowerCase()) {
            throw new Error("Password cannot be equal to your email address' username.");
        }
    }
};