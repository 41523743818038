import React, { Component } from "react";
import styled, { css } from "styled-components";
import LeftText from "../components/LeftText";
import { SubscriptionContext } from "../SubscriptionContext";
import {
    FlexContainer, CardHeadingLabel, Text, Button
} from "src/components";
import { yrOrMth, convertToCurrency } from "src/utils/currency";
import {
    AUSTRALIA, GST, SUBSCRIPTION_MONTHLY_DESCRIPTION, SUBSCRIPTION_TYPE_MONTHLY, SUBSCRIPTION_YEARLY_DESCRIPTION
} from "src/constants/subscriptions";

export const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 400px;
    height: ${p => p.height || "50px"};
    align-items: ${p => p.alignItems || "center"};
    margin: ${ p => p.margin };
    background-color: ${p => p.color || "transparent"};
    justify-content: ${ p => p.justifyContent || ""};
    ${props => props.isTopBorder && css`
        border-top: 1px solid black;
    `};
    ${props => props.isBottomBorder && css`
        border-bottom: 1px solid black;
    `};
`;

const StyledText = styled(Text)`
    text-align: left;
    margin: ${ p => p.margin || 0};
    font-size: 1rem;
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
`;

const InlineText = styled.p`
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: ${p => p.weight || 500 };
    font-size: ${p => p.size || "1rem"};
`;

const Card = styled.div`
    background-color: #EDEDED;
    width: 98%;
    max-width: 26.375rem;
    height: 11.125rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 2.3125rem;
    box-sizing: border-box;
`;

class Price extends Component {
    static contextType = SubscriptionContext;

    back = () => {
        this.props.goToOverview();
    };

    render() {
        const { totalNumber, currentForm, Forms } = this.props;
        const { subscriptionType, country } = this.context;

        /** @type {SubscriptionCostResponse} */
        const costing = this.context.costing;

        const subTotal = parseFloat(totalNumber * (costing.fullSubCostPerUserUsd - costing.gstIncluded));
        const gst = country === AUSTRALIA ? parseFloat(subTotal * GST) : 0;
        const pricePerUser = parseFloat(costing.fullSubCostPerUserUsd - costing.gstIncluded);
        const totalAmount = subTotal + gst;

        // Check we're on an expected form page
        const isInitialSubscription = currentForm === Forms.PLAN || currentForm === Forms.REVIEW;
        const isAddingUsers = currentForm === Forms.PURCHASE || currentForm === Forms.CHECKOUT;
        const isRenewal = currentForm === Forms.RENEWAL;
        if (!isInitialSubscription && !isAddingUsers && !isRenewal) {
            console.log("Unknown form for Price: " + currentForm);
            return <></>;
        }

        return (
            <FlexContainer flexGrow="1">
                <FlexContainer>
                    <FlexContainer marginLeft="2.125rem" marginRight="2.125rem">
                        <CardHeadingLabel margin="0 0 1.375rem 0">Order Summary</CardHeadingLabel>
                        <StyledText weight="700" color="#8E4EB4">Subscription Type</StyledText>
                        <StyledText color="#8E4EB4" style={{ whiteSpace: "pre-line" }}>
                            {subscriptionType === SUBSCRIPTION_TYPE_MONTHLY 
                                ? SUBSCRIPTION_MONTHLY_DESCRIPTION
                                : SUBSCRIPTION_YEARLY_DESCRIPTION
                            }
                        </StyledText>

                        <TextContainer>
                            <LeftText>
                                <InlineText>Subscription Duration</InlineText>
                                <InlineText>Number of User Licenses</InlineText>
                                <InlineText>Price Per User License</InlineText>
                            </LeftText>
                            <LeftText align="right">
                                <InlineText weight="700">12 Months</InlineText>
                                <InlineText weight="700">
                                    { totalNumber }
                                </InlineText>
                                <InlineText weight="700">
                                    {convertToCurrency(pricePerUser)}/per {subscriptionType}
                                </InlineText>
                            </LeftText>
                        </TextContainer>
                    </FlexContainer>

                    <Card>
                        <LeftText margin="0">
                            <InlineText>Subtotal</InlineText>
                            { country === AUSTRALIA && <InlineText>GST</InlineText> }
                            <InlineText weight="700" size="1.25rem">Total</InlineText>
                        </LeftText>
                        <LeftText margin="0" align="right">
                            <InlineText>
                                { convertToCurrency(subTotal) }/per { yrOrMth(subscriptionType) }
                            </InlineText>
                            { country === AUSTRALIA &&
                                <InlineText>
                                    { convertToCurrency(gst) }/{ yrOrMth(subscriptionType) }
                                </InlineText>
                            }
                            
                            <InlineText weight="700" size="1.25rem">
                                USD { convertToCurrency(totalAmount) }/{ yrOrMth(subscriptionType) }
                            </InlineText>
                        </LeftText>
                    </Card>

                    <Button color="#000000" backgroundColor="#ffffff"
                        width="8.75rem" height="2.35rem"
                        border="1px solid #000000"
                        marginTop="5.75rem"
                        marginLeft="2.125rem"
                        size="15px"
                        style={{ borderRadius: 7, lineHeight: "100%" }}
                        onClick={() => this.back()}
                    >
                        Back
                    </Button>
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default Price;