import React from "react";
import styled from "styled-components";
import moment from "moment";
import NoticeboardCard from "./NoticeboardCard";
import { Button } from "src/components";
import Checkbox from "src/scenes/EmployeeList/components/Checkbox";
import AttachIcon from "src/img/new/attach-image.svg";
import { FILTER_NOTICE } from "./../Noticeboard";

const SERVERLESS_IMAGE_HANDLER_URL = "https://image.dailyfixme.com/";

// const maxImageSize = 4194304; // 4 MB

const GridContainer = styled.div`
    display: flex;
    padding: 1rem 0rem 1.5rem 1rem;
    box-shadow: 0px 5px 5px #00000026;
    border: 1px solid #8291B2;
    border-radius: 18px;
    background: ${ p => p.isActive ? "#EDF4FF 0% 0% no-repeat padding-box" : "inherit" };
    margin-bottom: 1em;
`;

const DateLabel = styled.div`
    text-align: left;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    opacity: 1;
    
`;

const GridItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 5px;
    flex-basis: calc(100% / 12 * ${({ xs }) => xs});
`;

const HeaderItem = styled.div`
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 600;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 16px;
    padding-bottom: 10px;
    color: #000000;
`;

const EditButton = styled(Button)`
    border-radius: 10px !important;
    color: #000000;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 700;
    text-align: left;
    align-items: center;
    border: 1px solid #000000;
`;

const DisplayViews = styled.div`
    font-family: Roboto, Helvetica, sans-serif;
    border: 1px solid #2D3037;
    height: 2rem;
    width: 7rem;
    cursor: pointer;
    letter-spacing: 0.35px;
    line-height: 2rem;
    color: white;
    text-align: center;
    background: #000000;
    border-radius: 7px;
    color: #FFFFFF;
    font-size: 0.9 rem;
`;

const DatePostedLabel = ({ isActive }) => {
    if (isActive) {
        return <div style={{ color: "#000000" }}>Date Posted</div>;
    } else {
        return <div style={{ color: "#006CFF" }}>Scheduled</div>;
    }
};

const DatePostedContent = ({ notice }) => {
    const isScheduled = notice.scheduleDate;
    let dateToUse = isScheduled ? notice.scheduleDate : notice.postedDate;

    if (!isScheduled && notice.postedDate === null) { // old notice post doesn't have scheduleDate & postedDate yet.
        dateToUse = notice.date;
    }

    return (
        <div>
            {moment(dateToUse).format("Do MMMM YYYY")}
            <br/>
            {moment(dateToUse).format("hh:mma")}
        </div>
    );
};

function NoticeboardContent({ noticeArray, fetching, checkedNotices, handleCheckboxChange, handleEditNotice, handleShowNoticeViewershipModal }) {
    return noticeArray.map((notice, index) => {
        const isActive = checkedNotices.includes(notice.uuid);
        return (
            <GridContainer key={ index } isActive={isActive} >
                <GridItem xs={0.25}>
                    <HeaderItem>
                        <label>
                            <Checkbox
                                readOnly={false}
                                checked={isActive}
                                onChange={() => handleCheckboxChange(notice.uuid)}
                            />
                        </label>
                    </HeaderItem>
                </GridItem>
                <GridItem xs={1.25}>
                    <HeaderItem>
                        <DatePostedLabel isActive={notice.isActive} /> 
                    </HeaderItem>   
                    <div style={{ display: "flex", height: "80%", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <DateLabel>
                            <DatePostedContent notice={notice} />
                        </DateLabel>
                        <EditButton onClick={() => handleEditNotice(notice)} >Edit Post</EditButton>
                        
                    </div>
                </GridItem>
                <GridItem xs={2.5}>
                    <HeaderItem>
                        <div>Subject</div>
                    </HeaderItem>
                    <NoticeboardCard content={notice.subject} loading={fetching} ></NoticeboardCard>
                </GridItem>
                <GridItem xs={4}>
                    <HeaderItem>
                        <div>Message</div>
                    </HeaderItem>
                    <NoticeboardCard content={notice.message} loading={fetching} ></NoticeboardCard>
                </GridItem>
                <GridItem xs={2.5}>
                    <HeaderItem>
                        <div>{notice.postType === "text" ? "No Attachment" : (notice.postType === "image" ? "Image" : "Video") }</div>
                    </HeaderItem>
                    <NoticeboardCard content={""} cardType="preview" loading={fetching} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "300px" }}>
                        <PostTypeAssetPreview notice={notice}/>
                    </NoticeboardCard>
                </GridItem>


                <GridItem xs={1.5}>
                    <HeaderItem>{notice.readerType === FILTER_NOTICE.EMPLOYEE ? "Employee" : "Client"} Views</HeaderItem>
                    <div style={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <DisplayViews onClick={() => handleShowNoticeViewershipModal(notice)} >{notice.readCount} Views</DisplayViews>
                    </div>
                </GridItem>
            </GridContainer>
        );
    });
}

export function getAssetUrl(notice) {
    if (notice.postType === "image" && notice.image) {
        return getImageUrl(notice.image.imageKey); //will analyze in future diff how serverless image works
    }
    else if (notice.postType === "video" && notice.video) {
        return notice.video.videoUrl;
    }

    return null;
}

export const getImageUrl = (imageKey) => {
    // do transcoder stuff... generate a JSON object... base64 encode it... append that to the image.dailyfixme.com url
    // then use that url as the key for the <img> tag in the noticeboard class...
    let transcoderJsonObject = `{
            "bucket": "dailyfixme.content",
            "key":"` + imageKey + `"
    }`;

    let Buffer = require("buffer/").Buffer;
    const encodedString = new Buffer(transcoderJsonObject).toString("base64");
    return SERVERLESS_IMAGE_HANDLER_URL + encodedString;
};


function PostTypeAssetPreview({ notice }) {
    return (
        <>
            {
                notice.postType === "text" &&
                <img align="center" style={{ height: "5.25em" }}
                    src={AttachIcon} 
                    alt={AttachIcon} />
            }

            {
                notice.postType === "image" &&
                <img align="center" style={{ height: "100%", maxHeight: "140px", width: "inherit", padding: "0px", objectFit: "contain" }}
                    src={getAssetUrl(notice)} 
                    alt={getAssetUrl(notice)} />
            }
            
            { /* Render video content here */
                notice.postType === "video" &&
                <video controls style={{ height: "100%", maxHeight: "140px", width: "inherit", padding: "0px", objectFit: "contain" }}>
                    <source src={getAssetUrl(notice)} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            }
        </>
    );
}
export default NoticeboardContent;