import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import {
    Toast,
    LoadingIndicator,
    FlexContainer,
    Text,
    AuthenticatedLink,
    PageContainer,
    ExplainParagraphLabel, PageHeadingLabel
} from "src/components";

import { GridContainer, GridChild, StyledButton, ImageGroupRatingInsight, ImageGroupSatisfactionInsight, ImageGroupYesNoInsight } from "../components/Utils";
import { EnumSurveyType } from "../components/Enums";

import InsightIconFeedback from "src/img/survey/insight_feedback.png";
import SurveyUpdatePreview from "src/img/SurveyUpdatePreview.png";

class SurveyInsightPage extends Component {
    state = {
        fetchingInsight: true,
        fetchingSurvey: true,
        fetchingResponseStats: true,
        survey: {
            name: "...",
            surveyType: "...",
            startDate: "",
            finishDate: "",
            userGroup: "..."
        },
        insights: {
            numberOfUsersInTargetGroup: "...",
            numberOfUsersPromptedForSurvey: "...",
            numberOfTotalSurveysSubmitted: "...",
            surveyQuestions: [{}],
        },
        responsesStats: {},
        message: "",
        inputErrors: [],
        setInputTypeAs: "text",
        loading: false,
    };

    componentDidMount() {
        this.safeToInit();
    }

    safeToInit = () => {
        this.populateInsights();
        this.populateSurvey();
        this.populateResponseStats();
    };

    populateSurvey = () => {
        const { surveyUuid } = this.props;
        let surveyResponse = {};
        let hasError = true;

        axios.get(`${API_URL}/survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            surveyResponse = res.data.survey;
            hasError = false;
        }).finally(() => {
            if (!hasError)
            {
                this.setState(prevState => ({
                    survey: surveyResponse,
                    fetchingSurvey: false,
                }));
            }
            else
            {
                this.setState(state => ({
                    fetchingSurvey: false,
                }));
            }
        }).catch(error => {
            if (!error.response) 
                return Toast.error(error.message);

            return Toast.error(error.response.data.error);
        });
    };

    populateInsights = () => {
        const { surveyUuid } = this.props;
        let surveyInsight = {};

        let hasError = true;

        axios.get(`${API_URL}/survey/company/insights/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            surveyInsight = res.data;
            hasError = false;
        }).finally(() => {
            if (!hasError)
            {
                this.setState(prevState => ({
                    insights: surveyInsight,
                    fetchingInsight: false,
                }));
            }
            else
            {
                this.setState(state => ({
                    fetchingInsight: false,
                }));
            }
        }).catch(error => {
            if (!error.response) 
                return Toast.error(error.message);

            return Toast.error(error.response.data.error);
        });
    };

    populateResponseStats = () => {
        const { surveyUuid } = this.props;
        let surveyResponsesStats = {};
        let hasError = true;

        axios.get(`${API_URL}/survey/company/responsesStats/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            surveyResponsesStats = res.data;
            hasError = false;
        }).finally(() => {
            if (!hasError)
            {
                this.setState(prevState => ({
                    responsesStats: surveyResponsesStats,
                    fetchingResponseStats: false,
                }));
            }
            else
            {
                this.setState(state => ({
                    fetchingResponseStats: false,
                }));
            }
        }).catch(error => {
            if (!error.response) 
                return Toast.error(error.message);

            return Toast.error(error.response.data.error);
        });
    };

    render() {
        const { survey, insights, responsesStats, fetchingInsight, fetchingSurvey, fetchingResponseStats } = this.state;
        const { showInsights, user } = this.props;

        return (
            <>
                <PageContainer>
                    <FlexContainer maxWidth="80rem" marginRight="3rem">

                        { !fetchingInsight && <SurveyUpdateComingSoon /> }

                        <Text align="left" color="#23262D" size="40px" weight="500" >
                            Survey Insights
                        </Text>

                        { fetchingInsight && <LoadingIndicator />}
                        { !fetchingInsight && <SurveyInfo showInsights={showInsights} survey={survey} fetchingInsight={fetchingInsight} /> }

                        { !fetchingInsight && (fetchingSurvey || fetchingResponseStats) && <LoadingIndicator />}
                        { !fetchingSurvey && !fetchingResponseStats && <SurveyInsights user={user} survey={survey} insights={insights} responsesStats={responsesStats} /> }
                    </FlexContainer>
                </PageContainer>
            </>
        );
    }
}

class SurveyUpdateComingSoon extends Component {
    render() {
        return (<>
            <PageHeadingLabel>
                New Update Coming Soon!
            </PageHeadingLabel>
            <ExplainParagraphLabel>
                Exciting updates are coming soon to Survey Insights at Me Business! Get ready to dive into a completely
                revamped user interface designed to enhance your experience. We’re introducing new features that will
                empower you to harness the full potential of your surveys, making it easier and more intuitive than ever
                to gather and analyse feedback. Stay tuned for a transformation that will revolutionise how you interact
                with survey data!
            </ExplainParagraphLabel>
            <img style={{ width: "100%", height: "auto", margin: "auto auto 0" }} src={SurveyUpdatePreview} alt="Survey update preview" />
        </>);
    }
}

class SurveyInfo extends Component {
    render() {
        const { showInsights, survey } = this.props;

        const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = survey["startDate"] ? formatter.format(new Date(survey["startDate"])) : "...";
        const formattedFinishDate = survey["finishDate"] ? formatter.format(new Date(survey["finishDate"])) : "...";

        return (
            <>
                <GridContainer align="center" style={{ gridTemplateColumns: "60% 40%" }} >                    
             
                    <GridChild style={{ textAlign: "left" }}>
                       
                        <GridContainer>
                            <GridChild style={{ textAlign: "left" }}>
                                <Text color="#51315D" size="25px" weight="bold" align="left">
                                    Survey Overview
                                </Text>
                            </GridChild>
                        </GridContainer>

                        <GridContainer align="center" style={{ gridTemplateColumns: "50% 50%" }} >                    
                            <GridChild style={{ textAlign: "left" }}>
                            
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                    Survey Name:
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">{survey.name}</Text>
                                </Text>
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                    Survey Type:
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">{survey.surveyType}</Text>
                                </Text>
                            </GridChild>

                            <GridChild style={{ textAlign: "right" }}>
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                    User Group Selected:
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">{survey.userGroup}</Text>
                                </Text>
                                
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                    Survey Start/Finish Date:
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">Start: {formattedStartDate}</Text>
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">Finish: {formattedFinishDate}</Text>
                                </Text>
                            
                            </GridChild>

                        </GridContainer>
                    </GridChild>

                    <GridChild style={{ textAlign: "right" }}>
                        <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "150px", height: "40px" }}
                            onClick={() => { showInsights(false); }} >
                            Close
                        </StyledButton>
                    </GridChild>

                </GridContainer>
            </>
        );
    }
}

class SurveyInsights extends Component {
    state = {
        fetching: true,
        surveyQuestions: [{}],
        question1: "",
        questionUuid1: "",
        response1: { isSelect: false },

        question2: "",
        questionUuid2: "",
        response2: { isSelect: false },

        question3: "",
        questionUuid3: "",
        response3: { isSelect: false },

        errorAnswerKeys: [],
    };

    componentDidMount() {
        this.initializeSurveyInsightsState();
    }

    componentDidUpdate() {
        this.notifyUnknownAnswers();
    }

    /** this function will probably be seldom to be seen as we now sanitised the inputs in the backend */
    notifyUnknownAnswers = () => {
        const { survey } = this.props;
        const { errorAnswerKeys } = this.state;
        
        if (errorAnswerKeys.length > 0) {
            switch (survey.surveyType)
            {
            case EnumSurveyType.RATING: this.toastErrorMessage("Unknown '" + errorAnswerKeys.join() + "' answer for Rating Type"); break;
            case EnumSurveyType.SATISFACTION: this.toastErrorMessage("Unknown '" + errorAnswerKeys.join() + "' answer for Satisfaction Type"); break;
            case EnumSurveyType.YESNO: this.toastErrorMessage("Unknown '" + errorAnswerKeys.join() + "' answer for Yes/No Type"); break;
            default:
                //do nothing
                break;
            }
        }
    };

    initializeSurveyInsightsState = () => {
        const { survey, responsesStats } = this.props;

        const surveyQuestions = survey.surveyQuestions;

        const question1 = (surveyQuestions[0] === undefined) ? "" : surveyQuestions[0].question;
        const question2 = (surveyQuestions[1] === undefined) ? "" : surveyQuestions[1].question;
        const question3 = (surveyQuestions[2] === undefined) ? "" : surveyQuestions[2].question;
        
        const questionUuid1 = (surveyQuestions[0] === undefined) ? "" : surveyQuestions[0].uuid;
        const questionUuid2 = (surveyQuestions[1] === undefined) ? "" : surveyQuestions[1].uuid;
        const questionUuid3 = (surveyQuestions[2] === undefined) ? "" : surveyQuestions[2].uuid;

        const response1 = this.getResponsesForQuestionUuid(survey.surveyType, responsesStats, questionUuid1);
        const response2 = this.getResponsesForQuestionUuid(survey.surveyType, responsesStats, questionUuid2);
        const response3 = this.getResponsesForQuestionUuid(survey.surveyType, responsesStats, questionUuid3);

        this.setState(({
            surveyQuestions: surveyQuestions,
            question1: question1,
            questionUuid1: questionUuid1,
            response1: response1, 

            question2: question2,
            questionUuid2: questionUuid2,
            response2: response2,

            question3: question3,
            questionUuid3: questionUuid3,
            response3: response3, 

            fetching: false
        }));
    };

    toastErrorMessage = (message) => {
        return Toast.error(message);
    };

    getResponsesForQuestionUuid = (surveyType, responsesStats, surveyQuestionUuid) => {
        let scores = {
            rating: {
                score5: 0,
                score4: 0,
                score3: 0,
                score2: 0,
                score1: 0,
            },
            satisfaction: {
                excellent: 0,
                great: 0,
                ok: 0,
                poor: 0,
                bad: 0,
            },
            yesno: {
                yes: 0,
                no: 0
            }
        };

        let errorSurveyType = [];

        responsesStats.forEach(response => {
            if (response.surveyQuestionUuid === surveyQuestionUuid)
            {   
                switch (surveyType)
                {
                case EnumSurveyType.RATING:

                    switch (response.answerChosen) 
                    {
                    case "5":
                        scores.rating.score5 = response.totalUserAnswered;
                        break;
                    case "4":
                        scores.rating.score4 = response.totalUserAnswered;
                        break;
                    case "3":
                        scores.rating.score3 = response.totalUserAnswered;
                        break;
                    case "2":
                        scores.rating.score2 = response.totalUserAnswered;
                        break;
                    case "1":
                        scores.rating.score1 = response.totalUserAnswered;
                        break;
                    default:
                        this.setState(prevState => {
                            let errorAnswerKeys = prevState.errorAnswerKeys;
                            errorAnswerKeys.push(response.answerChosen);
                            return {
                                errorAnswerKeys: this.state.errorAnswerKeys
                            };
                        });

                        break;
                    }
                    break;
                case EnumSurveyType.SATISFACTION:

                    switch (response.answerChosen) 
                    {
                    case "excellent":
                        scores.satisfaction.excellent = response.totalUserAnswered;
                        break;
                    case "great":
                        scores.satisfaction.great = response.totalUserAnswered;
                        break;
                    case "ok":
                        scores.satisfaction.ok = response.totalUserAnswered;
                        break;
                    case "poor":
                        scores.satisfaction.poor = response.totalUserAnswered;
                        break;
                    case "bad":
                        scores.satisfaction.bad = response.totalUserAnswered;
                        break;
                    default:
                        this.setState(prevState => {
                            let errorAnswerKeys = prevState.errorAnswerKeys;
                            errorAnswerKeys.push(response.answerChosen);
                            return {
                                errorAnswerKeys: this.state.errorAnswerKeys
                            };
                        });
                        break;
                    }
                    break;
                case EnumSurveyType.YESNO:

                    switch (response.answerChosen) 
                    {
                    case "Yes":
                        scores.yesno.yes = response.totalUserAnswered;
                        break;
                    case "No":
                        scores.yesno.no = response.totalUserAnswered;
                        break;
                    default:
                        this.setState(prevState => {
                            let errorAnswerKeys = prevState.errorAnswerKeys;
                            errorAnswerKeys.push(response.answerChosen);
                            return {
                                errorAnswerKeys: this.state.errorAnswerKeys
                            };
                        });

                        break;
                    }
                    break;

                case EnumSurveyType.FEEDBACK:
                    //do nothing
                    break;

                default:
                    errorSurveyType.push(surveyType);
                    break;
                }
            }
        });

        if (errorSurveyType.length > 0) {
            this.toastErrorMessage("Unknown '" + errorSurveyType.join() + "' for Survey Type");       
        }

        return scores;
    };

    getHighestNumber = (obj) => {
        const sorted = Object.keys(obj)
            .sort(function(keyA, keyB) {
                return obj[keyB] - obj[keyA];
            });

        return sorted[0];
    };

    render() {
        const { fetching, surveyQuestions, question1, response1, question2, response2, question3, response3 } = this.state;
        const { survey, insights, user } = this.props;

        if (fetching) {
            return <LoadingIndicator />;
        }

        return (
            <>
                <br/>
                <hr style={{ width: "100%" }} />
                <FlexContainer>
                    <div>
                        <GridContainer>
                            <GridChild style={{ textAlign: "left" }}>
                                <Text color="#51315D" size="25px" weight="bold" align="left">
                                Survey Insights
                                </Text>
                            </GridChild>
                        </GridContainer>

                        <GridContainer align="center" style={{ gridTemplateColumns: "33% 33% 33%" }} >                    
                            <GridChild style={{ textAlign: "left" }}>
                        
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                Users In Target Group
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">{insights.numberOfUsersInTargetGroup}</Text>
                                </Text>
                            </GridChild>

                            <GridChild style={{ textAlign: "left" }}>
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                Users Prompted For Survey
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">{insights.numberOfUsersPromptedForSurvey}</Text>
                                </Text>
                        
                            </GridChild>

                            <GridChild style={{ textAlign: "left" }}>
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                Total Surveys Submitted
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">{insights.numberOfTotalSurveysSubmitted}</Text>
                                </Text>
                        
                            </GridChild>
                        </GridContainer>

                        <GridContainer align="center" style={{ gridTemplateColumns: "33% 33% 33%" }} >                    
                            <GridChild style={{ textAlign: "left" }}>
                        
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                Question 1
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">{question1}</Text>
                                </Text>
                            </GridChild>

                            {
                                surveyQuestions[1] !== undefined &&

                            <GridChild style={{ textAlign: "left" }}>
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                    Question 2
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">{question2}</Text>
                                </Text>
                            </GridChild>
                            }
                            {
                                surveyQuestions[2] !== undefined &&
                            <GridChild style={{ textAlign: "left" }}>
                                <Text color="#51315D" size="16px" weight="bold" align="left">
                                    Question 3
                                    <Text size="16px" color="#000" align="left" margin="5px 0px">{question3}</Text>
                                </Text>
                            </GridChild>
                            }
                        </GridContainer>
                        <br/>
                        {   
                            survey.surveyType === EnumSurveyType.FEEDBACK &&
                        <FlexContainer color="#F5F5F5" style={{ padding: "5rem 1rem" }} >
                            <GridContainer align="center" style={{ gridTemplateColumns: "40% 60%" }} >   
                                
                                <GridChild>
                                    <GridContainer className="SurveyInsightsGridContainer" align="center" style={{ display: "flex" }} >
                                        <GridChild margin="0" style={{ placeSelf: "center" }}>
                                            <img className="icon-surveyInsight" src={InsightIconFeedback} alt="InsightIconFeedback"/>
                                        </GridChild>
                                        <GridChild margin="0" style={{ textAlign: "left", placeSelf: "center", }}>
                                            <Text size="14px" align="left">
                                                <strong>Download</strong> all current written answers 
                                                from users for your questions:
                                            </Text>
                                        </GridChild>
                                    </GridContainer>
                                </GridChild>

                                <GridChild style={{ placeSelf: "center" }}><AuthenticatedLink className="insight-download-csv" url={`${API_URL}/survey/company/responsesDownload/${survey.uuid}`} filename={survey.name.toString() + ".csv"} user={user}>Download csv</AuthenticatedLink></GridChild>
                            </GridContainer>
                        </FlexContainer>
                        }

                        {   
                            survey.surveyType !== EnumSurveyType.FEEDBACK &&
                        <GridContainer align="center" style={{ gridTemplateColumns: "33% 33% 33%" }} >                    
                            <GridChild style={{ textAlign: "right" }}>
                                {survey.surveyType === EnumSurveyType.RATING && <ImageGroupRatingInsight isSelected={ this.getHighestNumber(response1.rating) } score1={response1.rating.score1} score2={response1.rating.score2} score3={response1.rating.score3} score4={response1.rating.score4} score5={response1.rating.score5}/> }
                                {survey.surveyType === EnumSurveyType.SATISFACTION && <ImageGroupSatisfactionInsight isSelected={ this.getHighestNumber(response1.satisfaction) } bad={response1.satisfaction.bad} poor={response1.satisfaction.poor} ok={response1.satisfaction.ok} great={response1.satisfaction.great} excellent={response1.satisfaction.excellent}/> }
                                {survey.surveyType === EnumSurveyType.YESNO && <ImageGroupYesNoInsight isSelected={ this.getHighestNumber(response1.yesno) } yes={response1.yesno.yes} no={response1.yesno.no} /> }
                            </GridChild>
                            {
                                surveyQuestions[1] !== undefined &&
                                <GridChild style={{ textAlign: "left" }}>
                                    {survey.surveyType === EnumSurveyType.RATING && <ImageGroupRatingInsight isSelected={ this.getHighestNumber(response2.rating) } score1={response2.rating.score1} score2={response2.rating.score2} score3={response2.rating.score3} score4={response2.rating.score4} score5={response2.rating.score5}/> }
                                    {survey.surveyType === EnumSurveyType.SATISFACTION && <ImageGroupSatisfactionInsight isSelected={ this.getHighestNumber(response2.satisfaction) } bad={response2.satisfaction.bad} poor={response2.satisfaction.poor} ok={response2.satisfaction.ok} great={response2.satisfaction.great} excellent={response2.satisfaction.excellent}/> }
                                    {survey.surveyType === EnumSurveyType.YESNO && <ImageGroupYesNoInsight isSelected={ this.getHighestNumber(response2.yesno) } yes={response2.yesno.yes} no={response2.yesno.no} /> }
                                </GridChild>
                            }
                            {
                                surveyQuestions[2] !== undefined &&
                                <GridChild style={{ textAlign: "left" }}>
                                    {survey.surveyType === EnumSurveyType.RATING && <ImageGroupRatingInsight isSelected={ this.getHighestNumber(response3.rating) } score1={response3.rating.score1} score2={response3.rating.score2} score3={response3.rating.score3} score4={response3.rating.score4} score5={response3.rating.score5}/> }
                                    {survey.surveyType === EnumSurveyType.SATISFACTION && <ImageGroupSatisfactionInsight isSelected={ this.getHighestNumber(response3.satisfaction) } bad={response3.satisfaction.bad} poor={response3.satisfaction.poor} ok={response3.satisfaction.ok} great={response3.satisfaction.great} excellent={response3.satisfaction.excellent}/> }
                                    {survey.surveyType === EnumSurveyType.YESNO && <ImageGroupYesNoInsight isSelected={ this.getHighestNumber(response3.yesno) } yes={response3.yesno.yes} no={response3.yesno.no} /> }
                                </GridChild>
                            }
                            
                        </GridContainer>
                        }
                    </div>
                </FlexContainer>
            </>
        );
    }
}

export default SurveyInsightPage;

