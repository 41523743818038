import React, { Component } from "react";
import styled from "styled-components";
import { Text, FlexContainer } from "src/components";
import { StyledButton } from "../Utils";

const OverviewCard = styled.div`
    /* Layout Properties */
    top: 355px;
    left: 349px;
    width: 268px;
    height: 117px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000026;
    border: 1px solid #8291B2;
    border-radius: 10px;
    opacity: 1;
`;

export class SurveyOverview extends Component {
    render() {
        const { showInsights, insights } = this.props;

        return (
            <FlexContainer align="center" width="100%" direction="column" style={{ borderRight: "1px solid #EFEFEF" }}>
                <Text color="#51315D" size="25px" weight="bold" align="left">
                    Survey Overview
                </Text>
                <OverviewCard >
                    <Text color="#000000" size="14px" weight="bold" align="left" margin="1.5rem 0.875rem" >
                        Total Users
                        <hr style={{ border: "1px solid #EFEFEF", width: "100%" }}/>
                        <Text size="20px" weight="bold" color="#000" align="left" margin="5px 0px" >{insights.numberOfUsersInTargetGroup}</Text>
                    </Text>
                </OverviewCard>
                <Text color="#8291B2" size="14px" width="268px" align="justify" padding="0 0 1rem 0">
                    The total amount of users invited to participate in the survey.
                </Text>

                <OverviewCard >
                    <Text color="##000000" size="14px" weight="bold" align="left" margin="1.5rem 0.875rem" >
                        Users Prompted for Survey
                        <hr style={{ border: "1px solid #EFEFEF", width: "100%" }}/>
                        <Text size="20px" weight="bold" color="#000" align="left" margin="5px 0px" >{insights.numberOfUsersPromptedForSurvey}</Text>
                    </Text>
                </OverviewCard>
                <Text color="#8291B2" size="14px" width="268px" align="justify" padding="0 0 1rem 0">
                    The total amount of users who were prompted or viewed the survey.
                </Text>

                <OverviewCard >
                    <Text color="##000000" size="14px" weight="bold" align="left" margin="1.5rem 0.875rem" >
                        Total Surveys Submitted
                        <hr style={{ border: "1px solid #EFEFEF", width: "100%" }}/>
                        <Text size="20px" weight="bold" color="#000" align="left" margin="5px 0px" >{insights.numberOfTotalSurveysSubmitted}</Text>
                    </Text>
                </OverviewCard>
                <Text color="#8291B2" size="14px" width="268px" align="justify" padding="0 0 1rem 0">
                    The total amount of user who submitted their survey answers.
                </Text>

                <OverviewCard >
                    <Text color="##000000" size="14px" weight="bold" align="left" margin="1.5rem 0.875rem" >
                        Total Surveys Not Submitted
                        <hr style={{ border: "1px solid #EFEFEF", width: "100%" }}/>
                        <Text size="20px" weight="bold" color="#000" align="left" margin="5px 0px" >{insights.numberOfUsersInTargetGroup - insights.numberOfTotalSurveysSubmitted}</Text>
                    </Text>
                </OverviewCard>
                <Text color="#8291B2" size="14px" width="268px" align="justify" padding="0 0 1rem 0">
                    The total amount of user who have yet to submit their survey answers.
                </Text>

                <div style={{ width: "268px" }}>
                    <Text color="#000000" size="16px" weight="bold" align="left">
                        Survey User Submissions
                    </Text>
                    <Text color="#8291B2" size="14px" align="justify">
                        View users who submitted or have yet to submit survey answers.
                    </Text>

                    <StyledButton backgroundColor="#000000" color="#FFFFFF" border="1px solid #000" style={{ width: "150px", height: "40px", borderRadius: "7px", weight: "bold", marginBottom: "170px" }}
                        onClick={() => { showInsights(false); }} >
                        View
                    </StyledButton>
                </div>
            </FlexContainer>
        );
    }
}