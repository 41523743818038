import React from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
    width: 70%;
    height: 10px;
    margin-top: 1px;
    background-color: transparent;
    border-radius: 8px;
`;

const ProgressBarFiller = styled.div`
    width: ${props => props.width + 10}%;
    height: 12px;
    background-color: ${props => props.color || "#007bff"};
    border-radius: 8px;
`;

const ProgressBar = ({ value, moodType }) => {
    const moodColors = {
        Happy: "#654CB8",
        Content: "#4C86B8",
        Neutral: "#4CB8B8",
        Sad: "#4CB858",
        Unhappy: "#C3CE45",
        Tired: "#FF981F",
        Stressed: "#D94B4B",
    };

    // Get the color based on the moodType, or use the default color
    const color = moodColors[moodType] || "#007bff";


    return (
        <ProgressBarContainer>
            <ProgressBarFiller width={value} color={color} />
        </ProgressBarContainer>
    );
};

export default ProgressBar;