import axios from "axios";
import { API_URL } from "src/scenes/App";

/**
 * @property {string} cardId
 * @property {string} displayInfo
 * @property {string} cardBrand
 * @property {string} cardLast4
 * @property {string} expiration
 * @property {boolean} isDefault
 */
class SquareCard {
    constructor(data) {
        this.cardId = data.cardId;
        this.displayInfo = data.displayInfo;
        this.cardBrand = data.cardBrand;
        this.cardLast4 = data.cardLast4;
        this.expiration = data.expiration;
        this.isDefault = data.isDefault;
    }
}

/**
 * @property {string} uuid
 * @property {string} id
 * @property {string} type
 * @property {boolean} isDefault
 * @property {AirwallexCard|null} card
 */
class AirwallexPaymentMethod {
    constructor(data) {
        this.uuid = data.uuid;
        this.id = data.id;
        this.type = data.type;
        this.isDefault = data.isDefault;
        if (data.type === "card" && data.card) {
            this.card = new AirwallexCard(data.card);
        }
    }
}

/**
 * @property {string} displayInfo
 * @property {string} name
 * @property {string} brand
 * @property {string} last4
 * @property {string} expiration
 */
class AirwallexCard {
    constructor(data) {
        this.displayInfo = data.displayInfo;
        this.name = data.name;
        this.brand = data.brand;
        this.last4 = data.last4;
        this.expiration = data.expiration;
    }
}

/**
 * @property {string} id
 * @property {string} source
 * @property {boolean} isDefault
 * @property {string} type
 * @property {string} cardBrand
 * @property {string} cardLast4
 * @property {string} expiration
 * @property {string} cardName
 */
export class GenericPaymentMethod {
    constructor(data) {
        this.id = data.id;
        this.source = data.source;
        this.isDefault = data.isDefault;
        this.type = data.type;
        this.cardBrand = data.cardBrand;
        this.cardLast4 = data.cardLast4;
        this.expiration = data.expiration;
        this.cardName = data.cardName;
    }

    /**
     * @returns {GenericPaymentMethod[]}
     */
    static default() {
        return [];
    }
}

/**
 * @property {SquareCard[]} squareCards
 * @property {AirwallexPaymentMethod[]} airwallex
 */
export default class CompanyPaymentMethods {
    constructor(data) {
        this.squareCards = data.squareCards.map(data => new SquareCard(data));
        this.airwallex = data.airwallex.map(data => new AirwallexPaymentMethod(data));
    }

    static default() {
        return new CompanyPaymentMethods({
            squareCards: [],
            airwallex: []
        });
    }

    static async get(token) {
        const airwallexResponse = await axios.get(`${API_URL}/company/airwallex/paymentMethods`, {
            headers: {
                Authorization: "Bearer " + token
            }
        });

        const squareResponse = await axios.get(`${API_URL}/company/square/cards`, {
            headers: {
                Authorization: "Bearer " + token
            }
        });

        return new CompanyPaymentMethods({
            squareCards: squareResponse.data.cards,
            airwallex: airwallexResponse.data.paymentMethods
        });
    }

    /**
     * Searches through Airwallex payment methods and Square cards for something marked as 'default'. Prefers
     * Airwallex over Square if both areas have a default.
     * @return {string} - the default method's ID, or an empty string if none were found
     */
    findDefault() {
        for (let i = 0; i < this.airwallex.length; i++) {
            if (this.airwallex[i].isDefault) {
                return this.airwallex[i].uuid;
            }
        }
        for (let i = 0; i < this.squareCards.length; i++) {
            if (this.squareCards[i].isDefault) {
                return this.squareCards[i].cardId;
            }
        }
        return "";
    }

    /**
     * @return GenericPaymentMethod[]
     */
    intoDataArray() {
        const squareData = this.squareCards.map(card => new GenericPaymentMethod({
            source: "square",
            id: card.cardId,
            isDefault: card.isDefault,
            cardBrand: card.cardBrand,
            cardLast4: card.cardLast4,
            expiration: card.expiration
        }));
        const airwallexData = this.airwallex.map(method => new GenericPaymentMethod({
            source: "airwallex",
            id: method.id,
            isDefault: method.isDefault,
            type: method.type,
            cardBrand: method.card.brand,
            cardLast4: method.card.last4,
            expiration: method.card.expiration,
            cardName: method.card.name
        }));
        return [...squareData, ...airwallexData];
    }
}
