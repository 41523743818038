import React from "react";
import styled from "styled-components";
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
    background-clip: content-box;
    box-sizing: border-box;
    background: ${props => (props.checked ? "#006CFF" : "white")};
    background-image: url("data:image/svg+xml,%3Csvg fill='white' width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #A5C9FF;
    border-radius: 3px;
    height: 17px;
    width: 17px;
    transition: all 150ms;
    border-radius: 3px;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px pink;
    }
`;

const Checkbox = ({ checked, onClickCallback, ...props }) => (
    <CheckboxContainer onClick={onClickCallback}>
        <HiddenCheckbox defaultChecked={checked} {...props} />
        <StyledCheckbox checked={checked} { ...props }>
        </StyledCheckbox>
    </CheckboxContainer>
);

export default Checkbox;
