import React, { useContext } from "react";
import styled from "styled-components";
import { SubscriptionContext } from "../SubscriptionContext";

import { Divider } from "@material-ui/core/index";
import {
    FormField, FlexContainer, Button, Text, Toast,
    PageHeadingLabel, ExplainParagraphLabel, CardHeadingLabel, SplitLayoutRightImage
} from "src/components";
import LeftText from "../components/LeftText";
import { Forms } from "../Subscription";

// assets
import LicenseIcon from "src/img/new/license.svg";
import RightImage from "src/img/subscription/3.jpeg";

const StyledButton = styled(Button)`
    font-family: Roboto;
    border-radius: 7px !important;
    width: 8.75rem;
    height: 2.3125rem;
    line-height: 100%;
    font-weight: 500;
    margin-right: 1.125rem;
    margin-top: 1.125rem;
    font-size: 15px;
`;

const StyledText = styled(Text)`
    text-align: left;
    margin: ${ p => p.margin || 0};
    font-size: ${p => p.size || "0.875rem" };
`;

const Icon = styled.img`
    margin-top: 9.1875rem;
`;

const Plan = () => {
    const context = useContext(SubscriptionContext);
    const { currentForm, totalNumber, moreUsers, updatePrice, nextClick } = context;
    const isPlan = currentForm === Forms.PLAN;

    const proceedToPayment = e => {
        e.preventDefault();

        const minimum = isPlan ? 5 : 1; 
        const value = isPlan ? totalNumber : moreUsers;
        const units = minimum > 1 ? "users" : "user";

        if (!value || value < minimum) {
            return Toast.error("Minimum of " + minimum + " " + units + " to purchase.");
        }

        nextClick();
    };

    const handleUserToAddCount = (value) => {
        if (value > 0) {
            updatePrice(currentForm, value);
        }
    };

    return (
        <React.Fragment>
            <PageHeadingLabel>{ isPlan ? "User Licenses: Step 3" : "Purchase More User Licenses" }</PageHeadingLabel>
            <ExplainParagraphLabel maxWidth="43.125rem" width="100%" size="0.9375rem">
                { isPlan ?
                    <React.Fragment>
                        To start inviting your employees or clients to your Me Business app you must first purchase user licenses. These licenses will be used to send app invites and provided your users access to your custom business app.
                    </React.Fragment>
                    :
                    <React.Fragment>
                        Enter the amount of user license you require.
                    </React.Fragment>
                }
            </ExplainParagraphLabel>
            <SplitLayoutRightImage image={RightImage}
                width="100%"
                margin={isPlan ? "1.125rem 0 0 0" : "3.625rem 0 0 0"}
            >
                <CardHeadingLabel>User Licenses</CardHeadingLabel>
                <StyledText weight="700" size="1rem" margin="0.875rem 0">
                    Enter Amount of User Licenses Required
                </StyledText>
                <StyledText margin="0.875rem 0" width="25.25rem">
                    { isPlan ? 
                        <React.Fragment>
                            Please enter the total number of Users that will be invited to use
                            your custom Me app. This includes employees and clients.
                        </React.Fragment>
                        :
                        "Please enter the total number of additional user licenses."
                    }
                </StyledText>
                <FormField margin="0 0 0.75rem 0"
                    height="3.125rem"
                    width="16.625rem"
                    border="1px solid #000000"
                    borderRadius="12px"
                    size="1.5625rem"
                    placeholderWeight="700"
                    placeholderSize="1.5625rem"
                    value={ currentForm === Forms.PLAN ? totalNumber : moreUsers }
                    placeholder={ currentForm === Forms.PLAN ? totalNumber : moreUsers }
                    style={{ boxShadow: "0px 3px 6px #00000029", fontWeight: 700 }}
                    onChange={(e) => handleUserToAddCount(e.target.value)}
                    type="number"
                />
                
                { isPlan &&
                    <React.Fragment>
                        <LeftText color="#8E4EB4" margin="0">
                            *Minimum of 5 Licenses
                        </LeftText>

                        <Icon src={LicenseIcon} />
                        <StyledText color="#612684" weight="700" size="1rem" margin="1.125rem 0 0.875rem 0">
                            Purchasing Additional User Licenses
                        </StyledText>
                        <StyledText width="25.25rem">
                        You will be able to purchase additional licenses if needed once you purchase and complete your initial subscription.
                        </StyledText>
                    </React.Fragment>
                }

                <Divider style={{ maxWidth: "39.375rem", marginTop: `${isPlan ? "1.5625rem" : "20.3125rem" }` }} />
                <FlexContainer direction="row">
                    <StyledButton border="1px solid #000000" color="#000000" onClick={() => context.backToPrevious() }>
                        Back
                    </StyledButton>
                    <StyledButton color="#ffffff" backgroundColor="#000000" onClick={(e) => proceedToPayment(e)}>
                        Next
                    </StyledButton>
                </FlexContainer>
            </SplitLayoutRightImage>
        </React.Fragment>
    );
};

export default Plan;
