import React from "react";
import styled from "styled-components";
import FlexContainer from "./FlexContainer";
import BackButton from "./BackButton";
import Text from "./Text";

export const FORM = {
    INITIAL: 0 
};

const StyledBackButton = styled(BackButton)`
    &&& {
        color: #51315D;
        border: 2px solid #000;
        border-radius: 42px;
        width: 98px;
        height: 38px;
        margin-right: 80px;
    }
`;

function Form(props) {
    const { children, setForm, currentForm } = props;
    return (
        <FlexContainer direction="row" alignItems="center">
            {currentForm !== FORM.INITIAL && <StyledBackButton onClick={(e) => setForm(0)}>Back</StyledBackButton>}
            <Text size="40px" color="#23262D" align="left" margin={currentForm !== FORM.INITIAL ? "0 40px" : "0 auto"}>
                {children}
            </Text>
        </FlexContainer>
    );
}

export default Form;