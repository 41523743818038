import React from "react";
import { MenuItem, ClickAwayListener } from "@material-ui/core/";
import { DropdownBox } from "./styled/chatMessagesStyled";


const DropdownActionsBox = (props) => {
    return (
        <ClickAwayListener onClickAway={() => props.setToggleDropdown(false)}>
            <DropdownBox >
                <MenuItem weight="500"
                    padding="0 0 0 1.0625rem"
                    onClick={() => props.showUserProfile()}    
                >
                    View Profile
                </MenuItem>
                <MenuItem
                    weight="500"
                    padding="0 0 0 1.0625rem" className="cursor-pointer"
                    onClick={() => props.deleteChat()}
                >
                    Delete Chat
                </MenuItem>
            </DropdownBox>
        </ClickAwayListener>
    );
};

export default DropdownActionsBox;