import React, { useState } from "react";

import { Box, Dialog } from "@material-ui/core/";
import {
    Button, Text
} from "src/components";
import * as ROLE from "src/constants/premissionRoles";

import Permission from "./PermissionType";

function MoreAboutPermissions(props) {
    const [ open, setOpen ] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const roles = [
        {
            id: ROLE.ACCOUNT_OWNER,
            role: ["Account Owner"],
            description: "The Account Owner is assigned to the person who created the Me Business Account, or received account ownership. Account Owners can manage and make changes to everything on the App & Web. You can only reassign the Account Owner by transferring the account to a new user email.",
            permissions: {
                app: ["Noticeboard", "Create Business Playlists", "Team Goals & Checklists"],
                chat: ["Full Access Business Chat", "Message Employees", "Message Clients", "Create Group Chat"],
                web: ["Login Access to Web Dashboard", "My Me App", "My User Lists", "Noticeboard", "Surveys & Polls (Web & App)", "Statistics", "Directory", "Sales & Promotions", "Billing & Subscriptions"]
            }
        },
        {
            id: ROLE.ADMIN,
            role: ["Admin"],
            description: "Admin permission have the same access as an account owner but cannot transfer or change the account ownership details.",
            permissions: {
                app: [ "Noticeboard", "Create Business Playlists", "Team Goals & Checklists"],
                chat: ["Full Access Business Chat", "Message Employees", "Message Clients", "Create Group Chat"],
                web: ["Login Access to Web Dashboard", "My Me App", "My User Lists", "Noticeboard", "Surveys & Polls (Web & App)", "Statistics", "Directory", "Sales & Promotions", "Billing & Subscriptions"]
            }
        },
        {
            id: ROLE.STANDARD,
            role: ["Standard (Employee)", "Standard (Client)"],
            description: "Standard Employee or Client permissions are users that are existing or newly added to the business. These users have yet to be assigned any permissions by an account owner or admin.",
            permissions: {
            }
        },
        {
            id: ROLE.APP_MANAGER,
            role: ["App Manager"],
            description: "App Managers and post to the noticeboard to both employees and clients as well as create custom company playlists.",
            permissions: {
                app: [ "Noticeboard", "Create Business Playlists", "Team Goals & Checklists"]
            }
        },
        {
            id: ROLE.WEB_MANAGER,
            role: ["Web Manager"],
            description: "Web Managers will be able to log into the business account on a Desktop using their login credentials and access select features within your Business Web Dashboard.",
            permissions: {
                web: ["Login Access to Web Dashboard", "Noticeboard", "Surveys & Polls (Web & App)", "Statistics", "Directory", "Sales & Promotions"]
            }
        },
        {
            id: ROLE.MARKETING,
            role: ["Marketing"],
            description: "Users with Marketing permissions will have access to a combination of both App and Web features for your business.",
            permissions: {
                app: ["Noticeboard", "Create Business Playlists"],
                web: ["Login Access to Web Dashboard", "Noticeboard", "Surveys & Polls (Web & App)", "Statistics", "Sales & Promotions"]
            }
        },

        {
            id: ROLE.FINANCE_AND_SALES,
            role: ["Finance & Sales"],
            description: "Users with Finance & Sales permissions will have access to select features within your Business Web Dashboard.",
            permissions: {
                web: [ "Login Access to Web Dashboard", "Sales & Promotions", "Billing & Subscriptions" ]
            }
        },
        {
            id: ROLE.MULTIPLE_PERMISSIONS,
            role: ["Multiple Permissions"],
            description: "When a user has been assigned a select individual permissions other than the preset Permission Types listed they will be shown as having ‘Multiple Permissions’. This can be a combinations of any permissions available.",
            permissions: {
            }
        },
    ];

    const displayLeftGrid = (obj) => {
        return Object.prototype.hasOwnProperty.call(obj, "app") || 
                    Object.prototype.hasOwnProperty.call(obj, "chat") || 
                        Object.prototype.hasOwnProperty.call("support");
    }; 

    return (
        <React.Fragment>
            <button className="learn-more-about-permission" 
                onClick={handleOpen}
            >About User Permissions</button>
            <Dialog open={open} onClose={handleClose}
                maxWidth={false}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
        		aria-describedby="scroll-dialog-description"
                PaperProps={{	// the dialog content is automatically wrapped in a 'Paper' class - see dev tools elements tab
                    style: {
                        borderRadius: 15,
                    },
                }}
            >
                <Box className="more-about-permissions-modal" sx={{ width: "auto", padding: "48px 43px 48px 174px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="heading">About User Permissions</div>
                        <Button backgroundColor="#000000" color="#fff" family="Roboto, Helvetica, sans-serif" weight="700"
                            style={{ borderRadius: "10px", fontFamily: "Roboto, Helvetica, sans-serif", boxShadow: "none", height: 39 }}
                            onClick={handleClose}
                        >Close</Button>
                    </div>
					
                    <div className="content custom-scroll">
                        {
                            roles.map((role, index) => {
                                return (
                                    <div className="role-container" key={index}>
                                        <div >
                                            <Permission role={role.id} />
                                            { role.id === ROLE.STANDARD && 
												<span style={{ marginLeft: "25px" }}>
												    <Permission role={role.id} isClient={true} />
												</span>
                                            }
                                        </div>
                                        <Text className="description" align="left" style={{ maxWidth: "40em" }}>
                                            { role.description }
                                        </Text>

                                        { role.id === ROLE.STANDARD && <>
                                            <Text className="font-14 font-roboto text-bold" color="#000" align="left">Client Permissions vs. Employee Permissions: </Text>
                                            <Text className="description" align="left" style={{ maxWidth: "40em" }}>
													In your Me Business portal, client permissions are intentionally limited to protect privacy, while employee permissions offer broader access for efficient service management.
                                            </Text>
                                        </> }
                                        <div className="grid grid-col-2" style={{ maxWidth: "500px" }}>
                                            {
                                                displayLeftGrid(role.permissions) &&
												<div className="list" style={{ paddingLeft: "10px" }}>
												    { Object.prototype.hasOwnProperty.call(role.permissions, "app") &&
															<div>
															    <Text className="font-14 font-roboto text-bold"
															        color="#000" align="left"
															    >App Permissions</Text>
															    <ul className="perm-list">
															        {
															            role.permissions.app.map((perm, index) => {
															                return (
															                    <li key={index}>
															                        <Text className="list-item">• { perm }</Text>
															                    </li>
															                );
															            })
															        }
															    </ul>
															</div>
												    }
												    { Object.prototype.hasOwnProperty.call(role.permissions, "chat") &&
															<div>
															    <Text className="font-14 font-roboto text-bold"
															        color="#000" align="left"
															    >Chat Permissions (App & Web)</Text>
															    <ul className="perm-list">
															        {
															            role.permissions.chat.map((perm, index) => {
															                return (
															                    <li key={index}>
															                        <Text className="list-item">• { perm }</Text>
															                    </li>
															                );
															            })
															        }
															    </ul>
															</div>
												    }
												</div>
                                            }
											
                                            <div className="list">
                                                { Object.prototype.hasOwnProperty.call(role.permissions, "web") &&
													<div>
													    <Text className="font-14 font-roboto text-bold"
													        color="#000" align="left"
													    >Web Dashboard Permissions</Text>
													    <ul className="perm-list">
													        {
													            role.permissions.web.map((perm, index) => {
													                return (
													                    <li key={index}>
													                        <Text className="list-item">• { perm }</Text>
													                    </li>
													                );
													            })
													        }
													    </ul>
													</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}

export default MoreAboutPermissions;
