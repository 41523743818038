import { RoomMemberEvent } from "matrix-js-sdk";
import { useEffect, useState } from "react";

/**
 * custom hook for listening if someone from the rooms is typing
 * not sure if this will be implemented in the Chat List sidebar too.
 * @param {MatrixClient} matrixClient 
 * @returns {{
 *      isTyping: boolean
 *      typingMember: null | object
 *      typingRoomId: null | string
 * }}
 */
export const useTypingListener = (matrixClient) => {
    const [isTyping, setIsTyping] = useState(false);
    const [typingMember, setTypingMember] = useState(null);
    const [typingRoomId, setTypingRoomId] = useState(null);

    useEffect(() => {
        const handleTyping = (event, member) => {
            if (member.typing) {
                const memberInfo = {
                    name: member.name,
                    userId: member.userId
                };
                setIsTyping(true);
                setTypingMember(memberInfo);
                setTypingRoomId(member.roomId);
                return;
            }

            setIsTyping(false);
            setTypingMember(null);
            return;
        };

        if (matrixClient !== null) {
            matrixClient.on(RoomMemberEvent.Typing, handleTyping);
        }
    }, [matrixClient]);

    return {
        isTyping,
        typingMember,
        typingRoomId
    };
};