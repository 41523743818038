import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import UserLogin from "./Forms/UserLogin";
import AccountOwnerLogin from "./Forms/AccountOwnerLogin";
import styled, { css } from "styled-components";
import {
    FlexContainer, Button, Text, ErrorLabel
} from "src/components";

const ErrorStrings = {
    EMPTY_ACCESS_TOKEN: "* Please enter an access token.",
    EMPTY_PASSWORD: "* Please enter a password.",
    EMPTY_EMAIL: "* Please enter an email address."
};

const Option = styled.div`
    width: 24.25rem;
    height: 6.5625rem;
    background: #fff;
    border-radius: 0.9375rem;
    display: flex;
    margin-bottom: 0.92rem;
`;

const Heading = styled.div`
    color: #612684;
    font-size: 1.25rem;
    font-weight: 700;
    font-family: Roboto, Helvetica, sans-serif;
    margin-bottom: 0.5rem;
`;

const Description = styled.div`
    color: #000000;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
`;

const RadioWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    margin: 0 8px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Radio = styled.input.attrs({ type: "radio" })`

    ${props => props.checked && css`
        border-color:green;
    `};
`;

const Login = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [tabShown, setTabShown] = useState(null);

    let location = useLocation();
    let query = new URLSearchParams(location.search);
    let status = query.get("status");

    const handleSelection = (selection) => setSelectedOption(selection);

    const switchTabs = () => setTabShown(selectedOption);

    const showLoginSelection = () => {
        setTabShown(null);
        setSelectedOption(null);
    };
    
    const loginSuccess = (data) => props.login(data);

    return (
        <FlexContainer alignItems="center" justifyContent="center" flexGrow="1" maxWidth="25rem">
            { tabShown === null ?
                <React.Fragment>
                    <Text color="#FFFFFF" weight="700" size="30px" family="Roboto, Helvetica, sans-serif" margin="0 0 2rem 0" >Log In As</Text>
                    <ErrorLabel height="1.3rem" margin="0 0 .575rem 0">{ status === "invalidToken" ? "Your token in this device has expired. Please login again." : "" }</ErrorLabel>
                    <Option>
                        <RadioWrapper>
                            <Radio name="selection" className="login-radios" value="account" 
                                onChange={() => handleSelection("account")}
                            />
                        </RadioWrapper>
                        <TextWrapper>
                            <Heading>Account Owner</Heading>
                            <Description>
                                Account owner that performs tasks requiring unrestricted access.
                            </Description>
                        </TextWrapper>
                    </Option>
                    <Option>
                        <RadioWrapper>
                            <Radio name="selection" className="login-radios" value="user" 
                                onChange={() => handleSelection("user")}
                            />
                        </RadioWrapper>
                        <TextWrapper>
                            <Heading>User</Heading>
                            <Description>
                                User with assigned Me Business permissions and access.
                            </Description>
                        </TextWrapper>
                    </Option>
                    <Button backgroundColor="#FFFFFF"
                        color="#4956FC"
                        family="Roboto, Helvetica, sans-serif"
                        size="15px" marginTop="2.93rem"
                        style={{ fontWeight: 700, borderRadius: 7 }}
                        onClick={() => switchTabs()}
                    >Next</Button>
                </React.Fragment>
                : tabShown === "user" ?
                    <UserLogin errorStrings={ErrorStrings}
                        loginSuccess={(data) => loginSuccess(data)}
                        showLoginSelection={() => showLoginSelection()}
                    />
                    :
                    <AccountOwnerLogin errorStrings={ErrorStrings}
                        loginSuccess={(data) => loginSuccess(data)}
                        showLoginSelection={() => showLoginSelection()}
                    />
            }
        </FlexContainer>
    );
};

export default Login;