import { css } from "styled-components";

export const mobile = (...args) => css`
    @media (max-width: 610px) {
        ${css(...args)}
    }
`;

export const tablet = (...args) => css`
    @media (min-width: 38em) {
        ${css(...args)}
    }
`;
export const desktop = (...args) => css`
    @media (min-width: 64em) {
        ${css(...args)}
    }
`;

export const smallDesktop = (...args) => css`
    @media (max-width: 64em) {
        ${css(...args)}
    }
`;
