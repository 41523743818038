import { useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import { MatrixEvent } from "matrix-js-sdk";
import { Toast } from "src/components";

/**
 * @param {MatrixClient} matrixClient
 * @returns {{
 *      roomTimeline: array
 *      isFetchingRoomTimeline: boolean
 *      didTimelineFetchFail: boolean
 *      isFetchingPaginatedEvents: boolean
 *      fetchPaginatedEvents: Function
 *      fetchRoomTimeline: Function
 *      roomTimelineEnd: string | undefined    // for pagination: attach `from=roomTimelineEnd` to route query. undefined means no more events to fetch
 *      roomsEvents: array
 * }}
 */
export const useFetchRoomTimeline = (matrixClient) => {
    const [roomTimeline, setRoomTimeline] = useState([]);
    const [isFetchingRoomTimeline, setIsFetchingRoomTimeline] = useState(false);
    const [didTimelineFetchFail, setDidTimelineFetchFail] = useState(false);
    const [isFetchingPaginatedEvents, setIsFetchingPaginatedEvents] = useState(false);
    const [roomTimelineEnd, setRoomTimelineEnd] = useState("");
    const [roomsEvents, setRoomsEvents] = useState([]);

    const fetchRoomTimeline = async (matrixRoomId, limit, headers) => {
        setIsFetchingRoomTimeline(true);
        setRoomTimeline([]);
        const decryptedTimeline = await handleTimelineFetchAndProcessing(matrixRoomId, limit, headers, "");
        setRoomTimeline(decryptedTimeline);
        setIsFetchingRoomTimeline(false);
    };

    const fetchPaginatedEvents = async (timeline, matrixRoomId, limit, headers, from) => {
        if (from) {
            setIsFetchingPaginatedEvents(true);
            const decryptedTimeline = await handleTimelineFetchAndProcessing(matrixRoomId, limit, headers, from);
            const combined = [...timeline, ...decryptedTimeline];

            setRoomTimeline(combined);
            setIsFetchingPaginatedEvents(false);
        }
    };

    const handleTimelineFetchAndProcessing = async (matrixRoomId, limit, headers, from) => {
        setDidTimelineFetchFail(false);
        try {
            const roomTimeline = await fetchTimeline(matrixRoomId, limit, headers, from);
            const decryptedContentMap = await decryptTimelineEvents(matrixClient, roomTimeline.events);
            const decryptedTimeline = replaceEventWithDecryptedContent(roomTimeline.events, decryptedContentMap);

            setRoomTimelineEnd(roomTimeline.end);
            return decryptedTimeline;
        } catch (error) {
            Toast.error(error.message);
            setDidTimelineFetchFail(true);
        }
    };

    const fetchRoomsTimelines = async (chatList, headers) => {
        try {
            if (chatList) {
                const chatsTimelines = await Promise.all(chatList.map(async (chat) => {
                    const roomTimeline = await fetchTimelineChecker(chat.matrixRoomId, headers);
                    const decryptedContentMap = await decryptTimelineEvents(matrixClient, roomTimeline.events);
                    const decryptedTimeline = replaceEventWithDecryptedContent(roomTimeline.events, decryptedContentMap);
                    return { chat: chat, timeline: decryptedTimeline };
                }));
                setRoomsEvents(chatsTimelines);
                return chatsTimelines;
            }
        } catch (error) {
            Toast.error(error.message);
        }
    };

    /**
     * push new event to the start of the timeline array
     * @param {array} currentTimeline 
     * @param {MatrixEvent} newTimelineEvent 
     */
    const updateTimeline = (currentTimeline, newTimelineEvent) => {
        if (Array.isArray(currentTimeline)) {
            let newTimeline = [...currentTimeline];
            newTimeline.unshift(newTimelineEvent);
            setRoomTimeline(newTimeline);
        }
    };

    return {
        roomTimeline,
        isFetchingRoomTimeline,
        didTimelineFetchFail,
        fetchRoomTimeline,
        isFetchingPaginatedEvents,
        updateTimeline,
        fetchPaginatedEvents,
        roomTimelineEnd,
        roomsEvents,
        fetchRoomsTimelines
    };
};

const fetchTimeline = async (matrixRoomId, limit, headers, from) => {
    try {
        const url = `${API_URL}/_matrix/client/v3/rooms/${matrixRoomId}/messages?dir=b&limit=${limit}&from=${from}`;
        const response = await axios.get(url, headers);
        return { events: response.data.chunk, end: response.data.end, start: response.data.start };
    } catch (error) {
        throw new Error("Failed to fetch matrix room timeline.");
    }
};

const fetchTimelineChecker = async (matrixRoomId, headers) => {
    try {
        const url = `${API_URL}/_matrix/client/v3/rooms/${matrixRoomId}/messages?dir=b&limit=10`;
        const response = await axios.get(url, headers);
        return { events: response.data.chunk };
    } catch (error) {
        throw new Error("Failed to fetch matrix room timeline.");
    }
};

const decryptTimelineEvents = async (matrixClient, timeline) => {
    if (!Array.isArray(timeline)) {
        throw new Error("Invalid timeline format. Cannot start to decrypt chat messages.");
    }

    const decryptedContentMap = {};

    for (const event of timeline) {
        const matrixEvent = new MatrixEvent(event);
        if (matrixEvent.shouldAttemptDecryption()) {
            await matrixEvent.attemptDecryption(matrixClient.getCrypto());
            decryptedContentMap[matrixEvent.event.event_id] = matrixEvent.getContent();
        }
    }

    return decryptedContentMap;
};

const replaceEventWithDecryptedContent = (timeline, decryptedContentMap) => {
    if (!Array.isArray(timeline)) {
        throw new Error("Invalid timeline format. Cannot replace events with decrypted content.");
    }
    
    const newTimeline = timeline.map(event => {
        const eventId = event.event_id;
        if (eventId in decryptedContentMap) {
            event.content = decryptedContentMap[eventId];
        }

        return event;
    });

    return newTimeline;
};