import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import { SettingContext } from "../SettingContext";
import {
    LoadingIndicator, Text, FlexContainer, Toast, CardHeadingLabel, ExplainParagraphLabel, Button
} from "src/components";

import CompanyPaymentMethods from "src/scenes/Subscription/api/CompanyPaymentMethods";

import CardGrid from "src/scenes/Setting/components/CardGrid";
import PaymentModal from "../components/PaymentModal";
import AirwallexForm, { AirwallexProduct } from "src/scenes/Subscription/components/AirwallexForm";
import PaymentIcons from "src/img/payment-icons.png";

const SubText = styled(Text)`
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: ${ p => p.weight || 400 };
    text-align: ${p => p.align || "left"};
    color: ${ p => p.color };
    font-size: ${p => p.size || "14px"};
    margin: ${ p => p.margin || 0 };
`;

const Hr = styled.hr`
    margin: 0.75rem 0 1.25rem;
`;

function Payments() {
    const { email, token, companyName, abn } = useContext(SettingContext);
    const [cardLoadings, setCardLoadings] = useState(0);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethodListKey, setPaymentMethodListKey] = useState(0);
    
    useEffect(() => {
        fetchPaymentMethods();
    }, []);

    const showAddPaymentMethodForm = () => {
        showAirwallexFormModal();
    };

    const hideAddPaymentMethodForm = () => {
        PaymentModal.close();
    };

    const onPaymentMethodAdded = () => {
        // Hide the form, make the payment method list regenerate
        PaymentModal.close();
        setPaymentMethodListKey(paymentMethodListKey + 1);
        fetchPaymentMethods();
    };

    const fetchPaymentMethods = async () => {
        setCardLoadings(prev => prev + 1);
        try {
            const dividedPaymentMethods = await CompanyPaymentMethods.get(token);
            setPaymentMethods(dividedPaymentMethods.intoDataArray());
        } catch (error) {
            console.log(error);
        } finally {
            setCardLoadings(prev => prev - 1);
        }
    };

    /**
     * @param {GenericPaymentMethod} item
     */
    const setPaymentMethodAsDefault = async (item) => {
        let route;
        let body;
        switch (item.source) {
        case "airwallex":
            route = `${API_URL}/company/airwallex/paymentMethods/default`;
            body = { paymentMethodId: item.id };
            break;
        case "square":
            route = `${API_URL}/company/airwallex/paymentMethods/default`;
            body = { paymentMethodId: item.id };
            break;
        default:
            console.log("Invalid source: " + item.source);
            return;
        }

        setCardLoadings(prev => prev + 1);
        try {
            await axios.post(route, body, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            setCardLoadings(prev => prev - 1);
            await fetchPaymentMethods();
        } catch (error) {
            setCardLoadings(prev => prev - 1);
            Toast.error(error.response.data.error);
            console.log(error);
        }
    };

    /**
     * @param {GenericPaymentMethod} item
     */
    const deletePaymentMethod = async (item) => {
        let route;
        switch (item.source) {
        case "airwallex":
            route = `${API_URL}/company/airwallex/paymentMethods/${item.id}`;
            break;
        case "square":
            route = `${API_URL}/company/square/card/${item.id}`;
            break;
        default:
            console.log("Invalid source: " + item.source);
            return;
        }

        setCardLoadings(prev => prev + 1);
        try {
            await axios.delete(route, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            setCardLoadings(prev => prev - 1);
            await fetchPaymentMethods();
        } catch (error) {
            setCardLoadings(prev => prev - 1);
            Toast.error(error.message);
            console.log(error);
        }
    };

    const HeaderDescription = styled.div`
        font-size: 14px;
        color: #808080;
        font-weight: 400;
        margin: 1em 0;
    `;

    const ActionButtonContainer = styled.div`
        margin: 1rem 0px;
    `;

    const ActionButton = styled(Button)`
        color: ${ p => (p.color) || "#000" };
        background-color: ${ p => (p.backgroundColor) || "#fff" };
        margin: 5px 5px;
        font-size:14px;
        padding: ${ p => (p.padding) || "9px 45px 9px !important" };
        border: ${ p => (p.border) || "2px solid #000" };
        border-radius: 10px !important;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
    `;

    const ContainerGrid = styled.div`
        display: grid;
        grid-template-columns: 47% 47%;
        grid-template-rows: auto;
        column-gap: 6%;
    `;
    const showAirwallexFormModal = () => {
        const modalHeader = <React.Fragment>
                                Add a Payment Method
            <HeaderDescription>
                <img src={PaymentIcons} alt={"Payment Icons"} height="34px" />
            </HeaderDescription>
        </React.Fragment>;
                            
        const addAirwallexForm = <> 
            <AirwallexForm
                token={ token }
                email={ email }
                backToPrevious={ () => hideAddPaymentMethodForm() }
                nextClick={ () => onPaymentMethodAdded() }
                companyName={ companyName }
                abn={abn}
                productType={AirwallexProduct.SAVE_NEW_PAYMENT_METHOD}
                numberOfUsers={0}
            />
            <ActionButtonContainer>
                <Hr/>
                                    
                <ContainerGrid>
                    <div></div>
                    <div style={{ textAlign: "right" }}>
                        <ActionButton style={{ marginLeft: "auto" }} backgroundColor="#FFFFFF" color="#000" onClick={hideAddPaymentMethodForm}>
                                                    Cancel
                        </ActionButton>
                    </div>
                </ContainerGrid>
                                    
            </ActionButtonContainer>
        </>;

        return PaymentModal.open(
            modalHeader,
            addAirwallexForm,
            false
        );
    };

    return (
        <FlexContainer>
            <FlexContainer marginBottom="2em">
                <CardHeadingLabel>
                        Payment Methods
                </CardHeadingLabel>
                <ExplainParagraphLabel>
                        View and manage the payment methods in your account.
                </ExplainParagraphLabel>
                <SubText weight={700} margin="1rem 0 0" color="black">Your Account Payment Methods</SubText>
            </FlexContainer>

            { cardLoadings > 0 ?
                <LoadingIndicator /> :
                <>
                    <React.Fragment key={paymentMethodListKey}>

                        <FlexContainer width="100%" flexGrow="1" margin="0 auto" justifyContent="left">
                            <CardGrid
                                paymentMethods={paymentMethods}
                                onSetDefaultClicked={item => setPaymentMethodAsDefault(item)}
                                onDeleteClicked={item => deletePaymentMethod(item)}
                                onAddPaymentMethodClicked={ () => showAddPaymentMethodForm() } />
                        </FlexContainer>
                    </React.Fragment>
                        
                </>
            }
        </FlexContainer>
    );
}

export default Payments;