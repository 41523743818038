import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import { SubscriptionContext } from "../SubscriptionContext";
import SubscriptionCostResponse from "../api/SubscriptionCostResponse";
import { API_URL } from "src/scenes/App/App";

// global components
import { Divider } from "@material-ui/core/index";
import {
    FlexContainer, Text, Checkbox, Button, FormField, CountrySelector,
    ErrorLabel, LoadingIndicator, SplitLayoutRightImage,
    CardHeadingLabel, ExplainParagraphLabel, PageHeadingLabel
} from "src/components";

// constants
import { AUSTRALIA } from "src/constants/subscriptions";

// assets
import ChevronDown from "src/img/new/chevron-down.png";
import RightImage from "src/img/subscription/1.jpeg";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 3.06rem 0 3.06rem 0;
`;

export const StyledButton = styled(Button)`
    color: white;
    background-color: #51315D;
    border-radius: 20px;
    font-weight: 500;
    border: 1px solid #292C33;
    padding: 10px 40px;
    margin-top: ${p => p.marginTop};
    &:focus::-webkit-input-placeholder {
        opacity: 0;
        transform: translate(70%);
        -webkit-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }
`;

const Label = styled.div`
    color: #000000;
    font-size: 1rem;
    font-weight: 700;
    font-family: Roboto;
    margin-bottom: 0.875rem;
    margin-top: 1.25rem;
`;

const StyledText = styled(Text)`
    text-align: left;
    font-size: 0.875rem;
    font-weight: 500;
    color: #8E4EB4;
    margin-top: 0;
`;

const StyledInput = styled(FormField)`
    border: 1px solid #E9EBEF;
    border-radius: 12px;
    font-family: Roboto;
    font-weight: 500;
    width: 100%;
    max-width: 17.8125rem;
    height: 3.125rem;
    padding: 0.875rem 1.625rem;
    text-align: left;
    margin: ${ p => p.margin || 0 };
    position: relative;
    ::placeholder {
        color: #808080;
        font-weight: 500;
        text-align: left;
    }
`;

const Col = styled.div`
    width: ${p => p.width};
    position: relative;
    margin-right: ${p => p.marginRight};
    box-sizing: border-box;
`;

class Initial extends Component {
    static contextType = SubscriptionContext;

    state = {
        errorMessages: [],
        checked: this.context.acceptToc,
        companyName: null,
        abn: "",
        website: "",
        country: "",
        promoCode: "",
        isAustralian: "",
        isValidatingPromoCode: false,
        isValidPromoCode: true, // default to true, to hide the error container
        isValidCountry: this.context.isValidCountry,
        isValidating: false,
        matches: window.matchMedia("(max-width: 1600px)").matches,
    };

    componentDidMount = () => {
        const { companyName, abn, website, country, promoCode } = this.context;

        this.setState({
            companyName, abn, website, country, promoCode
        });

        window.matchMedia("(max-width: 1600px)").addEventListener("change", this.handleMediaQueryChange);
    };

    componentWillUnmount() {
        window.matchMedia("(max-width: 1600px)").removeEventListener("change", this.handleMediaQueryChange);
    }

    handleMediaQueryChange = (e) => {
        this.setState({ matches: e.matches });
    };

    continue = async (e) => {
        e.preventDefault();

        const { companyName, abn, country, website, promoCode } = this.state;
        const { updateCompanyValueAndPossiblyGoNext } = this.context;

        if (!this.context.acceptToc) {
            this.setState({
                errorMessages: ["*Please accept terms and conditions first."],
            });
            return; // Return early if terms and conditions are not accepted
        }

        let errors = [];

        if (companyName.trim() === "") {
            errors.push("*You must enter your business name to proceed.");
        }

        if (country.trim() === "") {
            errors.push("*You must select your country.");
        }

        if (promoCode !== "") {
            this.setState({
                isValidPromoCode: true,
                isValidatingPromoCode: true
            });

            try {
                await this.setAccountPromoCode(promoCode);
                this.setState({
                    isValidatingPromoCode: false,
                    isValidPromoCode: true
                });
            } catch (err) {
                console.log(`API response: ${err}`);
                this.setState({
                    isValidatingPromoCode: false,
                    isValidPromoCode: false
                });
                return;
            }
        }

        if (errors.length) {
            return this.setState({ errorMessages: errors });
        } else {
            updateCompanyValueAndPossiblyGoNext("companyName", companyName, false);
            updateCompanyValueAndPossiblyGoNext("abn", abn, false);
            updateCompanyValueAndPossiblyGoNext("country", country, false);
            updateCompanyValueAndPossiblyGoNext("website", website, false);
            updateCompanyValueAndPossiblyGoNext("promoCode", promoCode, /* goNext */ true);
        }
    };

    setAccountPromoCode = promotionCode => {
        const token = localStorage.getItem("token");
        return new Promise((resolve, reject) => {
            axios.put(`${API_URL}/company/setPromotionCode`, { "promoCode": promotionCode }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
            });
        });
    };

    checkBox = () => {
        this.setState({ checked: !this.state.checked });
        this.context.updateValue("acceptToc", !this.state.checked);
    };

    handleInputChange = (e, key) => this.setState({ [key]: e.target.value });

    handleSelect = async (e) => {
        const token = localStorage.getItem("token");
        const country = e.target.value;
        
        this.setState({
            country: country,
            errorMessages: [],
            isValidating: true,
            isValidCountry: false
        }, async () => {
            try {
                await this.validateCountry(country, token);
                await this.fetchSubscriptionCost(token);

                this.setState({
                    isValidating: false,
                    isValidCountry: true
                });
                this.context.updateValue("isValidCountry", true);
            }
            catch (err) {
                this.setState({
                    isValidating: false,
                    isValidCountry: false,
                    errorMessages: [err]
                });
                this.context.updateValue("isValidCountry", false);
            }
        });
    };

    fetchSubscriptionCost = async (token) => {
        const { reloadCosting } = this.context;
        try {
            const costing = await SubscriptionCostResponse.get(token);
            reloadCosting(costing);
        }
        catch (err) {
            console.log("Failed loading subscription cost");
        }
    };

    /**
     * checks in the api if selected country matches user's ip address
     * @param {string} country 
     * @param {string} token
     */
    validateCountry = (country, token) => {
        return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/company/validateIpAndGivenCountry`, { countryCode: country }, 
                { headers: { Authorization: "Bearer " + token } }  
            ).then(() => {
                resolve();
            })
                .catch(() => {
                    reject("Unable to confirm your country. Please select the correct country or contact support@memotivationapp.com.");
                });
        });
    };

    isNextClickable = () => {
        const { country, isValidating, isValidCountry } = this.state;

        if (country !== "" && !isValidating && isValidCountry) {
            return true;
        }
    };

    renderErrors = (errors) => errors.length ? errors.map(val => <ErrorLabel key={val}>{ val }</ErrorLabel>) : "";

    render() {
        const { errorMessages, companyName, website, country, abn, promoCode, isValidatingPromoCode, isValidPromoCode, isValidating, matches } = this.state;

        return (
            <React.Fragment>
                <PageHeadingLabel>Business Information: Step 1</PageHeadingLabel>

                <ExplainParagraphLabel maxWidth="43.125rem" width="100%" size="0.9375rem">
                    Welcome to your Me Business dashboard design to help you manage your Me app and workplace. Get started by completing the information below.
                </ExplainParagraphLabel>

                <SplitLayoutRightImage image={RightImage}
                    contentPadding="2.8125rem 5.5625rem 2.8125rem 3.8125rem"
                >
                    <CardHeadingLabel margin="0 0 1.75rem 0">Business Information</CardHeadingLabel>

                    <FlexContainer direction={matches ? "column" : "row"}>
                        <Col width={matches ? "100%" : "50%"} marginRight="1rem">
                            <Label>Business Name</Label>
                            <StyledInput placeholder="Enter your business name here…" 
                                margin="0 1.5rem 0 0"
                                value={companyName || ""}
                                onChange={(e) => this.handleInputChange(e, "companyName")}
                            />
                        </Col>
                        <Col width={matches ? "100%" : "50%"}>
                            <Label>Website (Optional)</Label>
                            <StyledInput placeholder="Enter your website here…"
                                value={website || ""}
                                onChange={(e) => this.handleInputChange(e, "website")}
                            />
                        </Col>
                    </FlexContainer>

                    <FlexContainer direction={matches ? "column" : "row"}>
                        <Col width={matches ? "100%" : "50%"} marginRight="1rem">
                            <Label>Country</Label>
                            <div style={{ display: "flex", margin: 0, maxHeight: "3.125rem", alignItems: "center" }}>
                                <CountrySelector width="100%" 
                                    align="left" padding="0.8125rem 1.5rem"
                                    margin="0 1.5rem 0 0"
                                    placeholder="Please select…"
                                    placeholderAlignment="left"
                                    showErrors={false}
                                    placeholderColor="#808080"
                                    selectIcon={ChevronDown}
                                    value={country}
                                    maxWidth="17.8125rem"
                                    onChange={e => this.handleSelect(e, "country")}
                                    style={{
                                        opacity: isValidating ? 0.5 : 1,
                                        pointerEvents: isValidating ? "none" : "auto",
                                    }}
                                />
                                { isValidating && <LoadingIndicator width="10px" height="10px" containerHeight="10" /> }
                            </div>
                        </Col>
                        <Col width={matches ? "100%" : "50%"}>
                            <FlexContainer display={country === AUSTRALIA ? "flex" : "none"}>
                                <Label>ABN</Label>
                                <StyledInput placeholder="Enter your business ABN"
                                    value={abn}
                                    onChange={(e) => this.handleInputChange(e, "abn")}
                                />
                            </FlexContainer>
                        </Col>
                    </FlexContainer>

                    <FlexContainer>
                        <Label>Promo Code (Optional)</Label>
                        <StyledText>
                            If you have been provided a promotional code for an offer please enter the code below.
                            We will apply the promotion after your account creation.
                        </StyledText>
                    </FlexContainer>
                    <FlexContainer direction={matches ? "column" : "row"} alignItems="center">
                        <Col width={matches ? "100%" : "50%"} marginRight="1rem">
                            <StyledInput placeholder="Enter promo code here"
                                value={promoCode}
                                onChange={(e) => this.handleInputChange(e, "promoCode")}
                            />
                        </Col>
                        <Col width={matches ? "100%" : "50%"}>
                            { isValidatingPromoCode ? <LoadingIndicator width="10px" height="10px" containerHeight="10" /> : "" }
                            { isValidPromoCode ? "" : <ErrorLabel>*Promo code not valid.</ErrorLabel> }
                        </Col>
                    </FlexContainer>

                    <CheckboxContainer>
                        <label>
                            <Checkbox color="#36BE24"
                                height="30px" width="30px"
                                radius="9px"
                                checked={this.state.checked} onChange={this.checkBox}
                            />
                        </label>
                        <Text color="#000000" size="0.875rem" weight="500" margin="0 0 0 0.875rem">
                            I accept the&nbsp;
                            <a
                                href="https://www.memotivationapp.com/terms-conditions"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#000000" }}
                            >
                                Terms and Conditions
                            </a>
                        </Text>
                    </CheckboxContainer>

                    { this.renderErrors(errorMessages) }

                    <Divider style={{ maxWidth: "39.375rem" }} />

                    <Button color="#fff"
                        backgroundColor="#000000"
                        width="8.75rem"
                        family="Roboto"
                        height="2.35rem"
                        marginTop="1.125rem"
                        onClick={(e) => this.continue(e)}
                        style={{
                            borderRadius: 7,
                            pointerEvents: this.isNextClickable() ? "auto" : "none",
                            opacity: this.isNextClickable() ? 1 : 0.5
                        }}
                    >
                        Next
                    </Button>
                </SplitLayoutRightImage>
            </React.Fragment>
        );
    }
}

export default Initial;