import React from "react";
import styled from "styled-components";
import { Button } from "src/components";

const StyledButton = styled(Button)`
    padding: ${ p => p.padding || "5px 10px"};
    width: ${p => p.width || "100px"};
    background-color: transparent;
    color: ${p => p.color || "#2D3037"};
    border: 1px #2D3037 solid;
    border-radius: 20px;    
    height: ${ p => p.height || "40px"};
    margin-right: ${p => p.right};
    margin-top: ${p => p.marginTop};
`;

function CancelButton(props) {
    const { children, ...styleProps } = props;
    return (
        <StyledButton {...styleProps}>
            {children}
        </StyledButton>
    );
}

export default CancelButton;