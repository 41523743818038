import React, { Component } from "react";
import styled, { css } from "styled-components";
import Text from "src/components/Text";
import CalendarIcon from "src/img/survey/calendar.png";

export const TextInput = styled.input`
    color: ${ p => p.color};
    font-family: ${p => p.fontFamily || "Roboto, Helvetica, sans-serif"};
    text-align: ${p => p.align || "center"};
    background-color: ${ p => p.backgroundColor || "white" };
    opacity: ${ p => p.opacity || "1" };
    width: ${ p => p.width || "100%" };
    height: ${ p => p.height || "2.4rem" };
    flex-basis: ${ p => p.flexBasis};
    padding: ${ p => p.padding || "10px 20px 10px 20px" };
    border-radius: ${ p => p.borderRadius || "1.2rem" };
    border-right: none !important;
    border: ${ p => p.border || "none" };
    border-bottom: ${ p => p.borderBottom };
    margin: ${ p => p.margin || "5px 0" };
    box-sizing: border-box;
    font-size: ${p => p.size || "0.8rem"};
    overflow: ${p => p.hiddenOverFlow && "hidden"};
    font-weight: ${ p => p.fontWeight || "400" };
    max-width: ${p => p.maxWidth};
    ::placeholder {
        opacity: ${ p => p.opacity || "initial" };
        color: ${ p => p.placeholderColor};
        font-weight: ${ p => p.placeholderWeight || "400" };
        text-align: ${ p => (p.align || p.placeholderAlignment) || "center" };
        font-size: ${ p => p.placeholderSize || "0.875rem"};
    };
    ${ p => p.isHighlighted && css`
        border: 1px solid red !important;
    `};
    ::-webkit-inner-spin-button { display: none; }
    ::-webkit-datetime-edit-text { 
        color: #000; 
        padding: 0 0.3rem; 
    }
    ::-webkit-datetime-edit-month-field { color: ${p => p.color || "#6A1E00"}; } 
    ::-webkit-datetime-edit-day-field { color: ${p => p.color || "#6A1E00"}; }
    ::-webkit-datetime-edit-year-field { color: ${p => p.color || "#6A1E00"}; }
    ::-webkit-calendar-picker-indicator { background: #6A1E00; }

    ${ p => p.isHideDefaultCalendarIcon && css`
        ::-webkit-calendar-picker-indicator { 
            background: none; 
            height: 25px;
            width: 25px;
        }
    `};
    
`;

export const ErrorText = styled(Text)`
    color: red;
    text-align: ${p => (p.align || p.errorTextAlign) || "left"};
    font-size: 12px;
    margin: ${p => p.errorMargin || "0 20px 5px"};
    height: 12px;
    height: ${p => (p.errorTextHeight) || "12px"};
    white-space: pre-wrap;
`;

const InputContainer = styled.div`
      display: flex;
      width: 100%;
`;

const IconContainer = styled.i`
      background-color: ${ p => p.iconBackgroundColor };
      width: 10%;
      margin: 5px 0px;
      padding: 10px 20px 10px 0;
      border-left: none !important;
      border: ${ p => p.iconBorder };
      border-radius: 0px 10px 10px 0px;
      display: inline;
`;

class SurveyDurationFormField extends Component {
    render() {
        const { placeholder, errors, onChange, type, onFocus, showErrors, errorTextAlign, onBlur, inputName, ...styleProps } = this.props;
        const errorsArray = errors ? errors : [];
        return (
            <InputContainer>
                <TextInput
                    placeholder={placeholder}
                    opacity="1.0"
                    type={type}
                    isHighlighted={errorsArray.length > 0}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    name={inputName}
                    {...styleProps}
                />
                { (type === "text" && inputName === "surveyDurationInput") &&
                    <IconContainer iconBorder={styleProps.border} iconBackgroundColor={styleProps.backgroundColor}>
                        <img src={CalendarIcon} alt={CalendarIcon} height="24px" width="24px" style={{ verticalAlign: "middle" }}/>
                    </IconContainer>
                }
                { showErrors && <ErrorText errorTextAlign={errorTextAlign} {...styleProps}>{errorsArray}</ErrorText> }
            </InputContainer>
        );
    }
}

export default SurveyDurationFormField;