import React from "react";
import { Button, Text, FlexContainer, ButtonWithIcon } from "src/components";
import { Dialog, Box, Divider } from "@material-ui/core";
import styled from "styled-components";
import DownloadApple from "src/img/Apple@2x.png";
import DownloadGoogle from "src/img/Google@2x.png";
import MeBusinessApp from "src/img/me-business-icon@2x.png";
import DownloadQr from "src/img/download-qr@2x.png";

const GetQrModal = ({ showGetQrModal, handleCloseGetQrModal, companyQrCode }) => {
    return (
        <Dialog
            open={showGetQrModal}
            maxWidth="md"
            scroll="body"
            PaperProps={{
                style: {
                    width: 980,
                    height: "auto",
                    borderRadius: 15,
                    overflow: "auto"
                }
            }}
        >
            <Box sx={{ padding: "1.563rem 3.125rem 0.625rem" }}>
                <Text size="2.313rem" weight="700" align="left" margin="0">
                    Easy Access QR Code
                </Text>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem" }}>
                <ModalContent companyQrCode={companyQrCode} />
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem 2.188rem" }}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ textAlign: "right" }}>
                        <>
                            <Button
                                onClick={handleCloseGetQrModal}
                                size="0.875rem"
                                border="1px solid #000"
                                color="#000"
                                width="8.75rem"
                                style={{ borderRadius: "7px", boxShadow: "none" }}
                            >
                                Close
                            </Button>
                        </>
                    </div>
                </div>
            </Box>
        </Dialog>
    );
};

const QRCodeContainer = styled.div`
    padding: 1em;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #8291B2;
    border-radius: 8px;
`;

const QRCodeScanCountContainer = styled.div`
    width: 268px;
    div.bordered{
        padding: 1em;
        box-shadow: 0px 5px 5px #00000026;
        border: 1px solid #8291B2;
        border-radius: 10px;
    }
`;

const TwoColumnLayout = styled.div`
    display: flex; /* Enables flexbox for column layout */

    .left-column {
        width: 60%;
        padding-right: 2em;
    }

    .right-column {
        width: 40%;
    }
`;
const ModalContent = ({ companyQrCode }) => {

    function downloadBase64Image(base64String, filename) {
        const link = document.createElement("a");
        link.href = base64String;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleDownload = () => {
        downloadBase64Image(companyQrCode.base64StringInPng, "Easy Access QR Code.png");
    };

    return (
        <>
            <TwoColumnLayout>
                <div className="left-column">
                    <Text size="1rem" align="left" margin="0">
                        The Easy Access QR Code feature allows you to generate a unique QR code for your Me Business account. 
                        Display this QR code to your clients, enabling them to download and access your branded Me Business app seamlessly. 
                        <br/><br/>
                        <b>Example:</b>
                        <br/><br/>
                        For instance, if you own a hotel with a Me Business account and a branded app, you can place this QR code in guest areas. 
                        Guests can then scan the code to gain instant access to your app. 
                        Additionally, users who have scanned and accessed the app will appear in your sales and promotions user list, allowing you to track engagement and interactions effectively.
                    </Text>
                </div>
                <div className="right-column">
                    <QRCodeContainer>
                        <img src={companyQrCode.base64StringInPng} alt="Company QR Code" style={{ width: "100%" }} />
                    </QRCodeContainer>
                </div>
            </TwoColumnLayout>
            <TwoColumnLayout>
                <div className="left-column">
                    <img src={MeBusinessApp} alt="Me Business App" height="87px" style={{ position: "relative", left: "-8px" }} />
                </div>
                <div className="right-column" style={{ display: "flex", justifyContent: "right" }}>
                    <ButtonWithIcon icon={DownloadQr}
                        style={{ fontWeight: "700", marginTop: "1em" }}
                        borderRadius="10px !important"
                        iconHeight="20px" iconWidth="20px"
                        iconMargin="0 1em 0 0"
                        backgroundColor="#000000"
                        color="#FFF"
                        text="Download My QR Code"
                        height="40px"
                        onClick={handleDownload}
                    />
                </div>
            </TwoColumnLayout>
            <br/>
            <TwoColumnLayout>
                <div className="left-column">
                    <Text size="1rem" align="left" margin="0">
                        <strong>Downloading the Me Business App</strong><br/><br/>
                        
                        Provide your clients with this link: <a href="https://www.getthemebusinessapp.com" target="_blank" rel="noopener noreferrer">https://www.getthemebusinessapp.com/</a> to download the app. 
                        Once they have the app installed, they will be guided to scan your unique QR code to access your branded Me Business app effortlessly.
                    </Text>
                    <br/>
                    <FlexContainer display="table-cell" mWidth="unset">
                        <a href="https://apps.apple.com/au/app/daily-fix-me-motivation/id1445382703" target="_blank" rel="noopener noreferrer">
                            <img src={DownloadApple} alt="Download Apple" height="45px" style={{ padding: "10px", marginLeft: "-9px" }}/>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.onpoint_holdings.dailyfixme" target="_blank" rel="noopener noreferrer">
                            <img src={DownloadGoogle} alt="Download Google" height="65px" />
                        </a>
                    </FlexContainer>
                </div>
                <div className="right-column" style={{ display: "flex", justifyContent: "right" }}>
                    <QRCodeScanCountContainer>
                        <div className="bordered">
                            <Text size="0.875rem" align="left" margin="0">My QR Code Scan Count</Text>
                            <br/>
                            <Text size="2rem" align="left" margin="0" weight="800">{companyQrCode.qrScannedCount.toLocaleString()}</Text>
                        </div>
                        <Text color="#808080" size="0.875rem" align="left">Total amount of times your QR code has been scanned by a client.</Text>
                    </QRCodeScanCountContainer>
                    
                </div>
            </TwoColumnLayout>
        </>
    );
};

export default GetQrModal;