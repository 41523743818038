import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import {
    Text, FormField, Button, Form, FlexContainer, LoadingIndicator
} from "src/components";

const ResetError = {
    EMPTY_PASSWORD: 1,
    EMPTY_REENTER_PASSWORD: 2,
    PASSWORD_NOT_MATCH: 3,
};

const ErrorStrings = {
    [ResetError.EMPTY_PASSWORD]: "* Please enter a password",
    [ResetError.EMPTY_REENTER_PASSWORD]: "* Please re-enter the password",
    [ResetError.PASSWORD_NOT_MATCH]: "* Please ensure both passwords are the same",
};

class ResetPassword extends Component {
    state = {
        loading: false,
        password: "",
        rePassword: "",
        userToken: "",
        inputErrors: [],
        errorMessage: "",
        email: "",
        portal: "",
        resetSuccessful: false
    };

    componentDidMount() {
        localStorage.clear(); //remove cookies, to make the logged-in user as Guest
        //remove first character as it is the : instead of value
        const { portal, token, email } = this.props.match.params;
        this.setState({
            userToken: token,
            email: email,
            portal: portal
        });
    }

    reset = (e) => {
        const { password, rePassword, userToken, portal } = this.state;

        e.preventDefault();

        this.setState({ 
            inputErrors: [],
            errorMessage: "",
            loading: true
        });

        let errorArray = [];

        if (password.trim() === "") {
            errorArray.push(ResetError.EMPTY_PASSWORD);
        }

        if (rePassword.trim() === "") {
            errorArray.push(ResetError.EMPTY_REENTER_PASSWORD);
        }

        if (rePassword !== password) {
            errorArray.push(ResetError.PASSWORD_NOT_MATCH);
        }

        if (errorArray.length === 0) {
            const urls = {
                web: `${API_URL}/company/resetPassword`,
                app: `${API_URL}/setupPassword/${userToken}`
            };
            axios.put(urls[portal], {
                password: password,
                token: userToken
            })
                .then(res => {
                    this.setState({ loading: false, resetSuccessful: true });
                })
                .catch(error => {
                    this.setState({
                        errorMessage: error,
                        loading: false
                    });
                });
        } else {
            this.setState({ 
                inputErrors: errorArray,
                loading: false
            });
        }
    };

    renderErrors = () => this.state.inputErrors.map(val => <div key={val} style={{ color: "#f26052", textAlign: "center" }}>{ErrorStrings[val]}</div>);

    render() {
        const { inputErrors, errorMessage, loading, resetSuccessful } = this.state;

        return (
            <FlexContainer height="90%" alignItems="center" justifyContent="center" flexGrow="1">
                
                {resetSuccessful && <PasswordResetSuccessful logout={this.props.logout} />}
                {
                    !resetSuccessful && <>
                        <Form width="auto" style={{ display: "flex", flexDirection: "column", alignItems: "center", fontFamily: "Roboto" }}>
                            { 
                                <React.Fragment>
                                    <Text color="#f26052"><strong>{errorMessage}</strong></Text><br/>
                                    { inputErrors.length ? this.renderErrors() : "" }
                                    <Text weight="500" size="40px" color="white" margin="0 0 2.8125rem 0">
                                    Create a New Password
                                    </Text>
                                    <FormField
                                        placeholder="Enter your new password"
                                        placeholderColor="black"
                                        placeholderWeight="500"
                                        type="password"
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        width="251px"
                                        margin="0 0 1.375rem 0"
                                        fontFamily="Roboto, Helvetica, sans-serif"
                                        showErrors={false}
                                    />
                                    <FormField
                                        placeholder="Re-enter your new password"
                                        placeholderColor="black"
                                        placeholderWeight="500"
                                        type="password"
                                        onChange={(e) => this.setState({ rePassword: e.target.value })}
                                        width="251px"
                                        margin="0 0 0 0"
                                        fontFamily="Roboto, Helvetica, sans-serif"
                                        showErrors={false}
                                    />
                                    { loading ? <div style={{ marginTop: "2rem" }}><LoadingIndicator /> </div> :
                                        <Button backgroundColor="#000000" 
                                            height="40px"
                                            borderRadius="20px"
                                            width="130px"
                                            color="#fff"
                                            marginTop="57px"
                                            alt="Reset"
                                            onClick={(e) => this.reset(e)}
                                            family="Roboto, Helvetica, sans-serif"
                                        >Confirm</Button>
                                    }
                                </React.Fragment>
                            }
                        </Form>
                    </>
                }
            </FlexContainer>
        ); 
    }
}

const PasswordResetSuccessful = (props) => {
    return (
        <React.Fragment>
            <Text weight="500" size="2.5rem" family="Roboto, Helvetica, sans-serif" color="white" margin="0 0 1.4rem 0">
                Your Password Has Been Reset!
            </Text>
            <Text weight="500" size="1rem" color="white" family="Roboto, Helvetica, sans-serif" margin="0 0 3.45rem 0">
                Your password has been successfully reset. Please use your<br />
                new credentials to access your account. If you have any further<br />
                questions or need assistance, feel free to reach out to our<br />
                support team.
            </Text>
            <Button backgroundColor="#000000" 
                height="40px"
                borderRadius="20px"
                width="8.5rem"
                color="#fff"
                marginTop="0"
                alt="Back to Log In"
                onClick={ () => props.logout() }
                family="Roboto, Helvetica, sans-serif"
                style={{ textAlign: "center", padding: 0 }}
            >Back to Log In</Button>
            
        </React.Fragment>
    );
};

export default ResetPassword;