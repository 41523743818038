import React from "react";
import styled from "styled-components";
import { desktop } from "./Media";

const OuterContainer = styled.div`
    margin-top: ${ p => p.marginTop || 0 }; 
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${p => p.height};
    min-height: ${p => p.minHeight};
    align-items: start;
    justify-content: start;
    ${desktop`
        flex-direction: row;
        align-items: start;
        justify-content: start;
    `};
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${ p => p.width || "24rem"};
    height: 100%;
    margin: 0.5rem 1rem 0 0;
    padding: ${ p => p.padding || "2rem"};
    background-color: white;
    border-radius: 0.75rem;
    box-sizing: border-box;
    max-width: ${ p => p.maxWidth };
`;

function TwoColumnLayout(props) {
    const { children, leftContainerWidth, rightContainerWidth, ...styleProps } = props;
    
    if (children.length !== 2) {
        const myMessage = "TwoColumnLayout requires 2 children; " + children.length + " found";
        throw Object.assign(
            new Error(myMessage),
            { code: 402 }
        );
    }
    return (
        <OuterContainer {...styleProps}>
            <InnerContainer width={leftContainerWidth} 
                maxWidth={props.leftMaxWidth} 
                padding={props.leftContainerPadding}
            >
                { children[0] }
            </InnerContainer>
            <InnerContainer width={rightContainerWidth} 
                padding={props.rightContainerPadding}
                maxWidth={props.rightMaxWidth}
            >
                { children[1] }
            </InnerContainer>
        </OuterContainer>
    );
}

export default TwoColumnLayout;