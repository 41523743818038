import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { Button, FlexContainer, LottieAnimation } from "src/components";
import SearchImg from "src/img/new/search.svg";
import DropDownImg from "src/img/new/drop-down.svg";
import moment from "moment";
import Checkbox from "src/scenes/EmployeeList/components/Checkbox";
import PurpleLottieJson from "src/scenes/Noticeboard/assets/purple-lottie.json";

const UpperControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 94.5rem;
    background: #fff;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
`;

const UpperButtons = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 2.4375rem;
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 700;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
`;

const SearchInput = styled.input`
    position: relative;
    background-image: url(${SearchImg});
    background-repeat: no-repeat;
    text-indent: 25px;
    background-position: 16px center;
    line-height: 100%;
    width: 100%;
    border: 1px solid #000;
    max-width: 33.75rem;
    height: 2.438rem;
    margin-left: 1.4375rem;
    margin-right: 1.4375rem;
    border-radius: 10px;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 0 0 1rem;
    box-sizing: border-box;
    ::placeholder {
        text-align: left;
        font-weight: 700;
        font-size: 0.875rem;
    }
`;

const InputSelector = styled.select`
    height: 2.438rem;
    width: 13.125rem;
    border-radius: 7px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #000;
    padding: 0.3em 1.1em;
    color: #000;
    font-size: 0.875rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-image: url(${DropDownImg});
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;
`;

const ContactImgContainer = styled.div`
    width: 83px;
    height: 47px;
    display: flex;
    justify-content: center;
    border: 1px solid #8291B2;
    border-radius: 7px;
`;

const ContactImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
    align-self: center;
`;

const TdButton = styled.button`
    height: 2rem;
    background: ${p => p.background};
    color: ${p => p.color};
    border: ${p => p.border || "none"};
    font-size: 0.75rem;
    font-weight: ${p => p.weight || 500};
    text-align: center;
    width: ${p => p.width};
    box-sizing: border-box;
    border-radius: 7px;
    cursor: ${p => p.cursor || "auto"};
`;

const TdText = styled.p`
    font-weight: normal;
    font-size: 0.875rem;
    white-space: normal;
`;

const Td = styled.td`
    padding: 0 1em !important;
    height: 3.938rem;
`;

const FILTER_BY = {
    ALL_CONTACTS: "all",
    EXPIRED: "expired",
    NO_EXPIRY: "no-expiry"
};

const DirectoryTable = ({
    contactsArray,
    handleOpenModal,
    handleEditContact,
    handleSingleOrMultipleDelete,
    handleShowDeleteDialog,
    checkAllContacts,
    checkedContacts,
    handleCheckboxChange,
    handleCheckAllContacts,
    isFetching
}) => {
    const [contacts, setContacts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectAllContacts, setSelectAllContacts] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [filterOption, setFilterOption] = useState(FILTER_BY.ALL_CONTACTS);

    useEffect(() => {
        setContacts(contactsArray);
    }, [contactsArray]);

    useEffect(() => {
        setSelectAllContacts(checkAllContacts);
        setSelectedContacts(checkedContacts);
    }, [checkAllContacts, checkedContacts]);

    useEffect(() => {
        // reset selected contacts (checkboxes)
        setSelectAllContacts(false);
        setSelectedContacts([]);
    }, [handleSingleOrMultipleDelete]);

    const getUserGroup = contact => {
        if (contact.visibleToEmployees && contact.visibleToClients) {
            return "Employees & Clients";
        } else if (contact.visibleToEmployees) {
            return "Employees";
        } else if (contact.visibleToClients) {
            return "Clients";
        } else {
            return "";
        }
    };

    const trimText = description => {
        if (description && description.length > 117) {
            return description.slice(0, 117).trim() + "...";
        }
        return description ?? "";
    };

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
    };

    const handleFilterChange = event => {
        setFilterOption(event.target.value);
        // reset selected contacts (checkboxes)
        setSelectAllContacts(false);
        setSelectedContacts([]);
    };

    const formatExpiryDate = date => {
        if (date === null) return "None";
        const currentDate = moment();
        const dateToCheck = moment(date);
        if (dateToCheck.isBefore(currentDate)) {
            return (<span style={{ color: "#ff7171", fontWeight: 600 }}>{dateToCheck.format("Do MMMM, YYYY") + " Expired"}</span>);
        }
        return dateToCheck.format("Do MMMM, YYYY");
    };

    const tableHeaderWidths = ["5%", "10%", "25%", "35%", "10%", "10%", "10%"];

    const tableHeader = [
        <label>
            <Checkbox
                key="selectAll"
                readOnly={false}
                checked={selectAllContacts}
                onChange={() => handleCheckAllContacts(filteredContacts)}
            />
        </label>,
        "Image",
        "Contact Name/Title",
        "Description",
        "Contact Expiry",
        "User Group",
        "Edit Contact"
    ];

    const headings = tableHeader.map((heading, index) => (
        <th key={index} width={tableHeaderWidths[index]} style={{ textAlign: index === 0 || index === 6 ? "center" : "left" }}>
            {heading}
        </th>
    ));

    const filteredContacts = contacts.filter(contact => {
        const { title, description } = contact;
        const searchTerms = `${title.toLowerCase()} ${description ? description.toLowerCase() : ""}`;
        const isMatchingSearch = searchTerms.includes(searchQuery.toLowerCase());

        if (!isMatchingSearch) return false;

        switch (filterOption) {
        case FILTER_BY.EXPIRED:
            return moment(contact.expirationDate).isBefore(moment());
        case FILTER_BY.NO_EXPIRY:
            return !contact.expirationDate;
        default:
            return true;
        }
    });

    const contactsList = [];
    filteredContacts.forEach(contact => {
        contactsList.push(
            <tr key={contact.uuid}>
                <Td style={{ textAlign: "center" }}>
                    <label>
                        <Checkbox
                            readOnly={false}
                            checked={selectedContacts.includes(contact.uuid)}
                            onChange={event => handleCheckboxChange(event, contact.uuid)}
                        />
                    </label>
                </Td>
                <Td>
                    {contact.imageUrl ?
                        <ContactImgContainer>
                            <ContactImg src={contact.imageUrl} />
                        </ContactImgContainer> :
                        <TdText>{"No Image"}</TdText>}
                </Td>
                <Td><TdText>{contact.title}</TdText></Td>
                <Td><TdText>{trimText(contact.description)}</TdText></Td>
                <Td><TdText>{formatExpiryDate(contact.expirationDate)}</TdText></Td>
                <Td><TdText>{getUserGroup(contact)}</TdText></Td>
                <Td style={{ textAlign: "center" }}>
                    <TdButton
                        onClick={() => handleEditContact(contact)}
                        background="#fff"
                        color="#000"
                        cursor="pointer"
                        border="1px solid #000"
                        width="6.25rem"
                        weight="700"
                    >
                        Edit
                    </TdButton>
                </Td>
            </tr>
        );
    });

    // Pad with placeholder rows if less than 10 contacts
    const minRows = 10;
    const placeholdersNeeded = minRows - filteredContacts.length;

    for (let i = 0; i < placeholdersNeeded; i++) {
        contactsList.push(
            <tr key={`empty_${i}`}>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
            </tr>
        );
    }

    return (
        <>
            { isFetching && (
                <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    color="#fff"
                    style={{
                        maxWidth: "94.5rem",
                        height: "44.188rem",
                        border: "1px solid #8291B2",
                        borderRadius: "7px",
                    }}
                >
                    <PurpleLoadingIndicator />
                </FlexContainer>
            )}

            { !isFetching && (
                <>
                    <UpperControls>
                        <Grid item md={selectedContacts.length > 0 ? 7 : 10}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <SearchInput placeholder="Search Contact" type="text" value={searchQuery} onChange={handleSearchChange} />
                                <InputSelector value={filterOption} onChange={handleFilterChange}>
                                    <option value={FILTER_BY.ALL_CONTACTS}>All Directory Contacts</option>
                                    <option value={FILTER_BY.EXPIRED}>Expired Contacts</option>
                                    <option value={FILTER_BY.NO_EXPIRY}>Contacts with no Expiry</option>
                                </InputSelector>
                            </Grid>
                        </Grid>

                        <Grid item md={selectedContacts.length > 0 ? 5 : 2}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                {selectedContacts.length > 0 && (
                                    <UpperButtons
                                        backgroundColor="#ff7171"
                                        padding="0.625rem 0.938rem 0.625rem"
                                        onClick={() => handleShowDeleteDialog()}
                                    >
                                        Delete Selected Items
                                    </UpperButtons>
                                )}
                                <UpperButtons
                                    style={{ marginRight: "2.2rem", marginLeft: "1.25rem", width: "12.5rem", display: "block" }}
                                    onClick={() => handleOpenModal()}
                                >
                                    Add New Contact
                                </UpperButtons>
                            </Grid>
                        </Grid>
                    </UpperControls>

                    <div className="scrollable-table" style={{ maxWidth: "94.5rem" }}>
                        <table>
                            <thead>
                                <tr>{headings}</tr>
                            </thead>
                            <tbody>{contactsList}</tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
};

function PurpleLoadingIndicator() {
    return (
        <FlexContainer>
            <LottieAnimation animationJsonData={PurpleLottieJson} />
        </FlexContainer>
    );
}

export default DirectoryTable;
