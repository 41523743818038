import { useState } from "react";
import { KEYS } from "../../components/Leadership/LeadershipRoles";

/**
 *  Custom hook for assigning leadership roles to an employee (form)
 * @returns {{
 *      hasLeadershipRolesChanged: boolean
 *      selectedLeadershipRoles: array
 *      setSelectedLeadershipRoles: Function
 *      assignLeadershipRoles: Function
 * }}
 */
export const useSetEmployeeLeadershipRoles = () => {
    /**
     * will be used to determine changes to assigned leadership role,
     * to prevent leadership Toast from showing, without leadership role changes, when UserPermissions form is saved
     */
    const [hasLeadershipRolesChanged, setHasLeadershipRolesChanged] = useState(false);
    const [selectedLeadershipRoles, setSelectedLeadershipRoles] = useState([]);

    /**
     * 
     * @param {string} key // "isVisibleToEmployees" or "isVisibleToClients" 
     * @param {string} leadershipRoleUuid 
     */
    const assignLeadershipRoles = (key, leadershipRoleUuid) => {
        const existingRoleIndex = selectedLeadershipRoles.findIndex(role => role.uuid === leadershipRoleUuid);

        const updatedRoles = [...selectedLeadershipRoles];
        if (existingRoleIndex === -1) {
            updatedRoles.push({
                uuid: leadershipRoleUuid,
                [KEYS.isVisibleToClients]: key === KEYS.isVisibleToClients,
                [KEYS.isVisibleToEmployees]: key === KEYS.isVisibleToEmployees
            });
        } else {
            updatedRoles[existingRoleIndex][key] = !updatedRoles[existingRoleIndex][key];
        }

        setSelectedLeadershipRoles(updatedRoles);
        setHasLeadershipRolesChanged(true);
    };

    return {
        hasLeadershipRolesChanged,
        selectedLeadershipRoles,
        setSelectedLeadershipRoles,
        assignLeadershipRoles
    };
};