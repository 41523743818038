import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Dialog } from "@material-ui/core/";
import { ChatContext } from "../ChatContext";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import {
    FlexContainer, Text, FormField, Button, LoadingIndicator
} from "src/components";
import SearchIcon from "src/img/new/search-gray.svg";
import { FORM_TYPE_CREATE, GROUP_CHAT, USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import Single from "./create/Single";
import Group from "./create/Group";
import TypeSelection from "./create/TypeSelection";
import LeadershipRoleToUseSelection from "./create/LeadershipRoleToUseSelection";
import { useFetchUsersList } from "../hooks";

export const Header = styled(FlexContainer)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #F4F5F8;
    height: 5.125rem;
    padding: 0 2.375rem 0 1.6875rem;
    border-bottom: 1px solid #DBE5ED;
    border-radius: 1.25rem 1.25rem 0 0;
`;

export const SearchInput = styled(FormField)`
    position: relative;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: calc(100% - 0.9375rem) center;
    border: 1px solid #DBE5ED;
    width: 100%;
    max-width: 53.9375rem;
    height: 2.5rem;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 0.9375rem 0 0.9375rem;
    border-radius: 0.5rem !important;
    margin: ${p => p.margin};

    ::placeholder {
        text-align: left;
        color: #AFBBC6;
        font-size: 0.875rem;
    }
`;

export const StyledButton = styled(Button)`
    width: 11.5rem;
    height: 3.4375rem;
    border-radius: 0.625rem !important;
    font-size: 1rem;
    font-weight: 700;
    box-sizing: border-box;
    background-color: ${ p => p.backgroundColor || "" };
    color: ${ p => p.color || "" };
`;

const GridContainer = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
    gap: 1.25rem;
    width: 100%;
    position: relative;
`;

const isListTypeLeadershipRoleUuid = listType => {
    return listType && listType !== USER_TYPE_EMPLOYEES && listType !== USER_TYPE_CLIENTS;
};

/**
* @param {string|undefined} leadershipRoleUuid
* @param {string} userType - must be USER_TYPE_EMPLOYEES or USER_TYPE_CLIENTS
* @param {array} userLeadershipRoles
* @returns {boolean}
*/
const isUserVisibleInLeadershipRole = (leadershipRoleUuid, userType, userLeadershipRoles) => {

    const leadershipRole = userLeadershipRoles.find((leadershipRole) => leadershipRole.uuid === leadershipRoleUuid);
    if (!leadershipRole) {
        return false;
    }

    if (userType === USER_TYPE_EMPLOYEES) {
        return leadershipRole.isVisibleToEmployees;
    } else if (userType === USER_TYPE_CLIENTS) {
        return leadershipRole.isVisibleToClients;
    }
};

const CreateChatModal = (props) => {
    const { chatToCreate, selectedChatListTypeOrLeadershipUuid } = useContext(ChatContext);
    const currentUser = useContext(UserContext);
    const leadershipRoles = currentUser[UserKeys.LEADERSHIP_ROLES];
    const { showDialog, handleClose, createChat } = props;
    /** list of all company users */
    const [usersList, setUsersList] = useState([]);
    /** we dont want to change the `selectedChatListTypeOrLeadershipUuid` in here because it will trigger a re-render from the Chat */
    const [isChatType, setIsChatType] = useState(currentUser[UserKeys.IS_CLIENT] ? USER_TYPE_CLIENTS : USER_TYPE_EMPLOYEES);
    const [usersToDisplay, setUsersToDisplay] = useState([]);
    const [loadingFetchUsers, setLoadingFetchUsers] = useState(false);
    const [isUserListBeingSearched, setIsUserListBeingSearched] = useState(false);
    const [currentSelectedList, setCurrentSelectedList] = useState(selectedChatListTypeOrLeadershipUuid);
    const [ selectedUserListType, setSelectedUserListType ] = useState(selectedChatListTypeOrLeadershipUuid);
    const [formData, setFormData] = useState({
        selectedEmployeesToChat: [],
        groupChatName: "",
        leadershipRoleUuid: ""
    });
    const headers = { headers: { "Authorization": `Bearer ${currentUser[UserKeys.TOKEN]}` } };
    const { isFetchingUsersList, fetchUpdatedUsersList } = useFetchUsersList();

    const isGroupChat = chatToCreate.chatType === GROUP_CHAT;
    const hasLeadershipRoles = Array.isArray(leadershipRoles) && leadershipRoles.length > 0;
    

    const retrieveUpdatedUsersList = async (fetchUserType) => {
        setCurrentSelectedList(fetchUserType);
        setLoadingFetchUsers(true);
        setIsUserListBeingSearched(false);
        const users = await fetchUpdatedUsersList(fetchUserType, headers);
        if (users) {
            setUsersList(users);
            setUsersToDisplay(users);
        }
        setLoadingFetchUsers(false);
    };

    /** @param {array} data */
    const handleSelectedEmployeesToChat = (data) => {
        setFormData({ ...formData, selectedEmployeesToChat: data });
    };

    const setLeadershipRoleToUse = (leadershipRoleUuid) => {

        if (!isListTypeLeadershipRoleUuid(leadershipRoleUuid)) {
            setFormData({ ...formData, leadershipRoleUuid: undefined });
            return;
        }

        setFormData({ ...formData, leadershipRoleUuid: leadershipRoleUuid });
        const isAllowedEmployeesOption = isUserVisibleInLeadershipRole(leadershipRoleUuid, USER_TYPE_EMPLOYEES, leadershipRoles);
        const isAllowedClientsOption = isUserVisibleInLeadershipRole(leadershipRoleUuid, USER_TYPE_CLIENTS, leadershipRoles);
        
        if (isAllowedClientsOption && isAllowedEmployeesOption) {
            changeSelectedType(USER_TYPE_EMPLOYEES);
        }
        if (isAllowedClientsOption && !isAllowedEmployeesOption) {
            changeSelectedType(USER_TYPE_CLIENTS);
        }
        if (!isAllowedClientsOption && isAllowedEmployeesOption) {
            changeSelectedType(USER_TYPE_EMPLOYEES);
        }
    };

    const handleSetGroupChatName = (name) => setFormData({ ...formData, groupChatName: name });

    const handleSubmit = () => {
        if (selectedChatListTypeOrLeadershipUuid === formData.leadershipRoleUuid || (selectedChatListTypeOrLeadershipUuid === currentSelectedList && (formData.leadershipRoleUuid === undefined || !formData.leadershipRoleUuid))) {
            props.setIsCurrentlyCreatingChat(true);
        }
        createChat({
            employeeUuidsToChat: formData.selectedEmployeesToChat,
            groupChatName: formData.groupChatName,
            leadershipRoleUuid: formData.leadershipRoleUuid,
            isClient: selectedUserListType === USER_TYPE_CLIENTS
        });
    };

    const handleSearch = (searchKey) => {
        if (searchKey.length) {
            const filteredUsers = usersList.filter(user => 
                user.firstName.toLowerCase().includes(searchKey.toLowerCase())
                || user.lastName.toLowerCase().includes(searchKey.toLowerCase())    
            );
            setIsUserListBeingSearched(true);
            setUsersToDisplay(filteredUsers);
        } else {
            setIsUserListBeingSearched(false);
            setUsersToDisplay(usersList);
        }
    };

    /**
    * @param {string} userType    
    */
    const changeSelectedType = (userType) => {
        retrieveUpdatedUsersList(userType);
        setIsChatType(userType);
    };

    useEffect(() => {
        if (showDialog && !hasLeadershipRoles) {
            retrieveUpdatedUsersList(selectedChatListTypeOrLeadershipUuid);
        }
    }, [showDialog]);

    useEffect(() => {
        changeSelectedType(selectedChatListTypeOrLeadershipUuid);
    }, []);

    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={handleClose}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "90%",
                        height: "90%",
                        borderRadius: "1.25rem",
                        overflow: "auto",
                        background: "#F9FAFC",
                    },
                }}
                className="scroll-design"
            >
                <Header>
                    <Text color="#4B5155" size="1.125rem" weight="700">{ isGroupChat ? "Create Group Chat" : "New Chat" }</Text>
                    { !hasLeadershipRoles && leadershipRoles.size > 0 && 
                        <TypeSelection changeSelectedType={changeSelectedType}
                            defaultValue={selectedChatListTypeOrLeadershipUuid}
                            width="12.5rem"
                            border="1px solid #FFF"
                            
                        />
                    }
                </Header>
                <Box sx={{ padding: "1.5625rem 3.0625rem 0rem 2.375rem" }}>
                    <FlexContainer>
                        { isFetchingUsersList && <LoadingIndicator /> }

                        { !isFetchingUsersList &&
                            <>
                                { hasLeadershipRoles &&
                                    <GridContainer>
                                        <LeadershipRoleToUseSelection changeSelectedRole={data => setLeadershipRoleToUse(data)} />
                                        <TypeSelection showLabel={true} changeSelectedType={changeSelectedType} 
                                            defaultValue={currentSelectedList}
                                            fontSize="0.875rem" fontWeight="500"
                                            color="#000"
                                            border="1px solid #000"
                                            currentSelectedLeadershipRoleUuid={formData.leadershipRoleUuid}
                                            setSelectedUserListType={setSelectedUserListType}
                                            selectedUserListType={selectedUserListType}
                                        />
                                    </GridContainer>
                                }
                                { loadingFetchUsers && 
                                <FlexContainer style={{ height: "70vh", justifyContent: "center", textAlign: "center" }}>
                                    <LoadingIndicator />
                                </FlexContainer>
                                }
                                { 
                                    !loadingFetchUsers && (usersToDisplay.length > 0 || isUserListBeingSearched) ? 
                                        <>
                                            { chatToCreate.chatType === GROUP_CHAT ?
                                                <Group usersToDisplay={usersToDisplay}
                                                    formType={FORM_TYPE_CREATE}
                                                    handleSearch={handleSearch}
                                                    handleSelectedEmployeesToChat={handleSelectedEmployeesToChat}
                                                    handleSetGroupChatName={handleSetGroupChatName}
                                                    hasLeadershipRoles={hasLeadershipRoles}
                                                />
                                                :
                                                <Single usersToDisplay={usersToDisplay}
                                                    handleSearch={handleSearch}
                                                    handleSelectedEmployeesToChat={handleSelectedEmployeesToChat}
                                                    hasLeadershipRoles={hasLeadershipRoles}
                                                    createChatType={isChatType}
                                                    isUserListBeingSearched={isUserListBeingSearched}
                                                />
                                            }
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    !loadingFetchUsers && usersToDisplay.length == 0 && !isUserListBeingSearched &&
                                    <FlexContainer style={{ height: "70vh", justifyContent: "center", textAlign: "center" }}>
                                        No users to display.
                                    </FlexContainer>
                                }
                                <FlexContainer direction="row" justifyContent="flex-end" bottom="0.5rem" height="3.5rem" right="2rem">
                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                        <StyledButton backgroundColor="#FFF" color="#000"
                                            border="1px solid #DBE5ED" onClick={handleClose}
                                        >
                                            Cancel
                                        </StyledButton>
                                        <StyledButton backgroundColor="#006CFF" color="#FFF"
                                            marginLeft="1.3125rem" 
                                            onClick={() => handleSubmit()}
                                        >
                                            Message
                                        </StyledButton>
                                    </div>
                                </FlexContainer>
                            </>
                        }
                    </FlexContainer>
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default CreateChatModal;
