import React, { Component } from "react";
import styled from "styled-components";
import { Text, Button } from "src/components";

//assets
import CorrectGreen from "src/img/CorrectArrowGreen.png";
import WrongIcon from "src/img/wrong.png";

const ErrorText = styled(Text)`
    color: red;
    text-align: center;
    font-size: 12px;
    margin: ${p => p.margin || "0 20px 5px"};
`;

const ActionButtonContainer = styled.div`
    margin: 2rem 0px;
`;

const ActionButton = styled(Button)`
    color: ${ p => (p.color) || "#000" };
    background-color: ${ p => (p.backgroundColor) || "#fff" };
    margin: 5px 5px;
    font-size:14px;
    border: ${ p => (p.border) || "1px solid #000" };
    padding: ${ p => (p.padding) || "10px 45px 10px !important" };
`;

const LicenseElementDiv = ({ label, value }) => {
    return (
        <>
            <Text color="#000" align="center" size="16px" weight="bolder" margin="10px 0">
                {label}
            </Text>

            <div style={{
                borderRadius: "26px",
                padding: "10px 20px",
                border: "1px solid #2D3037",
                textAlign: "center",
                caretColor: "transparent",
                boxSizing: "border-box",
                height: "2.75rem"
            }}>
                {value}
            </div>
        </>
    );
};

class InviteSent extends Component {
    iconResponse = (isSuccessful) => {
        if (isSuccessful)
            return CorrectGreen;

        return WrongIcon;
    };
      
    render() {
        const { licenseEmployee, checkedEmailInvite, checkedSmsInvite, isEmailSentSuccessful, isSmsSentSuccessful, cancel, emailResponseMessage, smsResponseMessage } = this.props;
        return (
            <>
                <div style={{ width: "24rem", margin: "0 auto", minHeight: "20vh" }} >
                    {checkedEmailInvite && 
                        <div>
                            <div style={{ float: "right", position: "relative", left: "15%", top: "35px" }}>
                                <img src={this.iconResponse(isEmailSentSuccessful)} alt="success" height="30px" />
                            </div>
                            <LicenseElementDiv label={"Email"} value={licenseEmployee.email} />
                            <ErrorText margin="0">
                                { emailResponseMessage.length > 0 ? emailResponseMessage : "" }  
                            </ErrorText>
                        </div> 
                    }
                    {checkedSmsInvite && 
                        <div>
                            <div style={{ float: "right", position: "relative", left: "15%", top: "35px" }}>
                                <img src={this.iconResponse(isSmsSentSuccessful)} alt="success" height="30px" />
                            </div> 
                            <LicenseElementDiv label={"SMS "} value={licenseEmployee.phoneNumber} /> 
                            <ErrorText margin="0">
                                { smsResponseMessage.length > 0 ? smsResponseMessage : "" }  
                            </ErrorText>
                        </div>
                    }
                </div>

                <ActionButtonContainer>
                    <hr/>
                    
                    <div style={{ textAlign: "center", marginTop: "1rem", marginBottom: "2rem" }}>
                        <ActionButton style={{ marginLeft: "auto" }} backgroundColor="#FFFFFF" color="#000" onClick={cancel}>
                            Close
                        </ActionButton>
                    </div>
                    
                </ActionButtonContainer>
            </>
        );
    }
}

export default InviteSent;
