import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  background-clip: content-box;
  background-color: ${props => (props.checked ? props.color || "#85b336" : "white")};
  border: 1px solid #2D3037;
  box-sizing: border-box;
  height: ${p => p.height || "20px"};
  padding: ${p => p.padding || "5px"};
  border-radius: ${ p => p.radius || "0"};
  transition: all 150ms;
  width: ${p => p.width || "20px"};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
`;

const Checkbox = ({ checked, onClickCallback, ...props }) => (
    <CheckboxContainer onClick={onClickCallback}>
        <HiddenCheckbox defaultChecked={checked} {...props} />
        <StyledCheckbox checked={checked} {...props}>
        </StyledCheckbox>
    </CheckboxContainer>
);

export default Checkbox;
