import React from "react";
import styled from "styled-components";
import Text from "src/components/Text";

const StyledLeftText = styled(Text)`
    size: ${p => p.size || "14px"};
    color: ${p => p.color || "#23262D"}; 
    text-align: ${p => p.align || "left"};
    margin: ${p => p.margin || "30px 0"};
    line-height: ${p => p.lineHeight};  
    width: ${p => p.width};
    font-family: ${p => p.family};
    min-width: ${p => p.minWidth};
    max-width: ${p => p.maxWidth};
    font-weight: ${p => p.fontWeight || "normal"}
`;

function LeftText(props) {
    const { children, ...styleProps } = props;
    return (
        <StyledLeftText {...styleProps}>
            {children}
        </StyledLeftText>
    );
}

export default LeftText;