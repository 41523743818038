import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
    color: ${p => p.color || "transparent"};
    border: ${p => p.border || "none"};
    border-radius: ${p => p.borderRadius || "20px"};
    outline: none;
    background-color: ${p => p.backgroundColor || "transparent"};
    padding: ${p => p.padding || "none"};
    font-size: ${p => p.size};
    margin-top: ${p => p.marginTop};
    margin-left: ${p => p.marginLeft};
    margin-right: ${p => p.marginRight};
    margin-bottom: ${p => p.marginBottom};
    white-space: nowrap;
    width: ${p => p.width};
    height: ${p => p.height};
    font-family: ${ p => p.family || "Roboto, Helvetica, sans-serif"};
    font-weight: 500;
    cursor: ${p => p.cursor || "pointer"};
`;

function Button(props) {
    const { children, ...styleProps } = props;
    return (
        <StyledButton {...styleProps} >
            {children}
        </StyledButton>
    );
}

export default Button;