import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { LottieLoadingIndicator, Toast } from "src/components";
import SurveyTable from "./components/SurveyTable";
import HowItWorks from "./components/HowItWorks";
import SurveyInsightPage from "./components/SurveyInsightPage";
import SurveyForm from "./forms/SurveyForm";
import EditForm from "./forms/EditForm";

import "./css/SurveyStyling.css";
import { SurveyStatusType, SortableColumns, SortableColumnState } from "src/constants/survey";

class Survey extends Component {
    state = {
        fetching: false,
        surveyArray: [],
        sortableColumns: [],
        checkAllUsers: false,
        isShowSurveyForm: false,
        isShowHowItWorks: false,
        isShowInsights: false,
        isShowEditForm: false,
        surveyUuid: "",
        initialized: false,
    };

    componentDidMount() {
        this.safeToInit();
    }

    safeToInit = () => {
        this.getSurveys(SurveyStatusType.ACTIVE);
    };

    getSurveys = (surveyType) => {
        this.setState(state => ({
            fetching: true
        }));

        let URL = `${API_URL}/survey/company/allSurveys`;
        if (surveyType) {
            URL = URL + "?filter=" + surveyType;
        }
        axios.get(URL, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            let allSurveys = res.data;

            allSurveys && allSurveys.forEach(element => {
                if (element.startDate) {
                    const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "2-digit", day: "2-digit" });
                    const date = new Date(element.startDate);
                    element.startDate = formatter.format(date);
                }

                if (element.finishDate) {
                    const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "2-digit", day: "2-digit" });
                    const date = new Date(element.finishDate);
                    element.finishDate = formatter.format(date);
                }
            });
            this.setState(state => ({
                surveyArray: allSurveys
            }));

            // Initially sort list based on application signup status and field errors.
            let sortable = this.state.sortableColumns;
            sortable[SortableColumns.SURVEY_NAME] = SortableColumnState.ASCENDING;

            this.setState({
                sortableColumns: sortable,
            });
        }).finally(() => {
            if (!this.state.initialized) {
                this.setState(state => ({
                    initialized: true
                }));
            }
            this.setState(state => ({
                fetching: false
            }));
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    showSurveyForm = (isShow) => {
        this.setState({
            isShowSurveyForm: isShow
        });
    };

    hideEditForm = () => {
        this.setState({
            isShowEditForm: false
        });
    };

    editSurvey = (surveyUuid) => {
        this.setState({
            isShowEditForm: true,
            surveyUuid: surveyUuid
        });
    };

    showHowItWorks = (isShow) => {
        this.setState({
            isShowHowItWorks: isShow
        });
    };

    surveyInsights = (surveyUuid) => {
        this.setState({
            isShowInsights: true,
            surveyUuid: surveyUuid
        });
    };

    showInsights = (isShow) => {
        this.setState({
            isShowInsights: isShow
        });
    };


    sortField = (sortableState, fieldName) => {
        const surveys = this.state.surveyArray;

        if (sortableState === SortableColumnState.ASCENDING) {
            surveys.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1);
        } else {
            surveys.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1);
        }

        this.setState({
            surveyArray: surveys
        });
        console.log("Here are the new organized surveys:", this.state.surveyArray);
    };

    sortColumns = (columnIndex) => {
        const columns = this.state.sortableColumns;
        let fieldName = "";

        // Setting all other column values to inactive, this way they will always start as descending when clicked after another column,
        // while any subsequent clicks will toggle between descending and ascending sort.
        for (let i = 0; i <= SortableColumns.STATUS; i++) {
            if (i === columnIndex) {
                let value = columns[columnIndex];

                switch (value) {
                case SortableColumnState.DESCENDING:
                    value = SortableColumnState.ASCENDING;
                    break;
                case SortableColumnState.ASCENDING:
                case SortableColumnState.INACTIVE:
                default:
                    value = SortableColumnState.DESCENDING;
                    break;
                }

                columns[columnIndex] = value;
            } else {
                columns[i] = SortableColumnState.INACTIVE;
            }
        }

        // Calling the sort for the particular column affected.
        switch (columnIndex) {
        case SortableColumns.SURVEY_NAME:
            fieldName = "name";
            break;
        case SortableColumns.SURVEY_TYPE:
            fieldName = "surveyType";
            break;

        case SortableColumns.USER_GROUP:
            fieldName = "userGroup";
            break;
        case SortableColumns.START_DATE:
            fieldName = "startDate";
            break;
        case SortableColumns.FINISH_DATE:
            fieldName = "finishDate";
            break;
        case SortableColumns.STATUS:
            fieldName = "status";
            break;
        default:
            Toast.error("Sortable Columns unhandled case");
            this.sortStatus(columns[columnIndex]);
            break;
        }

        if (fieldName !== "")
            this.sortField(columns[columnIndex], fieldName);

        this.setState({
            sortableColumns: columns
        });
    };

    render() {
        const {
            fetching,
            surveyArray,
            isShowSurveyForm,
            isShowEditForm,
            surveyUuid,
            isShowHowItWorks,
            isShowInsights,
            initialized
        } = this.state;

        const { user } = this.props;
        if (fetching > 0 && !initialized) {
            return <LottieLoadingIndicator />;
        }

        if (isShowSurveyForm) {
            return <SurveyForm showSurveyForm={this.showSurveyForm} user={user} getSurveys={this.getSurveys} />;
        }

        if (isShowEditForm) {
            return <EditForm hideEditForm={this.hideEditForm} user={user} surveyUuid={surveyUuid} getSurveys={this.getSurveys} />;
        }

        if (isShowInsights) {
            return <SurveyInsightPage showInsights={this.showInsights} user={user} surveyUuid={surveyUuid} />;
        }

        if (isShowHowItWorks) {
            return <HowItWorks showHowItWorks={this.showHowItWorks} />;
        }

        return <SurveyTable surveyArray={surveyArray} sortColumn={(key) => this.sortColumns(SortableColumns[key])} showSurveyForm={this.showSurveyForm} editSurvey={this.editSurvey} showHowItWorks={this.showHowItWorks} surveyInsights={this.surveyInsights} fetchSurvey={this.getSurveys} fetching={fetching}/>;
    }
}

export default withRouter(Survey);