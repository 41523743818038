import React, { useContext, useEffect, useState } from "react";
import { Dialog, Box } from "@material-ui/core/";
import { Header, StyledButton } from "./CreateChatModal";
import { ChatContext } from "../ChatContext";
import { UserContext } from "src/scenes/App/UserContext";
import { LoadingIndicator, Text, FlexContainer } from "src/components";
import { FORM_TYPE_EDIT, USER_TYPE_CLIENTS } from "src/constants/chat";
import Group from "./create/Group";

import { useFetchUsersList } from "../hooks";

/** TODO: this component have duplicate methods in CreateChatModal component. will be refactored in the future */
const EditGroup = (props) => {
    const { showDialog, handleClose, updateGroupChat, deleteChat } = props;
    const { token } = useContext(UserContext);
    const { currentChat, selectedChatListTypeOrLeadershipUuid } = useContext(ChatContext);
    const headers = { headers: { "Authorization": `Bearer ${token}` } };
    /** List of all company users */
    const [usersList, setUsersList] = useState([]);
    /** List of users to be displayed in the form */
    const [usersToDisplay, setUsersToDisplay] = useState([]);
    const [formData, setFormData] = useState({
        selectedEmployeesToChat: [],
        groupChatName: ""
    });
    const [employeeUuidsToChat, setEmployeeUuidsToChat] = useState(null);
    const { isFetchingUsersList, fetchUsersList } = useFetchUsersList();

    useEffect(() => {
        const retrieveUsersList = async () => {
            const users = await fetchUsersList(selectedChatListTypeOrLeadershipUuid, headers);
            setUsersList(users);
        };
        if (selectedChatListTypeOrLeadershipUuid) {
            retrieveUsersList();
        }
    }, [selectedChatListTypeOrLeadershipUuid]);

    useEffect(() => {
        setUsersToDisplay(usersList);
    }, [usersList]);

    const handleSearch = (searchKey) => {
        if (searchKey.length) {
            const filteredUsers = usersList.filter(user => 
                user.firstName.toLowerCase().includes(searchKey.toLowerCase())
                || user.lastName.toLowerCase().includes(searchKey.toLowerCase())    
            );
            setUsersToDisplay(filteredUsers);
        }
        else {
            setUsersToDisplay(usersList);
        }
    };

    const handleSelectedEmployeesToChat = (data) => setEmployeeUuidsToChat(data);

    const handleSetGroupChatName = (name) => setFormData({ ...formData, groupChatName: name });

    const handleSubmit = () => {
        updateGroupChat({
            employeeUuidsToChat,
            /** primarily for null chat names in db but was generated by api during fetch
             * we only want to update it if value is different from the generated name */
            groupChatName: currentChat.name === formData.groupChatName ? "" : formData.groupChatName,
            isClient: selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS
        });
    };

    const handleGroupChatDeletion = () => {
        deleteChat();
    };

    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={handleClose}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "95%",
                        maxWidth: "59.375rem",
                        minHeight: "53.0625rem",
                        height: "auto",
                        borderRadius: "1.25rem",
                        overflow: "auto",
                        background: "#F9FAFC",
                    },
                }}
            >
                <Header>
                    <Text color="#4B5155" size="1.125rem" weight="700">Edit Group Chat </Text>
                </Header>
                <Box sx={{ padding: "1.5625rem 3.0625rem 0 2.375rem" }}>
                    { !isFetchingUsersList && usersToDisplay.length ?
                        <React.Fragment>
                            <Group
                                usersToDisplay={usersToDisplay} 
                                formType={FORM_TYPE_EDIT}
                                handleSearch={handleSearch}
                                handleSelectedEmployeesToChat={handleSelectedEmployeesToChat}
                                handleSetGroupChatName={handleSetGroupChatName}
                            />
                            <FlexContainer direction="row" justifyContent="space-between">
                                <StyledButton backgroundColor="#FF5353" color="#FFF"
                                    onClick={() => handleGroupChatDeletion()}
                                >
                                    Delete Group
                                </StyledButton>
                                <FlexContainer direction="row" justifyContent="flex-end">
                                    <StyledButton backgroundColor="#FFF" color="#000"
                                        border="1px solid #DBE5ED" onClick={handleClose}
                                    >
                                        Cancel
                                    </StyledButton>
                                    <StyledButton backgroundColor="#006CFF" color="#FFF"
                                        marginLeft="1.3125rem" 
                                        onClick={() => handleSubmit()}
                                    >
                                        Save Changes
                                    </StyledButton>
                                </FlexContainer>
                            </FlexContainer>
                        </React.Fragment>
                        :
                        <LoadingIndicator />
                    }
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default EditGroup;