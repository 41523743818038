import { API_URL } from "src/scenes/App";
import axios from "axios";
import { Toast } from "src/components";
import { doesObjectPropertyExist } from "src/utils/helpers";
import { useState } from "react";

/**
 * Custom hook for saving assigned leadership roles to an employee.
 * @returns {{
 *      isSavingAssignedLeadership: boolean
 *      saveAssignedLeadershipRoles: Function
 * }}
 */
export const useSaveAssignedLeadershipRoles = () => {
    const [isSavingAssignedLeadership, setIsSavingAssignedLeadership] = useState(false);
    
    /**
     * @param {string} employeeUuid 
     * @param {array} leadershipRoles 
     * @param {object} headers 
     */
    const saveAssignedLeadershipRoles = async (employeeUuid, leadershipRoles, headers) => {
        setIsSavingAssignedLeadership(true);
        try {
            const url = `${API_URL}/chats/leadership/employees`;
            const formData = formatFormData(employeeUuid, leadershipRoles);

            await axios.post(url, formData, headers);
            Toast.success("Leadership roles have been assigned to the employee/client.");
        } catch (error) {
            Toast.error("Failed to assign leadership roles.");
        } finally {
            setIsSavingAssignedLeadership(false);
        }
    };

    /**
     * as of writing, the api expects a "leadershipRoleUuid" property for the form
     * adding this formatting to prevent changing stuff in the api
     * @param {string} employeeUuid 
     * @param {array} leadershipRoles 
     */
    const formatFormData = (employeeUuid, leadershipRoles) => {
        const formattedRoles = leadershipRoles.map(obj => {
            if (typeof obj === "object" 
                && obj !== null 
                && doesObjectPropertyExist(obj, "uuid")
            ) {
                return {
                    leadershipRoleUuid: obj.uuid,
                    ...obj
                };
            }
            // at this point, we just let the API return the error   
            return obj;
        });

        return {
            employeeUuid: employeeUuid,
            leadershipRoles: formattedRoles
        };
    };

    return {
        isSavingAssignedLeadership,
        saveAssignedLeadershipRoles
    };
};