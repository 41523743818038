import React, { Component } from "react";
import {
    FlexContainer, Text, Button, TextLabel, BackgroundContainer
} from "src/components";
import ImageSlider from "../components/ImageSlider";
import ColorPicker from "../components/ColorPicker";
import { CustomiseContext } from "../CustomiseContext";
import { Container, ColorButtonsContainer, ButtonContainer } from "src/scenes/Customise/forms/SelectColor";
import { PhoneContainer } from "src/scenes/Customise/forms/UploadLogo";
import GridBackground from "src/img/GridBackground.png";
import UploadIconImg from "src/img/UploadIcon.png";

class Finish extends Component {
    static contextType = CustomiseContext;

    state = {
        imageHash: Date.now()
    };

    render() {
        const { backGroundColor, secondaryColor, primaryColor, companyName, colourPalette,
            currentScreen, currentForm, screenIds, leftScreen, rightScreen, assetLogoUrl, assetIconUrl, iconStatus } = this.context;
            
        return (
            <>
                <Container>
                    <div>
                        <TextLabel weight="700" size="1.875em" color="#2A2B2A" margin="2rem 0 0.5rem" flexGrow="1">
                                Customise Overview
                        </TextLabel>
    
                        <FlexContainer style={{ flexShrink: 0 }}>
    
                            <Text size="1em" align="left">
                                    Here is an overview of your custom Me Business app. 
                                    Refer to the device preview on the right for a visual representation of your custom app.
                            </Text>

                            <FlexContainer direction="row" justifyContent="space-between">
                                <BusinessLogoContainer assetLogoUrl={assetLogoUrl} />
                                <CustomAppIconContainer assetIconUrl={assetIconUrl} iconStatus={iconStatus} />
                            </FlexContainer>
                                
                            <br/>

                            <SelectAppColours backGroundColor={backGroundColor} primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            <br/>

                            <hr style={{ width: "100%" }}/>
                            <br/>
                                
                            <Button width="112px" height="39px" backgroundColor="#000000" color="white" size="14px" weight="500"
                                borderRadius="10px !important" onClick={() => this.context.goToStep1()}>
                                    Edit
                            </Button>

                        </FlexContainer>
                    </div>
                    <PhoneContainer>
                        <ImageSlider
                            companyName={companyName}
                            logoTopOffset={45}
                            phoneFrameTopOffset={30}
                            currentScreen={currentScreen}
                            currentForm={currentForm}
                            screenIds={screenIds}
                            leftScreen={leftScreen}
                            rightScreen={rightScreen}
                            colourPalette={colourPalette}
                            assetLogoUrl={assetLogoUrl}
                        />
                    </PhoneContainer>
                </Container>
            </>
        );
    }
}

class BusinessLogoContainer extends Component {
    render() {
        const timestamp = new Date().getTime();
        const { assetLogoUrl } = this.props;
        return (
            <div>
                <Text size="1.25em" align="left" weight="700">Business Logo:</Text>

                <FlexContainer width="300px" height="90px" alignItems="center">
                    <BackgroundContainer width="300px" height="90px" image={GridBackground} style={{ border: assetLogoUrl.length > 0 ? "1px solid #000" : "1px dashed #000" }}>       
                        <img style={{ maxWidth: "300px", maxHeight: "90px" }} src={`${assetLogoUrl + "?" + timestamp}`} alt={assetLogoUrl} />
                    </BackgroundContainer>
                </FlexContainer>
            </div>
        );
    }
}

class CustomAppIconContainer extends Component {
    getIconStatusFormat(iconStatus) {
        if (iconStatus === "pending")
            return <span style={{ color: "#006CFF" }}>Pending</span>;
        else if (iconStatus === "active")
            return <span style={{ color: "#36BE24" }}>Active</span>;
        else
            return <span >None</span>;
    }

    render() {
        const { assetIconUrl, iconStatus } = this.props;

        const hasCompanyIcon = assetIconUrl && assetIconUrl.charAt(0) !== "?";
        const companyIcon = hasCompanyIcon ? assetIconUrl : UploadIconImg;
        const iconStatusFormat = this.getIconStatusFormat(iconStatus);
        const timestamp = new Date().getTime();
        
        return (
            <div>
                <Text size="1.25em" align="left" weight="700">Custom App Icon: {iconStatusFormat}</Text>

                <FlexContainer alignItems="center" width="120px" height="120px" justifyContent="start">
                    <BackgroundContainer style={{ border: hasCompanyIcon ? "1px solid #000" : " " }}>
                        <img style={{ maxWidth: "120px", maxHeight: "120px" }} src={`${companyIcon + "?" + timestamp}`} alt={"Finish Company Icon"} />
                    </BackgroundContainer>
                </FlexContainer>
            </div>
        );
    }
}

class SelectAppColours extends Component {
    render() {
        const { backGroundColor, primaryColor, secondaryColor } = this.props;
        return (
            <>
                <Text size="20px" color="#2A2B2A" margin="10px 0px 10px 0px" align="left" weight="700">
                    Colour Selection:
                </Text><br/>
                <ColorButtonsContainer>
                    <ButtonContainer>
                        <ColorPicker isPreview={true} color={backGroundColor} handleColorChange={this.handleBackgroundChange} />
                        <Text size="16px" color="black" align="left" weight="500">Background</Text>
                    </ButtonContainer>
                    <ButtonContainer>
                        <ColorPicker isPreview={true} color={primaryColor} handleColorChange={this.handlePrimaryColorChange} />
                        <Text size="16px" color="black" weight="500">Primary</Text>
                    </ButtonContainer>
                    <ButtonContainer>
                        <ColorPicker isPreview={true} color={secondaryColor} handleColorChange={this.handleSecondaryColor} />
                        <Text size="16px" color="black" align="left" weight="500">Secondary</Text>
                    </ButtonContainer>
                </ColorButtonsContainer>
            </>
        );
    }
}

export default Finish;