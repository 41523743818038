import React from "react";
import styled from "styled-components";
import FlexContainer from "./FlexContainer";

const StyledButton = styled.button`
    text-align: center;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 0.8750em;
    color: #FFFFFF;
    font-weight: 500;
    
    background: #006CFF 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 10px !important;
    padding: 5px;
    width: 130px;
    height: 40px;
    cursor: pointer;
`;

class BrowseFileButton extends React.Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    render() {
        const { children, onChange } = this.props;
        return (
            <FlexContainer>
                <StyledButton onClick={() => this.fileInput.current.click()}>
                    {children}
                </StyledButton>
                <input
                    ref={this.fileInput}
                    style={{ display: "none" }}
                    type="file"
                    onChange={e => onChange([...e.target.files])} />
            </FlexContainer>
        );
    }
}

export default BrowseFileButton;
