import React from "react";
import { useRoomTimeline } from "../../hooks";

const ChatRoomTimeline = (props) => {
    const { roomTimeline } = props;
    const { renderTimelineEvent } = useRoomTimeline(roomTimeline);

    const renderRoomTimeline = () => {
        return (
            roomTimeline.map((event, index) => renderTimelineEvent(event, index))
        );
    };
    return (
        <React.Fragment>
            { roomTimeline && roomTimeline.length > 0 && renderRoomTimeline() }
        </React.Fragment>
    );
};

export default ChatRoomTimeline;