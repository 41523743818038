import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import {
    Toast, LoadingIndicator, FlexContainer, FormField, ErrorText,
    Text, Form
} from "src/components";

import Tooltip from "./../components/Tooltip";

//assets
import Correct from "src/img/Correct.svg";
import Information from "src/img/survey/information.png";

import { GridContainer, GridChild, CancelButton, NextStepButton, GridSurveyTypeContainer, GridSurveyTypeChildStart, GridSurveyTypeChildEnd, ImageGroupRating, ImageGroupSatisfaction, ImageGroupYesNo, ImageGroupFeedback, StyledButton, GridUserGroupContainer, GridUserGroupChild, CorrectImage, WrongImage, CorrectImageSurveyType, QuestionContainer, AddAnotherQuestionContainer, RemoveQuestionIconContainer, InputSelector } from "../components/Utils";
import { EnumSurveyType, EnumUserGroup, EnumSurveyShowFrequency, EnumSurveyShownIn, ErrorStrings, ErrorList, FormStep } from "../components/Enums";

class SurveyForm extends Component {
    state = {
        survey: {
            name: "",
            surveyType: "",
            startDate: "",
            finishDate: "",
            userGroup: "",
            surveyQuestions: {
                one: { uuid: "", question: "" },
                two: {},
                three: {}
            },
            showIn: EnumSurveyShownIn.QUOTE_SCREEN,
            showFrequency: EnumSurveyShowFrequency.EVERYTIME
        },
        message: "",
        inputErrors: [],
        formStep: FormStep.PAGE_SURVEY_NAME,
        setInputTypeAs: "text",
        activeQuestionCount: 1,
        loading: false,
    };

    nextStep = (stepNumber) => {
        //validate the page before going to the next
        let errors = this.getErrors();
        
        if (errors.length > 0 && stepNumber > this.state.formStep)
            stepNumber = this.state.formStep;

        this.setState({ 
            formStep: stepNumber,
            inputErrors: errors
        });
    };

    getErrors() {
        let survey = this.state.survey;
        let errors = [];

        switch (this.state.formStep)
        {
        default:
        case FormStep.PAGE_SURVEY_NAME:
            if (survey.name === "")
            {
                errors.push(ErrorList.EMPTY_SURVEY_NAME);
            }
            break;
        case FormStep.PAGE_TYPE_OF_SURVEY:
            if (survey.surveyType === "")
            {
                errors.push(ErrorList.EMPTY_SURVEY_TYPE);
            }
            break;
        case FormStep.PAGE_SURVEY_DURATION:

            if (survey.startDate === "")
            {
                errors.push(ErrorList.EMPTY_START_DATE);
            }
            if (survey.finishDate === "")
            {
                errors.push(ErrorList.EMPTY_FINISH_DATE);
            }

            break;
        case FormStep.PAGE_USER_GROUP:
            if (survey.userGroup === "")
            {
                errors.push(ErrorList.EMPTY_USER_GROUP);
            }
            break;
        case FormStep.PAGE_SURVEY_QUESTIONS:
            let activeQuestionCount = this.state.activeQuestionCount;
            if (activeQuestionCount >= 1 && survey.surveyQuestions.one.question === "")
            {
                errors.push(ErrorList.EMPTY_QUESTION_ONE);
            }

            if (activeQuestionCount >= 2 && survey.surveyQuestions.two.question === "")
            {
                errors.push(ErrorList.EMPTY_QUESTION_TWO);
            }

            if (activeQuestionCount >= 3 && survey.surveyQuestions.three.question === "")
            {
                errors.push(ErrorList.EMPTY_QUESTION_THREE);
            }
            break;
        case FormStep.PAGE_SURVEY_FREQUENCY:
            if (survey.showIn === "")
            {
                errors.push(ErrorList.SHOW_IN);
            }

            if (survey.showFrequency === "")
            {
                errors.push(ErrorList.SHOW_FREQUENCY);
            }

            break;
        }
        
        return errors;
    }

    inputSurveyName = (event) => {
        const surveyName = event.target.value;
        
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                name: surveyName // update the value of specific key
            }
        }));
    };

    inputStartDate = (event) => {
        const startDate = event.target.value;
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                startDate: startDate // update the value of specific key
            }
        }));
    };

    inputFinishDate = (event) => {
        const finishDate = event.target.value;
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                finishDate: finishDate // update the value of specific key
            }
        }));
    };

    selectSurveyType = (surveyType) => {
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                surveyType: surveyType // update the value of specific key
            }
        }));
    };

    inputQuestion = (questionNumber, event) => {
        const numberWord = ["", "one", "two", "three"];
        let questionElement = numberWord[questionNumber];

        const questionString = event.target.value;

        this.setState(prevState => ({
            survey: {                  
                ...prevState.survey,   
                surveyQuestions: { 
                    ...prevState.survey.surveyQuestions,
                    [questionElement]: { uuid: "", question: questionString, questionOrder: questionNumber }
                }        
            }
        }));
    };    

    selectUserGroup = (userGroup) => {
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                userGroup: userGroup, // update the value of specific key
            },
            inputErrors: [], //reset Error

        }));
    };
    
    removeQuestion = (questionElement) => {
        this.setState(prevState => ({
            survey: {                  
                ...prevState.survey,   
                surveyQuestions: { 
                    ...prevState.survey.surveyQuestions,
                    [questionElement]: {}
                }
            },
            activeQuestionCount: this.state.activeQuestionCount - 1
        }));
    };

    addQuestion = () => {
        this.setState({
            activeQuestionCount: this.state.activeQuestionCount + 1
        });
    };

    selectShowIn = (event) => {
        const showIn = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showIn: showIn // update the value of specific key
            }
        }));
    };

    selectShowFrequency = (event) => {
        const showFrequency = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showFrequency: showFrequency // update the value of specific key
            }
        }));
    };
    
    formSubmission = () => {
        this.setState({
            loading: true,
        });

        const surveyData = this.state.survey;
        
        const surveyQuestions = Object.keys(surveyData.surveyQuestions);

        let surveyQuestionItems = {};
        surveyQuestions.forEach(questionKey => {
            if (surveyData.surveyQuestions[questionKey].question !== "" && typeof surveyData.surveyQuestions[questionKey].question !== "undefined")
                surveyQuestionItems[questionKey] = surveyData.surveyQuestions[questionKey];
        });

        axios.post(`${API_URL}/survey/company`, {
            name: surveyData.name,
            surveyType: surveyData.surveyType,
            startDate: surveyData.startDate ? surveyData.startDate + " 00:00:00" : null,
            finishDate: surveyData.finishDate ? surveyData.finishDate + " 23:59:59" : null,
            userGroup: surveyData.userGroup,
            surveyQuestions: surveyQuestionItems,
            showIn: surveyData.showIn,
            showFrequency: surveyData.showFrequency
        },
        {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            this.setState({ 
                formStep: FormStep.PAGE_CONGRATULATIONS
            });
        }).catch(error => {
            if (error.message) {
                Toast.error(error.message.toString());
                return;
            }

            let errors = [];
            let dataErrorFields = error.fields;

            let formStep;

            try {
                dataErrorFields.forEach(function(fieldKey) {
                    switch (fieldKey) {
                    case "userGroupOverLap":
                        errors.push(ErrorList.DATE_AND_USERGROUP_OVERLAP);
                        formStep = FormStep.PAGE_USER_GROUP;
                        return;

                    case "startDateInvalid":
                        errors.push(ErrorList.DATE_START_DATE_INVALID);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;
        
                    case "finishDateInvalid":
                        errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;

                    case "startDateGreaterThanFinishDate":
                        errors.push(ErrorList.DATE_RANGE_INVALID);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;
                    default:
                        //NO ERROR TO CATCH
                        return;
                    }
                });

                this.setState({
                    inputErrors: errors,
                    formStep: formStep
                });

                return Toast.error("Please make sure your entries are correct");
            }
            catch (e) {
                return Toast.error(error.message); 
            }
        }).finally(error => {
            this.setState({
                loading: false,
            });
        });
    };

    render() {
        const { showSurveyForm, getSurveys } = this.props;
        const { inputErrors, formStep, survey, setInputTypeAs, activeQuestionCount, loading } = this.state;

        if (formStep === FormStep.PAGE_CONGRATULATIONS)
            return <PageCongratulations showSurveyForm={showSurveyForm} nextStep={this.nextStep} getSurveys={getSurveys} />;

        return (
            <FlexContainer justifyContent="center" alignItems="center" style={{ margin: "auto", minWidth: "500px" }}>
                <Text color="#23262D" size="40px" weight="500">
                    New Survey
                </Text>

                <Form width="100%" onSubmit={(e) => {e.preventDefault();}} >
                    { formStep === FormStep.PAGE_SURVEY_NAME &&
                        <PageSurveyName showSurveyForm={showSurveyForm} nextStep={this.nextStep} survey={survey} inputSurveyName={this.inputSurveyName} inputErrors={inputErrors} /> }
                    { formStep === FormStep.PAGE_TYPE_OF_SURVEY &&
                        <PageTypeOfSurvey showSurveyForm={showSurveyForm} nextStep={this.nextStep} survey={survey} selectSurveyType={this.selectSurveyType} inputErrors={inputErrors} /> }
                    { formStep === FormStep.PAGE_SURVEY_DURATION &&
                        <PageSurveyDuration showSurveyForm={showSurveyForm} nextStep={this.nextStep} survey={survey} inputStartDate={this.inputStartDate} inputFinishDate={this.inputFinishDate} inputErrors={inputErrors} setInputTypeAs={setInputTypeAs}/> }
                    { formStep === FormStep.PAGE_USER_GROUP &&
                        <PageUserGroup showSurveyForm={showSurveyForm} nextStep={this.nextStep} survey={survey} selectUserGroup={this.selectUserGroup} inputErrors={inputErrors} /> }
                    { formStep === FormStep.PAGE_SURVEY_QUESTIONS &&
                        <PageSurveyQuestions showSurveyForm={showSurveyForm} nextStep={this.nextStep} survey={survey} inputQuestion={this.inputQuestion} activeQuestionCount={activeQuestionCount} addQuestion={this.addQuestion} removeQuestion={this.removeQuestion} inputErrors={inputErrors} /> }
                    { formStep === FormStep.PAGE_SURVEY_FREQUENCY &&
                        <PageSurveyFrequency showSurveyForm={showSurveyForm} nextStep={this.nextStep} survey={survey} inputErrors={inputErrors} selectShowIn={this.selectShowIn} selectShowFrequency={this.selectShowFrequency} /> }
                    { formStep === FormStep.PAGE_OVERVIEW &&
                        <PageOverview showSurveyForm={showSurveyForm} nextStep={this.nextStep} survey={survey} activeQuestionCount={activeQuestionCount} formSubmission={this.formSubmission} loading={loading} /> }
                    
                </Form>

            </FlexContainer>
        );
    }
}

class PageSurveyName extends Component {
    state = { tooltipActive: false };

    render() {
        const { showSurveyForm, nextStep, survey, inputSurveyName, inputErrors } = this.props;
        const { tooltipActive } = this.state;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyNameError = getErrorStrings(ErrorList.EMPTY_SURVEY_NAME);

        return (
            <>
                <Text color="#23262D" size="20px" weight="500">
                Create a survey name

                    <img src={Information} alt="Tooltip Icon" className="tooltip" height="20px" onClick={(e) => this.setState({ tooltipActive: !tooltipActive })} />
                    <Tooltip active={tooltipActive}>  
                    Your survey name will only be visible to you and not your users.
                    </Tooltip>
                    
                </Text>
                <br></br>
                <br></br>
                <Text color="#23262D" size="20px">
                    <FormField borderRadius="32px" size="16px" padding="10px 20px" height="62px"
                        placeholderColor="#000000" border="1px solid #2D3037"
                        width="100%"
                        type="text"
                        placeholder="Enter your survey name here"
                        onChange={(event) => {inputSurveyName(event);} }
                        errors={surveyNameError}
                        maxLength="100"
                        value={survey.name}
                        showErrors={true}
                    >
                    </FormField>
                </Text>
                <br></br>
                <br></br>
                <GridContainer align="center">
                    <GridChild style={{ textAlign: "right" }}>
                        <CancelButton onClick={(e) => {showSurveyForm(false);}}>Cancel</CancelButton>
                    </GridChild>
                    <GridChild style={{ textAlign: "left" }}>
                        <NextStepButton onClick={(e) => {nextStep(FormStep.PAGE_TYPE_OF_SURVEY);}}>Next Step</NextStepButton>
                    </GridChild>
                </GridContainer>
            </>
        );
    }
}

class PageTypeOfSurvey extends Component {
    state = { tooltipActive: false };

    render() {
        const { nextStep, survey, selectSurveyType, inputErrors } = this.props;
        const { tooltipActive } = this.state;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyTypeError = getErrorStrings(ErrorList.EMPTY_SURVEY_TYPE);
        
        return (
            <>
                <Text color="#23262D" size="20px" weight="500">
                Select the type of survey.

                    <img src={Information} alt="Tooltip Icon" className="tooltip" height="20px" onClick={(e) => this.setState({ tooltipActive: !tooltipActive })} />

                    <Tooltip active={tooltipActive} transform="35%">  
                    Select how you would like to interact with your users. Use a &rsquo;Rating Survey&rsquo; to measure user satisfaction or a &rsquo;Feedback Survey&rsquo; to see what users have to say.
                    </Tooltip>
                </Text>
                <br></br>
                <br></br>
                <GridSurveyTypeContainer className={survey.surveyType === EnumSurveyType.RATING ? "surveyTypeSelected" : ""} onClick={(e) => {selectSurveyType(EnumSurveyType.RATING);}} >
                    <GridSurveyTypeChildStart>User Rating Survey</GridSurveyTypeChildStart>
                    <GridSurveyTypeChildEnd>
                        <ImageGroupRating isSelected={survey.surveyType === EnumSurveyType.RATING} />
                    </GridSurveyTypeChildEnd>
                    <CorrectImageSurveyType display={survey.surveyType === EnumSurveyType.RATING ? "inline" : ""}/>
                </GridSurveyTypeContainer>
            
                <GridSurveyTypeContainer className={survey.surveyType === EnumSurveyType.SATISFACTION ? "surveyTypeSelected" : ""} onClick={(e) => {selectSurveyType(EnumSurveyType.SATISFACTION);}}>
                    <GridSurveyTypeChildStart>User Satisfaction Survey</GridSurveyTypeChildStart>
                    <GridSurveyTypeChildEnd>
                        <ImageGroupSatisfaction isSelected={survey.surveyType === EnumSurveyType.SATISFACTION}/>
                    </GridSurveyTypeChildEnd>
                    <CorrectImageSurveyType display={survey.surveyType === EnumSurveyType.SATISFACTION ? "inline" : ""}/>
                </GridSurveyTypeContainer>
            
                <GridSurveyTypeContainer className={survey.surveyType === EnumSurveyType.YESNO ? "surveyTypeSelected" : ""} onClick={(e) => {selectSurveyType(EnumSurveyType.YESNO);}}>
                    <GridSurveyTypeChildStart>User Yes/No Survey</GridSurveyTypeChildStart>
                    <GridSurveyTypeChildEnd>
                        <ImageGroupYesNo isSelected={survey.surveyType === EnumSurveyType.YESNO}/> 
                    </GridSurveyTypeChildEnd>
                    <CorrectImageSurveyType display={survey.surveyType === EnumSurveyType.YESNO ? "inline" : ""}/>
                </GridSurveyTypeContainer>
            
                <GridSurveyTypeContainer className={survey.surveyType === EnumSurveyType.FEEDBACK ? "surveyTypeSelected" : ""} onClick={(e) => {selectSurveyType(EnumSurveyType.FEEDBACK);}}>
                    <GridSurveyTypeChildStart>User Feedback Survey</GridSurveyTypeChildStart>
                    <GridSurveyTypeChildEnd>
                        <ImageGroupFeedback isSelected={survey.surveyType === EnumSurveyType.FEEDBACK}/>
                    </GridSurveyTypeChildEnd>
                    <CorrectImageSurveyType display={survey.surveyType === EnumSurveyType.FEEDBACK ? "inline" : ""}/>
                </GridSurveyTypeContainer>
            
                <ErrorText>
                    {surveyTypeError}
                </ErrorText>

                <br></br>
                <br></br>

                <GridContainer align="center">
                    <GridChild style={{ textAlign: "right" }}>
                        <CancelButton onClick={(e) => {nextStep(FormStep.PAGE_SURVEY_NAME);}}>Back</CancelButton>
                    </GridChild>
                    <GridChild style={{ textAlign: "left" }}>
                        <NextStepButton onClick={(e) => {nextStep(FormStep.PAGE_SURVEY_DURATION);}}>Next Step</NextStepButton>
                    </GridChild>
                </GridContainer>
            </>
        );
    }
}

class PageSurveyDuration extends Component {
    state = {
        startDateSetInputTypeAs: "text",
        finishDateSetInputTypeAs: "text",
        tooltipActive: false
    };

    startDateChangeInputTypeToDate = (event) => {
        this.setState({
            startDateSetInputTypeAs: "date" 
        });
    };

    finishDateChangeInputTypeToDate = (event) => {
        this.setState({
            finishDateSetInputTypeAs: "date" 
        });
    };

    render() {
        const { nextStep, survey, inputStartDate, inputFinishDate, inputErrors } = this.props;
        const { startDateSetInputTypeAs, finishDateSetInputTypeAs, tooltipActive } = this.state;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
        const surveyFinishDateError = getErrorStrings(ErrorList.EMPTY_FINISH_DATE);
        
        const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
        const finishDateInvalid = getErrorStrings(ErrorList.DATE_FINISH_DATE_INVALID);

        const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
        const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_FINISH_FORMAT_INVALID);

        const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);

        return (
            <>
                <FlexContainer style={{ margin: "auto", width: "365px" }}>
                    <Text color="#23262D" size="20px" weight="500">
                    Enter the survey duration period.

                        <img src={Information} alt="Tooltip Icon" className="tooltip" height="20px" onClick={(e) => this.setState({ tooltipActive: !tooltipActive })} />

                        <Tooltip active={tooltipActive}>  
                        Choose when you would like your survey to start appearing in the app, and when you would like to end your survey.
                        </Tooltip>
                    </Text>
                
                    <br></br>
                    <br></br>

                    <Text color="#000" align="left" padding="0px 20px" size="16px" weight="bolder" margin="0">
                    Start
                    </Text>
                    <FormField borderRadius="26px" size="14px" padding="10px 20px" height="45px"
                        placeholderColor="#000000" border="1px solid #2D3037" 
                        type={survey.startDate.length > 0 ? "date" : startDateSetInputTypeAs }
                        placeholder="Choose date..."
                        onChange={(event) => {inputStartDate(event);} }
                        onFocus={(event) => {this.startDateChangeInputTypeToDate(event); } }
                        errors={surveyStartDateError}
                        value={survey.startDate}
                        max="9999-12-31"
                        showErrors={true}
                    >
                    </FormField>

                    <br></br>

                    <Text color="#000" align="left" padding="0px 20px" size="16px" weight="bolder" margin="0">
                    Finish
                    </Text>
                    <FormField borderRadius="26px" size="14px" padding="10px 20px" height="45px"
                        placeholderColor="#000000" border="1px solid #2D3037"
                        width="100%"
                        type={survey.finishDate.length > 0 ? "date" : finishDateSetInputTypeAs}
                        placeholder="Choose date..."
                        onChange={(event) => {inputFinishDate(event);} }
                        onFocus={(event) => {this.finishDateChangeInputTypeToDate(event);} }
                        errors={surveyFinishDateError}
                        value={survey.finishDate}
                        max="9999-12-31"
                        showErrors={true}
                    >
                    </FormField>

                    <ErrorText>
                        { startDateInvalid.length > 0 ? startDateInvalid : null }
                        { finishDateInvalid.length > 0 ? finishDateInvalid : null }
                        { dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null }
                        { dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null }
                        { dateRangeInvalid.length > 0 ? dateRangeInvalid : null }
                    </ErrorText>

                </FlexContainer>

                <br></br>
                <br></br>

                <GridContainer align="center">
                    <GridChild style={{ textAlign: "right" }}>
                        <CancelButton onClick={(e) => {nextStep(FormStep.PAGE_TYPE_OF_SURVEY);}}>Back</CancelButton>
                    </GridChild>
                    <GridChild style={{ textAlign: "left" }}>
                        <NextStepButton onClick={(e) => {nextStep(FormStep.PAGE_USER_GROUP);}}>Next Step</NextStepButton>
                    </GridChild>
                </GridContainer>
            </>
        );
    }
}

class PageUserGroup extends Component {
    state = { tooltipActive: false };

    render() {
        const { nextStep, survey, selectUserGroup, inputErrors } = this.props;
        const { tooltipActive } = this.state;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const userGroupError = getErrorStrings(ErrorList.EMPTY_USER_GROUP);
        const userGroupAndDateError = getErrorStrings(ErrorList.DATE_AND_USERGROUP_OVERLAP);

        return (
            <>
                <Text color="#23262D" size="20px" weight="500">
                Select the user group that will receive your survey.

                    <img src={Information} alt="Tooltip Icon" className="tooltip" height="20px" onClick={(e) => this.setState({ tooltipActive: !tooltipActive })} />

                    <Tooltip active={tooltipActive} transform="0%">  
                    Choose to share your survey with your Employees, Clients or All Users.
                    </Tooltip>
                </Text>

                <Text color="#51315D" size="13px" weight="500">
                *You can not schedule multiple surveys for the same user group <br></br> during the same time period.
                </Text>
                <br></br>
                <br></br>

                <GridUserGroupContainer className={survey.userGroup === EnumUserGroup.EMPLOYEES ? "userGroupSelected" : ""} onClick={(e) => {selectUserGroup(EnumUserGroup.EMPLOYEES);}} >
                    <GridUserGroupChild>Employees</GridUserGroupChild>
                    {userGroupAndDateError.length === 0 && <CorrectImage display={survey.userGroup === EnumUserGroup.EMPLOYEES ? "inline" : ""}/> }
                    {userGroupAndDateError.length > 0 && <WrongImage display={survey.userGroup === EnumUserGroup.EMPLOYEES ? "inline" : ""}/> }
                </GridUserGroupContainer>

                <GridUserGroupContainer className={survey.userGroup === EnumUserGroup.CLIENTS ? "userGroupSelected" : ""} onClick={(e) => {selectUserGroup(EnumUserGroup.CLIENTS);}} >
                    <GridUserGroupChild>Clients</GridUserGroupChild>
                    {userGroupAndDateError.length === 0 && <CorrectImage display={survey.userGroup === EnumUserGroup.CLIENTS ? "inline" : ""}/> }
                    {userGroupAndDateError.length > 0 && <WrongImage display={survey.userGroup === EnumUserGroup.CLIENTS ? "inline" : ""}/> }
                </GridUserGroupContainer>

                <GridUserGroupContainer className={survey.userGroup === EnumUserGroup.ALL_USERS ? "userGroupSelected" : ""} onClick={(e) => {selectUserGroup(EnumUserGroup.ALL_USERS);}} >
                    <GridUserGroupChild>All Users</GridUserGroupChild>
                    {userGroupAndDateError.length === 0 && <CorrectImage display={survey.userGroup === EnumUserGroup.ALL_USERS ? "inline" : ""}/> }
                    {userGroupAndDateError.length > 0 && <WrongImage display={survey.userGroup === EnumUserGroup.ALL_USERS ? "inline" : ""}/> }
                </GridUserGroupContainer>

                <ErrorText>
                    { userGroupError.length > 0 ? userGroupError : null }
                    { userGroupAndDateError.length > 0 ? userGroupAndDateError : null }
                </ErrorText>

                <br></br>
                <br></br>
                <GridContainer align="center">
                    <GridChild style={{ textAlign: "right" }}>
                        <CancelButton onClick={(e) => {nextStep(FormStep.PAGE_SURVEY_DURATION);}}>Back</CancelButton>
                    </GridChild>
                    <GridChild style={{ textAlign: "left" }}>
                        <NextStepButton onClick={(e) => {nextStep(FormStep.PAGE_SURVEY_QUESTIONS);}}>Next Step</NextStepButton>
                    </GridChild>
                </GridContainer>
            </>
        );
    }
}

class PageSurveyQuestions extends Component {
    state = { tooltipActive: false };

    render() {
        const { nextStep, inputQuestion, inputErrors, survey, activeQuestionCount, addQuestion, removeQuestion } = this.props;
        const { tooltipActive } = this.state;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);   

        let surveyQuestionOneError = getErrorStrings(ErrorList.EMPTY_QUESTION_ONE);
        let surveyQuestionTwoError = getErrorStrings(ErrorList.EMPTY_QUESTION_TWO);
        let surveyQuestionThreeError = getErrorStrings(ErrorList.EMPTY_QUESTION_THREE);

        return (
            <>
                <Text color="#23262D" size="20px" weight="500">
                What would you like to ask your users?

                    <img src={Information} alt="Tooltip Icon" className="tooltip" height="20px" onClick={(e) => this.setState({ tooltipActive: !tooltipActive })} />

                    <Tooltip active={tooltipActive} transform="15%">  
                    Create up to 3 questions you would like to ask your users. Ask open-ended questions or simply gauge user satisfaction.
                    </Tooltip>
                </Text>

                <Text color="#51315D" size="16px" weight="500">
                Survey Type Selected: <strong>User {survey.surveyType} Survey</strong>
                </Text>

                <br/>
                <br/>
            
                {activeQuestionCount >= 1 && <QuestionContainer questionNumber={1} questionValue={survey.surveyQuestions.one.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} ></QuestionContainer>}
                {activeQuestionCount === 1 && <AddAnotherQuestionContainer addQuestion={addQuestion} />}
            
                {activeQuestionCount >= 2 && <QuestionContainer questionNumber={2} questionValue={survey.surveyQuestions.two.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} ></QuestionContainer>}
                {activeQuestionCount === 2 && <RemoveQuestionIconContainer display="inline" removeQuestion={removeQuestion} questionElement={"two"} /> }
                {activeQuestionCount === 2 && <AddAnotherQuestionContainer addQuestion={addQuestion} /> }
            
                {activeQuestionCount >= 3 && <QuestionContainer questionNumber={3} questionValue={survey.surveyQuestions.three.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} ></QuestionContainer>}
                {activeQuestionCount === 3 && <RemoveQuestionIconContainer display="inline" removeQuestion={removeQuestion} questionElement={"three"} />}

                <GridContainer align="center">
                    <GridChild style={{ textAlign: "right" }}>
                        <CancelButton onClick={(e) => {nextStep(FormStep.PAGE_USER_GROUP);}}>Back</CancelButton>
                    </GridChild>
                    <GridChild style={{ textAlign: "left" }}>
                        <NextStepButton onClick={(e) => {nextStep(FormStep.PAGE_SURVEY_FREQUENCY);}}>Next Step</NextStepButton>
                    </GridChild>
                </GridContainer>
            </>
        );
    }
}

class PageSurveyFrequency extends Component {
    state = { tooltipActive: false };
    
    render() {
        const { survey, inputErrors, nextStep, selectShowIn, selectShowFrequency } = this.props;
        const { tooltipActive } = this.state;
        
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const showInError = getErrorStrings(ErrorList.EMPTY_SHOW_IN);
        const showFrequencyError = getErrorStrings(ErrorList.EMPTY_SHOW_FREQUENCY);

        return (

            <>
                <Text color="#23262D" size="20px" weight="500">
                Select where users will receive your survey<br/>and how often it will appear.

                    <img src={Information} alt="Tooltip Icon" className="tooltip" height="20px" onClick={(e) => this.setState({ tooltipActive: !tooltipActive })} />

                    <Tooltip active={tooltipActive} transform="35%">  
                    Choose where you want your survey to pop up in your business app! Select how often you want this action to happen.
                    </Tooltip>
                </Text>
                <br/>
                <br/>
                <FlexContainer>
                    <Text color="#000" align="center" margin="10px 0px" size="16px" weight="500">
                    User will receive my survey in:
                    </Text>

                    <InputSelector fontWeight="500" size="14px" color="#51315D" onChange={(event) => {selectShowIn(event);} } value={survey.showIn} >
                        <option value={EnumSurveyShownIn.QUOTE_SCREEN}>{EnumSurveyShownIn.QUOTE_SCREEN} (Main Screen)</option>
                        <option value={EnumSurveyShownIn.NOTICEBOARD_SCREEN}>{EnumSurveyShownIn.NOTICEBOARD_SCREEN}</option>
                        <option value={EnumSurveyShownIn.GOAL_TRACK_SCREEN}>{EnumSurveyShownIn.GOAL_TRACK_SCREEN}</option>
                        <option value={EnumSurveyShownIn.ARTICLES_SCREEN}>{EnumSurveyShownIn.ARTICLES_SCREEN}</option>
                        <option value={EnumSurveyShownIn.PLAYLISTS_SCREEN}>{EnumSurveyShownIn.PLAYLISTS_SCREEN}</option>
                        <option value={EnumSurveyShownIn.APP_SETTINGS_SCREEN}>{EnumSurveyShownIn.APP_SETTINGS_SCREEN}</option>
                    </InputSelector>

                    <ErrorText>
                        {showInError}
                    </ErrorText>

                    <br/>

                    <Text color="#000" align="center" margin="10px 0px" size="16px" weight="500">
                    How often will my survey appear if not submitted by a user?
                    </Text>
                
                    <InputSelector fontWeight="500" size="14px" color="#51315D" onChange={(event) => {selectShowFrequency(event);} } value={survey.showFrequency}>
                        <option value={EnumSurveyShowFrequency.EVERYTIME}>Every time a user opens the app</option>
                        <option value={EnumSurveyShowFrequency.HOURS24}>{EnumSurveyShowFrequency.HOURS24} after user has seen the survey</option>
                        <option value={EnumSurveyShowFrequency.HOURS48}>{EnumSurveyShowFrequency.HOURS48} after user has seen the survey</option>
                    </InputSelector>
                
                    <ErrorText>
                        {showFrequencyError}
                    </ErrorText>
                    <br/>
                    <br/>
                    <Text color="#51315D" size="13px" weight="500">
                    *Users will not receive your survey again once submitted.
                    </Text>

                    <br/>
                    <br/>
                    <GridContainer align="center">
                        <GridChild style={{ textAlign: "right" }}>
                            <CancelButton onClick={(e) => {nextStep(FormStep.PAGE_SURVEY_QUESTIONS);}}>Back</CancelButton>
                        </GridChild>
                        <GridChild style={{ textAlign: "left" }}>
                            <NextStepButton onClick={(e) => {nextStep(FormStep.PAGE_OVERVIEW);}}>Next Step</NextStepButton>
                        </GridChild>
                    </GridContainer>
                </FlexContainer>
            </>
        );
    }
}

class PageOverview extends Component {
    render() {
        const { nextStep, survey, activeQuestionCount, formSubmission, loading } = this.props;

        const QuestionOverView = (({ questionNumber, inputQuestion }) => {
            return (
                <>
                    <Text color="#000" size="16px" weight="bold" align="left">
                        Question {questionNumber}
                    </Text>
                    <Text align="left" weight="500">
                        {inputQuestion.question}
                    </Text>
                </>
            );
        });
                      
        const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = survey["startDate"] ? formatter.format(new Date(survey["startDate"])) : "";
        const formattedFinishDate = survey["finishDate"] ? formatter.format(new Date(survey["finishDate"])) : "";

        return (
            <>
                <Text color="#23262D" size="20px" weight="500">
                Please review before proceeding to finish your survey.
                </Text>

                <FlexContainer style={{ margin: "auto", width: "450px" }}>
                
                    <GridContainer>
                        <GridChild style={{ textAlign: "left" }}>
                            <Text color="#51315D" size="25px" weight="500" align="left">
                            Survey Overview
                            </Text>
                        </GridChild>
                    </GridContainer>

                    <GridContainer align="center" style={{ gridTemplateColumns: "60% 40%" }} >                    
                        <GridChild style={{ textAlign: "right" }}>
                            <Text color="#51315D" size="16px" weight="bold" align="left">
                            Survey Name:
                                <Text size="16px" color="#000" align="left" margin="0" weight="500">{survey.name}</Text>
                            </Text>
                            <Text color="#51315D" size="16px" weight="bold" align="left">
                            Survey Type:
                                <Text size="16px" color="#000" align="left" margin="0" weight="500">{survey.surveyType}</Text>
                            </Text>
                            <Text color="#51315D" size="16px" weight="bold" align="left">
                            Survey Start/Finish Date:
                                <Text size="16px" color="#000" align="left" margin="0" weight="500">Start: {formattedStartDate}</Text>
                                <Text size="16px" color="#000" align="left" margin="0" weight="500">Finish: {formattedFinishDate}</Text>
                            </Text>
                            <Text color="#51315D" size="16px" weight="bold" align="left">
                            User Group Selected:
                                <Text size="16px" color="#000" align="left" margin="0" weight="500">{survey.userGroup}</Text>
                            </Text>

                            <Text color="#51315D" size="16px" weight="bold" align="left">
                            Show In:
                                <Text size="16px" color="#000" align="left" margin="0" weight="500">{survey.showIn}</Text>
                            </Text>
                        
                            <Text color="#51315D" size="16px" weight="bold" align="left">
                            Show Frequency:
                                <Text size="16px" color="#000" align="left" margin="0" weight="500">{survey.showFrequency === EnumSurveyShowFrequency.EVERYTIME ? "Every time" : survey.showFrequency}</Text>
                            </Text>

                        </GridChild>
                        <GridChild style={{ textAlign: "left" }}>
                            <Text color="#51315D" size="16px" weight="bold" align="left">
                            Survey Questions:
                            </Text>

                            {activeQuestionCount >= 1 && <QuestionOverView questionNumber="1" inputQuestion={survey.surveyQuestions.one} />}
                            {activeQuestionCount >= 2 && <QuestionOverView questionNumber="2" inputQuestion={survey.surveyQuestions.two} />}
                            {activeQuestionCount >= 3 && <QuestionOverView questionNumber="3" inputQuestion={survey.surveyQuestions.three} />}
                        </GridChild>
                    </GridContainer>
                </FlexContainer>
                <br/>
                <br/>

                {loading 
                    ?
                    <FlexContainer height="34px" >
                        <LoadingIndicator containerHeight="2rem" height="20px" width="20px" style={{ minHeight: "2em !important" }}/>
                    </FlexContainer>
                    :
                    <GridContainer align="center">
                        <GridChild style={{ textAlign: "right" }}>
                            <CancelButton onClick={(e) => {nextStep(FormStep.PAGE_SURVEY_FREQUENCY);}}>Back</CancelButton>
                        </GridChild>
                        <GridChild style={{ textAlign: "left" }}>

                            <NextStepButton onClick={(e) => {formSubmission();}}>Finish</NextStepButton>
                            
                        </GridChild>
                    </GridContainer>
                }
            </>
        );
    }
}

class PageCongratulations extends Component {
    hideFormAndReloadList = () => {
        const { showSurveyForm, getSurveys } = this.props;

        showSurveyForm(false);
        getSurveys(); 
    };

    render() {
        return (
            <FlexContainer justifyContent="center" alignItems="center" height="70vh" style={{ margin: "auto" }}>
                <img src={Correct} alt="success" height="95px" />
                <Text color="#23262D" size="60px" weight="500" style={{ marginBlockEnd: "0px" }}>
                    Congratulations!
                </Text>
                <Text color="#23262D" size="60px" weight="500" style={{ marginBlockStart: "0px" }}>
                    Your Survey Has Been Created.
                </Text>
                <Text color="black" size="14px" width="650px" lineHeight="25px" margin="0">
                    You can always make edits to your scheduled and active surveys via the 
                    &rsquo;Surveys&rsquo; main screen and view insights once your survey has gone live.
                </Text>

                <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "100px", height: "40px", marginTop: "65px" }}
                    onClick={() => { this.hideFormAndReloadList(); }} >
                    Close
                </StyledButton>
            </FlexContainer>
        );
    }
}

export default SurveyForm;