import { EventType, RoomEvent } from "matrix-js-sdk";
import { KnownMembership } from "matrix-js-sdk/lib/@types/membership";
import { useCallback, useEffect, useState } from "react";
import { EVENT_ID_TO_REPLACE, LOCAL_ECHO } from "src/constants/matrixEventTypes";
import { useGetEventData } from "./useGetEventData";
import { ADMIN_MATRIX_ID } from "src/constants/chat";

/**
 * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
 * @typedef {import('matrix-js-sdk').MatrixEvent} MatrixEvent
 * @typedef {import('matrix-js-sdk').Room} Room
 * @param {MatrixClient} matrixClient
 * @param {boolean} isInitialSyncComplete
 */
export default function useTimelineListener(matrixClient, isInitialSyncComplete) {

    const { getEventData } = useGetEventData(matrixClient);

    const [newEvent, setNewEvent] = useState(null);
    const [matrixRoomIdToRemove, setMatrixRoomIdToRemoveState] = useState(null);
    const [newMatrixRoomId, setNewMatrixRoomIdState] = useState(null);
    const [roomWithMemberChange, setRoomWithMemberChangeState] = useState(null);

    useEffect(() => {
        if (matrixClient !== null && isInitialSyncComplete) {
            listenToTimeline(matrixClient);
        }
    }, [matrixClient, isInitialSyncComplete]);

    const resetRoomWithMemberChange = useCallback((propertyName) => {
        setRoomWithMemberChangeState(null);
    });

    const resetNewMatrixRoomId = useCallback(() => {
        setNewMatrixRoomIdState(null);
    }, []);

    const resetMatrixRoomIdToRemove = useCallback(() => {
        setMatrixRoomIdToRemoveState(null);
    }, []);

    const listenToTimeline = (client) => {

        client.on(
            "Room.timeline",

            /**
             * @param {MatrixEvent} event
             * @param {Room} room
             * @param _toStartOfTimeline
             * @returns {Promise<void>}
             */
            async function(event, room, _toStartOfTimeline) {
                const eventType = event.getType();
                const sender = event.getSender();

                if ([EventType.RoomMessageEncrypted, EventType.RoomMessage].includes(eventType)) {
                    if (sender === client.getUserId()) {
                        /**
                         * Messages sent by the logged-in user are encrypted but `matrixEvent.isEncrypted()` returns false
                         * maybe because it's a local echo? See more at `useSendMessage.js`
                         * Recreating an event here that can be read by the chat list and timeline messages that will be on
                         * a "sending" state.
                         */
                        const newEvent = {
                            type: eventType,
                            content: event.getContent(),
                            event_id: event.getId(),
                            sender: sender,
                            roomId: event.getRoomId(),
                            origin_server_ts: event.localTimestamp,
                            [LOCAL_ECHO]: true
                        };
                        setNewEvent(newEvent);
                    }
                    else if (sender !== client.getUserId()) {
                        // If the event is encrypted, it will be accounted for in getEventData
                        const formatted = await getEventData(event);
                        setNewEvent(formatted);
                    }
                }
                else if (eventType === EventType.RoomMember) {
                    const content = event.getContent();
                    const roomId = room.roomId;
                    const clientMatrixId = client.getUserId();

                    // this is for other users that are joining your chat
                    if (roomId && content?.membership === KnownMembership.Join) {
                        setRoomWithMemberChangeState(roomId);
                        setNewEvent(event.event);
                    }

                    if (roomId && content?.membership === KnownMembership.Leave) {
                        const removedUserId = event.getStateKey();
                        /**
                         * removedUserId === ADMIN_MATRIX_ID means the chat was deleted by the other user
                         * this listener and MyMembership do not receive an event specifically for a deleted chat
                         * TODO: as of writing, sometimes it removes the deleted chat, sometimes it doesnt. but attempts at opening the chat will return;
                         * a graceful error of "Unable to fetch timeline"
                         */
                        if ([clientMatrixId, ADMIN_MATRIX_ID].includes(removedUserId)) {
                            return setMatrixRoomIdToRemoveState(roomId);
                        }
                        setNewEvent(event.event);
                        setRoomWithMemberChangeState(roomId);
                    }
                }
            }
        );

        client.on(
            RoomEvent.MyMembership,

            /**
             * Transferring membership join here because the timeline listener receives several join membership events
             * for a single, new chat this is only fired if logged-in user is added to a new chat. Deleted chat event
             * cannot be received here
             * @param {Room} room
             * @param {KnownMembership} membership
             * @param _prev
             */
            (room, membership, _prev) => {
                if (membership === KnownMembership.Join) {
                    setNewMatrixRoomIdState(room.roomId);
                }
            }
        );

        client.on(
            RoomEvent.LocalEchoUpdated,

            /**
             * a confirmation that the message have been delivered to the server
             * ChatMessages will replace the local echo with this new event.
             * @param {MatrixEvent} event
             * @param {Room} room
             * @param {string} oldEventId
             */
            async (event, room, oldEventId) => {
                switch (event.status) {
                case "sent":
                    event.event.content = event.getClearContent();
                    event.event[EVENT_ID_TO_REPLACE] = oldEventId;
                    event.event.status = "sent";
                    setNewEvent(event.event);
                    break;
                case "sending":
                    event.event[EVENT_ID_TO_REPLACE] = oldEventId;
                    event.event.status = "sending";
                    setNewEvent(event.event);
                    break;
                case "encrypting":
                    event.event[EVENT_ID_TO_REPLACE] = oldEventId;
                    event.event.status = "encrypting";
                    setNewEvent(event.event);
                    break;
                default:
                    break;
                }
            });
    };

    return {
        newEvent,
        newMatrixRoomId,
        matrixRoomIdToRemove,
        roomWithMemberChange,
        resetNewMatrixRoomId,
        resetMatrixRoomIdToRemove,
        resetRoomWithMemberChange
    };
}