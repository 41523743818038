
const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
    labels,
    datasets: [
        {
            label: "Dataset 1",
            data: labels.map(() => randomNumber(-1000, 1000)),
            fill: false,
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            borderColor: "#02BC77",
            pointRadius: 0,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            yAxisID: "y",
        }
    ],
};