import React, { Component } from "react";
import styled from "styled-components";
import { LicenseRenewalSubscriptionContext } from "../LicenseRenewalSubscriptionContext";
import { SubscriptionContext } from "src/scenes/Subscription/SubscriptionContext";
import { FormStep } from "../LicenseRenewal";
import AirwallexForm, { AirwallexProduct } from "src/scenes/Subscription/components/AirwallexForm";
import { FlexContainer, Text, Button, tablet, desktop } from "src/components";

const PaymentConfirmationDiv = styled.div`
    background: #FFFFFF;
    
    ${desktop`
        padding-top: 2em;
    `};
`;

const WhiteBox = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;

    ${desktop`
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        padding: 3em;
        width: 455px;
    `};
`;

const BackButton = styled(Button)`
    width: 140px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 7px !important;

    padding: 10px 0px !important;
    color: #000;
    cursor: pointer;
    display: none;

    ${desktop`
        display:block;
    `};
`;

const TwoColumnRowDiv = styled.div`
    display: flex;
    padding: ${p => p.padding};
`;

const TwoColumnDiv = styled.div`
    flex: 50%;
    font-size: ${p => p.size || "1rem"};
    font-weight: ${p => p.weight || "normal"};
    text-align: ${p => p.align};
`;

const GrayBackgroundTotal = styled.div`
    background: #EDEDED 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 1em 2em;
    margin-bottom: 2em;

    ${tablet`
        margin-bottom: 4em;
    `};
`;

class PaymentConfirmation extends Component {
    state = {
        paymentConfirmationLoading: false,
    };

    static contextType = LicenseRenewalSubscriptionContext;

    backStepToPagePurchaseLicense = () => {
        this.context.nextStep(FormStep.PAGE_PURCHASE_LICENSE);
    };

    nextClick = () => {
        this.context.nextStep(FormStep.PAGE_PURCHASE_SUCCESSFUL);
    };

    render() {
        const { referralCode, key, companyName, licenseTermDuration, licenseTermDurationUnit, licenseTermAmount, isAustralian, gstAmount, chargeAmount } = this.context;

        return (
            <PaymentConfirmationDiv style={{ margin: "auto 0" }}>
                <FlexContainer padding="2rem 2rem">
                
                    <FlexContainer mDirection="column" direction="row" justifyContent="center">
                      
                        <WhiteBox>
                            <Text align="left" size="1.5625rem" weight="700" style={{ marginTop: "0" }}>
                                Order Summary
                            </Text>

                            <Price 
                                licenseTermDuration={licenseTermDuration} 
                                licenseTermAmount={licenseTermAmount} 
                                licenseTermDurationUnit={licenseTermDurationUnit} 
                                isAustralian={isAustralian}
                                gstAmount={gstAmount}
                                chargeAmount={chargeAmount}
                            />

                            <br/>

                            <FlexContainer>
                                <SubtotalGstAndTotal licenseTermAmount={licenseTermAmount} isAustralian={isAustralian} gstAmount={gstAmount} chargeAmount={chargeAmount}/>
                            </FlexContainer>

                            <BackButton onClick={(e) => {this.backStepToPagePurchaseLicense();}} >Back</BackButton>
                  
                        </WhiteBox>

                        <FlexContainer width="1em">{/*<!-- Vertical Space -->*/}</FlexContainer>

                        <WhiteBox>

                            <Text align="left" size="1.5625rem" weight="700" style={{ marginTop: "0" }}>
                                Payment Method
                            </Text>

                            <SubscriptionContext.Provider value={{
                                //global
                                referralCode: referralCode,
                                key: key,
                                nextStep: this.nextClick,
                                companyName: companyName, 
                                
                                //price related
                                licenseTermAmount: licenseTermAmount
                            }}>
                                <AirwallexForm
                                    productType={AirwallexProduct.CLIENT_RENEWAL}
                                    companyName={companyName}
                                    abn=""
                                    currentForm={""} 
                                    backToPrevious={() => this.backStepToPagePurchaseLicense()}
                                    nextClick={() => this.nextClick()} 
                                />
                            </SubscriptionContext.Provider>
                    
                        </WhiteBox>
                    </FlexContainer>

                </FlexContainer>
            </PaymentConfirmationDiv>
        );
    }
}

class Price extends Component {
    render() {
        const { licenseTermDuration, licenseTermDurationUnit, licenseTermAmount } = this.props;
        
        const durationUnit = licenseTermDuration <= 1 ? licenseTermDurationUnit : licenseTermDurationUnit + "s"; /** Plural Form */
        const durationNiceString = licenseTermDuration + " " + durationUnit.charAt(0).toUpperCase() + durationUnit.slice(1); /*Upper Casing */
        
        return (
            <>
                <FlexContainer>

                    <FlexContainer>
                        <Text margin="0" align="left" size="16px" weight="700">Subscription Type:</Text>
                        <Text margin="0" align="left" size="16px" >Yearly</Text>
                        <Text margin="0" align="left" size="16px" >Yearly Plan, Prepaid</Text>
                    </FlexContainer>

                    <br/>

                    <FlexContainer>
                        <TwoColumnRowDiv padding="5px 0px">
                            <TwoColumnDiv weight="500">License Duration</TwoColumnDiv>
                            <TwoColumnDiv weight="700" align="right">{durationNiceString}</TwoColumnDiv>
                        </TwoColumnRowDiv>
                    
                        <TwoColumnRowDiv padding="5px 0px">
                            <TwoColumnDiv weight="500">License Price</TwoColumnDiv>
                            <TwoColumnDiv weight="700" align="right">${licenseTermAmount.toFixed(2)}/per year</TwoColumnDiv>
                        </TwoColumnRowDiv>
                    </FlexContainer>
                
                </FlexContainer>
            </>
        );
    }
}

class SubtotalGstAndTotal extends Component {
    render() {
        const { licenseTermAmount, isAustralian, gstAmount, chargeAmount } = this.props;

        return (
            <GrayBackgroundTotal>
                <TwoColumnRowDiv padding="10px 0px">
                    <TwoColumnDiv weight="500">Subtotal</TwoColumnDiv>
                    <TwoColumnDiv weight="500" align="right">${licenseTermAmount.toFixed(2)}/yr</TwoColumnDiv>
                </TwoColumnRowDiv>

                {isAustralian &&
                <TwoColumnRowDiv padding="10px 0px">
                    <TwoColumnDiv weight="500">GST</TwoColumnDiv>
                    <TwoColumnDiv weight="500" align="right">${gstAmount}/yr</TwoColumnDiv>
                </TwoColumnRowDiv> }

                <TwoColumnRowDiv padding="10px 0px">
                    <TwoColumnDiv size="20" weight="700">Total</TwoColumnDiv>
                    <TwoColumnDiv size="20" weight="700" align="right">USD ${chargeAmount}/yr</TwoColumnDiv>
                </TwoColumnRowDiv>
            </GrayBackgroundTotal>
        );
    }
}

export default PaymentConfirmation;