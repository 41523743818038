import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import styled from "styled-components";
import {
    Button, FlexContainer, ExplainParagraphLabel, PageHeadingLabel,
    PageContainer, Toast,
    TwoColumnLayout
} from "src/components";
import "src/stylesheets/statistics.css";
import StatisticsCard from "./components/StatisticsCard";
import Skeleton from "./components/Skeleton";
import { IsFeatureReady } from "src/constants/features";
import MoodDistributionCard from "./components/MoodDistributionCard";
import MoodRatingCard from "./components/MoodRatingCard";
import { desktop, smallDesktop } from "src/components/Media";

const DateRange = [
    "Last 7 Days",
    "Last 30 Days",
    "Last 3 Months",
    "Last 6 Months",
    "Last Year",
    "Lifetime",
];

const Label = styled.div`
  color: #000000;
  font: normal normal bold 1rem/1.3125rem Roboto;
  margin: 1rem 0.5rem -2rem -9.5rem;
  text-align: left;
  letter-spacing: 0;
  opacity: 1;
`;

const DropDown = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 auto 1rem 0;
  width: 14rem;

  :hover div {
    display: block;
  }
`;

const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 20px;

  button {
    color: black;
    text-decoration: none;
    display: block;
    border-radius: 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: #ffffff;

    :hover {
      background-color: #f1f1f1;
    }
  }
`;

const ContentContainer = styled.div`
  overflow-x: auto;
  padding: 1rem 1.5rem 1rem 1.5rem;
  /* UI Properties */
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #8291b2;
  border-radius: 7px 7px 0 0;
  opacity: 1;
`;

const ContentCardRows = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, 17.5rem);
    ${smallDesktop`
        grid-template-columns: auto auto;
    `}
    ${desktop`
        grid-template-columns: min-content min-content min-content;
    `}
`;

const CardHeader = styled.div`
  margin: 0.5rem 1rem 0.5rem 0;
  width: 12.5rem;
  height: 1.625rem;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 1.25rem/1.625rem Roboto;
  font-size: 1.0625rem;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
`;

const DropDownContentButton = styled(Button)`
  width: 100%;
`;

const DropDownButton = styled.button`
  color: black;
  font-size: 0.875rem;
  cursor: pointer;
  width: 100%;
  height: 2.5rem;
  border: 1px solid #000000;
  border-radius: 0.4375rem;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  opacity: 1;

  :hover {
    background-color: #f3f3f4;
  }
`;

const LeftColumnSizing = `
    ${smallDesktop `
        65%
    `};
    ${desktop `
        50%
    `}
`;

class Statistics extends Component {
    state = {
        fetching: 0,
        fetchingLineData: 0,
        fetchingMoodTrackerData: 0,
        dateRange: DateRange[0],
        type: "Employees",
        stats: {
            totalActiveEmployees: {},
            appSessions: {},
            retentionRate: {},
            totalPosts: 0,
            postViews: 0,
            interactionRate: {},
            quoteSaved: 0,
            articlesRead: 0,
            playlistsCount: 0,
            bodyAndMind: 0,
            recordPlaylistCounts: 0,
            popularCategory: {},
            teamGoals: 0,
            teamGoalsAchieved: {},
            achievedTeamGoals: {},
            userCounts: {
                Employees: 0,
                Clients: 0,
            },
            moodData: {
                overAllMoodRating: "Happy",
                moodCheckins: [
                    {
                        moodType: "Happy",
                        count: 35
                    },
                    {
                        moodType: "Content",
                        count: 25
                    },
                    {
                        moodType: "Neutral",
                        count: 20
                    },
                    {
                        moodType: "Sad",
                        count: 10
                    },
                    {
                        moodType: "Unhappy",
                        count: 7
                    },
                    {
                        moodType: "Tired",
                        count: 2
                    },
                    {
                        moodType: "Stressed",
                        count: 1
                    }
                ]
            }
        },
    };

    setRange = (range) => {
        if (this.state.fetching === 0) {
            this.setState(
                {
                    dateRange: range,
                },
                () => {
                    this.fetchDateRange();
                }
            );
        }
    };

    setType = (type) => {
        if (this.state.fetching === 0) {
            this.setState(
                {
                    type: type,
                },
                () => {
                    this.fetchDateRange();
                }
            );
        }
    };

    componentDidMount() {
        this.fetchDateRange();
    }

    fetchLineData = () => {
        const { dateRange, type } = this.state;
        this.setState({ fetchingLineData: 1 });
        axios
            .get(
                `${API_URL}/company/statisticsLineData?filter=${dateRange}&type=${type}`,
                {
                    headers: {
                        Authorization: "Bearer " + this.props.user.token,
                    },
                }
            )
            .then((res) => {
                const {
                    totalActiveEmployees,
                    appSessions,
                    retentionRate,
                    postViews,
                    interactionRate,
                    teamGoalsAchieved
                } = res.data;
                this.setState({
                    stats: {
                        ...this.state.stats,
                        totalActiveEmployees: {
                            ...this.state.stats.totalActiveEmployees,
                            ...totalActiveEmployees,
                        },
                        appSessions: { ...this.state.stats.appSessions, ...appSessions },
                        retentionRate: {
                            ...this.state.stats.retentionRate,
                            ...retentionRate,
                        },
                        postViews: { ...this.state.stats.postViews, ...postViews },
                        interactionRate: {
                            ...this.state.stats.interactionRate,
                            ...interactionRate,
                        },
                        teamGoalsAchieved: {
                            ...this.state.stats.teamGoalsAchieved,
                            ...teamGoalsAchieved
                        }
                    },
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    error: "An error occurred while fetching data. Please try again.",
                });
            })
            .finally(() => {
                this.setState({
                    fetchingLineData: 0,
                });
            });
    };

    fetchDateRange = () => {
        const { type, dateRange } = this.state;
        this.setState({
            fetching: 1,
            fetchingMoodTrackerData: 1
        });

        axios
            .get(`${API_URL}/company/statistics?filter=${dateRange}&type=${type}`, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token,
                },
            })
            .then((res) => {
                if (res.status >= 200 && res.status <= 299) {
                    this.setState({
                        stats: { ...this.state.stats, ...res.data },
                        fetching: 0,
                    });
                    this.fetchMoodTrackerData();
                    this.fetchLineData();
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    fetching: 0,
                });
            });
    };

    fetchMoodTrackerData = () => {
        const { type, dateRange } = this.state;
        axios
            .get(`${API_URL}/company/moodTrackerData?filter=${dateRange}&type=${type}`, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token,
                },
            })
            .then((res) => {
                if (res.status >= 200 && res.status <= 299) {
                    this.setState({
                        stats: { ...this.state.stats,
                            moodData: { 
                                overAllMoodRating: res.data.overAllMoodRating,
                                moodCheckins: res.data.moodCheckins
                            }
                        },
                    });
                }
            })
            .catch((error) => {
                Toast.error(error);
                this.setState({ fetchingMoodTrackerData: 0 });
            })
            .finally(() => {
                this.setState({ fetchingMoodTrackerData: 0 });
            });
    };

    render() {
        const {
            fetching,
            dateRange,
            stats: {
                totalActiveEmployees,
                appSessions,
                retentionRate,
                totalPosts,
                postViews,
                interactionRate,
                quoteSaved,
                articlesRead,
                bodyAndMind,
                recordPlaylistCounts,
                popularCategory,
                userCounts,
                moodData,
                teamGoals,
                achievedTeamGoals,
                teamGoalsAchieved
            },
            type,
            fetchingLineData,
            fetchingMoodTrackerData,
        } = this.state;
        const users = type === "Employees" ? "Employees" : "Clients";
        const pageHeadingLabel = type === "Employees" ? "Employee Statistics" : "Client Statistics";
        const userCountsLabel = userCounts[type] !== 0 ? `(${userCounts[type]})` : "";
        const dropdownEmployeeLabel = userCounts.Employees !== 0 ? `Employees (${userCounts.Employees})` : "Employees";
        const dropdownClientLabel = userCounts.Clients !== 0 ? `Clients (${userCounts.Clients})` : "Clients";
        const totalActiveEmployeesValue = totalActiveEmployees.value ? totalActiveEmployees.value : 0;
        const activeUserGraphData = totalActiveEmployees.lineGraphData ? totalActiveEmployees.lineGraphData : [];
        const retentionRateValue = retentionRate.value ? retentionRate.value : 0;
        const retentionRateGraphData = retentionRate.lineGraphData ? retentionRate.lineGraphData : [];
        const appSessionsValue = appSessions.value ? appSessions.value : 0;
        const appSessionsGraphData = appSessions.lineGraphData ? appSessions.lineGraphData : [];
        const totalPostsValue = totalPosts ? totalPosts : 0;
        const postViewsValue = postViews.value ? postViews.value : 0;
        const postViewsRateOfChange = postViews.rateOfChange ? postViews.rateOfChange : 0;
        const postViewsGraphData = postViews.lineGraphData ? postViews.lineGraphData : [];
        const interactionRateValue = interactionRate.value ? interactionRate.value : 0;
        const interactionRateGraphData = interactionRate.lineGraphData ? interactionRate.lineGraphData : [];
        const quoteSavedValue = quoteSaved.value ? quoteSaved.value : 0;
        const articlesReadValue = articlesRead.value ? articlesRead.value : 0;
        const recordPlaylistCountValue = recordPlaylistCounts.value ? recordPlaylistCounts.value : 0;
        const bodyAndMindValue = bodyAndMind.value ? bodyAndMind.value : 0;
        const totalTeamGoalsSet = teamGoals;
        const totalAchievedTeamGoal = achievedTeamGoals.value ? achievedTeamGoals.value : 0 ;
        const teamGoalsAchievedLineGraphData = teamGoalsAchieved.lineGraphData ? teamGoalsAchieved.lineGraphData : [];
        return (
            <PageContainer>
                <FlexContainer
                    direction="row"
                    justifyContent="space-between"
                    marginBottom="1.625rem"
                    style={{ maxWidth: "95.188rem" }}
                >
                    <div style={{ flexGrow: 1 }}>
                        <PageHeadingLabel>{pageHeadingLabel}</PageHeadingLabel>
                        <ExplainParagraphLabel
                            width="100%"
                            size="0.775rem"
                            maxWidth="34rem"
                        >
                            Statistics offer valuable insights into your workforce and
                            clientele, enabling you to monitor the total number of active
                            employees and clients, assess brand impressions, and determine the
                            average time spent on your Me Business app. These data-driven
                            metrics are instrumental in facilitating informed decision-making
                            aimed at enhancing both employee and client experiences.
                        </ExplainParagraphLabel>
                    </div>
                    <FlexContainer direction="row">
                        <FlexContainer
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <FlexContainer direction="row" justifyContent="center">
                                <Label>Selected Statistics:</Label>
                                <DropDown>
                                    <DropDownButton>
                                        <div
                                            style={{
                                                flexGrow: 1,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textAlign: "left",
                                                    fontWeight: "bold",
                                                    paddingLeft: "0.625rem",
                                                    fontSize: "1rem",
                                                }}
                                            >{`${type} ${userCountsLabel}`}</div>
                                            <div
                                                style={{
                                                    paddingRight: "5px",
                                                    transform: "scaleX(1.5)",
                                                }}
                                            >
                                                ▼
                                            </div>
                                        </div>
                                    </DropDownButton>
                                    <DropDownContent>
                                        <DropDownContentButton
                                            onClick={() => this.setType("Employees")}
                                        >
                                            {dropdownEmployeeLabel}
                                        </DropDownContentButton>
                                        <DropDownContentButton
                                            onClick={() => this.setType("Clients")}
                                        >
                                            {dropdownClientLabel}
                                        </DropDownContentButton>
                                    </DropDownContent>
                                </DropDown>
                            </FlexContainer>
                            <FlexContainer direction="row" justifyContent="center">
                                <Label>Filter:</Label>
                                <DropDown>
                                    <DropDownButton>
                                        <div
                                            style={{
                                                flexGrow: 1,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textAlign: "left",
                                                    fontWeight: "bold",
                                                    paddingLeft: "0.625rem",
                                                    fontSize: "1rem",
                                                }}
                                            >
                                                {dateRange}
                                            </div>
                                            <div
                                                style={{
                                                    paddingRight: "5px",
                                                    transform: "scaleX(1.5)",
                                                }}
                                            >
                                                ▼
                                            </div>
                                        </div>
                                    </DropDownButton>
                                    <DropDownContent>
                                        {DateRange.map((a, i) => {
                                            return (
                                                <DropDownContentButton
                                                    key={i}
                                                    onClick={() => this.setRange(a)}
                                                >
                                                    {a}
                                                </DropDownContentButton>
                                            );
                                        })}
                                    </DropDownContent>
                                </DropDown>
                            </FlexContainer>
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
                <div>
                    <ContentContainer>
                        <TwoColumnLayout
                            padding="0rem"
                            leftContainerWidth={LeftColumnSizing} rightContainerWidth="auto"
                            leftContainerPadding="0rem" rightContainerPadding="0px 0px 0px 50px" 
                        > 
                            <> {/* left column */}
                                <CardHeader>
                                    {fetching ? 
                                        <Skeleton
                                            variant="Text"
                                            width="150px"
                                            height="25px"
                                            animation="wave"
                                        />
                                        :
                                        "User Engagement"
                                    }
                                </CardHeader>
                                <ContentCardRows>
                                    <StatisticsCard
                                        header={`Total Active ${users}`}
                                        footer={`Total number of Active ${users} within a specific period.`}
                                        loading={fetching}
                                        total={`${totalActiveEmployeesValue}`}
                                        rate={Math.round(totalActiveEmployees.rateOfChange)}
                                        loadingLineGraph={fetchingLineData}
                                        graphData={activeUserGraphData}
                                    ></StatisticsCard>
                                    <StatisticsCard
                                        header="Retention Rate"
                                        footer={`Percentage of ${users} who continue using the app over time.`}
                                        loading={fetching}
                                        total={`${`${parseFloat(retentionRateValue).toFixed()}%`}`}
                                        rate={Math.round(retentionRate.rateOfChange)}
                                        loadingLineGraph={fetchingLineData}
                                        graphData={retentionRateGraphData}
                                    ></StatisticsCard>
                                    <StatisticsCard
                                        header="App Sessions"
                                        footer={`Average Sessions per ${users}.`}
                                        loading={fetching}
                                        total={`${appSessionsValue}`}
                                        rate={Math.round(appSessions.rateOfChange)}
                                        loadingLineGraph={fetchingLineData}
                                        graphData={appSessionsGraphData}
                                    ></StatisticsCard>
                                </ContentCardRows>
                                <CardHeader>
                                    {fetching ? 
                                        <Skeleton
                                            variant="Text"
                                            width="150px"
                                            height="25px"
                                            animation="wave"
                                        />
                                        : 
                                        "Business Noticeboard"
                                    }
                                </CardHeader>
                                <ContentCardRows>
                                    <StatisticsCard
                                        header="Total Posts"
                                        footer={`Number of posts by admins on the ${users} Noticeboard.`}
                                        loading={fetching}
                                        total={`${totalPostsValue}`}
                                    ></StatisticsCard>
                                    <StatisticsCard
                                        header="Post Impressions"
                                        footer={`Total new posts viewed by all ${users}.`}
                                        loading={fetching}
                                        total={`${postViewsValue}`}
                                        rate={postViewsRateOfChange}
                                        loadingLineGraph={fetchingLineData}
                                        graphData={postViewsGraphData}
                                    ></StatisticsCard>
                                    {IsFeatureReady.interactionRateStat() && (
                                        <StatisticsCard
                                            header="Interaction Rate"
                                            footer={`How many ${users} engaged with the posted content.`}
                                            loading={fetching}
                                            total={`${interactionRateValue}`}
                                            rate={Math.round(interactionRate.rateOfChange)}
                                            loadingLineGraph={fetchingLineData}
                                            graphData={interactionRateGraphData}
                                        ></StatisticsCard>
                                    )}
                                </ContentCardRows>
                                <CardHeader>
                                    {fetching ? 
                                        <Skeleton
                                            variant="Text"
                                            width="150px"
                                            height="25px"
                                            animation="wave"
                                        />
                                        :
                                        "Content Engagement"
                                    }
                                </CardHeader>
                                <ContentCardRows>
                                    <StatisticsCard
                                        header="Quotes Saved"
                                        footer={`Number of times ${users} have saved quotes to favourites.`}
                                        loading={fetching}
                                        total={`${quoteSavedValue}`}
                                    ></StatisticsCard>
                                    <StatisticsCard
                                        header="Most Popular Quote Category"
                                        footer={`The most popular quote category selected by ${users}.`}
                                        loading={fetching}
                                        total={`${popularCategory}`}
                                    ></StatisticsCard>
                                    <StatisticsCard
                                        header="Articles Read"
                                        footer={`Number of times ${users} have opened and viewed an article.`}
                                        loading={fetching}
                                        total={`${articlesReadValue}`}
                                    ></StatisticsCard>
                                    <StatisticsCard
                                        header="Business Playlist"
                                        footer={`Number of times ${users} have selected to open your business playlists.`}
                                        loading={fetching}
                                        total={`${recordPlaylistCountValue}`}
                                    ></StatisticsCard>
                                    <StatisticsCard
                                        header="Body & Mind Content Saved"
                                        footer={`Number of times ${users} have saved content in the Body & Mind feature.`}
                                        loading={fetching}
                                        total={`${bodyAndMindValue}`}
                                    ></StatisticsCard>
                                </ContentCardRows>
                            </>

                            <> { /* right column */}
                                { users === "Employees" ?
                                    <>
                                        <CardHeader>
                                            {fetching ?
                                                <Skeleton
                                                    variant="Text"
                                                    width="150px"
                                                    height="25px"
                                                    animation="wave"
                                                />
                                                :
                                                "Team Goal Tracking"
                                            }
                                        </CardHeader>
                                        <ContentCardRows>
                                            <StatisticsCard
                                                header="Team Goals Set"
                                                footer="Number of team goals set by employees."
                                                loading={fetching}
                                                total={`${totalTeamGoalsSet}`}
                                                graphData={0}
                                            ></StatisticsCard>
                                            <StatisticsCard
                                                header="Achieved Team Goals"
                                                footer="Percentage of achieved team goals."
                                                loading={fetching}
                                                total={`${totalAchievedTeamGoal}`}
                                                rate={Math.round(retentionRate.rateOfChange)}
                                                loadingLineGraph={fetchingLineData}
                                                graphData={teamGoalsAchievedLineGraphData}
                                            ></StatisticsCard>
                                        </ContentCardRows>
                                    </>
                                    : <> </>}
                                <CardHeader>
                                    {fetching ?
                                        <Skeleton
                                            variant="Text"
                                            width="150px"
                                            height="25px"
                                            animation="wave"
                                        />
                                        :
                                        `${users} Mood Tracker`
                                    }
                                </CardHeader>
                                <ContentCardRows>
                                    <MoodRatingCard
                                        header="Overall Mood Rating"
                                        footer={`Overall mood rating for ${users}.`}
                                        loading={fetchingMoodTrackerData}
                                        moodData={moodData}
                                    >
                                    </MoodRatingCard>
                                    <MoodDistributionCard
                                        header="Mood Distribution"
                                        footer={`Mood distribution across all ${users}.`}
                                        loading={fetchingMoodTrackerData}
                                        cardHeight="22.875rem"
                                        cardType="Mood"
                                        moodData={moodData}
                                    />
                                </ContentCardRows>
                            </>
                        </TwoColumnLayout>
                    </ContentContainer>
                </div>
            </PageContainer>
        );
    }
}

export default Statistics;
