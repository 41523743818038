import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { LottieLoadingIndicator, Text } from "src/components";
import { countSurveyResponseValue } from "../Utils";
import { data } from "./data";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export const options = {
    responsive: true,
    legend: {
        display: false,
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        title: {
            display: false,
        },
        legend: {
            display: false,
        }
    },
    interaction: {
        intersect: false,
    },
    maintainAspectRatio: false,
    scales: {
        x: {
            display: false,
            title: {
                display: false
            },
            gridLines: {
                display: false
            },
            scaleLabel: {
                display: false
            }
        },
        y: {
            ticks: {
                beginAtZero: true
            },
            display: false,
            title: {
                display: false,
            },
            gridLines: {
                display: false
            },
            scaleLabel: {
                display: false
            },
            suggestedMin: -10,
            suggestedMax: 200
        }
    },
    elements: {
        point: {
            hoverRadius: 0
        }
    }
};


const OverviewCard = styled.div`
    width: 860px;
    height: 160px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000026;
    border: 1px solid #8291B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    position: relative;
`;

const OverviewHalfColor = styled.div`
    width: 860px;
    height: 80px;
    /* UI Properties */
    background: #F0F0F0 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000026;
    border-radius: 0 0 10px 10px;
    opacity: 1;
    position: absolute;
    bottom: 0;
`;

const OverviewCounterContainer = styled.div`
    width: 200px;
    height: 100%;
    margin: auto 0 4% 16px;
`;

const OverviewCounterItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: ${p => p.marginBottom}
`;

const OverviewCounterCircleIcon = styled.div`
    width: 47px;
    height: 47px;
    background: #000;
    border-radius: 25px;
`;

const OverviewCounterDetails = styled.div`
    display: flex;
    width: 110px;
    flex-direction: column;
    margin-left: 16px;
    text-align: left;
`;

const SurveyYesNoCard = (props) => {
    const { surveyQuestion, responsesStats, fetchingResponseStats } = props;
    const [yesCount, setYesCount] = useState(0);
    const [noCount, setNoCount] = useState(0);

    useEffect(() => {
        if (!fetchingResponseStats && responsesStats) {
            setYesCount(countSurveyResponseValue(responsesStats, "yes", surveyQuestion.uuid));
            setNoCount(countSurveyResponseValue(responsesStats, "no", surveyQuestion.uuid));
        }
    }, [yesCount, noCount]);

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <OverviewCard>
                <div style={{ zIndex: 1, width: "100%", height: "100%", display: "flex" }}>
                    <Line options={options} data={data} redraw={true} responsive={true} aspectRatio={2} maintainAspectRatio={false} width={"100%"} />
                </div>
                <OverviewHalfColor />
            </OverviewCard>
            <OverviewCounterContainer>
                { fetchingResponseStats &&
                    <LottieLoadingIndicator/>
                }
                { !fetchingResponseStats &&
                    <>
                        <OverviewCounterItemContainer marginBottom="30px">
                            <OverviewCounterCircleIcon>
                                <Text margin="14px 0" color="#fff" >
                                    Yes
                                </Text>
                            </OverviewCounterCircleIcon>
                            <OverviewCounterDetails>
                                <Text margin="0" align="left" size="20px">
                                    { yesCount }
                                </Text>
                                <Text margin="0" align="left" size="16px">
                                    Answered &apos;Yes&apos;
                                </Text>
                            </OverviewCounterDetails>
                        </OverviewCounterItemContainer>
                        <OverviewCounterItemContainer>
                            <OverviewCounterCircleIcon>
                                <Text margin="14px 0" color="#fff" >
                                    No
                                </Text>
                            </OverviewCounterCircleIcon>
                            <OverviewCounterDetails>
                                <Text margin="0" align="left" size="20px">
                                    { noCount }
                                </Text>
                                <Text margin="0" align="left" size="16px">
                                    Answered &apos;No&apos;
                                </Text>
                            </OverviewCounterDetails>
                        </OverviewCounterItemContainer>
                    </>
                }
            </OverviewCounterContainer>
        </div>
    );
};

export default SurveyYesNoCard;