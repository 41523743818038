import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { FlexContainer, Button, Text } from "src/components";
import { LeadershipContext } from "./LeadershipContext";

const Container = styled.div`
    margin-right: 1.875rem;
`;

const TextContainer = styled.div`
    max-width: 32.875rem;
    height: auto;
    width: 100%;
`;

const Card = styled.div`
    border: 1px solid #707070;
    border-radius: 1.5625rem;
    background: #FFF;
    box-sizing: border-box;
    padding: 1.4375rem 1.4375rem 0 1.4375rem;
    width: 100%;
    max-width: 23rem;
    min-height: 13.125rem;
`;

const EditButton = styled.button`
    border: 0;
    color: #FFF;
    background: #000;
    height: 1.875rem;
    width: 4.8125rem;
    border-radius: 0.4375rem;
    cursor: pointer;
    font-size: 0.875rem;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3.125rem;
    grid-row-gap: 1rem;
    margin-top: 1.75rem;
`;

export const KEYS = {
    isVisibleToClients: "isVisibleToClients",
    isVisibleToEmployees: "isVisibleToEmployees"
};

const LeadershipRoles = (props) => {
    const { isDataFetchFinished, toggleLeadershipModal, leadershipRoles, selectedLeadershipRoles, assignLeadershipRoles } = useContext(LeadershipContext);
    const [ roles, setRoles ] = useState([]);


    const toggleCheckbox = (key, roleUuid) => {
        assignLeadershipRoles(key, roleUuid);
    };

    useEffect(() => {
        setRoles(leadershipRoles);
    }, [leadershipRoles]);

    const isChecked = (key, roleUuid) => {
        const role = selectedLeadershipRoles.find(role => role.uuid === roleUuid);

        return role !== undefined ? role[key] : false;
    };

    const renderLeadershipRoles = () => {
        return roles.map((role, index) => (
            <Card key={role.uuid}>
                <FlexContainer direction="row" justifyContent="space-between">
                    <Text weight="700" size="1.25rem" align="left" margin="0 0 0.9375rem 0">{ role.name }</Text>
                    <EditButton onClick={() => toggleLeadershipModal(true, false, role)}>Edit</EditButton>
                </FlexContainer>
                    
                <div className="check-field">
                    <input type="checkbox" className="permissions-checkbox"
                        style={{ marginLeft: "0" }}
                        checked={isChecked(KEYS.isVisibleToEmployees, role.uuid)}
                        onChange={() => toggleCheckbox(KEYS.isVisibleToEmployees, role.uuid)}
                        disabled={props.disabled}
                    />
                    <label>Employee Leader</label>
                    <div className="sub-text">
                        The user will have access to your list of employees to initiate chat, and can be reached by them.
                    </div>
                </div>
                <div className="check-field">
                    <input type="checkbox" className="permissions-checkbox"
                        style={{ marginLeft: "0" }}
                        checked={isChecked(KEYS.isVisibleToClients, role.uuid)}
                        onChange={() => toggleCheckbox(KEYS.isVisibleToClients, role.uuid)}
                        disabled={props.disabled}
                    />
                    <label>Client Leader</label>
                    <div className="sub-text">
                        The user will have access to your list of clients to initiate chat, and can be reached by them.
                    </div>
                </div>
            </Card>
        ));
    };

    return (
        <React.Fragment>
            <Container>
                <Text size="1.25rem" weight="700" color="#000000" margin="0" align="left">
                    Leadership Roles (App & Web)
                </Text>
                <FlexContainer direction="row" justifyContent="space-between" alignItems="start">
                    <TextContainer>
                        <Text color="#000" margin="0.6875rem 0 0 0" weight="400" size="0.875rem" align="left">
                            Once a user is granted permission, they will assume a leadership role in the chat feature on the app and web interface, which will be visible to other users. For instance, they may appear as a “Support Professional.” Other users can then seek their assistance or address concerns related to their designated role title.
                        </Text>
                    </TextContainer>
                    <Button color="#FFF" marginTop="0.6875rem"
                        backgroundColor="#006CFF"
                        borderRadius="0.4375rem !important"
                        height="auto" maxWidth="14rem"
                        onClick={() => toggleLeadershipModal(true, true)}
                    >
                        Create a New Leadership Role
                    </Button>
                </FlexContainer>
                
                {
                    !isDataFetchFinished ?
                        <Text color="gray" margin="3rem 0 0 0">Waiting for available leadership roles...</Text>
                        :
                        <>
                            { Array.isArray(roles) && roles.length > 0 ? 
                                <GridContainer>
                                    { renderLeadershipRoles() }
                                </GridContainer>
                                : 
                                <Text color="gray" margin="3rem 0 0 0">No leadership roles available.</Text>
                            }
                        </>
                }
                
            </Container>
        </React.Fragment>
    );
};

export default LeadershipRoles;