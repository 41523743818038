import React, { useContext } from "react";
import styled from "styled-components";
import {
    PageHeadingLabel, ExplainParagraphLabel, CardHeadingLabel,
    TwoColumnLayout, FlexContainer, Button, Text
} from "src/components";
import LeftText from "../components/LeftText";
import { SubscriptionContext } from "../SubscriptionContext";
import { yrOrMth, convertToCurrency } from "src/utils/currency";
import {
    SUBSCRIPTION_TYPE_MONTHLY, AUSTRALIA, SUBSCRIPTION_MONTHLY_DESCRIPTION, SUBSCRIPTION_YEARLY_DESCRIPTION, GST
} from "src/constants/subscriptions";

const StyledText = styled(Text)`
    text-align: left;
    margin: ${ p => p.margin || 0};
    font-size: 1rem;
`;

const Card = styled.div`
    background-color: #EDEDED;
    width: 95%;
    max-width: 26.375rem;
    height: 11.125rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 2.3125rem;
    box-sizing: border-box;
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
`;

const InlineText = styled.p`
    font-weight: ${p => p.weight || 500 };
    font-size: ${p => p.size || "1rem"};
`;

const StyledButton = styled(Button)`
    border-radius: 7px !important;
    width: ${ p => p.width };
    height: 2.3125rem;
    line-height: 100%;
    font-weight: 500;
    font-size: 15px;
    padding: 0 !important;
    margin: ${p => p.margin};
`;

const SubscriptionOverview = ({ totalNumber, goToPayment }) => {
    const context = useContext(SubscriptionContext);
    const { companyName, abn, country, subscriptionType, costing, backToPrevious, isPurchasingMoreUsers } = context;

    const subTotal = parseFloat(totalNumber * (costing.fullSubCostPerUserUsd - costing.gstIncluded));
    const gst = country === AUSTRALIA ? parseFloat(subTotal * GST) : 0;
    const pricePerUser = parseFloat(costing.fullSubCostPerUserUsd - costing.gstIncluded);
    const totalAmount = subTotal + gst;

    const proceedToPayment = e => {
        goToPayment();
    };
    
    return (
        <React.Fragment>
            <PageHeadingLabel>
                { !isPurchasingMoreUsers ? "Subscription Overview: Step 4" 
                    : "Purchase More User Licenses"
                }
            </PageHeadingLabel>
            <ExplainParagraphLabel maxWidth="40.5rem" width="100%" size="0.9375rem">
                Here is an overview of your business setup and user license selection. View your order summary and click proceed to payment to purchase your subscription.
            </ExplainParagraphLabel>
            <TwoColumnLayout marginTop="2rem" minHeight="43.625rem" height="auto"
                leftContainerWidth="100%" rightContainerWidth="100%"
                leftMaxWidth="35.0625rem" rightMaxWidth="28.5rem" 
                leftContainerPadding="2.8125rem 3.75rem" rightContainerPadding="2.8125rem 0 0 0"
            >
                <FlexContainer>
                    <CardHeadingLabel>Subscription Overview</CardHeadingLabel>
                    <StyledText weight="700" margin="2.1875rem 0 0 0">Business Name:</StyledText>
                    <StyledText margin="0 0 0.9375rem 0">{companyName}</StyledText>

                    { country === AUSTRALIA && 
                        <React.Fragment>
                            <StyledText weight="700">ABN:</StyledText>
                            <StyledText margin="0 0 0.9375rem 0">{abn}</StyledText>
                        </React.Fragment>
                    }

                    <StyledText weight="700">Country:</StyledText>
                    <StyledText margin="0 0 0.9375rem 0">{country}</StyledText>

                    <StyledText weight="700">User Licenses:</StyledText>
                    <StyledText margin="0 0 0.9375rem 0">{totalNumber}</StyledText>

                    <StyledText weight="700">Subscription Type:</StyledText>
                    <StyledText margin="0 0 0.9375rem 0" style={{ whiteSpace: "pre-line" }}>
                        {subscriptionType === SUBSCRIPTION_TYPE_MONTHLY 
                            ? SUBSCRIPTION_MONTHLY_DESCRIPTION
                            : SUBSCRIPTION_YEARLY_DESCRIPTION
                        }
                    </StyledText>
                </FlexContainer>

                <FlexContainer>
                    <CardHeadingLabel margin="0 0 0 3.1875rem">Order Summary</CardHeadingLabel>
                    <TextContainer style={{ padding: "0 3.5rem 0 3.1875rem" }}>
                        <LeftText>
                            <InlineText>Subscription Duration</InlineText>
                            <InlineText>Number of User Licenses</InlineText>
                            <InlineText>Price Per User License</InlineText>
                        </LeftText>
                        <LeftText align="right">
                            <InlineText weight="700">12 Months</InlineText>
                            <InlineText weight="700">{totalNumber}</InlineText>
                            <InlineText weight="700">
                                {convertToCurrency(pricePerUser)}/per {subscriptionType}
                            </InlineText>
                        </LeftText>
                    </TextContainer>

                    <Card>
                        <LeftText margin="0">
                            <InlineText>Subtotal</InlineText>
                            { country === AUSTRALIA && <InlineText>GST</InlineText> }
                            <InlineText weight="700" size="1.25rem">Total</InlineText>
                        </LeftText>
                        <LeftText margin="0" align="right">
                            <InlineText>
                                {convertToCurrency(subTotal)}/{yrOrMth(subscriptionType)}
                            </InlineText>
                            { country === AUSTRALIA &&
                                <InlineText>
                                    {convertToCurrency(gst)}/{yrOrMth(subscriptionType)}
                                </InlineText>
                            }
                            
                            <InlineText weight="700" size="1.25rem">
                                USD {convertToCurrency(totalAmount)}/{yrOrMth(subscriptionType)}
                            </InlineText>
                        </LeftText>
                    </Card>

                    <FlexContainer direction="row" mDirection="row" tDirection="row" marginTop="11.25rem" justifyContent="flex-end">
                        <StyledButton margin="0 0.875rem 1rem 0" width="8.75rem" border="1px solid #000000" color="#000000"
                            onClick={() => backToPrevious()}
                        >
                            Back
                        </StyledButton>
                        <StyledButton margin="0 3.25rem 1rem 0" width="11.125rem" color="#ffffff" backgroundColor="#000000"
                            onClick={(e) => proceedToPayment(e)}
                        >
                            Proceed to Payment
                        </StyledButton>
                    </FlexContainer>
                </FlexContainer>
            </TwoColumnLayout>
        </React.Fragment>
    );
};

export default SubscriptionOverview;