import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import FlexContainer from "./FlexContainer";

/**
 * Background component for non-home, logged-out routes - /signUp, /login, /forgotPassword, /resetPassword.
 * Gradient background using DFM evening theme.
 */
class SignUpContainer extends Component {
    render() {
        const { children } = this.props;

        return (
            <FlexContainer alignItems="center" minHeight="100vh" style={{ backgroundColor: "#404df3" }}>
                <Header />
                {children}
                <Footer />
            </FlexContainer>
        );
    }
}

export default SignUpContainer;