import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import {
    FlexContainer, CardList, LoadingIndicator,
    Toast, TwoColumnLayout, CardHeadingLabel, ExplainParagraphLabel, PageHeadingLabel
} from "src/components";

import Price from "../../Subscription/components/Price";
import "@trendmicro/react-radio/dist/react-radio.css";
import { SubscriptionRenewalContext } from "../SubscriptionRenewalContext";
import { SubscriptionContext } from "src/scenes/Subscription/SubscriptionContext";

import { RenewalForms } from "../../SubscriptionRenewal/SubscriptionRenewal";
import AirwallexForm, { AirwallexProduct } from "src/scenes/Subscription/components/AirwallexForm";
import GreenCheck from "src/img/new/green-check.svg";
import CircleIcon from "src/img/new/circle-regular-24.png";
import { withRouter } from "react-router-dom";

import { PaymentSelectionButton, PaymentSelectionIcon, Methods } from "src/scenes/Subscription/forms/Review";

class Renewal extends Component {
    static contextType = SubscriptionRenewalContext;
    
    state = {
        loading: false,
        method: Methods.EXISTING
    };

    handleBackButton = () => {
        this.props.history.push("/setting/subscriptions");
    };

    /**
     *  Returns a Promise that is being used to `await` in CardList.js thisPurchaseClicked()
    */
    purchaseRenewal = (selectedMethod, methodSource) => {
        if (methodSource === "square") {
            return alert("Unable to process Square payment as of the moment");
        } else if (methodSource === "airwallex") {
            return this.purchaseWithOurApi(
                `${API_URL}/company/airwallex/purchaseNow/renewal`,
                {
                    paymentMethodId: selectedMethod
                });
        } else {
            console.log(`Bad method source: ${methodSource}`);
        }
    };

    purchaseWithOurApi = async (url, requestBody) => {
        const { token, nextClick } = this.context;
        this.setState({
            loading: true
        });
        axios.post(url, requestBody, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            this.setState({
                loading: false
            });
            
            nextClick();
        }).catch(error => {
            this.setState({
                loading: false
            });
            if (error.response) {
                if (error.response.data.error.messages) {
                    return Toast.error(error.response.data.error.messages.toString());
                }
                return Toast.error(error.response.data.error.toString());
            } else {
                return Toast.error(error.message);
            }
        });
    };

    updateMethod = (value) => {
        this.setState({
            method: value
        });
    };

    render() {
        const { token, email, nextClick, 
            totalNumber, companyName, abn, moreUsers, subscriptionEndDate, currentForm
        } = this.context;

        const { method, loading } = this.state;

        if (loading) {
            return <LoadingIndicator />;
        }

        const updatePaymentAndPurchase = () => {
            nextClick();
        };

        return (
            <React.Fragment>
                <PageHeadingLabel>
                    Reactivate Subscription
                </PageHeadingLabel>
                <ExplainParagraphLabel maxWidth="43.125rem" width="100%" size="0.9375rem">
                    Your business subscription has expired, and to continue enjoying our services, you’ll need to reactivate this subscription. Please update or enter a new payment method and select ‘Pay’ to complete the process. Your chosen payment method will be used for future subscription renewals.
                </ExplainParagraphLabel>
                <TwoColumnLayout marginTop="2rem" height="auto" minHeight="43.625rem"
                    leftMaxWidth="28.4375rem" rightMaxWidth="29.75rem"
                    leftContainerWidth="100%" rightContainerWidth="100%" 
                    leftContainerPadding="2.8125rem 1.06rem"
                    rightContainerPadding="2.8125rem 1.6875rem 1rem 2.125rem"
                >
                    <SubscriptionContext.Provider value={{
                        country: this.context.country,
                        subscriptionType: this.context.subscriptionType,
                        costing: this.context.costing
                    }} >
                        <Price
                            Forms={RenewalForms}
                            currentForm={currentForm}
                            companyName={companyName}
                            totalNumber={totalNumber}
                            subscriptionEndDate={subscriptionEndDate}
                            goToOverview={ this.handleBackButton }
                        />
                    </SubscriptionContext.Provider>
                    <FlexContainer justifyContent="start" height="auto">
                        <CardHeadingLabel>
                            Payment Method
                        </CardHeadingLabel>
                        
                        <FlexContainer direction="column" margin="1rem 0 0">
                            <FlexContainer direction="row" position="relative">
                                <PaymentSelectionButton 
                                    maxWidth="16rem" width="100%"
                                    background={method === Methods.EXISTING ? "#000" : "#FFF"}
                                    margin="0 0.375rem 1rem 0"
                                    color={method === Methods.EXISTING ? "#FFF" : "#000"}
                                    onClick={() => this.updateMethod(Methods.EXISTING)}
                                >

                                    <PaymentSelectionIcon 
                                        src={method === Methods.EXISTING ? GreenCheck : CircleIcon}
                                    />
                                    
                                    Use an existing credit/debit card
                                </PaymentSelectionButton>
                                <PaymentSelectionButton
                                    width="100%"
                                    maxWidth="9.6rem"
                                    margin="0 0 1.625rem 0"
                                    background={method === Methods.NEW ? "#000" : "#FFF"}
                                    color={method === Methods.NEW ? "#FFF" : "#000"}
                                    onClick={() => this.updateMethod(Methods.NEW)}
                                >
                                    <PaymentSelectionIcon 
                                        src={method === Methods.NEW ? GreenCheck : CircleIcon}
                                    />
                                    Add a new card
                                </PaymentSelectionButton>
                            </FlexContainer>
                            { this.state.method === Methods.NEW ?
                                <FlexContainer align="start" direction="column" flexGrow="1">
                                    <AirwallexForm
                                        token={token} email={email}
                                        productType={AirwallexProduct.RENEWAL}
                                        companyName={companyName} 
                                        companyAbn={abn} 
                                        numberOfUsers={moreUsers}
                                        backToPrevious={this.handleBackButton}
                                        nextClick={updatePaymentAndPurchase} />
                                </FlexContainer>
                                :
                                <CardList
                                    token={token}
                                    onBackClicked={this.handleBackButton}
                                    onPurchaseClicked={this.purchaseRenewal}
                                    onDeleteClicked={null}
                                />
                            }
                        </FlexContainer>
                       
                    </FlexContainer>
                </TwoColumnLayout>
            </React.Fragment>
        );
    }
}

export default withRouter(Renewal);