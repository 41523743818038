/**
 * to add commas to price
 * @param {float} num 
 * @returns string
 */
export const convertToCurrency = (num) => {
    const roundedNum = num.toFixed(2);
    return parseFloat(roundedNum).toLocaleString("en-US", { style: "currency", currency: "USD" });
};

/**
 * 
 * @param {string} subscriptionType 
 * @returns string
 */
export const yrOrMth = (subscriptionType) => subscriptionType === "month" ? "mo" : "yr";