
export const USER_TYPE_EMPLOYEES = "employees";
export const USER_TYPE_CLIENTS = "clients";

export const GROUP_CHAT = "group";
export const ONE_ON_ONE_CHAT = "single";

export const FORM_TYPE_CREATE = "create";
export const FORM_TYPE_EDIT = "edit";

/** for chat timestamp (Mon to Sun will be added here soon!) */
export const DAYSTRINGS = {
    TODAY: "Today",
    YESTERDAY: "Yesterday"
};

export const ADMIN_MATRIX_ID = "@admin:memotivationapp.com";