import React from "react";
import styled from "styled-components";

const BaseLabel = styled.div`
    font-weight: ${ p => p.weight || "normal" };
    font-size: ${ p => p.size || "1em" };
    color: ${ p => p.color || "black" };
    text-align: ${ p => p.align || "start" };
    padding: ${ p => p.padding || "0" };
    margin: ${ p => p.margin || "0" };
    width: ${ p => p.width };
    height: ${ p => p.height };
    flex-grow: ${ p => p.flexGrow };
    max-width: ${ p => p.maxWidth };
`;

/**
 * Base text label <div>, configurable with text size, weight and color, plus margin, padding, size, etc.
 * @return {JSX.Element}
 */
export function TextLabel(props) {
    const { children, ...styleProps } = props;
    return (
        <BaseLabel {...styleProps}>
            {children}
        </BaseLabel>
    );
}

/**
 * Text label configured for use as a page heading
 * @return {JSX.Element}
 */
export function PageHeadingLabel(props) {
    const { children } = props;
    return (
        <BaseLabel weight="700" size="2.5em" color="#11141A" margin="2rem 0 0.5rem">
            { children }
        </BaseLabel>
    );
}

/**
 * Text label configured for use as an explainer paragraph
 * @return {JSX.Element}
 */
export function ExplainParagraphLabel(props) {
    const { children, ...styleProps } = props;

    return (
        <BaseLabel weight="400" size="1em" color="#808080" margin="0.25em 0 0.5em" {...styleProps} >
            { children }
        </BaseLabel>
    );
}

/**
 * Text label configured for use as an explainer paragraph
 * @return {JSX.Element}
 */
export function CardHeadingLabel(props) {
    const { children } = props;
    return (
        <BaseLabel weight="700" size="1.5em" color="#612684" margin={props.margin || "0 0 0.5em"}>
            { children }
        </BaseLabel>
    );
}
