import axios from "axios";
import { API_URL, ENVIRONMENT } from "src/scenes/App";
import { getCompanySubscriptionEnd, findDiffInUnitsRoundingUp } from "src/utils/dates";

export default class SubscriptionCostResponse {
    constructor(data) {
        this.costPerUserUsdCents = data.costPerUserUsdCents;
        this.costPerUserUsd = data.costPerUserUsd;
        this.fullSubCostPerUserUsd = data.fullSubCostPerUserUsd;
        this.gstIncluded = data.gstIncluded;
        this.chargePeriodNumber = data.chargePeriodNumber;
        this.chargePeriodUnit = data.chargePeriodUnit;
    }

    static default() {
        return new SubscriptionCostResponse({
            costPerUserUsdCents: 100,
            costPerUserUsd: 1.0,
            fullSubCostPerUserUsd: 1.1,
            gstIncluded: 0.1,
            chargePeriodNumber: 12,
            chargePeriodUnit: "month"
        });
    }

    static async get(token) {
        const response = await axios.get(`${API_URL}/company/airwallex/subscriptionCost`, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        return new SubscriptionCostResponse(response.data);
    }

    /**
     * Mimic calculations that happen in the API in AirwallexController::startInitialSubscription.
     * Performing calculations using whole numbers of cents ensures rounding errors are minimal and the same on
     * both ends.
     * @param {number} numberOfUsers
     * @return {{total: number, subtotal: number, gst: number}}
     */
    getPriceStructureForNewUsers(numberOfUsers) {
        const subtotalCents = this.costPerUserUsdCents * this.chargePeriodNumber * numberOfUsers;
        const gstCents = Number(this.gstIncluded) > 0 ? Math.floor(subtotalCents / 10) : 0;
        const totalCents = subtotalCents + gstCents;
        console.log("Subtotal: " + subtotalCents + ", gst: " + gstCents + ", total: " + totalCents);
        return {
            subtotal: (subtotalCents) / 100,
            gst: (gstCents) / 100,
            total: (totalCents) / 100
        };
    }

    /**
     * Mimic calculations that happen in the API in AirwallexController::startAdditionalSubscription.
     * Performing calculations using whole numbers of cents ensures rounding errors are minimal and the same on
     * both ends.
     * @param {number} numberOfUsers
     * @param {Date} subscriptionStart
     * @param {number} subscriptionDuration
     * @return {{total: number, subtotal: number, gst: number}}
     */
    getPriceStructureForAdditionalUsers(numberOfUsers, subscriptionStart, subscriptionDuration) {
        const subscriptionEnd = getCompanySubscriptionEnd(subscriptionStart, subscriptionDuration, false);
        const calculationUnit = ENVIRONMENT.get() === ENVIRONMENT.PRODUCTION ? "day" : "hour";
        const remainingUnits = findDiffInUnitsRoundingUp(new Date(), subscriptionEnd, calculationUnit);
        const totalUnits = findDiffInUnitsRoundingUp(subscriptionStart, subscriptionEnd, calculationUnit);
        const proportionRemaining = totalUnits > 0 ? remainingUnits / totalUnits : 0;

        const subtotalCents = Math.floor(this.costPerUserUsdCents * numberOfUsers * subscriptionDuration * proportionRemaining);
        const gstCents = Number(this.gstIncluded) > 0 ? Math.floor(subtotalCents * 0.1) : 0;
        const totalCents = subtotalCents + gstCents;

        return {
            subtotal: (subtotalCents) / 100,
            gst: (gstCents) / 100,
            total: (totalCents) / 100
        };
    }
}
