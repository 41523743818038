import React, { useContext, useEffect, useState } from "react";
import { FlexContainer, Text, Toast, LoadingIndicator, ErrorLabel } from "src/components";

import SendIcon from "src/img/new/send.svg";
import { ChatContext } from "../ChatContext";

import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, isChatActionAllowed } from "src/constants/permissions";
import ViewGroupChatModal from "./ViewGroupChatModal";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import ChatRoomTimeline from "./children/ChatRoomTimeline";

import { useAbortController, useSendMessage, useFetchRoomTimeline } from "../hooks";
import {
    Container, ChatBox, Header, MessagesContainer, ChatInputContainer,
    ChatTextBox, ChatInput, SendIconContainer, EditChatButton,
    Dropdown, Dot
} from "./styled/chatMessagesStyled";
import { IsTypingBox } from "./children/chatmessages/IsTypingBox";
import { EVENT_ID_TO_REPLACE } from "src/constants/matrixEventTypes";
import NoActiveChats from "./children/chatmessages/NoActiveChats";
import { MatrixError } from "matrix-js-sdk";
import TimelinePagination from "./children/chatmessages/TimelinePagination";
import DropdownActionsBox from "./DropdownActionsBox";

export const INITIAL_EVENTS_LIMIT = 20;
const PAGINATION_LIMIT = 50;

const ChatMessages = (props) => {
    const userContext = useContext(UserContext);
    const { currentChat,
        currentUserEmployeeUuid,
        showCreateChatModalOrShowLeadershipEmployees,
        selectedChatListTypeOrLeadershipUuid,
        matrixClient,
        newTimelineEvent,
        showLoadingState,
        leadershipRoles,
    } = useContext(ChatContext);
    const [showViewGroupChatModal, setShowViewGroupChatModal] = useState(false);
    const [showCreateChatButtons, setShowCreateChatButtons] = useState(false);
    const [messageData] = useState(null);
    const headers = { headers: { Authorization: "Bearer " + userContext.token } };
    const { signal, abort } = useAbortController();
    const { prepareMessage, newMessage, setNewMessage } = useSendMessage();
    const { roomTimeline, 
        isFetchingRoomTimeline, 
        didTimelineFetchFail,
        fetchRoomTimeline,
        roomTimelineEnd,
        updateTimeline,
        isFetchingPaginatedEvents,
        fetchPaginatedEvents,
    } = useFetchRoomTimeline(matrixClient);
    const messageChecker = newMessage === "" || (!newMessage.replace(/\s/g, "").length) === true;

    useEffect(() => {
        if (matrixClient && currentChat) {
            matrixClient.downloadKeys(currentChat.users.map((i) => { return i.matrixUserId;}), true, null);
            fetchRoomTimeline(
                currentChat.matrixRoomId, 
                INITIAL_EVENTS_LIMIT, 
                headers, 
                signal
            );
        }

        return abort();
    }, [currentChat]);

    useEffect(() => {
        const processReceivedEvent = () => {
            let timelineArr = [...roomTimeline];
            if (newTimelineEvent[EVENT_ID_TO_REPLACE]) {
                const index = timelineArr.findIndex(event => event.event_id === newTimelineEvent[EVENT_ID_TO_REPLACE]);
                if (index !== -1) {
                    timelineArr.splice(index, 1);
                }
            }

            updateTimeline(timelineArr, newTimelineEvent);
        };
        if (newTimelineEvent) {
            processReceivedEvent();
        }
    }, [newTimelineEvent]);

    useEffect(() => {
        setShowCreateChatButtons(showCreateChatButton(selectedChatListTypeOrLeadershipUuid));
    }, [selectedChatListTypeOrLeadershipUuid]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && (!messageChecker)) {
            prepareMessage();
        }
    };

    const handleClick = () => {
        props.showEditGroupChatModal();
    };

    const renderChatType = () => {
        if (currentChat?.leadershipRoleUuid) {
            const lr = leadershipRoles.find((leadershipLabel) => leadershipLabel.uuid === currentChat.leadershipRoleUuid)?.name ?? "";
            return lr;
        }

        return currentChat.isClient ? currentChat.isGroupChat ? "Clients" : "Client"
            : currentChat.isGroupChat ? "Employees" : "Employee";
    };

    /**
     * 
     * @param {string | undefined} selectedChatListTypeOrLeadershipUuid 
     * @returns boolean
     */
    const showCreateChatButton = (selectedChatListTypeOrLeadershipUuid) => {
        const userPermissions = userContext[UserKeys.PERMISSIONS];
        const userLeadershipRoles = userContext[UserKeys.LEADERSHIP_ROLES];

        if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES) {
            return isChatActionAllowed(MESSAGE_EMPLOYEES, userPermissions);
        } else if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS) {
            return isChatActionAllowed(MESSAGE_CLIENTS, userPermissions);
        } else {
            //leadershipRole

            // Check if selectedChatListTypeOrLeadershipUuid exists in userLeadershipRoles
            return userLeadershipRoles.some(userLeadershipRole =>
                selectedChatListTypeOrLeadershipUuid === userLeadershipRole.uuid
            );
        }
    };

    const handleSendTypingEvent = async (value) => {
        try {
            await matrixClient.sendTyping(currentChat.matrixRoomId, value, 100);
        } catch (error) {
            const matrixError = new MatrixError(error);
            /**
             * this error means logged in user is sending a `typing` event to a room he/she is not a member of;
             * - a deleted chat, or one that they have been removed
             * ChatList should prevent this from happening see `matrixRoomIdToRemove` effect
             * in case that it doesnt, this will make the error somehow friendly :)
             */
            if (matrixError.errcode === "M_FORBIDDEN") {
                Toast.error("You can no longer send messages in this room. Page will reload.");
                setTimeout(() => window.location.reload(), 1500);
            }
        }
    };

    const handleGetOlderMessages = async () => {
        fetchPaginatedEvents(roomTimeline, currentChat.matrixRoomId, PAGINATION_LIMIT, headers, roomTimelineEnd, signal);
    };

    return (
        <React.Fragment>
            <Container >
                <ChatBox>
                    <Header> 
                        { currentChat &&
                            <React.Fragment>
                                <Text color="#4B5155" weight="700" size="1.125rem">{ currentChat.name }</Text>
                                { currentChat.isGroupChat ?
                                    <React.Fragment>
                                        { currentChat.ownerUuid === currentUserEmployeeUuid ?
                                            <EditChatButton onClick={handleClick} enabled={true}>
                                                Edit Group
                                            </EditChatButton>
                                            :
                                            <EditChatButton onClick={() => setShowViewGroupChatModal(true)} enabled={true}>
                                                View Group
                                            </EditChatButton>
                                        }
                                    </React.Fragment>
                                    
                                    :   
                                    <div className="flex-centered-content">
                                        <Text color="#000000" weight="700" size="1.125rem">
                                            { renderChatType() }
                                        </Text>
                                        <Dropdown className="flex-centered-content"
                                            onClick={() => props.setToggleDropdown(!props.toggleDropdown)}
                                        >
                                            <Dot />
                                            <Dot />
                                            <Dot />
                                            
                                        </Dropdown>
                                    </div>
                                }
                            </React.Fragment>
                        }
                        
                        { props.toggleDropdown &&
                            <DropdownActionsBox
                                showUserProfile={props.showUserProfile}
                                deleteChat={props.handleChatDeletion}
                                setToggleDropdown={props.setToggleDropdown}
                            />
                        }
                    </Header>

                    { isFetchingRoomTimeline && 
                    <div style={{ height: "100%" }}>
                        <LoadingIndicator /> 
                    </div>
                    }

                    { (!isFetchingRoomTimeline && didTimelineFetchFail) &&
                        <ErrorLabel align="center" margin="3rem 0 0 0">
                            Unable to retrieve messages.
                        </ErrorLabel>
                    }
                    
                    { (!isFetchingRoomTimeline && !currentChat && !didTimelineFetchFail) ? 
                        <FlexContainer height="100%" justifyContent="center" alignItems="center">
                            { !isChatActionAllowed(MESSAGE_CLIENTS, userContext.permissions) 
                                && !isChatActionAllowed(MESSAGE_EMPLOYEES, userContext.permissions) ?
                                "" :
                                !props.hasChats && !showLoadingState ? 
                                    <NoActiveChats 
                                        showCreateChatButton={showCreateChatButtons}
                                        permissions={userContext.permissions}
                                        showCreateChatModalOrShowLeadershipEmployees={showCreateChatModalOrShowLeadershipEmployees }
                                    />
                                    :
                                    <Text color="#8c8c8c">Select a chat to see its messages.</Text>
                            }
                            
                        </FlexContainer>
                        :
                        <React.Fragment>
                            { !isFetchingRoomTimeline &&
                                <MessagesContainer>
                                    <IsTypingBox />
                                    <ChatRoomTimeline roomTimeline={roomTimeline} />
                                    <TimelinePagination
                                        roomTimelineEnd={roomTimelineEnd}
                                        isFetchingPaginatedEvents={isFetchingPaginatedEvents}
                                        isFetchingRoomTimeline={isFetchingRoomTimeline}
                                        timelineLength={roomTimeline ? roomTimeline.length : 0}
                                        handleGetOlderMessages={handleGetOlderMessages}
                                    />
                                </MessagesContainer>
                            }
                            <ChatInputContainer>
                                <ChatTextBox onKeyDown={(e) => handleKeyPress(e)}>
                                    <ChatInput placeholder="Enter your message here"
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        value={newMessage}
                                        onFocus={() => handleSendTypingEvent(true)}
                                        onBlur={() => handleSendTypingEvent(false)}
                                    />
                                    
                                    <SendIconContainer onClick={() => prepareMessage()}
                                        disabled={messageChecker}
                                        className={messageData !== null && messageData.isSending ? "disabled-element" : ""}
                                    >
                                        <img src={SendIcon} alt="Send" style={{ transform: "rotate(-90deg)" }}/>
                                    </SendIconContainer>
                                </ChatTextBox>
                            </ChatInputContainer>
                        </React.Fragment>
                    }
                </ChatBox>
                { showViewGroupChatModal &&
                    <ViewGroupChatModal 
                        showDialog={showViewGroupChatModal}
                        handleClose={() => setShowViewGroupChatModal(false)}
                    />
                }
            </Container>
        </React.Fragment>
    );
};

export default ChatMessages;