import React from "react";
import styled from "styled-components";

const Circle = styled.div`
    height: 1.25rem;
    width: 1.25rem;
    background: #006CFF;
    color: #FFF;
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const UnreadCount = (props) => {
    const { unreadCount } = props;

    return (
        <Circle>{ unreadCount }</Circle>
    );
};

export default UnreadCount;