import React from "react";
import { Button } from "@material-ui/core/";
import { INITIAL_EVENTS_LIMIT } from "../../ChatMessages";
import { LoadingIndicator } from "src/components";

const TimelinePagination = (props) => {
    const {
        roomTimelineEnd,
        isFetchingPaginatedEvents,
        isFetchingRoomTimeline,
        timelineLength,
        handleGetOlderMessages
    } = props;

    if (timelineLength < INITIAL_EVENTS_LIMIT || roomTimelineEnd === undefined) {
        return "";
    }

    if (!isFetchingPaginatedEvents) {
        return (
            <Button onClick={() => handleGetOlderMessages()}>
                Load Older Messages
            </Button>
        );
    }

    if (isFetchingPaginatedEvents && !isFetchingRoomTimeline) {
        return (
            <LoadingIndicator height="1rem" width="1rem" containerHeight="2rem" />
        );
    }
};

export default TimelinePagination;
