import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { Button, FlexContainer, LottieAnimation } from "src/components";
import Checkbox from "src/scenes/EmployeeList/components/Checkbox";
import NoticeboardContent from "./NoticeboardContent";
import PurpleLottieJson from "./../assets/purple-lottie.json";
import { FILTER_BY } from "./../Noticeboard";

const UpperControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 94.5rem;
    background: #fff;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
    padding: 1em;
`;

const UpperButtons = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 2.4375rem;
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 700;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
`;

const DescriptionLabel = styled.div`
    color: #000000;
    text-align: center;
    font-size: 1.25rem;
`;

const GridItem = styled.div`
    margin-right: 1em;
`;

const InputSelector = styled.select`
    height: 2.4375rem !important;
    width: 12.5rem !important;
    font-size: 14px !important;
    border-radius: 7px;
    padding-left: 1em;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='45' viewBox='0 0 24 24' width='35' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;
    margin-right: 2em;
`;

const NoticeboardTable = ({
    usersArray,
    handleShowDeleteDialog,
    checkedNotices,
    handleCheckboxChange,
    handleCheckAllNotices,
    checkAllNotices,
    isFetching,
    handleEditNotice,
    handleNewNotice,
    filterByOption,
    handleFilterByChange,
    handleShowNoticeViewershipModal
}) => {
    return (
        <>
            <UpperControls>
                <Grid item xs={7}>
                    <Grid container style={{ paddingLeft: "30px" }} direction="row" justifyContent="flex-start" alignItems="center">
                        { usersArray.length > 0 && !isFetching && 
                            <GridItem>
                                <Checkbox
                                    key="selectAll"
                                    readOnly={false}
                                    checked={checkAllNotices}
                                    onClick={ () => handleCheckAllNotices(usersArray) }
                                />
                            </GridItem>
                        }      

                        <InputSelector value={filterByOption} onChange={handleFilterByChange}>
                            <option value={FILTER_BY.ALL_NOTICES}>All Posts</option>
                            <option value={FILTER_BY.ACTIVE_POSTS}>Active Posts</option>
                            <option value={FILTER_BY.SCHEDULED_POSTS}>Scheduled Posts</option>
                        </InputSelector>

                        { usersArray.length > 0 && !isFetching && 
                            <>
                                { checkedNotices.length > 0 &&
                                    <UpperButtons backgroundColor="#ff7171" padding="0.625rem 0.938rem 0.625rem" onClick={() => handleShowDeleteDialog()}>Delete Selected Posts</UpperButtons>
                                }
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        {!isFetching && <UpperButtons style={{ marginRight: "1rem", width: "12.5rem", display: "block" }} onClick={() => handleNewNotice()}>New Post</UpperButtons>}
                    </Grid>
                </Grid>
            </UpperControls>

            <div className="scrollable-table" style={{ maxWidth: "94.5rem", padding: "1.5em", border: "1px solid #8291B2", backgroundColor: "#FFF" }}>
                { 
                    isFetching && 
                    <FlexContainer 
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: "25rem" }}>
                        <PurpleLoadingIndicator/>
                    </FlexContainer>
                }

                { 
                    !isFetching && 
                    <FlexContainer direction="column" justifyContent="flex-start" style={{ minWidth: "1000px" }}>
                        { usersArray.length === 0 && <NoPostAvailable/> }
                        { usersArray.length !== 0 && 
                            <NoticeboardContent 
                                noticeArray={usersArray} 
                                checkedNotices={checkedNotices}
                                handleCheckboxChange={handleCheckboxChange}
                                handleEditNotice={handleEditNotice}
                                fetching={null} 
                                handleShowNoticeViewershipModal={handleShowNoticeViewershipModal}
                            /> 
                        }                                  
                    </FlexContainer>
                }
            </div>
        </>
    );
};

function NoPostAvailable() {
    return (
        <FlexContainer 
            direction="column"
            justifyContent="center"
            alignItems="center"
            marginTop="10em"
            marginBottom="10em"
        >
            <DescriptionLabel>You have no posts available on your business Noticeboard.</DescriptionLabel>
            <DescriptionLabel>Send your first post to your Employees or Clients by selecting the ‘New Post’ button.</DescriptionLabel>
        </FlexContainer>
    );
}

function PurpleLoadingIndicator() {
    return (
        <FlexContainer>
            <LottieAnimation animationJsonData={PurpleLottieJson} />
        </FlexContainer>
    );
}
export default NoticeboardTable;
