import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import countryList from "react-select-country-list";

import {
    Text, FormField, Button, CountrySelector, CountryPhoneInput
} from "src/components";

import { ErrorText } from "src/components/FormField";

// assets
import ChevronDown from "src/img/new/chevron-down.png";

export const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: auto;
    column-gap: 2%;
`;

export const ErrorList = {
    UNKNOWN: 0,
    EMPTY_BUSINESS_NAME: 1,
    EMPTY_ABN: 2,
    EMPTY_FIRST_NAME: 3,
    EMPTY_LAST_NAME: 4,
    EMPTY_COUNTRY: 5,
    MOBILE_FORMAT: 6,
    BUSINESS_NAME_INVALID: 7,
    ABN_INVALID: 8,
    FIRST_NAME_INVALID: 9,
    LAST_NAME_INVALID: 10,
    DATE_OF_BIRTH_INVALID: 11, 
    MOBILE_INVALID: 12, 
    WEBSITE_INVALID: 13, 
    COUNTRY_INVALID: 14, 
};

export let ErrorStrings = {
    
    [ErrorList.UNKNOWN]: "* Error unknown. Please contact support@memotivationapp.com",
    [ErrorList.EMPTY_BUSINESS_NAME]: "* Please enter a business name",
    [ErrorList.EMPTY_ABN]: "* Please enter an ABN",
    [ErrorList.EMPTY_FIRST_NAME]: "* Please enter a first name",
    [ErrorList.EMPTY_LAST_NAME]: "* Please enter a last name",
    [ErrorList.EMPTY_COUNTRY]: "* Please select a country",
    [ErrorList.MOBILE_FORMAT]: "* Please enter a valid mobile number. Is the country code correct for the mobile number you're trying to use?",
    [ErrorList.BUSINESS_NAME_INVALID]: "",
    [ErrorList.ABN_INVALID]: "",
    [ErrorList.FIRST_NAME_INVALID]: "",
    [ErrorList.LAST_NAME_INVALID]: "",
    [ErrorList.DATE_OF_BIRTH_INVALID]: "",
    [ErrorList.MOBILE_INVALID]: "",
    [ErrorList.WEBSITE_INVALID]: "",
    [ErrorList.COUNTRY_INVALID]: "",
};

export const EditButton = styled(Button)`
    background: #000000;
    color: #ffffff;
    border-radius: 10px !important;
    width: 135px;
    padding: 10px 30px 10px !important;
    margin: 5px 0;
`;

export const SaveButton = styled(Button)`
    background: #fff;
    color: #000;
    border: 2px solid #000;
    border-radius: 10px !important;
    width: 135px;
    padding: 10px 30px 10px !important;
    margin: 5px 0;
`;

const StyledCountryPhoneInput = styled(CountryPhoneInput)`
    
    div.flag-dropdown, input.form-control {
        height: 50px !important;
        border: 1px solid #E9EBEF;
        background-color: #F0F0F0 !important;
        background-color: ${p => p.disabled ? "#F0F0F0 !important" : "#FFF !important"};
    }

    input.form-control{
        width: 82% !important;
        margin-left: 75px !important;
    }

    div.flag-dropdown {
        width: 4rem;
        padding-left: 10px;
    }
`;
class BusinessName extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_BUSINESS_NAME, ErrorList.BUSINESS_NAME_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Business Name
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled ? "Company Name Here" : ""}
                        onChange={(event) => {onChangeFormData(event, "companyName");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.companyName || ""}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class Abn extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_ABN, ErrorList.ABN_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    ABN (Optional)
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled ? "Insert ABN Here" : ""}
                        onChange={(event) => {onChangeFormData(event, "abn");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.abn}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class Website extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const invalidFieldError = getErrorStrings(ErrorList.WEBSITE_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Website (Optional)
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled ? "Insert Website Here" : ""}
                        onChange={(event) => {onChangeFormData(event, "website");} }
                        errors={invalidFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.website}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class FirstName extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_FIRST_NAME, ErrorList.FIRST_NAME_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    First Name
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled ? "First Name Here" : ""}
                        onChange={(event) => {onChangeFormData(event, "firstName");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.firstName}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class LastName extends Component {
    render() {
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const emptyFieldError = getErrorStrings(ErrorList.EMPTY_LAST_NAME, ErrorList.LAST_NAME_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Last Name
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type="text"
                        placeholder={!placeholderDisabled ? "Last Name Here" : ""}
                        onChange={(event) => {onChangeFormData(event, "lastName");} }
                        errors={emptyFieldError}
                        showErrors={true}
                        maxLength="100"
                        value={formData.lastName}
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class DateOfBirth extends Component {
    state = {
        birthDateSetInputTypeAs: "text",
    };

    birthDateChangeInputTypeToDate = (event) => {
        this.setState({
            birthDateSetInputTypeAs: "date" 
        });
    };

    render() {
        const { birthDateSetInputTypeAs } = this.state;
        const { formData, onChangeFormData, inputErrors, placeholderDisabled, isEditable } = this.props;
        
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const invalidFieldError = getErrorStrings(ErrorList.EMPTY_DATE_OF_BIRTH, ErrorList.DATE_OF_BIRTH_INVALID);

        const birthDatePreview = (formData.birthDate !== undefined && formData.birthDate !== "") ? moment(formData.birthDate).format("DD-MM-YYYY") : "";
        const birthDateFormatted = (formData.birthDate !== undefined && formData.birthDate !== "") ? moment(formData.birthDate).format("YYYY-MM-DD") : "";
        
        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Date of Birth (Optional)
                    </Text>
                    <FormField borderRadius="12px" size="14px" height="50px"
                        placeholderColor="#808080" border="1px solid #E9EBEF"
                        color="000"
                        backgroundColor={!isEditable && "#F0F0F0"}
                        align="left"
                        width="100%"
                        type={ (isEditable || formData.birthDate === undefined) ? "date" : birthDateSetInputTypeAs }
                        placeholder={!placeholderDisabled ? "DD-MM-YYYY" : ""}
                        onChange={(event) => {onChangeFormData(event, "birthDate");} }
                        errors={invalidFieldError}
                        showErrors={true}
                        value={ !isEditable ? birthDatePreview : birthDateFormatted }
                        readOnly={!isEditable}
                    >
                    </FormField>
                </div>
            </>
        );
    }
}

class Mobile extends Component {
    render() {
        const { formData, handlePhoneNumber, defaultCountry, inputErrors, placeholderDisabled, isEditable } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const invalidFieldError = getErrorStrings(ErrorList.MOBILE_FORMAT, ErrorList.MOBILE_INVALID);

        return (
            <>
                <div>
                    <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Mobile (Optional)
                    </Text>

                    <div style={{ width: "100%", display: "inline-block", margin: "5px 0" }} >
                        <StyledCountryPhoneInput
                            autoFormat={false}
                            prefix={"+"}
                            showErrors={true}
                            errors={invalidFieldError}
                            country={defaultCountry.toLowerCase()}
                            value={formData.phoneNumber}
                            onChange={phone => handlePhoneNumber(phone)}
                            placeholder={!placeholderDisabled ? "Insert Mobile Here" : ""}
                            disabled={!isEditable}
                        />

                        { <ErrorText>{invalidFieldError}</ErrorText> }
                    </div>
                </div>
            </>
        );
    }
}

class Country extends Component {
    countryListData() { 
        return countryList().getData(); 
    }

    getSelectedCountryLabel() {
        const { formData } = this.props;
        const countryList = this.countryListData();
        const selectedCountry = countryList.find(country => country.value === formData.country);
        return selectedCountry && selectedCountry.label ? selectedCountry.label : "";
    }

    render() {
        const { formData, inputErrors, placeholderDisabled, isEditable, onChangeFormData } = this.props;
        const selectedCountryLabel = this.getSelectedCountryLabel();

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const invalidFieldError = getErrorStrings(ErrorList.EMPTY_COUNTRY, ErrorList.COUNTRY_INVALID);

        const textFieldPreview = <FormField borderRadius="12px" size="14px" height="50px"
            placeholderColor="#808080" border="1px solid #E9EBEF"
            color="000"
            backgroundColor="#F0F0F0"
            align="left"
            width="100%"
            type="text"
            placeholder={!placeholderDisabled ? "Country here" : ""}
            value={ selectedCountryLabel }
            readOnly={true}
        />;

        return (
            <div>
                <Text color="#000" align="left" size="16px" weight="bolder" margin="0.5em 0">
                    Country
                </Text>

                { !isEditable 
                    ? 
                    textFieldPreview 
                    : 
                    <CountrySelector width="100%" display="inline-block"
                        fontWeight="400"
                        align="left" padding="0.8125rem 1.5rem"
                        margin="5px 0"
                        placeholder="Select country"
                        placeholderAlignment="left"
                        errors={invalidFieldError}
                        showErrors={true}
                        placeholderColor="#808080"
                        selectIcon={ChevronDown}
                        value={formData.country}
                        onChange={(event) => {onChangeFormData(event, "country");} }
                    />
                }
            </div>
        );
    }
}

export {
    BusinessName, 
    Abn, 
    Website, 
    FirstName, 
    LastName, 
    DateOfBirth, 
    Mobile,
    Country
};

