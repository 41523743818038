import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CountryPhoneInput = styled(PhoneInput)`

    .flag-dropdown {
        border-radius: 12px !important;
        height: 40px !important;
        border: ${p => p.error && "1px solid red !important"};
    }

    input.form-control {
        color: ${p => p.error > 0 && "#ff0000 !important"};
        border: ${p => p.error ? "1px solid red !important" : "1px solid #000"};
    }

    input.form-control {
        border-radius: 12px !important;
        height: 40px !important;
        width: 170px !important;
        margin-left: 50px !important;
        padding-left: 20px !important;
        text-align: left !important;
        background-color: #F4F4F4;
    }

    .react-tel-input .selected-flag:focus {
        background-color: unset !important;
    }

    div.flag-dropdown {
        border-radius: 12px;
        border: 1px solid #000;
        text-align: left;
    }

    div.selected-flag{
        border-radius: 12px !important;
    }
`;

export default CountryPhoneInput;