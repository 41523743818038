import React, { useContext } from "react";
import { FlexContainer, LottieAnimation, Text, Toast } from "src/components";
import { ConfirmButton, BackButton, Container, Content, TextLabel, StyledInput, SubText } from "./styled";
import SecureBackupAnimationJson from "./assets/create";

import { usePasswordValidator } from "src/scenes/Chat/hooks";
import { UserContext } from "src/scenes/App/UserContext";
import { BackupKeyContext } from "src/scenes/Chat/BackupKeyContext";

const Create = (props) => {
    const { firstName, lastName, email } = useContext(UserContext);
    const { handleClose } = useContext(BackupKeyContext);
    const { 
        password, setPassword,
        confirmPassword, setConfirmPassword,
        handlePasswordValidation,
    } = usePasswordValidator();

    const handleSubmit = () => {
        try {
            handlePasswordValidation(firstName, lastName, email);
            props.handleBackupKeyPasswordCreation(password);
        } catch (error) {
            Toast.error(error.message);
        }  
    };
    
    return (
        <Container>
            <Content>
                <FlexContainer justifyContent="center" alignItems="center">
                    <LottieAnimation animationJsonData={SecureBackupAnimationJson} />

                    <Text size="20px" weight="700" margin="14px 0">Secure Your Chat History</Text>
                    <Text size="1rem" margin="0 0 38px 0" style={{ maxWidth: "552px" }}>
                        Create a strong password to secure your encrypted chat history. 
                        This password is essential for restoring your chats on a new device. 
                        Please remember or safely store this password, as it will be required for future access to your chat history.
                    </Text>
                    <TextLabel>Create Chat Backup Password</TextLabel>
                    <StyledInput placeholder="Create a new password"
                        type="password"
                        value={ password }
                        onChange={e => setPassword(e.target.value)}
                    />
                    <TextLabel>Re-enter Backup Password</TextLabel>
                    <StyledInput placeholder="Re-enter your new password"
                        type="password"
                        value={ confirmPassword }
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <SubText>• Minimum 8 characters</SubText>
                    <SubText>• Include a mix of uppercase letters, lowercase letters, numbers, and symbols to enhance security.</SubText>
                </FlexContainer>
            </Content>
            <FlexContainer margin="21px 0 0 0" padding="0 0 55px 0"
                direction="row" tDirection="row" mDirection="row"
                justifyContent="flex-end"
            >
                <BackButton onClick={handleClose}>
                    Back
                </BackButton>
                <ConfirmButton onClick={handleSubmit} disabled={props.disableButtons}>
                    Confirm
                </ConfirmButton>
            </FlexContainer>
        </Container>
    );
};

export default Create;