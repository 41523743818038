
import styled from "styled-components";

const ErrorLabel = styled.div`
    font-family: ${p => p.family || "Roboto, Helvetica, sans-serif"};
    font-weight: ${p => p.weight || 400};
    color: red;
    text-align: ${p => p.align || "left"};
    font-size: ${p => p.size || "14px"};
    width: ${p => p.width};
    min-width: ${p => p.minWidth};
    max-width: ${p => p.maxWidth};
    padding: ${p => p.padding};
    margin: ${p => p.margin};
    height: ${p => p.height};
`;

export default ErrorLabel;
