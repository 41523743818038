import React, { useEffect, useRef } from "react";
import { calculatePoints } from "src/utils/helpers";
import styled from "styled-components";

const ChartContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 4.6875rem;
    height: 3.125rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    `;

const Canvas = styled.canvas`
    flex: 1;
    width: 100%; /* Set to 100% to match the parent div's width */
    height: 100%; /* Set to 100% to match the parent div's height */
    `;

const LineChart = ({ data, isPositive }) => {
    const chartRef = useRef(null);
    
    useEffect(() => {
        if (chartRef.current && data) {
            const lineData = calculatePoints(data.sort(function(a, b) {
                const dateA = new Date(a["date"]);
                const dateB = new Date(b["date"]);
                return dateA - dateB;
            }).slice(0, 10)).map(a => { return a.point; });
            
            const ctx = chartRef.current.getContext("2d");
            const width = chartRef.current.width;
            const height = chartRef.current.height;

            ctx.clearRect(0, 0, width, height);

            const xSpacing = width / (lineData.length - 1);
            const yMax = Math.max(...lineData) + 2;
            const ySpacing = height / yMax;

            // Create a linear gradient
            const fillGradient = ctx.createLinearGradient(0, 0, 0, height);
            fillGradient.addColorStop(0, isPositive ? "rgba(2, 188, 119, 0.5)" : "rgba(255, 35, 100, 0.5)");
            fillGradient.addColorStop(0.9, isPositive ? "rgba(2, 188, 119, 0)" : "rgba(255, 35, 100, 0)");

            ctx.fillStyle = fillGradient;

            // Draw the filled area
            ctx.beginPath();
            ctx.moveTo(0, height - lineData[0] * ySpacing);

            for (let i = 1; i < lineData.length; i++) {
                const x = i * xSpacing;
                const y = (height - lineData[i] * ySpacing);

                const x0 = (x + (i - 1) * xSpacing) / 2;
                const y0 = (height - lineData[i - 1] * ySpacing + y) / 2;
                const x1 = ((x + x0) / 2) + 1;
                const y1 = (height - lineData[i - 1] * ySpacing + y0) / 2;

                ctx.bezierCurveTo(x1, y1, x0, y0, x, y);
            }

            // Close the path to complete the shape
            ctx.lineTo(width, height);
            ctx.lineTo(0, height);
            ctx.closePath();

            // Fill the area with the gradient
            ctx.fill();

            // Draw the stroke

            ctx.strokeStyle = isPositive ? "#02BC77" : "#FF2366";
            ctx.lineWidth = 7; 
            ctx.lineJoin = "round";

            ctx.beginPath();
            ctx.moveTo(0, height - lineData[0] * ySpacing);

            for (let i = 1; i < lineData.length; i++) {
                const x = i * xSpacing;
                const y = (height - lineData[i] * ySpacing);

                const x0 = (x + (i - 1) * xSpacing) / 2;
                const y0 = (height - lineData[i - 1] * ySpacing + y) / 2;
                const x1 = ((x + x0) / 2) + 1;
                const y1 = (height - lineData[i - 1] * ySpacing + y0) / 2;

                ctx.bezierCurveTo(x1, y1, x0, y0, x, y);
            }

            ctx.stroke();
        }
    }, [data]);
    
    return (
        <ChartContainer>
            <Canvas ref={chartRef} />
        </ChartContainer>
    );
};

export default LineChart;
