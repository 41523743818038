import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { List, ListItem } from "@material-ui/core";
import { Text, Toast } from "src/components";
import ListAvatar from "./ListAvatar";
import ListText from "./ListText";
import { ChatContext } from "../../ChatContext";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import UnreadCount from "./UnreadCount";
import { API_URL } from "src/scenes/App";
import { checkIfUserIsOnline, doesObjectPropertyExist } from "src/utils/helpers";
import { USER_TYPE_EMPLOYEES, USER_TYPE_CLIENTS } from "src/constants/chat";
import { ChatListScrollableContainer } from "../styled/chatListStyled";

export const StyledText = styled(Text)`
    font-weight: ${ p => p.weight || 400 };
    color: ${ p => p.color || "#000000" };
    text-align: left;
    font-size: ${ p => p.size };
    margin: ${ p => p.margin || "1px" };
    text-align: ${ p => p.align || "left"};
`;

const ListOfChats = (props) => {
    const { chatsList } = props;
    const {
        handleOpenChat, 
        matrixClient,
        currentChat,
        currentUserEmployeeUuid,
        onlineUsersMatrixId,
        selectedChatListTypeOrLeadershipUuid,
        usersList
    } = useContext(ChatContext);
    const currentUser = useContext(UserContext);
    const [safeToRenderChatList, setSafeToRenderChatList] = useState(false);
    const headers = { headers: { Authorization: "Bearer " + currentUser[UserKeys.TOKEN] } };

    useEffect(() => {
        if (chatsList && Array.isArray(chatsList)) {
            setSafeToRenderChatList(true);
        }
    }, [chatsList]);

    const renderClassName = (uuid) => {
        if (currentChat && currentChat.uuid === uuid) {
            return "active-chat";
        }
        return "inactive-chat cursor-pointer";
    };

    const beforeOpeningChat = async (chat) => {
        if (!doesObjectPropertyExist(chat, "matrixRoomId")) {
            return;
        }
        if (chat.unreadCount !== 0) {
            const hasSentReceipt = await sendReadReceipt(chat.matrixRoomId);
            if (!hasSentReceipt) {
                return;
            }
            chat.unreadCount = 0;
        }
        handleOpenChat(chat);
    };

    const sendReadReceipt = async (matrixRoomId) => {
        try {
            const room = await matrixClient.getRoom(matrixRoomId);
            const lastEvent = room.getLastLiveEvent();
            const url = `${API_URL}/_matrix/client/v3/rooms/${room.roomId}/receipt/m.read/${lastEvent.getId()}`;

            const formData = {
                "next_token": "abcdef",
                "notifications": [
                    {
                        "actions": ["notify"],
                        "event": lastEvent,
                        "profile_tag": "",
                        "read": true,
                        "room_id": room.roomId,
                        "ts": Date.now()
                    }
                ]
            };

            await axios.post(url, formData, headers);
            return true;
        } catch (error) {
            Toast.error("Did not find a valid room for this id. Unable to process request.");
            return false;
        }
    };

    const checkHasLeadershipRoles = (chat) => {
        if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES || selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS) {
            return true;
        }
        return chat.leadershipRoleUuid === selectedChatListTypeOrLeadershipUuid;
    };

    const checkIfUserPresenceOverrideCorrect = (userMatrixId, chat) => {
        const user = usersList.find((user) => user.matrixUserId === userMatrixId);
        if (!user) {
            // this only occurs if the user does not belong to usertype based on fetchUsersList result
            const nonCurrentChatTypeUser = chat.users.find((user) => user.matrixUserId === userMatrixId);
            return nonCurrentChatTypeUser.employeeDetails.chatPresenceOverride === "online";
        }
        return user.chatPresenceOverride === "online";
    };

    const renderChatsList = (chats) => {
        return chats.map(chat => {
            const { isGroupChat, users, name, latestEvent } = chat;
            const otherChatUsers = users.filter(user => user.employeeUuid !== currentUserEmployeeUuid);
            const otherUserDetails = otherChatUsers.length ? otherChatUsers[0] : currentUser;
            const isThisUserOnline = checkIfUserIsOnline(onlineUsersMatrixId, otherUserDetails?.matrixUserId) && checkIfUserPresenceOverrideCorrect(otherUserDetails?.matrixUserId, chat);
            const showMessagePreviewWhenNotEmpty = chat.message !== "No messages yet";
            const ownerAllowedToOpenChat = chat.ownerUuid === currentUserEmployeeUuid;
            if ((showMessagePreviewWhenNotEmpty || ownerAllowedToOpenChat) && checkHasLeadershipRoles(chat)) {
                return (
                    <ListItem key={chat.uuid}
                        className={renderClassName(chat.uuid)}
                        style={{ boxSizing: "border-box", padding: "0.625rem", borderRadius: "0.625rem", display: "flex" }}
                        onClick={() => beforeOpeningChat(chat)}
                    >
                        <ListAvatar
                            currentUser={currentUser}
                            users={users}
                            isGroupChat={isGroupChat}
                            currentUserEmployeeUuid={currentUserEmployeeUuid}
                            isOnline={ isThisUserOnline }
                            showPresenceStatus={true}
                        />
                        <ListText chatName={name} message={chat.message} latestEvent={latestEvent} showTimestamp={chat.unreadCount === 0} />
                        <div>
                            { chat.unreadCount !== 0 ? <UnreadCount unreadCount={chat.unreadCount} />
                                : <> </>
                            }
                        </div>
                    </ListItem>
                );
            }
        });
    };

    return (
        <React.Fragment>
            { safeToRenderChatList &&
                <>
                    { chatsList.length > 0 ?
                        <ChatListScrollableContainer>
                            <List>{ renderChatsList(chatsList) }</List>
                        </ChatListScrollableContainer>
                        :
                        <div className="flex-centered-content" style={{ height: "100%" }}>
                            <StyledText align="center" margin="1.25rem 0">You have no available chats.</StyledText>
                        </div>
                    }
                </>
                
            }
        </React.Fragment>
    );
};

export default ListOfChats;