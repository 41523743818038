import React, { Component } from "react";
import styled from "styled-components";
import {
    Toast, FlexContainer, Text, Button, PageHeadingLabel, PageContainer
} from "src/components";

//assets
import ArrowDown from "src/img/ArrowDown.svg";
import ArrowUp from "src/img/ArrowDown.svg";

import IconRating from "src/img/survey/rating.png";
import IconSatisfaction from "src/img/survey/emotion.png";
import IconYesNo from "src/img/survey/Yes-No.png";
import IconFeedBack from "src/img/survey/comment.png";

const SurveyButton = styled(Button)`
    padding: 10px 40px 10px;
    border-radius: 20px;
    color: #FFFFFF;
    position: relative;
    cursor: pointer;
`;

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const Th = styled.th`    
    background-color: #000000;
    color: white;
    padding: 20px 10px;
    font-size: 13px;
    font-weight: 500;
    width: ${p => p.width || "5rem"};
    border-left:1px solid #FFFFFF;
`;

const Tr = styled.tr`
    :nth-child(even) {
        background-color: white;
    }
    :nth-child(odd) {
        background-color: #f3f3f4;
    }
`;

const Td = styled.td`
    width: ${p => p.width || "5rem"};
    border-right: ${p => p.borderRight || "1px solid #2e3036"};
    text-align: center;
    padding: 10px;
    font-size: 13px;
`;

const ColumnHeader = (({ heading, sortDirection }) => {
    const Container = styled.div`
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;

        text-align: center;
        user-select: none;
        cursor: pointer;
    `;

    const SortIndicator = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-left: 8px;
    `;
    
    return (
        <Container>
            {heading}
            <SortIndicator hidden={sortDirection === SortableColumnState.INACTIVE}>
                {(sortDirection === SortableColumnState.DESCENDING) &&
                    <img width="10px" src={ArrowDown} alt="SortArrow"/>}
                {(sortDirection === SortableColumnState.ASCENDING) &&
                    <img width="10px" src={ArrowUp} alt="SortArrow" style={{ transform: "rotate(180deg)" }} />}
            </SortIndicator>
        </Container>
    );
});

const SurveyType = {  
    RATING: "Rating",
    SATISFACTION: "Satisfaction",
    YESNO: "Yes/No",
    FEEDBACK: "Feedback",
};

export const SortableColumns = {  
    SURVEY_NAME: 0,
    SURVEY_TYPE: 1,
    USER_GROUP: 2,
    START_DATE: 3,
    FINISH_DATE: 4,
    STATUS: 5
};

export const SortableColumnState = {
    INACTIVE: 0,
    DESCENDING: 1,
    ASCENDING: 2,
};

const ViewButton = styled(Button)`
    padding: 5px  0px;
    border-radius: 20px;
    background-color: #51315D;
    border: 1px #51315D solid;
    color: #FFFFFF;
    cursor: pointer;
    min-width: 100px;
`;

const EditButton = styled(Button)`
    padding: 5px  0px;
    border-radius: 20px;
    color: #000000;
    cursor: pointer;
    border: 1px #23262D solid;
    min-width: 100px;
`;

const TextInTable = styled(Text)`
    margin: 0;
    text-align: center;
    border: none;
    color: ${p => p.color || "black"};
    font-family: Roboto, Helvetica, sans-serif;
    size: "13px";
`;

class SurveyTable extends Component {
    state = {
        checkAllUsers: false,
        removeSelectedSurveysUuid: [],
        sortableColumns: [],
        showRemoveSelectedButton: false
    };

    // No need to set state fetching, this function is only setting up the sortable columns array.
    setColumns = () => {
        let columns = [];
        for (let i = 0; i < SortableColumns.FLAG_LENGTH; i++) {
            columns.push(SortableColumnState.INACTIVE);
        }

        this.setState(state => ({
            sortableColumns: columns,
        }));
    };
    
    sortColumns = (columnIndex) => {
        const columns = this.state.sortableColumns;
        let fieldName = "";

        // Setting all other column values to inactive, this way they will always start as descending when clicked after another column,
        // while any subsequent clicks will toggle between descending and ascending sort.
        for (let i = 0; i <= SortableColumns.STATUS; i++) {
            if (i === columnIndex) {
                let value = columns[columnIndex];

                switch (value) {
                case SortableColumnState.DESCENDING:
                    value = SortableColumnState.ASCENDING;
                    break;
                case SortableColumnState.ASCENDING:
                case SortableColumnState.INACTIVE:
                default:
                    value = SortableColumnState.DESCENDING;
                    break;
                }

                columns[columnIndex] = value;
            } else {
                columns[i] = SortableColumnState.INACTIVE;
            }
        }

        // Calling the sort for the particular column affected.
        switch (columnIndex) {
        case SortableColumns.SURVEY_NAME:
            fieldName = "name";
            break;
        case SortableColumns.SURVEY_TYPE:
            fieldName = "surveyType";
            break;

        case SortableColumns.USER_GROUP:
            fieldName = "userGroup";
            break;
        case SortableColumns.START_DATE:
            fieldName = "startDate";
            break;
        case SortableColumns.FINISH_DATE:
            fieldName = "finishDate";
            break;
        case SortableColumns.STATUS:
            fieldName = "status";
            break;
        default:
            Toast.error("Sortable Columns unhandled case");
            break;
        }

        if (fieldName !== "")
            this.sortField(columns[columnIndex], fieldName);

        this.setState({
            sortableColumns: columns
        });
    };

    sortField = (sortableState, fieldName) => {
        const surveys = this.props.surveyArray;

        if (sortableState === SortableColumnState.ASCENDING) {
            surveys.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1);
        } else {
            surveys.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1);
        }

        this.setState({
            surveyArray: surveys
        });
    };

    /**
     * @param {number} length
     */
    updateCheckAllInput = (length) => {
        /*disabled functionality */
        //this.setState({showRemoveSelectedButton: length === 0 ? false : true});
    };

    /** Used by Survey Table Content */
    addRemoveUser = (uuid) => {
        if (this.state.removeSelectedSurveysUuid.includes(uuid)) {
            let removeSelectedSurveysUuid = this.state.removeSelectedSurveysUuid.filter(item => item !== uuid);
            
            this.setState({
                removeSelectedSurveysUuid: removeSelectedSurveysUuid,
                checkAllUsers: false
            }, () => {
                this.updateCheckAllInput(this.state.removeSelectedSurveysUuid.length);
            });
        } else {
            this.setState({
                removeSelectedSurveysUuid: [...this.state.removeSelectedSurveysUuid, uuid] 
            }, () => {
                this.updateCheckAllInput(this.state.removeSelectedSurveysUuid.length);

                if (this.state.existedUsers === this.state.removeSelectedSurveysUuid.length) {
                    this.setState({ checkAllUsers: true });
                }
            });
        }
    };

    render() {
        //add method 
        const { surveyArray, showSurveyForm, editSurvey, showHowItWorks, surveyInsights } = this.props;

        //generate empty lines when survey/client list is less than 10 records
        let emptyList;
        if (surveyArray.length < 10) {
            emptyList = Array.apply(0, Array(10 - surveyArray.length)).map((value, index) => {
                return (
                    <Tr key={index} style={{ height: "46px" }}>

                        <Td width={"210px"}/>
                        <Td width={"180px"}/>

                        <Td width={"180px"}/>
                        <Td width={"250px"}/>
                        <Td width={"250px"}/>

                        <Td width={"180px"}/>
                        <Td width={"180px"}/>
                        <Td width={"180px"} borderRight="none"/>
                    </Tr>
                );});
        }  

        return (
            <PageContainer>
                <PageHeadingLabel>
                    Surveys
                </PageHeadingLabel>

                <FlexContainer height="40px" marginTop="20px" direction="row" style={{ alignItems: "baseline", flexFlow: "column wrap", alignContent: "space-between" }}>
                    
                    <SurveyButton backgroundColor="#000000" onClick={ () => { showHowItWorks(true); }}>
                        <span>How Does It Work?</span>
                    </SurveyButton>

                    <div>
                        <SurveyButton backgroundColor="#006CFF" onClick={(e) => showSurveyForm(true) }>
                            <span>Create New Survey</span>
                        </SurveyButton>
                    </div>
                </FlexContainer>

                <FlexContainer className="tableContainerOverFlow">
                    <Table className="tableWithStickyHeader">
                        <thead>
                            <Tr className="sticky">
                                <Th width={"210px"} onClick={() => this.sortColumns(SortableColumns.SURVEY_NAME)} >
                                    <ColumnHeader
                                        heading="Survey Name"
                                        sortDirection={this.state.sortableColumns[SortableColumns.SURVEY_NAME]}
                                    />
                                </Th>
                                <Th width={"180px"} onClick={() => this.sortColumns(SortableColumns.SURVEY_TYPE)} >
                                    <ColumnHeader
                                        heading="Survey Type"
                                        sortDirection={this.state.sortableColumns[SortableColumns.SURVEY_TYPE]}
                                    />
                                </Th>
                                <Th width={"180px"} onClick={() => this.sortColumns(SortableColumns.USER_GROUP)} >
                                    <ColumnHeader
                                        heading="User Group"
                                        sortDirection={this.state.sortableColumns[SortableColumns.USER_GROUP]}
                                    />
                                </Th>

                                <Th width={"250px"} onClick={() => this.sortColumns(SortableColumns.START_DATE)} >
                                    <ColumnHeader
                                        heading="Start Date"
                                        sortDirection={this.state.sortableColumns[SortableColumns.START_DATE]}
                                    />
                                </Th>
                                
                                <Th width={"250px"} onClick={() => this.sortColumns(SortableColumns.FINISH_DATE)} >
                                    <ColumnHeader
                                        heading="Finish Date"
                                        sortDirection={this.state.sortableColumns[SortableColumns.FINISH_DATE]}
                                    />
                                </Th>

                                <Th width={"180px"} >
                                    <ColumnHeader
                                        heading="Survey Insights"
                                    />
                                </Th>
                                <Th width={"180px"} >
                                    <ColumnHeader
                                        heading="Edit"
                                    />
                                </Th>
                                <Th width={"180px"} onClick={() => this.sortColumns(SortableColumns.STATUS)} >
                                    <ColumnHeader
                                        heading="Status"
                                        sortDirection={this.state.sortableColumns[SortableColumns.STATUS]}
                                    />
                                </Th>
                            </Tr>
                        </thead>
                
                        <tbody>
                            {
                                surveyArray.map((survey, index) => {
                                    return (
                                        <Tr key={survey.uuid} 
                                            onClick={() => this.setState({ editUser: survey.uuid })}
                                            style={{ "border": (survey.uuid === this.state.editUser) ? "1px solid #2D3037" : "none" }}>
                                            
                                            <Td style={{ textAlign: "left" }} width={"12%"}>
                                                {survey["name"]}
                                            </Td>
                                            <Td>
                                                { survey["surveyType"] === SurveyType.RATING && <img width="" src={IconRating} alt="IconRating"/>}
                                                { survey["surveyType"] === SurveyType.SATISFACTION && <img width="" src={IconSatisfaction} alt="IconSatisfaction"/>}
                                                { survey["surveyType"] === SurveyType.YESNO && <img width="" src={IconYesNo} alt="IconYesNo"/>}
                                                { survey["surveyType"] === SurveyType.FEEDBACK && <img width="" src={IconFeedBack} alt="IconFeedBack"/>}
                                            </Td>

                                            <Td>
                                                {survey["userGroup"]}
                                            </Td>
                                            <Td>
                                                {survey["startDate"]}
                                            </Td>

                                            <Td>
                                                {survey["finishDate"]}
                                            </Td>
                                            <Td> 
                                                {
                                                    survey["status"] !== "Scheduled" 
                                                        ?
                                                        <ViewButton onClick={() => surveyInsights(survey.uuid)}>
                                                            <span>View</span>
                                                        </ViewButton>  
                                                        :
                                                        <span> - </span>
                                                }
                                                
                                            </Td>
                                            <Td>
                                                <EditButton onClick={() => editSurvey(survey.uuid)} >
                                                    <span>Edit</span>
                                                </EditButton>
                                            </Td>
                                            <Td>
                                                <TextInTable>
                                                    {
                                                        survey["status"] === "Scheduled" 
                                                            ? 
                                                            <div style={{ fontWeight: "500", color: "#006CFF" }}>{survey["status"]}</div>
                                                            :
                                                            <div style={{ fontWeight: "500", color: "#77B500" }}>{survey["status"]}</div>
                                                    }
                                                        
                                                </TextInTable>
                                            </Td>
                                            
                                        </Tr>
                                    );
                                })
                            }

                            { emptyList }
                        </tbody>
                    </Table>
                </FlexContainer>
            </PageContainer>
        );
    }
}

export default SurveyTable;