import React, { Component } from "react";
import { Text, FlexContainer } from "src/components";
import { SetupPasswordContainerContext } from "src/components/SetupPasswordContainerContext";
import DownloadApple from "src/img/Apple@2x.png";
import DownloadGoogle from "src/img/Google@2x.png";

class WellDone extends Component {
    static contextType = SetupPasswordContainerContext;
    
    render() {
        const { businessName } = this.context;

        return (
            <FlexContainer style={{ margin: "auto 0" }} family="Roboto, Helvetica, sans-serif">
                <FlexContainer alignItems="center" padding="2rem 1rem" height="100%">
            
                    <Text color="#FFF" size="40px" mSize="30px" weight="500" width="25rem" margin="0">
                        Well Done!
                    </Text>

                    <Text color="#FFF" size="16px" weight="500" width="25rem">
                        You may now proceed to download the application and utilise your access code and password credentials to log in to {businessName} Me Business App.
                    </Text>

                    <Text color="#FFF" size="16px" weight="500" width="25rem">
                        Please ensure that you select &rsquo;Business Login&rsquo; option in order to gain access to the business application.
                    </Text>
                    
                    <br/>

                    <Text color="#FFF" size="20px" weight="500" width="25rem">
                        Download the app
                    </Text>

                    <FlexContainer display="table-cell" margin="0 auto" mWidth="unset">
                        <a href="https://apps.apple.com/au/app/daily-fix-me-motivation/id1445382703" target="_blank" rel="noopener noreferrer">
                            <img src={DownloadApple} alt="Download Apple" height="50px" style={{ padding: "10px" }}/>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.onpoint_holdings.dailyfixme" target="_blank" rel="noopener noreferrer">
                            <img src={DownloadGoogle} alt="Download Google" height="70px" />
                        </a>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        ); 
    }
}

export default WellDone;