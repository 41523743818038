import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios"; 
import Select, { components } from "react-select";

import {
    Toast, FlexContainer, FormField, ErrorText,
    Text, Form, PageHeadingLabel, PageContainer
} from "src/components";

import { CancelButton, SaveButton, StyledButton, QuestionContainer, AddAnotherQuestionContainer, RemoveQuestionIconContainer, FormBackground, FormSectionHeader, LoadingIndicatorButton, LoadingIndicatorIcon } from "../components/Utils";
import { EnumSurveyShowFrequency, EnumSurveyShownIn, ErrorStrings, ErrorList, FormStep, surveyTypeOptions, surveyUserGroupOptions, surveyShowInOptions, surveyFrequencyOptions } from "../components/Enums";
import { Checkbox } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const { Option } = components;
const IconOption = props => (
    <Option { ...props }>
        { props.data.label } 
        &nbsp;
        <div style={{ display: "inline-flex", justifyContent: "center", verticalAlign: "middle" }}>
            <img 
                src={ props.data.icon }
                style={{ height: 25 }}
                alt={ props.data.label }
            />
            { props.data.value == "Rating" || props.data.value == "Satisfaction" ?
                <>
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                </>
                :
                <>
                </>
            }
        </div>
    </Option>
);

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        { props.data.label } 
        &nbsp;
        <div style={{ display: "inline-flex", justifyContent: "center", verticalAlign: "middle", height: "100%" }}>
            <img 
                src={ props.data.icon }
                style={{ height: 25 }}
                alt={ props.data.label }
            />
            { props.data.value == "Rating" || props.data.value == "Satisfaction" ?
                <>
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                    &nbsp;
                    <img 
                        src={ props.data.icon }
                        style={{ height: 25 }}
                        alt={ props.data.label }
                    />
                </>
                :
                <>
                </>
            }
        </div>
    </components.SingleValue>
);

const customStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "12px",
        border: "1px solid #E9EBEF",
        textDecoration: "none",
        color: "white",
        height: "50px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        fontSize: "16px",
        fontFamily: "Roboto, Helvetica, sans-serif",
        appearance: "none",
        placeholderAlignment: "middle",
        "&:hover": {
            borderColor: "#E9EBEF",
            outline: "#E9EBEF",
            boxShadow: "0 0 1px #E9EBEF"
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "16px",
        fontWeight: "Regular",
        justifyContent: "center",
        verticalAlign: "middle"
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (provided) => ({
        marginTop: "1rem",
        borderRadius: "12px",
        border: ".5px solid black",
        fontSize: "16px",
        backgroundColor: "white",
        position: "absolute",
        zIndex: "10",
        width: "100%",
        "&hover": {
            borderRadius: "12px"
        }
    }),
    option: (base) => ({
        ...base,
        borderRadius: "12px",
    })
};

class SurveyForm extends Component {
    state = {
        survey: {
            name: "",
            surveyType: "",
            startDate: "",
            finishDate: "",
            userGroup: "",
            surveyQuestions: {
                one: { uuid: "", question: "" },
                two: {},
                three: {}
            },
            showIn: EnumSurveyShownIn.QUOTE_SCREEN,
            showFrequency: EnumSurveyShowFrequency.EVERYTIME,
            anonymousSurvey: false
        },
        message: "",
        inputErrors: [],
        formStep: FormStep.PAGE_SURVEY_NAME,
        setInputTypeAs: "text",
        activeQuestionCount: 1,
        loading: false,
    };

    nextStep = (stepNumber) => {
        //validate the page before going to the next
        let errors = this.getErrors();
        if (errors.length > 0 && stepNumber > this.state.formStep)
            stepNumber = this.state.formStep;

        this.setState({
            formStep: stepNumber,
            inputErrors: errors
        });
    };

    getErrors() {
        let survey = this.state.survey;
        let errors = [];

        switch (this.state.formStep)
        {
        default:
        case FormStep.PAGE_SURVEY_NAME:
            if (survey.name === "")
            {
                errors.push(ErrorList.EMPTY_SURVEY_NAME);
            }
            break;
        case FormStep.PAGE_TYPE_OF_SURVEY:
            if (survey.surveyType === "")
            {
                errors.push(ErrorList.EMPTY_SURVEY_TYPE);
            }
            break;
        case FormStep.PAGE_SURVEY_DURATION:

            if (survey.startDate === "")
            {
                errors.push(ErrorList.EMPTY_START_DATE);
            }
            if (survey.finishDate === "")
            {
                errors.push(ErrorList.EMPTY_FINISH_DATE);
            }

            break;
        case FormStep.PAGE_USER_GROUP:
            if (survey.userGroup === "")
            {
                errors.push(ErrorList.EMPTY_USER_GROUP);
            }
            break;
        case FormStep.PAGE_SURVEY_QUESTIONS:
            let activeQuestionCount = this.state.activeQuestionCount;
            if (activeQuestionCount >= 1 && survey.surveyQuestions.one.question === "")
            {
                errors.push(ErrorList.EMPTY_QUESTION_ONE);
            }

            if (activeQuestionCount >= 2 && survey.surveyQuestions.two.question === "")
            {
                errors.push(ErrorList.EMPTY_QUESTION_TWO);
            }

            if (activeQuestionCount >= 3 && survey.surveyQuestions.three.question === "")
            {
                errors.push(ErrorList.EMPTY_QUESTION_THREE);
            }
            break;
        case FormStep.PAGE_SURVEY_FREQUENCY:
            if (survey.showIn === "")
            {
                errors.push(ErrorList.SHOW_IN);
            }

            if (survey.showFrequency === "")
            {
                errors.push(ErrorList.SHOW_FREQUENCY);
            }

            break;
        }

        return errors;
    }

    inputSurveyName = (event) => {
        const surveyName = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                name: surveyName // update the value of specific key
            }
        }));
    };

    inputStartDate = (event) => {
        const startDate = event.target.value;
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                startDate: startDate // update the value of specific key
            }
        }));
    };

    inputFinishDate = (event) => {
        const finishDate = event.target.value;
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                finishDate: finishDate // update the value of specific key
            }
        }));
    };

    selectSurveyType = (event) => {
        const surveyType = event.value;
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                surveyType: surveyType // update the value of specific key
            }
        }));
    };

    inputQuestion = (questionNumber, event) => {
        const numberWord = ["", "one", "two", "three"];
        let questionElement = numberWord[questionNumber];

        const questionString = event.target.value;

        this.setState(prevState => ({
            survey: {
                ...prevState.survey,
                surveyQuestions: {
                    ...prevState.survey.surveyQuestions,
                    [questionElement]: { uuid: "", question: questionString, questionOrder: questionNumber }
                }
            }
        }));
    };

    selectUserGroup = (event) => {
        const userGroup = event.value;
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                userGroup: userGroup, // update the value of specific key
            },
            inputErrors: [], //reset Error

        }));
    };

    removeQuestion = (questionElement) => {
        this.setState(prevState => ({
            survey: {                  
                ...prevState.survey,   
                surveyQuestions: { 
                    ...prevState.survey.surveyQuestions,
                    [questionElement]: {}
                }
            },
            activeQuestionCount: this.state.activeQuestionCount - 1
        }));
    };

    addQuestion = () => {
        this.setState({
            activeQuestionCount: this.state.activeQuestionCount + 1
        });
    };

    selectShowIn = (event) => {
        const showIn = event.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showIn: showIn // update the value of specific key
            }
        }));
    };

    setSurveyAnonymous = (event) => {
        this.setState(prevState => ({
            survey: {
                ...prevState.survey,
                anonymousSurvey: event
            }
        }));
    };

    selectShowFrequency = (event) => {
        const showFrequency = event.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showFrequency: showFrequency // update the value of specific key
            }
        }));
    };
    
    formSubmission = () => {
        this.setState({
            loading: true,
        });

        const surveyData = this.state.survey;
        const surveyQuestions = Object.keys(surveyData.surveyQuestions);

        let surveyQuestionItems = {};
        surveyQuestions.forEach(questionKey => {
            if (surveyData.surveyQuestions[questionKey].question !== "" && typeof surveyData.surveyQuestions[questionKey].question !== "undefined")
                surveyQuestionItems[questionKey] = surveyData.surveyQuestions[questionKey];
        });

        axios.post(`${API_URL}/survey/company`, {
            name: surveyData.name,
            surveyType: surveyData.surveyType,
            startDate: surveyData.startDate ? surveyData.startDate + " 00:00:00" : null,
            finishDate: surveyData.finishDate ? surveyData.finishDate + " 23:59:59" : null,
            userGroup: surveyData.userGroup,
            surveyQuestions: surveyQuestionItems,
            showIn: surveyData.showIn,
            showFrequency: surveyData.showFrequency,
            isAnonymous: surveyData.anonymousSurvey
        },
        {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            this.setState({ 
                formStep: FormStep.PAGE_CONGRATULATIONS
            });
        }).catch(error => {
            if (error.message) {
                Toast.error(error.message.toString());
                return;
            }

            let errors = [];
            let dataErrorFields = error.fields;

            let formStep;

            try {
                dataErrorFields.forEach(function(fieldKey) {
                    switch (fieldKey) {
                    case "userGroupOverLap":
                        errors.push(ErrorList.DATE_AND_USERGROUP_OVERLAP);
                        formStep = FormStep.PAGE_USER_GROUP;
                        return;

                    case "startDateInvalid":
                        errors.push(ErrorList.DATE_START_DATE_INVALID);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;
        
                    case "finishDateInvalid":
                        errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;

                    case "startDateGreaterThanFinishDate":
                        errors.push(ErrorList.DATE_RANGE_INVALID);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;
                    case "name":
                        errors.push(ErrorList.EMPTY_SURVEY_NAME);
                        formStep = FormStep.PAGE_SURVEY_NAME;
                        return;
                    case "surveyType":
                        errors.push(ErrorList.EMPTY_SURVEY_TYPE);
                        formStep = FormStep.PAGE_SURVEY_NAME;
                        return;
                    case "userGroup":
                        errors.push(ErrorList.EMPTY_USER_GROUP);
                        formStep = FormStep.PAGE_USER_GROUP;
                        return;
                    case "startDateEmpty":
                        errors.push(ErrorList.EMPTY_START_DATE);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;
                    default:
                        //NO ERROR TO CATCH
                        return;
                    }
                });

                this.setState({
                    inputErrors: errors,
                    formStep: formStep
                });

                return Toast.error("Please make sure your entries are correct");
            }
            catch (e) {
                return Toast.error(error.message); 
            }
        }).finally(error => {
            this.setState({
                loading: false,
            });
        });
    };

    render() {
        const { showSurveyForm, getSurveys } = this.props;
        const { inputErrors, formStep, survey, setInputTypeAs, activeQuestionCount } = this.state;

        if (formStep === FormStep.PAGE_CONGRATULATIONS)
            return <PageCongratulations showSurveyForm={showSurveyForm} nextStep={this.nextStep} getSurveys={getSurveys} />;

        return (
            <PageContainer>
                <PageHeadingLabel size="2.5rem">Create New Survey</PageHeadingLabel>
                <div className="row" style={{ maxWidth: "94.5rem" }}>
                    <div className="description" style={{ marginBottom: "1em" }}>
                        Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability to <br/>
                        create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards <br/>
                        employees, clients, or both, ensuring you reach the right audience every time.
                    </div>
                </div>

                <FormBackground>
                    <div style={{ padding: "2.5rem", width: "100%" }}>
                        {
                            formStep === FormStep.PAGE_CONGRATULATIONS ?
                                <PageCongratulations showSurveyForm={showSurveyForm} nextStep={this.nextStep} getSurveys={getSurveys} />
                                :
                                <>
                                    <Form width="100%" onSubmit={(e) => { e.preventDefault(); }} >
                                        <div style={{ display: "flex" }}>
                                            <div style={{ flex: "50%", marginRight: "2rem" }}>
                                                <FormSectionHeader>
                                                    Survey Information
                                                    <SurveyNameField survey={survey} inputSurveyName={this.inputSurveyName} inputErrors={inputErrors} />
                                                    <TypeOfSurveyField showSurveyForm={true} nextStep={this.nextStep} survey={survey} selectSurveyType={this.selectSurveyType} inputErrors={inputErrors} />
                                                    <SurveyDurationFields showSurveyForm={showSurveyForm} nextStep={this.nextStep} survey={survey} inputStartDate={this.inputStartDate} inputFinishDate={this.inputFinishDate} inputErrors={inputErrors} setInputTypeAs={setInputTypeAs}/>
                                                    <UserGroupField showSurveyForm={true} nextStep={this.nextStep} survey={survey} selectUserGroup={this.selectUserGroup} inputErrors={inputErrors} />
                                                    <SurveyFrequencyField showSurveyForm={true} nextStep={this.nextStep} survey={survey} inputErrors={inputErrors} selectShowIn={this.selectShowIn} selectShowFrequency={this.selectShowFrequency} />
                                                </FormSectionHeader>
                                            </div>
                                            <div style={{ flex: "50%", marginRight: "5rem", display: "flex", flexWrap: "wrap" }}>
                                                <FormSectionHeader style={{ width: "100%" }}>
                                                    Survey Questions
                                                    <SurveyQuestionsFields survey={survey} inputQuestion={this.inputQuestion} activeQuestionCount={activeQuestionCount} addQuestion={this.addQuestion} removeQuestion={this.removeQuestion} inputErrors={inputErrors} />
                                                </FormSectionHeader>
                                                <div style={{ placeSelf: "flex-end", margin: "0 0 2.5rem 0" }}>
                                                    <SurveySubmissionAnonymousField setSurveyAnonymous={this.setSurveyAnonymous} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                            {
                                                this.state.loading ?
                                                    <LoadingIndicatorButton height="38px">
                                                        <LoadingIndicatorIcon height="20px" width="20px" margin="5px"/>
                                                    </LoadingIndicatorButton>
                                                    :
                                                    <>
                                                        <CancelButton borderRadius="12px" onClick={(e) => { showSurveyForm(false); }}>Cancel</CancelButton>
                                                        <SaveButton border="unset !important" color="white" backgroundColor="#006CFF" borderRadius="12px" onClick={(e) => { this.formSubmission(); }}>Save</SaveButton>
                                                    </>
                                            }
                                        </div>
                                    </Form>
                                </>
                        }
                    </div>
                </FormBackground>
            </PageContainer>
        );
    }
}

class SurveyNameField extends Component {
    render() {
        const { survey, inputSurveyName, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyNameError = getErrorStrings(ErrorList.EMPTY_SURVEY_NAME);

        return (
            <>
                <Text size="16px" align="left" weight="bold">
                    Enter survey name
                </Text>
                <FormField borderRadius="12px" size="16px" padding="10px 20px" height="50px"
                    placeholderColor="#000000" border="1px solid #E9EBEF"
                    placeholderAlignment="left"
                    width="100%"
                    type="text"
                    style={{ textAlign: "left" }}
                    placeholder="Enter name here"
                    onChange={(event) => { inputSurveyName(event); }}
                    errors={surveyNameError}
                    maxLength="100"
                    value={survey.name}
                    showErrors={surveyNameError.length > 0}
                >
                </FormField>
            </>
        );
    }
}

class TypeOfSurveyField extends Component {
    render() {
        const { selectSurveyType, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyTypeError = getErrorStrings(ErrorList.EMPTY_SURVEY_TYPE);

        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Select The Survey Type
                </Text>
                <Select
                    isSearchable={false}
                    className="surveyTypeDropdown"
                    placeholder="Please select..."
                    options={ surveyTypeOptions }
                    onChange={(choice) => selectSurveyType(choice)}
                    styles={customStyles}
                    components={{ Option: IconOption, SingleValue: SingleValue }}
                />

                { surveyTypeError.length > 0 &&
                    <ErrorText errorMargin="15px 20px 15px">
                        {surveyTypeError}
                    </ErrorText>
                }
            </>
        );
    }
}

class SurveyDurationFields extends Component {
    state = {
        startDateSetInputTypeAs: "text",
        finishDateSetInputTypeAs: "text",
        tooltipActive: false
    };

    startDateChangeInputTypeToDate = (event) => {
        this.setState({
            startDateSetInputTypeAs: "date"
        });
    };

    finishDateChangeInputTypeToDate = (event) => {
        this.setState({
            finishDateSetInputTypeAs: "date"
        });
    };

    render() {
        const { survey, inputStartDate, inputFinishDate, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
        const surveyFinishDateError = getErrorStrings(ErrorList.EMPTY_FINISH_DATE);

        const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
        const finishDateInvalid = getErrorStrings(ErrorList.DATE_FINISH_DATE_INVALID);

        const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
        const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_FINISH_FORMAT_INVALID);

        const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);

        const hasAnySurveyDurationErrors = (surveyStartDateError.length > 0 || surveyFinishDateError.length > 0 || startDateInvalid.length > 0 || finishDateInvalid.length > 0 || dateStartFormatInvalid.length > 0 || dateFinishFormatInvalid.length > 0 || dateRangeInvalid.length > 0);

        return (
            <>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", marginRight: "2rem" }}>
                        <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                            Start Date
                        </Text>
                        <FormField borderRadius="12px" size="14px" padding="10px 20px" height="45px"
                            placeholderColor="#808080" border="1px solid #E9EBEF"
                            align="left"
                            type="date"
                            placeholder="Choose date..."
                            onChange={(event) => { inputStartDate(event); }}
                            onFocus={(event) => { this.startDateChangeInputTypeToDate(event); }}
                            errors={surveyStartDateError}
                            value={survey.startDate}
                            max="9999-12-31"
                            showErrors={surveyStartDateError.length > 0 }
                        >
                        </FormField>
                    </div>
                    <div style={{ width: "50%" }}>
                        <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                            Finish Date
                        </Text>
                        <FormField borderRadius="12px" size="14px" padding="10px 20px" height="45px"
                            placeholderColor="#808080" border="1px solid #E9EBEF"
                            width="100%"
                            align="left"
                            type="date" 
                            placeholder="Choose date..."
                            onChange={(event) => { inputFinishDate(event); }}
                            onFocus={(event) => { this.finishDateChangeInputTypeToDate(event); }}
                            errors={surveyFinishDateError}
                            value={survey.finishDate}
                            max="9999-12-31"
                            showErrors={surveyFinishDateError.length > 0}
                        >
                        </FormField>
                    </div>
                </div>
                
                { hasAnySurveyDurationErrors &&
                    <ErrorText errorMargin="0px">
                        {startDateInvalid.length > 0 ? startDateInvalid : null}
                        {finishDateInvalid.length > 0 ? finishDateInvalid : null}
                        {dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null}
                        {dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null}
                        {dateRangeInvalid.length > 0 ? dateRangeInvalid : null}
                    </ErrorText>
                }
            </>
        );
    }
}

class UserGroupField extends Component {
    render() {
        const { selectUserGroup, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const userGroupError = getErrorStrings(ErrorList.EMPTY_USER_GROUP);
        const userGroupAndDateError = getErrorStrings(ErrorList.DATE_AND_USERGROUP_OVERLAP);

        const hasAnyUserGroupErrors = userGroupError.length > 0 || userGroupAndDateError.length > 0;
        return (
            <>
                <Text align="left" size="16px" weight="bold">
                    Select User Group
                </Text>

                <Select
                    isSearchable={false}
                    className="surveyTypeDropdown"
                    placeholder="Select a user group"
                    options={ surveyUserGroupOptions }
                    onChange={(choice) => selectUserGroup(choice)}
                    styles={customStyles}
                />

                { hasAnyUserGroupErrors &&
                    <ErrorText errorMargin="15px 20px 15px">
                        {userGroupError.length > 0 ? userGroupError : null}
                        {userGroupAndDateError.length > 0 ? userGroupAndDateError : null}
                    </ErrorText>
                }
            </>
        );
    }
}

class SurveyQuestionsFields extends Component {render() {
    const { inputQuestion, inputErrors, survey, activeQuestionCount, addQuestion, removeQuestion } = this.props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

    let surveyQuestionOneError = getErrorStrings(ErrorList.EMPTY_QUESTION_ONE);
    let surveyQuestionTwoError = getErrorStrings(ErrorList.EMPTY_QUESTION_TWO);
    let surveyQuestionThreeError = getErrorStrings(ErrorList.EMPTY_QUESTION_THREE);

    return (
        <>
            {activeQuestionCount >= 1 && <QuestionContainer questionNumber={1} questionValue={survey.surveyQuestions.one.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} ></QuestionContainer>}
            {activeQuestionCount === 1 && <AddAnotherQuestionContainer addQuestion={addQuestion} />}

            {activeQuestionCount >= 2 && 
                <>
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <QuestionContainer questionNumber={2} questionValue={survey.surveyQuestions.two.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} ></QuestionContainer>
                        </div>
                        {activeQuestionCount === 2 && <RemoveQuestionIconContainer display="block" position="absolute" removeQuestion={removeQuestion} questionElement={"two"} />}
                    </div>
                    {activeQuestionCount === 2 && <AddAnotherQuestionContainer addQuestion={addQuestion} />}
                </>
            }
            {activeQuestionCount >= 3 && 
                <>
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <QuestionContainer questionNumber={3} questionValue={survey.surveyQuestions.three.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} ></QuestionContainer>
                            {activeQuestionCount === 3 && <RemoveQuestionIconContainer display="block" position="absolute" removeQuestion={removeQuestion} questionElement={"three"} />}
                        </div>
                    </div>
                </>
            }
            
        </>
    );
}}

class SurveyFrequencyField extends Component {
    state = { tooltipActive: false };

    render() {
        const { inputErrors, selectShowIn, selectShowFrequency } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const showInError = getErrorStrings(ErrorList.EMPTY_SHOW_IN);
        const showFrequencyError = getErrorStrings(ErrorList.EMPTY_SHOW_FREQUENCY);

        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Show Survey In:
                </Text>
                <FlexContainer>
                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        defaultValue={surveyShowInOptions[0]}
                        options={ surveyShowInOptions }
                        onChange={(choice) => selectShowIn(choice)}
                        styles={customStyles}
                    />

                    <ErrorText>
                        {showInError}
                    </ErrorText>


                    <Text align="left" margin="10px 0px" size="16px" weight="500">
                        How often will my survey appear if not submitted by a user?
                    </Text>

                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        defaultValue={surveyFrequencyOptions[0]}
                        options={ surveyFrequencyOptions }
                        onChange={(choice) => selectShowFrequency(choice)}
                        styles={customStyles}
                    />
                    
                    
                    {/* <DropdownSelector fontWeight="500" size="14px" color="#51315D" onChange={(event) => { selectShowFrequency(event); }} value={survey.showFrequency}>
                        <option value={EnumSurveyShowFrequency.EVERYTIME}>Every time a user opens the app</option>
                        <option value={EnumSurveyShowFrequency.HOURS24}>{EnumSurveyShowFrequency.HOURS24} after user has seen the survey</option>
                        <option value={EnumSurveyShowFrequency.HOURS48}>{EnumSurveyShowFrequency.HOURS48} after user has seen the survey</option>
                    </DropdownSelector> */}

                    {/* <DropdownSelector fontWeight="500" size="14px" color="#51315D" onChange={(event) => { selectShowFrequency(event); }} value={survey.showFrequency}>
                        <option value={EnumSurveyShowFrequency.EVERYTIME}>Every time a user opens the app</option>
                        <option value={EnumSurveyShowFrequency.HOURS24}>{EnumSurveyShowFrequency.HOURS24} after user has seen the survey</option>
                        <option value={EnumSurveyShowFrequency.HOURS48}>{EnumSurveyShowFrequency.HOURS48} after user has seen the survey</option>
                    </DropdownSelector> */}
                    <ErrorText>
                        {showFrequencyError}
                    </ErrorText>
                </FlexContainer>
            </>
        );
    }
}

class SurveySubmissionAnonymousField extends Component {
    render() {
        const { setSurveyAnonymous } = this.props;
        const customTheme = createTheme({
            overrides: {
                MuiCheckbox: {
                    colorSecondary: {
                        color: "#FFFFFF",
                        border: "2px solid #000000",
                        borderRadius: "6px",
                        width: "5px",
                        height: "5px",
                        "&$checked": {
                            color: "#36BE24",
                            border: "2px solid #000000",
                            borderRadius: "6px",
                            background: "#36BE24 0% 0% no-repeat padding-box",
                        },
                        "&:hover": {
                            width: "5px",
                            height: "5px",
                            color: "#36BE24",
                            border: "2px solid #000000",
                            borderRadius: "6px",
                            background: "#36BE24 0% 0% no-repeat padding-box",
                        },
                        "&$checked:hover": {
                            width: "5px",
                            height: "5px",
                            color: "#36BE24",
                            border: "2px solid #000000",
                            borderRadius: "6px",
                            background: "#36BE24 0% 0% no-repeat padding-box",
                        }
                    }
                }
            }
        });
        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Make Survey Submission Anonymous (Optional)
                </Text>
                <Text align="left" size="14px" color="#8291B2">
                    By checking this box, you ensure that all survey responses will be collected anonymously. This<br/>
                    means users can share their thoughts freely and without any personal identifiers, promoting<br/>candid and unbiased feedback.
                </Text>
                <ThemeProvider theme={customTheme}>
                    <Checkbox onChange={(e) => setSurveyAnonymous(e.target.checked)} />
                </ThemeProvider>
            </>
        );
    }
}

class PageCongratulations extends Component {
    hideFormAndReloadList = () => {
        const { showSurveyForm, getSurveys } = this.props;

        showSurveyForm(false);
        getSurveys();
    };

    render() {
        return (
            <FlexContainer justifyContent="center" alignItems="center" height="70vh" style={{ margin: "auto" }}>
                <Text color="#000000" size="40px" weight="500" style={{ marginBlockEnd: "0px" }}>
                    Congratulations!
                </Text>
                <Text color="#000000" size="40px" weight="500" style={{ marginBlockStart: "0px" }}>
                    Your Survey Has Been Created.
                </Text>
                <Text color="black" size="16px" width="650px" lineHeight="25px" margin="0">
                    You can always make edits to your scheduled and active surveys via the <br/>
                    &rsquo;Surveys&rsquo; main screen and view insights once your survey has gone live.
                </Text>

                <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "100px", height: "40px", marginTop: "65px" }}
                    onClick={() => { this.hideFormAndReloadList(); }} >
                    Close
                </StyledButton>
            </FlexContainer>
        );
    }
}

export default SurveyForm;