import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import {
    ExplainParagraphLabel, Button, PageHeadingLabel, PageContainer, mobile, tablet, desktop,
    LottieLoadingIndicator
} from "src/components";

//assets
import ArrowDown from "src/img/ArrowDown.svg";
import ArrowUp from "src/img/ArrowDown.svg";

import IconRating from "src/img/survey/rating.png";
import IconSatisfaction from "src/img/survey/emotion.png";
import IconYesNo from "src/img/survey/Yes-No.png";
import IconFeedBack from "src/img/survey/comment.png";

import Checkbox from "./Checkbox";
import { SurveyStatusType, SurveyType, SortableColumnState } from "src/constants/survey";

const SurveyButton = styled(Button)`
    padding: 10px 40px 10px;
    border-radius: 7px !important;
    color: #FFFFFF;
    position: relative;
    cursor: pointer;
`;

const Table = styled.table`
    width: 100%;
    max-width: 1512px;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    border: 1px solid #8291B2;
    border-top: none;
    border-radius: 0 0 7px 7px;
    border-bottom: none;
`;

const LoadingContainer = styled.table`
    width: 100%;
    height: 100%;
    max-width: 1512px;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    border: 1px solid #8291B2;
    border-top: none;
    border-radius: 0 0 7px 7px;
    margin: 0 0 2.5rem 0;
`;

const Th = styled.th`    
    padding: 20px 10px;
    font-size: 13px;
    font-weight: 500;
    width: ${p => p.width || "5rem"};
    border-left: 0px solid #FFFFFF;
`;


const Td = styled.td`
    text-align: center;
    font-size: 14px;
    padding: ${p => p.padding || "0px"};
    border: 0px solid #8291B2;
    white-space: nowrap;
`;

const ColumnHeader = (({ heading, sortDirection }) => {
    const Container = styled.div`
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        text-align: center;
        user-select: none;
        cursor: pointer;
    `;

    const SortIndicator = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-left: 8px;
    `;

    return (
        <Container>
            {heading}
            <SortIndicator hidden={sortDirection === SortableColumnState.INACTIVE}>
                {(sortDirection === SortableColumnState.DESCENDING) &&
                    <img width="10px" src={ArrowDown} alt="SortArrow" />}
                {(sortDirection === SortableColumnState.ASCENDING) &&
                    <img width="10px" src={ArrowUp} alt="SortArrow" style={{ transform: "rotate(180deg)" }} />}
            </SortIndicator>
        </Container>
    );
});

const ViewButton = styled(Button)`
    min-width: 100px;
    width: ${p => p.width || "100%"};
    padding: 0.6em 0.7em;
    font-size: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 600;
    border-radius: 7px;
    border: none;
    box-shadow: none;
    border-color: #006CFF;
    background-color: #EAF3FF;
    cursor: pointer;
`;

const EditButton = styled(Button)`
    min-width: 100px;
    width: ${p => p.width || "100%"};
    padding: 0.6em 0.7em;
    font-size: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 600;
    border-radius: 7px;
    border: none;
    box-shadow: none;
    cursor: pointer;
`;

const UpperControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 1512px;
    background: #FFF;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
    
    ${tablet`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${mobile`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${desktop`
        padding: 0;
        flex-direction: row;
    `};
`;

const InputSelector = styled.select`
    background: #51315D;
    border:0;
    text-decoration: none;
    color: white;
`;

const SurveyTable = (props) => {
    //add method 
    const { sortColumn, showSurveyForm, editSurvey, showHowItWorks, surveyInsights, fetchSurvey, fetching } = props;
    const [selectedSurveys, setSelectedSurveys] = useState([]);
    const [selectAllSurveys, setSelectAllSurveys] = useState(false);
    const [selectedSurveyStatusType, setSelectedSurveyStatusType] = useState(SurveyStatusType.ACTIVE);
    const surveysArray = props.surveyArray;


    /**
     * @param {number} length
     */

    const emitSortColumn = (string) => {
        string = string.replace(/ /g, "_").toUpperCase();
        return sortColumn(string);
    };

    const handleCheckbox = (e) => {
        const isChecked = e.target.checked;
        if (!isChecked) {
            setSelectAllSurveys([]);
        } else {
            let arr = [];
            surveysArray.map(survey => arr.push({
                uuid: survey.uuid,
                name: survey.name,
                surveyType: survey.surveyType,
                userGroup: survey.userGroup,
                startDate: survey.startDate,
                finishDate: survey.finishDate,
                status: survey.status
            }));
            setSelectAllSurveys(arr);
        }
        setSelectAllSurveys(!selectAllSurveys);
    };

    const handleChangeFilter = (surveyTypeStatus) => {
        setSelectedSurveyStatusType(surveyTypeStatus);
        fetchSurvey(surveyTypeStatus);
    };

    const addRemoveSurveyFromSelected = (event, survey) => {
        const isChecked = event.target.checked;

        const data = {
            uuid: survey.uuid,
            name: survey.name,
            surveyType: survey.surveyType,
            userGroup: survey.userGroup,
            startDate: survey.startDate,
            finishDate: survey.finishDate,
            status: survey.status
        };
        return isChecked ? setSelectedSurveys([...selectedSurveys, data])
            : setSelectedSurveys(selectedSurveys.filter((obj) => obj["uuid"] !== survey.uuid));
    };

    const checkIfUuidIsSelected = (uuid) => selectedSurveys.some(obj => obj["uuid"] === uuid);

    //generate empty lines when survey/client list is less than 10 records
    const emptyList = () => {
        const emptyRows = 10 - surveysArray.length;

        if (emptyRows < 1)
            return;

        return Array.from({ length: emptyRows }, (_, i) => (
            <tr key={"empty_" + i}>
                <td className="change-color-on-select">&nbsp;</td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
            </tr>
        ));
    };

    const surveyList = (surveyItems) => surveyItems.map(survey => {
        return (
            <tr key={survey.uuid}
                className={checkIfUuidIsSelected(survey.uuid) ? "selected" : ""}
            >
                <td style={{ textAlign: "center" }}>
                    <label>
                        <Checkbox
                            readOnly
                            checked={checkIfUuidIsSelected(survey.uuid) || selectAllSurveys}
                            value={survey.uuid}
                            onChange={(e) => addRemoveSurveyFromSelected(e, survey)}
                        />
                    </label>
                </td>
                <Td style={{ textAlign: "left", padding: "0px 20px 0px 20px" }} width={"12%"}>
                    {survey["name"]}
                </Td>
                <Td>
                    {survey["surveyType"] === SurveyType.RATING && <img width="" src={IconRating} alt="IconRating" />}
                    {survey["surveyType"] === SurveyType.SATISFACTION && <img width="" src={IconSatisfaction} alt="IconSatisfaction" />}
                    {survey["surveyType"] === SurveyType.YESNO && <img width="" src={IconYesNo} alt="IconYesNo" />}
                    {survey["surveyType"] === SurveyType.FEEDBACK && <img width="" src={IconFeedBack} alt="IconFeedBack" />}
                </Td>

                <Td>
                    {survey["userGroup"]}
                </Td>
                <Td>
                    {moment(survey["startDate"]).format("D MMMM YYYY")}
                </Td>

                <Td>
                    {moment(survey["finishDate"]).format("D MMMM YYYY")}
                </Td>
                <Td padding="15px">
                    <EditButton onClick={() => editSurvey(survey.uuid)} width="6.25rem" className="survey-list-table-btn" backgroundColor="#FFFFFF"
                        color="#000" style={{ border: "1px solid #000" }}>
                        <span>Edit</span>
                    </EditButton>
                </Td>
                <Td padding="15px">
                    {
                        survey["status"] !== "Scheduled"
                            ?
                            <ViewButton onClick={() => surveyInsights(survey.uuid)} width="6.25rem" className="survey-list-table-btn" color="#006CFF" style={{ border: "1px solid #006CFF" }}>
                                <span>View</span>
                            </ViewButton>
                            :
                            <span> - </span>
                    }

                </Td>
                <Td>
                    {
                        survey["status"] === "Scheduled"
                            ?
                            <div style={{ fontWeight: "500", color: "#006CFF" }}>{survey["status"]}</div>
                            :
                            <div style={{ fontWeight: "500", color: "#77B500" }}>{survey["status"]}</div>
                    }
                </Td>

            </tr>
        );
    });

    return (
        <PageContainer>
            <PageHeadingLabel size="2.5rem">Surveys</PageHeadingLabel>
            <div className="row" style={{ maxWidth: "94.5rem" }}>
                <div className="description" style={{ marginBottom: "1em" }}>
                    Effortlessly create short and effective surveys. Send your surveys directly to your employees or clients Me <br />
                    Business app. Get instant feedback and data in real-time, staying informed and in control of your survey<br />
                    campaign&apos;s performance.
                </div>
                <div className="field">
                    <label>Filter:</label>
                    <InputSelector style={{ height: "51px" }} value={selectedSurveyStatusType} onChange={(e) => handleChangeFilter(e.target.value)} >
                        <option value={SurveyStatusType.ACTIVE} style={{ fontSize: "1rem" }}>
                            Active
                        </option>
                        <option value={SurveyStatusType.COMPLETE} style={{ fontSize: "1rem" }}>
                            Completed
                        </option>
                        <option value={SurveyStatusType.SCHEDULED} style={{ fontSize: "1rem" }}>
                            Scheduled
                        </option>
                    </InputSelector>
                </div>
            </div>
            <ExplainParagraphLabel
                width="100%"
                size="0.775rem"
                maxWidth="40rem"
            >

            </ExplainParagraphLabel>
            <UpperControls>
                <SurveyButton marginLeft="2.5rem" backgroundColor="#000000" onClick={() => { showHowItWorks(true); }}>
                    <span>How Does It Work?</span>
                </SurveyButton>
                {/* will need to add a remove users button when selecting, need to work on the logic for that one first and try to make something functional */}
                <SurveyButton marginRight="2.5rem" backgroundColor="#006CFF" onClick={(e) => showSurveyForm(true)}>
                    <span>Create New Survey</span>
                </SurveyButton>
            </UpperControls>

            { fetching && 
                <LoadingContainer>
                    <LottieLoadingIndicator/>
                </LoadingContainer>
            }

            { !fetching &&
                <div className="scrollable-table">
                    <Table>
                        <thead>
                            <tr>
                                <Th width={"5%"}>
                                    {/* Select all, does not highlight all the selected rows for the time being */}
                                    <label>
                                        <Checkbox readOnly checked={selectAllSurveys} onChange={(e) => handleCheckbox(e)} key="0" />
                                    </label>
                                </Th>
                                {/* column sorting does not show any icons at the moment, will add a fix to that later on */}
                                <Th width={"25%"} onClick={(e) => emitSortColumn("SURVEY_NAME")} >
                                    <ColumnHeader
                                        heading="Survey Name"
                                        sortDirection={emitSortColumn["SURVEY_NAME"]}
                                    />
                                </Th>
                                <Th width={"10%"} onClick={() => emitSortColumn("SURVEY_TYPE")} >
                                    <ColumnHeader
                                        heading="Survey Type"
                                        sortDirection={emitSortColumn["SURVEY_TYPE"]}
                                    />
                                </Th>
                                <Th width={"10%"} onClick={() => emitSortColumn("USER_GROUP")} >
                                    <ColumnHeader
                                        heading="User Group"
                                        sortDirection={emitSortColumn["USER_GROUP"]}
                                    />
                                </Th>

                                <Th width={"10%"} onClick={() => emitSortColumn("START_DATE")} >
                                    <ColumnHeader
                                        heading="Start Date"
                                        sortDirection={emitSortColumn["START_DATE"]}
                                    />
                                </Th>

                                <Th width={"10%"} onClick={() => emitSortColumn("FINISH_DATE")} >
                                    <ColumnHeader
                                        heading="Finish Date"
                                        sortDirection={emitSortColumn["FINISH_DATE"]}
                                    />
                                </Th>

                                <Th width={"10%"} >
                                    <ColumnHeader
                                        heading="Edit"
                                    />
                                </Th>
                                <Th width={"10%"} >
                                    <ColumnHeader
                                        heading="Survey Insights"
                                    />
                                </Th>
                                <Th width={"10%"} onClick={() => emitSortColumn("STATUS")} >
                                    <ColumnHeader
                                        heading="Status"
                                        sortDirection={emitSortColumn["STATUS"]}
                                    />
                                </Th>
                            </tr>
                        </thead>

                        <tbody>
                            {surveyList(surveysArray)}

                            {emptyList()}
                        </tbody>
                    </Table>
                </div>
            }
        </PageContainer >
    );
};


export default SurveyTable;