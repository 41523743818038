import React, { useContext } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "src/scenes/App/UserContext";
import { MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, isChatActionAllowed } from "src/constants/permissions";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { FlexContainer, Text } from "src/components";

const useStyles = makeStyles(() => ({
    outlined: {
        "&:focus": {
            outline: "none",
            background: "none"
        },
        
    }
}));

const selectStyle = {
    height: "2.5625rem",
    fontFamily: "Roboto",
    background: "#FFFFFF",
    border: "1px solid #000000",
    paddingLeft: "0.5rem",
    borderRadius: 7,
    "&:focus": {
        backgroundColor: "transparent"
    },
    "&:hover": {
        backgroundColor: "transparent",
    },
};


const TypeSelection = (props) => {
    /**
    * @param {string} currentSelectedLeadershipUuid
    * @param {string} checkerType
    * @param {array} leadershipRoles
    * @returns {boolean}
    */
    const checkLeadershipRoleVisibility = (currentSelectedLeadershipUuid, checkerType, leadershipRoles) => {
        if (currentSelectedLeadershipUuid === undefined || currentSelectedLeadershipUuid === "") {
            return true;
        }

        const selectedLeadershipRole = leadershipRoles.find((leadershipRole) => leadershipRole.uuid === currentSelectedLeadershipUuid);
        if (selectedLeadershipRole && currentSelectedLeadershipUuid === selectedLeadershipRole.uuid) {
            if (checkerType === USER_TYPE_EMPLOYEES) {
                return selectedLeadershipRole.isVisibleToEmployees;
            } else if (checkerType === USER_TYPE_CLIENTS) {
                return selectedLeadershipRole.isVisibleToClients;
            }
        }
    };
    const classes = useStyles();
    const { permissions, leadershipRoles } = useContext(UserContext);
    
    const hasAnyChatPermission = isChatActionAllowed(MESSAGE_EMPLOYEES, permissions) || isChatActionAllowed(MESSAGE_CLIENTS, permissions);
    const isSelectedLeadershipRoleDefault = !props.currentSelectedLeadershipRoleUuid;
    const isEmployeeTypeOptionAllowed = isSelectedLeadershipRoleDefault ? hasAnyChatPermission : checkLeadershipRoleVisibility(props.currentSelectedLeadershipRoleUuid, USER_TYPE_EMPLOYEES, leadershipRoles);
    const isClientTypeOptionAllowed = isSelectedLeadershipRoleDefault ? hasAnyChatPermission : checkLeadershipRoleVisibility(props.currentSelectedLeadershipRoleUuid, USER_TYPE_CLIENTS, leadershipRoles);

    /**
    * @param {string} userListType    
    */
    const setUserListType = (userListType) => {
        props.setSelectedUserListType(userListType);
        props.changeSelectedType(userListType);
    };

    return (
        <FlexContainer>
            { props.showLabel &&
                <Text color="#4B5155" size="1rem" align="left" margin="0 0 0.8125rem 0" weight="700">
                    Select user list:
                </Text>
            }
            <Select value={props.selectedUserListType}
                variant="outlined"
                classes={{ outlined: classes.outlined }}
                onChange={e => setUserListType(e.target.value)}
                style={{ 
                    ...selectStyle, 
                    width: props.width,
                    fontSize: props.fontSize || "1.125rem",
                    fontWeight: props.fontWeight || 600,
                    color: props.color || "#4B5155",
                }}
            >
                { isEmployeeTypeOptionAllowed && <MenuItem value={USER_TYPE_EMPLOYEES}>Employees</MenuItem> }
                { isClientTypeOptionAllowed && <MenuItem value={USER_TYPE_CLIENTS}>Clients</MenuItem> }
            </Select>
        </FlexContainer>
    );
};

export default TypeSelection;